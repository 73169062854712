import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { Profile } from "../../../Profile/Profile";
import { calIsMyTurn } from "../../../../../../utils/common";
import TrickStar from "../../TrickStarCard";

const useStyles = makeStyles(() => ({
  profile: {
    marginRight: (props) => props.marginRight,
    marginLeft: 6,
  },
  trickStar: {},
}));

const GameLeftTopContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: flex-end;
  width: 100%;
`;

const GameLeftTop = React.memo(function GameLeftTopComponent() {
  const otherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  const isMyTurn = useMemo(() => {
    return calIsMyTurn(otherPlayersState, (myIndex + 2) % 4);
  }, [otherPlayersState, myIndex]);

  const profile = useSelector((state) => state.room.profile2);
  // console.log(profile);
  const profileShipSpace = useSelector((state) => state.size.profileShipSpace);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const classes = useStyles({
    marginRight: profileShipSpace,
    marginBottom: shipSpace,
  });

  return (
    <GameLeftTopContainer paddingBottom={shipSpace}>
      <TrickStar />
      <Profile
        position="right"
        positionTurn="top"
        profile={profile}
        isMyTurn={isMyTurn}
        numberOfCalls={otherPlayersState[profile.index]?.numberOfCalls}
        className={classes.profile}
      />
    </GameLeftTopContainer>
  );
});

export default GameLeftTop;
