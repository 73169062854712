import { Container, Typography } from "@material-ui/core";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import Avatar from "@mui/material/Avatar";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import Backdrop from "../../../../components/Backdrop";
import Button from "../../../../components/Button";
import ErrorMessage from "../../../../components/ErrorMessage";
import Form from "../../../../components/Form";
import Input from "../../../../components/Input";
import { signInWithEmail } from "../../../../store/actions";
import { reSendVerifyEmail } from "../../../../store/actions/authActions";
import { loginValidateInput } from "../../../../utils/validation";
import BasicDialog from "../../../GameScreen/components/NewDialog/BasicDialog";
import useStyles, { BoxWrapper, LogoGame, Production, Space } from "./styles";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const ErrorValidate = styled.p`
  width: ${(props) => props.input_W}px;
  margin: 5px;
  color: #dc3233;
  text-align: center;
  font-size: 14;
  font-weight: 600;
`;

const initialValues = {
  email: "",
  password: "",
};

const AdminLogin = () => {
  const { t } = useTranslation();
  const logoGameWidth = 240;
  const logoGameHeight = 150;
  const productionWidth = 130;
  const productionHeight = 6;
  const boxWrapperWidth = 569;
  const input_W = 372;
  const input_H = 51;

  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [isOpenResend, setIsOpenResend] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const classes = useStyles();

  useDocumentTitle(t("common.gConceptTitle") + t("common.adminLoginTitle"));

  const handleSubmit = async (e) => {
    e.preventDefault();
    const err = loginValidateInput(values);
    setErrors(err);
    if (err === "") {
      setLoading(true);
      const res = await dispatch(
        signInWithEmail(values.email, values.password)
      );
      if (res === "") {
        history.push("/admin/admin-list");
      } else {
        if (res == "errors.emailIsNotVerified") {
          setIsOpenResend(true);
        } else {
          setErrorMessage(t(res));
          setLoading(false);
        }
      }
    }
  };

  const onChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors((err) => ({ ...err, [name]: "" }));
    setErrorMessage("");
  };

  const handleResendVerifyEmail = async () => {
    const url = `${window.location.origin}/admin-login`;
    await reSendVerifyEmail(values.email, values.password, url);
    setIsOpenResend(false);
    setLoading(false);
  };

  useEffect(() => {
    const clearError = setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    return () => clearTimeout(clearError);
  });

  return (
    <Container className={classes.root}>
      <Backdrop open={loading} />

      <Form>
        <LogoGame width={logoGameWidth} height={logoGameHeight} />
        <Space space={10} />
        <Production width={productionWidth} height={productionHeight} />
        <Space space={40} />
        <BoxWrapper width={boxWrapperWidth}>
          <Avatar
            sx={{
              position: "absolute",
              top: -50,
              width: 130,
              height: 130,
              backgroundColor: "#6f0000",
            }}
          >
            <SupervisorAccountIcon style={{ fontSize: 65 }} />
          </Avatar>
          <Space space={100} />
          <Input
            value={values.email}
            onChange={onChange}
            type="email"
            name="email"
            placeHolder={t("common.email")}
            autoFocus={true}
            error={t(errors.email)}
            width={input_W}
            height={input_H}
          />
          {errors.email === "validate.invalidEmail" ? (
            <>
              <ErrorMessage message={t(errors.email)} />
            </>
          ) : null}
          {errors.email === "validate.isRequired" ? (
            <ErrorValidate input_W={input_W}>
              {t("errors.loginEmailRequire")}
            </ErrorValidate>
          ) : null}
          <Space space={20} />
          <Input
            value={values.password}
            onChange={onChange}
            placeHolder={t("common.password")}
            type="password"
            name="password"
            InputProps={true}
            error={t(errors.password)}
            width={input_W}
            height={input_H}
          />
          <Space space={20} />
          {errorMessage === "errors.errorNetwork" ? (
            <>
              <ErrorMessage message={t(errorMessage)} />
            </>
          ) : null}
          <ErrorMessage message={errorMessage} />
          {errors.password === "validate.isRequired" ? (
            <ErrorValidate input_W={input_W}>
              {t("validate.passwordIsRequire")}
            </ErrorValidate>
          ) : (
            <ErrorMessage message={t(errors.password)} />
          )}
          <Space space={20} />
          <Button
            type="submit"
            variant="outlined"
            text={t("login.login")}
            className={classes.loginBtn}
            onClick={handleSubmit}
          ></Button>
          <Space space={30} />
          <Typography
            variant="body1"
            style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
          >
            {t("login.forgotPassword")}
            <Link className={classes.link} to="/forgotPassword">
              {t("login.clickHere")}
            </Link>
          </Typography>
          <div style={{ height: "10px", fontWeight: "600" }} />
          <Space space={30} />
        </BoxWrapper>
      </Form>

      <BasicDialog
        isOpen={isOpenResend}
        agreeText={t("common.reSend")}
        closeText={t("common.noReSend")}
        mainText={t("errors.reSendVerifyEmail")}
        onAgree={handleResendVerifyEmail}
        onCancel={() => {
          setIsOpenResend(false);
          setLoading(false);
        }}
      />
    </Container>
  );
};

export default AdminLogin;
