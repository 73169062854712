import { Button } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
const MiddleContainer = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding-top: ${(props) => props.space}px;
  box-sizing: border-box;
`;

const MenuText = styled.p`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 400;
  color: white;
  text-transform: uppercase;
  font-family: "Source Serif Pro";
`;

const MenuButtonGroup = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  overflow: auto;
  padding: ${(props) => props.padding}px;
  box-sizing: border-box;
`;

const MenuButton = styled(Button)`
  width: ${(props) => props.width - 3}px;
  background-image: url("/assets/btn_menu.png");
  font-size: ${(props) => props.fontSize}px;
  font-family: "Source Serif Pro";
  font-weight: 400;
  letter-spacing: 0;
  color: black;
  box-sizing: border-box;
  padding: ${(props) => props.fontSize * 1.5}px 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  &:hover {
    transform: scale(1.1);
    transition: all 0.2s ease-in-out;
  }
`;

const listMenuText = [
  "apocalypseTeaching",
  "rulesAndHow",
  "tableCardSuit",
  "useMasterCard",
  "apocalypseGlossary",
];

const MenuMiddle = React.memo(function MenuMiddleComponent() {
  const { t } = useTranslation();
  const shipHeight = useSelector((state) => state.size.vShipHeight);
  const menuTitleTextFontSize = useSelector(
    (state) => state.size.menuTitleTextFontSize
  );
  const height = useMemo(
    () => Math.round(shipHeight + menuTitleTextFontSize * 1.5),
    [shipHeight, menuTitleTextFontSize]
  );
  const menuButtonHeight = useSelector((state) => state.size.menuButtonHeight);
  const menuButtonWidth = useSelector((state) => state.size.menuButtonWidth);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (size) {
      const startGameFontSize = 16 * size?.minScale;
      setInnerSize({
        startGameFontSize,
      });
    } else {
      const startGameFontSize = 16;
      setInnerSize({
        startGameFontSize,
      });
    }
  }, [size]);
  const handleOpenPdf = (title) => {
    const titles = {
      apocalypseTeaching: "/rules/apocalypseTeaching.pdf",
      rulesAndHow: "/rules/rulesAndHow.pdf",
      tableCardSuit: "/rules/tableCardSuit.pdf",
      useMasterCard: "/rules/useMasterCard.pdf",
      apocalypseGlossary: "/rules/apocalypseGlossary.pdf",
    };
    window.open(titles[title]);
  };
  return (
    <MiddleContainer height={height} space={shipSpace} id="gc-menu-middle">
      <MenuText fontSize={menuTitleTextFontSize}>Menu</MenuText>
      <MenuButtonGroup padding={shipSpace}>
        {listMenuText.map((title, idx) => (
          <MenuButton
            key={`menu-button:${idx}`}
            fontSize={innerSize.startGameFontSize}
            width={menuButtonWidth}
            height={menuButtonHeight}
            onClick={() => handleOpenPdf(title)}
          >
            {t(`menu.${title}`)}
          </MenuButton>
        ))}
        {/* <MenuButton
          onClick={() => handleConfigSetting(!isInstructorDialog)}
          key={`menu-button:instruction`}
          fontSize={innerSize.startGameFontSize}
          width={menuButtonWidth}
          height={menuButtonHeight}
        >
          {t("narrationSetting.title")}
        </MenuButton> */}
      </MenuButtonGroup>
      {/* DIALOG */}
      {/* <NarrationSetting
        isOpen={isInstructorDialog}
        onClose={() => handleConfigSetting(false)}
      ></NarrationSetting> */}
    </MiddleContainer>
  );
});

export default MenuMiddle;
