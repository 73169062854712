import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { Button } from "@material-ui/core";

const MessageButtonStyled = styled(Button)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  font-family: "Source Serif Pro";
  letter-spacing: 0;
  text-transform: initial;
  color: #211c18;
  background-image: url("/assets/message.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: all 0.2 ease-in-out;
  position: relative;

  &:hover {
    transform: scale(1.1);
  }
`;

const IconMessageContainer = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const IconMessage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: "fill";
`;

const MessageButtonStyledBage = styled.div`
  position: absolute;
  top: -5px;
  right: -5px;
  width: 22px;
  height: 22px;
  background-color: red;
  border-radius: 50%;
  color: white;
  text-align: center;
  line-height: 22px;
  font-size: 13px;
`;

const MessageButton = React.memo(function MessageButtonComponent({ onClick }) {
  const { t } = useTranslation();
  const chatButtonWidth = useSelector((state) => state.size.chatButtonWidth);
  const chatButtonHeight = useSelector((state) => state.size.chatButtonHeight);
  const { countMessageUnRead } = useSelector((state) => state.chat);
  const minScale = useSelector((state) => state.size.minScale);
  const chatButtonFontSize = useMemo(() => 15 * (minScale ?? 1), [minScale]);
  const iconMessageSize = useMemo(() => 24 * (minScale ?? 1), [minScale]);
  return (
    <MessageButtonStyled
      onClick={onClick}
      width={chatButtonWidth}
      height={chatButtonHeight}
      fontSize={chatButtonFontSize}
    >
      <IconMessageContainer width={iconMessageSize} height={iconMessageSize}>
        <IconMessage src="/assets/message_icon.png" alt="message_icon" />
      </IconMessageContainer>
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;{t("game.chat")}
      {countMessageUnRead > 0 && (
        <MessageButtonStyledBage>{countMessageUnRead}</MessageButtonStyledBage>
      )}
    </MessageButtonStyled>
  );
});

MessageButton.propTypes = {
  onClick: PropTypes.any,
};

export default MessageButton;
