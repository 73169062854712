import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Confetti from "react-confetti";
import { useSelector } from "react-redux";
import { sleep } from "../../../../utils/common";
import ShowBackgroundComponent from "./ShowBackground";

const ConfettiCelebration = (props) => {
  const { isWinGame } = props;
  const width = useSelector((state) => state.size.width);
  const height = useSelector((state) => state.size.height);
  const [isShowBG, setIsShowBG] = useState(false);

  useEffect(() => {
    const showBackground = async () => {
      if (isWinGame === true) {
        await sleep(3000);
        setIsShowBG(true);
      }
    };
    showBackground();
  }, [isWinGame]);

  if (!isWinGame) return null;

  return (
    <div
      style={{
        position: "absolute",
        width: "90%",
        height: "90%",
        top: "5%",
        left: "5%",
        backgroundImage: `url("/assets/game_bg.png")`,
        backgroundAttachment: "fixed",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
      }}
    >
      <Confetti
        width={width}
        height={height}
        tweenDuration={3000}
        gravity={0.3}
        id={"confetti"}
        friction={1}
        numberOfPieces={100}
      />
      {isShowBG && <ShowBackgroundComponent />}
    </div>
  );
};

ConfettiCelebration.propTypes = {
  isWinGame: PropTypes.bool,
  onCapture: PropTypes.func,
  onClose: PropTypes.func,
  onDownload: PropTypes.func,
  setCanDownload: PropTypes.func,
  image: PropTypes.any,
};

export default ConfettiCelebration;
