import { getFunctions } from "../../services/firebase";
import {
  GET_ALL_RETAIL_PACKAGE,
  BY_RETAIL_PACKAGE,
  BY_RETAIL_PACKAGE_INVOICE,
} from "../funcs";
const functions = getFunctions();

export const getAllRetailPackage = async (packageId) => {
  try {
    const getAllRetailPackageFunction = functions.httpsCallable(
      GET_ALL_RETAIL_PACKAGE
    );
    return getAllRetailPackageFunction({ packageId });
  } catch (e) {
    console.error(e);
  }
};

export const byRetailPackage = async (playerId, retailPackageId) => {
  try {
    const byRetailPackageFunction = functions.httpsCallable(BY_RETAIL_PACKAGE);
    return byRetailPackageFunction({ playerId, retailPackageId });
  } catch (e) {
    console.error(e);
  }
};

export const byRetailPackageInvoice = async (
  playerId,
  paymentGatewayPriceId
) => {
  try {
    const byRetailPackageInvoiceFunction = functions.httpsCallable(
      BY_RETAIL_PACKAGE_INVOICE
    );
    return byRetailPackageInvoiceFunction({
      playerId,
      priceId: paymentGatewayPriceId,
    });
  } catch (e) {
    console.error(e);
  }
};
