import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  addNarrationPopup,
  addNarrationScreen,
  doneNarration,
  removeNarrationScreen,
  removeStopCurrentNarration,
  updateDuration,
  updateNarrationText,
} from "../../../../../store/actions/narrationActions";
import narrations from "../../../../../store/narration.json";
import steps from "../../../../../store/stepId";
import {
  REQUEST_UPDATE_STATE_USE_MASTER_CARDS,
  REQUEST_UPDATE_TURN_ORDER_CARD,
  STOP_CURRENT_NARRATION,
  UPDATE_CAN_SELECT_MC,
  UPDATE_SHOW_MASTER,
} from "../../../../../store/types";
import { sleep } from "../../../../../utils/common";
import MessDialog from "../../NewDialog/MessDialog";

const QueueNarration = ({ stepId }) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const audio = useSelector((state) => state.audio.audioList);
  const isPlaying = useSelector((state) => state.room.table.isPlaying);
  const stopNarrationId = useSelector(
    (state) => state.narration.stopNarrationId
  );
  const [currentNarrationId, setCurrentNarrationId] = useState("");
  const [text, setText] = useState("");

  useEffect(() => {
    const runQueue = async () => {
      if (!steps[stepId]) return;
      let queues = [];
      steps[stepId].forEach((queue) => queues.push(queue));
      while (queues.length > 0) {
        const localIsPlaying = JSON.parse(localStorage.getItem("isPlaying"));
        if (!localIsPlaying) {
          queues = [];
          return;
        }
        const narration = queues.shift();
        if (!narration) return;
        if (narration.type === "narration") {
          const _narration = narrations[narration.name];
          if (_narration) {
            if (narration.name !== "narrationSilence") {
              await sleep(1000);
            }
            if (
              narration.name === "narration32" ||
              narration.name === "narration32_1" ||
              narration.name === "narration33" ||
              narration.name === "narrationPlayerSelectMC"
            ) {
              dispatch({
                type: REQUEST_UPDATE_STATE_USE_MASTER_CARDS,
                payload: true,
              });
            }
            const duration = _narration.duration / 2;

            setCurrentNarrationId(narration.name);
            const response = dispatch(updateNarrationText(t, narration.name));
            dispatch(updateDuration(duration));
            setText(response);
            await sleep(duration);
            if (narration.name === "narrationPlayerSelectMC") {
              dispatch({ type: REQUEST_UPDATE_STATE_USE_MASTER_CARDS });
            }
            setText("");
          }
        }
        if (narration.type === "screen") {
          dispatch(addNarrationScreen(narration.name));
          await sleep(narration.duration);
          dispatch(updateDuration(narration.duration));
          dispatch(removeNarrationScreen(narration.name));
        }
        if (narration.type === "popup") {
          await sleep(1000);
          setCurrentNarrationId(narration.filename);
          dispatch(addNarrationPopup(narration.name));
          if (narration.name === "narrationPlayerSelectMC") {
            dispatch({ type: REQUEST_UPDATE_STATE_USE_MASTER_CARDS });
          }
        }
        dispatch(doneNarration());
        if (narration.name === "narrationFirstPlayerSelectOrderCard") {
          dispatch({ type: REQUEST_UPDATE_TURN_ORDER_CARD });
        }
        if (narration.name === "narration17") {
          dispatch({ type: UPDATE_SHOW_MASTER, payload: true });
        }
        if (narration.name === "narrationPlayerSelectMC") {
          dispatch({ type: UPDATE_CAN_SELECT_MC, payload: true });
        }
      }
    };
    localStorage.setItem("isPlaying", JSON.stringify(isPlaying));
    if (isPlaying) {
      runQueue();
    } else {
      setText("");
    }
    // eslint-disable-next-line
  }, [stepId, dispatch, isPlaying]);

  useEffect(() => {
    const stopCurrentNarration = () => {
      if (stopNarrationId !== STOP_CURRENT_NARRATION) return;
      if (currentNarrationId === "") return;
      if (!audio[currentNarrationId]) return;
      audio[currentNarrationId].exposeData.stop();
      dispatch(removeStopCurrentNarration());
    };
    stopCurrentNarration();
    // eslint-disable-next-line
  }, [stopNarrationId, currentNarrationId]);

  if (!text) return null;
  return <MessDialog isOpen={text !== ""} mainText={text} />;
};

QueueNarration.propTypes = {
  stepId: PropTypes.string,
};
export default React.memo(QueueNarration);
