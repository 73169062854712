import React from "react";
import PropTypes from "prop-types";
import { CardAnimated } from "../styles";

const MyCardComponent = ({
  width,
  height,
  src,
  onDragStart,
  onDoubleClick,
  draggable,
}) => {
  return (
    <CardAnimated
      width={width}
      height={height}
      src={src}
      onDragStart={onDragStart}
      onDoubleClick={onDoubleClick}
      draggable={draggable}
    />
  );
};

MyCardComponent.propTypes = {
  width: PropTypes.number,
  height: PropTypes.number,
  children: PropTypes.any,
  src: PropTypes.string,
  onDoubleClick: PropTypes.func,
  draggable: PropTypes.bool,
  onDragStart: PropTypes.func,
};
export default MyCardComponent;
