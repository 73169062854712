import InputBase from "@material-ui/core/InputBase";
import { alpha, createStyles, makeStyles } from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) =>
  createStyles({
    search: {
      position: "relative",
      borderRadius: "17px",
      backgroundColor: alpha(theme.palette.common.white, 0.15),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.white, 0.25),
      },
      marginLeft: 0,
      width: "100%",
      maxHeight: "32px",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(1),
        width: "auto",
      },
      border: "1px solid #442211",
      display: "flex",
      flexDirection: "row-reverse",
    },
    searchIcon: {
      padding: "0px 3px",
      height: "100%",
      backgroundColor: "#442211",
      color: "#fff",
      borderRadius: "0px 17px 17px 0px",
      zIndex: "2",
      position: "absolute",
      pointerEvents: "none",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      cursor: "pointer",
      "& svg": {
        fontSize: "25px",
      },
    },
    inputRoot: {
      color: "#442211",
      width: "100%",
    },
    inputInput: {
      paddingLeft: "20px",
      transition: theme.transitions.create("width"),
      width: "100%",
      borderRadius: "17px",
      backgroundColor: "#EBE1D6",
      maxHeight: "30px",
      zIndex: "1",
      [theme.breakpoints.up("sm")]: {
        width: "100%",
      },
    },
  })
);

export default function Search({ search, onFocus, onBlur }) {
  const classes = useStyles({ focus });

  return (
    <div className={classes.search}>
      <div className={classes.searchIcon}>
        <SearchIcon />
      </div>
      <InputBase
        onChange={(e) => search(e.target.value.toString().trim().toLowerCase())}
        classes={{
          root: classes.inputRoot,
          input: classes.inputInput,
        }}
        onFocus={onFocus}
        onBlur={onBlur}
        inputProps={{ "aria-label": "search " }}
      />
    </div>
  );
}

Search.propTypes = {
  search: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
};
