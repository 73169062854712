import { STD_SIZE } from "./../store/constant";

/**
 * Calculate card gap in the ship
 * @param {*} defaultMargin
 * @param {*} numCards
 * @param {*} bandWidth
 * @param {*} cardWidth
 * @returns
 */
export function cardGapWidthCalculator(
  defaultMargin,
  numCards = 11,
  bandWidth = STD_SIZE.STD_H_SHIP_WIDTH,
  cardWidth = STD_SIZE.STD_CARD_WIDTH
) {
  if (numCards < 11) {
    numCards = 11;
  }
  const numGap = numCards - 1;
  const totalGapWidth = bandWidth - 4 * defaultMargin - cardWidth;
  const gapWidth = Math.round(totalGapWidth / numGap);
  return [gapWidth, defaultMargin];
}

/**
 * Calcualate card gap in my card
 * @param {*} containerSize
 * @param {*} paddingGap
 * @returns
 */
export function calTutorialGap(containerSize, paddingGap) {
  const cardHeight = Math.round((containerSize - paddingGap * 3) / 2);
  const cardWidth = Math.round(
    cardHeight * STD_SIZE.STD_CARD_WIDTH_CARD_HEIGHT_RATIO
  );
  const widthGap = Math.round((containerSize - cardWidth - paddingGap * 2) / 6);
  return [cardWidth, cardHeight, widthGap];
}
