import styled from "styled-components";

// GChaos card
export const GChaosCardWrapper = styled.div`
  background-color: #eae1d3;
  border-radius: 4%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const GChaosCard = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
  box-shadow: 0 0 2px black;
  border-radius: 4%;
`;

// Normal card tutorials
export const LeftTutorialCardsWrapper = styled.div`
  width: 80%;
  height: 45%;
  box-sizing: border-box;
  background-color: #eae1d3;
  border-radius: 5px;
  position: relative;
  margin-right: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const RightTutorialCardsWrapper = styled.div`
  width: 90%;
  height: 45%;
  box-sizing: bor-box;
  background-color: #eae1d3;
  border-radius: 5px;
  position: relative;
  margin-left: 15px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TopTutorialCardsWrapper = styled.div`
  width: 50%;
  height: 90%;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 3px;
  margin: auto;
  background-color: #eae1d3;
  box-sizing: border-box;
  border-radius: 5px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const Cards = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
`;

export const CardsTop = styled.div`
  width: 100%;
  height: 48%;
`;

export const CardsBottom = styled.div`
  width: 100%;
  height: 48%;
`;

export const CardWrapper = styled.div`
  width: 140px;
  height: 100%;
  position: absolute;
  box-sizing: border-box;
  transform: translate(-24%, -25%) scale(0.45, 0.45);
  transition: all 0.4s ease-in-out;
`;

export const Card = styled.img`
  width: 100%;
  height: 100%;
  object-fit: fill;
  box-shadow: 0 0 3px black;
  border-radius: 4%;
`;
