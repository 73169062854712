import { isEmpty } from "lodash";
import firebase from "../../services/firebase";

const CHATS = "chats";
const STREAM_MESSAGES = "streamMessages";
const MESSAGES = "messages";
const chatCollection = firebase.firestore().collection(CHATS);
const streamMessageCollection = firebase
  .firestore()
  .collection(STREAM_MESSAGES);

/**
 * create thread data
 * @param {object} data create thread
 * @returns
 */
async function create(data = {}) {
  if (!isEmpty(data)) {
    return (await chatCollection.add(data)).id;
  }
}

/**
 * get data by docId
 * @param {string} docId
 * @returns
 */
async function read(docId = "") {
  return (await chatCollection.doc(docId).get()).data();
}

/**
 * get all players data
 * @returns
 */
async function readAll() {
  return chatCollection.get();
}

/**
 * update data by docId
 * @param {string} docId
 * @param {object} data
 * @returns
 */
async function update(docId = "", data = {}) {
  if (docId && !isEmpty(data)) {
    return chatCollection.doc(docId).update(data);
  }
}

/**
 * add sub document in sub collection
 * @param {string} docId threadId
 * @param {object} data message
 * @returns
 */
async function createSubDoc(docId = "", data = {}) {
  if (docId && !isEmpty(data)) {
    return (await chatCollection.doc(docId).collection(MESSAGES).add(data)).id;
  }
}

async function addMessageToStreamMessage(data = {}) {
  if (!isEmpty(data)) {
    const ref = streamMessageCollection.doc();
    return ref.set({ ...data, messageId: ref.id });
  }
}

async function deleteDocument(docPath) {
  if (docPath) {
    return chatCollection.doc(docPath).delete();
  }
}

async function deleteCollection(collectionPath) {
  if (collectionPath) {
    const collectionData = await firebase
      .firestore()
      .collection(collectionPath)
      .get();
    return collectionData.docs.forEach((doc) => doc.ref.delete());
  }
}

export default {
  create,
  createSubDoc,
  read,
  readAll,
  update,
  addMessageToStreamMessage,
  deleteDocument,
  deleteCollection,
};
