import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import createSagaMiddleware from "redux-saga";
import thunk from "redux-thunk";
import playerGameStateSagas from "../sagas/playerGameStateSagas";
import adminReducer from "./reducers/adminReducer";
import audioReducer from "./reducers/audioReducer";
import chatReducer from "./reducers/chatReducer";
import instructorNotifyReducer from "./reducers/instructorNoticeReducer";
import invite from "./reducers/inviteRoomReducer";
import joinedTableReducer from "./reducers/joinedTableReducer";
import narrationReducer from "./reducers/narrationReducer";
import paymentReducer from "./reducers/paymentReducer";
import playerGameStatesReducer from "./reducers/playerGameStatesReducer";
import roomReducer from "./reducers/roomReducer";
import sizeReducer from "./reducers/sizeReducer";
import statusPlayerReducer from "./reducers/statusPlayerReducer";
import stepQueueReducer from "./reducers/stepQueueReducer";
import subscribe from "./reducers/subscribeReducer";
import tableReducer from "./reducers/tableReducer";
import userReducer from "./reducers/userReducer";
import videoGameReducer from "./reducers/videoGameReducer";
import packageReducer from "./reducers/packageReducer";
import playTimeReducer from "./reducers/playTimeReducer";
import eventReducer from "./reducers/eventReducer";

const rootReducer = combineReducers({
  table: tableReducer,
  user: userReducer,
  subscribe: subscribe,
  inviteNotify: invite,
  room: roomReducer,
  videoGame: videoGameReducer,
  playerGameState: playerGameStatesReducer,
  joinedTable: joinedTableReducer,
  instructorNotice: instructorNotifyReducer,
  narration: narrationReducer,
  stepQueueReducer: stepQueueReducer,
  size: sizeReducer,
  payment: paymentReducer,
  audio: audioReducer,
  statusPlayer: statusPlayerReducer,
  chat: chatReducer,
  admin: adminReducer,
  package: packageReducer,
  playTime: playTimeReducer,
  event: eventReducer,
});

const composeEnhacer = composeWithDevTools({
  trace: true,
  traceLimit: 24,
  maxAge: 99,
});

const saga = createSagaMiddleware();
const middlewares = [thunk, saga];

const store = createStore(
  rootReducer,
  composeEnhacer(applyMiddleware(...middlewares))
);

saga.run(playerGameStateSagas);

export { rootReducer };

export default store;
