import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import ChangePositionDialog from "../NewDialog/ChangePositionDialog";
import ChangePositionButton from "./components/ChangePositionButton";
import MessageButton from "./components/MessageButton";
import VoiceVideoButton from "./components/VoiceVideoButton";
import { Container, VideoVoiceContainer } from "./styles";
import {
  OPEN_CHAT_BOX_BY_THREAD_ID,
  SET_LOADING_AUDIO,
  SET_LOADING_VIDEO,
} from "../../../../store/types";
import Timer from "../../../../components/Timer";
import MessDialog from "../NewDialog/MessDialog";
import { useTranslation } from "react-i18next";
import { updatePlayerStatus } from "../../../../store/actions/userAction";

export const Media = React.memo(function MediaComponent() {
  const playerId = useSelector((state) => state.user.playerId);
  const videoGame = useSelector((state) => state.videoGame);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const owner = useSelector((state) => state.room.table.owner);
  const isPlaying = useSelector((state) => state.room.table.isPlaying);
  const threadId = useSelector((state) => state.room.threadId);
  const isLoadingVideo = useSelector((state) => state.videoGame.isLoadingVideo);
  const isLoadingAudio = useSelector((state) => state.videoGame.isLoadingAudio);
  const isConnectTwilio = useSelector(
    (state) => state.videoGame.isConnectTwilio
  );
  const [open, setOpen] = useState(false);
  const [isVideo, setIsVideo] = useState(false);
  const [isVoice, setIsVoice] = useState(false);
  const [permissionsVideo, setPermissionsVideo] = useState(false);
  const [permissionsAudio, setPermissionsAudio] = useState(false);
  const [openDialogPermissionErr, setOpenDialogPermissionErr] = useState(false);
  const [errPermissionText, setErrPermissionText] = useState("");
  const [openDialogErrConnectTwilio, setOpenDialogErrConnectTwilio] =
    useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();
  useEffect(() => {
    if (!playerId || !videoGame[playerId]) {
      setIsVideo(false);
      setIsVoice(false);
      return;
    }
    setIsVideo(videoGame[playerId].isVideo);
    setIsVoice(videoGame[playerId].isAudio);
  }, [videoGame, playerId]);

  // useEffect(() => {
  //   //Check permissions camera
  //   navigator.permissions.query({ name: "camera" }).then(function (result) {
  //     if (result.state === "granted") {
  //       setPermissionsVideo(true);
  //     } else {
  //       setPermissionsVideo(false);
  //     }
  //   });

  //   //Check permissions microphone
  //   navigator.permissions.query({ name: "microphone" }).then(function (result) {
  //     if (result.state === "granted") {
  //       setPermissionsAudio(true);
  //     } else {
  //       setPermissionsAudio(false);
  //     }
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [navigator.permissions]);

  //Fix navigator not work on old safari versions (<16 versions)
  const { detect } = require("detect-browser");
  const browser = detect();
  useEffect(() => {
    requestPermissions();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigator.permissions, navigator.mediaDevices]);

  const requestPermissions = async () => {
    try {
      if (browser.name === "safari") {
        handleSafariPermissions();
      } else {
        handlePermissions();
      }
    } catch {
      console.log("Error requestPermissions");
    }
  };
  const handleSafariPermissions = async () => {
    if (navigator.mediaDevices !== undefined) {
      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          console.log("Access to microphone on safari", stream);

          setPermissionsAudio(true);
        })
        .catch(function (err) {
          console.log("Not access microphone on safari", err);
          setPermissionsAudio(false);
        });
    } else {
      console.log("Out of luck audio:))))");
    }

    if (navigator.mediaDevices !== undefined) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then(function (stream) {
          console.log("Access to camera on safari", stream);
          setPermissionsVideo(true);
        })
        .catch(function (err) {
          console.log("Not access camera on safari", err);
          setPermissionsVideo(false);
        });
    } else {
      console.log("Out of luck video:))))");
    }
  };
  const handlePermissions = async () => {
    if (navigator.permissions && navigator.permissions.query) {
      //Check permissions camera
      navigator.permissions.query({ name: "camera" }).then(function (result) {
        if (result.state === "granted") {
          setPermissionsVideo(true);
        } else {
          setPermissionsVideo(false);
        }
      });

      //Check permissions microphone
      navigator.permissions
        .query({ name: "microphone" })
        .then(function (result) {
          if (result.state === "granted") {
            setPermissionsAudio(true);
          } else {
            setPermissionsAudio(false);
          }
        });
    }
  };
  useEffect(() => {
    const clearError = setTimeout(() => {
      if (openDialogPermissionErr) {
        setOpenDialogPermissionErr(false);
        setErrPermissionText("");
      }
    }, 3000);

    return () => clearTimeout(clearError);
  }, [openDialogPermissionErr]);

  useEffect(() => {
    const clearError = setTimeout(() => {
      if (openDialogErrConnectTwilio) {
        setOpenDialogErrConnectTwilio(false);
      }
    }, 3000);

    return () => clearTimeout(clearError);
  }, [openDialogErrConnectTwilio]);

  // eslint-disable-next-line no-unused-vars
  const handleLoadingAudio = useCallback(
    (isLoading) => {
      dispatch({
        type: SET_LOADING_AUDIO,
        payload: isLoading,
      });
    },
    [dispatch]
  );

  // eslint-disable-next-line no-unused-vars
  const handleLoadingVideo = useCallback(
    (isLoading) => {
      dispatch({
        type: SET_LOADING_VIDEO,
        payload: isLoading,
      });
    },
    [dispatch]
  );

  const handleOnOffVideo = useCallback(
    (onOff) => {
      if (!isConnectTwilio) {
        setOpenDialogErrConnectTwilio(true);
        return;
      }

      handleLoadingVideo(true);
      if (permissionsVideo) {
        if (videoGame.localMedia && videoGame.localMedia.video) {
          videoGame.localMedia.video(onOff);
        }
      } else {
        setErrPermissionText("カメラへのアクセス権限を許可してください");
        setOpenDialogPermissionErr(true);
        handleLoadingVideo(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videoGame, permissionsVideo]
  );

  const handleOnOffVoice = useCallback(
    (onOff) => {
      if (!isConnectTwilio) {
        setOpenDialogErrConnectTwilio(true);
        return;
      }
      handleLoadingAudio(true);
      if (permissionsAudio) {
        if (videoGame.localMedia && videoGame.localMedia.audio) {
          videoGame.localMedia.audio(onOff);
        }
      } else {
        setErrPermissionText("マイクへのアクセス権限を許可してください");
        setOpenDialogPermissionErr(true);
        handleLoadingAudio(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [videoGame, permissionsAudio]
  );

  const isShowBtnChangePosition = useMemo(() => {
    return playerId === owner && !isPlaying;
  }, [playerId, owner, isPlaying]);

  const openChatBox = async () => {
    dispatch({
      type: OPEN_CHAT_BOX_BY_THREAD_ID,
      payload: threadId,
    });
    await updatePlayerStatus(playerId);
  };

  return (
    <React.Fragment>
      <Container>
        <VideoVoiceContainer padding={shipSpace}>
          <VoiceVideoButton
            onClick={() => handleOnOffVideo(!isVideo)}
            onSrc={"/assets/video_on.png"}
            offSrc={"/assets/video_off.png"}
            isOn={isVideo}
            isLoading={isLoadingVideo}
          />
          <VoiceVideoButton
            onClick={() => handleOnOffVoice(!isVoice)}
            onSrc={"/assets/voice_on.png"}
            offSrc={"/assets/voice_off.png"}
            isOn={isVoice}
            isLoading={isLoadingAudio}
          />
        </VideoVoiceContainer>

        {/* TIMER */}
        <Timer />

        {/* BUTTON CHANGE POSITION */}
        {isShowBtnChangePosition && (
          <ChangePositionButton
            disable={isPlaying ? 1 : 0}
            onClick={() => setOpen(true)}
          />
        )}
        <ChangePositionDialog isOpen={open} onClose={() => setOpen(false)} />
        <MessageButton onClick={openChatBox} />
      </Container>
      <MessDialog
        isOpen={openDialogPermissionErr}
        onClose={() => setOpenDialogPermissionErr(false)}
        mainText={errPermissionText}
      />
      <MessDialog
        isOpen={openDialogErrConnectTwilio}
        onClose={() => setOpenDialogErrConnectTwilio(false)}
        mainText={t("tables.mediaError", { error: "" })}
      />
    </React.Fragment>
  );
});
