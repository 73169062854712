import React from "react";
import { HorizontalItem } from "../styles";
import PropTypes from "prop-types";
const HorizontalItemComponent = ({ height, children }) => {
  return <HorizontalItem height={height}>{children}</HorizontalItem>;
};

HorizontalItemComponent.propTypes = {
  height: PropTypes.number,
  children: PropTypes.any,
};
export default HorizontalItemComponent;
