import styled from "styled-components";
import { BORDER_RADIUS, COLOR_YELLOW_LIGHT } from "../../../constants";

export const ChatItemContainer = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.isMine ? "row-reverse" : "row")};
  /* justify-content: space-between; */
  align-items: flex-start;
  width: 100%;
  margin: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 0.5}em 0` : `${0.5 * 0.5}em 0`};
  padding: ${(props) =>
    props.minscale > 0.5 ? `0 ${props.minscale * 1}em` : `0 ${0.5 * 1}em`};
  box-sizing: border-box;
  position: relative;
`;

export const ChatContainer = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
  white-space: pre-wrap;
`;

export const TimeSpan = styled.span`
  ${(props) => props.tip}
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 12}px` : `${0.5 * 12}px`};
  font-weight: 300;
`;

export const ChatNickName = styled.span`
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 13}px` : `${0.5 * 13}px`};
  font-weight: 300;
  margin-right: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 5}px` : `${0.5 * 5}px`};
  margin-left: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 10}px` : `${0.5 * 10}px`};
  ${(props) => props.namePosition}
`;

export const ChatText = styled.p`
  font-weight: 300;
  line-height: 1.4;
  word-break: break-word;
  white-space: pre-wrap;
  background-color: ${COLOR_YELLOW_LIGHT};
  padding: ${(props) =>
    props.minscale > 0.5
      ? `${props.minscale * 0.5}em ${props.minscale * 0.75}em`
      : `${0.5 * 0.5}em ${0.5 * 0.75}em`};
  width: 11em;
  position: relative;
  border-radius: ${BORDER_RADIUS};
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 1}em` : `${0.5 * 1}em`};
  min-height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 40}px` : `${0.5 * 40}px`};
  margin-bottom: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 5}px` : `${0.5 * 5}px`};

  &:after {
    content: "";
    position: absolute;
    left: -11px;
    top: 11px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-right: 15px solid ${COLOR_YELLOW_LIGHT};
    display: ${(props) => (props.isMine ? "none" : "block")};
  }

  &::before {
    content: "";
    position: absolute;
    right: -11px;
    top: 11px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 15px solid ${COLOR_YELLOW_LIGHT};
    display: ${(props) => (props.isMine ? "block" : "none")};
  }
`;

export const ChatTextContainer = styled.div`
  display: flex;
  /* justify-content: ${(props) =>
    props["aria-checked"] === true ? "flex-end" : "flex-start"}; */
  flex: 1;
  margin-left: 8px;
  flex-direction: row;
`;

export const BubbleTail = styled.span`
  background-color: ${COLOR_YELLOW_LIGHT};
`;

export const ChatWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AvatarWrapper = styled.div`
  padding-top: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 5}px` : `${0.5 * 5}px`};
  margin-right: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 1}rem` : `${0.5 * 1}rem`};
`;
