import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  min-width: 1700px;
  height: 100vh;
  box-sizing: border-box;
  background: url("/assets/game_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  z-index: 0;
  @media screen and (max-width: 1024px) {
    min-width: 1024px;
    overflow: hidden;
  }
`;

export const Main = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
  display: flex;
  /* background-image: radial-gradient(
    ellipse at 60% 55%,
    rgba(34, 19, 7, 0) 0%,
    rgba(34, 19, 7, 0.5) 50%,
    rgba(34, 19, 7, 0.75) 80%
  ); */
`;

export const LogoGame = styled.div`
  width: ${(props) => props.minScale * 180}px;
  height: ${(props) => props.minScale * 150}px;
  background: url("/assets/game_logo.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
