import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import { Box, makeStyles } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { deleteFriend } from "../../../../store/actions/friendAction";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  left: {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    padding: "10px 20px",
  },
  closeButton: {
    color: "white",
    borderRadius: 0,
    boxSizing: "border-box",
    padding: 0,
    margin: 0,
    width: 20,
    height: 20,
  },
  closeImg: {
    width: "18px",
  },
  dialogTitle: {
    fontSize: "1rem !important",
    padding: "16px 47px !important",
    color: "#442211 !important",
    fontWeight: "normal !important",
  },
  dialogActions: {
    padding: "0 !important",
    justifyContent: "space-evenly !important",
  },
  dialogButton: {
    background: "#442211 0% 0% no-repeat padding-box !important",
    borderRadius: "9px !important",
    width: "74px !important",
    height: "21px !important",
    color: "#fff !important",
    fonWeight: "normal !important",
    fontSize: "0.7rem !important",
  },
}));

export default function DeleteDialog({ isOpen, onClose, friendId }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const myId = useSelector((state) => state.user.playerId);

  const handleClose = () => {
    onClose();
  };

  const handleDeleteDialog = async () => {
    handleClose();
    await deleteFriend(myId, friendId);
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            backgroundColor: "#C1AE8A",
            borderRadius: "7px",
            width: "424px",
            height: "146px",
          },
        }}
      >
        <Box className={classes.left}>
          <Button className={classes.closeButton} onClick={handleClose}>
            <img
              className={classes.closeImg}
              src={require("../../../../assets/icons/icon-close.svg").default}
              alt="close"
            />
          </Button>
        </Box>
        <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
          {t("friends.deleteFriend")}
        </DialogTitle>
        <DialogActions className={classes.dialogActions}>
          <Button className={classes.dialogButton} onClick={handleDeleteDialog}>
            {t("tables.yes")}
          </Button>
          <Button className={classes.dialogButton} onClick={handleClose}>
            {t("tables.no")}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

DeleteDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  friendId: PropTypes.string,
  fetchOnlineUsers: PropTypes.func,
  isOnline: PropTypes.bool,
};
