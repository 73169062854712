import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "90vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    justifyContent: "center",
    color: "black",
    overflow: "auto",
  },
  contaier: {
    width: "80%",
    backgroundColor: "#f3f3f3",
    padding: "2rem",
    borderRadius: "20px",
    boxSizing: "border-box",
    color: "#541513",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
    overflow: "auto !important",
  },
  title: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "3px",
    fontSize: "20px",
    marginBottom: "1rem",
  },
  field: {
    display: "flex",
    alignItems: "start",
    padding: "1rem 0",
    boxSizing: "border-box",
  },
  fieldRight: {
    width: "230px",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    marginRight: "1rem",
    fontWeight: "bold",
  },
  fieldRightTitle: {},
  fieldRightMust: {
    backgroundColor: "red",
    padding: "2px 5px",
    color: "white",
    borderRadius: "5px",
    boxShadow: "rgba(0, 0, 0, 0.2) 0px 1px 4px",
    fontSize: "14px",
  },
  fieldLeft: {
    display: "flex",
    alignItems: "center",
    flex: 1,

    "& > div": {
      width: "100%",
    },

    "& span": {
      marginLeft: "10px",
    },

    "& input": {
      "&::placeholder": {
        color: "#c1c1c1 !important",
      },
    },

    "& label": {},
  },
  fieldLefRadioGroup: {
    flex: 1,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",

    "@media (max-width: 1200px)": {
      flexDirection: "column",
      alignItems: "start",
    },
  },
  fieldTextArea: {
    width: "100%",
    height: "200px",
    padding: "10px",
    outline: "none",
    boxSizing: "border-box",
    border: "1px solid",

    "&::placeholder": {
      color: "#c1c1c1 !important",
    },
  },
  fieldLeftInput: {
    height: "51px",
    width: "100%",
    padding: "10px",
    outline: "none",
    boxSizing: "border-box",
    border: "1px solid",
  },
  subHeader: {
    margin: 0,
    color: "#c1c1c1",
    fontSize: "14px",
  },
  btnWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    marginTop: "2rem",
  },
  btn: {
    padding: "0.5rem 3rem",
    background: "#ce5931",
    color: "#fff",
    fontSize: "16px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    border: "none",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#7d7d7d",
    },
  },
  btnBlue: {
    padding: "0.5rem 3rem",
    background: "#7d7d7d",
    color: "#fff",
    fontSize: "16px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    border: "none",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#d7d7d7",
    },
  },
  textErr: {
    color: "#f00",
    fontSize: "14px",
  },
}));
export default useStyles;
