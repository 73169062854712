// trigger event to open/close dialog
import { CLOSE_DIALOG_OVER_PLAY_TIME } from "../types";
const initialState = {
  playtime: false,
};

const eventReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLOSE_DIALOG_OVER_PLAY_TIME:
      return {
        ...state,
        playtime: action.payload,
      };
    default:
      return state;
  }
};
export default eventReducer;
