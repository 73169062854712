import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  row-gap: 1.5em;
  min-width: 400px;
  min-height: 450px;
  box-sizing: border-box;
  padding: 2em;
`;

export const ContractWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const Title = styled.div`
  padding-left: 1em;
  display: flex;
  font-size: 2em;
  font-weight: bold;
  align-items: center;
  @media screen and (max-width: 860px) {
    font-size: 1em;
  }
`;

export const PlanDetailWrapper = styled.div`
  padding-left: 2em;
`;

export const TitleWrapper = styled.div``;

export const InfoWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Item = styled.div`
  margin-bottom: 10px;
  line-height: 20px;
`;
export const GroupButton = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: "#ce5931";
  border-radius: 40px;
  justify-content: space-evenly;
`;

export const CurrentCardWraper = styled.div`
  margin-bottom: 8px;
  background: #4862e2;
  color: #eaeef2;
  border-radius: 7px;
  width: 95%;
  max-width: 250px;
  position: relative;
  transition: all 0.4s ease;
  box-shadow: 0 2px 4px 0 #cfd7df;
  min-height: 50px;
  padding: 13px;
  background: #1a1b1c;
  color: #efefef;

  &:hover {
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
  }
  @media screen and (max-width: 768px) {
    max-width: 220px;
  }
`;

export const CardNumber = styled.div`
  text-align: center;
  font-size: 16px;
  &:before {
    content: "**** **** **** ";
    color: #8999e5;
    font-size: 20px;
  }
`;

export const CardExpiry = styled.div`
  font-size: 16px;
  position: absolute;
  bottom: 8px;
  left: 15px;
`;

export const ItemWraper = styled.div`
  display: flex;
  justify-content: space-evenly;
  height: 76px;
  gap: 1em;
  margin-bottom: 10px;
`;

const useStyles = makeStyles(() => ({
  visa: {
    background: "#4862e2 !important",
    color: "#eaeef2 !important",
    "&::after": {
      content: '" "',
      position: "absolute",
      bottom: "10px",
      right: "15px",
      height: "16px",
      width: "50px",
      backgroundImage: "url('/assets/visa.png')",
    },
  },
  masterCard: {
    background: "#4f0cd6 !important",
    color: "#e3e8ef !important",
    "&::after": {
      content: '" "',
      position: "absolute",
      bottom: "10px",
      right: "15px",
      width: "40px",
      height: "25px",
      backgroundImage: "url('/assets/mastercard.png')",
    },
  },
  jcb: {
    background: "#cc3737 !important",
    color: "#f7e8e8 !important",
    "&::after": {
      content: '" "',
      position: "absolute",
      bottom: "10px",
      right: "15px",
      width: "30px",
      height: "15px",
      backgroundImage: "url('/assets/jcb.png')",
    },
  },
  diners: {
    background: "#8a38ff !important",
    color: "#f5efff !important",
    "&::after": {
      content: '" "',
      position: "absolute",
      bottom: "10px",
      right: "15px",
      width: "30px",
      height: "24px",
      backgroundImage: "url('/assets/diners.png')",
    },
  },
  discover: {
    background: "#f16821 !important",
    color: "#fff4ef !important",
    "&::after": {
      content: '" "',
      position: "absolute",
      bottom: "10px",
      right: "15px",
      width: "50px",
      height: "14px",
      backgroundImage: "url('/assets/discover.png')",
    },
  },
  amex: {
    background: "#1cd8b3 !important",
    color: "#f2fcfa !important",
    "&::after": {
      content: '" "',
      position: "absolute",
      bottom: "10px",
      right: "15px",
      width: "50px",
      height: "14px",
      backgroundImage: "url('/assets/amex.png')",
    },
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: "50px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    minWidth: "170px",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: "50px",
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    textAlign: "center",
    minWidth: "170px",
  },
  buttonChangeCard: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    height: "50px",
    textTransform: "capitalize",
    border: "2px solid black",
    textAlign: "center",
    minWidth: "150px",
  },
  leftColumn: {
    minWidth: "40%",
  },
}));

export default useStyles;
