import { STD_SIZE } from "./../constant";
import { SCREEN_RESIZE, SET_LOADING_GLOBAL } from "./../types";

const initialState = {
  width: STD_SIZE.STD_WIDTH,
  height: STD_SIZE.STD_HEIGHT,
  hShipWidth: STD_SIZE.STD_H_SHIP_WIDTH,
  hShipHeight: STD_SIZE.STD_H_SHIP_HEIGHT,
  vShipWidth: STD_SIZE.STD_V_SHIP_WIDTH,
  vShipHeight: STD_SIZE.STD_V_SHIP_HEIGHT,
  cardWidth: STD_SIZE.STD_CARD_WIDTH,
  cardHeight: STD_SIZE.STD_CARD_HEIGHT,
  minScale: 1,
  shipSpace: STD_SIZE.STD_SHIP_SPACE,
  myCardWidth: STD_SIZE.STD_MY_CARD_WIDTH,
  logoWidth: STD_SIZE.STD_LOGO_WIDTH,
  logoHeight: STD_SIZE.STD_LOGO_HEIGHT,
  // avatarWidth: STD_SIZE.STD_AVATAR_WIDTH,
  // avatarHeight: STD_SIZE.STD_AVATAR_HEIGHT,
  // arkSize:
  //   STD_SIZE.STD_H_SHIP_WIDTH * STD_SIZE.STD_ARK_SIZE_GRAND_CROSS_WIDTH_RATIO,
  // arkPadding: STD_SIZE.STD_ARK_PADDING,
  longButtonWidth: STD_SIZE.STD_LONG_BUTTON_WIDTH,
  longButtonHeight:
    STD_SIZE.STD_LONG_BUTTON_WIDTH *
    STD_SIZE.STD_LONG_BUTTON_HEIGHT_LONG_BUTTON_WIDTH_RATIO, // ratio la ti le chia
  menuButtonWidth: STD_SIZE.STD_MENU_BUTTON_WIDTH,
  menuWidth:
    STD_SIZE.STD_MENU_BUTTON_WIDTH *
    STD_SIZE.STD_MENU_WIDTH_MENU_BUTTON_WIDTH_RATIO,
  menuButtonHeight: STD_SIZE.STD_MENU_BUTTON_HEIGHT,
  // menuButtonSpace: STD_SIZE.STD_MENU_BUTTON_SPACE,
  chatButtonWidth: STD_SIZE.STD_CHAT_BUTTON_WIDTH,
  chatButtonHeight: STD_SIZE.STD_CHAT_BUTTON_HEIGHT,
  videoVoiceButtonWidth: STD_SIZE.STD_VIDEO_VOICE_BUTTON_WIDTH,
  videoVoiceButtonHeight: STD_SIZE.STD_VIDEO_VOICE_BUTTON_HEIGHT,
  welcomeBandWidth: STD_SIZE.STD_WELCOME_BAND_WIDTH,
  masterWidth: STD_SIZE.STD_MASTER_WIDTH,
  profileShipSpace: STD_SIZE.STD_PROFILE_SHIP_SPACE,
  // myCardLeftMargin: STD_SIZE.STD_MY_CARD_LEFT_MARGIN,
  bottomHeight: STD_SIZE.STD_H_SHIP_HEIGHT + STD_SIZE.SHIP_SPACE * 2,
  menuTitleTextFontSize: STD_SIZE.STD_MENU_TITLE_FONT_SIZE,
  freeHaftSpace: 0,
  sorButtonWidth:
    STD_SIZE.STD_MY_CARD_WIDTH /
    STD_SIZE.STD_MY_CARD_WIDTH_SORT_BUTTON_WIDTH_RATIO,
  sorButtonHeight:
    (STD_SIZE.STD_MY_CARD_WIDTH /
      STD_SIZE.STD_MY_CARD_WIDTH_SORT_BUTTON_WIDTH_RATIO) *
      STD_SIZE.STD_LONG_BUTTON_HEIGHT_LONG_BUTTON_WIDTH_RATIO +
    5,
  svgSettingButton: STD_SIZE.STD_SVG_SETTING_BUTTON,
  drawerWidth: STD_SIZE.STD_DRAWER_WIDTH,
  isLoading: false,
};

function calVShipSize(minScale) {
  const shipWidth = Math.round(STD_SIZE.STD_V_SHIP_WIDTH * minScale);
  const shipHeight = Math.round(shipWidth * STD_SIZE.STD_SHIP_LONG_SHORT_RATIO);
  return [shipWidth, shipHeight];
}

function calMinScale(wScale, hScale) {
  return wScale <= hScale ? wScale : hScale;
}

function calCardSize(wScale, hScale) {
  const minScale = calMinScale(wScale, hScale);
  const cardHeight = Math.round(STD_SIZE.STD_CARD_HEIGHT * minScale);
  const cardRatio = 16 / 11;
  const cardWidth = Math.round(cardHeight / cardRatio);
  return [cardWidth, cardHeight];
}

function calScales(width, height) {
  const hScale = height / STD_SIZE.STD_HEIGHT;
  const wScale = width / STD_SIZE.STD_WIDTH;
  return [wScale, hScale];
}

function calMenuSize(minScale) {
  const menuButtonWidth = Math.round(STD_SIZE.STD_MENU_BUTTON_WIDTH * minScale);
  const menuButtonHeight = Math.round(
    menuButtonWidth / STD_SIZE.STD_MENU_BUTTON_WIDTH_HEIGHT_RATIO
  );

  const menuWidth = Math.round(
    menuButtonWidth * STD_SIZE.STD_MENU_WIDTH_MENU_BUTTON_WIDTH_RATIO
  );

  return [menuButtonWidth, menuButtonHeight, menuWidth];
}

const sizeReducer = (state = initialState, action) => {
  switch (action.type) {
    case SCREEN_RESIZE: {
      const { width, height } = action.payload;
      const [wScale, hScale] = calScales(width, height);
      const minScale = calMinScale(wScale, hScale);
      const [vShipWidth, vShipHeight] = calVShipSize(minScale);
      const [hShipWidth, hShipHeight] = [vShipHeight, vShipWidth];
      const [cardWidth, cardHeight] = calCardSize(wScale, hScale);
      const [menuButtonWidth, menuButtonHeight, menuWidth] =
        calMenuSize(minScale);
      const shipSpace = Math.round(STD_SIZE.STD_SHIP_SPACE * minScale);
      const bottomHeight = Math.round(hShipHeight + shipSpace * 2);
      const menuTitleTextFontSize = Math.round(
        STD_SIZE.STD_MENU_TITLE_FONT_SIZE * minScale
      );
      const videoVoiceButtonWidth = Math.round(
        STD_SIZE.STD_VIDEO_VOICE_BUTTON_WIDTH * minScale
      );
      const videoVoiceButtonHeight = Math.round(
        STD_SIZE.STD_VIDEO_VOICE_BUTTON_HEIGHT * minScale
      );
      const longButtonWidth = Math.round(
        STD_SIZE.STD_LONG_BUTTON_WIDTH * minScale
      );
      const longButtonHeight = Math.round(
        longButtonWidth *
          STD_SIZE.STD_LONG_BUTTON_HEIGHT_LONG_BUTTON_WIDTH_RATIO
      );
      const chatButtonWidth = Math.round(
        STD_SIZE.STD_CHAT_BUTTON_WIDTH * minScale
      );
      const chatButtonHeight = Math.round(
        STD_SIZE.STD_CHAT_BUTTON_HEIGHT * minScale
      );
      const myCardWidth = Math.round(STD_SIZE.STD_MY_CARD_WIDTH * minScale);
      const profileShipSpace = Math.round(
        STD_SIZE.STD_PROFILE_SHIP_SPACE * minScale
      );
      const welcomeBandWidth = Math.round(
        STD_SIZE.STD_WELCOME_BAND_WIDTH * minScale
      );

      const masterWidth = Math.round(STD_SIZE.STD_MASTER_WIDTH * minScale);
      const sortButtonWidth = Math.round(myCardWidth / 3.5);
      const sortButtonHeight = Math.round(
        sortButtonWidth *
          STD_SIZE.STD_LONG_BUTTON_HEIGHT_LONG_BUTTON_WIDTH_RATIO +
          5
      );
      let freeSpace = Math.round(
        width -
          menuWidth -
          myCardWidth -
          2 * shipSpace -
          hShipWidth -
          hShipWidth -
          2 * shipSpace
      );
      const freeHaftSpace = freeSpace > 0 ? Math.round(freeSpace / 2) : 0;

      const logoWidth = Math.round(STD_SIZE.STD_LOGO_WIDTH * minScale);
      const logoHeight = Math.round(STD_SIZE.STD_LOGO_HEIGHT * minScale);
      const svgSettingButton = Math.round(
        STD_SIZE.STD_SVG_SETTING_BUTTON * minScale
      );
      return {
        width,
        height,
        hShipWidth,
        hShipHeight,
        vShipWidth,
        vShipHeight,
        cardWidth,
        cardHeight,
        shipSpace,
        myCardWidth,
        logoWidth,
        logoHeight,
        longButtonWidth,
        longButtonHeight,
        menuButtonWidth,
        menuWidth,
        menuButtonHeight,
        chatButtonWidth,
        chatButtonHeight,
        videoVoiceButtonWidth,
        videoVoiceButtonHeight,
        welcomeBandWidth,
        masterWidth,
        profileShipSpace,
        minScale,
        bottomHeight,
        menuTitleTextFontSize,
        freeHaftSpace,
        sortButtonWidth,
        sortButtonHeight,
        svgSettingButton,
      };
    }
    case SET_LOADING_GLOBAL: {
      return {
        ...state,
        isLoading: action.payload,
      };
    }
    default:
      return state;
  }
};
export default sizeReducer;
