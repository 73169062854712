import React, { useMemo } from "react";
import { useSelector } from "react-redux";

function ShowBackground() {
  const minScale = useSelector((state) => state.size.minScale);
  const textWidth = useMemo(() => Math.round(minScale * 1413), [minScale]);
  const textHeight = useMemo(() => Math.round(minScale * 375), [minScale]);
  return (
    <>
      <img
        src={"/assets/wingame_bg.png"}
        id="star-image"
        style={{
          position: "absolute",
          display: "block",
          width: "100%",
          height: "100%",
          objectFit: "fill",
        }}
      ></img>

      <div className={"confetti-img"} id={"banner_winner"}>
        <img
          src={"/assets/wingame_orange.png"}
          id="text-win-game"
          style={{
            display: "block",
            width: textWidth,
            height: textHeight,
            objectFit: "fill",
            left: `calc((100% - ${textWidth}px) / 2)`,
            position: "relative",
            zIndex: 2,
          }}
        ></img>
      </div>
    </>
  );
}

export default ShowBackground;
