import styled from "styled-components";
export const StarCardsContainer = styled.div`
  flex: 1;
`;

export const StarCards = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
`;

export const DotStyle = styled.img`
  height: 99.5%;
  margin: auto;
  box-sizing: border-box;
  overflow: hidden;
`;
