import {
  JOIN_AUDIO,
  JOIN_VIDEO,
  NEW_JOIN_GUY,
  CLEAR_ALL_TWILIO,
  OFFLINE_PLAYER_TWILIO,
  TWILIO_ACTIVE_ROOM,
  ADD_LOCAL_MEDIA_TWILIO,
  SET_LOADING_AUDIO,
  SET_LOADING_VIDEO,
  SET_IS_CONNECT_TWILIO,
} from "../types";

const initialState = {
  exampleKey: {
    video: null,
    isVideo: false,
    audio: null,
    isAudio: false,
    isOnline: false,
  },
  [TWILIO_ACTIVE_ROOM]: null,
  localMedia: {
    audio: null,
    video: null,
  },
  isLoadingVideo: false,
  isLoadingAudio: false,
  isConnectTwilio: true,
};

const videoGameReducer = (state = initialState, action) => {
  let currentState = {};
  let currentPlayer = {};
  switch (action.type) {
    case TWILIO_ACTIVE_ROOM:
      clearConnect(state[TWILIO_ACTIVE_ROOM]);
      return {
        ...state,
        [TWILIO_ACTIVE_ROOM]: action.payload.activeRoom,
      };
    case NEW_JOIN_GUY:
      currentState = { ...state };
      currentPlayer = getPlayerVideoByIdentity(
        currentState,
        action.payload.identity
      );
      currentPlayer.isOnline = true;
      return {
        ...currentState,
        [action.payload.identity]: currentPlayer,
      };
    case JOIN_VIDEO:
      currentState = { ...state };
      currentPlayer = getPlayerVideoByIdentity(
        currentState,
        action.payload.identity
      );
      currentPlayer.video = action.payload.video;
      currentPlayer.isVideo = action.payload.isVideo;
      currentPlayer.isOnline = true;
      return {
        ...currentState,
        [action.payload.identity]: currentPlayer,
      };

    case JOIN_AUDIO:
      currentState = { ...state };
      currentPlayer = getPlayerVideoByIdentity(
        currentState,
        action.payload.identity
      );
      currentPlayer.audio = action.payload.audio;
      currentPlayer.isAudio = action.payload.isAudio;
      currentPlayer.isOnline = true;
      return {
        ...currentState,
        [action.payload.identity]: currentPlayer,
      };
    case OFFLINE_PLAYER_TWILIO:
      currentState = { ...state };
      currentPlayer = {
        video: null,
        isVideo: false,
        audio: null,
        isAudio: false,
        isOnline: false,
      };
      return {
        ...currentState,
        [action.payload.identity]: currentPlayer,
      };
    case CLEAR_ALL_TWILIO:
      clearConnect(state[TWILIO_ACTIVE_ROOM]);
      return {
        ...initialState,
        [TWILIO_ACTIVE_ROOM]: state[TWILIO_ACTIVE_ROOM],
      };

    case ADD_LOCAL_MEDIA_TWILIO:
      return {
        ...state,
        localMedia: {
          ...state.localMedia,
          ...action.payload,
        },
      };

    case SET_LOADING_VIDEO: {
      return {
        ...state,
        isLoadingVideo: action.payload,
      };
    }
    case SET_LOADING_AUDIO: {
      return {
        ...state,
        isLoadingAudio: action.payload,
      };
    }
    case SET_IS_CONNECT_TWILIO: {
      return { ...state, isConnectTwilio: action.payload };
    }
    default:
      return state;
  }
};

const getPlayerVideoByIdentity = (currentState, identity) => {
  let videoGame = currentState[identity];
  if (!videoGame) {
    videoGame = {
      video: null,
      isVideo: false,
      audio: null,
      isAudio: false,
      isOnline: false,
    };
  }
  return videoGame;
};
const clearConnect = (activeRoom) => {
  if (activeRoom && "disconnected" !== activeRoom.state) {
    activeRoom.disconnect();
  }
};

export default videoGameReducer;
