import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "100vh",
    backgroundColor: "#e4e4e4",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    overflowX: "hidden",
  },
  pdf: {
    overflowY: "scroll",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
}));
const Rules = () => {
  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.ruleTitle"));
  const classes = useStyles();
  const [numPages, setNumPages] = useState(null);
  const [path, setPath] = useState("");
  const history = useHistory();
  const minScale = useSelector((state) => state.size.minScale);
  useEffect(() => {
    const pathName = history.location.pathname;
    const splitPath = pathName.split("/");
    setPath(`/PDF/${splitPath[2]}`);
  }, [history]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };
  useEffect(() => {
    pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
  }, []);

  return (
    <div className={classes.root}>
      <Document
        file={path}
        onLoadSuccess={onDocumentLoadSuccess}
        options={{ workerSrc: "pdf.worker.js" }}
        className={classes.pdf}
      >
        {Array.from(new Array(numPages), (_, index) => (
          <Page
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            scale={minScale <= 0.74 ? minScale : 1}
          />
        ))}
      </Document>
    </div>
  );
};

export default Rules;
