/* eslint-disable prettier/prettier */
import styled from "styled-components";
import { GrStatusGoodSmall } from "react-icons/gr";
import { Button } from "@material-ui/core";

export const TableText = styled.p`
  color: #442211;
  font-size: 12px;
  text-align: start;
`;

export const TableName = styled.p`
  padding-right: 15px;
  font-size: 12px;
  line-height: 1;
  color: #442211;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const GridTableName = styled.div`
  display: grid;
  grid-template-columns: 1.2fr 2.2fr 1.7fr 1fr;
  align-items: center;
  justify-items: start;
  margin-bottom: 0.75rem;
`;

export const LabelFriend = styled.div`
  box-sizing: border-box;
  padding: 2px 5px;
  width: max-content;
  min-width: 72px;
  text-align: center;
  background-color: #fff;
  color: #442211;
  font-size: 12px;
  border-radius: 6px;
  border: 1px solid #442211;
`;

export const ButtonAddFriend = styled(Button)`
  box-sizing: border-box;
  padding: 2px 5px !important;
  width: max-content;
  min-width: 72px;
  background-color: #442211;
  color: #fff;
  font-size: 12px;
  border-radius: 6px !important;
  border: none;
  &:hover {
    background-color: #442211;
  }
`;

export const ChatList = styled.div`
  padding: 10px;
  padding-right: 15px;
  height: calc(100% - 54px);
  max-height: inherit;
  box-sizing: border-box;
  overflow: auto;
  & > * {
    font-weight: 300;
  }
`;

export const ChatItem = styled.div`
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
`;

export const NickName = styled.p`
  font-size: 14px;
  text-align: center;
  box-sizing: border-box;
  color: #442211;
`;

export const NickNameWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Spacer = styled.div``;
export const StatusIcon = styled(GrStatusGoodSmall)`
  color: ${(props) => props.color};
  font-size: 14px;
  margin-right: 10px;
`;
