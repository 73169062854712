import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  formConfirm: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    color: "#541513 !important",
  },
  paper: {
    borderRadius: "24px !important",
  },
  title: {
    margin: "2rem 0 1rem 0 !important",
  },
  labelContent: {
    marginLeft: "1rem !important",
    color: "#541513 !important",
  },
  btn: {
    marginTop: "1rem !important",
    backgroundColor: "#ce5931 !important",
    width: "6rem !important",
  },
}));

export default useStyles;
