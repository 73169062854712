import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import {
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  Popover,
  Typography,
  Tooltip,
} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState, memo } from "react";
import Draggable from "react-draggable";
import { useTranslation } from "react-i18next";
import { FaAsterisk } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import noDataImage from "../../../../../../assets/icons/no-data.png";
import {
  setCountUnreadMessage,
  checkCountUnMessage,
  logoutChat,
} from "../../../../../../store/actions";
import {
  CLOSE_CHAT_BOX,
  SET_SELECTED_THREAD_ID,
} from "../../../../../../store/types";
import ChatBoxModalBodyContent from "../ChatBoxModalBodyContent";

import {
  ChatBoxModalBody,
  ChatBoxModalContent,
  ChatBoxModalContentHeader,
  ChatBoxModalHeader,
  ChatBoxModalTitle,
  ChatBoxModalWrapper,
  ChatMessageContent,
  ListItemTextStyled,
  ListRoom,
  ListRoomItem,
  ListRoomWrapper,
  MessageBadge,
} from "./styles";

const ChatBoxModal = (props) => {
  const { t } = useTranslation();
  const { isOpenChatModal } = props;
  const [anchorEl, setAnchorEl] = useState(null);
  const dispatch = useDispatch();
  const dataListRoom = useSelector((state) => state.chat.dataListRoom);
  const chatStatus = useSelector((state) => state.chat.chatStatus);
  const minscale = useSelector((state) => state.size.minScale);

  const selectedThreadId = useSelector(
    (state) => state.chat.selectedThreadId ?? ""
  );
  const countMessageUnRead = useSelector(
    (state) => state.chat.countMessageUnRead ?? 0
  );
  const playerId = useSelector((state) => state.user.playerId ?? "");
  const threads = useSelector((state) => state.user.threads ?? []);
  const [roomStatus, setRoomStatus] = useState({});
  const [threadType, setThreadType] = useState("");
  const [displayRoom, setDisplayRoom] = useState("");
  const [threadId, setThreadId] = useState("");
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const a11yProps = (index) => {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  };
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const isRoomShow = () => {
    let rs = false;
    for (let status in roomStatus) {
      if (roomStatus[status] === true) {
        rs = true;
        break;
      }
    }
    return rs;
  };

  const handleChangeRoom = (_, newValue) => {
    dispatch({
      type: SET_SELECTED_THREAD_ID,
      payload: newValue,
    });
  };

  const handleCountUnreadMessage = (_threadId = "") => {
    if (_threadId) {
      setCountUnreadMessage(_threadId, playerId, threads);
    }
  };

  const handleCloseTab = (e, _threadId = "") => {
    e.stopPropagation();
    if (_threadId) {
      let newRoomStatus = { ...roomStatus };
      newRoomStatus[_threadId] = false;
      setRoomStatus(newRoomStatus);
    }
    dispatch({
      type: SET_SELECTED_THREAD_ID,
      payload: "",
    });
  };

  const checkRoomShow = (thread_id = "") => {
    if (!thread_id) return false;
    return roomStatus[thread_id];
  };

  const resetThread = () => {
    setDisplayRoom("");
    setThreadType("");
    setThreadId("");
  };

  useEffect(() => {
    if (dataListRoom && dataListRoom.length > 0) {
      const thread = dataListRoom?.find((f) => f.threadId === selectedThreadId);
      if (thread) {
        const _threadId = thread.threadId ?? "";
        const _displayRoom = thread.displayRoom ?? "";
        const _threadType = thread.threadType ?? "";
        handleCountUnreadMessage(_threadId);
        setDisplayRoom(_displayRoom);
        setThreadType(_threadType);
        setThreadId(_threadId);
      } else {
        resetThread();
      }
    } else {
      resetThread();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedThreadId]);

  useEffect(() => {
    if (threads && threads.length > 0) {
      let _roomStatus = {};
      for (const t_id of threads) {
        _roomStatus[t_id] = true;
      }
      setRoomStatus(_roomStatus);
    }
  }, [threads, isOpenChatModal]);

  useEffect(() => {
    if (selectedThreadId === "" && dataListRoom && dataListRoom.length > 0) {
      dispatch({
        type: SET_SELECTED_THREAD_ID,
        payload: dataListRoom[0]?.threadId,
      });
    }
    // eslint-disable-next-line
  }, [dataListRoom, selectedThreadId]);

  if (isOpenChatModal) {
    return (
      <Draggable>
        <ChatBoxModalWrapper minscale={minscale}>
          <ChatBoxModalHeader minscale={minscale}>
            <ChatBoxModalTitle>
              <div
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="h5"
                  color="white"
                  sx={{
                    fontSize: `${
                      minscale > 0.5 ? `${minscale * 16}px` : `${0.5 * 16}px`
                    }`,
                  }}
                >
                  {t("game.titleChat")}
                </Typography>
                {countMessageUnRead > 0 ? (
                  <MessageBadge minscale={minscale}>
                    {countMessageUnRead}
                  </MessageBadge>
                ) : null}
              </div>
              <IconButton
                aria-label="close-icon"
                onClick={() => {
                  logoutChat(playerId, threads);
                  dispatch({
                    type: CLOSE_CHAT_BOX,
                  });
                }}
                style={{ padding: 0 }}
              >
                <CloseIcon
                  style={{
                    fontSize: `${
                      minscale > 0.5 ? `${minscale * 30}px` : `${0.5 * 30}px`
                    }`,
                    color: "white",
                  }}
                />
              </IconButton>
            </ChatBoxModalTitle>
          </ChatBoxModalHeader>
          {isRoomShow() && dataListRoom && dataListRoom.length > 0 ? (
            <ChatBoxModalBody minscale={minscale}>
              <ChatBoxModalContent>
                <ChatBoxModalContentHeader minscale={minscale}>
                  <ListRoomWrapper>
                    <ListRoom
                      value={selectedThreadId}
                      onChange={handleChangeRoom}
                      variant="scrollable"
                      scrollButtons={false}
                      aria-label="scrollable auto room list"
                    >
                      {dataListRoom &&
                        dataListRoom.length > 0 &&
                        dataListRoom.map((room) => {
                          if (checkRoomShow(room?.threadId)) {
                            return (
                              <ListRoomItem
                                minscale={minscale}
                                value={room?.threadId}
                                label={
                                  <>
                                    {room?.threadId !== threadId &&
                                    checkCountUnMessage(
                                      room?.threadId,
                                      playerId,
                                      chatStatus
                                    ) > 0 ? (
                                      <FaAsterisk
                                        style={{
                                          color: "#ff2d18",
                                          fontSize: `${
                                            minscale > 0.5
                                              ? `${minscale * 12}px`
                                              : `${0.5 * 12}px`
                                          }`,
                                          width: `${
                                            minscale > 0.5
                                              ? `${minscale * 20}px`
                                              : `${0.5 * 20}px`
                                          }`,
                                          marginRight: `${
                                            minscale > 0.5
                                              ? `${minscale * 5}px`
                                              : `${0.5 * 5}px`
                                          }`,
                                        }}
                                      />
                                    ) : null}
                                    {room?.displayRoom.length > 5 ? (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Tooltip
                                          title={room?.displayRoom}
                                          placement="top"
                                        >
                                          <Typography
                                            sx={{
                                              marginRight: `${
                                                minscale > 0.5
                                                  ? `${minscale * 10}px`
                                                  : `${0.5 * 10}px`
                                              }`,
                                            }}
                                          >
                                            {room?.displayRoom}
                                          </Typography>
                                        </Tooltip>
                                        <CloseIcon
                                          style={{
                                            fontSize: `${
                                              minscale > 0.5 ? "12px" : "8px"
                                            }`,
                                          }}
                                          onClick={(e) =>
                                            handleCloseTab(e, room?.threadId)
                                          }
                                        />
                                      </div>
                                    ) : (
                                      <div
                                        style={{
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      >
                                        <Typography
                                          sx={{
                                            marginRight: `${
                                              minscale > 0.5
                                                ? `${minscale * 10}px`
                                                : `${0.5 * 10}px`
                                            }`,
                                          }}
                                        >
                                          {room?.displayRoom}
                                        </Typography>
                                        <CloseIcon
                                          style={{
                                            fontSize: `${
                                              minscale > 0.5 ? "12px" : "8px"
                                            }`,
                                          }}
                                          onClick={(e) =>
                                            handleCloseTab(e, room?.threadId)
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                }
                                iconPosition="end"
                                {...a11yProps(room?.threadId)}
                                key={room?.threadId}
                              ></ListRoomItem>
                            );
                          }
                          return null;
                        })}
                    </ListRoom>
                  </ListRoomWrapper>
                  <div>
                    <IconButton
                      aria-label="read-more"
                      style={{
                        backgroundColor: "white",
                        borderRadius: 0,
                        padding: `${minscale > 0.5 ? `3px` : `1px`}`,
                      }}
                      variant="contained"
                      aria-describedby={id}
                      onClick={handleClick}
                    >
                      <DoubleArrowIcon
                        style={{
                          color: "#412c1d",
                          fontSize: `${
                            minscale > 0.5
                              ? `${minscale * 22}px`
                              : `${0.5 * 22}px`
                          }`,
                        }}
                      />
                    </IconButton>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: "top",
                        horizontal: "right",
                      }}
                      transformOrigin={{
                        vertical: "top",
                        horizontal: "left",
                      }}
                    >
                      <List
                        component="nav"
                        sx={{
                          width: `${
                            minscale > 0.5
                              ? `${minscale * 200}px`
                              : `${0.5 * 200}px`
                          }`,
                          backgroundColor: "#917e70",
                          color: "#fff",
                        }}
                      >
                        {dataListRoom &&
                          dataListRoom.map((room) => {
                            if (checkRoomShow(room?.threadId)) {
                              return (
                                <ListItemButton
                                  onClick={(event) => {
                                    handleChangeRoom(event, room?.threadId);
                                  }}
                                  sx={{
                                    padding: `${
                                      minscale > 0.5
                                        ? `0 ${minscale * 16}px`
                                        : `0 ${0.5 * 16}px`
                                    }`,
                                  }}
                                  key={room?.threadId}
                                >
                                  <ListItemIcon
                                    sx={{
                                      minWidth: `${
                                        minscale > 0.5
                                          ? `${minscale * 30}px`
                                          : `${0.5 * 30}px`
                                      }`,
                                    }}
                                  >
                                    {selectedThreadId === room?.threadId ? (
                                      <DoneIcon
                                        style={{
                                          fontSize: `${
                                            minscale > 0.5
                                              ? `${minscale * 26}px`
                                              : `${0.5 * 26}px`
                                          }`,
                                          color: "#412c1d",
                                        }}
                                      />
                                    ) : null}
                                    {room?.threadId !== threadId &&
                                    checkCountUnMessage(
                                      room?.threadId,
                                      playerId,
                                      chatStatus
                                    ) > 0 ? (
                                      <FaAsterisk
                                        style={{
                                          color: "#ff2d18",
                                          fontSize: `${
                                            minscale > 0.5
                                              ? `${minscale * 12}px`
                                              : `${0.5 * 12}px`
                                          }`,
                                          display: "flex",
                                          alignItems: "center",
                                          justifyContent: "center",
                                          width: "100%",
                                        }}
                                      />
                                    ) : null}
                                  </ListItemIcon>
                                  {room?.displayRoom?.length > 8 ? (
                                    <Tooltip
                                      title={room?.displayRoom}
                                      placement="top"
                                    >
                                      <ListItemTextStyled
                                        minscale={minscale}
                                        primary={room.displayRoom}
                                      />
                                    </Tooltip>
                                  ) : (
                                    <ListItemTextStyled
                                      primary={room.displayRoom}
                                      minscale={minscale}
                                    />
                                  )}
                                </ListItemButton>
                              );
                            }
                          })}
                      </List>
                    </Popover>
                  </div>
                </ChatBoxModalContentHeader>
                <ChatMessageContent minscale={minscale}>
                  {dataListRoom && dataListRoom.length > 0 && (
                    <ChatBoxModalBodyContent
                      threadId={threadId}
                      displayRoom={displayRoom}
                      threadType={threadType}
                    />
                  )}
                </ChatMessageContent>
              </ChatBoxModalContent>
            </ChatBoxModalBody>
          ) : (
            <div style={{ margin: "auto" }}>
              <img src={noDataImage} style={{ width: "90%" }}></img>
              <p
                style={{
                  textAlign: "center",
                  fontSize: `${
                    minscale > 0.5 ? `${minscale * 20}px` : `${0.5 * 20}px`
                  }`,
                  marginTop: `${
                    minscale > 0.5 ? `${minscale * 10}px` : `${0.5 * 10}px`
                  }`,
                }}
              >
                {t("dialog.noData")}
              </p>
            </div>
          )}
        </ChatBoxModalWrapper>
      </Draggable>
    );
  } else {
    return null;
  }
};

ChatBoxModal.propTypes = {
  isOpenChatModal: PropTypes.bool,
};

export default memo(ChatBoxModal);
