import ChangePassDialog from "./ChangePassDialog";
import AdminEditDialog from "./DialogEditAdmin";
import EditInvoice from "./EditInvoice";
import EditUser from "./EditUser";

export default {
  ChangePassDialog,
  AdminEditDialog,
  EditInvoice,
  EditUser,
};
