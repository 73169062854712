import { SET_PLAYER_LIST } from "../types";

const initialState = {
  playerList: [],
};

const statusPlayerReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_PLAYER_LIST: {
      state.playerList = action.playerList;
      return { ...state };
    }
    default:
      return state;
  }
};

export default statusPlayerReducer;
