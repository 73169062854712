import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { stopTutorialGame } from "../../../../store/actions/gameActions";
import { udpateCardSorted } from "../../../../store/actions/playerGameStateActions";
import {
  SCREEN_NARRATION,
  STD_SIZE,
  STEP_NARRATION,
  TURN_STATUS,
} from "../../../../store/constant";
import { sortCards } from "../../../../utils/cards";
import { getRoles } from "../../../../utils/common";
import BasicDialog from "../NewDialog/BasicDialog";
import ButtonComponent from "./components/ButtonComponent";
import SuitComponent from "./components/SuitComponent";
import TextComponent from "./components/TextComponent";
import GChaosCard from "./GChaosCard";
import MyCards from "./MyCards";
import { ButtonContainer, CardsContainer, Container } from "./styles";
import { findMyIndex } from "./processPlayCard";

export const PlayerCardsArea = ({ size }) => {
  const { t } = useTranslation();
  const {
    myState: { handCards },
    gameStatus,
    gameId,
    master,
    turnStatus,
    suit,
  } = useSelector((state) => state.playerGameState);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const { playerId } = useSelector((state) => state.user);
  const { isPlaying, currentPlayersId, tableId } = useSelector(
    (state) => state.room.table
  );
  const [myCards, setMyCards] = useState([]);
  const [isOpenStopGame, setIsOpenStopGame] = useState(false);
  const [orderCards, setOrderCards] = useState();
  const [reRender, setReRender] = useState(Math.random());
  const [isShowMyCards, setIsShowMyCards] = useState(false);
  const [isHideMyCards, setIsHideMyCards] = useState(false);
  const [isHideGChaosCard, setIsHideGChaosCard] = useState(false);
  const narrationShowCard = useSelector(
    (state) => state.narration.narrationScreen
  );
  const [innerSize, setInnerSize] = useState({});

  // SHOW CARD  AFTER STEP 7
  useEffect(() => {
    if (turnStatus === TURN_STATUS.INTRODUCE) {
      setIsHideMyCards(true);
    } else {
      !!turnStatus && setIsHideMyCards(false);
      setIsShowMyCards(true);
    }

    // declare number also hide cards
    if (
      turnStatus === TURN_STATUS.DECLARE_CALL &&
      stepId === STEP_NARRATION.SG_STEP5
    ) {
      setIsHideMyCards(false);
      setIsShowMyCards(false);
    }

    if (
      turnStatus === TURN_STATUS.DECLARE_CALL &&
      stepId !== STEP_NARRATION.SG_STEP5
    ) {
      setIsHideMyCards(false);
      setIsShowMyCards(true);
    }

    // hide card when step 6 and show after 2s
    if (stepId === STEP_NARRATION.SG_STEP6) {
      setIsHideMyCards(false);
      setIsShowMyCards(true);
    }
  }, [turnStatus, stepId]);

  useEffect(() => {
    if (narrationShowCard === SCREEN_NARRATION.SG_STEP3_DEAL_CARDS) {
      setIsHideMyCards(false);
      setIsShowMyCards(false);
    }
    if (narrationShowCard === SCREEN_NARRATION.SG_STEP5_SHOW_DEAL_CARDS) {
      setIsShowMyCards(true);
    }
  }, [narrationShowCard]);

  useEffect(() => {
    let cards = handCards?.filter((card) => card != null);
    let showCards = cards;

    if (orderCards && orderCards?.length > 0) {
      showCards = orderCards?.filter(
        (card) =>
          -1 !==
          cards?.findIndex(
            (handCard) =>
              handCard.suit === card?.suit && handCard?.value === card?.value
          )
      );
    }

    setMyCards(showCards);
    setReRender(Math.random());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handCards]);

  const handleSortCards = async () => {
    const myCardsOrder = sortCards(myCards);
    await udpateCardSorted(playerId);
    setOrderCards(myCardsOrder);
    setMyCards(myCardsOrder);
    setReRender(Math.random());
  };

  useEffect(() => {
    if (!isPlaying) {
      setOrderCards([]);
    }
  }, [isPlaying, gameStatus]);
  const handleStopGame = async () => {
    setIsOpenStopGame(false);
    await stopTutorialGame({
      gameId,
      tableId,
      ids: currentPlayersId,
      roles: getRoles(currentPlayersId, master),
    });
  };

  useEffect(() => {
    if (handCards) {
      const _isHideGChaosCard =
        handCards.filter((c) => c !== null).length === 0;
      if (_isHideGChaosCard) {
        setIsHideGChaosCard(true);
      } else {
        setIsHideGChaosCard(false);
      }
    }
  }, [handCards]);

  useEffect(() => {
    if (size) {
      const textFontSize = 16 * size?.minScale;
      const textPadding = 8 * size?.minScale;
      const minScale = size?.minScale;
      const height = size?.hShipHeight;
      const myCardTopMargin = size.shipSpace;
      setInnerSize({
        textFontSize,
        textPadding,
        minScale,
        height,
        myCardTopMargin,
      });
    } else {
      const textFontSize = 16 * 1;
      const textPadding = 8 * 1;
      const minScale = 1;
      const height = STD_SIZE.STD_SHIP_HEIGHT;
      const myCardTopMargin = 20;
      setInnerSize({
        textFontSize,
        textPadding,
        minScale,
        height,
        myCardTopMargin,
      });
    }
  }, [size]);

  return (
    <Container
      id="PlayerCards"
      height={innerSize.height}
      margin={innerSize.myCardTopMargin}
    >
      <ButtonContainer padding={innerSize.textPadding}>
        <TextComponent
          fontSize={innerSize.textFontSize}
          padding={innerSize.textPadding}
        >
          <p>{t("game.yourCard1")}</p>
          <p>{t("game.yourCard2")}</p>
        </TextComponent>
        <div
          style={{
            height: "100%",
            display: "flex",
            flex: 1,
            alignItems: "center",
            justifyContent: "space-around",
            padding: 0,
          }}
        >
          {handCards?.length === 0 ? (
            <SuitComponent suit={""} />
          ) : (
            <SuitComponent suit={suit} />
          )}
          {/* <SuitComponent suit={suit} /> */}

          {findMyIndex(currentPlayersId, playerId) !== 3 ? (
            <ButtonComponent
              onClick={() => handleSortCards()}
              scale={innerSize.minScale}
            >
              {t("game.autoSort")}
            </ButtonComponent>
          ) : (
            <div style={{ flex: 1 }}></div>
          )}
        </div>
      </ButtonContainer>
      <CardsContainer id="CardsContainer">
        {/* <MyCardLock show={isLock} /> */}
        {/* G-CHAOS IS PLAYER HAND CARDS */}
        {gameStatus !== "" &&
          !isHideGChaosCard &&
          currentPlayersId &&
          currentPlayersId.indexOf(playerId) === 3 && (
            <GChaosCard
              size={size}
              onOrderCard={(arraySortCard) => {
                setOrderCards(arraySortCard);
              }}
            />
          )}

        {/* PLAYER HAND CARDS */}
        {gameStatus !== "" &&
          currentPlayersId &&
          currentPlayersId.indexOf(playerId) !== 3 && (
            <MyCards
              myCards={myCards}
              key={reRender}
              onOrderCard={(arraySortCard) => {
                setOrderCards(arraySortCard);
              }}
              hidden={isHideMyCards}
              show={isShowMyCards}
              size={size}
            />
          )}
      </CardsContainer>
      <BasicDialog
        isOpen={isOpenStopGame}
        agreeText={t("game.continueGame")}
        closeText={t("game.stop")}
        onCancel={() => handleStopGame()}
        onAgree={() => setIsOpenStopGame(false)}
        mainText={t("game.stopTutorial")}
        onCloseDialog={() => setIsOpenStopGame(false)}
      />
    </Container>
  );
};

PlayerCardsArea.propTypes = {
  size: PropTypes.any,
};
