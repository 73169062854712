import {
  Button as MuiButton,
  // Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RounedDialog from "../../pages/GameScreen/components/Dialog/RounedDialog";

const DialogForgotPassword = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <RounedDialog
      isOpen={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ padding: "2em 0 0 2em", textAlign: "center" }}
      >
        {t("forgotPassword.resetPassword")}
      </DialogTitle>
      <DialogContent style={{ padding: "1em 2em" }}>
        <DialogContentText id="alert-dialog-description">
          {t("forgotPassword.emailSent")}
        </DialogContentText>
      </DialogContent>
      <DialogActions style={{ padding: "1em 2em" }}>
        <MuiButton onClick={onClose} className="button-style-1__outline">
          {t("tables.close")}
        </MuiButton>
      </DialogActions>
    </RounedDialog>
  );
};

DialogForgotPassword.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DialogForgotPassword;
