import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
  root: {
    boxSizing: "border-box",
    width: "100%",
    height: "100%",
    overflow: "hidden",
  },
  top: {
    width: "100%",
    display: "flex",
    flexDirection: "row-reverse",
    boxSizing: "border-box",
  },
  listPanel: {
    height: "calc(100%)",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    padding: 0,
    paddingTop: 5,
  },
  bottom: {
    width: "100%",
    height: "calc(100% - 30px)",
    marginTop: "10px",
    borderRadius: "10px",
    backgroundColor: "white",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  closeButton: {
    color: "white",
    width: 20,
    height: 20,
  },
  boxTabs: {
    width: "100%",
    borderBottom: 1,
    borderColor: "divider",
  },
  label: {
    width: (props) => `${Math.round(props.drawerwidth / 3 - 10)}px`,
    fontSize: (props) =>
      `${props.minScale < 0.5 ? 9 : Math.round(props.minScale * 16)}px`,
    textAlign: "center",
    boxSizing: "border-box",
  },
  closeImg: {
    width: "18px",
  },
  iconLabel: {
    width: 115,
    height: 50,
  },
  tab: {
    backgroundColor: "#917e70",
    opacity: 1,
    "&.Mui-selected": {
      backgroundColor: "#412c1d",
    },
  },
  flexContainer: {
    justifyContent: "space-evenly",
  },
}));
