import { Avatar, Badge, Box, Fab } from "@material-ui/core";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { Delete } from "@material-ui/icons";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import PropTypes from "prop-types";
import { createRef, useState } from "react";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    justifyContent: "center",
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
    border: "1px solid #e0e0e0",
    margin: theme.spacing(1),
  },
  buttonUpload: {
    textTransform: "none",
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(2),
  },
}));
const CustomeFab = withStyles((theme) => ({
  root: {
    width: 35,
    height: 35,
    border: `2px solid ${theme.palette.background.paper}`,
  },
}))(Fab);
const CustomBadge = withStyles(() => ({
  anchorOriginBottomRightCircular: {
    bottom: "26%",
  },
}))(Badge);

function AvatarUpload({ imageProp, setImageProp, avatar, name, id }) {
  const classes = useStyles();
  const [image, _setImage] = useState(null);
  const inputFileRef = createRef(null);

  const cleanup = () => {
    URL.revokeObjectURL(image);
    URL.revokeObjectURL(imageProp);
    inputFileRef.current.value = null;
    setImageProp(null);
  };

  const setImage = (newImage) => {
    if (image) {
      cleanup();
    }
    _setImage(newImage);
  };

  const handleOnChange = (event) => {
    const newImage = event.target.files[0];
    if (newImage) {
      setImage(URL.createObjectURL(newImage));
      setImageProp(event.target.files[0]);
    }
  };

  const handleClick = (event) => {
    if (image) {
      event.preventDefault();
      setImage(null);
    }
  };

  return (
    <Box className={classes.root}>
      <CustomBadge
        overlap="circular"
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        badgeContent={
          <CustomeFab size="small" aria-label="add">
            {image ? (
              <Delete onClick={handleClick} />
            ) : (
              <AddPhotoAlternateIcon
                htmlFor={id}
                onClick={() => {
                  inputFileRef.current.click();
                }}
              />
            )}
          </CustomeFab>
        }
      >
        <Avatar
          className={classes.avatar}
          alt="Avatar"
          src={image ? image : avatar}
          style={{
            position: "relative",
          }}
        ></Avatar>
      </CustomBadge>

      <input
        ref={inputFileRef}
        accept="image/*"
        hidden
        id={id}
        type="file"
        name={name}
        onChange={handleOnChange}
      />
    </Box>
  );
}

AvatarUpload.propTypes = {
  imageProp: PropTypes.object,
  setImageProp: PropTypes.func,
  avatar: PropTypes.string,
  name: PropTypes.string,
  id: PropTypes.string,
};

export default AvatarUpload;
