import { Dialog } from "@material-ui/core";
import useOnlineStatus from "@rehooks/online-status";
import { clamp } from "lodash";
import swap from "lodash-move";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useSprings } from "react-spring";
import { useDrag } from "react-use-gesture";
import BackDrop from "../../../../components/Backdrop";
import firebase from "../../../../services/firebase";
import {
  getTurnOfPlayer,
  playerPlayCard,
} from "../../../../store/actions/playerGameStateActions";
import { playCard } from "../../../../store/actions/tableActions";
import { CARD_SUIT, STD_SIZE, TURN_STATUS } from "../../../../store/constant";
import {
  ADD_INSTRUCTOR_MESSAGE,
  PLAY_CARD_LOCAL,
  DOUBLE_CLICK_HAND_CARD,
} from "../../../../store/types";
import { sleep } from "../../../../utils/common";
import MessDialog from "./../../components/NewDialog/MessDialog";
import MyCardComponent from "./components/MyCardComponent";
import {
  addMessageNoHaveSuit,
  checkNoHaveSuit,
  isCaptain,
  isG0Card,
  isGCard,
  isGChaosPlayer,
  isHaveSuitInHandCards,
  isPlayCard,
  notifyMessageCardInvalid,
} from "./processPlayCard";
import SelectSuitGCard from "./SelectSuitGCard";
import { AnimatedContainer } from "./styles";
import OkDialog from "../../../../pages/GameScreen/components/NewDialog/OkDialog";
let connectedRef = firebase.database().ref(".info/connected");

const MyCards = ({
  myCards,
  onOrderCard,
  hidden = false,
  show = false,
  size,
}) => {
  const playerId = useSelector((state) => state.user.playerId);
  const nickName = useSelector((state) => state.user.nickName);

  const pgsGrandCross = useSelector(
    (state) => state.playerGameState.grandCross
  );
  const pgsMyState = useSelector((state) => state.playerGameState.myState);
  const pgsOtherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const pgsIsMyTurn = useSelector((state) => state.playerGameState.isMyTurn);
  const pgsSuit = useSelector((state) => state.playerGameState.suit);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  const turnStatus = useSelector((state) => state.playerGameState.turnStatus);
  const canPlayCard = useSelector((state) => state.playerGameState.canPlayCard);
  const currentRound = useSelector(
    (state) => state.playerGameState.currentRound
  );
  const pgsPlayCardLocal = useSelector(
    (state) => state.playerGameState.playCardLocal
  );
  const doubleClickCard = useSelector(
    (state) => state.playerGameState.doubleClickCard
  );

  const tableId = useSelector((state) => state.room.table.tableId);
  const numberOfGame = useSelector((state) => state.room.table.numberOfGame);

  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);
  const roomId = useSelector((state) => state.room.roomId);
  const playCardFail = useSelector(
    (state) => state.playerGameState.playCardFail
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [innerSize, setInnerSize] = useState({});
  const [mess, setMess] = useState("");
  const [gCard, setGCard] = useState(null);
  const intervalRef = useRef(null);
  const interval1sRef = useRef(null);
  const responseRef = useRef(null);
  const statusRef = useRef(null);
  const loadingRef = useRef(false);
  const counterInterval = useRef(0);
  const onlineStatus = useOnlineStatus();
  const [counter, setCounter] = useState(0);
  let doubleClickHandCard = useRef(null);
  let countRePlay = 0;
  let countToReload = 0;

  /** SPRINGS */
  const fn =
    (cards, active = false, originalIndex = 0, currentIndex = 0, x = 0) =>
    (index) => {
      return active && index === originalIndex
        ? {
            x: currentIndex * Math.round(size?.cardWidth * 0.35) + x,
            zIndex: 14,
            immediate: true,
          }
        : {
            x: cards.indexOf(index) * Math.round(size?.cardWidth * 0.35),
            zIndex: cards.indexOf(index),
            immediate: true,
          };
    };

  const order = useRef([...Array(13).keys()]);
  const [springs, api] = useSprings(myCards.length, fn(order.current));

  const bind = useDrag(({ args: [originalIndex], active, movement: [x] }) => {
    const currentIndex = order.current.indexOf(originalIndex);
    const currentRow = clamp(
      Math.round(
        (currentIndex * Math.round(size?.cardWidth * 0.35) + x) /
          Math.round(size?.cardWidth * 0.35)
      ),
      0,
      myCards.length - 1
    );
    const newOrder = swap(order.current, currentIndex, currentRow);
    api.start(fn(newOrder, active, originalIndex, currentIndex, x));
    if (!active) {
      order.current = newOrder;
      updateOrderCard(newOrder);
    }
  });

  const updateOrderCard = (orderMyCards) => {
    if (!myCards || !orderMyCards) return;
    const sortedCard = [];
    myCards.forEach((_, idx) => {
      sortedCard.push(myCards[orderMyCards[idx]]);
    });
    onOrderCard(sortedCard);
  };

  useEffect(() => {
    if (pgsPlayCardLocal === null) return;
    const playCardWhenCanPlay = async () => {
      if (canPlayCard === true && pgsPlayCardLocal !== null) {
        const data = {
          card: pgsPlayCardLocal.card,
          tableId: pgsPlayCardLocal.tableId,
          playerId: pgsPlayCardLocal.playerId,
          gameId: pgsPlayCardLocal.gameId,
          newSuit: pgsPlayCardLocal.suit,
          playerGChaos: false,
        };
        const res = await playCard(data);
        console.log("play card success", res);
        dispatch({
          type: PLAY_CARD_LOCAL,
          payload: null,
        });
        dispatch({
          type: DOUBLE_CLICK_HAND_CARD,
          payload: null,
        });
      }
    };
    playCardWhenCanPlay();
  }, [canPlayCard, dispatch, pgsPlayCardLocal]);

  useEffect(() => {
    api.start(fn(order.current));
    if (size) {
      const dialogWidth = Math.round(size.hShipWidth * 2);
      const dialogHeight = Math.round(dialogWidth / 2);
      const cardWidth = size.cardWidth;
      const cardHeight = size.cardHeight;
      const cardXTranslate = Math.round(size.cardWidth * 0.35);
      setInnerSize({
        cardXTranslate,
        dialogWidth,
        dialogHeight,
        cardWidth,
        cardHeight,
      });
    } else {
      const cardXTranslate = Math.round(STD_SIZE.STD_CARD_WIDTH * 0.35);
      const dialogWidth = STD_SIZE.STD_SHIP_WIDTH * 2;
      const dialogHeight = Math.round(dialogWidth / 2);
      const cardWidth = STD_SIZE.STD_CARD_WIDTH;
      const cardHeight = STD_SIZE.STD_CARD_HEIGHT;
      setInnerSize({
        cardXTranslate,
        dialogWidth,
        dialogHeight,
        cardWidth,
        cardHeight,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const notifyNotYourTurn = () => {
    if (pgsIsMyTurn) return;
    const turnPlayer = turnOf();
    if (!turnPlayer) return;
    const mess = t("chatNotice.turnOf", { name: turnPlayer.nickName });
    dispatch({
      type: ADD_INSTRUCTOR_MESSAGE,
      payload: { message: mess },
    });
  };

  useEffect(() => {
    const hideMessage = async () => {
      if (mess === "") return;
      await sleep(2000);
      setMess("");
    };
    hideMessage();
  }, [mess]);

  /**
   * Check if the player is the turn of player
   * @returns {object} player
   */
  const turnOf = () => {
    try {
      const index = pgsOtherPlayersState.findIndex(
        (otherPlayer) => otherPlayer && otherPlayer.isYourTurn
      );
      if (-1 === index || (joinedPlayers && 0 === joinedPlayers.length)) {
        return null;
      }
      return joinedPlayers[index];
    } catch (error) {
      console.log("turnOf error", error);
    }
  };

  const playGCard = async (suit) => {
    try {
      const isBlockPlayCard = !gCard || !suit;
      if (isBlockPlayCard) return;

      const card = { ...gCard };
      setGCard(null);
      dispatch(playerPlayCard(card, myIndex));

      const data = {
        card: card,
        tableId: tableId,
        playerId: playerId,
        gameId: gameId,
        newSuit: suit,
        playerGChaos: false,
      };
      const res = await playCard(data);
      console.log("play G card success", res);
    } catch (error) {
      console.log("play G card error", error);
    }
  };

  const clearRecheckConnection = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    counterInterval.current = 0;
  };

  const clearInterval1s = () => {
    clearInterval(interval1sRef.current);
    interval1sRef.current = null;
  };

  const checkConnection = () => {
    let status = true;
    if (!onlineStatus) {
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          clearRecheckConnection();
          status = true;
        } else {
          console.log(`offline`);
          status = false;
        }
      });
    } else {
      clearRecheckConnection();
    }
    return status;
  };

  const onDoubleClickImg = async (e, cardIndex) => {
    e.preventDefault();

    const isNotPlayCardTurn = ![
      TURN_STATUS.PLAY_CARD,
      TURN_STATUS.CAPTAIN,
    ].includes(turnStatus);

    const isBlockPlayCard =
      isNotPlayCardTurn ||
      !pgsIsMyTurn ||
      currentRound === 12 ||
      !getTurnOfPlayer(pgsOtherPlayersState, pgsIsMyTurn) ||
      isPlayCard(myIndex, pgsGrandCross);

    if (isBlockPlayCard) return;
    notifyNotYourTurn();

    // when caption play G_Card please show dialog select suit
    const card = myCards[cardIndex];
    const isSetGCard =
      isCaptain(turnStatus) &&
      isGCard(card) &&
      !isGChaosPlayer(nickName, myIndex);
    if (isSetGCard) {
      setGCard(card);
      return;
    }

    //  except captain, The card have to same suit with round,  or what ever
    const isSetMessage =
      !isCaptain(turnStatus) &&
      CARD_SUIT.WHATEVER !== pgsSuit &&
      isHaveSuitInHandCards(pgsMyState.handCards, pgsSuit) &&
      card.suit !== pgsSuit &&
      !isG0Card(card) &&
      !isGChaosPlayer(nickName, myIndex);
    if (isSetMessage) {
      setMess(notifyMessageCardInvalid(t, pgsSuit));
      return;
    }

    const interval1S = () => {
      console.log("interval--1S");
      if (countRePlay > 60) {
        countToReload += 5;
        dispatch({
          type: "PLAY_CARD_FAIL",
          payload: true,
        });

        if (countToReload > 5) {
          location.reload();
        }
      }
      interval1sRef.current = setInterval(async () => {
        statusRef.current = await checkConnection();
        if (statusRef.current === true) {
          loadingRef.current = false;
          setCounter(0);
          clearInterval1s();
          interval5s();
        } else {
          setCounter(counter + 1);
          loadingRef.current = true;
        }
      }, 1000);
    };

    const interval5s = async () => {
      console.log("interval--5S");
      const newSuit = card.suit;
      const data = {
        card: card,
        tableId: tableId,
        playerId: playerId,
        gameId: gameId,
        newSuit: newSuit,
        playerGChaos: false,
      };
      responseRef.current = await playCard(data);
      intervalRef.current = setInterval(async () => {
        ++counterInterval.current;
        if (
          responseRef.current &&
          responseRef.current.data &&
          responseRef.current.data.status === 200
        ) {
          clearRecheckConnection();
          loadingRef.current = false;
          setCounter(0);
          countRePlay = 0;
        } else {
          countRePlay += 5;
          setCounter(counter + 1);
          loadingRef.current = true;
          interval1S();
        }
      }, 5000);
    };

    if (canPlayCard === true) {
      if (doubleClickCard) {
        setTimeout(() => {
          dispatch({
            type: DOUBLE_CLICK_HAND_CARD,
            payload: null,
          });
        }, 1000);
        return;
      }
      statusRef.current = await checkConnection();

      if (statusRef.current === true) {
        interval5s();
      } else {
        setCounter(counter + 1);
        loadingRef.current = true;
        interval1S();
      }
      // Update card in grandCross before  send to server
      dispatch(playerPlayCard(card, myIndex));

      if (
        checkNoHaveSuit(pgsSuit, turnStatus, card) &&
        !isGChaosPlayer(nickName, myIndex)
      ) {
        addMessageNoHaveSuit(dispatch, pgsSuit);
      }
    } else {
      if (doubleClickCard) return;
      // Update card in grandCross before  send to server
      dispatch(playerPlayCard(card, myIndex));
      dispatch({
        type: PLAY_CARD_LOCAL,
        payload: {
          numberOfGame,
          roomId,
          card,
          playerId,
          gameId,
          tableId,
          nickName,
        },
      });
      if (
        checkNoHaveSuit(pgsSuit, turnStatus, card) &&
        !isGChaosPlayer(nickName, myIndex)
      ) {
        addMessageNoHaveSuit(dispatch, pgsSuit);
      }
    }
  };

  return (
    <div
      style={{
        flex: 1,
        position: "relative",
        justifyContent: "center",
        alignItems: "center",
        height: "70%",
      }}
    >
      <BackDrop open={loadingRef.current} />
      <OkDialog
        isOpen={playCardFail}
        onAgree={() => {
          dispatch({
            type: "PLAY_CARD_FAIL",
            payload: false,
          });
        }}
        confirmText={t("reload.reloadNetworkNG", { countdown: 5 })}
        btnLabel={"OK"}
      />
      {springs.map(({ zIndex, x }, idx) => (
        <AnimatedContainer
          {...bind(idx)}
          key={`card:${idx}`}
          style={{
            zIndex,
            x,
          }}
        >
          {!hidden && (
            <MyCardComponent
              src={
                show
                  ? `/cards/${myCards[idx]?.suit}${myCards[idx]?.value}.jpg`
                  : `/cards/back.png`
              }
              onDoubleClick={(e) => {
                if (doubleClickHandCard.current) {
                  clearTimeout(doubleClickHandCard.current);
                }
                doubleClickHandCard.current = setTimeout(() => {
                  onDoubleClickImg(e, idx);
                }, 500);
              }}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              height={size?.cardHeight}
              width={size?.cardWidth}
            ></MyCardComponent>
          )}
        </AnimatedContainer>
      ))}
      <MessDialog isOpen={mess !== ""} mainText={mess} />

      <Dialog
        open={!!gCard}
        // open={true}
        onClose={() => {
          setGCard(null);
        }}
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "white",
            width: innerSize.dialogWidth,
            height: innerSize.dialogHeight,
            overflow: "hidden",
          },
        }}
      >
        <SelectSuitGCard
          onSelectCard={playGCard}
          width={innerSize.dialogWidth}
          height={innerSize.dialogHeight}
        ></SelectSuitGCard>
      </Dialog>
    </div>
  );
};
MyCards.propTypes = {
  myCards: PropTypes.array,
  onOrderCard: PropTypes.func,
  hidden: PropTypes.bool,
  show: PropTypes.bool,
  size: PropTypes.any,
};
export default MyCards;
