/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable no-unused-vars */
import React, { useCallback, useState } from "react";
import PropTypes from "prop-types";
import { Box, Container, Tab, Tabs } from "@material-ui/core";
import _useStyles from "./styles";
import ChangePlan from "./ChangePlan";
import RetailPackages from "./RetailPackages";
import { useTranslation } from "react-i18next";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";

const index = ({ goBack, handleChangeValue }) => {
  const classes = _useStyles({});
  const { t } = useTranslation();
  const isLoading = useSelector((state) => state.payment.isLoading);
  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        <Box className={classes.content}>
          <Backdrop
            style={{ zIndex: 9999, color: "#fff", position: "absolute" }}
            open={isLoading}
          >
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box className={classes.TabsWrapp}>
            <Tabs
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              classes={{
                indicator: classes.indicator,
                flexContainer: classes.flexContainer,
              }}
              isPlayTimeFree={playTimeInfo?.statusFree}
            >
              <Tab
                label={t("paymentSettings.changePlanTitle")}
                className={classes.singleTab}
              ></Tab>
              {!playTimeInfo?.statusFree && (
                <Tab
                  label={t("paymentSettings.addPlayCount")}
                  className={classes.singleTab}
                ></Tab>
              )}
            </Tabs>
          </Box>
          <Box>
            {value === 0 && (
              <ChangePlan
                goBack={goBack}
                handleChangeValue={handleChangeValue}
              />
            )}
            {value === 1 && (
              <RetailPackages
                goBack={goBack}
                handleChangeValue={handleChangeValue}
              />
            )}
          </Box>
        </Box>
      </Box>
    </Container>
  );
};

index.propTypes = {
  goBack: PropTypes.func,
  handleChangeValue: PropTypes.func,
};

export default index;
