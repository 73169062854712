import React from "react";
import PropTypes from "prop-types";
import { Dialog } from "@material-ui/core";
import "./RoundedDialog.css";

const RoundedDialog = (props) => {
  const { isOpen, onClose, PaperComponent, children } = props;
  return (
    <Dialog
      open={isOpen}
      className="rounded-dialog"
      onClose={onClose}
      PaperComponent={PaperComponent}
    >
      {children}
    </Dialog>
  );
};

RoundedDialog.propTypes = {
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  onClose: PropTypes.func,
  PaperComponent: PropTypes.any,
};

export default React.memo(RoundedDialog);
