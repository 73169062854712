import { isEqual } from "lodash";
import firebase, { getFunctions } from "../../services/firebase";
import { notifyRoom } from "../../utils/common";
import {
  addMemberToThread,
  addThreadInPlayerThreads,
  removeMemberInThread,
  removeThreadInPlayerThreads,
} from "../actions/chat";
import {
  CONFIRMS,
  DELAYS,
  PLAYER_CONFIRMED,
  PLAYER_CONFIRMS,
  UPDATE_NEW_SUIT,
} from "../constant";
import {
  CHANGE_POSITION_FUNC,
  CLEAR_CONFIRM_START_GAME_NO_CALL,
  CONFIRM_START_GAME_NO_CALL,
  JOIN_ROOM_FUNC,
  LEAVE_ROOM_FUNC,
  LEAVE_ROOM_WHILE_PLAYING,
  REMOVE_FIRST_NOTIFICATION,
} from "../funcs";
import RoomRepo from "../repositories/room";
import ChatRepo from "../repositories/chat";
import {
  CLEAR_NARRATION,
  OTHER_PROFILE,
  ROOM_PLAYER_OVER_GET,
  SET_JOIN_TABLE,
  SET_ROOM,
} from "../types";
const db = firebase.firestore();
const roomRef = db.collection("rooms");
const tableRef = db.collection("tables");
const functions = getFunctions();
// const RETRY_COUNT = 9;
let countTime = 0;
let isLogged = false;
let isCreated = false;

export const leaveRoom = async (
  roomId,
  playerId,
  nextOwnerId,
  playerProfiles
) => {
  localStorage.removeItem("gameUrl");
  try {
    let GChaosId = "";
    playerProfiles.forEach((item) => {
      if (item && item.playerId && item.guestName === "G-Chaos") {
        GChaosId = item.playerId;
      }
    });
    const data = {
      roomId: roomId,
      playerIdLeave: playerId,
      nextOwnerId: nextOwnerId,
      GChaosId,
    };

    const leaveRoomFunc = functions.httpsCallable(LEAVE_ROOM_FUNC);

    //Check to delete Chat
    const room = await RoomRepo.read(roomId);
    // let countPlayer = 0;
    // room.joinedPlayers.forEach((item) => {
    //   if (item !== null) {
    //     countPlayer += 1;
    //   }
    // });
    const resData = await leaveRoomFunc(data);
    console.log("resData", resData);
    if (!resData.data) {
      await removeThreadInPlayerThreads([playerId], room.threadId);
      await removeMemberInThread(room.threadId, playerId);
      await ChatRepo.deleteDocument(room.threadId);
      return;
    }
    await removeThreadInPlayerThreads([playerId], room.threadId);
    await removeMemberInThread(room.threadId, playerId);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const join = async (record, player, accessToken) => {
  try {
    // clear playerGameState here
    const { playerId, fullName, avatar, nickName } = player;
    const { roomId, tableId } = record;
    const playerData = {
      playerId: playerId,
      fullName: fullName,
      avatar: avatar,
      nickName: nickName,
    };
    const roomData = {
      playerId: playerId,
      roomId: roomId,
      tableId: tableId,
      player: playerData,
      accessToken,
    };
    const room = await RoomRepo.read(roomId);
    await addThreadInPlayerThreads([playerId], room.threadId);
    const member = {
      memberId: playerId,
      memberName: nickName,
      memberAvatar: avatar,
      isReading: false,
      countUnreadMessage: 0,
    };
    await addMemberToThread(room.threadId, [member]);
    const joinRoomFunc = functions.httpsCallable(JOIN_ROOM_FUNC);
    return await joinRoomFunc(roomData);
  } catch (error) {
    console.log(error);
  }
};

export const joinRequest = async (record, player) => {
  try {
    const playerData = {
      playerId: player.playerId,
      fullName: player.fullName,
      avatar: player.avatar,
      nickName: player.nickName,
    };
    const roomData = {
      playerId: player.playerId,
      roomId: record.roomId,
      tableId: record.tableId,
      player: playerData,
      isOwnerAcceptJoin: record.joinTableRole === "open" ? true : false,
    };
    const room = functions.httpsCallable("sendRequestJoinRoom");
    return await room(roomData);
  } catch (error) {
    console.log(error);
  }
};

export const subscribeRoom = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    try {
      const { playerId } = getState().user;
      const { currentRoomJoinedId } = getState().user;
      const { owner } = getState().room.table;
      if (
        currentRoomJoinedId &&
        currentRoomJoinedId !== "" &&
        playerId &&
        owner
      ) {
        const unsubscribe = roomRef
          .doc(currentRoomJoinedId)
          .onSnapshot(async (snapshot) => {
            const roomData = snapshot.data();
            const playersInRoom = roomData?.joinedPlayers || [];
            const indexOfPlayer = playersInRoom.findIndex(
              (p) => null !== p && p.playerId === playerId
            );

            if (indexOfPlayer !== -1) {
              playersInRoom.forEach((p, idx) => {
                const iPosition = (indexOfPlayer + idx) % 4;
                const playerPosition = {
                  ...playersInRoom[iPosition],
                };
                if (playerPosition) {
                  playerPosition["index"] = iPosition;
                  let guestName = "";
                  if (iPosition === 3) guestName = "G-Chaos";

                  if (iPosition !== 3)
                    guestName = "G's GUEST " + (iPosition + 1);

                  playerPosition["guestName"] = guestName;
                  playerPosition["isOwner"] =
                    playerPosition["playerId"] === owner;
                  dispatch({
                    type: OTHER_PROFILE,
                    payload: {
                      [`profile${idx}`]: playerPosition,
                    },
                  });
                }
              });
            }
            if (snapshot.exists) {
              notifyRoom(getState().room, roomData, dispatch);
              dispatch({
                type: SET_ROOM,
                payload: { ...roomData, roomId: snapshot.id },
              });
            }
          });
        setUnsubscribe(unsubscribe);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

let retryInterval = null;
let currentConfirmData = {};
// let count = 0;

function clearRetry() {
  clearInterval(retryInterval);
  retryInterval = null;
  // count = 0;
}
/**
 * retry confirm player
 * @returns
 */
// async function checkRetry() {
//   try {
//     const tableId = currentConfirmData.tableId ?? "";
//     if (!tableId) {
//       clearRetry();
//       return;
//     }
//     const tableDoc = await tableRef.doc(tableId).get();
//     if (!tableDoc.exists) {
//       clearRetry();
//       return;
//     }
//     if (!tableDoc.data().isPlaying) {
//       clearRetry();
//       return;
//     }

//     const confirmedRef = await roomRef
//       .doc(currentConfirmData.roomId)
//       .collection(PLAYER_CONFIRMED)
//       .doc(currentConfirmData?.type)
//       .get();
//     if (confirmedRef.exists) {
//       clearRetry();
//       return;
//     }

//     const doc = await roomRef
//       .doc(currentConfirmData.roomId)
//       .collection(PLAYER_CONFIRMS)
//       .doc(currentConfirmData.playerId)
//       .get();

//     if (!doc.exists || !isEqual(doc, currentConfirmData)) {
//       await roomRef
//         .doc(currentConfirmData.roomId)
//         .collection(PLAYER_CONFIRMS)
//         .doc(currentConfirmData.playerId)
//         .set(currentConfirmData);
//       count += 1;
//       if (count >= RETRY_COUNT) {
//         clearRetry();
//       }
//     }
//   } catch (error) {
//     console.error("checkRetry error", error);
//   }
// }
// retry to create playerConfirms sub documents
async function retryCreatePlayerConfirm(delay, data) {
  try {
    const tableId = currentConfirmData.tableId ?? "";
    if (!tableId) {
      clearRetry();
      return;
    }
    const tableDoc = await tableRef.doc(tableId).get();
    if (!tableDoc.exists) {
      clearRetry();
      return;
    }
    if (!tableDoc.data().isPlaying) {
      clearRetry();
      return;
    }

    const confirmedRef = await roomRef
      .doc(currentConfirmData.roomId)
      .collection(PLAYER_CONFIRMED)
      .doc(currentConfirmData?.type)
      .get();
    if (confirmedRef.exists) {
      clearRetry();
      return;
    }

    const doc = await roomRef
      .doc(currentConfirmData.roomId)
      .collection(PLAYER_CONFIRMS)
      .doc(currentConfirmData.playerId)
      .get();

    countTime += delay;

    if (
      doc.data() === undefined ||
      !doc.exists ||
      (doc.exists && !isEqual(doc.data().type, currentConfirmData.type))
    ) {
      await roomRef
        .doc(currentConfirmData.roomId)
        .collection(PLAYER_CONFIRMS)
        .doc(currentConfirmData.playerId)
        .set(currentConfirmData)
        .then(() => {
          // success
          clearRetry();
          countTime = 0;
          isLogged = false;
        });
    } else {
      countTime = 0;
    }
    console.log("Count Time: ", countTime);

    //If over 1 minute player not confirm, call function to log info
    if (countTime >= 60 && countTime < 120) {
      if (!isLogged) {
        let countDoc = 0;

        let confirmedPlayerIds = [];
        for (const playerId of data.playerIds) {
          const confirmDoc = await roomRef
            .doc(currentConfirmData.roomId)
            .collection(PLAYER_CONFIRMS)
            .doc(playerId)
            .get();
          if (confirmDoc.exists) {
            countDoc += 1;
            confirmedPlayerIds.push(confirmDoc.data().playerId);
          }
        }
        if (countDoc < 4) {
          const newData = {
            ...data,
            overMinus: 1,
            confirmedPlayerIds,
          };
          await functions.httpsCallable("confirmLogging")(newData);
          isLogged = true;
        }
      }
    } else if (countTime >= 120 && isLogged) {
      console.log("Over 2 minutes: ");
      let countDoc = 0;
      let confirmedPlayerIds = [];
      for (const playerId of data.playerIds) {
        const confirmDoc = await roomRef
          .doc(currentConfirmData.roomId)
          .collection(PLAYER_CONFIRMS)
          .doc(playerId)
          .get();
        if (confirmDoc.exists) {
          countDoc += 1;
          confirmedPlayerIds.push(confirmDoc.data().playerId);
        }
      }
      if (countDoc < 4) {
        const newData = {
          ...data,
          overMinus: 2,
          confirmedPlayerIds,
        };
        await functions.httpsCallable("confirmLogging")(newData);
        isLogged = false;
      }
    }
  } catch (error) {
    console.error("retryCreatePlayerConfirm error", error);
  }
}

/**
 * Confirm type
 * @param {*} param0
 * @returns
 */
export async function playerConfirm({
  roomId,
  playerId,
  playerIds = [],
  gameId,
  tableId,
  numberOfGame = null,
  type,
  actionPlayer = "",
  nickName,
  isGChaosComputer = true,
  stepId = "",
}) {
  try {
    const isValidCreateDocument =
      roomId && playerId && gameId && tableId && playerIds.length > 0;
    if (!isValidCreateDocument) return;

    const data = {
      roomId,
      playerId,
      playerIds,
      type,
      gameId,
      tableId,
      numberOfGame,
      actionPlayer,
      nickName,
      isGChaosComputer,
      stepId,
    };
    if (retryInterval !== null) {
      clearInterval(retryInterval);
      retryInterval = null;
    }
    currentConfirmData = data;

    try {
      await roomRef
        .doc(roomId)
        .collection(PLAYER_CONFIRMS)
        .doc(playerId)
        .set(data)
        .then(() => {
          //Create confirm doc first time success
          isCreated = true;
        })
    } catch (error) {
      console.error("playerConfirm roomRef", error);
    }
    if(isCreated && currentConfirmData.type !== CONFIRMS.CONFIRM_APPLY_CALL ){
      const tableDoc = await tableRef.doc(tableId).get();
      if (!tableDoc.exists && tableDoc.data().startTime) return;
      const gameTime = tick(tableDoc.data().startTime) / 60000;
      console.log("Current time: ", gameTime);
      // retryInterval = setInterval(checkRetry, DELAYS.RETRY_INTERVAL);
      if (gameTime < 1) {
        // first minute
        if (retryInterval) {
          clearInterval(retryInterval);
        }
        console.log("Confirm first minute: ");
        retryInterval = setInterval(
          retryCreatePlayerConfirm,
          DELAYS.RETRY_FIRST_MINUTE,
          5,
          data
        );
      } else if (gameTime >= 1 && gameTime < 5) {
        console.log("Confirm from 2nd to 5th minute: ");
        //from 2nd to 5th minute
        if (retryInterval) {
          clearInterval(retryInterval);
        }
        retryInterval = setInterval(
          retryCreatePlayerConfirm,
          DELAYS.RETRY_SECOND_TO_FIFTH_MINUTE,
          5,
          data
        );
      } else if (gameTime >= 5) {
        if (retryInterval) {
          clearInterval(retryInterval);
        }
        console.log("Confirm from 6th to end minute: ");
        //from 6th to rest of game
        retryInterval = setInterval(
          retryCreatePlayerConfirm,
          DELAYS.RETRY_SIXTH_TO_END_GAME,
          10,
          data
        );
      }
    }
    
  } catch (error) {
    console.error("playerConfirm error", error);
  }
}

function tick(startTime) {
  return Date.now() - startTime;
}

/**
 * Check condition to create document
 * @param {number} maxCounter
 * @param {boolean} isGChaosComputer
 * @param {string} confirmType
 * @returns {boolean}
 */
export const allowCreateDocument = (
  maxCounter,
  isGChaosComputer,
  confirmType
) => {
  return (
    (maxCounter === 3 && isGChaosComputer) ||
    (maxCounter === 4 && !isGChaosComputer) ||
    (maxCounter === 3 &&
      !isGChaosComputer &&
      confirmType === CONFIRMS.CONFIRM_FINAL_CALL) ||
    (maxCounter === 3 &&
      !isGChaosComputer &&
      confirmType === CONFIRMS.CONFIRM_APPLY_CALL)
  );
};

/**
 * Subscribe to player confirms collection
 * @param {*} setUnsubscribe
 * @returns
 */
export const subscribeSubCollectionRoom = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    const currentRoomJoinedId = getState().user.currentRoomJoinedId;
    const isGChaosComputer = getState().room?.table.isGChaoComputer;
    const isCanSubscribe =
      currentRoomJoinedId && isGChaosComputer !== undefined;

    if (!isCanSubscribe) return;

    try {
      const unsubscribe = roomRef
        .doc(currentRoomJoinedId)
        .collection(PLAYER_CONFIRMS)
        .onSnapshot(async (snap) => {
          let maxCounter = 0;
          let counterIndex = 0;
          let currentCheckCounterType = "";
          let currentActionPlayer = "";
          const allDocs = snap.docs.map((e) => e.data());
          allDocs.forEach((doc, index) => {
            if (
              doc.type === currentCheckCounterType &&
              doc.actionPlayer === currentActionPlayer
            ) {
              maxCounter += 1;
              counterIndex = index;
            } else {
              currentCheckCounterType = doc.type;
              currentActionPlayer = doc.actionPlayer;
              maxCounter = 1;
            }
          });
          const data = snap.docs[counterIndex]?.data();
          if (data) {
            const condToCreateDocument = allowCreateDocument(
              maxCounter,
              isGChaosComputer,
              data.type
            );
            if (condToCreateDocument) {
              await roomRef
                .doc(currentRoomJoinedId)
                .collection(PLAYER_CONFIRMED)
                .doc(data.type)
                .set(data);
            }
          }
        });
      setUnsubscribe(unsubscribe);
    } catch (error) {
      console.log(error);
    }
  };
};

/**
 * todo: subscribe sub collection in rooms
 * todo: add step narration when players screen display card
 * todo: delete all document in sub collection
 * @returns
 */
export const subscribeSubCollectionRoomUpdateNewSuit = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    const currentRoomJoinedId = getState().user?.currentRoomJoinedId;
    const isGChaosComputer = getState().room?.table.isGChaoComputer;
    const grandCross = getState().playerGameState?.grandCross;

    const isCanSubscribe =
      currentRoomJoinedId && isGChaosComputer !== undefined;
    if (!isCanSubscribe) return;

    try {
      const unsubscribe = roomRef
        .doc(currentRoomJoinedId)
        .collection(UPDATE_NEW_SUIT)
        .onSnapshot((snap) => {
          const isDelete =
            grandCross && grandCross.filter((e) => null !== e).length > 1;
          if (isDelete) {
            snap.docs.forEach((doc) => doc.ref.delete());
            return;
          }
          const counters = snap.size;
          const isUpdateNewSuit =
            (counters >= 3 && isGChaosComputer) || counters >= 4;
          if (!isUpdateNewSuit) return;
          setTimeout(() => {
            snap.docs.forEach((doc) => doc.ref.delete());
          }, 2000);
        });
      setUnsubscribe(unsubscribe);
    } catch (error) {
      console.log(error);
    }
  };
};

export const createDocumentUpdateNewSuit = async (roomId, playerId, stepId) => {
  try {
    await roomRef
      .doc(roomId)
      .collection(UPDATE_NEW_SUIT)
      .doc(playerId)
      .set({ stepId });
  } catch (error) {
    console.log("create new document to update new suit: ", error);
  }
};

export const changePosition = async (data) => {
  try {
    return await functions.httpsCallable(CHANGE_POSITION_FUNC)(data);
  } catch (error) {
    console.log(error);
  }
};

export const swapPositionDisplayPlayer = (arrPlayers, playerId, master) => {
  let newPosition = [];
  try {
    if (arrPlayers && arrPlayers.length > 0) {
      const copyArrPlayers = arrPlayers.map((player, idx) => ({
        ...player,
        isGChaos: false,
        guestName: `G's GUEST ${idx + 1}`,
        isMaster: player && player.playerId === master,
      }));
      if (copyArrPlayers[3]) {
        copyArrPlayers[3]["isGChaos"] = true;
        copyArrPlayers[3]["guestName"] = "G-Chaos";
      }
      let index = 0;
      if (arrPlayers.length > 0) {
        index = copyArrPlayers.findIndex(
          (player) => player && player.playerId === playerId
        );
      }
      const sliceArray = [...copyArrPlayers].slice(index);
      newPosition = sliceArray.concat(copyArrPlayers.slice(0, index));
    }
    return newPosition;
  } catch (error) {
    console.log(error);
    return newPosition;
  }
};

export const subscribeTableJoin = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    try {
      const { currentRoomJoinedId } = getState().user;
      const isPlaying = getState().room?.table?.isPlaying;
      if (currentRoomJoinedId && currentRoomJoinedId !== "") {
        const unsubscribe = db
          .collection("tables")
          .where("roomId", "==", currentRoomJoinedId)
          .onSnapshot(async (snapshot) => {
            if (snapshot.size > 0) {
              const tableData = snapshot.docs[0].data();
              dispatch({ type: SET_JOIN_TABLE, payload: tableData });
              if (!isPlaying) {
                dispatch({ type: CLEAR_NARRATION });
              }
            }
          });
        setUnsubscribe(unsubscribe);
      }
    } catch (error) {
      console.log(error);
    }
  };
};

export const showOverGetPlayerName = (playerId) => (dispatch) =>
  dispatch({ type: ROOM_PLAYER_OVER_GET, payload: { playerId: playerId } });

export const removeFirstNotification = async (roomId, type, playerId) => {
  await functions.httpsCallable(REMOVE_FIRST_NOTIFICATION)({
    roomId,
    type,
    playerId,
  });
};

export const leaveRoomWhilePlayingFunc = async (
  playerIds,
  playerId,
  gameId,
  tableId,
  roomId,
  threadId
) => {
  await functions.httpsCallable(LEAVE_ROOM_WHILE_PLAYING)({
    playerIds,
    playerId,
    gameId,
    tableId,
    roomId,
    threadId,
  });
};

export const confirmStartGameWhenGChaosIsMaster = async (gameId) => {
  await functions.httpsCallable(CONFIRM_START_GAME_NO_CALL)({
    gameId,
  });
};

export const clearConfirmStartGameNoCall = async (roomId) => {
  await functions.httpsCallable(CLEAR_CONFIRM_START_GAME_NO_CALL)({
    roomId,
  });
};

export const getPlayerStatus = (playerId = "") => {
  return (_, getState) => {
    if (playerId === "") return null;
    const joinedPlayers = getState().room?.joinedPlayers;
    if (joinedPlayers && joinedPlayers.length > 0) {
      return joinedPlayers.filter((p) => p && p.playerId === playerId)[0];
    }
    return null;
  };
};
