import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { rejectNumberOfCallsOfPlayer } from "../../../../../store/actions/gameActions";
import {
  removeNarrationPopup,
  stopCurrentNarration,
} from "../../../../../store/actions/narrationActions";
import { updateApplyCall } from "../../../../../store/actions/playerGameStateActions";
import { playerConfirm } from "../../../../../store/actions/roomActions";
import { CONFIRMS, SCREEN_NARRATION } from "../../../../../store/constant";
import BasicDialog from "../../NewDialog/BasicDialog";

const Narration10Recall = () => {
  const { t } = useTranslation();
  const popupId = useSelector((state) => state.narration.narrationPopup);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const playerId = useSelector((state) => state.user.playerId);
  const nickName = useSelector((state) => state.user.nickName);
  const roomId = useSelector((state) => state.room.table.roomId);
  const tableId = useSelector((state) => state.room.table.tableId);
  const numberOfCalls = useSelector(
    (state) => state.playerGameState.myState.numberOfCalls
  );
  const currentLimitCall = useSelector((state) => state.playerGameState.myState.limitCalls)
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const GChaoId = useSelector((state) => state.playerGameState.gChaosId);

  const [isShow, setIsShow] = useState(false);
  const [isClicked, setIsClicked] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!playerId) return;
    setIsShow(SCREEN_NARRATION.SG_STEP7_RECALL === popupId);
  }, [popupId, stepId, playerId]);

  useEffect(() => {
    if (-1 === numberOfCalls && SCREEN_NARRATION.SG_STEP7_RECALL === popupId) {
      dispatch(removeNarrationPopup(SCREEN_NARRATION.SG_STEP7_RECALL));
    }
  }, [numberOfCalls, popupId, dispatch]);

  useEffect(() => {
    setIsClicked(false);
    if (-1 === numberOfCalls) {
      setIsShow(false);
    }
  }, [numberOfCalls]);

  const isGchaos = playerId === GChaoId;
  const removeDialog = () => {
    // stop sound narration when player confirmed
    dispatch(stopCurrentNarration());
    // remove to close dialog
    dispatch(removeNarrationPopup(SCREEN_NARRATION.SG_STEP7_RECALL));
    
  };
  const applyNumberOfCalls = () => {
    // remove to close dialog
    if(!isClicked){
      setIsClicked(true);
      removeDialog();
      if (-1 !== numberOfCalls) {
        dispatch(updateApplyCall(currentLimitCall - 1));
        playerConfirm({
          gameId,
          roomId,
          tableId,
          playerId,
          playerIds: currentPlayersId,
          type: CONFIRMS.CONFIRM_APPLY_CALL,
          nickName,
          isGChaosComputer: isGChaosComputer,
        });
      }
    }
  };

  const rejectNumberOfCalls = () => {
    // remove to close dialog
    removeDialog();
    //TODO call sever reject
    if (-1 !== numberOfCalls) {
      rejectNumberOfCallsOfPlayer(playerId, gameId, roomId, nickName);
    }
  };
  return !isGchaos ? (
    <BasicDialog
      isOpen={isShow}
      mainText={t("tables.confirmRecall")}
      agreeText={t("tables.yesJP")}
      closeText={t("tables.recall")}
      onAgree={applyNumberOfCalls}
      onCancel={rejectNumberOfCalls}
      style={true}
    />
  ) : null;
};
export default React.memo(Narration10Recall);
