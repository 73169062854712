import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { createSetupIntent } from "../../../../store/actions/paymentAction";
import PlayerRepo from "../../../../store/repositories/player";
import { SET_LOADING_GLOBAL } from "../../../../store/types";
import SetupForm from "./setupForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRIVATE_KEY);

const CardChange = ({ handleCurrentPage }) => {
  const playerId = useSelector((state) => state.user.playerId);
  const [clientSecret, setClientSecret] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const getClientSecret = async () => {
      dispatch({
        type: SET_LOADING_GLOBAL,
        payload: true,
      });
      const playerData = await PlayerRepo.read(playerId);
      const customerId = playerData?.paymentCustomerId;
      const setupIntent = await createSetupIntent(customerId);
      setClientSecret(setupIntent?.data.client_secret ?? "");
    };
    getClientSecret();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);
  const language = localStorage.getItem("i18nextLng");
  const options = {
    clientSecret: clientSecret,
    appearance: {
      theme: "flat",
    },
    locale: language,
    loader: "never",
  };

  if (clientSecret) {
    return (
      <Elements stripe={stripePromise} options={options}>
        <SetupForm
          clientSecret={clientSecret}
          handleCurrentPage={handleCurrentPage}
        />
      </Elements>
    );
  }
  return null;
};

CardChange.propTypes = {
  handleCurrentPage: PropTypes.func,
};

export default CardChange;
