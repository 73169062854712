import { createTheme } from "@material-ui/core/styles";
import {
  COLOR_BLUE_LIGHT,
  COLOR_YELLOW_LIGHT,
} from "./pages/GameScreen/components/constants";
const theme = createTheme({
  palette: {
    primary: {
      dark: COLOR_YELLOW_LIGHT,
      main: "#000000",
      light: "#535b63",
    },
    secondary: {
      light: "#e9893c",
      main: COLOR_BLUE_LIGHT,
      dark: "#9f4b08",
    },
    default: {
      main: COLOR_BLUE_LIGHT,
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "black",
        },
      },
      focused: {},
    },
  },
});
export default theme;
