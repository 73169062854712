import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { Dialog } from "@material-ui/core";

import { COLOR_BROWN_LIGHT } from "../../../constants";

export const useStyles = makeStyles({
  dialog: {
    width: "400px",
    backgroundColor: "unset",
  },
  iconClose: {
    fontSize: "30px",
  },
  iconBtn: {
    padding: 0,
  },
  dialogTitle: {
    fontSize: "20px",
    fontWeight: "bold",
    marginBottom: "3rem",
  },
});

export const DialogStyled = styled(Dialog)`
  & .MuiDialog-paper {
    background-color: transparent !important;
    box-shadow: none;
  }
`;

export const DialogHeader = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.7em;
  height: 3em;
  background-color: ${COLOR_BROWN_LIGHT};
`;

export const DialogContent = styled.div`
  background-color: ${COLOR_BROWN_LIGHT} !important;
  height: 14em;
  background-color: #fff;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
export const DialogFooter = styled.div`
  background-color: transparent;
  height: 12em;
`;

export const ListFriendItem = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 20px;
`;

export const ListFriend = styled.div`
  background-color: #fff;
  position: absolute;
  top: 40px;
  left: 2%;
  width: 98%;
  opacity: 1;
  visibility: visible;
  padding: 10px 0;
  border-radius: 5px;
  max-height: 12rem;
  overflow-y: auto;
  visibility: ${(props) => (props.focus ? "visible" : "hidden")};
  opacity: ${(props) => (props.focus ? 1 : 0)};
`;
export const WrapperSearch = styled.div`
  width: 80%;
  margin: 0 auto;
  position: relative;
`;

export const StyleButton = styled.button`
  width: max-content;
  margin-top: 2rem;
  box-sizing: border-box;
  border-radius: 10px;
  padding: 10px 15px;
  font-size: 12px;
  font-weight: 300;
  border: none;
  cursor: pointer;
  ${(props) => (props.isDisable ? "pointer" : "default")};
  background-color: ${(props) =>
    props.isDisable ? "#412c1d" : "rgba(0, 0, 0, 0.26)"};

  color: ${(props) => (props.isDisable ? "#fff" : "rgba(0, 0, 0, 0.5)")};
  border: none;
`;
