import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { stopCurrentNarration } from "../../../../../store/actions/narrationActions";
import { clearPlayerGameState } from "../../../../../store/actions/playerGameStateActions";
import { playerConfirm } from "../../../../../store/actions/roomActions";
import {
  POPUPS,
  SCREEN_NARRATION,
  STEP_NARRATION,
  TRIGGER_END_GAME_NO_CALL,
  TUTORIAL_MODE,
  GAME_STATUS,
} from "../../../../../store/constant";
import {
  CLEAR_NARRATION,
  CLEAR_NEWEST_PLAYER_OTHER_STATE,
} from "../../../../../store/types";
import OkDialog from "../../NewDialog/OkDialog";

const Narration41EndGame = () => {
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const nickName = useSelector((state) => state.user.nickName);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const owner = useSelector((state) => state.room.table.owner);
  const tableId = useSelector((state) => state.room.table.tableId);
  const roomId = useSelector((state) => state.room.table.roomId);
  const winGameShowed = useSelector(
    (state) => state.playerGameState.winGameShowed
  );
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);

  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const [isShow, setIsShow] = useState(false);
  const [mainText, setMainText] = useState("");
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const isInSteps = [
      STEP_NARRATION.ED_STEP1,
      STEP_NARRATION.ED_STEP2,
      STEP_NARRATION.ED_STEP3,
      STEP_NARRATION.ED_STEP4,
      STEP_NARRATION.END_GAME_TUTORIAL_NO_CALL,
      STEP_NARRATION.SG_STEP12,
      STEP_NARRATION.SG_STEP12_STOP_TUTORIAL,
    ].includes(stepId);
    if (!isInSteps) {
      setIsShow(false);
      return;
    }
    const isInScreen = [
      SCREEN_NARRATION.ED_STEP2_END_GAME,
      SCREEN_NARRATION.ED_STEP3_END_GAME,
      SCREEN_NARRATION.ED_STEP4_END_GAME,
      POPUPS.ANIMATED_WIN_GAME,
      POPUPS.END_GAME_TUTORIAL_NO_CALL,
      SCREEN_NARRATION.SG_STEP12_END_GAME,
      SCREEN_NARRATION.SG_STEP12_STOP_TUTORIAL,
    ].includes(confirmId);

    const isTutorialModeNoCall = tutorialMode === TUTORIAL_MODE.NO_CALL;
    const isOwner = owner && playerId && owner === playerId;
    const isShowDialog =
      (isInScreen && isOwner) || (isInScreen && isTutorialModeNoCall);
    if (isShowDialog) {
      const showText =
        tutorialMode === TUTORIAL_MODE.NO_CALL
          ? t("narrations.narration38_no_call")
          : t("narrations.returnToGameInitialScreen");
      setMainText(showText);
      if (gameStatus !== GAME_STATUS.WIN) {
        // console.log("gameStatus", gameStatus);
        setIsShow(true);
      } else {
        if (winGameShowed === true) setIsShow(true);
        // console.log("winGameShowed", winGameShowed);
      }
    }
  }, [
    confirmId,
    stepId,
    owner,
    playerId,
    tutorialMode,
    t,
    gameStatus,
    winGameShowed,
  ]);

  const handleConfirmEndGameNoCall = () => {
    setIsShow(false);
    dispatch(stopCurrentNarration());
    const data = {
      roomId: roomId,
      gameId: gameId,
      tableId: tableId,
      playerId: playerId,
      playerIds: currentPlayersId,
      type: TRIGGER_END_GAME_NO_CALL,
      nickName: nickName,
    };

    playerConfirm(data);
  };

  const handleReturnToGameInitialScreen = () => {
    setIsShow(false);
    dispatch({ type: CLEAR_NEWEST_PLAYER_OTHER_STATE });
    dispatch({ type: CLEAR_NARRATION });
    clearPlayerGameState({
      playerIds: currentPlayersId,
      gameId,
      tableId,
      roomId,
    });
  };

  return (
    // (owner === playerId || tutorialMode === TUTORIAL_MODE.NO_CALL) && (
    isShow && (
      <OkDialog
        isOpen={isShow}
        btnLabel={t("narrationSetting.OK")}
        onAgree={
          tutorialMode !== TUTORIAL_MODE.NO_CALL
            ? handleReturnToGameInitialScreen
            : handleConfirmEndGameNoCall
        }
        confirmText={mainText}
      />
    )
  );
};
export default React.memo(Narration41EndGame);
