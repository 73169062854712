import { clearPrivateMessage } from "../store/actions/userAction";
import { ADD_INVITE } from "../store/types";
import firebase, { getFunctions, getMessaging } from "./firebase";

export const subscribeJoinRoom = async (dispatch) => {
  try {
    // clear all priavte message when login, just receive new invite
    const currentUser = firebase.auth().currentUser;
    if (!currentUser) {
      return;
    }
    const uid = currentUser.uid;
    await clearPrivateMessage(uid);

    const messaging = await getMessaging();

    if (null === messaging) {
      return;
    }
    // Get registration token. Initially this makes a network call, once retrieved
    // subsequent calls to getToken will return from cache
    const currentToken = await messaging.getToken({
      vapidKey: process.env.REACT_APP_VAPID_KEY,
    });
    if (currentToken) {
      await registerTopOnServer(currentToken);

      // Show permission request UI
      // console.log("FCM get token  success ", currentToken, result);
    } else {
      // Show permission request UI
      // console.log(
      //   "No registration token available. Request permission to generate one."
      // );
      // ...
    }
    messaging.onMessage((payload) => {
      if (payload.data && uid === payload.data.uid) {
        // remove notify from yourself
        return;
      }
      const newInviteNotify = {
        roomId: payload.data.roomId,
        tableName: payload.data.tableName,
        tableAvatar: payload.data.tableAvatar,
        description: payload.data.description,
        message: payload.data.message,
        ownerFullName: payload.data.ownerFullName,
        type: "public",
        seen: false,
        timestamp: Date.now(),
      };
      dispatch({ type: ADD_INVITE, payload: newInviteNotify });
    });
  } catch (err) {
    console.error(err);
  }
};

const registerTopOnServer = async (currentToken) => {
  try {
    const apiSubscribeJoinRoomTopic = getFunctions().httpsCallable(
      "apiSubscribeJoinRoomTopic"
    );
    const res = await apiSubscribeJoinRoomTopic({
      registerToken: currentToken,
    });
    return res.data;
  } catch (error) {
    //
  }
};
