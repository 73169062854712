/* eslint-disable no-undef */
/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  header: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "100%",
    padding: "0 10px",
    boxShadow: "0px 0px 20px 0px #ede7e7",
  },
  toggleBtn: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  toggleIcon: {
    cursor: "pointer",
    display: "flex",
    padding: "10px",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "100vw",

    "&:hover": {
      background: "#fafafa",
    },
  },
  Btns: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    position: "relative",
  },
  userName: {
    marginLeft: "0.5rem !important",
    textTransform: "capitalize",
    color: "#000",
  },
  handleToggleSettings: {},
}));

export default useStyles;
