import { SET_ADMIN_LOADING, SET_ADMIN_ID } from "../types";

const initialState = {
  isLoading: false,
  adminId: "",
  adminNickName: "",
  adminEmail: "",
};

const adminReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ADMIN_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case SET_ADMIN_ID:
      return {
        ...state,
        adminId: action.payload.playerId,
        adminNickName: action.payload.nickName,
        adminEmail: action.payload.email,
      };
    case "INITIAL_STATE": {
      return {
        ...initialState,
      };
    }
    default:
      return state;
  }
};
export default adminReducer;
