import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #311c0a;
  margin-top: auto;
  justify-content: space-between;
`;

export const MediaButton = styled(IconButton)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;

  &:hover {
    transform: scale(1.05);
    transition: all 0.2s ease-in-out;
  }
`;

export const VideoVoiceContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
  height: auto;
  padding: ${(props) => props.padding}px 0;
`;
