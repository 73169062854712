import { Box, Container } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Backdrop from "../../components/Backdrop";
import firebase from "../../services/firebase";
import useStyles, { LogoGame, Production } from "./styles";
import RegisterPhoneOtp from "./components/RegisterPhoneOtp";
import RegisterPhone from "./components/RegisterPhone";
import RegisterSuccess from "../../components/Otp/RegisterSuccess";
import SendedOtp from "../../components/Otp/SendedOtp";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../hooks/useDocumentTitle";

firebase.auth().languageCode = "ja";

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const initialValues = {
  numberPhone: "",
  email: "",
};

const Register = () => {
  const logoGameWidth = 240;
  const logoGameHeight = 150;
  const productionWidth = 130;
  const productionHeight = 6;
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState(initialValues);
  const [indexPage, setIndexPage] = useState(0);
  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.registerTitle"));

  const handleChangeValues = useCallback(
    (e) => {
      const { name, value } = e.target;
      setValues({ ...values, [name]: value });
    },
    [values]
  );

  const handleChangePage = useCallback((_page) => {
    setIndexPage(_page);
  }, []);

  useEffect(() => {
    setLoading(false);
    return () => setLoading(true);
  }, []);

  return (
    <Container className={classes.root}>
      <Backdrop open={loading} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LogoGame width={logoGameWidth} height={logoGameHeight} />
        <Space space={10} />
        <Production width={productionWidth} height={productionHeight} />
        {indexPage === 0 && (
          <RegisterPhone
            values={values}
            handleChangeValues={handleChangeValues}
            handleChangePage={handleChangePage}
          />
        )}
        {indexPage === 1 && (
          <RegisterPhoneOtp
            handleChangePage={handleChangePage}
            values={values}
          />
        )}
        {indexPage === 2 && (
          <SendedOtp
            handleChangePage={handleChangePage}
            numberPhone={values.numberPhone}
            labelButton="登録画面に戻る"
          />
        )}
        {indexPage === 3 && <RegisterSuccess />}
      </Box>
    </Container>
  );
};

export default Register;
