import React, { useMemo } from "react";
import { useSelector } from "react-redux";

import {
  FormControl,
  FormLabel,
  RadioGroup as MuiRadioGroup,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    margin: "25px auto",
    width: (props) => `${props.input_W}px`,
    color: "#541513",
    fontWeight: "600",
    "& label": {
      fontWeight: "600",
      fontSize: 16,
      "& span": {
        fontWeight: "600",
        fontSize: 16,
      },
    },
  },
  label: {
    transformOrigin: "top left",
    color: "#541513 !important",
    fontWeight: "600",
  },
  // col: {
  //   flexDirection:"column",
  // }
}));

const styledRadio = { color: "black", opacity: "0.7" };
function RadioGroup(props) {
  const minScale = useSelector((state) => state.size.minScale);
  const input_W = useMemo(() => (minScale < 0.4 ? 250 : 372), [minScale]);

  const classes = useStyles({ input_W });
  const { name, label, value, onChange, items, className, col } = props;
  return (
    <FormControl className={`${classes.root} ${className}`}>
      <FormLabel className={classes.label}>{label}</FormLabel>
      {col ? (
        <MuiRadioGroup name={name} value={value} onChange={onChange}>
          {items.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.id}
              control={<Radio style={styledRadio} />}
              label={item.title}
            />
          ))}
        </MuiRadioGroup>
      ) : (
        <MuiRadioGroup row name={name} value={value} onChange={onChange}>
          {items.map((item, index) => (
            <FormControlLabel
              key={index}
              value={item.id}
              control={<Radio style={styledRadio} />}
              label={item.title}
            />
          ))}
        </MuiRadioGroup>
      )}
    </FormControl>
  );
}

RadioGroup.propTypes = {
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  items: PropTypes.array,
  className: PropTypes.string,
  col: PropTypes.bool,
};

export default RadioGroup;
