import { Box, Button, Typography } from "@material-ui/core";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextareaAutosize,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { subscribeToBronzePackageOfCyclo } from "../../../../store/actions/packageActions";
import { deletePlayer } from "../../../../store/actions/playerActions";
import { logout } from "../../../../store/actions/authActions";
import {
  sendMailUnsubscribePlan,
  sendMailDeletePlayer,
} from "../../../../store/actions/subscriptionActions";
import { BRONZE_PACKAGE_NAME } from "../../../../store/constant";
import { SET_LOADING_GLOBAL } from "../../../../store/types";
import DialogConfirm from "../../../GameScreen/components/Dialog/DialogConfirm";
import useStyles from "./styles";

const UnsubscribePlan = ({ handleCurrentPage }) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const playerId = useSelector((state) => state.user.playerId);
  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);
  const email = useSelector((state) => state.user.email);
  const fullName = useSelector((state) => state.user.fullName);
  const history = useHistory();

  const [listReasonChecked, setListReasonChecked] = useState([
    { key: "reason1", name: `${t("paymentSettings.reason1")}`, checked: false },
    { key: "reason2", name: `${t("paymentSettings.reason2")}`, checked: false },
    { key: "reason3", name: `${t("paymentSettings.reason3")}`, checked: false },
    { key: "reason4", name: `${t("paymentSettings.reason4")}`, checked: false },
    { key: "reason5", name: `${t("paymentSettings.reason5")}`, checked: false },
    { key: "reason6", name: `${t("paymentSettings.reason6")}`, checked: false },
  ]);

  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleUnsubscribePlan = useCallback(async () => {
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: true,
    });
    let result = true;

    if (playTimeInfo.packageName === BRONZE_PACKAGE_NAME) {
      await deletePlayer(playerId);
      await sendMailDeletePlayer(email, fullName);
      dispatch(logout);
      history.push("/login");
    } else {
      result = await subscribeToBronzePackageOfCyclo(playerId);

      //TODO: Send mails
      await sendMailUnsubscribePlan(email);
    }
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: false,
    });
    toast[`${result ? "success" : "error"}`](
      `${
        result
          ? t("paymentSettings.unsubscribePlanSuccess")
          : t("paymentSettings.unsubscribePlanFailed")
      }`
    );
    setOpen(false);
    handleCurrentPage(0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckbox = (e) => {
    let newListReasonChecked = [...listReasonChecked];
    let index = newListReasonChecked.findIndex((f) => f.key === e.target.name);
    if (index > -1) {
      newListReasonChecked[index] = {
        ...newListReasonChecked[index],
        checked: e.target.checked,
      };
      setListReasonChecked(newListReasonChecked);
    }
  };

  return (
    <Box className={classes.wrappContainer}>
      <Box className={classes.titleBox}>
        <Typography className={classes.titleContent}>
          {playTimeInfo.packageName === BRONZE_PACKAGE_NAME
            ? t("paymentSettings.unsubscribePlanTitle")
            : t("paymentSettings.unsubscribeOfCycloPlanTitle")}
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.description}>
          {playTimeInfo.packageName === BRONZE_PACKAGE_NAME
            ? t("paymentSettings.unsubscribePlanDisciption", {
                name: `${
                  playTimeInfo.statusFree
                    ? t("paymentSettings.trial")
                    : t("paymentSettings." + playTimeInfo.packageName)
                }`,
              })
            : t("paymentSettings.unsubscribeOfCycloPlanDisciption", {
                name: `${t("paymentSettings." + playTimeInfo.packageName)}`,
              })}
        </Typography>
      </Box>
      <Box>
        <FormGroup className={classes.checkBoxWrapper}>
          {listReasonChecked &&
            listReasonChecked.map((item) => (
              <FormControlLabel
                classes={{
                  label: classes.checkBoxItemLabel,
                }}
                control={
                  <Checkbox
                    checked={item.checked}
                    onChange={handleChangeCheckbox}
                    name={item.key}
                  />
                }
                label={item.name}
                key={item.key}
              />
            ))}
        </FormGroup>
      </Box>
      <Box className={classes.remarks}>
        <Box>
          <Typography className={classes.remarksTitle}>
            {t("paymentSettings.remarks")}
          </Typography>
        </Box>
        <Box style={{ width: "100%" }}>
          <TextareaAutosize
            className={classes.remarksContent}
            maxRows={4}
            aria-label="maximum height"
            placeholder={`${t("paymentSettings.unsubscribePlaceholder")}`}
          />
        </Box>
      </Box>
      <Box className={classes.buttons}>
        <Button
          className={classes.buttonBlue}
          onClick={() => handleCurrentPage(0)}
        >
          {t("paymentSettings.back")}
        </Button>
        <Button className={classes.buttonRed} onClick={() => setOpen(true)}>
          {t("paymentSettings.unsubscribe")}
        </Button>
      </Box>
      <DialogConfirm
        open={open}
        handleClose={handleClose}
        handleSubmit={handleUnsubscribePlan}
        contentConfirm={
          playTimeInfo.packageName === BRONZE_PACKAGE_NAME
            ? t("paymentSettings.unsubscribePlanDialogDescription")
            : t("paymentSettings.unsubscribeOfCycloPlanDialogDescription")
        }
        okLabel={t("paymentSettings.oke")}
        cancelLabel={t("paymentSettings.cancel")}
      />
    </Box>
  );
};

UnsubscribePlan.propTypes = {
  handleCurrentPage: PropTypes.func,
};

export default UnsubscribePlan;
