import { makeStyles } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import {
  initGame,
  updateShowArkStatus,
} from "../../../../../store/actions/gameActions";
import {
  checkAllowStartGame,
  getOfflineNickname,
  updateIsOffline,
} from "../../../../../store/actions/playTimeAction";
import {
  DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD,
  GAME_STATUS,
  POPUPS,
  SCREEN_NARRATION,
  STD_SIZE,
  STEP_NARRATION,
  TURN_STATUS,
  TUTORIAL_MODE,
} from "../../../../../store/constant";
import {
  checkStepMasterCardsGChaosComputer,
  checkStepMasterCardsGChaosHuman,
  checkStepOrderCards,
  sleep,
} from "../../../../../utils/common";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import firebase from "../../../../../services/firebase";
import {
  CLEAR_NARRATION,
  CLEAR_NEWEST_PLAYER_OTHER_STATE,
  NOT_SHOW_ARK_AFTER_USE_MASTER_CARD,
} from "../../../../../store/types";
import OkDialog from "../../../../GameScreen/components/NewDialog/OkDialog";
import { GCC_HBottom } from "../../GCC/GCC_HBottom/GCC_HBottom";
import { GCC_HTop } from "../../GCC/GCC_HTop/GCC_HTop";
import { GCC_VLeft } from "../../GCC/GCC_VLeft/GCC_VLeft";
import { GCC_VRight } from "../../GCC/GCC_VRight/GCC_VRight";
import BasicDialog from "../../NewDialog/BasicDialog";
import DeclareCallsDialog from "../../NewDialog/DeclareCallsDialog";
import MessDialog from "../../NewDialog/MessDialog";
import ShowMasterCards from "../../ShowMasterCards";
import ShowMasterCardsGChaosIsHuman from "../../ShowMasterCards/ShowMasterCardGChaosIsHuman";
import ShowOrderCards from "../../ShowOrderCards";
import BottomStarCards from "../../StarCards/BottomStarCards";
import { StarCard } from "../../StarCards/StarCard";
import TopStarCards from "../../StarCards/TopStarCards";
import CenterItemComponent from "./components/CenterItemComponent";
import HorizontalItemComponent from "./components/HorizontalItemComponent";
import { ArkWrapper, LogoArk, MenuStartGame, ModifiedButton } from "./styles";

const db = firebase.firestore();
const tablesRef = db.collection("tables");

const GameCenterContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

const GameCenterTop = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 100;
`;

const GameCenterMiddle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: ${(props) => props.height}px;
  position: relative;
  // padding: ${(props) => props.padding}px 0;
  box-sizing: border-box;
`;

const GameCenterBottom = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
  display: flex;
  flex-direction: column;
  background: #311c0a;
`;

const useStyles = makeStyles(() => ({
  bottomShip: {
    marginTop: (props) => props.marginTop,
  },
}));

export const GameCenter = () => {
  const bottomHeight = useSelector((state) => state.size.bottomHeight);
  const height = useSelector((state) => state.size.height);
  const hShipWidth = useSelector((state) => state.size.hShipWidth);
  const vShipHeight = useSelector((state) => state.size.vShipHeight);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const centerHeight = useMemo(
    () => vShipHeight + 2 * shipSpace,
    [shipSpace, vShipHeight]
  );
  const { t } = useTranslation();
  const room = useSelector((state) => state.room);
  const player = useSelector((state) => state.user);
  const table = useSelector((state) => state.room.table);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const size = useSelector((state) => state.size);
  const isPlaying = useSelector((state) => state.room.table.isPlaying);
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const { myState, otherPlayersState } = useSelector(
    (state) => state.playerGameState
  );
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const turnStatus = useSelector((state) => state.playerGameState.turnStatus);
  const master = useSelector((state) => state.playerGameState.master);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const orderCards = useSelector((state) => state.playerGameState.orderCards);
  const tableId = useSelector((state) => state.room.table.tableId);
  const playerReady = useSelector((state) => state.room.playerReady);
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );

  const [isOpenTutorialDialog, setOpenTutorialDialog] = useState(false);
  const [isOpenIntroduceDialog, setIsOpenIntroduceDialog] = useState(false);
  const [isOpenIntroduceTutorialDialog, setIsOpenIntroduceTutorialDialog] =
    useState(false);
  const [isOpenAllowStartGame, setIsOpenAllowStartGame] = useState(false);
  const [isOpenDisconnect, setIsOpenDisconnect] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isCall, setIsCall] = useState("");
  const [isClicked, setIsClicked] = useState(false);
  const [innerSize, setInnerSize] = useState({});
  const [isShowStartBtn, setIsShowStartBtn] = useState(false);
  const [showArk, setShowArk] = useState(true);

  const popupId = useSelector((state) => state.narration.narrationPopup);
  const classes = useStyles({ marginTop: shipSpace });
  const dispatch = useDispatch();
  const show_Ark = useSelector((state) => state.playerGameState.showArk);
  useEffect(() => {
    if (size) {
      const bottomHeight = size.bottomHeight;
      const centerItemWidth = size?.hShipWidth / 3;
      const horizontalItemHeight = size?.hShipWidth / 3;
      const startGameFontSize = 16 * size?.minScale;
      const hShipWidth = size?.hShipWidth;
      const hShipHeight = size?.hShipHeight;
      const dialogWidth = Math.round(size.hShipWidth * 2);
      const dialogHeight = Math.round(dialogWidth / 2);
      setInnerSize({
        bottomHeight,
        centerItemWidth,
        horizontalItemHeight,
        startGameFontSize,
        hShipWidth,
        hShipHeight,
        dialogHeight,
        dialogWidth,
      });
    } else {
      const bottomHeight = (STD_SIZE.STD_HEIGHT - STD_SIZE.STD_SHIP_WIDTH) / 2;
      const centerItemWidth = STD_SIZE.STD_SHIP_WIDTH / 3;
      const horizontalItemHeight = STD_SIZE.STD_SHIP_WIDTH / 3;
      const startGameFontSize = 16;
      const hShipWidth = STD_SIZE.STD_H_SHIP_WIDTH;
      const hShipHeight = STD_SIZE.STD_H_SHIP_HEIGHT;
      const dialogWidth = STD_SIZE.STD_SHIP_WIDTH * 2;
      const dialogHeight = Math.round(dialogWidth / 2);
      setInnerSize({
        bottomHeight,
        centerItemWidth,
        horizontalItemHeight,
        startGameFontSize,
        hShipWidth,
        hShipHeight,
        dialogHeight,
        dialogWidth,
      });
    }
  }, [size]);
  useEffect(() => {
    if (
      popupId === POPUPS.PLAYER_SELECT_MASTER_CARD ||
      popupId === SCREEN_NARRATION.UM_SHOW_MASTER_CARD ||
      popupId === SCREEN_NARRATION.UM_CLOSE_MASTER_CARD ||
      popupId === SCREEN_NARRATION.UM_SHOW_MASTER_CARD_1 ||
      popupId === SCREEN_NARRATION.UM_SHOW_MASTER_CARD_ALL ||
      popupId === SCREEN_NARRATION.SG_STEP_SHOW_TRICK_STAR
    ) {
      console.log("Hide ARK");
      dispatch({
        type: NOT_SHOW_ARK_AFTER_USE_MASTER_CARD,
        payload: false,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [popupId, dispatch]);
  useEffect(() => {
    if (!show_Ark) {
      //update show ark into table collection
      updateShowArkStatus(tableId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [show_Ark]);
  const onStartGame = async (tutorialMode, isSkipIntroduce) => {
    try {
      setIsLoading(true);
      if (!isClicked) {
        setIsClicked(true);
        setOpenTutorialDialog(false);
        setIsOpenIntroduceDialog(false);
        await initGame(
          table.tableId,
          table.currentPlayersId,
          tutorialMode,
          table.numberOfGame,
          table.isGChaoComputer,
          isSkipIntroduce
        );
        //set ark to initial state
        dispatch({
          type: NOT_SHOW_ARK_AFTER_USE_MASTER_CARD,
          payload: true,
        });
        setShowArk(true);
        setIsLoading(false);
        setIsClicked(false);
      }
    } catch (error) {
      console.log("onStartGame", error);
      setIsLoading(false);
      setIsClicked(false);
    }
  };

  const isDiableStartGameButton = useMemo(() => {
    return (
      player &&
      table &&
      player.playerId !== "" &&
      table.owner !== "" &&
      !table.isPlaying &&
      gameStatus != GAME_STATUS.PLAYING &&
      player.playerId === table.owner &&
      table.currentPlayersId.indexOf(null) === -1 &&
      playerReady > 3
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player, table, gameStatus, isPlaying, playerReady]);

  const isOwner = useMemo(() => {
    return table && table.owner !== "" && table.owner === player.playerId;
  }, [table, player]);

  const isShowGrandCross = useMemo(() => {
    return isPlaying && turnStatus !== "";
  }, [isPlaying, turnStatus]);

  useEffect(() => {
    if (
      isOwner &&
      !isPlaying &&
      gameId === "" &&
      turnStatus === "" &&
      playerId === "" &&
      gameStatus == GAME_STATUS.RESET
    ) {
      setIsShowStartBtn(true);
      return;
    }
    setIsShowStartBtn(false);
  }, [isOwner, isPlaying, gameId, turnStatus, playerId, gameStatus]);
  const isOpenDeclareCalls = useMemo(() => {
    return isPlaying && turnStatus === TURN_STATUS.DECLARE_CALL;
  }, [isPlaying, turnStatus]);

  const isShowMasterCardsGChaosComputer = useMemo(() => {
    const showMC = checkStepMasterCardsGChaosComputer(stepId);
    if (!stepId) {
      localStorage.removeItem(DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD);
    }
    const isShow =
      (showMC && isGChaosComputer) ||
      (showMC && !isGChaosComputer && stepId === STEP_NARRATION.UM_STEP7);
    return isShow;
  }, [stepId, isGChaosComputer]);

  const isShowMasterCardsGChaosHuman = useMemo(() => {
    const showMC = checkStepMasterCardsGChaosHuman(stepId);
    if (!stepId) {
      localStorage.removeItem(DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD);
    }
    return showMC && !isGChaosComputer;
  }, [stepId, isGChaosComputer]);

  const isShowOrderCards = useMemo(() => {
    const showOrderCards = checkStepOrderCards(stepId);
    const hasOrderCards = orderCards && orderCards.length > 0;
    return showOrderCards && hasOrderCards;
  }, [stepId, orderCards]);

  const getPlayerNickName = async (disconnectPlayerId) => {
    if (disconnectPlayerId) {
      const offNickName = await getOfflineNickname(disconnectPlayerId);
      setMessage(t("instructor.notifyLeaveRoom", { name: offNickName }));
      setIsOpenDisconnect(true);
      return offNickName;
    }
  };

  useEffect(() => {
    const hideMessage = async () => {
      if (isOpenDisconnect) {
        await sleep(3000);
        setIsOpenDisconnect(false);
      }
    };
    hideMessage();
  }, [isOpenDisconnect]);

  useEffect(() => {
    let unsubscribe = async () => {};
    if (tableId) {
      unsubscribe = tablesRef.doc(tableId).onSnapshot(async (snapshot) => {
        if (snapshot.exists) {
          const tableData = snapshot.data();
          // player offline while playing
          if (tableData && tableData.isOffline) {
            await getPlayerNickName(tableData.offlinePlayers[0]);
            await updateIsOffline(tableId);
            dispatch({ type: CLEAR_NEWEST_PLAYER_OTHER_STATE });
            dispatch({ type: CLEAR_NARRATION });
          }
          //listen ark status
          setShowArk(tableData.showArk);
        }
      });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableId]);

  const handleStartGameNormalMode = async () => {
    setIsLoading(true);
    const allowStartGame = await checkAllowStartGame(currentPlayersId);
    if (allowStartGame && allowStartGame.isAllowed) {
      setIsOpenIntroduceDialog(true);
      setIsShowStartBtn(false);
    } else {
      setIsOpenAllowStartGame(true);
    }
    setIsLoading(false);
  };

  const handleStartGameTutorialMode = async () => {
    setIsLoading(true);
    const allowStartGame = await checkAllowStartGame(currentPlayersId);
    if (allowStartGame && allowStartGame.isAllowed) {
      setOpenTutorialDialog(true);
      setIsShowStartBtn(false);
    } else {
      setIsOpenAllowStartGame(true);
    }
    setIsLoading(false);
  };

  return (
    <GameCenterContainer width={hShipWidth} height={height}>
      {/* Start cards top wrapper */}
      <GameCenterTop>
        <div
          style={{
            width: "100%",
            height: "100%",
            transform: "rotate(180deg)",
          }}
        >
          <StarCard
            profile={room.profile2}
            master={master}
            height={innerSize.hShipHeight}
            size={size}
          >
            <TopStarCards
              numberOfCalls={
                otherPlayersState[room.profile2.index]?.numberOfCalls
              }
              cards={otherPlayersState[room.profile2.index]?.starCards}
            />
          </StarCard>
        </div>
      </GameCenterTop>
      <GameCenterMiddle height={centerHeight} padding={shipSpace}>
        {isShowGrandCross && (
          <div
            style={{
              aspectRatio: 1,
              boxSizing: "border-box",
              display: "flex",
              flexDirection: "column",
              width: innerSize?.hShipWidth,
              height: innerSize?.hShipWidth,
              backgroundImage: `radial-gradient(
              circle,
              #ebe0d3,
              #d9c1a5,
              #c6a278,
              #b2844e,
              #9d6624
            )`,
            }}
          >
            <HorizontalItemComponent height={innerSize.horizontalItemHeight}>
              <GCC_HTop />
            </HorizontalItemComponent>
            <HorizontalItemComponent height={innerSize.horizontalItemHeight}>
              <CenterItemComponent width={innerSize.centerItemWidth}>
                <GCC_VLeft />
              </CenterItemComponent>
              <CenterItemComponent width={innerSize.centerItemWidth}>
                <ArkWrapper>
                  {/* ARK */}
                  {showArk && (
                    <div
                      style={{
                        width: "100%",
                        height: "100%",
                        padding: "15px",
                        boxSizing: "border-box",
                        backgroundColor: "#3b0904",
                        margin: "auto",
                      }}
                    >
                      <LogoArk src="/assets/logo_ark.png" />
                    </div>
                  )}
                </ArkWrapper>
              </CenterItemComponent>
              <CenterItemComponent width={innerSize.centerItemWidth}>
                <GCC_VRight />
              </CenterItemComponent>
            </HorizontalItemComponent>
            <HorizontalItemComponent height={innerSize.horizontalItemHeight}>
              <GCC_HBottom />
            </HorizontalItemComponent>
            {/* </>
            )} */}
          </div>
        )}
        <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        {isShowStartBtn && (
          <MenuStartGame>
            <ModifiedButton
              disable={!isDiableStartGameButton ? 1 : 0}
              onClick={handleStartGameNormalMode}
              style={{
                pointerEvents: isDiableStartGameButton ? "initial" : "none",
              }}
              fontSize={innerSize.startGameFontSize}
            >
              {t("tables.startGame")}
            </ModifiedButton>
            <ModifiedButton
              disable={!isDiableStartGameButton ? 1 : 0}
              style={{
                pointerEvents: isDiableStartGameButton ? "initial" : "none",
              }}
              onClick={handleStartGameTutorialMode}
              fontSize={innerSize.startGameFontSize}
            >
              {t("game.tutorial")}
            </ModifiedButton>
          </MenuStartGame>
        )}
        {isShowOrderCards && <ShowOrderCards />}
        {isShowMasterCardsGChaosHuman ? <ShowMasterCardsGChaosIsHuman /> : null}
        {isShowMasterCardsGChaosComputer ? <ShowMasterCards /> : null}
        {isOpenDeclareCalls && <DeclareCallsDialog />}
      </GameCenterMiddle>
      {/* BOTTOM WRAPPER */}
      <GameCenterBottom height={bottomHeight}>
        <StarCard
          profile={room.profile0}
          master={master}
          height={innerSize?.hShipHeight}
          width={"100%"}
          size={size}
          className={classes.bottomShip}
        >
          <BottomStarCards
            numberOfCalls={myState?.numberOfCalls}
            cards={myState?.starCards}
          />
        </StarCard>
      </GameCenterBottom>
      <OkDialog
        isOpen={isOpenAllowStartGame}
        onAgree={() => {
          setIsOpenAllowStartGame(false);
        }}
        confirmText={t("tables.canNotStartGame")}
        btnLabel={"OK"}
      />
      {isOpenDisconnect ? (
        <MessDialog isOpen={isOpenDisconnect} mainText={message} />
      ) : (
        <></>
      )}
      <BasicDialog
        isOpen={isOpenTutorialDialog}
        onCancel={() => {
          setIsCall(TUTORIAL_MODE.NO_CALL);
          setIsOpenIntroduceTutorialDialog(true);
          setOpenTutorialDialog(false);
        }}
        onAgree={() => {
          setIsCall(TUTORIAL_MODE.HAS_CALL);
          setIsOpenIntroduceTutorialDialog(true);
          setOpenTutorialDialog(false);
        }}
        mainText={t("game.selectTutorialVersion")}
        closeText={t("game.tutorialNoCall")}
        agreeText={t("game.tutorialHasCall")}
        withXButton={true}
        onXButtonClick={() => {
          setOpenTutorialDialog(false);
          setIsShowStartBtn(true);
        }}
      />

      <BasicDialog
        isOpen={isOpenIntroduceDialog}
        onCancel={() => onStartGame(TUTORIAL_MODE.NO_TUTORIAL, false)}
        onAgree={() => onStartGame(TUTORIAL_MODE.NO_TUTORIAL, true)}
        mainText={t("game.confirmGameIntro")}
        closeText={t("tables.no")}
        agreeText={t("tables.yes")}
        withXButton={true}
        onXButtonClick={() => {
          setIsOpenIntroduceDialog(false);
          setIsShowStartBtn(true);
        }}
      />
      {/* Flow tutorial mode with instruction */}
      <BasicDialog
        isOpen={isOpenIntroduceTutorialDialog}
        onCancel={() => {
          setIsOpenIntroduceTutorialDialog(false);
          onStartGame(isCall, true);
        }}
        onAgree={() => {
          setIsOpenIntroduceTutorialDialog(false);
          onStartGame(isCall, false);
        }}
        mainText={t("game.confirmGameIntro")}
        closeText={t("tables.no")}
        agreeText={t("tables.yes")}
        withXButton={true}
        onXButtonClick={() => {
          setIsOpenIntroduceTutorialDialog(false);
          setIsShowStartBtn(true);
        }}
      />
    </GameCenterContainer>
  );
};
