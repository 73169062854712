import PropTypes from "prop-types";
import { Box, makeStyles, Typography } from "@material-ui/core";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { GrStatusGoodSmall } from "react-icons/gr";
import styled from "styled-components";

const StatusWrapper = styled.div`
  padding: 15px 0;
  display: grid;
  grid-template-columns: repeat(3, 1fr);

  border-bottom: 1px solid #442211;
`;

const useStyles = makeStyles(() => ({
  status: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  label: {
    fontSize: "14px",
    marginLeft: "5px",
    textAlign: "start",
    boxSizing: "border-box",
    color: "#442211",
  },
  hideClass: {
    opacity: 0,
    visible: "hidden",
  },
}));
PlayerStt.propTypes = {
  isShowOnlineStatus: PropTypes.bool,
};
export default function PlayerStt(props) {
  let { isShowOnlineStatus } = props;
  const [circleSize] = useState(14);
  const { t } = useTranslation();
  const classes = useStyles({});

  return (
    <StatusWrapper>
      <Box
        className={`${classes.status} ${
          isShowOnlineStatus ? "" : classes.hideClass
        }`}
      >
        <GrStatusGoodSmall style={{ color: "#399D26", fontSize: circleSize }} />
        <Typography noWrap className={classes.label}>
          {t("status.waiting")}
        </Typography>
      </Box>
      <Box className={classes.status}>
        <GrStatusGoodSmall style={{ color: "#FFEC00", fontSize: circleSize }} />
        <Typography noWrap className={classes.label}>
          {t("status.joinInTable")}
        </Typography>
      </Box>
      <Box className={classes.status}>
        <GrStatusGoodSmall style={{ color: "#DC000C", fontSize: circleSize }} />
        <Typography noWrap className={classes.label}>
          {t("status.inplay")}
        </Typography>
      </Box>
    </StatusWrapper>
  );
}
