import { Box, Typography } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  CARD_SUIT_CLUBS,
  CARD_SUIT_DIAMONDS,
  CARD_SUIT_HEARTS,
  CARD_SUIT_SPADES,
} from "../../../../../store/constant";
import FlippedCard from "../../FlippedCard";
import useStyles from "./styles";

const SelectSuitGCard = ({ onSelectCard, width, height }) => {
  const { t } = useTranslation();
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (size) {
      const fullWidth = width;
      const fullHeight = height;
      setInnerSize({ fullWidth, fullHeight });
    } else {
      const fullWidth = width;
      const fullHeight = height;
      setInnerSize({ fullWidth, fullHeight });
    }
  }, [size, width, height]);
  const classes = useStyles({
    fullWidth: innerSize.fullWidth,
    fullHeight: innerSize.fullHeight,
  });
  const handleSelectSuitCard = (suit) => {
    if (onSelectCard) onSelectCard(suit);
  };
  return (
    <Box className={classes.root + " " + "glow-on-hover"}>
      <Box display="flex" justifyContent="center">
        <Typography component="div">
          <Box m={1} fontWeight="fontWeightBold">
            {t("tables.cardSuitTitle")}
          </Box>
        </Typography>
      </Box>
      <Box className={classes.boxCard}>
        <FlippedCard
          flipped={true}
          src={`/cards/${CARD_SUIT_SPADES}.jpg`}
          onClickSelectCard={() => handleSelectSuitCard(CARD_SUIT_SPADES)}
          className={classes.cardBorder}
        />
        <FlippedCard
          flipped={true}
          src={`/cards/${CARD_SUIT_HEARTS}.jpg`}
          onClickSelectCard={() => handleSelectSuitCard(CARD_SUIT_HEARTS)}
          className={classes.cardBorder}
        />
        <FlippedCard
          flipped={true}
          src={`/cards/${CARD_SUIT_DIAMONDS}.jpg`}
          onClickSelectCard={() => handleSelectSuitCard(CARD_SUIT_DIAMONDS)}
          className={classes.cardBorder}
        />
        <FlippedCard
          flipped={true}
          src={`/cards/${CARD_SUIT_CLUBS}.jpg`}
          onClickSelectCard={() => handleSelectSuitCard(CARD_SUIT_CLUBS)}
          className={classes.cardBorder}
        />
      </Box>
    </Box>
  );
};
SelectSuitGCard.propTypes = {
  onSelectCard: PropTypes.func,
  width: PropTypes.number,
  height: PropTypes.number,
};
export default SelectSuitGCard;
