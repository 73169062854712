import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Chip,
  // Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { HighlightOff } from "@material-ui/icons";
import PropTypes from "prop-types";
import { useEffect, useRef, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { v4 as uuidV4 } from "uuid";
import RadioGroup from "../../../components/RadioGroup";
import RounedDialog from "../../../pages/GameScreen/components/Dialog/RounedDialog";
import { createRoom, getNewRoomId } from "../../../store/actions/tableActions";
import { uploadImage } from "../../../store/actions/userAction";
import {
  MAX_CHARACTER_DESC,
  MAX_CHARACTER_MESSAGE,
  MAX_CHARACTER_NAME,
} from "../../../store/constant";
import theme from "../../../theme";
import AvatarUpload from "../../AvatarUpload";
import BackDrop from "../../Backdrop";
import MuiInput from "../../MuiInput";
import useStyles from "./styles";
import PlayerRepo from "../../../store/repositories/player";
const initialValues = {
  tableName: "",
  joinTableRole: "",
  description: "",
  tableAvatar: "",
  isGChaoComputer: "",
  notify: "",
  roomId: "",
  accessToken: "",
  tableUrl: "",
  message: "",
  invites: [],
};

const TableSetting = ({ open, onClose }) => {
  const initialState = useRef(true);
  const classes = useStyles();
  const { t } = useTranslation();

  const [values, setValues] = useState(initialValues);
  const [image, setImage] = useState(null);
  const [invites, setInvites] = useState([]);
  const user = useSelector((state) => state.user);
  const friends = useSelector((state) => state.user.friends);
  const defaultSetting = useSelector((state) => state.user.setting || {});
  const userUid = useSelector((state) => state.user.playerId);
  const [loading, setLoading] = useState(false);
  const [copied, setCopied] = useState(false);
  const [currentFriends, setCurrentFriends] = useState([]);

  const handleChangeInvites = (event) => {
    setInvites(event.target.value);
  };
  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleCheckBoxChange = (e, checked) => {
    const { name } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const handleDelete = (userId, event) => {
    event.stopPropagation();

    let listInviteFriend = [...invites];
    listInviteFriend = listInviteFriend.filter(
      (friend) => friend.friendId !== userId
    );
    setInvites(listInviteFriend);
  };
  const updateInvites = (invitesArg) => {
    let inviteIDs = [];
    if (invitesArg && invitesArg.length > 0)
      inviteIDs = invitesArg.map((friend) => friend.friendId);
    return inviteIDs;
  };

  const handleCreateTable = async () => {
    setLoading(true);
    const newValues = {
      ...values,
      invites: updateInvites(invites),
      isStaff: false,
    };
    //  not send private invite when notify all
    if (values.joinTableRole === "close") {
      newValues.notify = false;
    }
    if (values.joinTableRole === "open" && values.notify) {
      newValues.invites = [];
    }
    if (image) {
      const newImage = await uploadImage(image, userUid, false, true);
      newValues.tableAvatar = newImage;
    }
    await createRoom(newValues, user);
  };
  const joinTableItems = [
    { id: "open", title: t("settings.open"), color: "default" },
    { id: "close", title: t("settings.close"), color: "default" },
  ];
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 25,
        marginTop: 45,
      },
    },
  };

  const getCurrentRoomJoinedId = async (_playerId = "") => {
    if (_playerId) {
      const player = await PlayerRepo.read(_playerId);
      return player?.currentRoomJoinedId;
    }
    return "";
  };

  // handle inital
  useEffect(() => {
    if (!initialState.current) {
      return;
    }
    let accessToken = uuidV4();
    let tmpRoomId = getNewRoomId();
    const url = "".concat(
      window.location.origin,
      "/game/",
      tmpRoomId,
      "?access_token=",
      accessToken
    );
    const newValues = {
      ...values,
      accessToken: accessToken,
      roomId: tmpRoomId,
      tableUrl: url,
      tableName: defaultSetting.defaultTableName,
      joinTableRole: defaultSetting.joinTableRole,
      tableAvatar: defaultSetting.defaultAvatar,
      isGChaoComputer: false,
      notify: false,
    };
    setValues(newValues);

    initialState.current = false;
  }, [values, defaultSetting]);

  useEffect(() => {
    const checkInvitesFriends = async () => {
      let currentFriendData = [];
      if (friends && friends.length > 0) {
        currentFriendData = friends.map(async (friend) => {
          const currentRoomJoinedId = await getCurrentRoomJoinedId(
            friend.friendId
          );
          return { ...friend, currentRoomJoinedId };
        });
      }
      const _currentFriends = await Promise.all(currentFriendData);
      setCurrentFriends(_currentFriends);
    };
    checkInvitesFriends();
  }, [friends]);

  useEffect(() => {
    const clearCopied = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 2000);
    return () => clearTimeout(clearCopied);
  }, [copied]);

  return (
    <ThemeProvider theme={theme}>
      <RounedDialog
        isOpen={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          <Box
            fontWeight="fontWeightBold"
            display="flex"
            justifyContent="center"
          >
            {t("tables.tableSetting")}
          </Box>
          <Box display="flex" justifyContent="center" p={2}>
            <AvatarUpload
              avatar={values.tableAvatar}
              name="avatar"
              id="avatar"
              provider="table"
              imageProp={image}
              setImageProp={setImage}
            />
          </Box>
        </DialogTitle>
        <DialogContent
          className={classes.tableForm}
          style={{ overflowY: "scroll" }}
        >
          <BackDrop open={loading} className={classes.backdrop} />
          <Box>
            <MuiInput
              autoFocus={true}
              name="tableName"
              label={t("tables.tableName")}
              type="text"
              value={values.tableName}
              required={false}
              onChange={handleChangeValues}
              maxLength={MAX_CHARACTER_NAME}
            />
          </Box>

          <Box>
            <RadioGroup
              name="joinTableRole"
              label={t("tables.joinTableRole")}
              value={values.joinTableRole}
              items={joinTableItems}
              onChange={handleChangeValues}
              className={classes.radio}
            />
          </Box>
          <Box
            style={values.joinTableRole === "open" ? {} : { display: "none" }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={handleCheckBoxChange}
                  name="notify"
                  value={values.notify}
                />
              }
              label={t("tables.notify")}
              //   label="テーブル作成をオンライン中のユーザーに通知"
            />
          </Box>
          <Box>
            <MuiInput
              name="description"
              label={t("tables.description")}
              type="text"
              value={values.description}
              required={false}
              onChange={handleChangeValues}
              maxLength={MAX_CHARACTER_DESC}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="secondary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={handleCheckBoxChange}
                  name="isGChaoComputer"
                  value={values.isGChaoComputer}
                />
              }
              label={t("tables.gChaosIsComputer")}
              //   label="G-Chaosをコンピュータが担当する"
            />
          </Box>
          <Box
            display={
              values.joinTableRole === "open" && values.notify ? "none" : ""
            }
          >
            <FormControl
              style={{ width: "100%" }}
              className={classes.formControl}
            >
              <InputLabel id="demo-mutiple-chip-label">
                {t("tables.inviteFriends")}
              </InputLabel>
              <Select
                labelId="demo-mutiple-chip-label"
                id="demo-mutiple-chip"
                multiple
                value={invites}
                onChange={handleChangeInvites}
                input={<Input id="select-multiple-chip" />}
                renderValue={(selected) => (
                  <Box className={classes.chips}>
                    {selected.map((value) => (
                      <Chip
                        avatar={
                          <Avatar alt={value.nickName} src={value.avatar} />
                        }
                        key={value.friendId}
                        label={value.nickName}
                        className={classes.chip}
                        onDelete={() => {}}
                        deleteIcon={
                          <HighlightOff
                            onMouseDown={(e) => handleDelete(value.friendId, e)}
                          />
                        }
                      />
                    ))}
                  </Box>
                )}
                MenuProps={MenuProps}
              >
                {currentFriends &&
                  currentFriends.map((friendValue) => (
                    <MenuItem
                      key={friendValue.friendId}
                      value={friendValue}
                      disabled={friendValue.currentRoomJoinedId}
                    >
                      {friendValue.nickName}
                    </MenuItem>
                  ))}
              </Select>
            </FormControl>
          </Box>
          <Box>
            <MuiInput
              name="message"
              label={t("tables.message")}
              type="text"
              value={values.message}
              required={false}
              onChange={handleChangeValues}
              maxLength={MAX_CHARACTER_MESSAGE}
            />
          </Box>
          <Box>
            <FormControl
              style={{ width: "100%" }}
              className={classes.formControl}
            >
              <Tooltip title={copied ? t("tables.copied") : ""}>
                <CopyToClipboard
                  text={values.tableUrl}
                  onCopy={() => setCopied(true)}
                >
                  <Button
                    style={{
                      backgroundColor: "#ecbd2a",
                      padding: "0.5em 1.5em",
                      borderRadius: "0",
                    }}
                  >
                    {t("tables.copyURL")}
                  </Button>
                </CopyToClipboard>
              </Tooltip>
              <input
                name="tableUrl"
                id="tableUrl"
                value={values.tableUrl}
                style={{ display: "none" }}
                onChange={() => {}}
              ></input>
            </FormControl>
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "1em 2em" }}>
          <Button onClick={onClose} className="button-style-1__outline">
            {t("tables.cancel")}
          </Button>
          <Button
            onClick={handleCreateTable}
            disabled={String(values.tableName).trim() === ""}
            className="button-style-1"
          >
            {t("tables.createTable")}
          </Button>
        </DialogActions>
      </RounedDialog>
    </ThemeProvider>
  );
};
TableSetting.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};
export default TableSetting;
