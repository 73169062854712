import { Box, Container, Tab, Tabs } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import React, { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router";
import Header from "../../components/Header";
import useStyles from "./styles";
import AccountSettings from "../AccountSettings";
import TableSettings from "../TableSettings";
import PaymentSettings from "../PaymentSettings/index";
import { useTranslation } from "react-i18next";
import theme from "../../theme";
import { useDispatch, useSelector } from "react-redux";
import { Redirect } from "react-router-dom";
import InvoiceSetting from "../InvoiceSettings";
import PurchaseManagement from "../PurchaseManagement";
import PlayTimeInfoBox from "../../components/PlayTimeInfoBox";
import ByRetailPackageDialog from "../../components/Dialog/ByRetailPackageDialog";
import BySubDialog from "../../components/Dialog/BySubDialog";
// import { SET_LOADING_GLOBAL } from "../../store/types";
import { BUYING_PLAN_OR_PACKAGE } from "../../store/types";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const Settings = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const user = useSelector((state) => state.user);
  const minScale = useSelector((state) => state.size.minScale);
  const [innerSize, setInnerSize] = useState({});
  const [showDialogBySub, setShowDialogBySub] = useState(false);
  const [showDialogByRetail, setShowDialogByRetail] = useState(false);

  useDocumentTitle(t("common.gConceptTitle") + t("common.settingTitle"));

  const handleRedirect = useCallback(() => {
    handleChangeValue(2);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = useCallback((newValue) => {
    setValue(newValue);
  }, []);

  const handleShowDialogBySub = useCallback((value) => {
    setShowDialogBySub(value);
  }, []);

  const handleShowDialogByRetail = useCallback((value) => {
    setShowDialogByRetail(value);
  }, []);

  const handleLoading = useCallback((_value) => {
    dispatch({
      type: BUYING_PLAN_OR_PACKAGE,
      payload: _value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (minScale) {
      const tabsWidth = minScale < 0.4 ? 150 : 300;
      const tabsHeight = user?.providerId === "password" ? 350 : 280;
      const fonSize12 = minScale < 0.4 ? 11 : 16;
      const paddingLeft = Math.round(24 * minScale);
      setInnerSize({
        tabsWidth,
        tabsHeight,
        fonSize12,
        paddingLeft,
      });
    } else {
      const tabsWidth = 300 * 1;
      const tabsHeight = user?.providerId === "password" ? 315 : 255;
      const fonSize12 = 16 * 1;
      const paddingLeft = 24 * 1;
      setInnerSize({
        tabsWidth,
        tabsHeight,
        fonSize12,
        paddingLeft,
      });
    }
  }, [minScale, user?.providerId]);

  useEffect(() => {
    if (localStorage.getItem("settingIndexPage")) {
      const index = JSON.parse(localStorage.getItem("settingIndexPage"));
      setValue(index);
    }
    return () => {
      localStorage.setItem("settingIndexPage", JSON.stringify(0));
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const classes = useStyles({
    tabsWidth: innerSize.tabsWidth,
    tabsHeight: innerSize.tabsHeight,
    fonSize12: innerSize.fonSize12,
    paddingLeft: innerSize.paddingLeft,
  });
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const goBack = () => {
    history.push("/tables");
  };

  if (user.currentRoomJoinedId && user.currentRoomJoinedId != "") {
    return <Redirect push to="/tables" />;
  }

  return (
    <ThemeProvider theme={theme}>
      <Container
        maxWidth="xl"
        className={classes.root}
        style={{ height: "100%", overflow: "hidden" }}
      >
        <Header />
        <Container className={classes.settings}>
          <Box className={classes.accountSettings}>
            <Tabs
              orientation="vertical"
              value={value}
              onChange={handleChange}
              aria-label="Vertical tabs example"
              className={classes.tabs}
              classes={{
                indicator: classes.indicator,
              }}
            >
              <Tab
                label={t("settings.userSetting")}
                className={classes.singleTab}
              />
              {user && !user.firstLogin ? (
                <Tab
                  label={t("settings.tableSetting")}
                  className={classes.singleTab}
                />
              ) : null}
              {user && !user.firstLogin ? (
                <Tab
                  label={t("settings.settingPackages")}
                  className={classes.singleTab}
                />
              ) : null}
              {user && !user.firstLogin ? (
                <Tab
                  label={t("settings.purchaseManagement")}
                  className={classes.singleTab}
                />
              ) : null}
              {user && !user.firstLogin ? (
                <Tab
                  label={t("settings.invoice")}
                  className={classes.singleTab}
                />
              ) : null}
            </Tabs>
            {value === 0 && <AccountSettings goBack={goBack} />}
            {value === 1 && <TableSettings goBack={goBack} />}
            {value === 2 && <PaymentSettings goBack={goBack} />}
            {value === 3 && (
              <PurchaseManagement
                goBack={goBack}
                handleChangeValue={handleChangeValue}
              />
            )}
            {value === 4 && <InvoiceSetting goBack={goBack} />}
          </Box>
        </Container>
      </Container>
      {/* time remain clock */}
      {user && !user.firstLogin && (
        <Box className={classes.timeRemainClockBox}>
          <PlayTimeInfoBox
            handleUpPlan={() => handleShowDialogBySub(true)}
            handleByPlayTime={() => handleShowDialogByRetail(true)}
          />
        </Box>
      )}
      {/* by subscribe package */}
      <BySubDialog
        openDialog={showDialogBySub}
        goBack={() => handleShowDialogBySub(false)}
        handleLoading={handleLoading}
        handleRedirect={handleRedirect}
      />
      {/* By Retail package dialog */}
      <ByRetailPackageDialog
        openDialog={showDialogByRetail}
        goBack={() => handleShowDialogByRetail(false)}
        // handleLoading={handleLoading}
        handleRedirect={handleRedirect}
      />
      s
    </ThemeProvider>
  );
};

export default Settings;
