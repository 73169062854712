import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  packageImageBox: {
    width: "35px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: "10px",

    "& img": {
      width: "100%",
    },
  },
  toolbar: {
    backgroundColor: "#e9e1d4",
    minHeight: "50px",
    padding: "0px !important",
  },
  tittle: {
    textDecoration: "none",
    color: "inherit",
    height: 50,
    width: (props) => `${props.introImgSize}px`,
    backgroundColor: "#214b67",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  png: {
    width: (props) => `${props.introImgSize}px`,
    // height: (props) => `${props.introImgHeight}px`,
  },
  buttonLogin: {
    textTransform: "capitalize",
    backgroundColor: "#dd501f",
    color: "white",
    minHeight: "50px",
    borderRadius: 0,
  },
  buttonRegister: {
    textTransform: "capitalize",
    backgroundColor: "#dfaf9c",
    minHeight: "50px",
    borderRadius: 0,
    "& span": {
      "&:hover": {
        color: "white",
      },
    },
  },

  buttonLanguages: {
    textTransform: "capitalize",
    backgroundColor: "#ffffff",
    fontWeight: 500,
    color: "#3f0202",
    borderRadius: 0,
    minHeight: "50px",
    fontSize: "14px !important",
    padding: (props) => `0 ${props.btnLanguage}px !important`,
  },
  avatar: {
    width: "30px",
    height: "30px",
    margin: "0 5px",
    backgroundColor: "#4b4b4c",
  },
  drawerCustum: {
    "& .MuiDrawer-paper": {
      top: "6%",
      height: "94%",
    },
  },
  nickName: {},
  notifications: {
    width: "350px",
    height: "100%",
    boxSizing: "border-box",
    padding: "10px",
  },
  notifyItem: {
    boxSizing: "border-box",
    width: "100%",
    height: "100px",
    marginBottom: "10px",
    opacity: "0.8",
    cursor: "pointer",
    "&:hover": {
      opacity: "1",
    },
    transition: "all .3s ease-in-out",
    color: "#ffffff",
    background: `rgba(0,0,0,0.5)`,
  },
  menuSettings: {
    border: "none !important",
    display: "flex",
    backgroundColor: "#dd501f",
    minHeight: "50px",
    alignItems: "center",
    flexDirection: "flex-end",
    "&:hover": {
      cursor: "pointer",
    },
  },
  logoContainer: {
    height: "50px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    flex: 1,
  },
  iconButton: {
    paddingLeft: (props) => `${props.iconWidth}em `,
    paddingRight: (props) => `${props.iconWidth}em `,
    minHeight: "50px !important",
    "& .MuiButtonBase-root": {
      "&:hover": {
        backgroundColor: "transparent",
      },
    },
  },
  dropDownIcon: {},
}));

export default useStyles;
