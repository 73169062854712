import React from "react";
import { Box } from "@mui/material";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";

const ContactSuccess = () => {
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  useDocumentTitle(t("common.gConceptTitle") + t("common.contactSuccessTitle"));

  return (
    <Box className={classes.root}>
      <div className={classes.contaier}>
        <p className={classes.title}>{t("contact.contactSuccessPageTitle")}</p>
        <div className={classes.btns}>
          <button
            className={classes.btnGoContact}
            onClick={() => history.push("/contact")}
          >
            {t("contact.goContactPage")}
          </button>
          <button
            className={classes.btnGoHome}
            onClick={() => history.push("/")}
          >
            {t("contact.goHomePage")}
          </button>
        </div>
      </div>
    </Box>
  );
};

export default ContactSuccess;
