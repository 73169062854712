/* eslint-disable no-unused-vars */
import React from "react";
import PropTypes from "prop-types";
import { ListFriendGroup, ListFriend, ListFriendItem } from "./styles";
import { useSelector } from "react-redux";

const ListFriendOfGroup = ({ threadId }) => {
  const dataListRoom = useSelector((state) => state.chat.dataListRoom);
  const friendList = dataListRoom?.filter((f) => f.threadId === threadId)[0];
  const threadMembers = friendList?.threadMembers;
  return (
    <ListFriendGroup>
      <ListFriend>
        {threadMembers?.map((member, index) => (
          <ListFriendItem key={index}>{member.memberName}</ListFriendItem>
        ))}
      </ListFriend>
    </ListFriendGroup>
  );
};

ListFriendOfGroup.propTypes = {
  threadId: PropTypes.string,
};

export default ListFriendOfGroup;
