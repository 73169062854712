/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import useStyles from "./styles";
import { Box } from "@mui/material";
import { contactValidateInput } from "../../utils/validation";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { useHistory } from "react-router-dom";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useSelector } from "react-redux";
import { sendMailContact } from "../../store/actions/adminAction";
import moment from "moment";
import useDocumentTitle from "../../hooks/useDocumentTitle";

moment.locale("ja");

const initialContactData = {
  contactType: "",
  content: "",
  fullName: "",
  email: "",
  numberPhone: "",
};

const contactTypeJP = {
  playRole: "遊び方について",
  playTime: "プレイ回数について",
  payment: "決済について",
  support: "サポートについて",
  other: "その他",
};

const Contact = () => {
  // variables
  const { t } = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const [values, setValues] = useState(initialContactData);
  const [errors, setErrors] = useState({});
  const [sendError, setSendError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const numberPhone = useSelector((state) => state.user.numberPhone);
  const email = useSelector((state) => state.user.email);
  const fullName = useSelector((state) => state.user.fullName);

  useDocumentTitle(t("common.gConceptTitle") + t("common.contactTitle"));

  //functions
  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setErrors({});
    const err = contactValidateInput(values);
    const webhookUrl =
      "https://hooks.slack.com/services/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";

    if (err) {
      setErrors((prevErr) => ({ ...prevErr, ...err }));
    }
    if (err === "") {
      const data = {
        text: ` 
          ${moment(Date.now()).format("ll")}
          お問い合わせの種類: ${contactTypeJP[values.contactType]}
          フルネーム: ${values.fullName}
          メールアドレス: ${values.email}
          電話番号: ${values.numberPhone}
          -----------------------------------------------
          お問い合わせの内容: ${values.content}
        `,
      };

      let res = await axios.post(webhookUrl, JSON.stringify(data), {
        withCredentials: false,
        transformRequest: [
          (_data, headers) => {
            delete headers.post["Content-Type"];
            return _data;
          },
        ],
      });
      if (res.status === 200) {
        const { email, fullName, contactType, content } = values;
        setIsLoading(true);
        await sendMailContact(
          email,
          fullName,
          contactTypeJP[contactType],
          content
        );
        history.push("/contact-success");

        //clear state so text boxes clear
      } else {
        setSendError(true);
      }
    }
  };

  useEffect(() => {
    const clearError = setTimeout(() => {
      setSendError(false);
    }, 3000);
    return () => clearTimeout(clearError);
  }, [sendError]);

  useEffect(() => {
    if (email || fullName || numberPhone) {
      setValues({
        ...values,
        email: email,
        numberPhone: numberPhone,
        fullName: fullName,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [email, fullName, numberPhone]);

  return (
    <Box className={classes.root}>
      <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <div className={classes.contaier}>
        <form onSubmit={handleSubmit}>
          <div className={classes.title}>
            <h2>{t("contact.inquiry")}</h2>
          </div>
          <div className={classes.field}>
            <div className={classes.fieldRight}>
              <span className={classes.fieldRightTitle}>
                {t("contact.inquiryType")}
              </span>
              <span className={classes.fieldRightMust}>
                {t("contact.required")}
              </span>
            </div>
            <div className={classes.fieldLeft}>
              <div className={classes.fieldLefRadioGroup}>
                <label htmlFor="playRole">
                  <input
                    type="radio"
                    id="playRole"
                    name="contactType"
                    value="playRole"
                    onChange={handleChangeValues}
                  />
                  <span>{t("contact.playRole")}</span>
                </label>
                <label htmlFor="playTime">
                  <input
                    type="radio"
                    id="playTime"
                    name="contactType"
                    value="playTime"
                    onChange={handleChangeValues}
                  />
                  <span>{t("contact.playTime")}</span>
                </label>
                <label htmlFor="payment">
                  <input
                    type="radio"
                    id="payment"
                    name="contactType"
                    value="payment"
                    onChange={handleChangeValues}
                  />
                  <span>{t("contact.payment")}</span>
                </label>
                <label htmlFor="support">
                  <input
                    type="radio"
                    id="support"
                    name="contactType"
                    value="support"
                    onChange={handleChangeValues}
                  />
                  <span>{t("contact.support")}</span>
                </label>
                <label htmlFor="other">
                  <input
                    type="radio"
                    id="other"
                    name="contactType"
                    value="other"
                    onChange={handleChangeValues}
                  />
                  <span>{t("contact.other")}</span>
                </label>
              </div>
            </div>
          </div>
          {errors?.contactType && (
            <div
              style={{
                display: "flex",
                alignItems: "start",
                boxSizing: "border-box",
              }}
            >
              <div className={classes.fieldRight}></div>
              <span
                className={classes.textErr}
                style={{
                  textAlign: "center",
                  display: "flex",
                  marginLeft: "10px",
                }}
              >
                {t(errors?.contactType)}
              </span>
            </div>
          )}
          <div className={classes.field}>
            <div className={classes.fieldRight}>
              <span className={classes.fieldRightTitle}>
                {t("contact.contentOfInquiry")}
              </span>
              <span className={classes.fieldRightMust}>
                {t("contact.required")}
              </span>
            </div>
            <div className={classes.fieldLeft}>
              <div>
                <label htmlFor="content">
                  <textarea
                    type="text"
                    name="content"
                    className={classes.fieldTextArea}
                    placeholder={t("contact.enterInquiry")}
                    onChange={handleChangeValues}
                  />
                </label>
                {errors?.content && (
                  <span className={classes.textErr}>{t(errors?.content)}</span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.field}>
            <div className={classes.fieldRight}>
              <span className={classes.fieldRightTitle}>
                {t("common.fullName")}
              </span>
              <span className={classes.fieldRightMust}>
                {t("contact.required")}
              </span>
            </div>
            <div className={classes.fieldLeft}>
              <div>
                <label htmlFor="fullName">
                  <input
                    type="text"
                    name="fullName"
                    className={classes.fieldLeftInput}
                    placeholder={t("contact.enterName")}
                    onChange={handleChangeValues}
                    value={values.fullName}
                  />
                </label>
                {errors?.fullName && (
                  <span className={classes.textErr}>{t(errors?.fullName)}</span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.field}>
            <div className={classes.fieldRight}>
              <span className={classes.fieldRightTitle}>
                {t("common.email")}
              </span>
              <span className={classes.fieldRightMust}>
                {t("contact.required")}
              </span>
            </div>
            <div className={classes.fieldLeft}>
              <div>
                <label htmlFor="email">
                  <span className={classes.subHeader}>
                    {t("contact.confirmEmailTitle")}
                  </span>
                  <input
                    type="text"
                    name="email"
                    className={classes.fieldLeftInput}
                    placeholder={t("contact.enterMail")}
                    onChange={handleChangeValues}
                    value={values.email}
                  />
                </label>
                {errors?.email && (
                  <span className={classes.textErr}>{t(errors?.email)}</span>
                )}
              </div>
            </div>
          </div>
          <div className={classes.field}>
            <div className={classes.fieldRight}>
              <span className={classes.fieldRightTitle}>
                {t("common.phone")}
              </span>
              <span className={classes.fieldRightMust}>
                {t("contact.required")}
              </span>
            </div>
            <div className={classes.fieldLeft}>
              <div>
                <label htmlFor="numberPhone">
                  <input
                    type="text"
                    name="numberPhone"
                    className={classes.fieldLeftInput}
                    placeholder={t("contact.enterPhone")}
                    onChange={handleChangeValues}
                    value={values.numberPhone}
                  />
                </label>
                {errors?.numberPhone && (
                  <span className={classes.textErr}>
                    {t(errors?.numberPhone)}
                  </span>
                )}
              </div>
            </div>
          </div>
          {sendError && (
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "red",
              }}
            >
              {t("contact.sendInquiryFail")}
            </span>
          )}

          <div className={classes.btnWrapper}>
            <button
              className={classes.btnBlue}
              type="button"
              onClick={() => history.push("/")}
            >
              戻る
            </button>
            <button className={classes.btn} type="submit">
              {t("common.send")}
            </button>
            <div
              className={classes.btn}
              style={{ background: "none", boxShadow: "none" }}
            ></div>
          </div>
        </form>
      </div>
    </Box>
  );
};

Contact.propTypes = {};

export default Contact;
