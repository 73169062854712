import styled from "styled-components";

// Narration15DetermineCall
export const NumberOfCallContainer = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
export const NumberOfCallItem = styled.div`
  width: 68%;
  height: 40px;
  box-sizing: border-box;
  background-color: #ecbd2a;
  display: flex;
  margin-bottom: 2px;
`;

export const NicknameWrapper = styled.div`
  width: 60%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 0.5em;
`;

export const Nickname = styled.p`
  width: 100%;
  padding-left: 4px;

  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;

export const NumberOfCalls = styled.div`
  width: 40%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #e95316;
  color: white;
  font-weight: 500;
`;

export const Number = styled.div`
  width: fit-content;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const TextCall = styled.span`
  height: 100%;
  /* font-size: 1em; */
  margin-left: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StyleOctagon = styled.div`
  width: ${(props) => Math.round(props.size)}px;
  height: ${(props) => Math.round(props.size)}px;
  background-image: url("/assets/icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
  padding-left: 4px;
`;
