/* eslint-disable react/prop-types */
import React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { MdWarning } from "react-icons/md";
import { Typography } from "@material-ui/core";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const DialogConfirm = ({
  open,
  handleClose,
  handleSubmit,
  contentConfirm,
  isNoShowConfirmButton,
  okLabel,
  cancelLabel,
}) => {
  const classes = useStyles({});
  const { t } = useTranslation();

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={classes.dialogWrap}
      classes={{ paper: classes.paper }}
    >
      <DialogTitle style={{ display: "flex", alignItems: "center" }}>
        <MdWarning className={classes.icon} />
        <Typography className={classes.title}>
          {t("paymentSettings.confirmation")}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText className={classes.description}>
          {contentConfirm}
        </DialogContentText>
      </DialogContent>
      <DialogActions className={classes.btns}>
        <Button onClick={handleClose} className={classes.buttonBlue}>
          {cancelLabel}
        </Button>

        {!isNoShowConfirmButton && (
          <Button onClick={handleSubmit} className={classes.buttonRed}>
            {okLabel}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DialogConfirm;
