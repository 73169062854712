import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  Box,
  Button,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import { AiOutlineDownload } from "react-icons/ai";
import { BiSearchAlt } from "react-icons/bi";
import { FaEdit } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";

import { toast } from "react-toastify";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";
import {
  deleteInvoiceById,
  getAllInvoices,
  getInvoiceListPagination,
} from "../../../../store/actions/invoiceAction";
import { numberWithCommas } from "../../../../utils/common";
import DialogConfirm from "../../../GameScreen/components/Dialog/DialogConfirm";
import HeaderCells from "../../../InvoiceSettings/components/HeaderCells";
import { Dialog } from "../index";
import useStyles from "./styles";

const headerCSV = [
  { label: "請求番号 Code", key: "invoiceId" },
  { label: "請求番号", key: "paymentGatewayInvoiceNumber" },
  { label: "メールアドレス", key: "email" },
  { label: "請求日", key: "completeDate" },
  { label: "金額", key: "amount" },
  { label: "決済ステータス", key: "status" },
];

const Invoices = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("paymentGatewayInvoiceNumber");
  const [page, setPage] = useState(0); // eslint-disable-next-line react-hooks/exhaustive-deps
  const [rows, setRows] = useState([]);
  const [openModal, setOpenModal] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const [selectedInvoiceId, setselectedInvoiceId] = useState("");
  const [term, setTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [pageSize] = useState(7);
  const [allData, setAllData] = useState([]);
  const [isDownLoad, setIsDownLoad] = useState(false);
  const [openDialogEdit, setOpenDialogEdit] = useState(false);
  const [invoiceSelectEdit, setInvoiceSelectEdit] = useState({});

  const handleLoading = useCallback((_isLoading) => {
    setIsLoading(_isLoading);
  }, []);

  useDocumentTitle(t("common.gConceptTitle") + t("common.invoiceTitle"));

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage - 1);
  };

  const csvLinkEl = document.getElementById("down-report");

  const headCells = useMemo(
    () => [
      {
        id: "paymentGatewayInvoiceNumber",
        label: `${t("paymentSettings.invoiceNumber")}`,
      },
      { id: "email", label: `${t("paymentSettings.email")}` },
      { id: "completeDate", label: `${t("paymentSettings.billingDate")}` },
      { id: "amount", label: `${t("paymentSettings.price")}` },
      { id: "status", label: `${t("paymentSettings.paymentStatus")}` },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStorage.getItem("i18nextLng")]
  );

  const handleViewReceipt = (invoicePdfLink) => {
    window.open(`${invoicePdfLink}`, "_blank");
  };

  const handleSearch = (_term) => {
    setTerm(_term);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleClickInvoice = (_invoiceId) => {
    setselectedInvoiceId(_invoiceId);
    setOpenModal(true);
  };

  const handleOpenEditModal = (selectedInvoice) => {
    if (selectedInvoice) {
      let completeDate = selectedInvoice.completeDate;
      const dateFormat = moment(completeDate).format("YYYY/MM/DD");
      setInvoiceSelectEdit({ ...selectedInvoice, completeDate: dateFormat });
      setOpenDialogEdit(true);
    }
  };

  const downLoadReport = async () => {
    setIsDownLoad(true);
    const invoices = await getAllInvoices();

    if (invoices.data && invoices.data.length > 0) {
      const dataFilter = invoices.data.map((el) => {
        return {
          invoiceId: el.invoiceId,
          paymentGatewayInvoiceNumber: el.paymentGatewayInvoiceNumber,
          email: el.email,
          completeDate: el.completeDate,
          amount: el.amount,
          status: el.status,
        };
      });
      setAllData(dataFilter);
    }
    setIsDownLoad(false);
  };

  useEffect(() => {
    if (allData && allData.length > 0) {
      csvLinkEl.click();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allData]);

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order, page, pageSize]);

  const search = async () => {
    setIsLoading(true);
    const isOrder = order === "asc" ? true : false;
    const invoices = await getInvoiceListPagination(
      orderBy,
      term,
      isOrder,
      page,
      pageSize
    );
    const { elements, metaData } = invoices.data;
    if (elements && elements.length > 0) {
      setRows(elements);
      setTotalPage(metaData.totalPage);
    } else {
      setRows([]);
    }

    setIsLoading(false);
  };

  const handleDeleteInvoice = async () => {
    setOpenModal(false);
    setIsLoading(true);

    if (!selectedInvoiceId) return;
    const resulf = await deleteInvoiceById(selectedInvoiceId);

    if (resulf) {
      await search();
      toast.success(t("admin.deleteInvoiceSuccess"));
    } else {
      toast.error(t("admin.deleteInvoiceFail"));
    }

    setIsLoading(false);
  };

  return (
    <Box>
      <Box className={classes.headerWapper}>
        <Box className={classes.headerRight}>
          <Typography className={classes.headerTitle}>
            {t("paymentSettings.invoiceHistory")}
          </Typography>
        </Box>
        <Box style={{ display: "flex" }}>
          {isDownLoad ? (
            <LoadingButton
              loading
              loadingPosition="start"
              startIcon={<AiOutlineDownload />}
              variant="outlined"
              className={classes.headerBtnsDownFile}
              type="button"
            >
              {t("paymentSettings.downLoad")}
            </LoadingButton>
          ) : (
            <Button
              variant="outlined"
              startIcon={<AiOutlineDownload />}
              className={classes.headerBtnsDownFile}
              onClick={downLoadReport}
              type="button"
            >
              {t("paymentSettings.downLoad")}
            </Button>
          )}

          <CSVLink
            data={allData}
            filename="report.csv"
            id="down-report"
            headers={headerCSV}
          />
          <form
            className={classes.btnSearchBox}
            onSubmit={(e) => {
              e.preventDefault();
              setPage(0);
              search();
            }}
          >
            <input
              placeholder={`${t("admin.enterSearchMail")}`}
              type="text"
              onChange={(e) =>
                handleSearch(e.target.value.toString().trim().toLowerCase())
              }
            />
            <IconButton
              color="secondary"
              aria-label="seach button"
              type="submit"
            >
              <BiSearchAlt />
            </IconButton>
          </form>
        </Box>
      </Box>
      <Box className={classes.invoice}>
        <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{ backgroundColor: "transparent", boxShadow: "unset" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              className={classes.table}
              style={{ minWidth: "unset" }}
            >
              <HeaderCells
                headCells={headCells}
                orderBy={orderBy}
                order={order}
                createSortHandler={createSortHandler}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" className={classes.tableCell}>
                        {row.paymentGatewayInvoiceNumber}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.email}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {moment(row.completeDate).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {`${numberWithCommas(row.amount)} 円`}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.status === "paid"
                          ? `${t("paymentSettings.paid")}`
                          : `${t("paymentSettings.unpaid")}`}
                      </TableCell>
                      <TableCell align="left" className={classes.tableCell}>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => handleClickInvoice(row?.invoiceId)}
                          style={{ marginRight: "1rem" }}
                        >
                          <AiOutlineDelete />
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          color="primary"
                          onClick={() => handleOpenEditModal(row)}
                          style={{ marginRight: "1rem" }}
                        >
                          <FaEdit />
                        </IconButton>
                        {row?.status === "paid" && (
                          <Button
                            variant="contained"
                            onClick={() =>
                              handleViewReceipt(row?.invoicePdfLink)
                            }
                          >
                            {t("paymentSettings.viewReceipt")}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {rows && rows.length <= 0 && (
              <Box sx={{ padding: "2rem 0", textAlign: "center" }}>
                <Typography>{t("dialog.noData")}</Typography>
              </Box>
            )}
          </TableContainer>
          <Box className={classes.invoiceBottom}>
            {rows && rows.length > 0 && (
              <Stack spacing={2} alignItems="center" height="100%">
                <Pagination
                  count={totalPage}
                  className={classes.pagination}
                  onChange={handleChangePage}
                  page={page + 1}
                />
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
      <DialogConfirm
        open={openModal}
        handleClose={handleCloseModal}
        handleSubmit={() => handleDeleteInvoice()}
        contentConfirm={t("admin.deleteInvoiceTitle")}
        okLabel={t("paymentSettings.oke")}
        cancelLabel={t("paymentSettings.cancel")}
      />

      <Dialog.EditInvoice
        handleClose={() => setOpenDialogEdit(false)}
        open={openDialogEdit}
        invoiceData={invoiceSelectEdit}
        handleLoading={handleLoading}
        reloadData={search}
      />
    </Box>
  );
};

export default Invoices;
