/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  admin: {
    width: "100%",
    height: "100%",
    background: "#fff",
    display: "flex",
    position: "relative",
    boxSizing: "border-box",
  },
  siderBar: {
    position: "fixed",
    width: "18rem",
    height: "100%",
    boxShadow: "0px 0px 10px 0px #ede7e7",
    transform: (props) =>
      `${props.toggleSiderBar ? "translateX(0)" : "translateX(-18rem)"}`,
    transition: "0.3s ease",
  },

  adminBody: {
    width: "100%",
    marginLeft: (props) => `${props.toggleSiderBar ? "18rem" : 0}`,
    padding: "0 10px",
    transition: "0.3s ease",
  },

  adminHeader: {
    position: "static",
    width: "100%",
    height: "50px",
  },
  adminContent: {
    height: "100%",
    marginTop: "1rem",
    boxShadow: "0px 0px 20px 0px #ede7e7",
    padding: "1rem",
  },
}));

export default useStyles;
