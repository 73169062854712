import { IconButton } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { CountdownCircleTimer } from "react-countdown-circle-timer";
import { useTranslation } from "react-i18next";
import { ImHourGlass } from "react-icons/im";
import { useDispatch, useSelector } from "react-redux";
import {
  COLOR_BLUE_LIGHT,
  COLOR_YELLOW,
} from "../../pages/GameScreen/components/constants";
import BasicDialog from "../../pages/GameScreen/components/NewDialog/BasicDialog";
import { sendTimeOut } from "../../store/actions/gameActions";
import { Timer, TimerContainer } from "./style";

/**
 * render time
 * @param {number} remainingTime
 * @returns
 */
const renderTime = ({ remainingTime }) => {
  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <div style={{ fontSize: "24px", color: "yellow" }}>{remainingTime}</div>
    </div>
  );
};
renderTime.propTypes = {
  remainingTime: PropTypes.number,
};

const TimerComponent = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // useState
  const [startTimer, setStartTimer] = useState(false);
  const [showEndGameDialog, setShowEndGameDialog] = useState(false);

  // useSelector
  const playerId = useSelector((state) => state.user.playerId);
  const owner = useSelector((state) => state.room.table.owner);
  const gameId = useSelector((state) => state.playerGameState.gameId);

  const isOwner = () => {
    return owner === playerId;
  };

  const handleTimeOut = () => {
    setShowEndGameDialog(false);
    dispatch(sendTimeOut());
  };

  if (!gameId) return <div style={{ flex: 1 }}></div>;
  if (isOwner() === false) return <div style={{ flex: 1 }}></div>;
  return (
    <TimerContainer>
      <Timer>
        {/* TIMER ICON */}
        {startTimer === false ? (
          <IconButton
            onClick={() => setStartTimer(true)}
            className="background-hourGlass"
            style={{ display: "block" }}
          >
            <ImHourGlass color="#311d0a" />
          </IconButton>
        ) : (
          <CountdownCircleTimer
            isPlaying
            size={50}
            strokeWidth={4}
            duration={10}
            colors={[COLOR_YELLOW, COLOR_BLUE_LIGHT, "#A30000", "#A30000"]}
            colorsTime={[7, 5, 2, 0]}
            onComplete={() => {
              setStartTimer(false);
              setShowEndGameDialog(true);
              return [true, 0];
            }}
          >
            {renderTime}
          </CountdownCircleTimer>
        )}
      </Timer>
      {
        // END GAME DIALOG
        showEndGameDialog ? (
          <BasicDialog
            isOpen={showEndGameDialog}
            onCancel={handleTimeOut}
            onAgree={() => setShowEndGameDialog(false)}
            closeText={t("game.endGame")}
            agreeText={t("game.continueGame")}
            mainText={t("game.confirmContinue")}
          />
        ) : null
      }
    </TimerContainer>
  );
};

export default React.memo(TimerComponent);
