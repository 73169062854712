import React from "react";
import { useSelector } from "react-redux";

import { makeStyles } from "@material-ui/core";
import MenuTop from "./components/MenuTop";
import MenuBottom from "./components/MenuBottom";
import MenuMiddle from "./components/MenuMiddle";

const useStyles = makeStyles(() => ({
  menuRoot: {
    height: (props) => props.height,
    width: (props) => props.width,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    alignItems: "center",
  },
}));

export const Menu = () => {
  const menuWidth = useSelector((state) => state.size.menuWidth);
  const menuHeight = useSelector((state) => state.size.height);
  const classes = useStyles({
    width: menuWidth,
    height: menuHeight,
  });
  return (
    <div id="gc-menu" className={classes.menuRoot}>
      <MenuTop />
      <MenuMiddle />
      <MenuBottom />
    </div>
  );
};
