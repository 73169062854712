import { Typography } from "@mui/material";
import styled from "styled-components";
import { makeStyles } from "@material-ui/core/styles";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import Container from "@material-ui/core/Container";
import { useTranslation } from "react-i18next";
// import useStyles from "./styles";

export const LogoGame = styled.div`
  aspect-ratio: 1/1;
  background: url("/assets/intro2.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const Production = styled.div`
  background: url("/assets/introtext2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-top: -30px;
`;
export const BoxWrapper = styled.div`
  position: relative;
  width: 1000px;
  text-align: center;
  // background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  margin-top: 1em;
  padding: 1em;
`;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    background: "none",
  },
  link: {
    textDecoration: "none",
    // marginLeft: theme.spacing(1),
    color: "#fff",
    "&:hover": {
      color: "#ccc8c9",
      textDecoration: "underline",
    },
  },
  button: {
    backgroundColor: "rgb(186,71,35)",
    borderRadius: "999px",
    border: "black 1px solid",
    padding: "7px 2em",
    marginTop: 40,
  },
  mainWarpper: {
    padding: (props) => `${props.setPadding}px`,
  },
}));

const PageNotFound = () => {
  const minScale = useSelector((state) => state.size.minScale);
  const logoGameWidth = 240;
  const logoGameHeight = 150;
  const productionWidth = 130;
  const productionHeight = 7;
  const boxWrapperWidth = 350;
  const [innerSize, setInnerSize] = useState({});
  const { t } = useTranslation();
  useEffect(() => {
    if (minScale) {
      const padding = Math.round(50 * minScale);
      setInnerSize({ padding });
    } else {
      const padding = 50;
      setInnerSize({ padding });
    }
  }, [minScale]);
  const classes = useStyles({
    setPadding: innerSize.padding,
  });
  return (
    <Container maxWidth="xl" className={classes.root}>
      <LogoGame width={logoGameWidth} height={logoGameHeight} />
      <Production width={productionWidth} height={productionHeight} />

      <BoxWrapper width={boxWrapperWidth}>
        <div className={classes.mainWarpper} />
        <Typography
          variant="body1"
          style={{ color: "#fff", fontSize: 110, fontWeight: 600 }}
        >
          {"404"}
        </Typography>
        <Typography variant="body1" style={{ color: "#fff", fontSize: 50 }}>
          {t("common.pageNotFound")}
        </Typography>
        <Typography style={{ fontSize: 25 }}>
          <a className={classes.link} href="/">
            {t("common.returnToHomePage")}
          </a>
        </Typography>

        <div className={classes.mainWarpper} />
      </BoxWrapper>
    </Container>
  );
};

export default PageNotFound;
