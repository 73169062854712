import React from "react";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import useStyles, { BoxWrapper, Space } from "./styles";
import Button from "../../../components/Button";
import { useTranslation } from "react-i18next";

const RegisterSuccess = () => {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();

  return (
    <BoxWrapper>
      <Typography className={classes.title}>
        {t("notifications.createAccountSuccess")}
      </Typography>
      <Space space={10} />
      <Typography className={classes.subTitle}>
        {t("notifications.accountRegistrationCompleted")}
      </Typography>
      <Space space={20} />
      <Button
        type="submit"
        variant="outlined"
        text="ログインする"
        className={classes.loginBtn}
        onClick={() => history.push("/login")}
      ></Button>
    </BoxWrapper>
  );
};

export default RegisterSuccess;
