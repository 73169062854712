import useOnlineStatus from "@rehooks/online-status";
import { orderBy } from "lodash";
import moment from "moment";
import PropTypes from "prop-types";
import { memo, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import noInRoom from "../../../../../../assets/icons/select-room.svg";
import firebase from "../../../../../../services/firebase";
import { chatRef } from "../../../../../../store/actions";
import { CHAT_TYPE } from "../../../../../../store/constant";
import ChatRepo from "../../../../../../store/repositories/chat";
import { SET_STATUS_ONLINE } from "../../../../../../store/types";
import ChatBoxGroup from "../ChatBoxGroup";
import ChatBoxTable from "../ChatBoxTable";

var connectedRef = firebase.database().ref(".info/connected");
const TIMEOUT_CHECK_CONNECT = 10;

const ChatBoxModalBodyContent = ({
  displayRoom = "",
  threadId = "",
  threadType = "",
}) => {
  const [messageList, setMessageList] = useState([]);
  const [dataRoom, setDataRoom] = useState({});
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const tablePlayers = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const minscale = useSelector((state) => state.size.minScale);

  const intervalRef = useRef(null);
  const counterRef = useRef(0);
  const [counter, setCounter] = useState(0);

  const onlineStatus = useOnlineStatus();

  useEffect(() => {
    if (counter >= TIMEOUT_CHECK_CONNECT) {
      clearInterval(intervalRef.current);
      intervalRef.current = null;
      dispatch({
        type: SET_STATUS_ONLINE,
        payload: false,
      });
    }
    // eslint-disable-next-line
  }, [counter]);

  useEffect(() => {
    if (onlineStatus === false) {
      intervalRef.current = setInterval(() => {
        connectedRef.once("value", (snap) => {
          if (snap.val() === true) {
            counterRef.current = 0;
            setCounter(0);
          } else {
            ++counterRef.current;
            setCounter(counterRef.current);
          }
        });
      }, 1000);
    } else {
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      intervalRef.current = null;
      counterRef.current = 0;
      setCounter(0);
      dispatch({
        type: SET_STATUS_ONLINE,
        payload: true,
      });
    }
    // eslint-disable-next-line
  }, [onlineStatus]);

  useEffect(() => {
    let unsubscribe = async () => {};
    if (threadId) {
      unsubscribe = chatRef
        .doc(threadId)
        .collection("messages")
        .onSnapshot((snapshot) => {
          const threadMembers = dataRoom?.threadMembers ?? [];
          const messages = snapshot.docs.map((doc) => {
            const { createdAt, message, senderId } = doc.data();
            const now = moment(createdAt).format("HH:mm");
            const members = threadMembers?.filter(
              (player) => player && player.memberId === senderId
            );
            if (members?.length > 0) {
              const { memberId, memberName, memberAvatar } = members[0];
              return {
                time: now,
                milliseconds: createdAt,
                avatar: memberAvatar,
                message: message,
                playerId: memberId,
                nickName: memberName,
              };
            }
            return null;
          });
          const sortedMessages = orderBy(
            messages.filter((m) => m !== null),
            "milliseconds"
          );
          setMessageList(sortedMessages);
        });
    }

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [threadId, dataRoom]);

  useEffect(() => {
    const readData = async () => {
      if (threadId) {
        const thread = await ChatRepo.read(threadId);
        setDataRoom(thread);
      }
    };
    readData();
  }, [threadId, tablePlayers]);

  return (
    <>
      {threadId && (
        <>
          {threadType === CHAT_TYPE.ROOM ? (
            <ChatBoxTable
              displayRoom={displayRoom}
              threadId={threadId}
              messageList={messageList}
            />
          ) : null}
          {threadType !== CHAT_TYPE.ROOM ? (
            <ChatBoxGroup
              isGroup={threadType === CHAT_TYPE.GROUP}
              displayRoom={displayRoom}
              threadId={threadId}
              messageList={messageList}
            />
          ) : null}
        </>
      )}

      {!threadId && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            flexDirection: "column",
            height: "100%",
          }}
        >
          <img src={noInRoom} style={{ width: "90%" }}></img>
          <p
            style={{
              textAlign: "center",
              fontSize: `${
                minscale > 0.5 ? `${minscale * 16}px` : `${0.5 * 16}px`
              }`,
              marginTop: `${
                minscale > 0.5 ? `${minscale * 20}px` : `${0.5 * 20}px`
              }`,
            }}
          >
            {t("dialog.PleaseChooseARoom")}
          </p>
        </div>
      )}
    </>
  );
};

ChatBoxModalBodyContent.propTypes = {
  displayRoom: PropTypes.string,
  threadId: PropTypes.string.isRequired,
  threadType: PropTypes.string,
};

export default memo(ChatBoxModalBodyContent);
