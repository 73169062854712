import {
  Box,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { ThemeProvider } from "@material-ui/core/styles";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { BiRefresh } from "react-icons/bi";
import { TiUserOutline } from "react-icons/ti";
import InfiniteScroll from "react-infinite-scroller";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import ByRetailPackageDialog from "../../components/Dialog/ByRetailPackageDialog";
import BySubDialog from "../../components/Dialog/BySubDialog";
import ConfirmMutipleDevices from "../../components/Dialog/ConfirmMutipleDevices";
import ShowInfo from "../../components/Dialog/ShowInfo";
import DialogTableSetting from "../../components/Dialog/TableSetting";
import Header from "../../components/Header";
import PlayTimeInfoBox from "../../components/PlayTimeInfoBox";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { subscribeToBronzePackage } from "../../store/actions/packageActions";
import {
  checkPlayerRemainPlays,
  getTrialStatus,
} from "../../store/actions/playTimeAction";
import { join, joinRequest } from "../../store/actions/roomActions";
import { TablePaging } from "../../store/actions/tableActions";
import { clearGameStateWhenOutRoom } from "../../store/actions/playerGameStateActions";
import {
  CLEAR_ALL_TWILIO,
  CLEAR_INSTRUCTOR_STATE,
  CLOSE_DIALOG_OVER_PLAY_TIME,
  SET_LOADING_GLOBAL,
  BUYING_PLAN_OR_PACKAGE,
  SET_TABLE_EMPTY,
  SET_TABLE_LIST,
} from "../../store/types";
import theme from "../../theme";
import BasicDialog from "../GameScreen/components/NewDialog/BasicDialog";
import OkDialog from "../GameScreen/components/NewDialog/OkDialog";
import { updatePlayerStatus } from "../../store/actions/userAction";

import useStyles, {
  TableImage,
  TableItem,
  TableList,
  TableName,
  TablePlayers,
} from "./styles";
import {
  CLEAR_NARRATION,
  CLEAR_NEWEST_PLAYER_OTHER_STATE,
} from "../../store/types";

const Tables = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [reload, setReload] = useState(false);
  const [roomData, setRoomData] = useState({});
  const [value, setValue] = useState("open");
  const [showDialogBySub, setShowDialogBySub] = useState(false);
  const [showDialogByRetail, setShowDialogByRetail] = useState(false);
  const [openDialogJoinRequest, setOpenDialogJoinRequest] = useState(false);
  const [openDialogWarningPlayerPlayTime, setOpenDialogWarningPlayerPlayTime] =
    useState(false);
  const tables = useSelector((state) => state.table.tables); //data store where ?
  const player = useSelector((state) => state.user);
  const playTimeId = useSelector((state) => state.user.playTimeId);
  const playerId = useSelector((state) => state.user.playerId);
  const [isClicked, setIsClicked] = useState(false);
  const trialStatus = useSelector(
    (state) => state.playTime.playTimeInfo.statusFree
  );

  const isLoading = useSelector((state) => state.size.isLoading ?? false);
  const isBuyingLoading = useSelector((state) => state.payment.isBuyingLoading ?? false);

  const currentRoomJoinedId = useSelector(
    (state) => state.user.currentRoomJoinedId
  );
  const readedConfirmMutipleDevices = useSelector(
    (state) => state.user.readedConfirmMutipleDevices
  );
  // const playerGameState = useSelector((state) => state.playerGameState);
  // const gameId = useSelector((state) => state.playerGameState.gameId);
  const dispatch = useDispatch();
  const history = useHistory();
  const [openErrorDialog, setOpenErrorDialog] = useState(false);
  const isInitialTable = useRef(true);
  const [tablePaging, setTablePaging] = useState(new TablePaging("open"));
  const [isCompleteLoad, setIsCompleteLoad] = useState(false);
  const [isOpenModelConfirm, setIsOpenModelConfirm] = useState(false);

  const handleRedirect = useCallback(() => {
    localStorage.setItem("settingIndexPage", JSON.stringify(2));
    history.push("/settings");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useDocumentTitle(t("common.gConceptTitle") + t("common.tableTitle"));
  useEffect(() => {
    const checkPlayTime = async () => {
      const isEnoughPlaytime = await checkPlayerRemainPlays(playTimeId);
      const isTrialStatus = await getTrialStatus(playTimeId);
      if (isEnoughPlaytime === undefined && isTrialStatus === undefined) return;
      if (isTrialStatus) return;
      if (isEnoughPlaytime) return;
      setOpenDialogWarningPlayerPlayTime(true);
    };
    checkPlayTime();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (playerId && currentRoomJoinedId === null) {
      dispatch({ type: CLEAR_NEWEST_PLAYER_OTHER_STATE });
      dispatch({ type: CLEAR_NARRATION });
      clearGameStateWhenOutRoom(playerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId, currentRoomJoinedId]);

  useEffect(() => {
    if (!isInitialTable.current) {
      return;
    }
    const joinGameByURL = async () => {
      let flagLoadTable = true;
      const gameUrl = localStorage.getItem("gameUrl") || ""; //localStorage
      let roomId = "";
      let accessToken = "";
      let tableId = "";
      if (playTimeId !== undefined && playTimeId !== "") {
        const isEnoughPlaytime = await checkPlayerRemainPlays(playTimeId);
        const isTrialStatus = await getTrialStatus(playTimeId);
        if (gameUrl && gameUrl !== "") {
          if (!isTrialStatus) {
            if (!isEnoughPlaytime) {
              localStorage.setItem("gameUrl", "");
              setOpenDialogWarningPlayerPlayTime(true);
              return;
            }
          }
          const split = gameUrl.split(/[?,/,=]/);
          roomId = split[2];
          accessToken = split[4];
          const roomData = {
            roomId,
            tableId,
          };
          dispatch({
            type: SET_LOADING_GLOBAL,
            payload: true,
          });
          const joinRes = await join(roomData, player, accessToken);
          dispatch({
            type: SET_LOADING_GLOBAL,
            payload: false,
          });
          setOpenErrorDialog(joinRes && joinRes.data ? true : false);
          // start load tables
          flagLoadTable = joinRes?.data;
          localStorage.removeItem("gameUrl");
        }
        if (flagLoadTable) {
          // start load tables
          setIsCompleteLoad(flagLoadTable);
        }
      }
    };
    joinGameByURL();
    isInitialTable.current = false;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [player]);

  useEffect(() => {
    if (!playTimeId) {
      subscribeToBronzePackage(playerId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playTimeId]);

  useEffect(() => {
    if (currentRoomJoinedId && currentRoomJoinedId !== "") {
      history.push(`game/${currentRoomJoinedId}`);
    }
    if (!currentRoomJoinedId) {
      dispatch({ type: CLEAR_ALL_TWILIO });
    }
  }, [history, currentRoomJoinedId, dispatch]);

  useEffect(() => {
    if (player && player.firstLogin) {
      history.push("/settings");
    }
  }, [player, history]);

  const handleClickOpen = async () => {
    // TODO: Check player remaining time
    const isEnoughPlaytime = await checkPlayerRemainPlays(playTimeId);
    await updatePlayerStatus(playerId);
    if (isEnoughPlaytime || trialStatus) {
      setOpen(true);
    } else {
      setOpenDialogWarningPlayerPlayTime(true);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSendJoinRequest = async () => {
    setOpenDialogJoinRequest(false);
    await joinRequest(roomData, player);
  };

  const handleCloseDialogJoinRequest = () => {
    setOpenDialogJoinRequest(false);
  };

  const reloadTables = async () => {
    setReload(!reload);
    await updatePlayerStatus(playerId);
    createNewTablePaging(value);
  };

  const handleFilter = async (e) => {
    setValue(e.target.value);
    createNewTablePaging(e.target.value);
  };
  const createNewTablePaging = (value) => {
    tablePaging.destroy();
    let newTablePaging = new TablePaging(value);

    if ("close" === value) {
      const includeIds = (player?.friends || []).map(
        (friend) => friend.friendId
      );
      // in case have no friend, add current ids to cheat
      includeIds.push(player.playerId);

      newTablePaging.addPrivateId(includeIds);
    }
    setTablePaging(newTablePaging);
    completedLoadTable();
  };

  const loadMoreTables = async () => {
    if (isCompleteLoad) {
      setIsCompleteLoad(false);
      await tablePaging.executeQuery(completedLoadTable);
    }
  };

  const completedLoadTable = () => {
    dispatch({
      type: SET_TABLE_LIST,
      payload: {
        tables: tablePaging.allDocs,
        tablePaging: tablePaging,
      },
    });
    setIsCompleteLoad(true);
  };

  // update tables when change type of filter
  useEffect(() => {
    dispatch({
      type: SET_TABLE_LIST,
      payload: {
        tables: tablePaging.allDocs,
        tablePaging: tablePaging,
      },
    });
  }, [dispatch, tablePaging]);

  // clear table Info  and unsubscribe all when component is destroy
  useEffect(() => {
    dispatch({ type: CLEAR_INSTRUCTOR_STATE });
    return () => {
      dispatch({ type: SET_TABLE_EMPTY });
    };
  }, [dispatch]);

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  const minScale = useSelector((state) => state.size.minScale);
  const [innerSize, setInnerSize] = useState({});

  const fontSize16 = useMemo(() => Math.round(16 * minScale), [minScale]);
  const fontSize24 = useMemo(() => Math.round(24 * minScale), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);

  useEffect(() => {
    if (!readedConfirmMutipleDevices) {
      setIsOpenModelConfirm(true);
    }
  }, [readedConfirmMutipleDevices]);

  useEffect(() => {
    if (minScale) {
      const searchAndFilterWidth =
        minScale < 0.7 ? Math.round(982 * minScale) : 982;
      const fontSize = Math.round(24 * minScale);
      const btnWidth = Math.round(203 * minScale);
      const btnHeight = Math.round(56 * minScale);
      const viewHeight = 78;
      setInnerSize({
        searchAndFilterWidth,
        fontSize,
        btnWidth,
        btnHeight,
        viewHeight,
      });
    } else {
      const searchAndFilterWidth = Math.round(982 * 1);
      const searchAndFilterHeight = Math.round(90 * 1);
      const fontSize = Math.round(24 * 1);
      const btnWidth = Math.round(203 * 1);
      const btnHeight = Math.round(56 * 1);
      const viewHeight = 78 * 1;
      setInnerSize({
        searchAndFilterWidth,
        searchAndFilterHeight,
        fontSize,
        btnWidth,
        btnHeight,
        viewHeight,
      });
    }
  }, [minScale]);
  const classes = useStyles({
    searchAndFilterWidth: innerSize.searchAndFilterWidth,
    fontSize: innerSize.fontSize,
    fontSize16: fontSize16,
    fontSize24: fontSize24,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth: innerSize.btnWidth,
    btnHeight: innerSize.btnHeight,
    viewHeight: innerSize.viewHeight,
  });

  const TableRows = useMemo(() => {
    const joinRoom = async (room) => {
      if (room.joinTableRole === "open") {
        dispatch({
          type: SET_LOADING_GLOBAL,
          payload: true,
        });
        const joinRes = await join(room, player);
        await updatePlayerStatus(playerId);
        dispatch({
          type: SET_LOADING_GLOBAL,
          payload: false,
        });
        if (joinRes?.data) {
          setOpenErrorDialog(false);
          tablePaging.destroy();
        } else {
          setOpenErrorDialog(true);
        }
      }

      if (room.joinTableRole === "close") {
        setRoomData(room);
        setOpenDialogJoinRequest(true);
      }
    };
    const handleJoinRoom = async (room) => {
      if(isClicked) return;
      setIsClicked(true);
      const isEnoughPlaytime = await checkPlayerRemainPlays(playTimeId);
      if (isEnoughPlaytime || trialStatus) {
        joinRoom(room);
      } else {
        setOpenDialogWarningPlayerPlayTime(true);
      }
    };

    const makeTableItem = (tbl, index) => {
      return (
        <TableItem
          key={`${tbl.tableId}-${index}`}
          onClick={() => handleJoinRoom(tbl)}
        >
          <TableImage src={tbl.tableAvatar} />
          <TableName>
            {`${t("tables.tableName")}:`}&nbsp;{tbl.tableName}
          </TableName>
          <TableName>
            {`${t("tables.desc")}`}&nbsp;{tbl.description}
          </TableName>
          <TablePlayers>
            {tbl.currentPlayersId.map((playerCurrentId) => {
              if (playerCurrentId) {
                return (
                  <TiUserOutline
                    key={playerCurrentId}
                    style={{ color: "#612622", fontWeight: 600 }}
                  />
                );
              }
            })}
          </TablePlayers>
        </TableItem>
      );
    };

    if (tables && tables.length > 0) {
      const tableRows = [];
      for (const table of tables) {
        tableRows.push(makeTableItem(table));
      }
      return tableRows;
    } else {
      return [];
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    tables,
    setOpenErrorDialog,
    t,
    setRoomData,
    setOpenDialogJoinRequest,
    player,
    tablePaging,
  ]);

  //TODO: handle dialog by sub
  const handleShowDialogBySub = useCallback((value) => {
    setShowDialogBySub(value);
  }, []);

  const handleShowDialogByRetail = useCallback((value) => {
    setShowDialogByRetail(value);
  }, []);

  const handleLoading = useCallback((_value) => {
    dispatch({
      type: BUYING_PLAN_OR_PACKAGE,
      payload: _value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container maxWidth="xl" className={classes.root} id="tables">
      <Backdrop style={{ zIndex: 99999, color: "#fff" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop style={{ zIndex: 99999, color: "#fff", display: "flex", flexDirection: "column"}} open={isBuyingLoading}>
        <CircularProgress color="inherit" />
        <div style={{ padding: "50px" }}>{t("loading.isBuyingLoading")}</div>
      </Backdrop>
      <ShowInfo
        isOpen={openErrorDialog}
        onClose={handleCloseErrorDialog}
        value={t("tables.enoughPlayer")}
      />
      <Header />
      <Container>
        <Box className={classes.boxTable}>
          <Box className={classes.searchAndFilter}>
            <ThemeProvider theme={theme}>
              <Typography className={classes.headText}>
                {t("tables.tableType")}
              </Typography>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="position"
                  name="position"
                  defaultValue="top"
                  value={value}
                  onChange={handleFilter}
                >
                  <FormControlLabel
                    value="open"
                    control={<Radio style={{ color: `rgba(0,0,0,0.7)` }} />}
                    label={t("tables.tableOpen")}
                    labelPlacement="end"
                    className={classes.headText}
                    style={{ fontSize: fontSize16 }}
                  />
                  <FormControlLabel
                    value="close"
                    control={<Radio style={{ color: `rgba(0,0,0,0.7)` }} />}
                    label={t("tables.tableClose")}
                    labelPlacement="end"
                    className={classes.headText}
                    style={{ fontSize: fontSize16 }}
                  />
                </RadioGroup>
              </FormControl>
              <div>
                <Button
                  variant="outlined"
                  endIcon={<BiRefresh />}
                  className={classes.buttonReload}
                  onClick={reloadTables}
                >
                  {t("tables.reload")}
                </Button>
                <Button
                  variant="outlined"
                  className={classes.buttonCreate}
                  onClick={handleClickOpen}
                >
                  {t("tables.createTable")}
                </Button>
              </div>
            </ThemeProvider>
          </Box>
          <InfiniteScroll
            initialLoad={true}
            className={classes.tableList}
            loadMore={loadMoreTables}
            hasMore={tablePaging.hasMoreDocument && isCompleteLoad}
            loader={<h4 key={0}>{t("tables.loading")}</h4>}
          >
            <TableList>{TableRows.map((e) => e)}</TableList>
          </InfiniteScroll>
          {0 === tables.length && (
            <Box display="flex" justifyContent="center">
              {t("tables.noInfo")}
            </Box>
          )}
          {open && (
            <DialogTableSetting
              open={open}
              onClose={handleClose}
            ></DialogTableSetting>
          )}
        </Box>
      </Container>
      <BasicDialog
        isOpen={openDialogJoinRequest}
        mainText={t("tables.joinRequest")}
        agreeText={t("tables.request")}
        closeText={t("tables.cancel")}
        onAgree={handleSendJoinRequest}
        onCancel={handleCloseDialogJoinRequest}
      />
      <OkDialog
        isOpen={openDialogWarningPlayerPlayTime}
        onAgree={() => {
          setOpenDialogWarningPlayerPlayTime(false);
          dispatch({ type: CLOSE_DIALOG_OVER_PLAY_TIME, payload: true });
        }}
        confirmText={t("game.outOfPlayTime")}
        btnLabel={"OK"}
      />
      {/* Dialog ConfirmMutipleDevices */}
      <ConfirmMutipleDevices
        open={isOpenModelConfirm}
        close={() => setIsOpenModelConfirm(false)}
      />
      {/* by subscribe package */}
      <BySubDialog
        openDialog={showDialogBySub}
        goBack={() => handleShowDialogBySub(false)}
        handleLoading={handleLoading}
        handleRedirect={handleRedirect}
      />
      {/* By Retail package dialog */}
      <ByRetailPackageDialog
        openDialog={showDialogByRetail}
        goBack={() => handleShowDialogByRetail(false)}
        // handleLoading={handleLoading}
        handleRedirect={handleRedirect}
      />
      s{/* time remain clock */}
      <Box className={classes.timeRemainClockBox}>
        <PlayTimeInfoBox
          handleUpPlan={() => handleShowDialogBySub(true)}
          handleByPlayTime={() => handleShowDialogByRetail(true)}
        />
      </Box>
    </Container>
  );
};

export default Tables;
