/* eslint-disable no-irregular-whitespace */
import React from "react";
import { Box, Container } from "@material-ui/core";
import useStyles from "./styles";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";

const PrivacyPolicy = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.privacyPolicyTitle"));

  return (
    <Container className={classes.root}>
      <Box className={classes.wrapper}>
        <p style={{ textAlign: "center", fontWeight: "bold", fontSize: 24 }}>
          <span>個人情報保護ポリシー</span>
        </p>
        <br />
        <p>
          <span>
            GIFT R&D.M合同会社（以下「当社」といいます）は、黙示伝授オンライン
            サービス会員（以下「会員」といいます）の氏名や住所、メールアドレスなどの特定の個人を識別できる情報または個人識別符号が含まれる情報（以下「個人情報」といいます）を適切に取り扱います。
          </span>
        </p>
        <br />
        <p>
          <span>１　法令の遵守</span>
        </p>
        <p>
          <span>
            当社は、個人情報を取扱うにあたり、個人情報保護に関する関係法令および社内規程を遵守します。
          </span>
        </p>
        <br />
        <p>
          <span>２　利用目的</span>
        </p>
        <p>
          <span>
            当社は、個人情報について、その利用目的を特定するとともに、利用目的の達成に必要な範囲において取り扱うこととし、その範囲を超える取扱いしません。なお、特定の個人情報の利用目的が法令等に基づき別途限定されている場合には、当該利用目的以外での取扱いはしません。当社における個人情報の利用目的は、黙示伝授オンライン
            サービス利用規約第27条をご参照ください。
          </span>
        </p>
        <br />
        <p>
          <span>３　個人情報の取得</span>
        </p>
        <p>
          <span>
            当社は、会員の個人情報を取得する場合、その利用目的と、会員に対する当社の担当窓口をお知らせした上で、業務上必要な範囲において、適正かつ適法な手段により取得します。
          </span>
        </p>
        <br />
        <p>
          <span>４　個人情報の適正管理</span>
        </p>
        <p>
          <span>
            当社は、個人データ（個人情報の保護に関する法律第2条第6項に規定する個人データ）への不正アクセス、紛失、破壊、改ざんおよび漏えい等を防止する措置を講じます。個人データは、利用目的に必要な範囲内で正確かつ最新の内容を確保し、利用の必要がなくなったときは遅滞なく消去するよう努めます。
          </span>
        </p>
        <br />
        <p>
          <span>５　第三者提供の制限</span>
        </p>
        <p>
          <span>
            当社は、会員の同意を得た場合や法令等に基づく場合等を除き、原則として個人データを第三者に対して提供いたしません。ただし、利用目的の達成に必要な範囲内において個人データの取扱いを委託する場合、および別途定める特定の者との間で共同利用する場合には、会員の同意を得ることなく、個人データを当社以外の者に対して提供することがあります。
          </span>
        </p>
        <br />
        <p>
          <span>６　要配慮個人情報の取扱い</span>
        </p>
        <p>
          <span>
            当社は、会員等の要配慮個人情報（人種、信条、社会的身分、病歴、犯罪の経歴、犯罪により害を被った事実その他本人に対する不当な差別、偏見その他の不利益が生じないようにその取扱いに特に配慮を要する情報）については、あらかじめ本人の同意を得た場合や法令等に基づく場合等を除き、取得しません。
          </span>
        </p>
        <br />
        <p>
          <span>７　委託先の監督</span>
        </p>
        <p>
          <span>
            当社は、会員等の個人データの取扱いを第三者に委託する場合、契約の締結等により委託先においても安全な情報管理が図られるよう適切に監督します。
          </span>
        </p>
        <br />
        <p>
          <span>8　個人情報の開示請求等</span>
        </p>
        <p>
          <span>
            保有個人データの開示・訂正・削除・利用停止等のご請求については、法令に別段の定めのある場合を除き、合理的な範囲で速やかに対応します（担当窓口　個人情報係　電話０３－５２１５－９６９９　　メールinfo@mokuden.net）。
          </span>
        </p>
        <br />
        <p>
          <span>以上</span>
        </p>
        <br />
        <p>
          <span>令和４年９月９日</span>
        </p>
        <p>
          <span>GIFT R&D.M合同会社</span>
        </p>
        <p>
          <span>（個人情報取扱事業者）</span>
        </p>
        <p>
          <span>オンライン事業部長　佐藤　啓</span>
        </p>
        <br />
        <br />
      </Box>
    </Container>
  );
};

export default PrivacyPolicy;
