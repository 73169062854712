import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
  },
  btnSearchBox: {
    minWidth: "300px",
    display: "flex",
    alignItems: "center",
    height: "inherit",
    border: "1px solid #c1c1c1",
    borderRadius: "10px",
    overflow: "hidden",
    "&  input": {
      border: "none",
      outline: "none",
      padding: "5px 10px",
      flex: 1,
    },
    "&  button": {
      backgroundColor: "#1976d2",
      borderRadius: "10px",
      color: "white",
      minWidth: "60px",
    },
  },
  searchIcon: {
    borderRadius: "10px",
    marginRight: "4px",
  },
  title: {
    fontSize: "24px !important",
    fontWeight: "600 !important",
    letterSpacing: "2px !important",
    textAlign: "center",
  },
  addButton: {
    fontSize: "20px !important",
    gap: "10px",
    alignItems: "center !important",
    textAlign: "center",
  },
  tableCell: {
    "@media (max-width: 1200px)": {
      padding: "10px 5px !important",
      fontSize: "12px !important",
    },
    "@media (max-width: 991px)": {
      padding: "10px !important",
      fontSize: "10px !important",
    },
    "@media (max-width: 768px)": {
      padding: "10px 5px !important",
    },
  },
  tableContainer: {
    backgroundColor: "transparent",
  },
  adminListBottom: {
    display: "flex",
    justifyContent: "space-around",
    alignItems: "center",
    marginTop: "1.5rem",
    "& button": {
      "@media (max-width: 768px)": {
        fontSize: "10px",
      },
    },
  },
  pagination: {
    "@media (max-width: 768px)": {
      "& li": {
        width: "30px",
      },
    },
  },
}));

export default useStyles;
