/* eslint-disable no-unused-vars */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  headerWapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: "40px",
    boxSizing: "border-box",
  },
  headerRight: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  headerTitle: {
    marginRight: "1rem!important",
    fontSize: "24px !important",
    fontWeight: "bold !important",
  },
  headerBtnsDownFile: {},
  btnSearchBox: {
    minWidth: "300px",
    display: "flex",
    alignItems: "center",
    height: "inherit",
    border: "1px solid #c1c1c1",
    borderRadius: "10px",
    overflow: "hidden",

    "&  input": {
      border: "none",
      outline: "none",
      padding: "5px 10px",
      flex: 1,
    },

    "&  button": {
      backgroundColor: "#1976d2",
      borderRadius: "initial",
      color: "white",
      minWidth: "60px",
    },
  },

  invoice: {
    display: "flex",
    flexDirection: "column",
    margin: "auto",
    height: "80vh",
    position: "relative",
    overflow: "hidden",
    marginTop: "1rem",
    justifyContent: "space-between",
    overflowX: "auto",

    "@media (max-width: 991px)": {
      padding: "2rem",
    },
  },
  invoiceBottom: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "1.5rem",
    "& button": {
      "@media (max-width: 768px)": {
        fontSize: "10px",
      },
    },
  },
  tableCell: {
    "@media (max-width: 1200px)": {
      padding: "10px 5px !important",
      fontSize: "12px !important",
    },
    "@media (max-width: 991px)": {
      padding: "10px !important",
      fontSize: "10px !important",
    },
    "@media (max-width: 768px)": {
      padding: "10px 5px !important",
    },
  },

  tableContainer: {
    backgroundColor: "transparent",
  },

  pagination: {
    "@media (max-width: 768px)": {
      "& li": {
        width: "30px",
      },
    },
  },
  title: {
    fontSize: "20px !important",
    fontWeight: "600 !important",
    letterSpacing: "2px !important",
    textAlign: "center",
    marginBottom: "1rem !important",
  },
}));

export default useStyles;
