import { Box, FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import PaperComponent from "../../PaperComponent";
import { OKButton, CancelButton } from "../Buttons";

const LeaveRoomDialog = (props) => {
  const { isOpen, onClose, onLeaveRoom } = props;
  const classes = useStyles();
  const nickNamesRefs = useRef(null);
  const room = useSelector((state) => state.room);
  const {
    t,
    i18n: { language },
  } = useTranslation();

  const [humanPlayerNotOwner, setHumanPlayerNotOwner] = useState([]);
  const { isGChaoComputer } = useSelector((state) => state.room.table);
  const currentUserId = useSelector((state) => state.user.playerId);
  const profile0 = useSelector((state) => state.room.profile0);
  const profile1 = useSelector((state) => state.room.profile1);
  const profile2 = useSelector((state) => state.room.profile2);
  const profile3 = useSelector((state) => state.room.profile3);

  useEffect(() => {
    if (!room.joinedPlayers) {
      return;
    }

    let players = [...room.joinedPlayers];
    // remove gChao is computer
    if (isGChaoComputer) {
      players[players.length - 1] = null;
    }
    const ownerIndex = players.findIndex(
      (player) => null !== player && player.playerId === currentUserId
    );
    if (-1 !== ownerIndex) {
      players[ownerIndex] = null;
    }
    const playerProfiles = [profile0, profile1, profile2, profile3];
    let GChaosId = "";
    playerProfiles.forEach((item) => {
      if (item && item.playerId && item.guestName === "G-Chaos") {
        GChaosId = item.playerId;
      }
    });
    players = players.filter(
      (player) => null !== player && player.playerId !== GChaosId
    );
    // auto select the next player to become new owner
    if (players.length > 0) {
      setValue(players[0].playerId);
    }

    setHumanPlayerNotOwner(players);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [room, isGChaoComputer, currentUserId]);
  const handleOnClose = () => {
    onClose();
  };
  const [value, setValue] = React.useState("female");

  const handleChange = (event) => {
    setValue(event.target.value);
  };
  const handleOnOk = () => {
    if (onLeaveRoom) onLeaveRoom(value);
  };
  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        style: {
          borderRadius: "35px",
          backgroundColor: "#f7f7f7",
          cursor: "move",
          overflow: "hidden",
        },
      }}
      PaperComponent={PaperComponent}
    >
      <div className="dialog-style-1" id="draggable-dialog-title">
        <div style={{ height: "70%" }}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              style={{
                top: 10,
                fontSize: 20,
                letterSpacing: language == "en" ? 0 : 18,
                position: "relative",
              }}
              fontWeight={700}
            >
              {t("tables.leaveRoom")}
            </Box>
            <Box
              component="span"
              fontSize="smaller"
              style={{
                margin: "15px 10px 0px 10px",
                fontSize: 14,
                fontWeight: 800,
              }}
            >
              {t("tables.leaveRoomQuote")}
            </Box>
          </Box>
          <Box className={classes.mainDialog}>
            <Box
              className={`${classes.defaultSize} ${classes.playersNickName}`}
              ref={nickNamesRefs}
            >
              <RadioGroup
                name="leaveRoomGroup"
                value={value}
                onChange={handleChange}
              >
                {humanPlayerNotOwner &&
                  humanPlayerNotOwner.map((newPlayer) => {
                    return newPlayer === null ? (
                      ""
                    ) : (
                      <FormControlLabel
                        style={{
                          height: 33,
                          width: 400,
                          textOverflow: "ellipsis",
                          whiteSpace: "noWrap",
                          overflow: "hidden",
                          display: "inline-block",
                        }}
                        key={newPlayer.playerId + ":position"}
                        value={newPlayer.playerId}
                        control={<Radio className="color-1" />}
                        label={`${newPlayer.nickName} ${
                          language == "jp" || language == "ja" ? " さん" : ""
                        }`}
                      />
                    );
                  })}
              </RadioGroup>
            </Box>
          </Box>
        </div>
        <Box
          display="flex"
          justifyContent="center"
          style={{ height: "25%", marginTop: "2%" }}
        >
          <Box width={135}>
            <CancelButton onClick={() => onClose()}>
              {t("tables.cancel")}
            </CancelButton>
          </Box>
          <Box width={15}></Box>
          <Box width={135}>
            <OKButton onClick={() => handleOnOk()}>
              <p
                style={{
                  letterSpacing: 15,
                  marginLeft: "15%",
                }}
              >
                {t("tables.ok")}
              </p>
            </OKButton>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};
LeaveRoomDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onLeaveRoom: PropTypes.func,
};
export default LeaveRoomDialog;
