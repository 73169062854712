import { Box, Drawer } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
import { NOTIFY } from "../../../store/constant";
import NotifyJoin from "../../Dialog/NotifyJoin";
import Friend from "../../Friend";
import AcceptFriendNotify from "../../Friend/acceptFriendNotify";
import BlockFriendRequest from "../../../components/Status/components/BlockFriendRequest";
import OkDialog from "../../../pages/GameScreen/components/NewDialog/OkDialog";
import useStyles from "../styles";

Notifications.propTypes = {
  allNotifies: PropTypes.array,
  myId: PropTypes.string,
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
};

function Notifications({
  allNotifies = [],
  myId = "",
  isOpen = false,
  toggle = () => {},
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [dialog, setDialog] = useState({
    isOpen: false,
    friendId: "",
  });
  const [openWarningPlayTime, setWarningPlayTime] = useState(false);
  const handleOpenDialog = (open) => {
    setDialog({ ...dialog, isOpen: open });
  };

  const handleShowNoPlayTime = useCallback(() => {
    setWarningPlayTime(!openWarningPlayTime);
  }, [openWarningPlayTime]);

  return (
    <>
      <Drawer
        anchor={"right"}
        open={isOpen}
        onClose={toggle}
        className={classes.drawerCustum}
      >
        <Box
          display="flex"
          flexDirection="column"
          className={classes.notifications}
        >
          {allNotifies &&
            allNotifies.map((notify, index) => {
              if (notify.type === "public" || notify.type === "private") {
                return (
                  <NotifyJoin
                    key={`notify-create-room${index}`}
                    item={notify}
                    toggle={toggle}
                    handleShowNoPlayTime={handleShowNoPlayTime}
                  />
                );
              } else if (notify.type === NOTIFY.ADD_FRIEND) {
                return (
                  <Friend
                    key={`notify-add-friend${index}`}
                    notify={notify}
                    myId={myId}
                    setDialog={setDialog}
                  />
                );
              } else if (notify.type === NOTIFY.ACCEPT_FRIEND) {
                return (
                  <AcceptFriendNotify
                    key={`notify-accept-friend${index}`}
                    notify={notify}
                    myId={myId}
                  />
                );
              }
            })}
          <div style={{ height: 20 }}></div>
        </Box>
      </Drawer>
      <BlockFriendRequest
        isOpen={dialog.isOpen}
        friendId={dialog.friendId}
        myId={myId}
        onClose={handleOpenDialog}
      />
      <OkDialog
        isOpen={openWarningPlayTime}
        onAgree={() => {
          setWarningPlayTime(false);
        }}
        confirmText={t("game.outOfPlayTime")}
        btnLabel={"OK"}
      />
    </>
  );
}

export default Notifications;
