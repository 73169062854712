/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import PropTypes from "prop-types";
import { Button, Typography, DialogContent, Dialog } from "@mui/material";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import { transitionReadedConfirmMutipleDevices } from "../../../store/actions/userAction";

const ConfirmMutipleDevices = ({ open, close }) => {
  const { t } = useTranslation();
  const classes = useStyles({});
  const [isChecked, setIsChecked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    transitionReadedConfirmMutipleDevices(isChecked);
    close();
  };
  return (
    <Dialog
      open={open}
      fullWidth
      maxWidth="sm"
      classes={{ paper: classes.paper }}
    >
      <DialogContent>
        <form className={classes.formConfirm} onSubmit={handleSubmit}>
          <Typography className={classes.title}>
            {t("tables.ConfirmMutipleDevices")}
          </Typography>
          <label className={classes.label} htmlFor="confirmMutipleDevices">
            <input
              type="checkbox"
              name="confirmMutipleDevices"
              value={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
            />
            <span className={classes.labelContent}>
              {t("tables.notShowMessage")}
            </span>
          </label>
          <Button variant="contained" className={classes.btn} type="submit">
            {t("paymentSettings.oke")}
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
};

ConfirmMutipleDevices.propTypes = {
  open: PropTypes.bool,
  close: PropTypes.func,
};

export default ConfirmMutipleDevices;
