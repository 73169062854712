import { Box, makeStyles } from "@material-ui/core";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  hideButtonAddFriend,
  subscribeStreamMessage,
} from "../../../store/actions/chat";
import { sendFriendRequest } from "../../../store/actions/friendAction";
import PlayerStt from "../components/PlayerStt";
import ReadMore from "../components/ReadMore";
import {
  ButtonAddFriend,
  ChatItem,
  ChatList,
  GridTableName,
  LabelFriend,
  NickName,
  NickNameWrapper,
  StatusIcon,
  TableName,
  TableText,
} from "./styles";

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "14px",
    textAlign: "start",
    boxSizing: "border-box",
    color: "#442211",
  },
  labelFriend: {
    boxSizing: "border-box",
    width: "fit-content",
    borderRadius: "10px",
    border: "1px solid #442211",
    backgroundColor: "#442211",
    padding: "3px 15px",
    fontSize: "12px",
    fontWeight: "300",
  },
  tableName: {
    fontSize: "14px",
    textAlign: "start",
    boxSizing: "border-box",
    fontWeight: "600",
    color: "#442211",
  },
  tableNameFriend: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  root: {
    padding: "5px 15px 15px",
  },
  tableNameWrap: {
    display: "flex",
    alignItems: "center",
  },
}));

const status = {
  AVAILABLE: "#399D26",
  WAITING: "#FFEC00",
  PLAYING: "#DC000C",
  OFFLINE: "grey",
};

export default function ChatStatus() {
  const streams = useSelector((state) => state.chat.streams);
  const myId = useSelector((state) => state.user.playerId);
  const nickname = useSelector((state) => state.user.nickName);
  const avatar = useSelector((state) => state.user.avatar);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles();

  useEffect(() => {
    let unsubscribe = () => {};
    dispatch(subscribeStreamMessage((func) => (unsubscribe = func)));
    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  const addFriend = async (friendId) => {
    dispatch(hideButtonAddFriend(friendId));
    const introduce = t("friends.introduceAddFriend", { name: nickname });
    await sendFriendRequest({
      myId,
      avatar,
      nickname,
      friendId,
      introduce,
    });
  };

  return (
    <>
      <PlayerStt isShowOnlineStatus={true} />
      <ChatList id="chat-list">
        {streams.map((stream, index) => (
          <ChatItem key={index}>
            <Box>
              <NickNameWrapper>
                <StatusIcon
                  color={status[stream.status] ?? status["OFFLINE"]}
                />
                <NickName>{stream.senderName}</NickName>
              </NickNameWrapper>
              <Box className={classes.tableNameFriend}>
                <GridTableName>
                  <TableText>{t("status.tableName")}</TableText>
                  <TableName>{stream.tableName}</TableName>
                  {stream.isShowButton && stream.isFriend ? (
                    <LabelFriend>{t("status.friend")}</LabelFriend>
                  ) : null}
                  {stream.isShowButton && !stream.isFriend ? (
                    <ButtonAddFriend onClick={() => addFriend(stream.senderId)}>
                      {t("status.addFriend")}
                    </ButtonAddFriend>
                  ) : null}
                </GridTableName>
              </Box>
            </Box>
            <Box>
              <ReadMore text={stream?.message} time={stream?.createdAt} />
            </Box>
          </ChatItem>
        ))}
      </ChatList>
    </>
  );
}
