import { useEffect, useState } from "react";
import {
  CONFIRMS,
  STEP_NARRATION,
  TRIGGER_G_CHAOS_MASTER_START_GAME,
  TRIGGER_OPEN_CARDS_ROUND_13,
  TRIGGER_PLAYER_START_GAME,
  TRIGGER_PLAYER_START_GAME_AFTER_CALL,
} from "../store/constant";

export const useTriggerConfirms = (stepId = "", popupId = "") => {
  const [trigger, setTrigger] = useState("");
  const [message, setMessage] = useState("");
  const [isShowDialog, setIsShowDialog] = useState(false);

  useEffect(() => {
    const isInSteps = [
      STEP_NARRATION.SG_STEP1,
      STEP_NARRATION.SG_STEP3,
      STEP_NARRATION.SG_STEP4,
      STEP_NARRATION.SG_STEP25,
      STEP_NARRATION.SG_STEP14,
      STEP_NARRATION.SG_STEP15,
      STEP_NARRATION.SG_STEP16,
      STEP_NARRATION.SG_STEP17,
      STEP_NARRATION.SG_STEP18,
      STEP_NARRATION.SG_STEP19,
      STEP_NARRATION.SG_STEP21,
      STEP_NARRATION.SG_STEP22,
      STEP_NARRATION.SG_STEP23,
      STEP_NARRATION.SG_STEP24,
      STEP_NARRATION.ED_STEP1,
    ].includes(stepId);
    if (!isInSteps) {
      setTrigger("");
      setMessage("");
      setIsShowDialog(false);
      return;
    }

    const triggers = {
      CONFIRM_PLAYER_COMPLETE_INTRODUCE: {
        trigger: CONFIRMS.CONFIRM_PLAYER_COMPLETE_INTRODUCE,
        message: "narrations.completeIntroduce",
        isShowDialog: true,
      },
      CONFIRM_G_CHAOS_MASTER_START_GAME: {
        trigger: TRIGGER_G_CHAOS_MASTER_START_GAME,
        message: "narrations.narration20_2",
        isShowDialog: true,
      },
      CONFIRM_AFTER_INSTRUCTION: {
        trigger: TRIGGER_PLAYER_START_GAME,
        message: "narrations.narration8",
        isShowDialog: true,
      },
      CONFIRM_AFTER_CALL: {
        trigger: TRIGGER_PLAYER_START_GAME_AFTER_CALL,
        message: "narrations.narration20",
        isShowDialog: true,
      },
      CONFIRM_OPEN_CARDS_ROUND_13: {
        trigger: TRIGGER_OPEN_CARDS_ROUND_13,
        message: "narrations.narration35",
        isShowDialog: true,
      },
    };

    const result = triggers[popupId];
    if (!result) return;
    setTrigger(result.trigger);
    setMessage(result.message);
    setIsShowDialog(result.isShowDialog);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stepId, popupId]);

  return { isShowDialog, trigger, message };
};
