import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { GChaosCard, GChaosCardWrapper } from "./styles";
import PropTypes from "prop-types";
import { STD_SIZE } from "../../../../store/constant";

export const GChaosLeftRightCardTutorialCard = ({ size }) => {
  const handCards = useSelector(
    (state) => state.playerGameState.myState.handCards
  );
  const [isHideGChaosCard, setIsHideGChaosCard] = useState(false);
  const currentRound = useSelector(
    (state) => state.playerGameState.currentRound
  );
  const [innerSize, setInnerSize] = useState({});

  useEffect(() => {
    if (size) {
      const cardWidth = size?.cardWidth;
      const cardHeight = size?.cardHeight;
      setInnerSize({ cardWidth, cardHeight });
    } else {
      const cardWidth = STD_SIZE.STD_SHIP_WIDTH;
      const cardHeight = STD_SIZE.STD_CARD_HEIGHT;
      setInnerSize({ cardWidth, cardHeight });
    }
  }, [size]);
  useEffect(() => {
    if (handCards) {
      const isHideGChaosCardComponent =
        handCards.filter((c) => c !== null).length === 0;
      if (isHideGChaosCardComponent) {
        setIsHideGChaosCard(true);
      } else {
        setIsHideGChaosCard(false);
      }
    }
  }, [handCards]);

  const getGChaosCard = () =>
    currentRound < 13 ? <GChaosCard src="/cards/back.png" /> : <></>;

  return !isHideGChaosCard ? (
    <GChaosCardWrapper
      width={innerSize.cardWidth}
      height={innerSize.cardHeight}
    >
      {getGChaosCard()}
    </GChaosCardWrapper>
  ) : null;
};

GChaosLeftRightCardTutorialCard.propTypes = {
  size: PropTypes.any,
};
