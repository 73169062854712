import { Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Button from "../../../components/Button";
import { PHONE_CODE } from "../../../constants";
import firebase from "../../../services/firebase";
import { sendOtpCode } from "../../../store/actions/authActions";
import Backdrop from "../../Backdrop";
import useStyles, { BoxWrapper, Space } from "./styles";

const SendedOtp = ({ handleChangePage, numberPhone, labelButton }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(false);
  const [message, setMessage] = useState("");

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        defaultCountry: "JP",
        "expired-callback": () => {
          console.log("err");
        },
      }
    );
    window.recaptchaVerifier.render();
  };

  const reSendOtp = async (_numberPhone) => {
    setIsLoading(true);
    configureCaptcha();
    const phoneNumber = PHONE_CODE + _numberPhone;
    const appVerifier = window.recaptchaVerifier;
    const errorOtp = await sendOtpCode(phoneNumber, appVerifier);
    if (errorOtp) setMessage(errorOtp);
    if (errorOtp == "") {
      setMessage("notifications.reSendCode");
    }

    setIsLoading(false);
  };

  useEffect(() => {
    reSendOtp(numberPhone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Backdrop open={isLoading} />
      {!isLoading && (
        <BoxWrapper>
          <Typography className={classes.title}>{t(message)}</Typography>
          <Space space={20} />
          <Button
            type="submit"
            variant="outlined"
            text={labelButton}
            className={classes.loginBtn}
            onClick={() => handleChangePage(1)}
          ></Button>
        </BoxWrapper>
      )}
      <div id="recaptcha-container"></div>
    </Fragment>
  );
};

SendedOtp.propTypes = {
  handleChangePage: PropTypes.func,
  numberPhone: PropTypes.string,
  labelButton: PropTypes.string,
};

export default SendedOtp;
