import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import styled from "styled-components";

const useStyles = makeStyles(() => ({
  root: {
    margin: 0,
    padding: 0,
    width: "100vw",
    height: "100vh",
  },
  main: {
    margin: 0,
    padding: 0,
    display: "flex",
    width: "100%",
    height: "calc(100% - 50px)",
  },
  inputSearch: {
    width: "90%",
    margin: "10px",
    [`& fieldset`]: {
      borderRadius: "999px",
      fontColor: "white",
      borderColor: "white",
    },
  },
  inputSearchFriend: {
    color: "white",
    fontSize: "16px",
    fontWeight: "bold",
    "&::placeholder": {
      color: "green !important",
    },
  },
  disabled: {
    backgroundColor: "red",
  },
  rootBtn: {
    "&$disabled": {
      background: "rgba(0, 0, 0, 0.5)",
      color: "white",
      boxShadow: "none",
    },
  },
  goBack: {
    margin: "10% 0",
    padding: "5px 15px",
    textTransform: "initial",
    alignSelf: "flex-end",
    color: "white",
  },
  sidebarListRequest: {
    overflowY: "scroll",
    overflowX: "hidden",
    height: "97%",
  },
  sidebarRequestFriends: {
    height: "calc(100% - 250px)",
  },
}));

// Sidebar
export const SidebarWrapper = styled.div`
  width: 350px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  border-right: 1px solid #e0e0e0;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;
// Add friends
export const AddFriendTitle = styled.p`
  width: 100%;
  padding: 10px;
  font-size: 1.5em;
  font-weight: 500;
  color: white;
  box-sizing: border-box;
  background-image: linear-gradient(
    to top,
    #22d0eb,
    #03c9ee,
    #00c3f0,
    #00bbf2,
    #12b4f2
  );
`;

export const SearchBoxWrapper = styled.div`
  width: 100%;
  height: 80px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  border-bottom: 1px solid #e0e0e0;
  box-sizing: border-box;
`;

export const SearchItem = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: "";
    border-bottom: 1px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 95%;
    margin: auto;
  }
`;

export const SearchItemNickname = styled.p`
  flex: 1;
  color: white;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;

export const ButtonStyle2 = styled(Button)`
  /* width: fit-content; */
  padding: 5px auto;
  margin-right: 10px;
`;

// Friend requests
export const Title = styled.p`
  width: 100%;
  padding: 10px;
  font-size: 1.3em;
  font-weight: 500;
  color: white;
  box-sizing: border-box;
  background-image: linear-gradient(
    to top,
    #22d0eb,
    #03c9ee,
    #00c3f0,
    #00bbf2,
    #12b4f2
  );
`;

export const RequestItem = styled.div`
  width: 100%;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: "";
    border-bottom: 1px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 95%;
    margin: auto;
  }
`;

export const ButtonBox = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
`;

// Friends
export const FriendsWrapper = styled.div`
  width: 400px;
  max-width: 450px;
  min-width: 350px;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  /* background-color: rgba(61, 110, 144, 0.8); */
  box-sizing: border-box;
`;

export const FriendBox = styled.div`
  width: 100%;
  overflow: auto;
`;

export const FriendItemWrapper = styled.div`
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  position: relative;
  &::after {
    content: "";
    border-bottom: 1px solid white;
    position: absolute;
    bottom: 0;
    right: 0;
    width: 95%;
    margin: auto;
  }
`;

export const FriendInfo = styled.div`
  flex: 1;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  box-sizing: border-box;
`;

export const FriendAvatar = styled.div`
  width: 80px;
  height: 80px;
  box-sizing: border-box;
  padding: 14px;
`;
export const FriendNickname = styled.p`
  color: white;
  font-weight: 300;
  font-size: 1.1em;
  margin-bottom: 10px;
  width: 80%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
`;

export const OnlineStatus = styled.p`
  color: #00e676;
`;

export const OfflineStatus = styled.p`
  color: red;
`;

export default useStyles;
