import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";

export const GCC_HBottom = () => {
  const [cardImg, setCardImg] = useState("");
  const { playerId, grandCross } = useSelector(
    (state) => state.playerGameState
  );
  const { currentPlayersId } = useSelector((state) => state.room.table);
  const size = useSelector((state) => state.size);
  const renderTopIdx = useMemo(
    () => currentPlayersId.indexOf(playerId),
    [currentPlayersId, playerId]
  );

  useEffect(() => {
    let imgSrc =
      grandCross[renderTopIdx] != null && // don't show confirm introduce
      "NewYorkTimes" !== grandCross[renderTopIdx].suit
        ? `/cards/${grandCross[renderTopIdx].suit}${grandCross[renderTopIdx].value}.jpg`
        : "";
    setCardImg(imgSrc);
  }, [grandCross, renderTopIdx]);
  return (
    <div
      style={{
        width: size?.cardWidth,
        height: size?.cardHeight,
        borderRadius: "4%",
      }}
    >
      {cardImg != "" && (
        <img
          src={cardImg}
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "4%",
            objectFit: "fill",
          }}
        />
      )}
    </div>
  );
};
