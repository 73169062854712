import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  boxButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    margin: "auto",
    width: "100%",
    height: "100%",
    padding: "24px 0",
    boxSizing: "border-box",
    "&>*": {
      textTransform: "capitalize",
    },
  },
  button: {
    padding: `5px 15px`,
  },
  packageList: {
    display: "flex",
    flexWrap: "wrap",
    "&>*": {
      margin: "20px",
    },
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  packageRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    margin: "auto",
    "&>*": {
      margin: "10px",
    },
    width: "80%",
    "@media (max-width: 1024px)": {
      flexDirection: "row",
      flexWrap: "wrap",
      width: "100%",
      justifyContent: "space-evenly",
      margin: "0 0 24px 0",
    },
  },
  container: {
    boxSizing: "border-box",
    paddingBottom: "4em",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
    // "&::-webkit-scrollbar": {
    //   display: "none",
    // },
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "auto",
    backgroundColor: "rgba(176,195,199,0.7)",
    borderRadius: "40px",
    boxSizing: "border-box",
  },
  inviteSection: {
    display: "flex",
    flexDirection: "row",
    width: "70%",
    justifyContent: "space-between",
  },
  packageTitle: {
    color: "#541513",
    width: "80%",
    margin: "auto",
    padding: "24px 0",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
}));

export default useStyles;
