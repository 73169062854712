import { Typography } from "@material-ui/core";
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useDocumentTitle from "../../hooks/useDocumentTitle";
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    background: "none",
  },
  link: {
    textDecoration: "none",
    textTransform: "none",
    color: "white",
  },
  button: {
    backgroundColor: "rgb(186,71,35)",
    borderRadius: "999px",
    border: "black 1px solid",
    padding: "7px 2em",
    marginTop: 40,
  },
  mainWarpper: {
    padding: (props) => `${props.setPadding}px`,
  },
}));
export const LogoGame = styled.div`
  aspect-ratio: 1/1;
  background: url("/assets/intro2.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const Production = styled.div`
  background: url("/assets/introtext2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-top: -30px;
`;
export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  margin-top: 1em;
  padding: 1em;
`;
const Notifications = () => {
  const { t } = useTranslation();
  const minScale = useSelector((state) => state.size.minScale);

  const logoGameWidth = 240;
  const logoGameHeight = 150;
  const productionWidth = 130;
  const productionHeight = 7;
  const boxWrapperWidth = 350;
  const loginBtn_W = 203;
  const loginBtn_H = 56;
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (minScale) {
      const padding = Math.round(50 * minScale);
      setInnerSize({ padding });
    } else {
      const padding = 50;
      setInnerSize({ padding });
    }
  }, [minScale]);

  const classes = useStyles({
    setPadding: innerSize.padding,
  });
  useDocumentTitle(t("common.gConceptTitle") + t("common.notificationTitle"));
  return (
    <Container maxWidth="xl" className={classes.root}>
      <LogoGame width={logoGameWidth} height={logoGameHeight} />
      <Production width={productionWidth} height={productionHeight} />

      <BoxWrapper width={boxWrapperWidth}>
        <div className={classes.mainWarpper} />
        <Typography
          variant="body1"
          style={{ color: "#541f1c", fontSize: 28, fontWeight: 600 }}
        >
          {t("notifications.createAccountSuccess")}
        </Typography>
        <div style={{ height: 10 }}></div>
        <Typography variant="body1" style={{ color: "#dd501f", fontSize: 14 }}>
          {t("notifications.checkEmail1")}
        </Typography>
        <Typography variant="body1" style={{ color: "#dd501f", fontSize: 14 }}>
          {t("notifications.checkEmail2")}
        </Typography>

        <Button
          variant="contained"
          className={classes.button}
          width={loginBtn_W}
          height={loginBtn_H}
        >
          <Link to="/login" className={classes.link}>
            {t("notifications.backToLogin")}
          </Link>
        </Button>

        <div className={classes.mainWarpper} />
      </BoxWrapper>
    </Container>
  );
};
export default Notifications;
