import firebase from "../services/firebase";
import { logs } from "../store/constant";

const db = firebase.firestore();
const rtdb = firebase.database();
const ROOMS = "rooms";
const GAMES = "games";
const LOGS = "logs";

export async function writeLog(roomId, numberOfGames, event, actor, data) {
  const logId = new Date().toUTCString();
  await db
    .collection(ROOMS)
    .doc(roomId)
    .collection(GAMES)
    .doc(`${numberOfGames}`)
    .collection(LOGS)
    .doc(logId)
    .set({ time: logId, event: event ?? "Unknown", actor, data });
}

let oldTime = new Date().getTime();

export function writeLogRealtime(
  playerNickName = "",
  gameKey = "",
  event = "",
  eventData = {},
  gameStartTime = ""
) {
  if (
    playerNickName === "" ||
    gameKey === "" ||
    event === "" ||
    gameStartTime === "" ||
    Object.keys(eventData).length === 0
  )
    return;
  try {
    const now = new Date();
    const dateKey = `${now.getUTCFullYear()}-${now.getUTCMonth()}-${now.getUTCDate()}`;
    const newTime = now.getTime();
    const timeChanged = newTime - oldTime;
    oldTime = newTime;
    eventData[logs.TIME_CHANGED] = timeChanged;
    const logRef = rtdb.ref(
      `${LOGS}/${playerNickName}/${dateKey}/${gameStartTime}-${gameKey}`
    );
    const time = `${now.getUTCHours()}:${now.getUTCMinutes()}:${now.getUTCSeconds()}:${now.getUTCMilliseconds()}`;
    logRef.child(time).set(eventData);
  } catch (error) {
    console.log(error);
  }
}
