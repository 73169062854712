import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import { orderBy } from "lodash";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BronzeIcon from "../../../assets/icons/bronze.png";
import GoldIcon from "../../../assets/icons/gold.png";
import PlatinumlIcon from "../../../assets/icons/platinum.png";
import SilverlIcon from "../../../assets/icons/silver.png";
import TrialIcon from "../../../assets/icons/trial.png";
import ButtonCustom from "../../../components/Button";
import PackageCard from "../../../components/PackageCard";
import { PACKAGE_BACK_GROUND_COLOR } from "../../../constants";
import DialogConfirm from "../../../pages/GameScreen/components/Dialog/DialogConfirm";
import { byRetailPackageInvoice } from "../../../store/actions/retailPackageAction";
import { numberWithCommas } from "../../../utils/common";
import { BUYING_PLAN_OR_PACKAGE } from "../../../store/types";
import useStyles from "./styles";

const ByRetailPackageDialog = ({
  goBack,
  openDialog,
  // handleLoading,
  isInGameSreen,
  handleRedirect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [openDialogErrorCard, setOpenDialogErrorCard] = useState(false);
  const [retailPackages, setRetailPackages] = useState([]);
  const [seclectedPackage, setSeclectedPackage] = useState(null);
  const playerId = useSelector((state) => state.user.playerId);
  const paymentMethodId = useSelector((state) => state.user.paymentMethodId);
  const activePackage = useSelector((state) => state.package.activePackage);
  const handleClose = useCallback(() => {
    setSeclectedPackage(null);
    setOpen(false);
  }, []);

  const iconPackage = {
    GOLD: GoldIcon,
    TRIAL: TrialIcon,
    BRONZE: BronzeIcon,
    PLATINUM: PlatinumlIcon,
    SILVER: SilverlIcon,
  };

  const getBackgroudColorPackage = (packageName) => {
    return PACKAGE_BACK_GROUND_COLOR[`${packageName?.toUpperCase()}`];
  };

  const getIconPackage = (packageName) => {
    return iconPackage[`${packageName?.toUpperCase()}`];
  };

  const handleCloseDialogErrorCard = useCallback(() => {
    setOpenDialogErrorCard(false);
    goBack();
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleByRetailPackage = (_package) => {
    if (paymentMethodId) {
      setSeclectedPackage(_package);
      setOpen(true);
    } else {
      setOpenDialogErrorCard(true);
    }
  };

  const handleByRetailPackageFunc = useCallback(async () => {
    if (activePackage && seclectedPackage && playerId) {
      setOpen(false);
      // handleLoading(true);
      dispatch({
        type: BUYING_PLAN_OR_PACKAGE,
        payload: true,
      });
      const resulf = await byRetailPackageInvoice(
        playerId,
        seclectedPackage.paymentGatewayPriceId
      );
      goBack();
      if (!resulf?.data) {
        // handleLoading(false);
        dispatch({
          type: BUYING_PLAN_OR_PACKAGE,
          payload: false,
        });
        toast.warning(t("paymentSettings.paymentFail"));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePackage, seclectedPackage, playerId]);

  useEffect(() => {
    if (activePackage) {
      const _retailPackages = activePackage.retailPackages;
      const sortedRetailPackage = orderBy(
        _retailPackages,
        "retailPackagePlayTimes"
      );
      setRetailPackages(sortedRetailPackage);
    }
  }, [activePackage]);

  return (
    <Dialog
      open={openDialog}
      className={classes.dialogWapper}
      fullWidth
      maxWidth="lg"
      classes={{ paper: classes.paper }}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.ChangePlanBox} style={{ color: "#541513" }}>
          <Typography className={classes.dialogContentTilte}>
            {t("paymentSettings.addPlayCount")}
          </Typography>
          <Box className={classes.PlanCurrent}>
            <Typography className={classes.PlanCurrentTitle}>
              {t("paymentSettings.currentPlan")}
            </Typography>
            <Box className={classes.ListPlan}>
              {activePackage && (
                <Box style={{ width: "33%" }}>
                  <PackageCard
                    packageName={activePackage?.packageName}
                    packagePrice={activePackage?.packagePrice}
                    isShowPackagePrice
                    packagePlayTimes={activePackage?.packagePlayTimes}
                    isShowDesciption
                    packageIcon={
                      <img
                        src={`${getIconPackage(activePackage?.packageName)}`}
                      />
                    }
                    backgroundColor={getBackgroudColorPackage(
                      activePackage?.packageName
                    )}
                  />
                </Box>
              )}
            </Box>
          </Box>
          <Box className={classes.ListPlanWrapp}>
            <Typography className={classes.ListPlanTitle}>
              {t("paymentSettings.buyRetailPackageTitle")}
            </Typography>
            <Box className={classes.ListPlan}>
              {retailPackages &&
                retailPackages.length > 0 &&
                retailPackages.map((item) => (
                  <Box
                    className={classes.ListPlanBox}
                    key={item?.retailPackageId}
                  >
                    <Box className={[classes.PlanBox, classes.Gold]}>
                      <Typography className={classes.PlanBoxText}>
                        {` ${item?.retailPackagePlayTimes} ${t(
                          "paymentSettings.ticket"
                        )}`}
                      </Typography>
                    </Box>
                    <Button
                      variant="contained"
                      className={classes.packageBtn}
                      onClick={() => {
                        handleByRetailPackage(item);
                      }}
                      style={{
                        backgroundColor: "#ce5931",
                        textTransform: "capitalize",
                      }}
                    >
                      {`${
                        item?.retailPackagePrice
                          ? numberWithCommas(item?.retailPackagePrice)
                          : 0
                      } 円で${t("paymentSettings.Purchase")}`}
                    </Button>
                  </Box>
                ))}
            </Box>
          </Box>
          <div className={classes.btnGroup}>
            <Box className={classes.btns}>
              <ButtonCustom
                className={classes.buttonBlue}
                onClick={() => goBack()}
                text={t("paymentSettings.back")}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>*価格はすべて税込です</Typography>
            </Box>
          </div>
          <DialogConfirm
            open={open}
            handleClose={handleClose}
            handleSubmit={handleByRetailPackageFunc}
            contentConfirm={t("paymentSettings.changeRetailPackageTitle", {
              playTimes: seclectedPackage?.retailPackagePlayTimes,
              money: `${
                seclectedPackage?.retailPackagePrice
                  ? numberWithCommas(seclectedPackage?.retailPackagePrice)
                  : 0
              }`,
            })}
            okLabel={t("paymentSettings.oke")}
            cancelLabel={t("paymentSettings.cancel")}
          />
          <DialogConfirm
            open={openDialogErrorCard}
            handleClose={() => setOpenDialogErrorCard(false)}
            handleSubmit={handleCloseDialogErrorCard}
            contentConfirm={t("paymentSettings.dialogErrorCard")}
            okLabel="プラン詳細へ"
            cancelLabel="閉じる"
            isNoShowConfirmButton={isInGameSreen ? true : false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ByRetailPackageDialog.propTypes = {
  goBack: PropTypes.func,
  // handleLoading: PropTypes.func,
  openDialog: PropTypes.bool,
  isInGameSreen: PropTypes.bool,
  handleRedirect: PropTypes.func,
};

export default ByRetailPackageDialog;
