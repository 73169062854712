import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  mainContent: { minWidth: "400px" },
  icon: { fontSize: "4rem" },
  actionsContent: {
    display: "flex",
    justifyContent: "center",
  },
  closeButton: {
    position: "absolute",
    top: 0,
    right: 0,
  },
}));

export default useStyles;
