import { Box, Container } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import AvatarUpload from "../../components/AvatarUpload";
import Button from "../../components/Button";
import Input from "../../components/Input";
import RadioGroup from "../../components/RadioGroup";
import SuccessMessage from "../../components/SuccessMessage";
import { updateSettingDefaultTable } from "../../store/actions/userAction";
import { MAX_CHARACTER_NAME } from "../../store/constant";
import { SET_DEFAULT_TABLE } from "../../store/types";
import useStyles, { BoxWrapper } from "./styles";

const initialValues = {
  joinTableRole: "",
  defaultTableName: "",
  defaultAvatar: "",
  isNarrationSound: false,
  isNarrationText: true,
};

// eslint-disable-next-line react/prop-types
const TableSettings = ({ goBack }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [imageTable, setImageTable] = useState(null);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const user = useSelector((state) => state.user);
  const dispatch = useDispatch();

  const minScale = useSelector((state) => state.size.minScale);
  const boxWrapperWidth = useMemo(
    () => (minScale < 0.4 ? 350 : 569),
    [minScale]
  );
  const input_W = useMemo(() => (minScale < 0.4 ? 250 : 372), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 11 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(203 * minScale * 2) : 203),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H: 51,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });

  const joinTableItems = [
    { id: "open", title: t("settings.open") },
    { id: "close", title: t("settings.close") },
  ];

  useEffect(() => {
    setValues({
      joinTableRole: user.setting.joinTableRole,
      defaultTableName: user.setting.defaultTableName,
      defaultAvatar: user.setting.defaultAvatar,
      isNarrationSound: user.setting.isNarrationSound,
      isNarrationText: user.setting.isNarrationText,
    });
  }, [user]);

  useEffect(() => {
    const clearError = setTimeout(() => {
      setSuccess("");
    }, 3000);
    return () => clearTimeout(clearError);
  }, [success]);

  useEffect(() => {
    setSuccess(success);
  }, [success]);

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors((err) => ({ ...err, [name]: "" }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const newSettings = { ...initialValues };
    newSettings["joinTableRole"] = values.joinTableRole;
    newSettings["defaultTableName"] = values.defaultTableName;
    newSettings["defaultAvatar"] = values.defaultAvatar;
    newSettings["isNarrationSound"] = user.setting.isNarrationSound;
    newSettings["isNarrationText"] = user.setting.isNarrationText;
    try {
      dispatch({ type: SET_DEFAULT_TABLE, payload: newSettings });
      const response = await updateSettingDefaultTable(
        user.playerId,
        newSettings,
        imageTable
      );
      if (response === user.playerId) {
        setSuccess("notifications.updateSuccessfully");
      }
    } catch (error) {
      // console.log(error);
    }
  };

  // const handleClickCancel = () => {
  //   setValues({
  //     joinTableRole: user.setting.joinTableRole,
  //     defaultTableName: user.setting.defaultTableName,
  //     defaultAvatar: user.setting.defaultAvatar,
  //     isNarrationSound: user.setting.isNarrationSound,
  //     isNarrationText: user.setting.isNarrationText,
  //   });
  // };

  return (
    <Container className={classes.root}>
      <Box className={classes.mainBox}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="center">
            <AvatarUpload
              imageProp={imageTable}
              setImageProp={setImageTable}
              avatar={user.setting.defaultAvatar}
              provider="table"
              name="imageTable"
              id="imageTable"
            />
          </Box>

          <div style={{ height: "20px" }} />
          <BoxWrapper width={boxWrapperWidth}>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <div style={{ height: 40 }} />

              <Input
                name="defaultTableName"
                placeHolder={t("tableSettings.tableName")}
                type="text"
                value={values.defaultTableName}
                onChange={handleChangeValues}
                error={errors.defaultTableName}
                width={input_W}
                height={51}
                maxLength={MAX_CHARACTER_NAME}
                show={true}
              />
              <RadioGroup
                name="joinTableRole"
                value={values.joinTableRole}
                items={joinTableItems}
                onChange={handleChangeValues}
              />
            </div>
            <SuccessMessage message={t(success)} />
            <div style={{ height: "30px" }} />
            <Box className={classes.boxButton}>
              <Button
                onClick={goBack}
                text={t("tableSettings.back")}
                className={classes.buttonBlue}
              />
              <Button
                onClick={handleSubmit}
                text={t("tableSettings.save")}
                className={classes.buttonOrange}
              />
            </Box>
          </BoxWrapper>
        </form>
      </Box>
    </Container>
  );
};

export default TableSettings;
