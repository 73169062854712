import { Avatar, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  acceptFriendRequest,
  rejectFriendRequest,
  seenNotify,
} from "../../store/actions/friendAction";
import { NOTIFY } from "../../store/constant";
import { formatTimestamp } from "../../utils/time";
import {
  FriendInfoWrapper,
  FriendItem,
  FriendItemAvatar,
  FriendItemBody,
  FriendItemFooter,
  FriendItemIntroduce,
  // FriendItemNickname,
  Timestamp,
} from "./styles";



function Friend({ notify, myId, setDialog }) {
  const { t } = useTranslation();
  const [isClicked, setIsClicked] = useState(false);

  const handleAcceptAddFriend = async (friendId) => {
    if(!isClicked){
      setIsClicked(true);
      await acceptFriendRequest(myId, friendId);
    }
  };

  const handleRejectFriendRequest = async (friendId) => {
    await rejectFriendRequest(myId, friendId);
    setDialog({
      isOpen: true,
      friendId,
    });
  };

  return (
    <FriendItem bg={notify?.seen ? "#e9e3d7" : "#c9bc9d"}>
      <Timestamp>{formatTimestamp(notify.timestamp)}</Timestamp>
      <FriendItemBody
        onClick={() =>
          seenNotify({
            myId: myId,
            type: NOTIFY.ADD_FRIEND,
            index: -2,
            notifyId: notify?.id,
          })
        }
      >
        <FriendItemAvatar>
          <Avatar
            src={notify?.friendAvatar}
            style={{ border: "2px solid #fff" }}
          />
        </FriendItemAvatar>
        <FriendInfoWrapper>
          {/* <FriendItemNickname>{notify?.friendName}</FriendItemNickname> */}
          <FriendItemIntroduce>
            {notify?.friendName + t("friends.introduceAddFriend")}
          </FriendItemIntroduce>
        </FriendInfoWrapper>
      </FriendItemBody>
      <FriendItemFooter>
        <Button
          variant="outlined"
          style={{
            backgroundColor: "#442211",
            color: "#fff",
            padding: "0px 15px",
          }}
          onClick={() => handleAcceptAddFriend(notify?.friendId)}
        >
          {t("friends.approve")}
        </Button>
        <Button
          variant="contained"
          style={{ padding: "0px 15px" }}
          onClick={() => handleRejectFriendRequest(notify?.friendId)}
        >
          {t("friends.deny")}
        </Button>
      </FriendItemFooter>
    </FriendItem>
  );
}

Friend.propTypes = {
  notify: PropTypes.object,
  myId: PropTypes.string,
  setDialog: PropTypes.func,
};

export default Friend;
