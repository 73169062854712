import {
  CLOSE_CHAT_BOX,
  OPEN_CHAT_BOX,
  SET_DATA_LIST_ROOM,
  SET_DATA_MESSAGE_BY_LIST_ROOM,
  SET_MESSAGES_IN_ROOM,
  SET_STREAMS,
  SET_THREAD_IN_ROOM,
  OPEN_CHAT_BOX_BY_THREAD_ID,
  RESET_DATA,
  UN_READ_MESSAGE,
  SET_SELECTED_THREAD_ID,
  SET_CHAT_STATUS,
  SET_STATUS_ONLINE,
} from "../types";

const initialState = {
  streams: [],
  isOpenChatModal: false,
  rooms: [],
  threadInRoom: {},
  dataListRoom: [],
  dataMessageByListRoom: [],
  roomSelectedIndex: "",
  countMessageUnRead: 0,
  selectedThreadId: "",
  chatStatus: [],
  isOnline: true,
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_STREAMS:
      return {
        ...state,
        streams: action.payload,
      };
    case SET_MESSAGES_IN_ROOM:
      return {
        ...state,
        rooms: action.payload,
      };
    case SET_THREAD_IN_ROOM:
      return {
        ...state,
        threadInRoom: action.payload,
      };
    case CLOSE_CHAT_BOX:
      return {
        ...state,
        isOpenChatModal: false,
      };
    case OPEN_CHAT_BOX_BY_THREAD_ID:
      return {
        ...state,
        isOpenChatModal: true,
        selectedThreadId: action.payload,
      };
    case OPEN_CHAT_BOX:
      return {
        ...state,
        isOpenChatModal: true,
      };

    case SET_DATA_MESSAGE_BY_LIST_ROOM:
      return {
        ...state,
        dataMessageByListRoom: [...action.payload],
      };

    case SET_DATA_LIST_ROOM: {
      return {
        ...state,
        dataListRoom: action.payload,
      };
    }
    case RESET_DATA: {
      return {
        ...state,
        dataListRoom: [],
      };
    }
    case UN_READ_MESSAGE: {
      return {
        ...state,
        countMessageUnRead: action.payload,
      };
    }
    case SET_SELECTED_THREAD_ID: {
      return {
        ...state,
        selectedThreadId: action.payload,
      };
    }
    case SET_CHAT_STATUS: {
      return {
        ...state,
        chatStatus: action.payload,
      };
    }
    case SET_STATUS_ONLINE: {
      return {
        ...state,
        isOnline: action.payload,
      };
    }

    default:
      return state;
  }
};
export default chatReducer;
