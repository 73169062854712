import {
  ADD_NARRATION,
  ADD_NARRATION_POPUP,
  ADD_NARRATION_SCREEN,
  CLEAR_NARRATION,
  DONE_NARRATION,
  REMOVE_NARRATION_POPUP,
  REMOVE_NARRATION_SCREEN,
  REMOVE_STOP_CURRENT_NARRATION,
  STOP_CURRENT_NARRATION,
  UPDATE_DURATION,
} from "../types";

import { getFunctions } from "../../services/firebase";
import { clearGChaosComputerSanNickName, sleep } from "../../utils/common";
import { emptyMessageNarration } from "../../utils/narration";
import { isUnderGet } from "../../utils/underget";
import { G_CHAOS } from "../constant";
import { STEP_NARRATION_FUNC } from "../funcs";
import stepJson from "../stepId";
import {
  getNickNameMaster,
  getNicknamePlayerSelectOrderCard,
  getNickNameTurnOfPlayer,
} from "./playerGameStateActions";
import { doneStepNarrations } from "./stepActions";
const functions = getFunctions();

const stepNarration = async ({
  stepId,
  numberOfGame,
  isRecall,
  isOverCall,
  gameId,
  playerId,
  playersId,
  limitCalls,
  turnStatus,
}) => {
  try {
    const data = {
      stepId: stepId,
      numberOfGame: numberOfGame,
      isRecall: isRecall,
      isOverCall: isOverCall,
      gameId: gameId,
      playerId: playerId,
      playersId: playersId,
      limitCalls: limitCalls,
      turnStatus: turnStatus,
    };

    const func = functions.httpsCallable(STEP_NARRATION_FUNC);
    await func(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

const getStepNarration = (stepId) => {
  const arrNarration = stepJson[stepId];
  if (!arrNarration) {
    return [];
  }
  return [...stepJson[stepId]];
};
const doStep = (stepId) => {
  return (dispatch) => {
    const narrations = getStepNarration(stepId);
    if (!narrations || 0 === narrations.length) {
      return;
    }
    dispatch(executeStep(narrations));
  };
};

const executeStep = (narrations) => {
  return (dispatch) => {
    const nextNarration = () => {
      setTimeout(async () => {
        const narration = narrations.shift();
        if (!narration) {
          dispatch(doneStepNarrations());
          return;
        }
        switch (narration.type) {
          case "narration":
            await sleep(1000);
            dispatch(
              addNarration({
                narrationId: narration.name,
                onDone: nextNarration,
              })
            );
            break;
          case "popup":
            await sleep(1000);
            dispatch(addNarrationPopup(narration.name));
            nextNarration();
            break;
          case "screen":
            dispatch(addNarrationScreen(narration.name));
            await sleep(narration.duration);
            dispatch(removeNarrationScreen(narration.name));
            nextNarration();
            break;
        }
      });
    };
    nextNarration();
  };
};

const addNarration = ({ narrationId, duration }) => {
  return (dispatch) =>
    dispatch({
      type: ADD_NARRATION,
      payload: { narrationId, duration },
    });
};

const addNarrationScreen = (screenName) => {
  return (dispatch) =>
    dispatch({
      type: ADD_NARRATION_SCREEN,
      payload: {
        narrationScreen: screenName,
      },
    });
};

const removeNarrationScreen = (screenName) => {
  return (dispatch) =>
    dispatch({
      type: REMOVE_NARRATION_SCREEN,
      payload: {
        narrationScreen: screenName,
      },
    });
};
const addNarrationPopup = (screenName) => {
  return (dispatch) =>
    dispatch({
      type: ADD_NARRATION_POPUP,
      payload: {
        narrationPopup: screenName,
      },
    });
};

const removeNarrationPopup = (screenName) => {
  return (dispatch) =>
    dispatch({
      type: REMOVE_NARRATION_POPUP,
      payload: {
        narrationPopup: screenName,
      },
    });
};

const doneNarration = () => {
  return (dispatch) => {
    dispatch({ type: DONE_NARRATION });
  };
};
const clearNarration = () => {
  return (dispatch) => {
    dispatch({ type: CLEAR_NARRATION });
  };
};

const stopCurrentNarration = () => (dispatch) =>
  dispatch({
    type: STOP_CURRENT_NARRATION,
  });

const removeStopCurrentNarration = () => (dispatch) =>
  dispatch({
    type: REMOVE_STOP_CURRENT_NARRATION,
  });

const updateNarrationText =
  (t, message = "") =>
  (_, getState) => {
    const isGChaosComputer = getState().room?.table.isGChaoComputer;
    const lastPlayerAction = getState().room?.endGamePlayerName;
    const joinedPlayers = getState().room?.joinedPlayers;
    const master = getState().playerGameState?.master;
    const isNarrationSound = getState().user?.setting.isNarrationSound;
    const otherPlayersState = getState().playerGameState?.otherPlayersState;
    const myIndex = getState().playerGameState?.myIndex;
    const nicknames = getState().playerGameState?.nicknames;
    const nextActionPlayer = getState().playerGameState?.nextActionPlayer;
    const myState = getState().playerGameState?.myState;

    let text = "";

    if (message === "" || emptyMessageNarration(message)) {
      return text;
    }
    text = t(`narrations.${message}`, { name: lastPlayerAction });
    if (message === "narration38") {
      if (isUnderGet(myState, otherPlayersState, myIndex)) {
        text = "";
      } else {
        if (!lastPlayerAction) {
          text = t("narrations.narration38Noname");
        }
        if (isGChaosComputer === true && lastPlayerAction === G_CHAOS) {
          text = t("narrations.narration38OvergetGChaos");
          text = clearGChaosComputerSanNickName(text);
        } else {
          text = t("narrations.narration38", { name: lastPlayerAction });
        }
      }
    }
    if (message === "narrationPlayerSelectMC") {
      const turnOfPlayer = getNickNameTurnOfPlayer(
        otherPlayersState,
        joinedPlayers,
        myIndex,
        true
      );
      text = t("narrations.narrationPlayerSelectMC", {
        name: turnOfPlayer?.nickName,
      });
      if (isGChaosComputer === true && turnOfPlayer?.nickName === G_CHAOS) {
        text = clearGChaosComputerSanNickName(text);
      }
    }
    if (message === "narration21") {
      text = isNarrationSound
        ? t("narrations.narration21")
        : t("narrations.narration21SoundOff");
    }

    if (message === "narration16_1") {
      text = t(`narrations.${message}`, {
        nicknames: nicknames,
      });
    }

    if (message === "narration17") {
      text = getNickNameMaster(
        t,
        message,
        joinedPlayers,
        master,
        isGChaosComputer
      );
    }
    /** GET NICKNAME SELECT ORDER CARD */
    if (message === "narrationFirstPlayerSelectOrderCard") {
      text = getNicknamePlayerSelectOrderCard(
        t,
        message,
        joinedPlayers,
        nextActionPlayer,
        isGChaosComputer
      );
    }
    if (!text) return "";
    return text;
  };

const updateDuration = (duration) => (dispatch) => {
  dispatch({ type: UPDATE_DURATION, payload: { duration } });
};

export {
  addNarration,
  addNarrationPopup,
  removeNarrationPopup,
  addNarrationScreen,
  removeNarrationScreen,
  doneNarration,
  clearNarration,
  doStep,
  stepNarration,
  stopCurrentNarration,
  removeStopCurrentNarration,
  updateNarrationText,
  updateDuration,
};
