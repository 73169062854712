import React, { useState, useEffect, useMemo } from "react";
import styled from "styled-components";
import { useSelector } from "react-redux";
import { STD_SIZE } from "../../../../../store/constant";

const Card = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  background-repeat: no-repeat;
  background-size: 100% 100%;
  border-radius: 4%;
  transform: rotate(90deg);

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 4%;
  }
`;

export const GCC_VLeft = () => {
  const [cardImg, setCardImg] = useState("");
  const { playerId, grandCross } = useSelector(
    (state) => state.playerGameState
  );
  const { currentPlayersId } = useSelector((state) => state.room.table);
  const renderTopIdx = useMemo(
    () => (currentPlayersId.indexOf(playerId) + 3) % 4,
    [currentPlayersId, playerId]
  );
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (size) {
      const cardWidth = size?.cardWidth;
      const cardHeight = size?.cardHeight;
      setInnerSize({ cardWidth, cardHeight });
    } else {
      const cardWidth = STD_SIZE.STD_CARD_WIDTH;
      const cardHeight = STD_SIZE.STD_CARD_HEIGHT;
      setInnerSize({ cardWidth, cardHeight });
    }
  }, [size]);

  useEffect(() => {
    var imgSrc =
      grandCross[renderTopIdx] != null && // don't show confirm introduce
      "NewYorkTimes" !== grandCross[renderTopIdx].suit
        ? `/cards/${grandCross[renderTopIdx].suit}${grandCross[renderTopIdx].value}.jpg`
        : "";
    setCardImg(imgSrc);
  }, [grandCross, renderTopIdx]);
  return (
    <Card width={innerSize.cardWidth} height={innerSize.cardHeight}>
      {cardImg != "" && <img src={cardImg} />}
    </Card>
  );
};
