import PropTypes from "prop-types";
import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import {
  Content,
  Footer,
  FullText,
  LessText,
  TextReadOrHide,
  TextWrap,
  Time,
} from "./styles";

const ReadMore = ({ text, time }) => {
  const [isReadMore, setIsReadMore] = useState(true);
  const [isShowLess, setIsShowLess] = useState(false);
  const [descLineCount, setDescLineCount] = useState("");
  const { t } = useTranslation();
  const desc = useRef();

  const toggleReadMore = () => {
    setIsReadMore(!isReadMore);
    handleShowLess();
  };

  const handleShowLess = () => {
    setIsShowLess(!isShowLess);
  };

  const countLines = () => {
    var divHeight = desc.current.clientHeight;
    var lineHeight = 14 * 1.5;
    var lines = divHeight / lineHeight;
    return lines;
  };

  useEffect(() => {
    setDescLineCount(countLines);
  }, []);

  return (
    <Content>
      {isReadMore ? (
        <LessText line={descLineCount > 2 ? 2 : 0} ref={desc}>
          {text}
        </LessText>
      ) : (
        <FullText ref={desc}>{text}</FullText>
      )}
      <Footer>
        <div></div>
        {descLineCount > 2 ? (
          isReadMore ? (
            <TextWrap onClick={toggleReadMore}>
              <img src="/assets/icons/icon-showmore.svg" alt="show-more" />
              <TextReadOrHide>{t("status.showMore")}</TextReadOrHide>
            </TextWrap>
          ) : (
            <TextWrap onClick={toggleReadMore}>
              <img src="/assets/icons/icon-showless.svg" alt="show-less" />
              <TextReadOrHide>{t("status.showLess")}</TextReadOrHide>
            </TextWrap>
          )
        ) : (
          <div></div>
        )}
        <Time>{time}</Time>
      </Footer>
    </Content>
  );
};

ReadMore.propTypes = {
  text: PropTypes.string,
  time: PropTypes.string,
};

export default ReadMore;
