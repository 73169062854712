/**
 * Sort card by suit and value
 * @param {*} cards
 * @returns
 */
export const sortCards = (cards) => {
  const hasClubsJ = cards.findIndex(
    (card) => card.suit === "Clubs" && card.value === 11
  );
  const clubsJ = hasClubsJ !== -1 && cards[hasClubsJ];

  const cards_without_clubJ = cards.filter(
    (card) => card.suit !== "Clubs" || card.value !== 11
  );
  const hasSpadesJ = cards_without_clubJ.findIndex(
    (card) => card.suit === "Spades" && card.value === 11
  );

  const spadesJ = hasSpadesJ !== -1 && cards_without_clubJ[hasSpadesJ];

  const card_without_clubJ_and_spaceJ = cards_without_clubJ.filter(
    (card) => card.suit !== "Spades" || card.value !== 11
  );
  const clubs = sortByCardValue(
    card_without_clubJ_and_spaceJ.filter((card) => card.suit === "Clubs")
  );
  const hearts = sortByCardValue(
    card_without_clubJ_and_spaceJ.filter((card) => card.suit === "Hearts")
  );
  const diamonds = sortByCardValue(
    card_without_clubJ_and_spaceJ.filter((card) => card.suit === "Diamonds")
  );
  const spades = sortByCardValue(
    card_without_clubJ_and_spaceJ.filter((card) => card.suit === "Spades")
  );
  const GCards = sortByCardValue(
    card_without_clubJ_and_spaceJ.filter((card) => card.suit === "G")
  );
  const newCards = spades.concat(hearts).concat(diamonds).concat(clubs);
  if (clubsJ !== false) newCards.unshift(clubsJ);
  if (spadesJ !== false) newCards.unshift(spadesJ);
  return GCards.concat(newCards);
};

/**
 * Sort card only by value
 * @param {*} cards
 * @returns
 */
const sortByCardValue = (cards) => {
  return cards.sort((card1, card2) => {
    return card1.suit === "G"
      ? -(card2.value - card1.value)
      : card2.value - card1.value;
  });
};

/**
 * Sort play cards
 * @param {*} grandCross
 * @returns
 */
export const sortedPlayerCards = (grandCross) => {
  const cards = sortCards(grandCross);
  return cards.map((card) => {
    return grandCross.findIndex(
      (el) => el.suit === card.suit && el.value === card.value
    );
  });
};
