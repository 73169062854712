import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { StyleButton } from "../styles";
import { useSelector } from "react-redux";

const ButtonComponent = ({ scale, onClick, children }) => {
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  const sortButtonWidth = useSelector((state) => state.size.sortButtonWidth);
  const sortButtonHeight = useSelector((state) => state.size.sortButtonHeight);
  useEffect(() => {
    if (size) {
      const startGameFontSize = 16 * size?.minScale;
      setInnerSize({
        startGameFontSize,
      });
    } else {
      const startGameFontSize = 16;
      setInnerSize({
        startGameFontSize,
      });
    }
  }, [size]);
  return (
    <StyleButton
      scale={scale}
      onClick={onClick}
      width={sortButtonWidth}
      height={sortButtonHeight}
      fontSize={innerSize.startGameFontSize}
    >
      {children}
    </StyleButton>
  );
};

ButtonComponent.propTypes = {
  scale: PropTypes.number,
  children: PropTypes.any,
  onClick: PropTypes.func,
};
export default ButtonComponent;
