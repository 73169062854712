import React, { forwardRef, useEffect, useMemo, useState } from "react";

import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Slide,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import moment from "moment";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import { apiStatus } from "../../../../../constants";
import { adminSwitchPackagePlayer } from "../../../../../store/actions/packageActions";
import { getPaymentMethodIdByPlayerId } from "../../../../../store/actions/playerActions";
import useStyles from "./styles";

export const ErrorText = styled.p`
  color: rgb(220, 50, 51);
  font-weight: 600;
  width: 376px;
  font-size: 14px;
  margin: auto;
  text-align: center;
`;

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditUser = ({
  handleClose,
  open,
  dataUser,
  handleLoading,
  handleSelectedPlayerId,
  reloadData,
  clearDataUser,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const [prePackageId, setPrePackageId] = useState("");
  const [disableButton, setDisableButton] = useState(true);
  const [isShowSelectPlan, setIsShowSelectPlan] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [errCard, setErrCard] = useState(false);
  const [paymentMethod, setPaymentMethod] = useState("");
  const [isFetchData, setIsFetchData] = useState(true);
  const packages = useSelector((state) => state.package.packages);
  const minScale = useSelector((state) => state.size.minScale);
  const input_W = useMemo(() => (minScale < 0.4 ? 250 : 300), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 11 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(100 * minScale * 2) : 150),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H: 51,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });
  const handleClearData = () => {
    setDisableButton(true);
    setIsShowSelectPlan(false);
    setErrCard(false);
    clearDataUser();
  };

  const handleChange = (event) => {
    if (event.target.value !== prePackageId) {
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
    setValues({ ...values, packageId: event.target.value });
  };

  const handleSubmit = async () => {
    handleLoading(true);
    handleClearData();
    handleClose();
    handleSelectedPlayerId(values.playerId);
    const resulf = await adminSwitchPackagePlayer(
      prePackageId,
      values.packageId,
      values.playerId
    );

    //NOTE: In case of registering for Bronze package, status 200 will be returned,
    //the change plan result will be successful, and status 201 will wait for the user
    //to pay and then let the user know if it is successful or not.

    if (resulf.data.status === apiStatus.FAIL) {
      handleLoading(false);
      toast.error(t("paymentSettings.ChangePlanUserFail"));
    } else if (resulf.data.status === apiStatus.SUCCESS) {
      handleLoading(false);
      reloadData();
      toast.success(t("paymentSettings.ChangePlanUserSuccess"));
    }
  };

  const getData = async (dataUser) => {
    setIsFetchData(true);

    setValues(dataUser);
    setPrePackageId(dataUser?.packageId);
    const _paymentMethod = await getPaymentMethodIdByPlayerId(
      dataUser?.playerId
    );
    setPaymentMethod(_paymentMethod.data);
    setIsFetchData(false);
  };

  const handleClickChangePlan = () => {
    if (paymentMethod) {
      setIsShowSelectPlan(true);
    } else {
      setErrCard(true);
    }
  };
  useEffect(() => {
    if (dataUser) {
      getData(dataUser);
    }
  }, [dataUser]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        handleClearData();
        handleClose();
      }}
      aria-describedby="dialog-change-pass"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        sx={{
          color: "#541513",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "30px",
        }}
      >
        {t("admin.userDetail")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-change-edit-user-content">
          <Box className={classes.mainBox}>
            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.planName")}
              </Typography>
              {isShowSelectPlan ? (
                <FormControl
                  sx={{ minWidth: 120 }}
                  className={classes.formSeclect}
                >
                  <Select
                    value={values.packageId}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    name="package"
                  >
                    {packages &&
                      packages.length > 0 &&
                      packages.map((el) => (
                        <MenuItem value={el.packageId} key={el.packageId}>
                          {t("paymentSettings." + el.packageName)}
                        </MenuItem>
                      ))}
                  </Select>
                </FormControl>
              ) : (
                <Typography>
                  {t("paymentSettings." + values.packageName)}
                </Typography>
              )}

              {!isShowSelectPlan && (
                <LoadingButton
                  onClick={() => handleClickChangePlan()}
                  loading={isFetchData}
                >
                  {t("paymentSettings.changePlan")}
                </LoadingButton>
              )}
            </Box>
            <Space space={20} />
            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("admin.userName")}
              </Typography>
              <Typography>{values.nickName}</Typography>
            </Box>
            <Space space={20} />

            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.email")}
              </Typography>
              <Typography>{values.email}</Typography>
            </Box>
            <Space space={20} />

            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("admin.startDate")}
              </Typography>
              <Typography>
                {moment(values.startDate).format("YYYY/MM/DD")}
              </Typography>
            </Box>
            <Space space={20} />

            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("admin.nextInvoiceDate")}
              </Typography>
              <Typography>
                {moment(values.nextInvoiceDate).format("YYYY/MM/DD")}
              </Typography>
            </Box>
            <Space space={20} />

            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("admin.endDate")}
              </Typography>
              <Typography>
                {moment(values.endDate).format("YYYY/MM/DD")}
              </Typography>
            </Box>
          </Box>
          {errCard && (
            <Typography
              color="red"
              style={{ textAlign: "center", marginBottom: "10px" }}
            >
              ユーザーはクレジットカードの情報は登録されません
            </Typography>
          )}
          <Box className={classes.boxButton}>
            <Button
              variant="outlined"
              className={classes.buttonBlue}
              onClick={() => {
                handleClearData();
                handleClose();
              }}
            >
              {t("tableSettings.back")}
            </Button>
            <Button
              onClick={handleSubmit}
              variant="contained"
              className={classes.buttonOrange}
              disabled={disableButton}
            >
              {t("changePassword.update")}
            </Button>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

EditUser.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  dataUser: PropTypes.object.isRequired,
  handleLoading: PropTypes.func,
  handleSelectedPlayerId: PropTypes.func,
  reloadData: PropTypes.func,
  clearDataUser: PropTypes.func,
};

export default EditUser;
