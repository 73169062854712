import { Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../components/Button";
import firebase from "../../../../services/firebase";
import { validatePhone } from "../../../../utils/validation";
import useStyles, { BoxWrapper } from "./styles";
import { sendOtpCode } from "../../../../store/actions/authActions";
import { SET_LOADING_GLOBAL } from "../../../../store/types";
import { useDispatch } from "react-redux";
import { PHONE_CODE } from "../../../../constants";
import { checkNumberPhoneExits } from "../../../../store/actions/playerActions";

firebase.auth().languageCode = "ja";

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const LoginPhone = ({ values, handleChangePage, handleChangeValues }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const boxWrapperWidth = 569;
  const classes = useStyles();
  const dispatch = useDispatch();

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        defaultCountry: "JP",
      }
    );
    window.recaptchaVerifier.render();
  };

  const handleLoading = (_value) => {
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: _value,
    });
  };

  const onSignInSubmit = async () => {
    handleLoading(true);
    setErrorMessage("");

    const err = validatePhone(values);
    if (err) {
      handleLoading(false);
      setErrorMessage(err);
      return;
    }

    // TODO: check number phone exits
    const isExitNumberPhone = await checkNumberPhoneExits(values);
    if (!isExitNumberPhone?.data) {
      handleLoading(false);
      setErrorMessage("validate.numberPhoneNotRegister");
      return;
    }

    if (err === "") {
      configureCaptcha();
      const phoneNumber = PHONE_CODE + values;
      const appVerifier = window.recaptchaVerifier;
      const otpErr = await sendOtpCode(phoneNumber, appVerifier);
      if (otpErr === "") {
        handleChangePage(1);
      } else {
        setErrorMessage(otpErr);
      }
    }
    handleLoading(false);
  };

  useEffect(() => {
    const clearError = setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    return () => clearTimeout(clearError);
  });

  return (
    <BoxWrapper width={boxWrapperWidth}>
      <Space space={30} />
      <Typography
        style={{ fontSize: 20, fontWeight: "bold", letterSpacing: "2px" }}
      >
        {t("register.registerTitle1")}
      </Typography>
      <Space space={5} />
      <Typography style={{ color: "#541f1c" }}>
        {t("register.registerTitle2")}
      </Typography>
      <Space space={40} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography className={classes.labelInput}>
          {t("common.numberPhone")}
        </Typography>
        <input
          type="text"
          name="numberPhone"
          placeholder={t("common.phone")}
          value={values}
          className={classes.input}
          onChange={handleChangeValues}
        />
      </Stack>
      <Space space={20} />

      {errorMessage && (
        <span style={{ fontSize: 12, color: "red" }}>{t(errorMessage)}</span>
      )}

      <Space space={20} />
      <Button
        variant="outlined"
        text={t("login.sendVerificationCode")}
        className={classes.loginBtn}
        onClick={onSignInSubmit}
      ></Button>
      <Space space={30} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("login.createNewAccount")}
        <Link className={classes.link} to="/register">
          {t("login.clickHere")}
        </Link>
      </Typography>
      <Space space={10} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("login.toContact")}
        <Link className={classes.link} to="/contact">
          {t("login.clickHere")}
        </Link>
      </Typography>

      <Space space={20} />
      <div id="recaptcha-container"></div>
    </BoxWrapper>
  );
};

LoginPhone.propTypes = {
  values: PropTypes.string,
  handleChangeValues: PropTypes.func,
  handleChangePage: PropTypes.func,
};

export default LoginPhone;
