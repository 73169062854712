import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  doneStepNarrations,
  updateStepNarrations,
} from "../../../../../store/actions/stepActions";
import { STEP_NARRATION } from "../../../../../store/constant";
import Narration10Recall from "../ScreenNarration/Narration10Recall";
import Narration1314OverCall from "../ScreenNarration/Narration1314OverCall";
import Narration15DetermineCall from "../ScreenNarration/Narration15DetermineCall";
import Narration20_1MasterGChaos from "../ScreenNarration/Narration20_1MasterGChaos";
import Narration34GCard from "../ScreenNarration/Narration34G0G1";
import Narration41EndGame from "../ScreenNarration/Narration41EndGame";
import Narration4_123 from "../ScreenNarration/Narration4_123";
import Narration5Ark from "../ScreenNarration/Narration5Ark";
import NarrationLeaveWhilePlayingNotification from "../ScreenNarration/NarrationLeaveWhilePlayingNotification";
import NarrationNotify from "../ScreenNarration/NarrationNotify";
import NarrationUseMasterWinner from "../ScreenNarration/NarrationUseMasterWinner";
import PopupConfirms from "../ScreenNarration/PopupConfirms";
import { doStep } from "./../../../../../store/actions/narrationActions";

const StepNarration = () => {
  const dispatch = useDispatch();
  const stepQueue = useSelector((state) => state.stepQueueReducer.stepQueue);
  const canRunNewStep = useSelector((state) => state.stepQueueReducer.isDone);
  const message = useSelector((state) => state.stepQueueReducer.message);

  useEffect(() => {
    if (canRunNewStep && stepQueue && stepQueue.length > 0) {
      const newQueue = [...stepQueue];
      const step = newQueue.shift();
      dispatch(updateStepNarrations(newQueue, message));
      if (STEP_NARRATION[step]) {
        dispatch(doStep(step));
      } else {
        dispatch(doneStepNarrations());
      }
    }
  }, [canRunNewStep, dispatch, stepQueue, message]);

  return (
    <>
      <Narration5Ark />
      <Narration10Recall />
      <Narration15DetermineCall />
      <Narration20_1MasterGChaos />
      <Narration34GCard />
      <Narration41EndGame />
      <Narration1314OverCall />
      <Narration4_123 />
      <NarrationUseMasterWinner />
      <NarrationLeaveWhilePlayingNotification />
      <NarrationNotify />
      <PopupConfirms />
    </>
  );
};
export default React.memo(StepNarration);
