import { Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/ja";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import AddFriendToRoomDialog from "../../../../../../components/Dialog/AddFriendToRoomDialog";
import { sendMessage } from "../../../../../../store/actions/chat";
import { CHAT_TYPE } from "../../../../../../store/constant";
import ChatRepo from "../../../../../../store/repositories/chat";
import { reduceNameLength } from "../../../../../../utils/common";
import ChatItem from "../ChatItem";
import ListFriendOfGroup from "../ListFriendOfGroup";
import {
  ChatBoxInputSection,
  ChatContent,
  ChatGroupHeader,
  ChatGroupNamePlayer,
  ChatGroupTitle,
  FirstNextDay,
  MessageContent,
  SendButton,
  StyleButton,
  StyledTextInput,
} from "./styles";

moment.locale("ja");

const ChatBoxGroup = (props) => {
  const { isGroup, displayRoom, threadId, messageList } = props;
  const { t } = useTranslation();
  const [chatMess, setChatMes] = useState("");
  const [openDialog, setOpenDialog] = useState(false);
  const [memberIds, setMemberIds] = useState([]);
  const { playerId, avatar, nickName } = useSelector((state) => state.user);
  const isOnline = useSelector((state) => state.chat.isOnline);
  const minscale = useSelector((state) => state.size.minScale);

  const [messageListHandled, setMessageListHandled] = useState([]);

  const handleOnClose = () => {
    setOpenDialog(false);
  };

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  const onMessageChange = (event) => {
    let message = event.target.value;
    setChatMes(message);
  };
  const onEnter = (event) => {
    if (event.key === "Enter" && event.keyCode == 13) {
      onSend(chatMess);
      setChatMes("");
    }
  };

  const onSend = (message) => {
    if (message == "") return;
    sendMessage({
      threadId: threadId,
      threadType: isGroup ? CHAT_TYPE.GROUP : CHAT_TYPE.PRIVATE,
      senderId: playerId,
      senderName: nickName,
      senderAvatar: avatar,
      message: message,
      tableName: displayRoom,
    });
  };

  useEffect(() => {
    let time = 0;
    const _messageListHandled = messageList.map((item) => {
      if (new Date(item.milliseconds).getDate() - time >= 1) {
        time = new Date(item.milliseconds).getDate();
        return {
          ...item,
          firstNextDay: true,
        };
      }
      return { ...item, firstNextDay: false };
    });
    setMessageListHandled(_messageListHandled);
  }, [messageList]);

  useEffect(() => {
    scrollToBottom();
  }, [messageListHandled]);

  useEffect(() => {
    const readData = async () => {
      if (threadId) {
        const thread = await ChatRepo.read(threadId);
        const threadMemberIds = thread?.threadMemberIds ?? [];
        setMemberIds(threadMemberIds);
      }
    };
    readData();
  }, [threadId]);

  return (
    <ChatContent>
      <MessageContent minscale={minscale}>
        {isGroup ? (
          <ChatGroupHeader minscale={minscale}>
            <ChatGroupTitle minscale={minscale}>
              <Typography
                variant="h5"
                fontSize={minscale > 0.5 ? minscale * 17 : 0.5 * 17}
              >
                {t("game.groupChat")}
              </Typography>
              {memberIds?.length < 10 && (
                <>
                  <StyleButton
                    minscale={minscale}
                    onClick={() => setOpenDialog(true)}
                  >
                    {t("game.addFriends")}
                  </StyleButton>
                  <AddFriendToRoomDialog
                    isOpen={openDialog}
                    onClose={handleOnClose}
                    threadId={threadId}
                  />
                </>
              )}
            </ChatGroupTitle>
            <ChatGroupNamePlayer
              style={{
                fontSize: `${
                  minscale > 0.5 ? minscale * 17 + "px" : 0.5 * 17 + "px"
                }`,
              }}
            >
              {reduceNameLength(displayRoom, 15)}
              <ListFriendOfGroup threadId={threadId} />
            </ChatGroupNamePlayer>
          </ChatGroupHeader>
        ) : (
          <ChatGroupHeader minscale={minscale}>
            <ChatGroupTitle
              minscale={minscale}
              style={{ justifyContent: "flex-end" }}
            >
              {memberIds?.length < 10 && (
                <StyleButton
                  minscale={minscale}
                  onClick={() => setOpenDialog(true)}
                >
                  {t("game.addFriends")}
                </StyleButton>
              )}

              {threadId && (
                <AddFriendToRoomDialog
                  isOpen={openDialog}
                  onClose={handleOnClose}
                  threadId={threadId}
                />
              )}
            </ChatGroupTitle>
          </ChatGroupHeader>
        )}
        {messageListHandled &&
          messageListHandled.length > 0 &&
          messageListHandled.map((item, index) => {
            if (item.firstNextDay) {
              return (
                <>
                  <FirstNextDay>
                    {moment(item.milliseconds).format("llll").slice(0, -5)}
                  </FirstNextDay>
                  <ChatItem messageData={item} id={playerId} key={index} />
                </>
              );
            } else {
              return <ChatItem messageData={item} id={playerId} key={index} />;
            }
          })}

        {isOnline === false && (
          <div
            style={{
              textAlign: "center",
              fontSize: `${
                minscale > 0.5 ? `${minscale * 12}px` : `${0.5 * 12}px`
              }`,
              color: "red",
              marginBottom: `${
                minscale > 0.5 ? `${minscale * 10}px` : `${0.5 * 10}px`
              }`,
              letterSpacing: "2px",
            }}
          >
            {t("status.sendMessageFailed")}
          </div>
        )}
        <div ref={messagesEndRef} />
      </MessageContent>
      <ChatBoxInputSection minscale={minscale}>
        <StyledTextInput
          minscale={minscale}
          onChange={onMessageChange}
          placeholder={t("game.sendChatMessage")}
          onKeyDown={onEnter}
          value={chatMess}
        />
        <SendButton
          minscale={minscale}
          onClick={() => {
            setChatMes("");
            onSend(chatMess);
          }}
        >
          {t("common.send")}
        </SendButton>
      </ChatBoxInputSection>
    </ChatContent>
  );
};

ChatBoxGroup.propTypes = {
  isGroup: PropTypes.bool,
  displayRoom: PropTypes.string,
  threadId: PropTypes.string,
  messageList: PropTypes.array,
  isConnet: PropTypes.bool,
  isLoading: PropTypes.bool,
};

export default ChatBoxGroup;
