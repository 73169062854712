import styled from "styled-components";
import { SECOND_COLOR } from "../../../../../../constants";

export const ListFriendGroup = styled.div`
  position: absolute;
  background-color: ${SECOND_COLOR};
  padding: 0.5rem;
  border-radius: 10px;
  min-width: 100px;
  max-width: 150px;
  top: 0;
  right: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transform-origin: top left;
  transform: scale(0);
`;
export const ListFriend = styled.div`
  max-height: 200px;
  overflow-y: auto;
`;
export const ListFriendItem = styled.div`
  color: white;
  padding: 5px;
  line-height: 1.7;
  display: -webkit-box !important;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;
