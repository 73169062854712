import React from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import { numberWithCommas } from "../../utils/common";
import { useTranslation } from "react-i18next";
import useStyles from "./styles";

const PackageCard = ({
  packageName,
  packagePrice,
  isShowPackagePrice,
  packagePlayTimes,
  isShowDesciption,
  packageIcon,
  backgroundColor,
  color,
  reposinve,
}) => {
  const { t } = useTranslation();
  const classes = useStyles({ backgroundColor, color });

  return (
    <Box className={classes.packageBox}>
      <Typography
        className={`${reposinve ? classes.textRepnsive : classes.text}`}
      >
        {t("paymentSettings." + packageName)}
      </Typography>

      {/* packagePrice */}
      {isShowPackagePrice && (
        <Typography
          className={`${reposinve ? classes.textRepnsive : classes.text}`}
        >
          {packagePrice === 0
            ? `${t("paymentSettings.free")}`
            : `${numberWithCommas(packagePrice)} 円`}
        </Typography>
      )}

      {/* packageDescription */}
      {isShowDesciption && packagePlayTimes && (
        <Typography
          className={`${reposinve ? classes.textRepnsive : classes.text}`}
        >{`${packagePlayTimes} 回/月`}</Typography>
      )}

      {/* packageIcon */}
      <Box className={classes.packageIconBox}>{packageIcon}</Box>
    </Box>
  );
};

PackageCard.propTypes = {
  packageName: PropTypes.string,
  packagePrice: PropTypes.number,
  packagePlayTimes: PropTypes.number,
  isShowPackagePrice: PropTypes.bool,
  isShowDesciption: PropTypes.bool,
  backgroundColor: PropTypes.string,
  reposinve: PropTypes.bool,
  color: PropTypes.string,
  packageIcon: PropTypes.element,
};

export default PackageCard;
