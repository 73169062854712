import styled from "styled-components";

export const Container = styled.div``;
export const Text = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.clamp};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;
export const TextReadOrHide = styled.p`
  margin-left: 5px;
`;
export const TextWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
export const ReadOrHide = styled.span`
  display: block;
  text-align: center;
  color: #442211;
  cursor: pointer;
`;
export const Time = styled.p`
  text-align: right;
  color: #442211;
  font-size: 12px;
`;
export const Footer = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: auto auto auto;
`;

export const Content = styled.div`
  box-sizing: border-box;
  font-size: 14px;
  color: #442211;
  text-align: start;
`;

export const FullText = styled.div`
  word-break: break-word;
`;

export const LessText = styled.div`
  display: -webkit-box;
  -webkit-line-clamp: ${(props) => props.line};
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
`;
