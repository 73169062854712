import { makeStyles } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import ByRetailPackageDialog from "../../../../../components/Dialog/ByRetailPackageDialog";
import BySubDialog from "../../../../../components/Dialog/BySubDialog";
import TimeRemainClock from "../../../../../components/TimeRemainClock";
import { clearNarration } from "../../../../../store/actions/narrationActions";
import { leaveRoomWhenRoomNotExisted } from "../../../../../store/actions/playerActions";
import {
  clearGameStateOfOnePlayer,
  saveHandCardsRound12,
} from "../../../../../store/actions/playerGameStateActions";
import {
  leaveRoom,
  leaveRoomWhilePlayingFunc,
  playerConfirm,
} from "../../../../../store/actions/roomActions";
import {
  GAME_STATUS,
  OVERTIME,
  STD_SIZE,
  TUTORIAL_MODE,
} from "../../../../../store/constant";
import {
  CLOSE_CHAT_BOX,
  LEAVE_ROOM,
  REMOVE_PLAYER_GAME_STATE,
  SET_SELECTED_THREAD_ID,
} from "../../../../../store/types";
import OkDialog from "../../../../GameScreen/components/NewDialog/OkDialog";
import BasicDialog from "../../NewDialog/BasicDialog";
import LeaveRoomDialog from "../../NewDialog/LeaveRoom";
import LeaveRoomWhilePlayingDialog from "../../NewDialog/LeaveRoomWhilePlayingDialog";
import { Profile } from "../../Profile/Profile";
import { TopTutorialCards } from "../../TutorialCards/TopTutorialCards";
import GameConfig from "../GameConfig";
import GameRightMiddle from "./component/GameRightMiddle";
import RightBottom from "./component/RightBottom";
import { LeaveRoomBtn } from "./styles";

const useStyles = makeStyles(() => ({
  profile: {
    marginLeft: (props) => props.marginLeft,
  },
  tutorial: {
    marginLeft: (props) => props.marginTop,
  },
  button: {
    marginTop: (props) => props.btnMarginTop,
  },
  li: {
    display: "inlineBlock",
    fontSize: "1rem",
    listStyleType: "none",
  },
  span: {
    display: "block",
    fontSize: "1.5rem",
  },
}));
// NOTE: WHy need to compute turn again?
const getTurnOfPlayer = (otherPlayersState, isMyTurn) => {
  let counterTurnPlayer = 0;
  const _otherPlayersState = [...otherPlayersState];
  for (const pl of _otherPlayersState) {
    if (pl && pl.isYourTurn === false) ++counterTurnPlayer;
  }
  return 3 === counterTurnPlayer && isMyTurn;
};

const isGameEnd = (gameStatus) => {
  return [GAME_STATUS.END, GAME_STATUS.WIN, GAME_STATUS.LOSE].includes(
    gameStatus
  );
};

const GameRightContainer = styled.div`
  height: ${(props) => props.height}px;
  display: flex;
  width: ${(props) => props.width}px;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
`;

const GameRightTop = styled.div`
  flex: 1;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-end;
  width: 100%;
  position: relative;
`;

const COUNTDOWN_TIME = 2400000;

export const GameRight = ({ handleLoading, toggleStatusList }) => {
  const profile0 = useSelector((state) => state.room.profile0);
  const profile1 = useSelector((state) => state.room.profile1);
  const profile2 = useSelector((state) => state.room.profile2);
  const profile3 = useSelector((state) => state.room.profile3);
  const bottomHeight = useSelector((state) => state.size.bottomHeight);
  const hShipWidth = useSelector((state) => state.size.hShipWidth);
  const height = useSelector((state) => state.size.height);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const players = useSelector((state) => state.room.joinedPlayers);
  const playerId = useSelector((state) => state.user.playerId);
  const nickName = useSelector((state) => state.user.nickName);
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const roomId = useSelector((state) => state.room.table.roomId);
  const tableId = useSelector((state) => state.room.table.tableId);
  const gameId = useSelector((state) => state.room.table.gameId);
  const profileShipSpace = useSelector((state) => state.size.profileShipSpace);
  const rightWidth = useMemo(
    () => hShipWidth + 2 * shipSpace,
    [shipSpace, hShipWidth]
  );
  const isPlaying = useSelector((state) => state.room.table.isPlaying ?? false);
  const size = useSelector((state) => state.size);
  const longButtonWidth = useSelector((state) => state.size.longButtonWidth);
  const longButtonHeight = useSelector((state) => state.size.longButtonHeight);
  const [innerSize, setInnerSize] = useState({});
  const [isDisable, setIsDisable] = useState(false);
  const classes = useStyles({
    marginTop: shipSpace,
    marginLeft: profileShipSpace,
    btnMarginTop: shipSpace,
  });
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );
  const isMyTurn = useSelector((state) => state.playerGameState.isMyTurn);
  const myState = useSelector((state) => state.playerGameState.myState);
  const cardPlayer2 = useSelector((state) => state.playerGameState.cardPlayer2);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const [showDialogBySub, setShowDialogBySub] = useState(false);
  const [showDialogByRetail, setShowDialogByRetail] = useState(false);
  const otherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const owner = useSelector((state) => state.room.table.owner);
  const isGChaoComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const showPlayingTimer = useSelector(
    (state) => state.room.table.showRemainTimer
  );
  const startTime = useSelector((state) => state.room.table.startTime);
  const isMyTurnComputed = useMemo(
    () => getTurnOfPlayer(otherPlayersState, isMyTurn),
    [otherPlayersState, isMyTurn]
  );
  const threadId = useSelector((state) => state.room.threadId);

  const { t } = useTranslation();
  const [openLeaveRoom, setOpenLeaveRoom] = useState(false);
  const [playedTime, setPlayedTime] = useState("");
  const [openConfirmLeave, setOpenConfirmLeave] = useState(false);
  const [
    openLeaveRoomWhilePlayingAskLeave,
    setOpenLeaveRoomWhilePlayingAskLeave,
  ] = useState(false);
  const [
    openLeaveRoomWhilePlayingAskStop,
    setOpenLeaveRoomWhilePlayingAskStop,
  ] = useState(false);
  const [isGameOver, setIsGameOver] = useState(false);
  const [openOverTimeDialog, setOpenOverTimeDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const confirmLeaveRoom = () => {
    setOpenLeaveRoom(true);
  };
  const { detect } = require("detect-browser");
  const browser = detect();

  const handleLeaveRoomNotPlaying = () => {
    try {
      if (profile0.playerId !== owner) {
        console.log("handleLeaveRoomNotPlaying: ", "not owner");
        setOpenConfirmLeave(true);
        return;
      }
      if (!currentPlayersId || currentPlayersId.length === 0) {
        console.log("handleLeaveRoomNotPlaying: ", "no player");
        setOpenConfirmLeave(true);
        return;
      }
      const currentPlayerIds = currentPlayersId.filter(
        (playerId) => null !== playerId
      );
      let numberOfPlayer = currentPlayerIds.length;
      if (isGChaoComputer) {
        numberOfPlayer -= 1;
      }
      if (numberOfPlayer <= 2 && profile0.playerId === owner) {
        console.log("handleLeaveRoomNotPlaying: ", "2 player");
        setOpenConfirmLeave(true);
        return;
      }
    } catch (error) {
      console.log("handleLeaveRoomNotPlaying: ", error);
    }
    confirmLeaveRoom();
  };

  const handleLeaveRoomWhilePlaying = async () => {
    setOpenLeaveRoomWhilePlayingAskLeave(true);
  };

  const handlePlayerLeaveRoom = async () => {
    if (isPlaying === true) {
      setIsLoading(true);
      handleLeaveRoomWhilePlaying();
      setIsLoading(false);
    } else {
      setIsLoading(true);
      handleLeaveRoomNotPlaying();
      setIsLoading(false);
    }
  };

  const leaveRoomWhilePlayingAskLeaveAgree = () => {
    setOpenLeaveRoomWhilePlayingAskLeave(false);
    setOpenLeaveRoomWhilePlayingAskStop(true);
  };

  const leaveRoomWhilePlayingAskLeaveStop = async () => {
    setIsLoading(true);
    setOpenLeaveRoomWhilePlayingAskStop(false);
    localStorage.setItem("isShowJoinRoomTwilio", JSON.stringify(false));
    const playerIds = players?.map((pl) => pl && pl.playerId) ?? [];
    const playerId = profile0?.playerId;
    dispatch(clearNarration());
    dispatch({ type: LEAVE_ROOM });
    dispatch({ type: REMOVE_PLAYER_GAME_STATE });
    await leaveRoomWhenRoomNotExisted(playerId);
    await leaveRoomWhilePlayingFunc(
      playerIds,
      playerId,
      gameId,
      tableId,
      roomId,
      threadId
    );
    if (browser.name === "safari") {
      location.reload();
    }
    setIsLoading(false);
  };

  const handleLeaveRoom = async (nextPlayerId) => {
    try {
      handleLoading(true);
      setOpenConfirmLeave(false);
      localStorage.setItem("isShowJoinRoomTwilio", JSON.stringify(false));

      await leaveRoomWhenRoomNotExisted(playerId); // TODO: remove current room from player
      const playerProfiles = [profile0, profile1, profile2, profile3];
      await leaveRoom(roomId, profile0?.playerId, nextPlayerId, playerProfiles);
      dispatch(clearNarration());
      dispatch({ type: REMOVE_PLAYER_GAME_STATE });
      dispatch({ type: CLOSE_CHAT_BOX });
      dispatch({
        type: SET_SELECTED_THREAD_ID,
        payload: "",
      });
      handleLoading(false);
      if (browser.name === "safari") {
        location.reload();
      }
    } catch (error) {
      console.log("handleLeaveRoom: ", error);
      handleLoading(false);
    }
  };

  const isTutorial = useMemo(() => {
    return [TUTORIAL_MODE.HAS_CALL, TUTORIAL_MODE.NO_CALL].includes(
      tutorialMode
    );
  }, [tutorialMode]);

  //TODO: handle dialog by sub
  const handleShowDialogBySub = useCallback((value) => {
    setShowDialogBySub(value);
  }, []);

  const handleShowDialogByRetail = useCallback((value) => {
    setShowDialogByRetail(value);
  }, []);

  const handleConfirmOverGame = useCallback(
    (playerId, roomId, tableId, currentPlayersId, isGChaosComputer, gameId) => {
      setIsDisable(true);
      clearGameStateOfOnePlayer(playerId, roomId, tableId);
      clearAllPlayerGameState(
        roomId,
        playerId,
        currentPlayersId,
        gameId,
        tableId,
        isGChaosComputer
      );
      setOpenOverTimeDialog(false);
      const time = setTimeout(() => {
        setIsDisable(false);
        clearTimeout(time);
      }, 500);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const clearAllPlayerGameState = async (
    roomId,
    playerId,
    playerIds,
    idGame,
    tableId,
    GChaosComputer
  ) => {
    setPlayedTime(0);
    const data = {
      roomId: roomId,
      playerId: playerId,
      playerIds: playerIds,
      gameId: idGame,
      tableId: tableId,
      type: OVERTIME,
      nickName,
      isGChaosComputer: GChaosComputer,
    };
    playerConfirm(data);
  };
  useEffect(() => {
    if (!cardPlayer2?.cards) return;
    if (!isGameEnd(gameStatus)) {
      const cards = cardPlayer2?.cards.filter((card) => card != null);
      if (cards.length < 2) {
        dispatch(saveHandCardsRound12());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardPlayer2.cards, gameStatus]);

  useEffect(() => {
    if (size) {
      const shipSpace = size.shipSpace;
      const leaveRoomWidth = size.longButtonWidth;
      const vShipWidth = size.hSHipWidth + 2 * shipSpace;
      const bottomHeight = size.bottomHeight;
      const topHeight = size.height - bottomHeight - size.vShipHeight;
      const leaveGameFontSize = 16 * size?.minScale;
      const remainClockWidth = bottomHeight - longButtonHeight;
      setInnerSize({
        leaveRoomWidth,
        vShipWidth,
        topHeight,
        bottomHeight,
        leaveGameFontSize,
        remainClockWidth,
      });
    } else {
      const leaveRoomWidth = STD_SIZE.STD_LONG_BUTTON_WIDTH;
      const vShipWidth = STD_SIZE.STD_V_SHIP_WIDTH;
      const bottomHeight =
        STD_SIZE.STD_H_SHIP_HEIGHT + 2 * STD_SIZE.STD_SHIP_SPACE;
      const topHeight =
        STD_SIZE.STD_HEIGHT - bottomHeight - STD_SIZE.STD_SHIP_WIDTH;
      const leaveGameFontSize = 16;
      const remainClockWidth = bottomHeight - longButtonHeight;

      setInnerSize({
        leaveRoomWidth,
        vShipWidth,
        topHeight,
        bottomHeight,
        leaveGameFontSize,
        remainClockWidth,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const tick = (startTime) => {
    return Date.now() - startTime;
  };

  useEffect(() => {
    if (isGameOver) {
      setOpenOverTimeDialog(true);
      setIsGameOver(false);
    }
  }, [isGameOver]);
  useEffect(() => {
    if (startTime) {
      let timerID = setInterval(() => {
        const result = COUNTDOWN_TIME - tick(startTime);
        if (result >= 0) {
          setPlayedTime(result);
        }
        if (result < 0) {
          //game time out
          setIsGameOver(true);
          clearInterval(timerID);
        }
      }, 1000);
      return () => clearInterval(timerID);
    }
  }, [startTime]);
  return (
    <GameRightContainer width={rightWidth} height={height}>
      <GameRightTop>
        <TopTutorialCards
          id="topTutorialCards"
          isTutorial={isTutorial}
          cards={cardPlayer2?.cards}
          isGChaos={cardPlayer2.isGChaos}
          className={classes.tutorial}
          isSorted={cardPlayer2.isSorted}
        />
        <GameConfig toggleStatusList={toggleStatusList} />
      </GameRightTop>
      <GameRightMiddle />
      <RightBottom id="bottomProfile" height={bottomHeight}>
        <div
          style={{
            width: "50%",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
          }}
        >
          <Profile
            position="left"
            profile={profile0}
            positionTurn="bottom"
            isMyTurn={isMyTurnComputed}
            numberOfCalls={myState?.numberOfCalls}
            size={size}
            className={classes.profile}
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            height: bottomHeight,
          }}
        >
          <div
            style={{
              width: `${longButtonWidth}px`,
              height: `calc(${bottomHeight}px - ${longButtonHeight}px - ${
                innerSize.leaveGameFontSize / 1.2
              }px)`,
              boxSizing: "border-box",
              overflow: "hidden",
              marginTop: "10px",
            }}
          >
            <TimeRemainClock
              time={playedTime}
              isRemaining={showPlayingTimer}
              isGameScreen={true}
              handleUpPlan={() => handleShowDialogBySub(true)}
              handleByPlayTime={() => handleShowDialogByRetail(true)}
            />
          </div>

          <LeaveRoomBtn
            onClick={handlePlayerLeaveRoom}
            width={longButtonWidth}
            height={longButtonHeight}
            fontSize={innerSize.leaveGameFontSize}
            className={classes.button}
          >
            {t("tables.leaveRoom")}
          </LeaveRoomBtn>
          <LeaveRoomDialog
            isOpen={openLeaveRoom}
            onClose={() => {
              setOpenLeaveRoom(false);
            }}
            onLeaveRoom={handleLeaveRoom}
          />
          <BasicDialog
            isOpen={openConfirmLeave}
            onCancel={() => setOpenConfirmLeave(false)}
            onAgree={handleLeaveRoom}
            closeText={t("tables.cancel")}
            agreeText={t("tables.leaveRoom")}
            mainText={t("tables.confirmLeaveRoom")}
            onCloseDialog={() => setOpenConfirmLeave(false)}
          ></BasicDialog>
          {/* ASK LEAVE */}
          <LeaveRoomWhilePlayingDialog
            isOpen={openLeaveRoomWhilePlayingAskLeave}
            onCancel={() => setOpenLeaveRoomWhilePlayingAskLeave(false)}
            onAgree={leaveRoomWhilePlayingAskLeaveAgree}
            closeText={t("tables.cancel")}
            agreeText={t("tables.leaveRoom")}
            mainText={t("tables.confirmLeaveRoom")}
            onCloseDialog={() => setOpenLeaveRoomWhilePlayingAskLeave(false)}
          ></LeaveRoomWhilePlayingDialog>
          {/* ASK STOP */}
          <LeaveRoomWhilePlayingDialog
            isOpen={openLeaveRoomWhilePlayingAskStop}
            onCancel={() => setOpenLeaveRoomWhilePlayingAskStop(false)}
            onAgree={leaveRoomWhilePlayingAskLeaveStop}
            closeText={t("tables.no")}
            agreeText={t("common.yes")}
            mainText={t("tables.confirmStopGameLeaveRoomWhilePlaying")}
            onCloseDialog={() => setOpenLeaveRoomWhilePlayingAskStop(false)}
          ></LeaveRoomWhilePlayingDialog>
        </div>
        {/* BY PACKAGE */}
        <BySubDialog
          openDialog={showDialogBySub}
          goBack={() => handleShowDialogBySub(false)}
          handleLoading={handleLoading}
          isInGameSreen={true}
        />

        {/* By Retail package dialog */}
        <ByRetailPackageDialog
          openDialog={showDialogByRetail}
          goBack={() => handleShowDialogByRetail(false)}
          // handleLoading={handleLoading}
          isInGameSreen={true}
        />
      </RightBottom>
      {/* Dialog overtime */}
      <OkDialog
        isOpen={openOverTimeDialog}
        onAgree={() =>
          handleConfirmOverGame(
            playerId,
            roomId,
            tableId,
            currentPlayersId,
            isGChaosComputer,
            gameId
          )
        }
        confirmText={t("game.overTime")}
        btnLabel={"OK"}
        isDisable={isDisable}
      />
      <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </GameRightContainer>
  );
};

GameRight.propTypes = {
  handleLoading: PropTypes.func,
  toggleStatusList: PropTypes.func,
};
