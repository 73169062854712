/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  imgBox: {
    display: "flex",
    alignItems: "center",
    background: "#786565",
    textDecoration: "none",
  },
  logoImg: {
    width: "20px",
    height: "20px",
    borderRadius: "100vw",
    border: "3px solid #c1c1c1",
    padding: "5px",
    margin: "7px",
    cursor: "pointer",
  },
  logoTitle: {
    color: "#fff",
    fontSize: "1.5rem",
  },

  link: {
    padding: "1rem 1.5rem",
    color: "black",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    "&:hover": {
      background: "#624a4a",
      color: "#fff !important",
    },

    "&:hover >  span": {
      color: "#fff",
    },
  },
  linkActive: {
    padding: "1rem 1.5rem",
    display: "flex",
    alignItems: "center",
    textDecoration: "none",
    background: "#624a4a",
    color: "#fff !important",

    "& >  span": {
      color: "#fff",
    },
  },
  title: {
    color: "black",
    marginLeft: "10px",
  },
  listItem: {},
}));

export default useStyles;
