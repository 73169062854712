import styled from "styled-components";

export const Container = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  z-index: +1;
  position: absolute;
  top: ${(props) => props.top}px;
  right: 0;
  left: 0;
  margin: auto;
  bottom: ${(props) => props.top}px;
  background-image: url("/assets/bg_master_cards.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const TitleWrapper = styled.div`
  width: 80%;
  text-align: center;
  color: white;
  margin-top: 20%;
`;

export const CardWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 20%;
  flex: 1;
`;

export const CardSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const Nickname = styled.div`
  width: 110%;
  height: 28px;
  box-sizing: border-box;
  padding: 2px 5px;
  background-color: ${(props) =>
    props.hasNickname ? "#ffffff" : "transparent"};
  border-radius: 999px;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    width: 90%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ArkBackground = styled.div`
  width: 30%;
  height: 30%;
  padding: 15px;
  box-sizing: border-box;
  background-color: #3b0904;
  margin: auto;
`;
