import firebase, { getFunctions } from "../../services/firebase";
import { createThread } from "../actions/chat";
import { CARD_SUIT_WHATEVER, CHAT_TYPE } from "../constant";
import {
  CHECK_OWNER_STATUS,
  NORMAL_PLAY_CARD,
  REMOVE_TABLE,
  CREATE_GAME_ROOM,
} from "../funcs";
import RoomRepo from "../repositories/room";
import TableRepo from "../repositories/table";
import { GET_STATE } from "../types";
import { uploadImage } from "./userAction";

/** FIELDS */
const IS_PLAYING = "isPlaying";
const TABLE_ID = "tableId";
const TABLE_NAME = "tableName";
const TABLE_DESCRIPTION = "description";
const IS_OPEN = "joinTableRole";
const ROOM_ID = "roomId";
const OWNER = "owner";
const ROOM_PLAYERS = "joinedPlayers";
const PLAYER_ID = "playerId";
const PLAYER_NAME = "nickName";

const functions = getFunctions();
const db = firebase.firestore();

export const removeRoom = async (
  playerIds,
  myId,
  gameId,
  tableId,
  roomId,
  threadId
) => {
  const removeRoomFunc = functions.httpsCallable(REMOVE_TABLE);
  await removeRoomFunc({ playerIds, myId, gameId, tableId, roomId, threadId });
};

export const getNewRoomId = () => {
  return firebase.firestore().collection("rooms").doc().id;
};

export const createRoom = async (roomSetting, player) => {
  try {
    const playerData = {
      playerId: player.playerId,
      fullName: player.fullName,
      avatar: player.avatar,
      nickName: player.nickName,
    };
    const tableData = {
      isPlaying: false,
      tableName: roomSetting.tableName,
      tableAvatar: roomSetting.tableAvatar,
      isGChaoComputer: roomSetting.isGChaoComputer,
      joinTableRole: roomSetting.joinTableRole,
      owner: player.playerId,
      description: roomSetting.description,
      roomId: roomSetting.roomId,
    };

    const members = [
      {
        memberId: player.playerId,
        memberName: player.nickName,
        memberAvatar: player.avatar,
        isReading: false,
        countUnreadMessage: 0,
      },
    ];
    const threadId = await createThread(members, CHAT_TYPE.ROOM);
    const numPlayerReady = () => {
      return roomSetting.isGChaoComputer ? 2 : 1;
    };
    const roomData = {
      owner: playerData,
      tableData: tableData,
      joinTableRole: roomSetting.joinTableRole,
      accessToken: roomSetting.accessToken,
      invites: roomSetting.invites,
      message: roomSetting.message,
      isPublicNotify: roomSetting.notify,
      threadId: threadId,
      tableName: roomSetting.tableName,
      playerReady: numPlayerReady(),
    };

    const createTable = functions.httpsCallable(CREATE_GAME_ROOM);
    return await createTable(roomData);
  } catch (error) {
    console.log(error);
  }
};

export const getState = () => ({
  type: GET_STATE,
});

export const getVideoGameToken = async (roomId) => {
  let accessToken = "";
  try {
    const videoGame = functions.httpsCallable("videoGame");
    const res = await videoGame({ roomId: roomId });
    accessToken = res.data.token;
  } catch (error) {
    accessToken = "";
  }
  return accessToken;
};

export const subscribeTableByRoomId = (roomId, callback) => {
  return db
    .collection("tables")
    .where("roomId", "==", roomId)
    .onSnapshot((snapshot) => {
      if (snapshot.size > 0 && callback) {
        callback(snapshot.docs[0].data());
      }
    });
};

export const inviteFriendJoinRoom = async (friend, player, room, table) => {
  try {
    const inviteJoinRoom = functions.httpsCallable("inviteJoinRoom");

    const data = {
      playerId: friend.friendId,
      roomId: room.roomId,
      roomInvitation: {
        roomId: room.roomId,
        ownerNickName: player.nickName,
        tableAvatar: table.tableAvatar,
        ownerAvatar: player.avatar,
        inviteMessage: "",
        description: table.description,
        ownerFullName: player.fullName,
        tableName: table.tableName,
        timestamp: Date.now(),
      },
    };
    await inviteJoinRoom(data);
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateTableSettings = async (data) => {
  try {
    const tableUpdateData = {
      tableId: data.tableId,
      roomId: data.roomId,
      newData: data,
    };
    const urlTableAvatar = await uploadImage(
      data.tableAvatar,
      data.tableId,
      false,
      data.tableAvatar
    );
    tableUpdateData.newData.tableAvatar = urlTableAvatar;
    const updateTable = functions.httpsCallable("updateTableSettings");
    await updateTable(tableUpdateData);
  } catch (error) {
    console.log(error);
  }
};
export class TablePaging {
  constructor(tableState = "open") {
    this.limit = 10;
    this.hasMoreDocument = true;
    this.allPageResult = [];
    this.allDocs = [];
    this.tableState = tableState;
    this.subscribe = [];
    this.lastDocument = null;
    this.privateIds = null;
    this.setTableKey = new Set();
  }

  addPrivateId(ids) {
    this.privateIds = ids;
  }
  getPageTablesQuery() {
    if (this.tableState === "staff") {
      let query = db.collection("tables").where("isStaffTable", "==", true);
      return query.orderBy("createDate", "desc");
    } else {
      let query = db
        .collection("tables")
        .where("joinTableRole", "==", this.tableState);
      if (this.privateIds) {
        query = query.where("owner", "in", this.privateIds);
      }
      return query.orderBy("createDate", "desc");
    }
  }
  getAllDocs() {
    return this.allDocs;
  }
  destroy() {
    if (this.subscribe)
      this.subscribe.forEach((unsubscribe) => {
        unsubscribe();
      });
  }
  async executeQuery(completeCallBack) {
    let currentExecuteIndex = this.allPageResult.length;
    // add limit
    let query = this.getPageTablesQuery();
    // add limit
    query = query.limit(this.limit);
    if (0 == currentExecuteIndex) {
      const firstSnap = await this.getPageTablesQuery().limit(1).get();
      if (!firstSnap.empty) {
        query = query.startAt(firstSnap.docs[0]);
      }
    }

    //  add start index
    if (null !== this.lastDocument) {
      query = query.startAfter(this.lastDocument);
    }

    if (!this.hasMoreDocument) {
      // complete load data
      if (completeCallBack) completeCallBack();
      return;
    }
    const subsSnap = query.onSnapshot((tablesSnapshot) => {
      // check page exists
      let pageExists = currentExecuteIndex < this.allPageResult.length;
      //  return when have mo data, and set no more data
      if (tablesSnapshot.empty) {
        // and set no more data
        if (!pageExists) {
          this.hasMoreDocument = false;
          const unsubscribe = this.subscribe[currentExecuteIndex - 1];
          if (unsubscribe) unsubscribe();
        } else {
          this.allPageResult[currentExecuteIndex] = [];
          // update allDocs
          const currentAllDoc = [];
          this.allPageResult.forEach((pageResult) =>
            currentAllDoc.push(...pageResult)
          );
          this.allDocs = currentAllDoc;
          // complete load data
          if (completeCallBack) completeCallBack();
        }
        return;
      }
      // save tmp tables data
      let tables = [];

      // update data of page exists
      if (pageExists) {
        let sortData = false;
        // old data
        tables = this.allPageResult[currentExecuteIndex];
        tablesSnapshot.docChanges().forEach((change) => {
          const changeDoc = change.doc.data();
          // Add more record if it not exists
          if (
            "added" === change.type &&
            !this.setTableKey.has(changeDoc.tableId)
          ) {
            this.setTableKey.add(changeDoc.tableId);

            tables.push(changeDoc);
            sortData = true;
          }
          // remove data was deleted and no add new data in after page
          if ("removed" === change.type)
            tables = tables.filter(
              (table) => table.tableId !== changeDoc.tableId
            );
          // update modified data
          if ("modified" === change.type) {
            const modifiedIndex = tables.findIndex(
              (table) => table.tableId === changeDoc.tableId
            );
            tables[modifiedIndex] = changeDoc;
          }
        });
        // sort by date if have more item added
        if (sortData) {
          tables = tables.sort((tableA, tableB) =>
            tableA.createDate <= tableB.createDate ? 1 : -1
          );
        }
        // update data in data list
        this.allPageResult[currentExecuteIndex] = tables;

        // unsubscribe page does not have data
        if (0 === tables.length) this.subscribe[currentExecuteIndex - 1];
      } else {
        //// add new data of new page
        tables = tablesSnapshot.docChanges().map((change) => change.doc.data());
        tables.forEach((table) => this.setTableKey.add(table.tableId));
        // add new data into in  the end of list;
        // this.allPageResult[currentExecuteIndex] = ;
        this.allPageResult.push(tables);
      }

      // update allDocs
      const currentAllDoc = [];
      this.allPageResult.forEach((pageResult) =>
        currentAllDoc.push(...pageResult)
      );
      this.allDocs = currentAllDoc;

      // Determine if there's more posts to request
      this.hasMoreDocument = tablesSnapshot.docs.length == this.limit;

      // save last document
      if (currentExecuteIndex === this.allPageResult.length - 1) {
        this.lastDocument = tablesSnapshot.docs[tablesSnapshot.docs.length - 1];
      }

      // complete load data
      if (completeCallBack) completeCallBack();
    });

    //  save  subscribe to unsubscribe when destroy
    this.subscribe.push(subsSnap);
  }
}

/**
 * player is GChaos play card
 * @param card object suit: string, value: number
 * @param tableId string
 * @param playerId string
 * @param gameId string
 * @param newSuit string
 * @param playerGChaos boolean
 * @returns
 */
export const playCard = async (data) => {
  let playCardRes = null;
  try {
    const requestData = {
      card: data.card,
      playerId: data.playerId,
      gameId: data.gameId,
      newSuit: data.playerGChaos ? CARD_SUIT_WHATEVER : data.newSuit,
      tableId: data.tableId,
      playerGChaos: data.playerGChaos,
    };
    const normalPlayCard = functions.httpsCallable(NORMAL_PLAY_CARD);
    playCardRes = await normalPlayCard(requestData);
    console.log(playCardRes);
  } catch (error) {
    console.log("playCard: ", error);
  }
  return playCardRes;
};

export const checkOwnerStatusAction = async (
  ownerId,
  tableId,
  roomId,
  myId
) => {
  const func = functions.httpsCallable(CHECK_OWNER_STATUS);
  func({ ownerId, tableId, roomId, myId });
};

export const getAllDocsFromTable = async () => {
  const querySnapshot = await db.collection("tables").get();
  return querySnapshot.docs.map((doc) => doc.data());
};

export const getPlayersInRoom = async (roomId) => {
  const roomData = await db.collection("rooms").doc(roomId).get();
  if (!roomData.exists) return "";
  return roomData.data();
};

export const getAllDocsFromPlayer = async () => {
  const playerData = await db.collection("players").get();
  return playerData.docs.map((doc) => doc.data());
};

/**
 * get table list
 * @returns
 */
export async function tableList() {
  try {
    const tables = await TableRepo.readAll();
    const promises = tables.docs.map(async (doc) => {
      const data = doc.data();
      const room = await RoomRepo.read(data[ROOM_ID]);
      const owner = data[OWNER];
      const roomPlayers = room[ROOM_PLAYERS].filter((p) => p && p !== null);
      const players = roomPlayers.map((p) => {
        return {
          playerId: p[PLAYER_ID],
          playerName: p[PLAYER_NAME],
          isOwner: owner === p[PLAYER_ID],
        };
      });
      return {
        isPlaying: data[IS_PLAYING],
        tableId: data[TABLE_ID],
        tableName: data[TABLE_NAME],
        isOpen: data[IS_OPEN] === "open" ? true : false,
        description: data[TABLE_DESCRIPTION],
        players: players,
      };
    });
    return await Promise.all(promises);
  } catch (error) {
    console.log(error);
  }
}
