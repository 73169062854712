import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { getSourceImages } from "../../../../utils/common";

const LoadCard = ({ isLoaded }) => {
  const [images, setImages] = useState([]);

  useEffect(() => {
    const imgs = getSourceImages();
    setImages(imgs);
  }, [isLoaded]);

  if (images.length === 0) return null;
  return images.map((src) => (
    <img
      key={src}
      alt={src}
      src={`${process.env.PUBLIC_URL}/${src}`}
      style={{ display: "none" }}
    />
  ));
};

LoadCard.propTypes = {
  isLoaded: PropTypes.bool,
};

export default React.memo(LoadCard);
