import { getFunctions } from "../../services/firebase";
import {
  GET_USER_LIST_PAGINATION,
  MINUS_PLAYTIME,
  ALLOW_START_GAME,
  // CHECK_PLAYER_REMAIN_PLAYS,
  START_GAME,
} from "../funcs";
const functions = getFunctions();
import TableRepo from "../repositories/table";
import PlayerRepo from "../repositories/player";
import PlaytimeRepo from "../repositories/playTime";

export const getUserListPagination = async (
  orderByField,
  keySearch = "",
  order = true,
  page = 0,
  limit = 5
) => {
  try {
    const getUserListPaginationFUNC = functions.httpsCallable(
      GET_USER_LIST_PAGINATION
    );
    return getUserListPaginationFUNC({
      page,
      limit,
      orderByField,
      order,
      keySearch,
    });
  } catch (e) {
    console.error(e);
  }
};

export const checkAllowStartGame = async (currentPlayersId) => {
  try {
    const result = await functions.httpsCallable(ALLOW_START_GAME)({
      currentPlayersId,
    });
    return result.data;
  } catch (error) {
    console.log(error);
  }
};

export const minusPlayerPlayTime = async (currentPlayersId) => {
  try {
    await functions.httpsCallable(MINUS_PLAYTIME)({
      currentPlayersId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const handleStartGame = async (tableId, roomId, tutorialMode) => {
  try {
    return await functions.httpsCallable(START_GAME)({
      tableId,
      roomId,
      tutorialMode,
    });
  } catch (error) {
    console.log(error);
  }
};

export const checkPlayerRemainPlays = async (playTimeId) => {
  try {
    if (playTimeId) {
      const playTimeData = await PlaytimeRepo.read(playTimeId);
      return playTimeData.playTimes > 0;
    }
  } catch (error) {
    console.log(error);
  }
};

export const getTrialStatus = async (playTimeId) => {
  try {
    if (playTimeId) {
      const playTimeData = await PlaytimeRepo.read(playTimeId);
      return playTimeData.statusFree === true;
    }
  } catch (error) {
    console.log(error);
  }
};

export const updateIsOffline = async (tableId) => {
  try {
    const tableData = await TableRepo.read(tableId);
    await TableRepo.update(tableId, { ...tableData, isOffline: false });
  } catch (error) {
    console.log(error);
  }
};

export const getOfflineNickname = async (offPlayerId) => {
  try {
    const playerData = await PlayerRepo.read(offPlayerId);
    return playerData.nickName;
  } catch (error) {
    console.log(error);
  }
};
