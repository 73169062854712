import { isEqual } from "lodash";
import { call, delay, put, select, takeEvery } from "redux-saga/effects";
import {
  getTurnOfGChaos,
  getTurnOfPlayerOrderCard,
} from "../store/actions/playerGameStateActions";
import { playerConfirm } from "../store/actions/roomActions";
import {
  CONFIRMS,
  DELAYS,
  GAME_STATUS,
  G_CHAOS,
  G_CHAOS_PLAY_CARD_DELAY_TIME,
  logs,
  STEP_NARRATION,
  TURN_STATUS,
} from "../store/constant";
import {
  ADD_STEP_NARRATION,
  CHECK_SHOW_DIALOG_MASTER_PICKING_CARD,
  G_CHAOS_AFTER_CAPTAIN,
  HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST,
  LOCAL_SAVE_PLAYER_GAME_STATE,
  NICKNAMES_PLAYERS_SELECT_ORDER_CARDS,
  REQUEST_RESET_OTHER_STATE_USE_MASTER_CARD,
  REQUEST_UPDATE_STATE_G_CHAOS_SELECT_MASTER_CARD,
  REQUEST_UPDATE_STATE_USE_MASTER_CARDS,
  REQUEST_UPDATE_TURN_ORDER_CARD,
  RESET_OTHER_STATE_USE_MASTER_CARD,
  SET_NEED_RELOAD,
  SET_NOTIFY_G_CHAOS_TURN,
  SET_PLAYER_GAME_STATE,
  SET_PLAYER_GAME_STATE_REQUEST,
  SHOW_G_CHAOS_TURN_SELECT_MASTER_CARD_REQUEST,
  UPDATE_CAN_SELECT_CARD,
  UPDATE_OTHER_PLAYERS_STATE,
  UPDATE_OTHER_STATE_G_CHAOS_SELECT_MASTER_CARD,
  UPDATE_OTHER_STATE_USE_MASTER_CARDS,
  UPDATE_PLAYER_GAME_STATE_FROM_LOCAL_SAVED,
  UPDATE_SHOW_MASTER,
  UPDATE_TURN_SELECT_ORDER_CARD,
  DOUBLE_CLICK_HAND_CARD,
} from "../store/types";
import {
  checkFirstPlayerSelectOrderCard,
  checkIsHideAllTurn,
  checkStepMasterCards,
  checkStepOrderCards,
  checkStepsShowMaster,
  getInstructorEndRound,
  mapAllPlayerInRoom,
} from "../utils/common";
import { writeLogRealtime } from "../utils/log";

const selectCurrentPlayerGameState = (state) => state.playerGameState;
const selectNickname = (state) => state.user.nickName;
const selectOtherPlayersState = (state) =>
  state.playerGameState.otherPlayersState;
const selectGChaosId = (state) => state.playerGameState.gChaosId;

const selectNewSuitOtherPlayersState = (state) =>
  state.playerGameState.newSuitOtherPlayersState;
const selectCardPlayer1 = (state) => state.playerGameState.cardPlayer1;
const selectCardPlayer2 = (state) => state.playerGameState.cardPlayer2;
const selectCardPlayer3 = (state) => state.playerGameState.cardPlayer3;

const selectPlayerRole = (state) => state.playerGameState.role;
const selectMyIndex = (state) => state.playerGameState.myIndex;

const selectTableId = (state) => state.room.table.tableId;
const selectRoomIdFromTable = (state) => state.room.table.roomId;
const selectGChaosComputer = (state) => state.room.table.isGChaoComputer;
const selectRoom = (state) => state.room;
const selectRoomId = (state) => state.room.roomId;
const selectPlayerIds = (state) => state.room.table.currentPlayersId;

const selectPlayerId = (state) => state.playerGameState.playerId;
const selectPlayers = (state) => state.room.joinedPlayers;
const selectNextConfirmType = (state) => state.playerGameState.nextConfirmType;
const selectNextActionPlayer = (state) =>
  state.playerGameState.nextActionPlayer;

const selectCurrentPlayer = (state) => state.playerGameState;
const selectGameId = (state) => state.playerGameState.gameId;
const selectNumberOfGame = (state) => state.room.table.numberOfGame;
const selectNicknamesOrderCards = (state) => state.playerGameState.nicknames;
const selectShowMaster = (state) => state.playerGameState.isShowMaster;
const selectMaster = (state) => state.playerGameState.master;
const selectStepId = (state) => state.playerGameState.stepId;
const selectCanSelectCard = (state) => state.playerGameState.canSelectCard;
const selectTimer = (state) => state.playerGameState.startTurnTime;

const selectIsOnNarrationSound = (state) => state.user.setting.isNarrationSound;

function* selectGChaosIndex() {
  const cardPlayer1 = yield select(selectCardPlayer1);
  const cardPlayer2 = yield select(selectCardPlayer2);
  const cardPlayer3 = yield select(selectCardPlayer3);
  const gChaosCardPlayer = [cardPlayer1, cardPlayer2, cardPlayer3].filter(
    (e) => e.isGChaos === true
  );
  return gChaosCardPlayer[0]?.myIndex;
}

function* getConfirmData(type, actionPlayer) {
  const tableId = yield select(selectTableId);
  const roomId = yield select(selectRoomId);
  const roomIdFromTable = yield select(selectRoomIdFromTable);
  const playerIds = yield select(selectPlayerIds);
  const gameId = yield select(selectGameId);
  const numberOfGame = yield select(selectNumberOfGame);
  const playerId = yield select(selectPlayerId);
  const nickName = yield select(selectNickname);
  const isGChaosComputer = yield select(selectGChaosComputer);

  return {
    tableId,
    roomId: roomId ? roomId : roomIdFromTable,
    playerIds,
    gameId,
    numberOfGame,
    type,
    actionPlayer,
    playerId,
    nickName,
    isGChaosComputer,
  };
}

/**
 * Update captain play card first then after [G_CHAOS_PLAY_CARD_DELAY_TIME] update G-Chaos card (add it to grand cross)
 * @param {any} action
 */
function* handleGchaosAfterCaptainSaga(action) {
  const newState = action.payload;
  const newGrandCross = [...newState.grandCross];
  let orderCards = [];
  const useMasterCardSteps = checkStepMasterCards(newState.stepId);
  const useOrderCardSteps = checkStepOrderCards(newState.stepId);
  if (useOrderCardSteps) {
    orderCards = [...newState.orderCards];
  }
  const isFirstPlayerSelectOrderCard =
    checkFirstPlayerSelectOrderCard(orderCards);
  newGrandCross[3] = null;

  const otherPlayersState = [...newState.otherPlayersState];
  const gChaosIndex = yield selectGChaosIndex();
  const allTurnFasleOtherPlayersState = otherPlayersState.map((e, idx) => {
    if (e) {
      e.isYourTurn = idx === gChaosIndex;
    }
    return e;
  });
  const nextPlayerIndex = (gChaosIndex + 1) % 4;
  const myRole = yield select(selectPlayerRole);
  yield put({
    type: SET_PLAYER_GAME_STATE_REQUEST,
    payload: {
      ...newState,
      grandCross: newGrandCross,
      isMyTurn: myRole === G_CHAOS,
      otherPlayersState: allTurnFasleOtherPlayersState,
    },
  });

  // G-Chaos play card
  const gChaosPlayer = otherPlayersState[gChaosIndex];
  otherPlayersState[gChaosIndex] = { ...gChaosPlayer, isYourTurn: false };
  const myIndex = yield select(selectMyIndex);
  const saveNewState = {
    ...newState,
    otherPlayersState,
    isMyTurn: false,
  };

  yield put({
    type: LOCAL_SAVE_PLAYER_GAME_STATE,
    payload: { state: saveNewState, isGChoasAfterCaptain: true },
  });

  // Delay for "on stage" message
  yield delay(G_CHAOS_PLAY_CARD_DELAY_TIME);
  if (
    !useMasterCardSteps ||
    (!useOrderCardSteps && isFirstPlayerSelectOrderCard)
  ) {
    yield put({
      type: UPDATE_PLAYER_GAME_STATE_FROM_LOCAL_SAVED,
      payload: { isGChaosAfterCaptain: false },
    });

    // Delay next player turn
    yield delay(DELAYS.CHANGE_TURN_NORMAL);
    const delayOtherPlayer = [...otherPlayersState];
    const nextPlayer = delayOtherPlayer[nextPlayerIndex];
    delayOtherPlayer[nextPlayerIndex] = { ...nextPlayer, isYourTurn: true };
    const saveNewStateDelay = {
      ...saveNewState,
      otherPlayersState: delayOtherPlayer,
      isMyTurn: nextPlayerIndex === myIndex,
    };
    yield put({ type: SET_PLAYER_GAME_STATE, payload: saveNewStateDelay });
  }
}
/**
 * Keep the turn for G-Chaos
 * @param {*} action
 */
function* handleSetPlayerGameStateRequest(action) {
  const newState = { ...action.payload };
  const nextConfirmType = newState.nextConfirmType;
  const nextActionPlayer = newState.nextActionPlayer;
  const playerId = newState.playerId;
  const currentCanSelectCard = yield select(selectCanSelectCard);
  const isOnNarrationSound = yield select(selectIsOnNarrationSound);
  const isGChaosComputer = yield select(selectGChaosComputer);
  yield showDialogPickingCard(newState);
  yield sagaLog(newState);
  if (currentCanSelectCard !== newState.canSelectCard) {
    yield put({
      type: UPDATE_CAN_SELECT_CARD,
      payload: newState.canSelectCard,
    });
  }
  yield put({ type: SET_NOTIFY_G_CHAOS_TURN, payload: false });
  yield delay(100);
  yield countDown(newState);
  yield handleShowMaster();
  if (nextConfirmType === CONFIRMS.CONFIRM_NEW_TURN) {
    yield put({ type: SET_NEED_RELOAD, payload: false }); // game continue -> not need to show reload dialog
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    yield put({ type: SET_PLAYER_GAME_STATE, payload: newState }); // show turn
    yield put({
      type: DOUBLE_CLICK_HAND_CARD,
      payload: null,
    });
    if (nextActionPlayer === newState.gChaosId) {
      yield delay(isOnNarrationSound ? 2000 : 1000); // delay G-Chaos play card
    }
    const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
    yield call(playerConfirm, confirmData);
    const gChaosId = yield select(selectGChaosId);
    if (
      nextActionPlayer === gChaosId &&
      playerId === gChaosId &&
      !isGChaosComputer
    ) {
      yield put({ type: SET_NOTIFY_G_CHAOS_TURN, payload: true });
    }
    if (nextActionPlayer === gChaosId && isGChaosComputer) {
      yield put({ type: SET_NEED_RELOAD, payload: true }); // start tracking G-Chaos playing
    }
  } else if (nextConfirmType === CONFIRMS.CONFIRM_NEW_TURN_DONE) {
    const playCardLocal = yield select(
      (state) => state.playerGameState.playCardLocal
    );
    // Update after player play card in local
    if (playCardLocal !== null) {
      const grandCross = yield select(
        (state) => state.playerGameState.newSuitGrandCross
      );
      const myState = yield select(
        (state) => state.playerGameState.newSuitMyState
      );
      yield put({
        type: SET_PLAYER_GAME_STATE,
        payload: { ...newState, grandCross, myState },
      });
    } // Update before player play card in local
    else {
      yield put({ type: SET_PLAYER_GAME_STATE, payload: newState });
    }
  } else if (nextConfirmType === CONFIRMS.CONFIRM_SHOW_SUIT_OF_ROUND) {
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    yield put({ type: SET_PLAYER_GAME_STATE, payload: newState }); // show card on grand cross
    // yield delay(isOnNarrationSound ? 1200 : 600);
    yield put({
      // show on stage
      type: ADD_STEP_NARRATION,
      payload: { steps: [newState.stepId] },
    });
    yield delay(isOnNarrationSound ? 6000 : 3000); // delay narration on stage
    const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
    yield call(playerConfirm, confirmData);
  } else if (CONFIRMS.CONFIRM_SHOW_CARD === nextConfirmType) {
    yield put({ type: SET_NEED_RELOAD, payload: false });
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    yield put({ type: SET_PLAYER_GAME_STATE, payload: newState });
    const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
    yield call(playerConfirm, confirmData);
    const gChaosId = yield select(selectGChaosId);
    if (nextActionPlayer === gChaosId && isGChaosComputer) {
      yield put({ type: SET_NEED_RELOAD, payload: true });
    }
  } else if (nextConfirmType === CONFIRMS.CONFIRM_PREPARE_TO_CALL) {
    yield prepareToCall(newState);
  } else if (nextConfirmType === CONFIRMS.CONFIRM_FINAL_CALL) {
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
    yield call(playerConfirm, confirmData);
  } else if (nextConfirmType === CONFIRMS.CONFIRM_END_ORDER_CARD) {
    yield updateStateEndOrderCards(nextConfirmType, nextActionPlayer, newState);
  } else if (
    [
      CONFIRMS.CONFIRM_CAN_SELECT_ORDER_CARD,
      CONFIRMS.CONFIRM_CAN_SELECT_ORDER_CARD_1,
      CONFIRMS.CONFIRM_CAN_SELECT_ORDER_CARD_2,
      CONFIRMS.CONFIRM_G_CHAOS_SELECT_ORDER_CARD,
    ].includes(nextConfirmType)
  ) {
    yield updateNicknamesAllPlayerSelectOrderCard(newState);
    yield put({ type: SET_PLAYER_GAME_STATE, payload: newState });
  } else if (nextConfirmType === CONFIRMS.CONFIRM_OPEN_ORDER_CARD) {
    yield updateStateOpenOrderCard(newState);
  } else if (nextConfirmType === CONFIRMS.CONFIRM_END_GAME) {
    yield resetTurn(newState);
  } else if (nextConfirmType === CONFIRMS.CONFIRM_SHOW_CARD_ROUND_13) {
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    yield resetTurn(newState);
  } else if (nextConfirmType === CONFIRMS.CONFIRM_END_STAGE_13) {
    yield endStage13(newState, nextActionPlayer);
  } else if (CONFIRMS.CONFIRM_SHOW_CAPTAIN === nextConfirmType) {
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    yield delay(isOnNarrationSound ? 6000 : 3000);
    yield put({ type: SET_PLAYER_GAME_STATE, payload: newState });
    const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
    yield call(playerConfirm, confirmData);
  } else if (nextConfirmType === CONFIRMS.CONFIRM_SHOW_LAST_CARD) {
    yield put({ type: SET_NEED_RELOAD, payload: false });
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    yield resetTurn(newState);
    yield delay(isOnNarrationSound ? 600 : 300);
    const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
    yield call(playerConfirm, confirmData);
    const gChaosId = yield select(selectGChaosId);
    if (nextActionPlayer === gChaosId && isGChaosComputer) {
      yield put({ type: SET_NEED_RELOAD, payload: true });
    }
  } else if (nextConfirmType === CONFIRMS.CONFIRM_WAIT_MASTER_REQUEST_PLEASE) {
    const currentNextConfirmType = yield select(selectNextConfirmType);
    if (nextConfirmType === currentNextConfirmType) return;
    if (currentNextConfirmType === CONFIRMS.HAS_TRICK_STAR) return;
    yield delay(100);
    yield resetTurn(newState);
  } else {
    let isHideAllTurn = checkIsHideAllTurn(newState);
    if (isHideAllTurn) {
      yield resetTurn(newState);
    } else if (nextConfirmType === CONFIRMS.HAS_TRICK_STAR) {
      yield resetTurn(newState);
    } else {
      yield put({
        type: SET_PLAYER_GAME_STATE,
        payload: { ...newState, canSelectMC: false },
      });
    }
  }
}

function* countDown(newState) {
  const currentTimer = yield select(selectTimer);
  const newTimer = newState.starTurnTime;
  if (isEqual(currentTimer, newTimer)) return;
  yield put({
    type: SET_PLAYER_GAME_STATE,
    payload: { starTurnTime: newTimer },
  });
}

function* prepareToCall(newState) {
  const currentStepId = yield select(selectStepId);
  const newStepId = newState.stepId;
  if (
    currentStepId === STEP_NARRATION.SG_STEP7 &&
    newStepId === STEP_NARRATION.SG_STEP5
  ) {
    yield put({
      type: SET_PLAYER_GAME_STATE,
      payload: { ...newState, stepId: currentStepId },
    });
    return;
  }
  yield put({
    type: SET_PLAYER_GAME_STATE,
    payload: newState,
  });
}

function* endStage13(newState, nextActionPlayer) {
  const otherPlayersState = [...newState.otherPlayersState].map((item) => {
    return item !== null ? { ...item, isYourTurn: false } : null;
  });
  if (newState.gameStatus !== GAME_STATUS.PLAYING) {
    yield delay(1000);
    yield put({
      type: SET_PLAYER_GAME_STATE,
      payload: { ...newState, otherPlayersState },
    });
  } else {
    const currentPlayersState = yield select(selectCurrentPlayer);
    const roomState = yield select(selectRoom);

    const mapCurrentPlayers = mapAllPlayerInRoom(
      currentPlayersState,
      roomState
    );
    const mapNewPlayers = mapAllPlayerInRoom(newState, roomState);
    const stepId = getInstructorEndRound(
      mapCurrentPlayers,
      mapNewPlayers,
      nextActionPlayer
    );
    // yield put({ type: STOP_CURRENT_NARRATION });
    // yield put({ type: CLEAR_STEP_NARRATION });
    yield put({
      type: ADD_STEP_NARRATION,
      payload: { steps: [stepId] },
    });
    yield delay(4000);
    yield put({
      type: SET_PLAYER_GAME_STATE,
      payload: { ...newState, otherPlayersState },
    });
    yield put({
      type: ADD_STEP_NARRATION,
      payload: { steps: [newState.stepId] },
    });
  }
}

function* updateNicknamesAllPlayerSelectOrderCard(newState) {
  const nicknamesOrderCards = yield select(selectNicknamesOrderCards);
  const players = yield select(selectPlayers);
  const orderCards = newState.orderCards;

  if (orderCards && orderCards.length === 0) return;
  if (players && players.length === 0) return;
  if (nicknamesOrderCards !== "") return;
  const masterIndexes = orderCards[0].playerIndexes;
  const nicknames = masterIndexes.map((item) => players[item].nickName);
  const uniqueSet = new Set(nicknames);
  const _nicknames = [...uniqueSet].join(", ");
  yield put({
    type: NICKNAMES_PLAYERS_SELECT_ORDER_CARDS,
    payload: { nicknames: _nicknames },
  });
}

function* resetTurn(newState) {
  const otherPlayersState = [...newState.otherPlayersState].map((item) => {
    return item !== null ? { ...item, isYourTurn: false } : null;
  });
  yield put({
    type: SET_PLAYER_GAME_STATE,
    payload: {
      ...newState,
      otherPlayersState,
      isMyTurn: false,
      canSelectMC: false,
    },
  });
}

function* updateStateOpenOrderCard(newState) {
  const _newState = { ...newState, isMyTurn: false };
  yield put({ type: SET_PLAYER_GAME_STATE, payload: _newState });
}

function* updateStateEndOrderCards(
  nextConfirmType,
  nextActionPlayer,
  newState
) {
  yield put({ type: SET_PLAYER_GAME_STATE, payload: newState });
  const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
  yield call(playerConfirm, confirmData);
}

function* showGChaosTurnSelectMasterCardRequest() {
  const gChaosIndex = yield selectGChaosIndex();
  const otherPlayersState = yield select(selectOtherPlayersState);
  const gChaosState = otherPlayersState[gChaosIndex];
  otherPlayersState[gChaosIndex] = { ...gChaosState, isYourTurn: true };
  yield put({ type: UPDATE_OTHER_PLAYERS_STATE, payload: otherPlayersState });
}

function* handleHideAllTurnOtherPlayersState() {
  const otherPlayersState = yield select(selectOtherPlayersState);
  const currentPlayerGameState = yield select(selectCurrentPlayerGameState);
  const newState = otherPlayersState.map((e) => ({ ...e, isYourTurn: false }));
  yield put({
    type: SET_PLAYER_GAME_STATE,
    payload: {
      ...currentPlayerGameState,
      isMyTurn: false,
      otherPlayersState: newState,
    },
  });
}

/**
 * USE MASTER CARD
 * todo: find turn of player
 * todo: update turn in other player state
 */
function* handleUpdateStateUseMasterCards(action) {
  if (action.payload === true) {
    const currentPlayerGameState = yield select(selectCurrentPlayerGameState);
    const otherPlayersState = yield select(selectNewSuitOtherPlayersState);
    const myIndex = currentPlayerGameState.myIndex;

    // TODO: get isMyTurn all players
    const turns = [
      {
        myIndex: currentPlayerGameState.myIndex,
        isMyTurn: currentPlayerGameState.isMyTurn,
      },
      currentPlayerGameState.cardPlayer1,
      currentPlayerGameState.cardPlayer2,
      currentPlayerGameState.cardPlayer3,
    ];

    // TODO: find index of player has isMyTurn = true
    let indexOfTurnPlayer = -1;
    for (const turn of turns) {
      if (turn.isMyTurn) {
        indexOfTurnPlayer = turn.myIndex;
      }
    }

    // TODO: update other player state
    let _otherPlayerState = [...otherPlayersState];
    _otherPlayerState = otherPlayersState.map((item, index) => {
      if (item && item !== null) {
        return {
          ...item,
          isMyTurn: _otherPlayerState[index]
            ? _otherPlayerState[index].isYourTurn
            : false,
          isYourTurn: false,
        };
      } else {
        return null;
      }
    });

    if (indexOfTurnPlayer !== -1) {
      _otherPlayerState = otherPlayersState.map((item, index) => {
        return {
          ...item,
          isYourTurn: false,
          isMyTurn: indexOfTurnPlayer === index,
        };
      });
      _otherPlayerState[indexOfTurnPlayer]["isMyTurn"] = true;
      _otherPlayerState[myIndex] = null;
    }

    // TODO: update store
    yield put({
      type: UPDATE_OTHER_STATE_USE_MASTER_CARDS,
      payload: {
        otherPlayersState: _otherPlayerState,
      },
    });
  } else {
    const playState = yield select(selectCurrentPlayerGameState);
    const myIndex = playState.myIndex;
    const otherPlayersState = playState.otherPlayersState;
    let _otherPlayerState = [...otherPlayersState];
    const isAllFalse =
      _otherPlayerState.filter((e) => e && e.isMyTurn === false).length === 3;
    let updateIsMyTurn = false;
    if (isAllFalse) {
      _otherPlayerState[myIndex] = null;
      updateIsMyTurn = true;
    }

    if (updateIsMyTurn) {
      yield put({
        type: UPDATE_OTHER_STATE_USE_MASTER_CARDS,
        payload: {
          isMyTurn: updateIsMyTurn,
          otherPlayersState: _otherPlayerState,
        },
      });
    } else {
      _otherPlayerState.map((e) => {
        if (e && e.isMyTurn) {
          e.isYourTurn = true;
        }
      });
      yield put({
        type: UPDATE_OTHER_STATE_USE_MASTER_CARDS,
        payload: {
          isMyTurn: updateIsMyTurn,
          otherPlayersState: _otherPlayerState,
        },
      });
    }
  }
  // }
}

/**
 * USE MASTER CARD
 * todo: update turn g-chaos
 */
function* handleUpdateStateGChaosSelectMasterCard() {
  const currentPlayerGameState = yield select(selectCurrentPlayerGameState);
  const myIndex = currentPlayerGameState.myIndex;
  const otherPlayersState = yield select(selectNewSuitOtherPlayersState);

  // TODO: update turn of g-chaos
  const _otherPlayerState = otherPlayersState.map((item) => ({
    ...item,
    isYourTurn: false,
  }));
  _otherPlayerState[3].isYourTurn = true;
  _otherPlayerState[myIndex] = null;

  // TODO: update store
  yield put({
    type: UPDATE_OTHER_STATE_G_CHAOS_SELECT_MASTER_CARD,
    payload: {
      otherPlayersState: _otherPlayerState,
    },
  });
}

/**
 * USE MASTER CARD
 * todo: reset turn all player with false
 */
function* handleResetOtherState() {
  const otherPlayersState = yield select(selectNewSuitOtherPlayersState);
  // TODO: reset all turn player with false
  const _otherPlayerState = otherPlayersState.map((item) => ({
    ...item,
    isYourTurn: false,
  }));

  // TODO: update store
  yield put({
    type: RESET_OTHER_STATE_USE_MASTER_CARD,
    payload: {
      otherPlayersState: _otherPlayerState,
    },
  });
}

function* handleUpdateTurnOrderCard() {
  const nextConfirmType = yield select(selectNextConfirmType);
  const nextActionPlayer = yield select(selectNextActionPlayer);
  const otherPlayersState = yield select(selectNewSuitOtherPlayersState);
  const myIndex = yield select(selectMyIndex);

  if (!nextConfirmType || !nextActionPlayer) return;

  if (nextConfirmType === CONFIRMS.CONFIRM_G_CHAOS_SELECT_ORDER_CARD) {
    const _otherPlayerState = getTurnOfGChaos(otherPlayersState, myIndex);

    // TODO: update store
    yield put({
      type: UPDATE_TURN_SELECT_ORDER_CARD,
      payload: {
        isMyTurn: false,
        otherPlayersState: _otherPlayerState,
      },
    });
  }
  if (
    [
      CONFIRMS.CONFIRM_CAN_SELECT_ORDER_CARD,
      CONFIRMS.CONFIRM_CAN_SELECT_ORDER_CARD_1,
      CONFIRMS.CONFIRM_CAN_SELECT_ORDER_CARD_2,
    ].includes(nextConfirmType)
  ) {
    const playerIds = yield select(selectPlayerIds);
    const { otherState, newIndexTurn } = getTurnOfPlayerOrderCard(
      nextActionPlayer,
      myIndex,
      playerIds,
      otherPlayersState
    );
    // TODO: update store
    yield put({
      type: UPDATE_TURN_SELECT_ORDER_CARD,
      payload: {
        isMyTurn: newIndexTurn === myIndex,
        otherPlayersState: otherState,
      },
    });
  }
  const confirmData = yield getConfirmData(nextConfirmType, nextActionPlayer);
  yield call(playerConfirm, confirmData);
}

function* handleShowMaster() {
  const currentIsShowMaster = yield select(selectShowMaster);
  if (currentIsShowMaster) return;

  const master = yield select(selectMaster);
  if (!master) return;

  const stepId = yield select(selectStepId);
  const isShowMaster = checkStepsShowMaster(stepId);
  if (isShowMaster) {
    yield put({ type: UPDATE_SHOW_MASTER, payload: false });
    return;
  }
  yield put({ type: UPDATE_SHOW_MASTER, payload: true });
}

function* showDialogPickingCard(newState) {
  const playerIds = yield select(selectPlayerIds);
  if (playerIds?.length < 4) return;

  const isShowDialogMasterConfirmRequestPlease =
    newState.turnStatus === TURN_STATUS.WAIT_RF &&
    newState.master === newState.playerId &&
    playerIds.indexOf(newState.master) !== 3;

  const isShowDialogWaitMasterConfirmRequestPlease =
    newState.turnStatus === TURN_STATUS.WAIT_RF &&
    newState.gameStatus === GAME_STATUS.PLAYING &&
    newState.master !== newState.playerId;

  yield put({
    type: CHECK_SHOW_DIALOG_MASTER_PICKING_CARD,
    payload: {
      isShowDialogMasterConfirmRequestPlease,
      isShowDialogWaitMasterConfirmRequestPlease,
    },
  });
}

function* sagaLog(newState) {
  const players = yield select(selectPlayers);
  const playerNickName = yield select(selectNickname);
  const playerFilter = players?.filter(
    (player) => player?.playerId === newState.nextActionPlayer
  );
  const actionPlayerNickName =
    playerFilter?.length === 0 ? "" : playerFilter[0]?.nickName;
  const eventData = {
    [logs.CONFIRM_TYPE]: newState.nextConfirmType ?? "",
    [logs.ACTION_PLAYER]: newState.nextActionPlayer ?? "",
    [logs.ACTION_PLAYER_NICK_NAME]: actionPlayerNickName,
    [logs.GRAND_CROSS]: newState.grandCross,
    [logs.ORDER_CARDS]: newState.orderCards,
    [logs.GAME_ROUND]: newState.currentRound,
    [logs.CAN_PLAY_CARD]: newState.canPlayCard,
    [logs.CAN_SELECT_CARD]: newState.canSelectCard,
  };
  writeLogRealtime(
    playerNickName,
    newState.gameId,
    newState.nextConfirmType,
    eventData,
    newState.gameStartTime
  );
}

function* playerGameStateSagas() {
  yield takeEvery(
    HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST,
    handleHideAllTurnOtherPlayersState
  );
  yield takeEvery(
    SHOW_G_CHAOS_TURN_SELECT_MASTER_CARD_REQUEST,
    showGChaosTurnSelectMasterCardRequest
  );
  yield takeEvery(
    SET_PLAYER_GAME_STATE_REQUEST,
    handleSetPlayerGameStateRequest
  );
  yield takeEvery(G_CHAOS_AFTER_CAPTAIN, handleGchaosAfterCaptainSaga);
  /** UPDATE OTHER STATE USE MASTER CARD */
  yield takeEvery(
    REQUEST_UPDATE_STATE_USE_MASTER_CARDS,
    handleUpdateStateUseMasterCards
  );
  yield takeEvery(
    REQUEST_UPDATE_STATE_G_CHAOS_SELECT_MASTER_CARD,
    handleUpdateStateGChaosSelectMasterCard
  );
  yield takeEvery(
    REQUEST_RESET_OTHER_STATE_USE_MASTER_CARD,
    handleResetOtherState
  );

  /** UPDATE TURN USE ORDER CARDS */
  yield takeEvery(REQUEST_UPDATE_TURN_ORDER_CARD, handleUpdateTurnOrderCard);
}

export default playerGameStateSagas;
