import { Dialog } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";

const Text = styled.p`
  font-weight: 700;
  line-height: 1.5;
  text-align: center;
`;

const BoxDialog = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  padding: 0 2.5em;
`;

const ReloadNetworkNG = ({ isOpen, countdown }) => {
  const { t } = useTranslation();

  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        style: {
          borderRadius: "35px",
          backgroundColor: "#f7f7f7",
        },
      }}
      PaperComponent={PaperComponent}
      maxWidth="sm"
    >
      <div className="dialog-style-1" id="draggable-dialog-title">
        <BoxDialog>
          <Text>{t("reload.reloadNetworkNG", { countdown: countdown })}</Text>
        </BoxDialog>
      </div>
    </Dialog>
  );
};

ReloadNetworkNG.propTypes = {
  isOpen: PropTypes.bool,
  countdown: PropTypes.number,
};
export default React.memo(ReloadNetworkNG);
