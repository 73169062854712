import { makeStyles } from "@material-ui/styles";
import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { SHIP_BG_COLOR } from "../../../../constants";
import { TUTORIAL_MODE } from "../../../../store/constant";
import { cardGapWidthCalculator } from "../../../../utils/calculations";
import HCard from "../Card/HCard";
import { DotStyle } from "../StarCards/LeftStarCards/LSCards/styles";
import {
  GChaosWelcome,
  GuestWelcome,
  Master,
} from "../StarCards/LeftStarCards/styles";

const VCardShipContainer = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: ${(props) => (props.side === "left" ? "row-reverse" : "row")};
  justify-content: flex-start;
  align-items: center;
  background: ${SHIP_BG_COLOR};
  transform-origin: center;
  transform: rotate(0deg);
`;

const CardContainer = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
  transform: rotate(${(props) => (props.side === "left" ? 0 : 180)}deg);
`;

const useStyles = makeStyles(() => ({
  master: {
    width: (props) => props.masterWidth,
  },
  welcome: {
    width: (props) => props.welcomeWidth,
  },
}));

const VCardShip = React.memo(function VCardShipComponent({
  side,
  cards,
  numberOfCalls,
  profile,
}) {
  const shipWidth = useSelector((state) => state.size.vShipWidth);
  const shipHeight = useSelector((state) => state.size.vShipHeight);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const cardWidth = useSelector((state) => state.size.cardWidth);
  // const cardHeight = useSelector((state) => state.size.cardHeight);
  const master = useSelector((state) => state.playerGameState.master);
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );
  const isShowMaster = useSelector(
    (state) => state.playerGameState.isShowMaster
  );
  const masterWidth = useSelector((state) => state.size.masterWidth);
  const welcomeBandWidth = useSelector((state) => state.size.welcomeBandWidth);
  const eveIndex = numberOfCalls - 2;
  const [gapWidth, margin] = useMemo(
    () =>
      cardGapWidthCalculator(
        shipSpace,
        (cards && [...cards]?.length) || 0,
        shipHeight,
        cardWidth
      ),
    [cards, shipHeight, cardWidth, shipSpace]
  );
  // console.log([gapWidth, margin], "Vertical");
  const isEve1Card = useMemo(
    () => numberOfCalls == 2 && cards.length >= 1,
    [numberOfCalls, cards]
  );
  const isEve1CardAdd = useMemo(
    () => (isEve1Card ? shipSpace * 2 : 0),
    [isEve1Card, shipSpace]
  );
  const isBreakThrough1Call = useMemo(
    () => numberOfCalls == 1 && cards.length == 1,
    [numberOfCalls, cards]
  );

  const getPositionCard = (cardIndex, over_get_num_gap) => {
    const positionCard = Math.round(
      cardIndex * gapWidth + (cardIndex == 0 ? isEve1CardAdd : 0)
    );
    if (tutorialMode === TUTORIAL_MODE.NO_CALL) {
      return positionCard;
    }
    return cardIndex >= numberOfCalls
      ? Math.round(
          shipHeight -
            (over_get_num_gap - (cardIndex - numberOfCalls)) * gapWidth -
            cardWidth -
            margin * 4
        )
      : positionCard;
  };

  const getMaster = useMemo(() => {
    if (master === profile.playerId && isShowMaster) {
      return <Master width={masterWidth} />;
    }
    if (profile.index === 3 && !isShowMaster) {
      return <Master width={masterWidth} />;
    }
    return <div style={{ height: "100%", width: masterWidth }} />;
  }, [master, profile.playerId, profile.index, isShowMaster, masterWidth]);

  const classes = useStyles({ masterWidth, welcomeWidth: welcomeBandWidth });
  return (
    <VCardShipContainer
      width={shipWidth}
      height={shipHeight}
      side={side}
      className={classes.welcome}
    >
      {getMaster}
      {profile.index === 3 && (
        <GChaosWelcome width={welcomeBandWidth} side={side}>
          <img
            src="/assets/v_g_chaos_welcome.png"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
            }}
          />
        </GChaosWelcome>
      )}
      {profile.index !== 3 && (
        <GuestWelcome
          width={welcomeBandWidth}
          side={side}
          className={classes.welcome}
        >
          <img
            src="/assets/v_guest_welcome.png"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
            }}
          />
        </GuestWelcome>
      )}
      <CardContainer side={side}>
        <DotStyle src="/assets/v_dot.png" />
        {cards?.map((card, cardIndex) => {
          const over_get_num_gap =
            cards.length - numberOfCalls > 0
              ? cards.length - numberOfCalls - 1
              : 0;
          return (
            <HCard
              src={`/cards/${card.suit}${card.value}.jpg`}
              key={`card-left:${cardIndex}`}
              gap={getPositionCard(cardIndex, over_get_num_gap)}
              zIndex={cardIndex}
              isEve={cardIndex === eveIndex}
              isBreakThrough1Call={isBreakThrough1Call}
              isEve1Card={isEve1Card}
            />
          );
        })}
      </CardContainer>
    </VCardShipContainer>
  );
});

VCardShip.propTypes = {
  cards: PropTypes.any,
  numberOfCalls: PropTypes.number,
  side: PropTypes.any,
  profile: PropTypes.any,
};

export default VCardShip;
