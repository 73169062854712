import { Box } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Button,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import moment from "moment";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import ButtonCustom from "../../components/Button";
import {
  getInvoiceListPaginationByPlayer,
  getUrlReceipt,
} from "../../store/actions/invoiceAction";
import { numberWithCommas } from "../../utils/common";
import HeaderCells from "./components/HeaderCells";
import useStyles from "./styles";
import axios from "axios";
import { DOMAIN_GET_RECEIPT, DOMAIN_INVOICE } from "../../constants";

const InvoiceSetting = ({ goBack }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const pageSize = useMemo(() => 5, []);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("completeDate");
  const [page, setPage] = useState(0); // eslint-disable-next-line react-hooks/exhaustive-deps
  const [rows, setRows] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [totalPage, setTotalPage] = useState(0);
  const playerId = useSelector((state) => state.user.playerId);

  const headCells = useMemo(
    () => [
      {
        id: "paymentGatewayInvoiceNumber",
        label: `${t("paymentSettings.invoiceNumber")}`,
      },
      { id: "completeDate", label: `${t("paymentSettings.billingDate")}` },
      { id: "amount", label: `${t("paymentSettings.price")}` },
      { id: "status", label: `${t("paymentSettings.paymentStatus")}` },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStorage.getItem("i18nextLng")]
  );

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage - 1);
  };

  // eslint-disable-next-line no-unused-vars
  const handleViewReceipt = async (invoicePdfLink) => {
    const url = invoicePdfLink.replace(DOMAIN_INVOICE, DOMAIN_GET_RECEIPT);
    const _url = url.replace("?s=ap", "").trim();
    const result = await getUrlReceipt(_url);

    if (result?.data?.file_url) {
      window.open(`${result?.data?.file_url}`, "_blank");
    }
  };
  const getInvoices = async () => {
    setIsLoading(true);

    const isOrder = order === "asc" ? true : false;
    const resufl = await getInvoiceListPaginationByPlayer(
      playerId,
      orderBy,
      isOrder,
      page,
      pageSize
    );

    const { elements, metaData } = resufl.data;
    if (elements && elements.length > 0) {
      setRows(elements);
      setTotalPage(metaData.totalPage);
    } else {
      setRows([]);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getInvoices();
    getReceipt();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, orderBy, order]);

  const getReceipt = async () => {
    const res = await axios.get(
      "https://invoicedata.stripe.com/invoice_receipt_file_url/acct_1L7wwDJPffsU1pMx/test_YWNjdF8xTDd3d0RKUGZmc1UxcE14LF9NU1pFakJ2a3paNmhaUnFQcUs4SEFuSXlFcEx6QUhKLDU0MTEyMTE40200BJ7g4ot9",
      {
        headers: {
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    console.log("res :", res);
  };

  return (
    <div className={classes.container} style={{ color: "#541513" }}>
      <Box className={classes.invoice}>
        <Backdrop
          style={{ zIndex: 9999, color: "#fff", position: "absolute" }}
          open={isLoading}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box>
          <Typography className={classes.title}>{`${t(
            "paymentSettings.invoiceHistory"
          )}`}</Typography>
        </Box>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            justifyContent: "space-between",
            flexDirection: "column",
          }}
        >
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{ backgroundColor: "transparent", boxShadow: "unset" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              className={classes.table}
              style={{ minWidth: "unset" }}
            >
              <HeaderCells
                headCells={headCells}
                orderBy={orderBy}
                order={order}
                createSortHandler={createSortHandler}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  rows.map((row) => (
                    <TableRow
                      key={row.completeDate}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell
                        align="left"
                        className={classes.tableCell}
                        style={{ color: "#541513", fontWeight: "bold" }}
                      >
                        {row.paymentGatewayInvoiceNumber}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        style={{ color: "#541513", fontWeight: "bold" }}
                      >
                        {moment(row.completeDate).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        style={{ color: "#541513", fontWeight: "bold" }}
                      >
                        {`${numberWithCommas(row.amount)} 円`}
                      </TableCell>
                      <TableCell
                        align="center"
                        className={classes.tableCell}
                        style={{ color: "#541513", fontWeight: "bold" }}
                      >
                        {row.status === "paid"
                          ? `${t("paymentSettings.paid")}`
                          : `${t("paymentSettings.unpaid")}`}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row?.status === "paid" && (
                          <Button
                            variant="contained"
                            className={classes.tableCell}
                            onClick={() =>
                              handleViewReceipt(row?.invoicePdfLink)
                            }
                            style={{
                              color: "#fff !important",
                              backgroundColor: "#ce5931",
                              textTransform: "capitalize",
                            }}
                          >
                            {t("paymentSettings.viewReceipt")}
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {rows && rows.length <= 0 && (
              <Box
                sx={{
                  padding: "2rem 0",
                  textAlign: "center",
                  color: "#541513",
                }}
              >
                <Typography>{t("dialog.noData")}</Typography>
              </Box>
            )}
          </TableContainer>
          <Box className={classes.invoiceBottom}>
            <ButtonCustom
              className={classes.buttonBlue}
              onClick={() => goBack()}
              text={t("paymentSettings.back")}
            />
            {rows && rows.length > 0 && (
              <Stack
                spacing={2}
                alignItems="center"
                justifyContent="center"
                height="100%"
              >
                <Pagination
                  count={totalPage}
                  className={classes.pagination}
                  onChange={handleChangePage}
                />
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
    </div>
  );
};

InvoiceSetting.propTypes = {
  goBack: PropTypes.func,
};

export default InvoiceSetting;
