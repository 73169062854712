import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import useStyles, { GroupButton, Title } from "./styles";
import Button from "../../../../components/Button";
import { SET_LOADING_GLOBAL } from "../../../../store/types";

const SetupForm = ({ handleCurrentPage }) => {
  const { t } = useTranslation();
  const classes = useStyles({});
  const stripe = useStripe();
  const elements = useElements();
  const [isShow, setIsShow] = useState(false);
  const [cardError, setCardError] = useState("");
  const dispatch = useDispatch();

  const handleSubmit = async (event) => {
    event.preventDefault();
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: true,
    });
    if (!stripe || !elements) {
      return;
    }
    const { error } = await stripe.confirmSetup({
      elements,
      confirmParams: {
        return_url: process.env.REACT_APP_STRIPE_SETUP_INTENT_URL,
      },
    });

    if (error) {
      setCardError(error.message);
    }

    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: false,
    });
  };

  useEffect(() => {
    const clearError = setTimeout(() => {
      setCardError("");
    }, 3000);

    return () => clearTimeout(clearError);
  }, [cardError]);

  return (
    <div style={{ padding: `${isShow ? "2em" : 0}` }}>
      <form>
        {isShow && <Title>{t("paymentSettings.changeCardTitle")}</Title>}
        <PaymentElement
          onReady={() => {
            setIsShow(true);
            dispatch({
              type: SET_LOADING_GLOBAL,
              payload: false,
            });
          }}
          id="payment-id"
        />

        {isShow && (
          <GroupButton>
            <Button
              sx={{ fontSize: "16px !important" }}
              className={classes.buttonBlue}
              type="button"
              onClick={() => handleCurrentPage(0)}
              text={t("paymentSettings.back")}
            />
            <Button
              sx={{ fontSize: "16px !important" }}
              className={classes.buttonOrange}
              text={t("register.register")}
              disabled={!stripe}
              onClick={handleSubmit}
            />
          </GroupButton>
        )}
        {cardError && (
          <p style={{ textAlign: "center", color: "red" }}>{cardError}</p>
        )}
      </form>
    </div>
  );
};

SetupForm.propTypes = {
  handleCurrentPage: PropTypes.func,
  clientSecret: PropTypes.string,
};

export default SetupForm;
