import { useEffect, useState } from "react";
import { searchFriends } from "../store/actions/friendAction";

const useFriendsSearch = (keyword, currentFriendIds) => {
  const [friends, setFriends] = useState([]);
  useEffect(() => {
    const loadSearch = async () => {
      const friendsList = await searchFriends(keyword, currentFriendIds);
      setFriends(friendsList);
    };
    loadSearch();
  }, [keyword, currentFriendIds]);

  return { friends };
};

export default useFriendsSearch;
