import { a, useSpring } from "@react-spring/web";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";

const FlippedCard = ({ flipped, src, onClickSelectCard, className }) => {
  // const size = useSelector((state) => state.size);

  const cardWidth = useSelector((state) =>
    Math.round(state.size.cardWidth * 1.15)
  );
  const cardHeight = useSelector((state) =>
    Math.round(state.size.cardHeight * 1.2)
  );
  const props = useSpring({
    opacity: 1,
    transform: `perspective(600px) rotateY(${flipped ? 180 : 0}deg)`,
    width: "100%",
    height: "100%",
    cursor: "pointer",
  });

  return (
    <div
      style={{
        height: cardHeight,
        width: cardWidth,
        textAlign: "center",
        boxSizing: "border-box",
        borderRadius: "4%",
      }}
      onClick={onClickSelectCard}
      className={className}
    >
      {flipped === false && (
        <a.div
          // className={`${classes.c} ${classes.back}`}
          style={{ ...props }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
              borderRadius: "4%",
            }}
            src={"/cards/back.png"}
          ></img>
        </a.div>
      )}
      {flipped === true && (
        <a.div
          // className={`${classes.c} ${classes.front} ${classes.active}`}
          style={{
            ...props,
            rotateY: "180deg",
          }}
        >
          <img
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
              borderRadius: "4%",
            }}
            src={src}
          ></img>
        </a.div>
      )}
    </div>
  );
};

FlippedCard.propTypes = {
  flipped: PropTypes.bool,
  src: PropTypes.string,
  size: PropTypes.any,
  onClickSelectCard: PropTypes.func,
  className: PropTypes.string,
};

export default FlippedCard;
