import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableCell: {
    "@media (max-width: 1200px)": {
      padding: "10px 5px !important",
      fontSize: "12px !important",
    },
    "@media (max-width: 991px)": {
      padding: "10px !important",
      fontSize: "10px !important",
    },
    "@media (max-width: 768px)": {
      padding: "10px 5px !important",
    },
  },
  label: {
    "& svg": {
      display: "none !important",
    },
    "&:hover svg": {
      display: "inline-block !important",
    },
    "&.Mui-active svg": {
      display: "inline-block !important",
    },
  },
}));

export default useStyles;
