import PropTypes from "prop-types";
import { Fragment, useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { GrStatusGoodSmall } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import {
  checkCreateThread,
  CheckIsFriendRequests,
  createThread,
  updateThreadIdInPlayerFriends,
} from "../../../../store/actions";
import { sendFriendRequest } from "../../../../store/actions/friendAction";
import { CHAT_TYPE, FRIEND_REQUEST_ALLOW } from "../../../../store/constant";
import { OPEN_CHAT_BOX_BY_THREAD_ID } from "../../../../store/types";
import DeleteDialog from "../DeleteDialog";
import {
  LabelFriend,
  StatusWrapper,
  StyleButton,
  UserList,
  UserName,
  Wrapper,
} from "./styles";

ListPlayer.propTypes = {
  checkBox: PropTypes.bool,
  usersOnline: PropTypes.any,
  fetchOnlineUsers: PropTypes.any,
  scaleHeight: PropTypes.number,
};

export default function ListPlayer({
  checkBox = false,
  usersOnline = [],
  fetchOnlineUsers = () => {},
  scaleHeight = 1,
}) {
  const playerId = useSelector((state) => state.user.playerId);
  const avatar = useSelector((state) => state.user.avatar);
  const nickName = useSelector((state) => state.user.nickName);
  const friends = useSelector((state) => state.user.friends);
  const friendRequests = useSelector((state) => state.user.friendRequests);
  const [userList, setUserList] = useState(usersOnline);
  const [isDelete, setIsDelete] = useState(false);
  const [friendId, setFriendId] = useState(null);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (checkBox) {
      const friendList = friends?.map((item) => item.friendId);
      const newUserOnlineFilter = usersOnline.filter((user) =>
        friendList.includes(user.playerId)
      );
      setUserList(newUserOnlineFilter);
    } else {
      setUserList(usersOnline);
    }
  }, [checkBox, usersOnline, friends]);

  const handleDeleteFriend = (fid) => {
    setIsDelete(true);
    setFriendId(fid);
  };
  const handleSendFriendRequest = async (fid) => {
    await sendFriendRequest({
      myId: playerId,
      avatar,
      nickname: nickName,
      friendId: fid,
    });
  };

  const buttonRef = useRef(null);

  const openChatBox = async (user) => {
    buttonRef.current.disabled = true;
    let threadId = "";
    if (await checkCreateThread(friends, user.playerId)) {
      const members = [
        {
          memberId: playerId,
          memberName: nickName,
          memberAvatar: avatar,
          isReading: false,
          countUnreadMessage: 0,
        },
        {
          memberId: user.playerId,
          memberName: user.nickName,
          memberAvatar: user.avatar,
          isReading: false,
          countUnreadMessage: 0,
        },
      ];
      threadId = await createThread(members, CHAT_TYPE.PRIVATE);
      if (threadId) {
        await updateThreadIdInPlayerFriends(playerId, user.playerId, threadId);
      }
    } else if (friends && friends.length > 0) {
      threadId = friends.find((f) => f.friendId === user.playerId)?.threadId;
    }

    if (threadId) {
      dispatch({
        type: OPEN_CHAT_BOX_BY_THREAD_ID,
        payload: threadId,
      });
    }
    buttonRef.current.disabled = false;
  };

  return (
    <Fragment>
      <Wrapper scaleHeight={scaleHeight}>
        {userList &&
          userList.map((user, index) => {
            const {
              colorPlayerStatus,
              isShowButtonAddFriend,
              isFriend,
              allowMode,
            } = user;
            return (
              <UserList key={index}>
                <StatusWrapper>
                  <GrStatusGoodSmall
                    style={{
                      color: colorPlayerStatus,
                      fontSize: 14,
                      flexShrink: 0,
                    }}
                  />
                  <UserName>{user.nickName}</UserName>
                </StatusWrapper>
                {isFriend ? (
                  <>
                    <LabelFriend>{t("status.friend")}</LabelFriend>
                    <StyleButton
                      isPresse={true}
                      ref={buttonRef}
                      onClick={() => openChatBox(user)}
                    >
                      {t("status.chat")}
                    </StyleButton>
                    <StyleButton
                      isPresse={true}
                      onClick={() => handleDeleteFriend(user.playerId)}
                    >
                      {t("status.unfriend")}
                    </StyleButton>
                  </>
                ) : null}

                {!isFriend &&
                isShowButtonAddFriend &&
                allowMode !== FRIEND_REQUEST_ALLOW.NOT_ALLOW ? (
                  <StyleButton
                    isPresse={true}
                    onClick={() => handleSendFriendRequest(user.playerId)}
                  >
                    {t("status.addFriend")}
                  </StyleButton>
                ) : null}
                {!isFriend &&
                !isShowButtonAddFriend &&
                allowMode === FRIEND_REQUEST_ALLOW.ALL_USER &&
                !CheckIsFriendRequests(friendRequests, user.playerId) &&
                user.playerId !== playerId ? (
                  <StyleButton isPresse={false}>
                    {t("status.pending")}
                  </StyleButton>
                ) : null}
              </UserList>
            );
          })}
        <div style={{ height: 20 }} />
      </Wrapper>

      <DeleteDialog
        isOpen={isDelete}
        onClose={() => {
          setIsDelete(false);
        }}
        friendId={friendId}
        fetchOnlineUsers={fetchOnlineUsers}
        isOnline={true}
      />
    </Fragment>
  );
}
