import React from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core";
import { Media } from "../../Media/Media";

const useStyles = makeStyles(() => ({
  menuBottomRoot: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "flex-start",
    height: (props) => props.height,
    width: "100%",
  },
}));

const MenuBottom = React.memo(function MenuBottomComponent() {
  const bottomHeight = useSelector((state) => state.size.bottomHeight);
  const classes = useStyles({ height: bottomHeight });
  return (
    <div className={classes.menuBottomRoot} id="gc-menu-bottom">
      <Media />
    </div>
  );
});

export default MenuBottom;
