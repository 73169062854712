import { Button } from "@material-ui/core";

import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import * as htmlToImage from "html-to-image";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import NotifyGChaosTurn from "../../components/NotifyGChaosTurn";
import ReloadTracker from "../../components/ReloadTracker";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import {
  subscribeOtherPlayer,
  subscribePlayerGameStateByPlayerId,
} from "../../store/actions/playerGameStateActions";
import {
  subscribeRoom,
  subscribeSubCollectionRoom,
  subscribeTableJoin,
} from "../../store/actions/roomActions";
import { GAME_STATUS, POPUPS } from "../../store/constant";
import { SET_LOADING_GLOBAL, UPDATE_WIN_GAME_SHOWED } from "../../store/types";
import { sleep } from "../../utils/common";
import StatusTab from "../components/StatusTab";
import ConfettiCelebration from "./components/ConfettiCelebration/ConfettiCelebration";
import { GameArea } from "./components/GameArea/GameArea";
import { Menu } from "./components/Menu/Menu";
import QueueNarration from "./components/Narration/QueueNarration/QueueNarration";
import StepNarration from "./components/Narration/StepNarration";
import BasicDialog from "./components/NewDialog/BasicDialog";
import OkDialog from "./components/NewDialog/OkDialog";
import OnlineStatusNotifier from "./components/OnlineStatusNotifier/OnlineStatusNotifier";
import VideoComponent from "./components/VideoComponent";
import { Container, Main } from "./styles";
import { updatePlayerStatus } from "./../../store/actions/userAction";

export const GameScreen = () => {
  const dispatch = useDispatch();
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const playerIdStatus = useSelector((state) => state.user.playerId);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const roomId = useSelector((state) => state.room.table.roomId);
  const owner = useSelector((state) => state.room.table.owner);
  const popupId = useSelector((state) => state.narration.narrationPopup);
  const widthScreen = useSelector((state) => state.size.width);
  const isLoading = useSelector((state) => state.size.isLoading ?? false);
  const isBuyingLoading = useSelector((state) => state.payment.isBuyingLoading ?? false);

  const isNarrationSound = useSelector(
    (state) => state.user.setting.isNarrationSound
  );
  const [soundCount, setSoundCount] = useState(0);
  const [showCaptureDialog, setShowCaptureDialog] = useState(false);
  const [showAnimated, setShowAnimated] = useState(false);
  const [showJoinRoom, setShowJoinRoom] = useState(false);
  const [joinRoomTwilio, setJoinRoomTwilio] = useState(false);
  const [skip, setSkip] = useState(false);
  const image = useRef(null);
  const [openStatusList, setOpenStatusList] = useState(false);
  const refImg = useRef(null);

  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.gameScreenTitle"));

  const hideConfetti = () => {
    setShowCaptureDialog(false);
    setShowAnimated(false);
    setSkip(false);
    setSoundCount(1);
  };

  const handleJoinRoomTwilio = useCallback(() => {
    setShowJoinRoom(false);
    setJoinRoomTwilio(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [roomId]);

  const handleLoading = useCallback((_value) => {
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: _value,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const createFileName = (extension = "", ...names) => {
    if (!extension) {
      return "";
    }

    return `${names.join("")}.${extension}`;
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const takeScreenShott = useCallback(async (node) => {
    const dataURI = await htmlToImage.toJpeg(node, { cacheBust: true });
    return dataURI;
  });

  const download = (image, { name = "img", extension = "jpeg" } = {}) => {
    const a = document.createElement("a");
    a.href = image;
    a.download = createFileName(extension, name);
    a.click();
  };

  const onCancelDownloadImage = async () => {
    hideConfetti();
    dispatch({ type: UPDATE_WIN_GAME_SHOWED, payload: true });
  };

  const onDownloadImage = async () => {
    setShowCaptureDialog(false);
    if (image.current !== null) {
      download(image.current);
    } else {
      takeScreenShott(refImg.current).then((img) => download(img));
    }
    await sleep(2000);
    dispatch({ type: UPDATE_WIN_GAME_SHOWED, payload: true });
    hideConfetti();
  };

  const toggleStatusList = useCallback(async () => {
    setOpenStatusList(!openStatusList);
    await updatePlayerStatus(playerIdStatus);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [openStatusList]);

  useEffect(() => {
    const showConfetti = async () => {
      if (!playerId) {
        hideConfetti();
        return;
      }
      if (
        popupId !== POPUPS.ANIMATED_WIN_GAME ||
        gameStatus !== GAME_STATUS.WIN
      ) {
        hideConfetti();
        return;
      }
      setSkip(true);
      setShowAnimated(true);
      await sleep(16000);
      setSoundCount(1);
      await sleep(16000);
      setShowAnimated(false);
      setSkip(false);
      setShowCaptureDialog(true);
    };
    showConfetti();
  }, [popupId, playerId, gameStatus, soundCount]);

  useEffect(() => {
    localStorage.setItem("isNarrationSound", JSON.stringify(isNarrationSound));
  }, [isNarrationSound]);

  useEffect(() => {
    const isShow = JSON.parse(localStorage.getItem("isShowJoinRoomTwilio"));
    setShowJoinRoom(isShow);
    setJoinRoomTwilio(!isShow);
    if (!isShow) {
      localStorage.setItem("isShowJoinRoomTwilio", JSON.stringify(true));
    }
  }, []);

  useEffect(() => {
    let unsubscribeRoom = () => {};
    let unsubscribeSubCollectionRoom = () => {};
    let unsubscribeTableJoin = () => {};
    let unsubscribePlayerGameState = () => {};

    dispatch(subscribeRoom((func) => (unsubscribeRoom = func)));
    dispatch(
      subscribeSubCollectionRoom(
        (func) => (unsubscribeSubCollectionRoom = func)
      )
    );
    dispatch(subscribeTableJoin((func) => (unsubscribeTableJoin = func)));
    dispatch(
      subscribePlayerGameStateByPlayerId(
        (func) => (unsubscribePlayerGameState = func)
      )
    );

    return () => {
      unsubscribeRoom();
      unsubscribeSubCollectionRoom();
      unsubscribeTableJoin();
      unsubscribePlayerGameState();
    };
  }, [playerId, owner, dispatch]);

  useEffect(() => {
    let unsubscribeOtherPlayer = () => {};
    dispatch(subscribeOtherPlayer((func) => (unsubscribeOtherPlayer = func)));
    return () => {
      unsubscribeOtherPlayer();
    };
  }, [gameStatus, dispatch]);

  const handleTakeScreenShot = useCallback(async () => {
    image.current = await takeScreenShott(refImg.current);
  }, [takeScreenShott]);

  useEffect(() => {
    const screenshot = async () => {
      if (showAnimated) {
        await sleep(8000);
        handleTakeScreenShot();
      }
    };
    screenshot();
  }, [showAnimated, handleTakeScreenShot]);

  const skipAnimation = () => {
    setShowAnimated(false);
    setSkip(false);
    setShowCaptureDialog(true);
  };

  return (
    <div>
      <Backdrop style={{ zIndex: 99999, color: "#fff" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop style={{ zIndex: 99999, color: "#fff", display: "flex", flexDirection: "column" }} open={isBuyingLoading}>
        <CircularProgress color="inherit" />
        <div style={{ padding: "50px" }}>{t("loading.isBuyingLoading")}</div>
      </Backdrop>
      {showAnimated && (
        <audio
          autoPlay={popupId === POPUPS.ANIMATED_WIN_GAME}
          src={
            popupId === POPUPS.ANIMATED_WIN_GAME
              ? "/sounds/06_Fnf_Victory.mp3"
              : ""
          }
        />
      )}
      <div ref={refImg}>
        <ConfettiCelebration isWinGame={showAnimated} />
        <Container>
          <Main>
            {skip && (
              <Button
                style={{
                  position: "fixed",
                  backgroundColor: "#ecbd2a",
                  border: "2px solid #ecbd2a",
                  borderRadius: "20",
                  fontWeight: "600",
                  right: "0.5%",
                  top: "0.5%",
                  zIndex: "3",
                  whiteSpace: "nowrap",
                }}
                onClick={skipAnimation}
              >
                {t("tables.skip")}
              </Button>
            )}
            <Menu />
            <GameArea
              handleLoading={handleLoading}
              toggleStatusList={toggleStatusList}
            />
            {joinRoomTwilio && (
              <VideoComponent roomId={roomId}></VideoComponent>
            )}
          </Main>
          <QueueNarration stepId={stepId} />
          {/* <NarrationSound /> */}
          <StepNarration />
          <OnlineStatusNotifier />
        </Container>
        {popupId === POPUPS.ANIMATED_WIN_GAME ? (
          <BasicDialog
            mainText={t("endGame.congratulation")}
            isOpen={showCaptureDialog}
            onCancel={onCancelDownloadImage}
            onAgree={onDownloadImage}
            closeText={t("narrationSetting.no")}
            agreeText={t("common.yes")}
          />
        ) : null}
      </div>
      <ReloadTracker />
      <NotifyGChaosTurn />
      {/* TAB STATUS */}
      <StatusTab
        isOpen={openStatusList}
        toggle={toggleStatusList}
        coordinates={{ x: widthScreen - 400, y: 55 }}
      />

      {/* //whet */}
      <OkDialog
        isOpen={showJoinRoom}
        onAgree={handleJoinRoomTwilio}
        confirmText="カメラとマイクを再び利用するときは、画面左下の「Video」「Voice」ボタンをもう一度押してください。"
        btnLabel={"OK"}
      />
    </div>
  );
};
