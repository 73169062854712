import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
const useStyles = makeStyles(() => ({
  dialogPaper: {
    backgroundColor: "rgba(176, 195, 199, 1) !important",
    borderRadius: "40px !important",
    maxWidth: "unset !important",
    overflow: "unset !important",
  },
  mainBox: {
    // display: "flex",
    // overflowX: "hidden",
    // justifyContent: "center",
    // alignItems: "flex-start",
    // height: "100%",
    // width: "100%",
    // flexDirection: "row",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    "& span": {
      marginLeft: "12px",
    },
  },
  iconBox: {
    display: "flex !important",
    alignItems: "center !important",
    height: "51px !important",
  },
  formSeclect: {
    width: "300px",
    height: "50px !important",
    border: "2px solid rgb(75, 74, 74) !important",
    backgroundColor: " rgb(233, 225, 212) !important",
    margin: "0 auto !important",
    color: "rgb(84, 21, 19) !important",
    justifyContent: "center",
    fontWeight: 600,
  },
  boxButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "10px",
    marginTop: "1rem",
  },
  fieldItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "10px",
  },
  datePickCustum: {
    width: (props) => `${props.input_W}px`,
    height: (props) => `${props.input_H}px`,
    color: "#541f1c",
    textAlign: "center",
    border: "2px solid rgb(75, 74, 74)",
    backgroundColor: "#e9e1d4",
    display: "flex",
    overflow: "hidden",
    margin: 0,
    "& div": {
      height: "100%",
      color: "#541513",
      backgroundColor: "#e9e1d4",
      paddingLeft: "1em",
      border: "none",
    },
    "& input": {
      fontWeight: "600",
      fontSize: 16,
    },

    "& > p": {
      fontSize: 11,
    },
  },
}));

export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  border-radius: 40px;
  justify-content: space-evenly;
`;
export default useStyles;
