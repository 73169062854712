// Spacing
export const BORDER_RADIUS = "0.7em";

// Colors
export const COLOR_BROWN = "#542e14";
export const COLOR_WHITE = "#ffffff";
export const COLOR_DARK_GREEN = "#d1e066";
export const COLOR_BLACK = "#000000";
export const COLOR_YELLOW_LIGHT = "#f9eab9";
export const COLOR_BLUE_LIGHT = "#61baea";
export const COLOR_YELLOW = "#ecbd2a";
export const COLOR_GREY_LIGHT = "#f7f7f7";
export const COLOR_BROWN_LIGHT = "#cca98e";
export const COLOR_GREY = "#e6e6e7";
export const COLOR_ORANGE = "#e95316";
export const SHIP_BG_COLOR = "#dea417";
export const MAIN_COLOR = "#412c1d";
export const SECOND_COLOR = "#917e70";

export const colorsPlayerStatus = {
  AVAILABLE: "#399D26",
  WAITING: "#FFEC00",
  PLAYING: "#DC000C",
};
export const paymentStatus = {
  FAIL: "requires_payment_method",
  SUCCESS: "succeeded",
  REQUIRES: "requires_action",
};

export const apiStatus = {
  CREATE: 201,
  SUCCESS: 200,
  FAIL: 404,
};

export const invoiceType = {
  SUB: "subscription",
  PAYMENT: "payment",
};

//Contact type
export const CONTACT_TYPE = {
  OTHER: "other",
  PLAY_TIME: "playTime",
  PLAY_ROLE: "playRole",
  PAYMENT: "payment",
  SUPPORT: "support",
};

// international phone code
export const PHONE_CODE = "+81";

//package background color
export const PACKAGE_BACK_GROUND_COLOR = {
  GOLD: "#f4ca49",
  TRIAL: "#597029",
  BRONZE: "#d4947a",
  PLATINUM: "#b5b8ba",
  SILVER: "#e3e2e1",
};

//DOMAIN
export const DOMAIN_INVOICE = "https://invoice.stripe.com/i/";
export const DOMAIN_GET_RECEIPT =
  "https://invoicedata.stripe.com/invoice_receipt_file_url/";

// error types
export const POSITION_ERROR = {
  LOGIN: "LOGIN",
  REGISTER: "REGISTER",
  CHAT: "CHAT",
  TABLE: "TABLE",
  SETTINGS: "SETTINGS",
  GAME: "GAME",
  CONTACT: "CONTACT",
};

export const TYPE_ERROR = {
  TWILIO: "TWILIO",
};
