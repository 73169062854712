import {
  AppBar,
  Avatar,
  Badge,
  Box,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import { Tooltip } from "@mui/material";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineGlobal, AiOutlineMessage } from "react-icons/ai";
import { IoIosNotifications } from "react-icons/io";
import { RiArrowDropDownLine } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import packageImageBronze from "../../assets/icons/bronze.png";
import packageImageGold from "../../assets/icons/gold.png";
import packageImagePlatinum from "../../assets/icons/platinum.png";
import packageImageSilver from "../../assets/icons/silver.png";
import packageImageTrial from "../../assets/icons/trial.png";
import StatusTab from "../../pages/components/StatusTab";
import { logout } from "../../store/actions/authActions";
import { seenAllNotifications } from "../../store/actions/playerActions";
import {
  clearPrivateMessage,
  updatePlayerStatus,
} from "../../store/actions/userAction";
import { ONE_DAY } from "../../store/constant";
import {
  ADD_INVITE,
  CLOSE_CHAT_BOX,
  OPEN_CHAT_BOX,
  SEEN_ALL_INVITE,
} from "../../store/types";
import Notifications from "./components/notifications";
import useStyles from "./styles";

const Header = () => {
  const [openMenuLanguages, setOpenMenuLanguages] = useState(false);
  const [openMenuSettings, setOpenMenuSettings] = useState(false);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [openStatusList, setOpenStatusList] = useState(false);
  const [activePackageName, setActivePackageName] = useState("");

  const anchorRefLanguages = useRef(null);
  const anchorRefSettings = useRef(null);
  const user = useSelector((state) => state.user);
  const playerId = useSelector((state) => state.user.playerId);
  const avatar = useSelector((state) => state.user.avatar);
  const nickName = useSelector((state) => state.user.nickName);
  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);

  const { t, i18n } = useTranslation();
  const history = useHistory();
  const privateInvites = useSelector(
    (state) => state.user.privateInvitations ?? []
  );
  const friendRequests = useSelector((state) => state.user.friendRequests);
  const notifications = useSelector((state) => state.user.notifications);
  const infoNotifications = useSelector(
    (state) => state.user.infoNotifications
  );
  const countMessageUnRead = useSelector(
    (state) => state.chat.countMessageUnRead
  );
  const notifies = useSelector((state) => state.inviteNotify.invite);
  const minScale = useSelector((state) => state.size.minScale);
  const widthScreen = useSelector((state) => state.size.width);
  const [innerSize, setInnerSize] = useState({});
  const [notifyBadge, setNotifyBadge] = useState(0);
  const [allNotifies, setAllNotifies] = useState([]);
  const registeredTime = useSelector((state) => state.user.registeredTime);

  const dispatch = useDispatch();

  const isTrial = useMemo(() => {
    const registeredDate = new Date(registeredTime);
    const today = new Date();
    const diffInDays = (today - registeredDate) / ONE_DAY;
    return diffInDays < 7;
  }, [registeredTime]);

  const badgeColors = useMemo(() => {
    if (isTrial) return ["#1fd969", "black"];
    return ["#c4c721", "black"];
  }, [isTrial]);

  const handleShowtitlePackage = (packageName) => {
    return `${t("paymentSettings." + packageName)}`;
  };

  const handleShowImgSrcPackage = (packageName) => {
    if (packageName === "Gold") {
      return packageImageGold;
    } else if (packageName === "Platinum") {
      return packageImagePlatinum;
    } else if (packageName === "Silver") {
      return packageImageSilver;
    } else {
      return packageImageBronze;
    }
  };

  useEffect(() => {
    if (playTimeInfo) {
      setActivePackageName(playTimeInfo.packageName);
    }
  }, [playTimeInfo]);

  useEffect(() => {
    if (minScale) {
      const introImgSize = minScale < 0.5 ? 250 : minScale < 0.55 ? 350 : 440;
      const introImgHeight = minScale < 0.5 ? 18 : 43;
      const fontSize16 = minScale < 0.4 ? 9 : 16;
      const iconWidth =
        minScale > 0.6 ? 2 : minScale >= 0.4 ? minScale * 2.3 : minScale * 2;
      const btnLanguage = minScale > 0.6 ? 24 : minScale * 24;
      const menuWidth = minScale < 0.4 ? 250 : minScale * 250;
      const minScalex = minScale <= 0.4 ? minScale * 16 : 16;
      setInnerSize({
        introImgSize,
        introImgHeight,
        fontSize16,
        iconWidth,
        btnLanguage,
        menuWidth,
        minScalex,
      });
    } else {
      const introImgSize = Math.round(589 * 1);
      const introImgHeight = 43;
      const fontSize16 = 16;
      const iconWidth = 2;
      const btnLanguage = 24;
      const menuWidth = 250;
      const minScalex = 12;
      setInnerSize({
        introImgSize,
        introImgHeight,
        fontSize16,
        iconWidth,
        btnLanguage,
        menuWidth,
        minScalex,
      });
    }
  }, [minScale]);
  const classes = useStyles({
    introImgSize: innerSize.introImgSize,
    introImgHeight: innerSize.introImgHeight,
    fontSize16: innerSize.fontSize16,
    badgeBgColor: badgeColors[0],
    badgeTextColor: badgeColors[1],
    iconWidth: innerSize.iconWidth,
    btnLanguage: innerSize.btnLanguage,
    menuWidth: innerSize.menuWidth,
    minScalex: innerSize.minScalex,
  });
  const toggleDrawer = () => {
    seenAllNotifications(playerId);
    dispatch({ type: SEEN_ALL_INVITE, payload: {} });
    setOpenNotifications(!openNotifications);
  };
  const toggleStatus = async () => {
    await updatePlayerStatus(playerId);
    setOpenStatusList(!openStatusList);
  };

  const handleToggleLanguages = () => {
    setOpenMenuLanguages((prevOpen) => !prevOpen);
  };

  const handleToggleSettings = () => {
    setOpenMenuSettings((prevOpen) => !prevOpen);
  };

  const handleClose = (event, value) => {
    if (
      anchorRefLanguages.current &&
      anchorRefLanguages.current.contains(event.target)
    ) {
      return;
    }
    if (
      anchorRefSettings.current &&
      anchorRefSettings.current.contains(event.target)
    ) {
      return;
    }

    if (value === "en" || value === "ja") {
      i18n.changeLanguage(value);
    }

    if (value === "settings") {
      history.push("/settings");
    }
    setOpenMenuLanguages(false);
    setOpenMenuSettings(false);
  };

  const handleLogout = () => {
    dispatch({
      type: CLOSE_CHAT_BOX,
    });
    dispatch(logout);
    history.push("/");
  };

  const prevOpenMenuLanguages = useRef(openMenuLanguages);
  useEffect(() => {
    if (prevOpenMenuLanguages.current === true && openMenuLanguages === false) {
      anchorRefLanguages.current.focus();
    }

    prevOpenMenuLanguages.current = openMenuLanguages;
  }, [openMenuLanguages]);

  const prevOpenMenuSettings = useRef(openMenuSettings);
  useEffect(() => {
    if (prevOpenMenuSettings.current === true && openMenuSettings === false) {
      anchorRefSettings.current.focus();
    }

    prevOpenMenuSettings.current = openMenuSettings;
  }, [openMenuSettings]);

  useEffect(() => {
    if (notifies && friendRequests && notifications) {
      const badgeCount =
        notifies.filter((notify) => !notify.seen).length +
        friendRequests.filter((item) => item.seen === false).length +
        notifications.filter((item) => item.seen === false).length;
      setNotifyBadge(badgeCount);
      const data = notifies.concat(friendRequests, notifications);
      const _notifies = data.sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : -1
      );
      setAllNotifies(_notifies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifies, friendRequests, notifications]);

  // update notify
  useEffect(() => {
    if (privateInvites && privateInvites.length > 0) {
      privateInvites.forEach((itemInvite) => {
        dispatch({
          type: ADD_INVITE,
          payload: {
            roomId: itemInvite.roomId,
            tableName: itemInvite.tableName,
            tableAvatar: itemInvite.tableAvatar,
            description: itemInvite.description,
            message: itemInvite.inviteMessage,
            ownerFullName: itemInvite.ownerFullName,
            type: "private",
            seen: false,
            timestamp: Date.now(),
          },
        });
      });
      clearPrivateMessage(playerId);
    }
  }, [privateInvites, dispatch, playerId]);

  // update notify
  useEffect(() => {
    if (infoNotifications && infoNotifications.length > 0) {
      infoNotifications.forEach((itemInfo) => {
        dispatch({
          type: ADD_INVITE,
          payload: {
            roomId: "",
            tableName: t(itemInfo.title),
            tableAvatar: "",
            description: "",
            message: t("invitation.playTimeAdded"),
            ownerFullName: t("invitation.inviteAFriendSuccessfully"),
            type: itemInfo.type,
            seen: false,
            timestamp: Date.now(),
          },
        });
      });
    }
  }, [infoNotifications, dispatch, t]);

  const handleSetNickName = (nickName) => {
    if (nickName && nickName.length > 5) {
      return nickName.slice(0, 5) + "...";
    }
    return nickName;
  };

  return (
    <AppBar position="sticky">
      <Toolbar className={classes.toolbar}>
        <div className={classes.logoContainer}>
          <Typography className={classes.tittle} component={Link} to="/">
            <img className={classes.png} src="/assets/introduction.png" />
          </Typography>
          <div style={{ height: "100%", flex: 1 }}></div>
        </div>
        <div>
          <Button
            ref={anchorRefLanguages}
            aria-controls={
              openMenuLanguages ? "menu-list-languages" : undefined
            }
            aria-haspopup="true"
            onClick={handleToggleLanguages}
            endIcon={<RiArrowDropDownLine />}
            className={classes.buttonLanguages}
          >
            {i18n.language === "en"
              ? t("languages.english")
              : t("languages.japanese")}
          </Button>
          <Popper
            open={openMenuLanguages}
            anchorEl={anchorRefLanguages.current}
            role={undefined}
            transition
            disablePortal
          >
            {({ TransitionProps, placement }) => (
              <Grow
                {...TransitionProps}
                style={{
                  transformOrigin:
                    placement === "bottom" ? "center top" : "center bottom",
                }}
              >
                <Paper>
                  <ClickAwayListener onClickAway={handleToggleLanguages}>
                    <MenuList
                      autoFocusItem={openMenuLanguages}
                      id={"menu-list-languages"}
                    >
                      <MenuItem
                        onClick={(e) => handleClose(e, "en")}
                        style={{ fontSize: "14px !important" }}
                      >
                        {t("languages.english")}
                      </MenuItem>
                      <MenuItem
                        onClick={(e) => handleClose(e, "ja")}
                        style={{ fontSize: "14px !important" }}
                      >
                        {t("languages.japanese")}
                      </MenuItem>
                    </MenuList>
                  </ClickAwayListener>
                </Paper>
              </Grow>
            )}
          </Popper>
        </div>
        {user && user.playerId !== "" ? null : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            <Button
              className={classes.buttonRegister}
              component={Link}
              to="/register"
              style={{ padding: "0 2em" }}
              disableRipple={true}
            >
              {t("register.register")}
            </Button>
            <Button
              className={classes.buttonLogin}
              component={Link}
              to="/login"
              style={{ padding: "0 2em" }}
            >
              {t("login.login")}
            </Button>
          </div>
        )}

        {user && user.playerId !== "" ? (
          <>
            <div
              style={{ backgroundColor: "#efcbbc" }}
              className={classes.iconButton}
            >
              <IconButton
                component="span"
                color="inherit"
                disableRipple={true}
                onClick={toggleStatus}
              >
                <AiOutlineGlobal style={{ color: "#4b4b4b" }} />
              </IconButton>
            </div>
            {/* show chat tab */}
            <div
              style={{ backgroundColor: "#efb39b" }}
              className={classes.iconButton}
            >
              <IconButton
                component="span"
                color="inherit"
                disableRipple={true}
                onClick={() => dispatch({ type: OPEN_CHAT_BOX })}
              >
                <Badge
                  badgeContent={countMessageUnRead ?? 0}
                  color="error"
                  overlap="rectangular"
                >
                  <AiOutlineMessage style={{ color: "#4b4b4b" }} />
                </Badge>
              </IconButton>
            </div>
            <div
              style={{ backgroundColor: "#e49a7d" }}
              className={classes.iconButton}
            >
              <IconButton
                component="span"
                color="inherit"
                disableRipple={true}
                onClick={toggleDrawer}
              >
                <Badge
                  badgeContent={notifyBadge ?? 0}
                  color="error"
                  overlap="rectangular"
                >
                  <IoIosNotifications style={{ color: "#4b4b4b" }} />
                </Badge>
              </IconButton>
            </div>
            <Box
              ref={anchorRefSettings}
              edge="end"
              color="inherit"
              aria-controls={openMenuSettings ? "menu-settings" : undefined}
              aria-haspopup="true"
              onClick={handleToggleSettings}
              component="button"
              className={classes.menuSettings}
            >
              <Avatar src={avatar} className={classes.avatar} />
              <Typography noWrap className={classes.nickName}>
                {handleSetNickName(nickName)}
              </Typography>
              {minScale >= 0.41 && !playTimeInfo?.statusFree && (
                <div className={classes.packageImageBox}>
                  <Tooltip
                    title={handleShowtitlePackage(activePackageName)}
                    placement="bottom"
                  >
                    <img src={handleShowImgSrcPackage(activePackageName)} />
                  </Tooltip>
                </div>
              )}
              {minScale >= 0.41 && playTimeInfo?.statusFree && (
                <div className={classes.packageImageBox}>
                  <Tooltip title="トライアル中" placement="bottom">
                    <img src={packageImageTrial} />
                  </Tooltip>
                </div>
              )}

              <IconButton className={classes.dropDownIcon} color="inherit">
                <RiArrowDropDownLine />
              </IconButton>
            </Box>
            <Popper
              open={openMenuSettings}
              anchorEl={anchorRefSettings.current}
              role={undefined}
              transition
              disablePortal
              placement="bottom-end"
              style={{ zIndex: 1000 }}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin: placement === "bottom-end",
                  }}
                >
                  <Paper>
                    <ClickAwayListener onClickAway={handleToggleSettings}>
                      <MenuList
                        autoFocusItem={openMenuSettings}
                        id={"menu-settings"}
                        style={{ width: "250px" }}
                      >
                        <MenuItem
                          onClick={(e) => handleClose(e, "settings")}
                          style={{
                            display: "flex",
                            justifyContent: "center",
                          }}
                        >
                          <Avatar src={avatar} />
                        </MenuItem>
                        <MenuItem onClick={(e) => handleClose(e, "settings")}>
                          {t("settings.settings")}
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                          {t("common.logout")}
                        </MenuItem>
                      </MenuList>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </>
        ) : null}
      </Toolbar>

      {/* DRAWER NOTIFICATIONS */}
      <Notifications
        allNotifies={allNotifies}
        myId={playerId}
        isOpen={openNotifications}
        toggle={toggleDrawer}
      />
      {/* TAB STATUS */}
      <StatusTab
        isOpen={openStatusList}
        toggle={toggleStatus}
        coordinates={{ x: widthScreen - 400, y: 55 }}
      />
    </AppBar>
  );
};

export default Header;
