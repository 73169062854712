import PropTypes from "prop-types";
import React, { useMemo } from "react";

import { useSelector } from "react-redux";
import styled from "styled-components";

const CardContainer = styled.div`
  height: ${(props) => props.height}px;
  width: ${(props) => props.width}px;
  transform: ${(props) => props.transformText};
  transform-origin: center;
  position: absolute;
  z-index: ${(props) => props.zIndex};
  top: ${(props) => props.gap};
  border-radius: 4%;
  box-shadow: 0 0 3px black;
`;

const Card = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 4%;
  object-fit: fill;
`;
const HCard = React.memo(function HCardComponent({
  src,
  zIndex,
  gap,
  isBreakThrough1Call,
  isEve,
}) {
  const width = useSelector((state) => state.size.cardWidth);
  const height = useSelector((state) => state.size.cardHeight);
  const gapText = useMemo(
    () => (isBreakThrough1Call ? "35%" : `${gap}px`),
    [gap, isBreakThrough1Call]
  );
  const rotateText = useMemo(
    () => (isEve ? "rotate(0deg)" : "rotate(90deg)"),
    [isEve]
  );
  const translateText = useMemo(
    () =>
      isEve
        ? `translate(${Math.round(height / 2 - width / 2)}px,0px)`
        : "translate(0,0)",
    [isEve, height, width]
  );

  const transformText = useMemo(
    () => `${rotateText} ${translateText}`,
    [rotateText, translateText]
  );

  return (
    <CardContainer
      zIndex={zIndex}
      gap={gapText}
      width={width}
      height={height}
      transformText={transformText}
    >
      <Card src={src} />
    </CardContainer>
  );
});

HCard.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  zIndex: PropTypes.number,
  gap: PropTypes.number,
  isEve: PropTypes.bool,
  isBreakThrough1Call: PropTypes.bool,
  isEve1CardAdd: PropTypes.bool,
};
export default HCard;
