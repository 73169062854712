import styled from "styled-components";
import { IconButton } from "@material-ui/core";

export const Container = styled.div`
  box-sizing: border-box;
  background-color: #e6e6e7;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  position: absolute;
  border-radius: 30px;
  transform: scale(0.9);
  height: 60%;
  font-size: 1em;
  @media screen and (max-width: 1024px) {
    // transform: translate(calc(-20% + 12px), 0) scale(0.6);
    border-radius: 20px;
  }
`;

export const MyNumberOfCalls = styled.div`
  width: 30%;
  height: 67%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  text-align: center;
`;

export const Button = styled(IconButton)`
  padding: 0;
`;

export const NumberOfCalls = styled.p`
  font-size: 30px;
  font-weight: bold;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const ChangeCalls = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const ButtonOK = styled.button`
  cursor: pointer;
  border: none;
  color: white;
  width: 80%;
  height: 32%;
  padding: 10px 15px;
  font-size: 20px;
`;

export const PlayersName = styled.div`
  width: 30%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
export const NickName = styled.div`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  font-size: ${(props) => props.fontSize}px;
`;
export const PlayersCall = styled.div`
  width: 15%;
  height: 60%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;
