import { Box, Container } from "@material-ui/core";
import React, { useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import Backdrop from "../../components/Backdrop";
import firebase from "../../services/firebase";
import useStyles, { LogoGame, Production } from "./styles";
import LoginPhoneOtp from "./components/LoginPhoneOtp";
import LoginPhone from "./components/LoginPhone";
import SendedOtp from "../../components/Otp/SendedOtp";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../hooks/useDocumentTitle";

firebase.auth().languageCode = "ja";

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const Login = () => {
  const logoGameWidth = 240;
  const logoGameHeight = 150;
  const productionWidth = 130;
  const productionHeight = 6;
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.loginTitle"));

  const [loading, setLoading] = useState(false);
  const [indexPage, setIndexPage] = useState(0);
  const [numberPhone, setNumberPhone] = useState("");

  const handleChangeValues = useCallback((e) => {
    const { value } = e.target;
    setNumberPhone(value);
  }, []);

  const handleChangePage = useCallback((_page) => {
    setIndexPage(_page);
  }, []);

  useEffect(() => {
    setLoading(false);
    return () => setLoading(true);
  }, []);

  return (
    <Container className={classes.root}>
      <Backdrop open={loading} />
      <Box
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <LogoGame width={logoGameWidth} height={logoGameHeight} />
        <Space space={10} />
        <Production width={productionWidth} height={productionHeight} />
        {indexPage === 0 && (
          <LoginPhone
            values={numberPhone}
            handleChangeValues={handleChangeValues}
            handleChangePage={handleChangePage}
          />
        )}
        {indexPage === 1 && (
          <LoginPhoneOtp
            handleChangePage={handleChangePage}
            values={numberPhone}
          />
        )}
        {indexPage === 2 && (
          <SendedOtp
            handleChangePage={handleChangePage}
            numberPhone={numberPhone}
            labelButton="ログイン画面に戻る"
          />
        )}
      </Box>
    </Container>
  );
};

export default Login;
