import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { getFunctions } from "../../../../services/firebase";
import { trackPlayTime } from "../../../../store/actions/packageActions";
import {
  G_CHAOS,
  G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST,
  MIN,
} from "../../../../store/constant";
import { REQUEST_PLEASE } from "../../../../store/funcs";
import {
  CLEAR_ALL_TWILIO,
  CLEAR_NARRATION,
  CLEAR_PLAYER_GAME_STATE,
  CLEAR_ROOM,
} from "../../../../store/types";
import {
  clearGChaosComputerSanNickName,
  expiredTrial,
  sleep,
} from "../../../../utils/common";
import InstructorNotify from "../InstructorNotify";
import BasicDialog from "../NewDialog/BasicDialog";
import OkDialog from "../NewDialog/OkDialog";
import ShowInfo from "../ShowInfo";
import { GameCenter } from "./GameCenter/GameCenter";
import { GameLeft } from "./GameLeft/GameLeft";
import { GameRight } from "./GameRight/GameRight";
import Spacer from "./Spacer";
const functions = getFunctions();
const Container = styled.div`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  background-color: transparent;
  display: flex;
  position: relative;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`;

export const GameAreaComponent = ({ handleLoading, toggleStatusList }) => {
  const master = useSelector((state) => state.playerGameState.master);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const playerId = useSelector((state) => state.user.playerId);
  const timeTrial = useSelector((state) => state.user.timeTrial);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const isShowDialogMasterConfirmRequestPlease = useSelector(
    (state) => state.playerGameState.isShowDialogMasterConfirmRequestPlease
  );
  const isShowDialogWaitMasterConfirmRequestPlease = useSelector(
    (state) => state.playerGameState.isShowDialogWaitMasterConfirmRequestPlease
  );
  const isGChaoComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const currentRound = useSelector(
    (state) => state.playerGameState.currentRound
  );
  const instructorMessage = useSelector(
    (state) => state.playerGameState.instructorMessage
  );
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);
  const { t } = useTranslation();
  const freeHaftSpace = useSelector((state) => state.size.freeHaftSpace);

  const [closeGameInfo, setCloseGameInfo] = useState(false);
  const currentRoomJoinedId = useSelector(
    (state) => state.user.currentRoomJoinedId
  );

  const [isOpenRF, setOpenRF] = useState(false);
  const [masterPickingDialogIsShow, setMasterPickingDialogIsShow] =
    useState(false);

  const handleOpenRF = async (isRFCalled) => {
    setOpenRF(false);
    await functions.httpsCallable(REQUEST_PLEASE)({
      isUseRequestPlease: isRFCalled,
      gameId: gameId,
      currentRound,
    });
  };

  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (!expiredTrial(timeTrial) || !playerId) return;
    const intervalPlayTime = setInterval(() => trackPlayTime(playerId, 1), MIN);
    return () => {
      clearInterval(intervalPlayTime);
    };
  }, [timeTrial, playerId]);

  useEffect(() => {
    if (!currentRoomJoinedId) {
      // DISCONNECT_TWILIO remove twilio room if it exists
      dispatch({ type: CLEAR_ALL_TWILIO });
      dispatch({ type: CLEAR_ROOM });
      dispatch({ type: CLEAR_PLAYER_GAME_STATE });
      dispatch({ type: CLEAR_NARRATION });
      history.push(`/tables`);
    }
  }, [history, currentRoomJoinedId, dispatch]);

  /**
   * NOTE: Show dialog to user at the end of round to notify player that the master is considering to use master carf or not.
   * Dialog disappear when:
   * 1. Current player select "OK"
   * 2. The master (in other screen), confirm to use master card or not to use it.
   */
  useEffect(() => {
    const openDialog = async () => {
      if (isShowDialogMasterConfirmRequestPlease) {
        await sleep(G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST);
        setOpenRF(true);
      } else {
        setOpenRF(false);
      }
      if (isShowDialogWaitMasterConfirmRequestPlease) {
        await sleep(G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST);
        setMasterPickingDialogIsShow(true);
      } else {
        setMasterPickingDialogIsShow(false);
      }
    };
    openDialog();
  }, [
    isShowDialogMasterConfirmRequestPlease,
    isShowDialogWaitMasterConfirmRequestPlease,
  ]);

  const masterName = useMemo(
    () =>
      joinedPlayers.filter((player) => player && player.playerId === master)
        .length > 0
        ? joinedPlayers.filter(
            (player) => player && player.playerId === master
          )[0].nickName
        : "",
    [joinedPlayers, master]
  );

  const [confirmMessage, setConfirmMessage] = useState("");

  useEffect(() => {
    let message = t("narrations.masterPickingCard", {
      name:
        masterName.length > 15 ? masterName.slice(0, 15) + "..." : masterName,
    });
    if (isGChaoComputer === true && masterName === G_CHAOS) {
      message = clearGChaosComputerSanNickName(message);
    }
    setConfirmMessage(message);
  }, [isGChaoComputer, masterName, t]);

  return (
    <Container>
      <Spacer width={freeHaftSpace} />
      <GameLeft />
      <GameCenter />
      <GameRight
        handleLoading={handleLoading}
        toggleStatusList={toggleStatusList}
      />
      <Spacer />

      {/* Use master card */}
      <BasicDialog
        isOpen={isOpenRF}
        agreeText={t("common.yes")}
        closeText={t("tables.skip")}
        onCancel={() => handleOpenRF(false)}
        onAgree={() => handleOpenRF(true)}
        mainText={t("game.requestPlease")}
      />
      <ShowInfo
        isOpen={closeGameInfo}
        gameStatus={gameStatus}
        onClose={() => {
          setCloseGameInfo(false);
        }}
        value={t(`${instructorMessage}`)}
      />
      {/* Notifications */}
      <InstructorNotify />
      <OkDialog
        isOpen={masterPickingDialogIsShow}
        confirmText={confirmMessage}
        onAgree={() => setMasterPickingDialogIsShow(false)}
      />
    </Container>
  );
};

GameAreaComponent.propTypes = {
  handleLoading: PropTypes.func,
  toggleStatusList: PropTypes.func,
};

export const GameArea = React.memo(GameAreaComponent);
