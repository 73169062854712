import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  wrappContainer: {
    padding: "2rem",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    color: "#541513 !important",
  },
  titleBox: {
    width: "100%",
  },
  titleContent: {
    fontSize: "26px",
    fontWeight: "600",
    marginTop: "1rem",
    textAlign: "center",
    "@media (max-width: 768px)": {
      fontSize: "20px",
      marginTop: "0.5rem",
    },
  },
  description: {
    textAlign: "center",
    margin: "2rem 0",
    fontWeight: "500",
    "@media (max-width: 991px)": {
      fontSize: "13px",
    },
    "@media (max-width: 768px)": {
      margin: "1rem 0",
      fontSize: "10px",
    },
  },
  checkBoxWrapper: {
    display: "flex",
    flexDirection: "row !important",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
    marginBottom: "1.5rem",
  },
  checkBoxItemLabel: {
    fontSize: "14px !important",
    "@media (max-width: 991px)": {
      fontSize: "10px !important",
    },
    "@media (max-width: 768px)": {
      fontSize: "8px !important",
    },
  },
  remarks: {
    display: "flex",
    width: "100%",
    outline: "none",
  },
  remarksTitle: {
    marginRight: "1.5rem",
    width: "40px",
    "@media (max-width: 991px)": {
      fontSize: "12px !important",
    },
    "@media (max-width: 768px)": {
      fontSize: "10px !important",
      width: "25px",
    },
  },
  remarksContent: {
    width: "95%",
    height: "100px !important",
    fontSize: ".75rem",
    padding: "0.5rem",
    "@media (max-width: 991px)": {
      fontSize: ".5rem",
    },
    "@media (max-width: 768px)": {
      fontSize: ".5rem",
      height: "70px !important",
    },
  },
  buttons: {
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop: "2rem",
    "& > * ~ *": {
      marginLeft: "1rem",
    },
    "@media (max-width: 768px)": {
      marginTop: "1rem",
    },
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: "180px",
    height: "56px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: "7px 22px",
    border: "2px solid black",
    "@media (max-width: 991px)": {
      width: "150px",
      height: "50px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
      width: "100px",
      height: "30px",
    },
  },
  buttonRed: {
    borderRadius: "28px",
    backgroundColor: "#ce5931",
    color: "white",
    width: "180px",
    height: "56px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: "7px 22px",
    border: "2px solid black",
    "@media (max-width: 991px)": {
      width: "150px",
      height: "50px",
    },
    "@media (max-width: 768px)": {
      fontSize: "12px",
      width: "100px",
      height: "30px",
    },
  },
}));

export default useStyles;
