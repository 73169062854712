import { isEmpty } from "lodash";
import firebase from "../../services/firebase";

const CHAT_STATUS = "chatStatus";
const chatStatusCollection = firebase.firestore().collection(CHAT_STATUS);

/**
 * create thread data
 * @param {object} data create thread
 * @returns
 */
async function create(data = {}) {
  if (!isEmpty(data)) {
    return (await chatStatusCollection.add(data)).id;
  }
}

/**
 * get data by docId
 * @param {string} docId
 * @returns
 */
async function read(docId = "") {
  return (await chatStatusCollection.doc(docId).get()).data();
}

/**
 * get all players data
 * @returns
 */
async function readAll() {
  return chatStatusCollection.get();
}

/**
 * update data by docId
 * @param {string} docId
 * @param {object} data
 * @returns
 */
async function update(docId = "", data = {}) {
  if (docId && !isEmpty(data)) {
    return chatStatusCollection.doc(docId).update(data);
  }
}

async function deleteDocument(docPath) {
  if (docPath) {
    return chatStatusCollection.doc(docPath).delete();
  }
}

async function deleteCollection(collectionPath) {
  if (collectionPath) {
    const collectionData = await firebase
      .firestore()
      .collection(collectionPath)
      .get();
    return collectionData.docs.forEach((doc) => doc.ref.delete());
  }
}

export default {
  create,
  read,
  readAll,
  update,
  deleteDocument,
  deleteCollection,
};
