import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OkDialog from "../../pages/GameScreen/components/NewDialog/OkDialog";
import { MIN, STEP_NARRATION } from "../../store/constant";
import { SET_NEED_RELOAD } from "../../store/types";

const ReloadTracker = () => {
  const need_reload = useSelector((state) => state.playerGameState.needReload);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const timeoutTimer = useRef(null);
  const current_need_reload = useRef(need_reload);
  const [isShowReloadDialog, setIsShowReloadDialog] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const isTrackReload = () => {
      return [
        STEP_NARRATION.ED_STEP1,
        STEP_NARRATION.ED_STEP2,
        STEP_NARRATION.ED_STEP3,
        STEP_NARRATION.ED_STEP4,
        STEP_NARRATION.UM_STEP1,
        STEP_NARRATION.UM_STEP2,
        STEP_NARRATION.UM_STEP3,
        STEP_NARRATION.UM_STEP4,
        STEP_NARRATION.UM_STEP5,
        STEP_NARRATION.UM_STEP6,
        STEP_NARRATION.UM_STEP7,
        STEP_NARRATION.UM_STEP8,
      ].includes(stepId);
    };
    if (isTrackReload() && current_need_reload.current) {
      setIsShowReloadDialog(false);
      clearTimeout(timeoutTimer.current);
    }
  }, [stepId]);

  useEffect(() => {
    current_need_reload.current = need_reload;
    if (need_reload === true) {
      timeoutTimer.current = setTimeout(() => {
        setIsShowReloadDialog(true);
        clearTimeout(timeoutTimer.current);
      }, MIN);
    } else {
      clearTimeout(timeoutTimer.current);
      setIsShowReloadDialog(false);
    }
  }, [need_reload, dispatch]);

  const onOK = () => {
    clearTimeout(timeoutTimer.current);
    setIsShowReloadDialog(false);
    dispatch({ type: SET_NEED_RELOAD, payload: false });
  };

  // TODO: Auto reload when click OK
  return (
    <div>
      <OkDialog
        isOpen={isShowReloadDialog}
        onAgree={onOK}
        confirmText={t("reload.reload")}
        btnLabel={"OK"}
      />
    </div>
  );
};

export default ReloadTracker;
