import Link from "@mui/material/Link";
import moment from "moment";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import ButtonCustom from "../../../../components/Button";
import { Button } from "@mui/material";
import { getPaymentMethodList } from "../../../../store/actions/paymentAction";
import PlayerRepo from "../../../../store/repositories/player";
import { SET_LOADING_GLOBAL } from "../../../../store/types";
import useStyles, {
  CardExpiry,
  CardNumber,
  Container,
  ContractWrapper,
  CurrentCardWraper,
  GroupButton,
  InfoWrapper,
  Item,
  ItemWraper,
  PlanDetailWrapper,
  Title,
} from "./styles";

const ContractDetail = ({ handleCurrentPage, goBack }) => {
  const { t } = useTranslation();
  const classes = useStyles({});
  const playerId = useSelector((state) => state.user.playerId);
  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);
  const [paymentMethodList, setPaymentMethodList] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const getListMethod = async () => {
      dispatch({
        type: SET_LOADING_GLOBAL,
        payload: true,
      });
      const playerData = await PlayerRepo.read(playerId);
      const customerId = playerData?.paymentCustomerId;
      if (customerId) {
        const paymentMethodData = await getPaymentMethodList(customerId);
        setPaymentMethodList(paymentMethodData?.data?.data ?? {});
      }
      dispatch({
        type: SET_LOADING_GLOBAL,
        payload: false,
      });
    };
    getListMethod();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return playTimeInfo ? (
    <Container>
      <ContractWrapper>
        <Title style={{ color: "#541513", fontSize: "24px" }}>
          {t("paymentSettings.planDetail")}
        </Title>
        <GroupButton>
          <Button
            variant="contained"
            className={classes.PlanBtn}
            onClick={() => handleCurrentPage(1)}
            style={{ textTransform: "capitalize", backgroundColor: "#ce5931" }}
          >
            {t("paymentSettings.changePlan")}
          </Button>
        </GroupButton>
      </ContractWrapper>
      <PlanDetailWrapper style={{ color: "#541513", fontWeight: "bold" }}>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.planName")}
          </Item>
          {playTimeInfo?.statusFree ? (
            <Item>{t("paymentSettings.packageNameFree")}</Item>
          ) : (
            <Item>{t("paymentSettings." + playTimeInfo?.packageName)}</Item>
          )}
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.playTimeRemain")}
          </Item>
          {playTimeInfo?.statusFree ? (
            <Item>{t("paymentSettings.packagePlayTimesFree")}</Item>
          ) : (
            <Item>{playTimeInfo?.playTimes}</Item>
          )}
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.userName")}
          </Item>
          <Item>{playTimeInfo?.nickName}</Item>
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.userEmail")}
          </Item>
          <Item>{playTimeInfo?.email}</Item>
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.paymentMethod")}
          </Item>
          <Item>{t("paymentSettings.cardType")}</Item>
        </InfoWrapper>
        <InfoWrapper>
          <Item style={{ minHeight: "76px" }} className={classes.leftColumn}>
            {t("paymentSettings.cardInfo")}
          </Item>
          <Item>
            <ItemWraper>
              {paymentMethodList && paymentMethodList.length > 0 ? (
                paymentMethodList.map((item, i) => {
                  switch (item.card.brand) {
                    case "visa":
                      return (
                        <CurrentCardWraper className={classes.visa} key={i}>
                          <CardNumber>{item.card.last4}</CardNumber>

                          <CardExpiry>
                            {item?.card["exp_month"]}/{item?.card["exp_year"]}
                          </CardExpiry>
                        </CurrentCardWraper>
                      );
                    case "mastercard":
                      return (
                        <CurrentCardWraper
                          className={classes.masterCard}
                          key={i}
                        >
                          <CardNumber>{item.card.last4}</CardNumber>

                          <CardExpiry>
                            {item?.card["exp_month"]}/{item?.card["exp_year"]}
                          </CardExpiry>
                        </CurrentCardWraper>
                      );
                    case "jcb":
                      return (
                        <CurrentCardWraper className={classes.jcb} key={i}>
                          <CardNumber>{item.card.last4}</CardNumber>

                          <CardExpiry>
                            {item?.card["exp_month"]}/{item?.card["exp_year"]}
                          </CardExpiry>
                        </CurrentCardWraper>
                      );
                    case "diners":
                      return (
                        <CurrentCardWraper className={classes.diners} key={i}>
                          <CardNumber>{item.card.last4}</CardNumber>

                          <CardExpiry>
                            {item?.card["exp_month"]}/{item?.card["exp_year"]}
                          </CardExpiry>
                        </CurrentCardWraper>
                      );
                    case "discover":
                      return (
                        <CurrentCardWraper className={classes.discover} key={i}>
                          <CardNumber>{item.card.last4}</CardNumber>

                          <CardExpiry>
                            {item?.card["exp_month"]}/{item?.card["exp_year"]}
                          </CardExpiry>
                        </CurrentCardWraper>
                      );
                    case "amex":
                      return (
                        <CurrentCardWraper className={classes.amex} key={i}>
                          <CardNumber>{item.card.last4}</CardNumber>

                          <CardExpiry>
                            {item?.card["exp_month"]}/{item?.card["exp_year"]}
                          </CardExpiry>
                        </CurrentCardWraper>
                      );
                  }
                })
              ) : (
                <CurrentCardWraper>
                  <CardNumber>****</CardNumber>
                  <CardExpiry>{t("paymentSettings.emptyCard")}</CardExpiry>
                </CurrentCardWraper>
              )}
              <Link
                conmponent="button"
                underline="hover"
                sx={{
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  width: "100px",
                }}
                variant="outlined"
                onClick={() => handleCurrentPage(3)}
              >
                {t("paymentSettings.changeCardInfo")}
              </Link>
            </ItemWraper>
          </Item>
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.startDate")}
          </Item>
          <Item>{moment(playTimeInfo?.startDate).format("YYYY/MM/DD")}</Item>
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.nextPlanDate")}
          </Item>
          {playTimeInfo?.nextInvoiceDate && (
            <Item>
              {moment(playTimeInfo?.nextInvoiceDate).format("YYYY/MM/DD")}
            </Item>
          )}
        </InfoWrapper>
        <InfoWrapper>
          <Item className={classes.leftColumn}>
            {t("paymentSettings.endDate")}
          </Item>
          <Item>{moment(playTimeInfo?.endDate).format("YYYY/MM/DD")}</Item>
        </InfoWrapper>
      </PlanDetailWrapper>
      <GroupButton>
        <ButtonCustom
          className={classes.buttonBlue}
          onClick={goBack}
          text={t("paymentSettings.back")}
        />
        <Link
          conmponent="button"
          underline="hover"
          sx={{ cursor: "pointer", display: "flex", alignItems: "center" }}
          variant="outlined"
          onClick={() => handleCurrentPage(2)}
        >
          {t("paymentSettings.unsubscribeHere")}
        </Link>
      </GroupButton>
    </Container>
  ) : null;
};

ContractDetail.propTypes = {
  handleCurrentPage: PropTypes.func,
  goBack: PropTypes.func,
};

export default ContractDetail;
