import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { rejectNumberOfCallsOfPlayer } from "../../../../../store/actions/gameActions";
import { stopCurrentNarration } from "../../../../../store/actions/narrationActions";
// import { changeStepId } from "../../../../../store/actions/playerGameStateActions";
import { SCREEN_NARRATION } from "../../../../../store/constant";
import OkDialog from "../../NewDialog/OkDialog";

const Narration1314OverCall = () => {
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const numberOfCalls = useSelector(
    (state) => state.playerGameState.myState.numberOfCalls
  );
  // const dispatch = useDispatch();
  const [isShow, setIsShow] = useState(false);
  const [messageText, setMessageText] = useState();
  const playerId = useSelector((state) => state.user.playerId);
  const nickName = useSelector((state) => state.user.nickName);
  const roomId = useSelector((state) => state.room.table.roomId);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const { t } = useTranslation();
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const dispatch = useDispatch();
  useEffect(() => {
    // just close when player click close.
    const show = [
      SCREEN_NARRATION.SG_STEP10_OVER_CALL,
      SCREEN_NARRATION.SG_STEP11_OVER_CALL,
    ].includes(confirmId);
    const isNotGChaos =
      currentPlayersId &&
      currentPlayersId[3] &&
      currentPlayersId[3] !== playerId;
    show && isNotGChaos && setIsShow(show);

    if (SCREEN_NARRATION.SG_STEP10_OVER_CALL === confirmId) {
      setMessageText(t("narrations.narration13"));
    }
    if (SCREEN_NARRATION.SG_STEP11_OVER_CALL === confirmId) {
      setMessageText(t("narrations.narration14"));
    }
  }, [confirmId, currentPlayersId, playerId, t]);

  const handleAcceptRejectDeclareCall = () => {
    setIsShow(false);
    dispatch(stopCurrentNarration());
    //   move to step 6
    // dispatch(changeStepId("SG_STEP_6"));
    //TODO call sever reject
    if (-1 !== numberOfCalls) {
      rejectNumberOfCallsOfPlayer(playerId, gameId, roomId, nickName);
    }
  };

  return (
    <OkDialog
      isOpen={isShow}
      btnLabel={t("narrationSetting.OK")}
      onAgree={handleAcceptRejectDeclareCall}
      confirmText={messageText}
    ></OkDialog>
  );
};
export default React.memo(Narration1314OverCall);
