import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dialogWrap: {
    padding: "20px 10px",
  },
  paper: {
    overflow: "unset !important",
  },
  icon: {
    color: "red",
    fontSize: "24px",
    marginRight: "0.5rem",
  },
  title: {
    fontSize: "20px",
  },
  description: {
    textAlign: "center",
  },
  btns: {
    width: "100%",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",
    padding: 0,
    marginBottom: "1rem",
  },
  buttonRed: {
    borderRadius: "28px",
    backgroundColor: "#ce5931 !important",
    color: "white !important",
    width: "120px",
    height: "40px",
    textTransform: "capitalize !important",
    fontSize: "16px",
    padding: "7px 22px",
    border: "2px solid black",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d !important",
    color: "white !important",
    width: "120px",
    height: "40px",
    textTransform: "capitalize !important",
    fontSize: "16px",
    padding: "7px 22px",
    border: "2px solid black",
  },
}));

export default useStyles;
