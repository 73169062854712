import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMasterCard } from "../../../../store/actions/gameActions";
import {
  checkPlayerIsSelected,
  playerSelectMC,
  updateMasterGrandCross,
} from "../../../../store/actions/playerGameStateActions";
import {
  DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD,
  SCREEN_NARRATION,
} from "../../../../store/constant";
import { HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST } from "../../../../store/types";
import FlippedCard from "../FlippedCard";
import {
  CardSelectedWrapper,
  CardWrapper,
  Container,
  Nickname,
  TitleWrapper,
} from "./styles";
import UpdateTurnPlayerSelectMasterCard from "./UpdateTurnPlayerSelectMasterCard";

const ShowMasterCards = () => {
  const masterGrandCross = useSelector(
    (state) => state.playerGameState.masterGrandCross
  );
  const isMyTurn = useSelector((state) => state.playerGameState.isMyTurn);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const canSelectMC = useSelector((state) => state.playerGameState.canSelectMC);
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);
  const popupId = useSelector((state) => state.narration.narrationPopup);
  const screenId = useSelector((state) => state.narration.narrationScreen);
  const size = useSelector((state) => state.size);

  const [innerSize, setInnerSize] = useState({});
  const [showOneCard, setShowOneCard] = useState(false);
  const [showAllCard, setShowAllCard] = useState(false);
  const [isShowMasterCard, setIsShowMasterCard] = useState(false);
  const [gChaosSelectAllMC, setGChaosSelectAllMC] = useState(false);
  const [isDisplayNicknameGChaos, setIsDisplayNicknameGChaos] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [
    isDisplayNickNameGChaosSelectAll,
    setIsDisplayNickNameGChaosSelectAll,
  ] = useState(false);
  const [masterCards, setMasterCards] = useState([]);

  const nickName = useSelector((state) => state.user.nickName);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const hShipWidth = useSelector((state) => state.size.hShipWidth);
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  // const roomId = useSelector((state) => state.room.roomId);
  // const numberOfGame = useSelector((state) => state.room.table.numberOfGame);
  const [hideCount, setHideCount] = useState(0);
  const dispatch = useDispatch();

  const handleSelectCard = async (card) => {
    if (!canSelectMC) return;
    const isPlayerSelected = checkPlayerIsSelected(
      nickName,
      masterGrandCross,
      isMyTurn,
      card
    );
    if (isPlayerSelected) return;
    const playerIdx = card.playerIdx;
    dispatch(playerSelectMC(playerIdx, nickName, myIndex));
    selectMasterCard(playerId, playerIdx, stepId);
  };

  useEffect(() => {
    const show = [
      SCREEN_NARRATION.UM_SHOW_USE_MASTER_WINNER_CONFIRM,
      SCREEN_NARRATION.UM_SHOW_MASTER_CARD,
      SCREEN_NARRATION.UM_SHOW_MASTER_CARD_ALL,
    ].includes(popupId);
    if (show) {
      if (hideCount === 0) {
        dispatch({ type: HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST });
        setHideCount(1);
      }
      setIsShowMasterCard(true);
    }
  }, [popupId, dispatch, masterGrandCross, hideCount]);

  useEffect(() => {
    if (SCREEN_NARRATION.UM_SHOW_MASTER_CARD_1 === screenId) {
      setShowOneCard(true);
    }
    if (SCREEN_NARRATION.UM_SHOW_MASTER_CARD_ALL === screenId) {
      setShowAllCard(true);
    }
    if (SCREEN_NARRATION.UM_G_CHAOS_SELECT_ALL_CARD === screenId) {
      setIsDisplayNickNameGChaosSelectAll(true);
    }
    if (SCREEN_NARRATION.UM_SHOW_MASTER_CARD_ALL === screenId) {
      setIsFlipped(true);
    }
    if (SCREEN_NARRATION.UM_G_CHAOS_SELECT_CARD === screenId) {
      dispatch(updateMasterGrandCross());
      localStorage.setItem(DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD, true);
    }
    if (!isDisplayNicknameGChaos)
      setIsDisplayNicknameGChaos(
        localStorage.getItem(DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD)
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [screenId, dispatch]);

  useEffect(() => {
    if (size) {
      const fontSize = Math.round(16 * size.minScale);
      setInnerSize({ fontSize });
    } else {
      const fontSize = 16;
      setInnerSize({ fontSize });
    }
  }, [size]);

  useEffect(() => {
    const isSetMasterCards = masterGrandCross && masterGrandCross.length > 4;
    if (!isSetMasterCards) return;
    const _masterCards = masterGrandCross.slice(4);
    let countGChaosSelectCard = 0;
    for (const card of _masterCards) {
      if (card.playerIdx === 3) ++countGChaosSelectCard;
    }
    if (countGChaosSelectCard === 2) {
      setGChaosSelectAllMC(true);
    }
    setMasterCards(_masterCards);
  }, [masterGrandCross, dispatch]);

  const checkShowMasterCards = useMemo(() => {
    return isShowMasterCard && masterCards.length > 0;
  }, [isShowMasterCard, masterCards]);

  const handleSetNickName = (nickname) => {
    if (nickname && nickname.length > 0) {
      if (nickname !== "G-Chaos" && nickname.length > 10) {
        return nickname.slice(0, 7) + "...";
      }
      return nickname;
    }
    return "";
  };

  if (!checkShowMasterCards) return null;

  return (
    <Container top={shipSpace} width={hShipWidth} id="show-master-card">
      <UpdateTurnPlayerSelectMasterCard popupId={popupId} />
      <TitleWrapper>
        <p></p>
      </TitleWrapper>
      <CardWrapper>
        {joinedPlayers &&
          masterCards.map((c, index) => {
            if (gChaosSelectAllMC) {
              return (
                <CardSelectedWrapper key={index}>
                  <Nickname
                    fontSize={innerSize.fontSize}
                    hasNickname={c.playerIdx > -1}
                    isDisplayNickName={isDisplayNickNameGChaosSelectAll}
                  >
                    <p style={{ fontSize: innerSize.fontSize }}>
                      {handleSetNickName(
                        c.playerIdx > -1 && joinedPlayers[c.playerIdx]?.nickName
                      )}
                    </p>
                  </Nickname>
                  <FlippedCard
                    flipped={
                      0 === index ? showOneCard || showAllCard : showAllCard
                    }
                    src={`/cards/${c.suit}${c.value}.jpg`}
                    onClickSelectCard={() => {}}
                    size={size}
                  />
                </CardSelectedWrapper>
              );
            }
            return (
              <CardSelectedWrapper key={index}>
                <Nickname
                  hasNickname={c.playerIdx > -1}
                  isDisplayNickName={
                    (c.playerIdx === 3 && isDisplayNicknameGChaos) ||
                    (c.playerIdx >= 0 && c.playerIdx !== 3)
                  }
                  fontSize={innerSize.fontSize}
                >
                  <p style={{ fontSize: innerSize.fontSize }}>
                    {handleSetNickName(
                      c.playerIdx > -1 && joinedPlayers[c.playerIdx]?.nickName
                    )}
                  </p>
                </Nickname>
                <FlippedCard
                  flipped={isFlipped}
                  src={`/cards/${c.suit}${c.value}.jpg`}
                  onClickSelectCard={() => handleSelectCard(c)}
                  size={size}
                />
              </CardSelectedWrapper>
            );
          })}
      </CardWrapper>
    </Container>
  );
};
export default React.memo(ShowMasterCards);
