import firebase from "firebase/app";
import "firebase/auth";
import "firebase/database";
import "firebase/firestore";
import "firebase/functions";
import "firebase/messaging";
import "firebase/storage";
import { IS_DEBUGGER, TOKYO_REGION } from "../store/constant";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  vapidKey: process.env.REACT_APP_VAPID_KEY,
};

firebase.initializeApp(firebaseConfig);

export const getFunctions = () => {
  try {
    let functions = null;
    if (IS_DEBUGGER) {
      functions = firebase.app().functions(TOKYO_REGION);
      try {
        firebase.app().functions(TOKYO_REGION).useEmulator("localhost", 5001);
        firebase.auth().useEmulator("http://localhost:9099");
        firebase.database().useEmulator("localhost", 9002);
        firebase.firestore().useEmulator("localhost", 8082);
      } catch (error) {
        // console.log(error);
      }
    } else {
      functions = firebase.app().functions(process.env.REACT_APP_FUNCTION_URL);
    }
    return functions;
  } catch (error) {
    //
  }
};

export const getMessaging = async () => {
  let messaging = null;
  try {
    messaging = firebase.messaging();
    await messaging.getToken(firebaseConfig);
  } catch (error) {
    // console.log(error);
  }
  return messaging;
};

getMessaging();

export const config = firebaseConfig;

export default firebase;
