import { Avatar, Box } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { GAME_STATUS, TUTORIAL_MODE } from "../../../../store/constant";
import { COLOR_ORANGE } from "../constants";
import VoiceComponent from "./component/VoiceComponent";
import {
  AvatarWrapper,
  Container,
  MasterTurn,
  Owner,
  ProfileAvatar,
  ProfileContent,
  ProfileHeader,
  ProfileHeaderContent,
  ProfileHeaderOrange,
  ProfileInfo,
  // StyleOctagon,
  Video,
} from "./styles";
const useStyles = makeStyles(() => ({
  videoContainer: {
    width: "100%",
    height: "75%",
    position: "relative",
    boxSizing: "border-box",
    "& > video": {
      position: "absolute",
      backgroundSize: "100% 100%",
      top: -2,
      left: 0,
      bottom: 0,
      right: 0,
      width: "100%",
      height: "100%",
      margin: "auto",
    },
  },
  videoTurn: {
    boxShadow: (props) => `0 0 0 ${props.turnResize}px ${COLOR_ORANGE}`,
  },
  videoOnline: {
    // border: "3px solid #4caf50",
  },
  videoOffline: {
    // border: "3px solid  #ffff66",
  },
}));

const ProfileMemo = ({
  position,
  className,
  profile,
  numberOfCalls,
  isMyTurn,
  positionTurn,
  isTutorial = false,
}) => {
  const { t } = useTranslation();
  const { tutorialMode } = useSelector((state) => state.playerGameState);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const shipWidth = useSelector((state) => state.size.vShipWidth);
  const master = useSelector((state) => state.playerGameState.master);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const isShowMaster = useSelector(
    (state) => state.playerGameState.isShowMaster
  );

  const width = useMemo(() => shipWidth, [shipWidth]);
  const turnResize = width / 40;
  const minScale = useSelector((state) => state.size.minScale);
  const fontSizeBase16 = useMemo(
    () => Math.round((minScale ?? 1) * 16),
    [minScale]
  );
  const fontSizeBase12 = useMemo(
    () => Math.round((minScale ?? 1) * 12),
    [minScale]
  );
  const fontSizeBase13 = useMemo(
    () => Math.round((minScale ?? 1) * 13),
    [minScale]
  );
  const fontSizeBase14 = useMemo(
    () => Math.round((minScale ?? 1) * 14),
    [minScale]
  );
  const orangePadding = useMemo(
    () => Math.round((minScale ?? 1) * 3),
    [minScale]
  );
  const videoSize = useMemo(() => Math.round((minScale ?? 1) * 20), [minScale]);
  const videoTopLeft = useMemo(
    () => Math.round((minScale ?? 1) * 6),
    [minScale]
  );
  const ownerFontSize = useMemo(
    () => Math.round((minScale ?? 1) * 16),
    [minScale]
  );
  const videoTextLeft = useMemo(
    () => Math.round(videoTopLeft * 2 + videoSize),
    [videoTopLeft, videoSize]
  );
  const avatarWidth = useMemo(() => Math.round(width / 3), [width]);
  const starSize = useMemo(() => Math.round((minScale ?? 1) * 30), [minScale]);
  const { nickName, avatar, guestName, playerId, isOwner } = profile;
  const videoGame = useSelector((state) => state.videoGame);
  const cameRef = useRef();
  const [isVideo, setIsVideo] = useState(false);
  const [isAudio, setIsAudio] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  useEffect(() => {
    const cardChild = cameRef.current;
    cardChild.innerText = "";
    if (!videoGame || !videoGame[playerId]) {
      setIsVideo(false);
      setIsAudio(false);
      setIsOnline(false);
      return;
    }
    const cameInfo = videoGame[playerId];
    setIsVideo(cameInfo?.isVideo || false);
    cameInfo?.isVideo && cardChild.appendChild(cameInfo?.video);
    setIsAudio(cameInfo?.isAudio || false);
    cameInfo?.isAudio && cardChild.appendChild(cameInfo?.audio);

    setIsOnline(cameInfo?.isOnline);
  }, [videoGame, playerId]);

  const classes = useStyles({
    turnResize: turnResize,
  });
  const tipLeftRight = useMemo(() => {
    if (position === "left") {
      if (isMyTurn === true) {
        return `right: calc(100% + ${Math.round(shipSpace / 2 - 1)}px)`;
      }
      return "right: 100%";
    }
    if (position === "right") {
      if (isMyTurn === true) {
        return `left: calc(100% + ${Math.round(shipSpace / 2 - 1)}px)`;
      }
      return "left: 100%";
    }
  }, [position, isMyTurn, shipSpace]);

  const leftRightArrow = useMemo(() => {
    if (positionTurn === "right") {
      return `bottom: calc(100% + ${Math.round(shipSpace / 2)}px);
              left: 50%;
              transform: translateX(-50%);
              border-top: ${shipSpace * 1.25}px solid ${COLOR_ORANGE};
              border-right: ${shipSpace * 1.25}px solid transparent;
              border-left: ${shipSpace * 1.25}px solid transparent;`;
    }
    if (positionTurn === "left") {
      return `bottom: calc(100% + ${Math.round(shipSpace / 2)}px);
              left: 50%;
              transform: translateX(-50%);
              border-top: ${shipSpace * 1.25}px solid ${COLOR_ORANGE};
              border-right: ${shipSpace * 1.25}px solid transparent;
              border-left: ${shipSpace * 1.25}px solid transparent;
              `;
    }
    if (positionTurn === "top") {
      return `
                right:calc(100% - ${Math.round(shipSpace / 1.5)}px);
                top: 50%;
                transform: translateY(-50%);  
                border-top: ${shipSpace * 1}px solid transparent;
                border-bottom: ${shipSpace * 1}px solid transparent;
                border-left: ${shipSpace * 1.25}px solid ${COLOR_ORANGE};
                border-right: ${shipSpace * 1.25}px solid transparent;
                
                `;
    }
    if (positionTurn === "bottom") {
      return `top: 50%;
              transform: translateY(-50%); 
              left: calc(100% - ${Math.round(shipSpace / 1.5)}px);
              border-top: ${shipSpace * 1}px solid transparent;
              border-bottom: ${shipSpace * 1}px solid transparent;
              border-right: ${shipSpace * 1.25}px solid ${COLOR_ORANGE};
              border-left: ${shipSpace * 1.25}px solid transparent;
              `;
    }
  }, [positionTurn, shipSpace]);

  const boxTurn = useMemo(() => {
    if (positionTurn === "right") {
      return `left: 50%;
              transform: translateX(-50%);
              bottom: calc(100% + ${Math.round(shipSpace * 1.25)}px);
             `;
    }
    if (positionTurn === "left") {
      return `left: 50%;
              transform: translateX(-50%); 
              bottom: calc(100% + ${Math.round(shipSpace * 1.25)}px);
      `;
    }
    if (positionTurn === "top") {
      return `top: 50%; 
              right:calc(100% + ${Math.round(shipSpace * 1.25)}px);
              transform: translateY(-50%); 
              `;
    }
    if (positionTurn === "bottom") {
      return ` top: 50%;
              transform: translateY(-50%);
              left: calc(100% + ${Math.round(shipSpace * 1.25)}px);
      `;
    }
  }, [positionTurn, shipSpace]);

  const yourTurn = useMemo(() => {
    return t("tables.yourTurn");
  }, [t]);

  const isPlayerMaster = useMemo(() => {
    if (gameStatus !== GAME_STATUS.PLAYING || !isShowMaster) return false;
    return master === playerId;
  }, [gameStatus, master, playerId, isShowMaster]);
  return (
    <Container
      className={`${className} ${isMyTurn ? classes.videoTurn : ""}`}
      width={width}
      height={width}
      isTutorial={isTutorial}
    >
      <ProfileHeader
        positionTurn={positionTurn}
        fontSize={fontSizeBase16}
        leftRightArrow={leftRightArrow}
        boxTurn={boxTurn}
        isMyTurn={isMyTurn}
        tipSize={shipSpace}
        yourTurn={yourTurn}
      >
        <ProfileHeaderContent fontSize={fontSizeBase16} starSize={starSize}>
          <ProfileHeaderOrange padding={orangePadding}>
            {!(
              numberOfCalls === -1 ||
              numberOfCalls === undefined ||
              tutorialMode === TUTORIAL_MODE.NO_CALL
            ) && (
              <span
                style={{ fontSize: `${fontSizeBase16}px` }}
              >{`${numberOfCalls}`}</span>
            )}
            {tutorialMode && tutorialMode === TUTORIAL_MODE.NO_CALL ? (
              <span
                style={{
                  fontSize: `${fontSizeBase12}px`,
                  whiteSpace: "nowrap",
                }}
              >{`${t("game.tutorialNoCall")}`}</span>
            ) : (
              <span
                style={{
                  fontSize: `${fontSizeBase12}px`,
                  whiteSpace: "nowrap",
                }}
              >{`${t("numberOfCalls.call")}`}</span>
            )}
          </ProfileHeaderOrange>
          <div
            style={{
              // display: "flex",
              // flexDirection: "row",
              // alignItems: "center",
              // justifyContent: "center",
              // flex: 1,
              margin: "auto",
            }}
          >
            {/* <StyleOctagon size={starSize} /> */}
            <p
              style={{
                whiteSpace: "nowrap",
              }}
            >
              {guestName || ""}
            </p>
          </div>
        </ProfileHeaderContent>
      </ProfileHeader>

      <ProfileContent
        style={{
          display: "flex",
          flexDirection: "column",
          alignItem: "center",
          position: "relative",
          width: "100%",
          height: "100%",
        }}
        isMyTurn={isMyTurn}
        isVideo={isVideo}
        position={position}
        tipSize={shipSpace}
        leftRightText={tipLeftRight}
      >
        <Box
          ref={cameRef}
          display={!isVideo ? "none" : ""}
          className={`${classes.videoContainer}`}
        />
        {isVideo && (
          <ProfileAvatar>
            {isOwner && (
              <Tooltip title="Owner" placement="top-start" arrow>
                <Owner
                  width={videoSize}
                  height={videoSize}
                  top={videoTopLeft}
                  left={videoTopLeft}
                  fontSize={ownerFontSize}
                >
                  O
                </Owner>
              </Tooltip>
            )}
          </ProfileAvatar>
        )}
        {!isVideo && (
          <ProfileAvatar
            name-tag="profile"
            className={`${
              isOnline ? classes.videoOnline : classes.videoOffline
            }`}
          >
            <AvatarWrapper isMyTurn={isMyTurn} width={avatarWidth}>
              <Avatar
                style={{ width: "100%", height: "100%" }}
                src={avatar || ""}
                crossOrigin="anonymous"
              />
            </AvatarWrapper>
            <Video
              src="/assets/video_off2.png"
              width={videoSize}
              height={videoSize}
              top={videoTopLeft}
              left={videoTopLeft}
            />
            <p
              style={{
                left: videoTextLeft,
                top: videoTopLeft,
                position: "absolute",
                fontSize: fontSizeBase13,
              }}
            >
              Video
            </p>
            {isOwner && (
              <Tooltip title="Owner" placement="top-start" arrow>
                <Owner
                  width={videoSize}
                  height={videoSize}
                  top={videoTopLeft}
                  left={videoTopLeft}
                  fontSize={ownerFontSize}
                >
                  O
                </Owner>
              </Tooltip>
            )}
          </ProfileAvatar>
        )}
        {position === "left" && (
          <ProfileInfo padding={videoTopLeft}>
            <VoiceComponent
              src={isAudio ? "/assets/voice_on2.png" : "/assets/voice_off2.png"}
              width={videoSize}
              height={videoSize}
            />
            <p
              style={{
                fontSize: `${fontSizeBase14}px`,
              }}
            >
              {nickName || ""}
            </p>
            {isPlayerMaster && (
              <MasterTurn
                width={videoSize}
                height={videoSize}
                minScale={minScale}
              >
                <p
                  style={{
                    fontSize: `${fontSizeBase14}px`,
                  }}
                >
                  {t("tables.master")}
                </p>
              </MasterTurn>
            )}
          </ProfileInfo>
        )}
        {position === "right" && (
          <ProfileInfo padding={videoTopLeft}>
            <VoiceComponent
              src={isAudio ? "/assets/voice_on2.png" : "/assets/voice_off2.png"}
              width={videoSize}
              height={videoSize}
            />
            <p
              style={{
                fontSize: `${fontSizeBase14}px`,
              }}
            >
              {nickName || ""}
            </p>
            {isPlayerMaster && (
              <MasterTurn
                width={videoSize}
                height={videoSize}
                minScale={minScale}
              >
                <p
                  style={{
                    fontSize: `${fontSizeBase16}px`,
                  }}
                >
                  {t("tables.master")}
                </p>
              </MasterTurn>
            )}
          </ProfileInfo>
        )}
      </ProfileContent>
    </Container>
  );
};

ProfileMemo.propTypes = {
  position: PropTypes.string,
  positionTurn: PropTypes.string,
  className: PropTypes.any,
  numberOfCalls: PropTypes.number,
  profile: PropTypes.object,
  isMyTurn: PropTypes.bool,
  title: PropTypes.string,
  number: PropTypes.number,
  numberString: PropTypes.string,
  isTutorial: PropTypes.bool,
};
export const Profile = React.memo(ProfileMemo);
