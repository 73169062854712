import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import PropTypes from "prop-types";
import { STD_SIZE } from "../../../../store/constant";

const SpacerStyled = styled.div`
  ${(props) => props.widthText};
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
`;

const SpaceBottom = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  background: #311c0a;
`;

const Spacer = React.memo(function SpacerComponent({ width }) {
  const widthText = useMemo(
    () => (width !== undefined ? `width: ${width}px` : "flex: 1"),
    [width]
  );
  const [height, setHeight] = useState(
    STD_SIZE.STD_H_SHIP_HEIGHT + 2 * STD_SIZE.STD_SHIP_SPACE
  );
  const bottomHeight = useSelector((state) => state.size.bottomHeight);

  useEffect(() => {
    if (bottomHeight) {
      setHeight(bottomHeight);
    }
  }, [bottomHeight]);
  return (
    <SpacerStyled widthText={widthText}>
      <SpaceBottom height={height} />
    </SpacerStyled>
  );
});

Spacer.propTypes = {
  width: PropTypes.number,
};

export default Spacer;
