import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { STD_SIZE } from "../../../../../../store/constant";

const HBottomStarCards = ({
  src,
  zIndex,
  marginLeft,
  isRotate,
  isEve1Card,
  size,
  isBreakThrough1Call,
}) => {
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (size) {
      const left = isBreakThrough1Call
        ? `calc(50% - ${Math.round(size?.cardWidth / 2)}px)`
        : `${marginLeft}px`;

      const rotate = `rotate(${0 + (isRotate ? 90 : 0)}deg)`;
      const translate = `translate(-${
        isRotate ? Math.round(size?.cardHeight / 2 - size?.cardWidth / 2) : 0
      }px,0)`;
      setInnerSize({ left, rotate, translate });
    } else {
      const left = isBreakThrough1Call
        ? `calc(50% - ${Math.round(size?.cardWidth / 2)}px)`
        : `${marginLeft}px`;

      const rotate = `rotate(${0 + (isRotate ? 90 : 0)}deg)`;
      const translate = `translate(-${
        isRotate
          ? Math.round(
              STD_SIZE.STD_CARD_HEIGHT / 2 - STD_SIZE.STD_CARD_WIDTH / 2
            )
          : 0
      }px,0)`;
      setInnerSize({ left, rotate, translate });
    }
  }, [marginLeft, isRotate, isEve1Card, size, isBreakThrough1Call]);

  return (
    <div
      style={{
        width: size?.cardWidth,
        height: size?.cardHeight,
        borderRadius: "4%",
        transform: `${innerSize.rotate} ${innerSize.translate}`,
        zIndex: zIndex,
        position: "absolute",
        left: innerSize.left,
        boxShadow: "0 0 3px black",
      }}
    >
      <img
        src={src}
        style={{
          objectFit: "fill",
          borderRadius: "4%",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};

HBottomStarCards.propTypes = {
  src: PropTypes.elementType,
  zIndex: PropTypes.number,
  marginLeft: PropTypes.any,
  size: PropTypes.any,
  isRotate: PropTypes.bool,
  isEve1Card: PropTypes.bool,
  isBreakThrough1Call: PropTypes.bool,
};

export default HBottomStarCards;
