import { Dialog, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import PaperComponent from "../../PaperComponent";
// import CloseIcon from "@material-ui/icons/Close";
import { useSelector } from "react-redux";
import { STD_SIZE } from "../../../../../store/constant";

const useStyles = makeStyles(() => ({
  toolBar: {
    height: "28px",
    backgroundColor: "#63baea",
    "&:active": {
      cursor: "move",
    },
    "@media (max-width: 1024px)": {
      height: "20px",
    },
  },
  closeIcon: {
    color: "black",
    fontSize: 12,
  },
  iconBtn: {
    backgroundColor: "white !important",
    padding: "5px",
    float: "right",
    marginRight: 15,
    marginTop: 3,
    // "@media (max-width: 1024px)": {
    //   margin: "3px",
    // },
  },
  PaperProps: {
    overflow: "hidden !important",
    borderRadius: "20px 20px 35px 35px",
    backgroundColor: "#f7f7f7",
    left: "35%",
    bottom: "35%",
    fontSize: (props) => `${props.fontSize}px`,
    "@media (max-width: 1280px)": {
      left: "33%",
      bottom: "30%",
    },
    "@media (max-width: 1024px)": {
      borderRadius: "20px 20px 30px 30px",
      left: "30%",
      bottom: "30%",
    },
  },
  wrapper: {
    width: (props) => `${props.wrapperWidth}px`,
    minHeight: "120px",
    overflow: "hidden",
    boxSizing: "border-box",
  },
}));
const MessDialog = (props) => {
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  const { isOpen, mainText } = props;
  useEffect(() => {
    if (size) {
      const contentFontSize = Math.round(16 * size.minScale);
      const wrapperWidth = Math.round(size.hShipWidth);
      const wrapperHeight = Math.round(size.hShipHeight);
      setInnerSize({ contentFontSize, wrapperWidth, wrapperHeight });
    } else {
      const contentFontSize = Math.round(16 * 1);
      const wrapperWidth = Math.round(STD_SIZE.STD_SHIP_WIDTH);
      const wrapperHeight = Math.round(STD_SIZE.STD_SHIP_HEIGHT);
      setInnerSize({ contentFontSize, wrapperWidth, wrapperHeight });
    }
  }, [size]);

  const classes = useStyles({
    fontSize: innerSize.contentFontSize,
    wrapperWidth: innerSize.wrapperWidth,
  });

  return (
    <Dialog
      hideBackdrop={true}
      open={isOpen}
      PaperProps={{ classes: { root: classes.PaperProps } }}
      PaperComponent={PaperComponent}
      id={"mess-dialog"}
    >
      <div className={classes.wrapper}>
        <div id="draggable-dialog-title" className={classes.toolBar}>
          {/* <IconButton className={classes.iconBtn} onClick={() => onClose()}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton> */}
        </div>
        <div
          style={{
            width: "100%",
            height: "76%",
            padding: "1em 1em 1em",
            boxSizing: "border-box",
            overflowY: "auto",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "85%",
              boxSizing: "border-box",
              fontWeight: 600,
              textAlign: "center",
            }}
            dangerouslySetInnerHTML={{ __html: mainText }}
          ></div>
        </div>
      </div>
    </Dialog>
  );
};
MessDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  mainText: PropTypes.string,
};
export default React.memo(MessDialog);
//450 x 205
