const EMAIL = "email";
const PASSWORD = "password";
const CONFIRM_PASSWORD = "confirmPassword";
const NEW_PASSWORD = "newPassword";
const NEW_PASSWORD_CONFIRM = "newPasswordConfirm";
const CURRENT_PASSWORD = "currentPassword";
const NICKNAME = "nickName";
const IS_REQUIRED = "validate.isRequired";
const AMOUNT = "amount";
const INVOICE_NUMBER_CODE = "paymentGatewayInvoiceNumber";
const COMPLETE_DATE = "completeDate";
const CONTACT_TYPE = "contactType";
const CONTENT = "content";
const NUMBER_PHONE = "numberPhone";
const FULL_NAME = "fullName";

export const validate = {
  ok: "",
  invalidEmail: "validate.invalidEmail",
  passwordIsRequire: "validate.passwordIsRequire",
  passwordNotMatch: "validate.passwordNotMatch",
  passwordTooShort: "validate.passwordTooShort",
  containSpecialCharacters: "validate.containSpecialCharacters",
  notHaveLowerCase: "validate.notHaveLowerCase",
  notHaveUpperCase: "validate.notHaveUpperCase",
  notHaveNumber: "validate.notHaveNumber",
  newPasswordConfirmNotMatch: "validate.newPasswordConfirmNotMatch",
  notNumber: "validate.notNumber",
  lengthMoreThan20: "validate.lengthMoreThan20",
  lengthMoreThan500: "validate.lengthMoreThan500",
  invalidPhone: "validate.invalidPhone",
};

export const isEmail = (email) => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
};

export const isPhone = (numberPhone) => {
  const mobile = "0[789]0-?\\d{4}-?\\d{4}";
  const regex = new RegExp("^" + mobile + "$");
  return regex.test(numberPhone);
};

export const passwordValidate = (password) => {
  if (password.length < 8) {
    return validate.passwordTooShort;
  }
  if (password.match(/[!@#$%^&*(),.?":{}|<>]/)) {
    return validate.containSpecialCharacters;
  }
  if (!password.match(/[a-z]/)) {
    return validate.notHaveLowerCase;
  }
  if (!password.match(/[A-Z]/)) {
    return validate.notHaveUpperCase;
  }
  if (!password.match(/[0-9]/)) {
    return validate.notHaveNumber;
  }
  return validate.ok;
};

export const match = (password, confirmPassword) => {
  return password === confirmPassword;
};

export const checkTextLength = (text, number) => {
  if (!text) return IS_REQUIRED;
  return text.length < number ? "" : validate[`lengthMoreThan${number}`];
};

export const validateEmail = (email) => {
  if (!email) return IS_REQUIRED;
  return isEmail(email) ? "" : validate.invalidEmail;
};

export const validatePhone = (phone) => {
  if (!phone) return IS_REQUIRED;
  return isPhone(phone) ? "" : validate.invalidPhone;
};

export const validatePassword = (password) => {
  if (!password) return validate.passwordIsRequire;
  return passwordValidate(password);
};

export const validatePasswordMatch = (password, confirmPassword) => {
  return password && confirmPassword && password === confirmPassword
    ? ""
    : validate.passwordNotMatch;
};

export const validateNumber = (value) => {
  return !isNaN(value) && Number(value) >= 0 ? "" : validate.notNumber;
};

export const registerValidateInput = (fieldValues) => {
  let errors = {};
  if (EMAIL in fieldValues) errors[EMAIL] = validateEmail(fieldValues.email);
  if (PASSWORD in fieldValues)
    errors[PASSWORD] = validatePassword(fieldValues[PASSWORD]);
  if (CONFIRM_PASSWORD in fieldValues) {
    errors[CONFIRM_PASSWORD] = validatePasswordMatch(
      fieldValues[PASSWORD],
      fieldValues[CONFIRM_PASSWORD]
    );
  }
  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};

export const loginValidateInput = (fieldValues) => {
  let errors = {};
  if (EMAIL in fieldValues) errors[EMAIL] = validateEmail(fieldValues[EMAIL]);
  if (PASSWORD in fieldValues)
    errors[PASSWORD] = fieldValues[PASSWORD] ? "" : IS_REQUIRED;

  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};

export const settingValidateInput = (fieldValues) => {
  let errors = {};
  if (NICKNAME in fieldValues)
    errors[NICKNAME] = fieldValues[NICKNAME] ? "" : IS_REQUIRED;
  if (EMAIL in fieldValues && fieldValues.email)
    errors[EMAIL] = validateEmail(fieldValues[EMAIL]);

  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};

export const changePasswordValidateInput = (fieldValues) => {
  let errors = {};
  if (CURRENT_PASSWORD in fieldValues)
    errors[CURRENT_PASSWORD] = !fieldValues[CURRENT_PASSWORD]
      ? IS_REQUIRED
      : "";
  if (NEW_PASSWORD in fieldValues)
    errors[NEW_PASSWORD] = validatePassword(fieldValues[NEW_PASSWORD]);
  if (NEW_PASSWORD in fieldValues && NEW_PASSWORD_CONFIRM in fieldValues)
    errors[NEW_PASSWORD_CONFIRM] =
      fieldValues[NEW_PASSWORD] === fieldValues[NEW_PASSWORD_CONFIRM]
        ? ""
        : validate.newPasswordConfirmNotMatch;
  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};

export const invoiceValidateInput = (fieldValues) => {
  let errors = {};
  if (INVOICE_NUMBER_CODE in fieldValues)
    errors[INVOICE_NUMBER_CODE] = fieldValues[INVOICE_NUMBER_CODE]
      ? ""
      : IS_REQUIRED;
  if (EMAIL in fieldValues)
    errors[EMAIL] = fieldValues[EMAIL] ? "" : IS_REQUIRED;
  if (EMAIL in fieldValues && fieldValues.email)
    errors[EMAIL] = validateEmail(fieldValues[EMAIL]);
  if (COMPLETE_DATE in fieldValues)
    errors[COMPLETE_DATE] = fieldValues[COMPLETE_DATE] ? "" : IS_REQUIRED;
  if (AMOUNT in fieldValues)
    errors[AMOUNT] = fieldValues[AMOUNT] ? "" : IS_REQUIRED;
  if (AMOUNT in fieldValues)
    errors[AMOUNT] = validateNumber(fieldValues[AMOUNT]);

  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};

export const contactValidateInput = (fieldValues) => {
  let errors = {};
  if (EMAIL in fieldValues) errors[EMAIL] = validateEmail(fieldValues[EMAIL]);
  if (CONTACT_TYPE in fieldValues)
    errors[CONTACT_TYPE] = fieldValues[CONTACT_TYPE] ? "" : IS_REQUIRED;

  if (CONTENT in fieldValues)
    errors[CONTENT] = checkTextLength(fieldValues[CONTENT], 500);

  if (NUMBER_PHONE in fieldValues) {
    errors[NUMBER_PHONE] = validatePhone(fieldValues[NUMBER_PHONE]);
  }
  if (FULL_NAME in fieldValues)
    errors[FULL_NAME] = checkTextLength(fieldValues[FULL_NAME], 20);

  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};

export const registerPhoneValidateInput = (fieldValues) => {
  let errors = {};

  if (EMAIL in fieldValues) errors[EMAIL] = validateEmail(fieldValues[EMAIL]);
  if (NUMBER_PHONE in fieldValues) {
    errors[NUMBER_PHONE] = validatePhone(fieldValues[NUMBER_PHONE]);
  }
  let result = Object.values(errors).every((x) => x === "");
  return result ? "" : errors;
};
