import { makeStyles } from "@material-ui/core/styles";
import { Avatar } from "@material-ui/core";
import styled from "styled-components";

export const color = `#4B4B4C`;

export const TableList = styled.div`
  box-sizing: border-box;
  margin: 10px 0 30px;
  padding: 20px 30px;
  display: grid;
  grid-gap: 35px;
  grid-template-columns: repeat(auto-fill, minmax(353px, 1fr));
  overflow-y: auto;
  scroll-behavior: smooth;
  &::-webkit-scrollbar {
    width: 0.8em;
  }

  &::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  }

  &::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }
`;

export const TableItem = styled.div`
  border-radius: 40px;
  box-sizing: border-box;
  background-color: rgba(182, 195, 198, 0.8);
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-evenly;
  padding: 15px 35px;
  cursor: pointer;
  &:hover {
    transform: scale(1.025);
  }
  transition: all 0.3s ease-in-out;
  width: 100%;
  height: 244px;
`;

export const TableImage = styled(Avatar)`
  width: 40px;
  height: 40px;
  border-radius: 999px;
  object-fit: cover;
  flex-shrink: 0;
  color: white;
  background-color: ${color};
`;

export const TableName = styled.p`
  color: #612622;
  font-weight: 600;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const TablePlayers = styled.div`
  display: flex;
  justify-content: flex-start;
  color: ${color};
`;

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    boxSizing: "border-box",
    backgroundImage: "/assets/game_bg.png",
  },
  boxTable: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
  tableList: {
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    width: "100%",
    height: (props) => `${props.viewHeight}vh`,
    justifyContent: "flex-start",
    flexDirection: "column",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  },
  status: {
    display: "flex",
    color: "#4b4b4c",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  addTable: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
  },
  iconAdd: {
    fontSize: "3em",
    color: "#ffffff",
    [theme.breakpoints.down("lg")]: {
      fontSize: "2.4em",
    },
  },
  searchAndFilter: {
    marginTop: "30px",
    backgroundColor: "#e8e1d5",
    borderRadius: "999px",
    // width: (props) => `${props.searchAndFilterWidth}px`,
    height: (props) => `${props.searchAndFilterHeight}px`,
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",
    flexWrap: "wrap",
    boxSizing: "border-box",
    margin: " auto",
    color: "black",
    fontWeight: "600",
    "& span": {
      fontWeight: "600",
    },
    "&>*": {
      margin: "0 15px",
      fontWeight: "600",
    },
    padding: "20px",
  },
  searchBox: {
    flexGrow: 1,
  },
  buttonReload: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    minWidth: "120px",
    minHeight: "35px",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    // fontSize: (props) => `${props.fontSize16}px`,
    fontSize: "0.8rem",
    marginRight: "40px",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonCreate: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    minWidth: "120px",
    minHeight: "35px",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    // fontSize: (props) => `${props.fontSize16}px`,
    fontSize: "0.8rem",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  headText: {
    // fontSize: (props) => `${props.fontSize16}px`,
    fontSize: "1rem",
    color: "#541513",
    "& span": {
      // fontSize: (props) => `${props.fontSize16}px`,
      fontSize: "1rem",
    },
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "90%",
    margirn: "auto",
    alignItems: "center",
  },
  staffTableBadge: {
    backgroundColor: "#36F04C",
    color: "#4B4B4C",
    padding: "0.5em 1em",
    borderRadius: 8,
  },
  timeRemainClockBox: {
    right: "2%",
    width: "200px",
    bottom: "2%",
    position: "fixed",
    zIndex: 10,
  },
  DialogBySub: {
    minWidth: "900px",
    maxWidth: "900px",
  },
}));
export default useStyles;
