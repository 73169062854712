import useOnlineStatus from "@rehooks/online-status";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import firebase from "../../../../services/firebase";
import {
  getTurnOfPlayer,
  playerPlayCard,
} from "../../../../store/actions/playerGameStateActions";
import { playCard } from "../../../../store/actions/tableActions";
import {
  ADD_INSTRUCTOR_MESSAGE,
  PLAY_CARD_LOCAL,
  DOUBLE_CLICK_HAND_CARD,
} from "../../../../store/types";
import { checkPlayCardTurn, isPlayCard } from "./processPlayCard";
import { Card } from "./styles";
let connectedRef = firebase.database().ref(".info/connected");
import { GAME_STATUS } from "../../../../store/constant";
import { AnimatedContainer } from "./styles";
import { useSprings } from "react-spring";
import { useDrag } from "react-use-gesture";
import { clamp } from "lodash";
import swap from "lodash-move";
import MyCardComponent from "./components/MyCardComponent";
import BackDrop from "../../../../components/Backdrop";
import OkDialog from "../../../../pages/GameScreen/components/NewDialog/OkDialog";

function GChaosCard({ size, onOrderCard }) {
  const cardWidth = useSelector((state) => state.size.cardWidth);
  const cardHeight = useSelector((state) => state.size.cardHeight);

  const playerId = useSelector((state) => state.user.playerId);
  const nickName = useSelector((state) => state.user.nickName);

  const pgsOtherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const pgsIsMyTurn = useSelector((state) => state.playerGameState.isMyTurn);
  const pgsGrandCross = useSelector(
    (state) => state.playerGameState.grandCross
  );
  const pgsGameId = useSelector((state) => state.playerGameState.gameId);
  const handCards = useSelector(
    (state) => state.playerGameState.myState.handCards
  );
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);
  const tableId = useSelector((state) => state.room.table.tableId);
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  const turnStatus = useSelector((state) => state.playerGameState.turnStatus);
  const canPlayCard = useSelector((state) => state.playerGameState.canPlayCard);
  const roomId = useSelector((state) => state.room.roomId);
  const numberOfGame = useSelector((state) => state.room.table.numberOfGame);
  const currentRound = useSelector(
    (state) => state.playerGameState.currentRound
  );
  const playCardLocal = useSelector(
    (state) => state.playerGameState.playCardLocal
  );
  const doubleClickCard = useSelector(
    (state) => state.playerGameState.doubleClickCard
  );
  // const isLoseGameOverGet = useSelector(
  //   (state) => state.narration.narrationPopup
  // );
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  // const endGamePlayerName = useSelector(
  //   (state) => state.room.endGamePlayerName
  // );
  const playCardFail = useSelector(
    (state) => state.playerGameState.playCardFail
  );

  const dispatch = useDispatch();
  const { t } = useTranslation();

  const intervalRef = useRef(null);
  const interval1sRef = useRef(null);
  const responseRef = useRef(null);
  const statusRef = useRef(null);
  const loadingRef = useRef(false);
  const counterInterval = useRef(0);
  const onlineStatus = useOnlineStatus();
  const [counter, setCounter] = useState(0);
  let doubleClickHandCard = useRef(null);
  let countRePlay = 0;
  let countToReload = 0;

  /** SPRINGS */
  const fn =
    (cards, active = false, originalIndex = 0, currentIndex = 0, x = 0) =>
    (index) => {
      return active && index === originalIndex
        ? {
            x: currentIndex * Math.round(size?.cardWidth * 0.35) + x,
            zIndex: 14,
            immediate: true,
          }
        : {
            x: cards.indexOf(index) * Math.round(size?.cardWidth * 0.35),
            zIndex: cards.indexOf(index),
            immediate: true,
          };
    };

  const order = useRef([...Array(13).keys()]);
  const [springs, api] = useSprings(handCards.length, fn(order.current));

  const bind = useDrag(({ args: [originalIndex], active, movement: [x] }) => {
    const currentIndex = order.current.indexOf(originalIndex);
    const currentRow = clamp(
      Math.round(
        (currentIndex * Math.round(size?.cardWidth * 0.35) + x) /
          Math.round(size?.cardWidth * 0.35)
      ),
      0,
      handCards.length - 1
    );
    const newOrder = swap(order.current, currentIndex, currentRow);
    api.start(fn(newOrder, active, originalIndex, currentIndex, x));
    if (!active) {
      order.current = newOrder;
      updateOrderCard(newOrder);
    }
  });

  const updateOrderCard = (orderMyCards) => {
    if (!handCards || !orderMyCards) return;
    const sortedCard = [];
    handCards.forEach((_, idx) => {
      sortedCard.push(handCards[orderMyCards[idx]]);
    });
    onOrderCard(sortedCard);
  };

  useEffect(() => {
    if (playCardLocal === null) return;
    const playCardWhenCanPlay = async () => {
      console.log("playCardWhenCanPlay");
      console.log("canPlayCard", canPlayCard);
      console.log("playCardLocal", playCardLocal);
      if (canPlayCard === true && playCardLocal !== null) {
        const data = {
          card: playCardLocal.card,
          gameId: playCardLocal.gameId,
          playerId: playCardLocal.playerId,
          tableId: playCardLocal.tableId,
          newSuit: playCardLocal.newSuit,
          playerGChaos: true,
        };
        const res = await playCard(data);
        console.log("playCardWhenCanPlay res", res);
        dispatch({
          type: PLAY_CARD_LOCAL,
          payload: null,
        });
      }
    };
    playCardWhenCanPlay();
  }, [canPlayCard, dispatch, playCardLocal]);
  useEffect(() => {});

  useEffect(() => {
    api.start(fn(order.current));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [size]);

  const turnOf = () => {
    const index = pgsOtherPlayersState.findIndex(
      (otherPlayer) => otherPlayer && otherPlayer.isYourTurn
    );
    if (-1 === index || (joinedPlayers && joinedPlayers.length === 0)) {
      return null;
    }
    return joinedPlayers[index];
  };

  const notifyNotYourTurn = () => {
    if (!pgsIsMyTurn) {
      const turnPlayer = turnOf();
      if (!turnPlayer) return;
      // notify turn of other people
      const _mess = t("chatNotice.turnOf", { name: turnPlayer.nickName });
      dispatch({
        type: ADD_INSTRUCTOR_MESSAGE,
        payload: {
          message: _mess,
        },
      });
    }
  };

  const clearRecheckConnection = () => {
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    counterInterval.current = 0;
  };
  const clearInterval1s = () => {
    clearInterval(interval1sRef.current);
    interval1sRef.current = null;
  };

  const checkConnection = () => {
    let status = true;
    if (!onlineStatus) {
      connectedRef.on("value", (snap) => {
        if (snap.val() === true) {
          clearRecheckConnection();
          status = true;
        } else {
          console.log(`offline`);
          status = false;
        }
      });
    } else {
      clearRecheckConnection();
    }
    return status;
  };

  const onDoubleClickImg = async (e) => {
    e.preventDefault();

    console.log("onDoubleClickImg");

    const isBlockGChaosPlayCard =
      currentRound === 12 ||
      !getTurnOfPlayer(pgsOtherPlayersState, pgsIsMyTurn) ||
      pgsIsMyTurn === false ||
      isPlayCard(myIndex, pgsGrandCross);

    if (isBlockGChaosPlayCard) return;
    notifyNotYourTurn();

    let card = { suit: "", value: -1 };
    if (handCards && handCards.length > 0) {
      card = handCards[0];
    }
    if (card.value === -1) return;
    console.log("get-card", card);

    //TODO: G-CHAOS will not have permission to play card.
    const isCanNotPlayCard = !pgsIsMyTurn || !checkPlayCardTurn(turnStatus);
    if (isCanNotPlayCard) return;

    const interval1S = () => {
      console.log(`interval--1S`);
      if (countRePlay > 60) {
        countToReload += 5;
        dispatch({
          type: "PLAY_CARD_FAIL",
          payload: true,
        });

        if (countToReload > 5) {
          location.reload();
        }
      }
      interval1sRef.current = setInterval(async () => {
        statusRef.current = await checkConnection();
        console.log(`statusRef.current`, statusRef.current);
        if (statusRef.current === true) {
          loadingRef.current = false;
          setCounter(0);
          clearInterval1s();
          interval5s();
        } else {
          setCounter(counter + 1);
          loadingRef.current = true;
        }
      }, 1000);
    };

    const interval5s = async () => {
      console.log(`interval--5s`);
      console.log("card", card);
      const newSuit = card.suit;
      const data = {
        card: card,
        playerId: playerId,
        gameId: pgsGameId,
        newSuit: newSuit,
        tableId: tableId,
        playerGChaos: true,
      };
      responseRef.current = await playCard(data);
      console.log(`responseRef.current`, responseRef.current);
      intervalRef.current = setInterval(async () => {
        ++counterInterval.current;
        if (
          responseRef.current.data &&
          responseRef.current.data.status === 200
        ) {
          clearRecheckConnection();
          loadingRef.current = false;
          setCounter(0);
          countRePlay = 0;
        } else {
          countRePlay += 5;
          setCounter(counter + 1);
          loadingRef.current = true;
          interval1S();
        }
      }, 5000);
    };

    if (canPlayCard === true) {
      if (doubleClickCard) {
        setTimeout(() => {
          dispatch({
            type: DOUBLE_CLICK_HAND_CARD,
            payload: null,
          });
        }, 1000);
        return;
      }
      statusRef.current = await checkConnection();
      if (statusRef.current === true) {
        interval5s();
      } else {
        setCounter(counter + 1);
        loadingRef.current = true;
        interval1S();
      }
      // Update card in grandCross before  send to server
      dispatch(playerPlayCard(card, myIndex));
    } else {
      if (doubleClickCard) return;
      // Update card in grandCross before  send to server
      dispatch(playerPlayCard(card, myIndex));
      dispatch({
        type: PLAY_CARD_LOCAL,
        payload: {
          numberOfGame,
          roomId,
          card,
          playerId,
          gameId: pgsGameId,
          tableId,
          nickName,
        },
      });
    }
  };

  // todo: show card when player is g-chaos
  // const getFirstCard = () => {
  //   if (handCards && handCards.length > 0) {
  //     const handCardsNotNull = handCards.filter((c) => c !== null);
  //     return `/cards/${handCardsNotNull[0].suit}${handCardsNotNull[0].value}.jpg`;
  //   }
  //   return "";
  // };
  if (handCards && handCards.length > 0 && gameStatus === GAME_STATUS.LOSE) {
    return (
      <div
        style={{
          flex: 1,
          position: "relative",
          justifyContent: "center",
          alignItems: "center",
          height: "70%",
        }}
      >
        <BackDrop open={loadingRef.current} />
        <OkDialog
          isOpen={playCardFail}
          onAgree={() => {
            dispatch({
              type: "PLAY_CARD_FAIL",
              payload: false,
            });
          }}
          confirmText={t("reload.reloadNetworkNG", { countdown: 5 })}
          btnLabel={"OK"}
        />
        {springs.map(({ zIndex, x }, idx) => (
          <AnimatedContainer
            {...bind(idx)}
            key={`card:${idx}`}
            style={{
              zIndex,
              x,
            }}
          >
            <MyCardComponent
              src={`/cards/${handCards[idx]?.suit}${handCards[idx]?.value}.jpg`}
              draggable={false}
              onDragStart={(e) => e.preventDefault()}
              height={size?.cardHeight}
              width={size?.cardWidth}
            ></MyCardComponent>
          </AnimatedContainer>
        ))}
      </div>
    );
  }
  if (handCards && handCards.length > 0) {
    return (
      <Card
        left={`${6 * 7}%`}
        src={`/cards/back.png`}
        width={cardWidth}
        height={cardHeight}
        // onDoubleClick={onDoubleClickImg}
        onDoubleClick={(e) => {
          if (doubleClickHandCard.current) {
            clearTimeout(doubleClickHandCard.current);
          }
          doubleClickHandCard.current = setTimeout(() => {
            onDoubleClickImg(e);
          }, 500);
        }}
      />
    );
  }
  return null;
}
GChaosCard.propTypes = {
  myCards: PropTypes.array,
  size: PropTypes.any,
  onOrderCard: PropTypes.func,
};

export default GChaosCard;
