import { Checkbox } from "@material-ui/core";
import { FormControlLabel, FormGroup, Typography } from "@mui/material";
import moment from "moment";
import "moment/locale/ja";
import PropTypes from "prop-types";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { sendMessage } from "../../../../../../store/actions/chat";
import { CHAT_TYPE } from "../../../../../../store/constant";
import {
  ChatBoxCheckbox,
  ChatBoxInputSection,
  ChatContent,
  FirstNextDay,
  MessageContent,
  SendButton,
  StyledTextInput,
} from "../ChatBoxGroup/styles";
import ChatItem from "../ChatItem";

moment.locale("ja");

const ChatBoxTable = ({ displayRoom, messageList, threadId }) => {
  const { t } = useTranslation();
  const [chatMess, setChatMes] = useState("");
  const [checkBox, setCheckBox] = useState(false);
  const [isPublic, setIsPublic] = useState(false);

  const { playerId, avatar, nickName } = useSelector((state) => state.user);
  const isOnline = useSelector((state) => state.chat.isOnline);
  const minscale = useSelector((state) => state.size.minScale);
  const messagesEndRef = useRef(null);
  const [messageListHandled, setMessageListHandled] = useState([]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView();
  };

  const onSend = (message) => {
    if (message == "") return;
    sendMessage({
      threadId: threadId,
      threadType: CHAT_TYPE.ROOM,
      senderId: playerId,
      senderName: nickName,
      senderAvatar: avatar,
      message: message,
      tableName: displayRoom,
      isPublic,
    });
  };

  const handleCheckBox = (e) => {
    setCheckBox(e.target.checked);
    setIsPublic(!isPublic);
  };

  const onMessageChange = (event) => {
    let message = event.target.value;
    setChatMes(message);
  };

  const onEnter = (event) => {
    if (event.key === "Enter" && event.keyCode == 13) {
      onSend(chatMess);
      setChatMes("");
    }
  };

  useEffect(() => {
    let time = 0;
    const _messageListHandled = messageList.map((item) => {
      if (new Date(item.milliseconds).getDate() - time >= 1) {
        time = new Date(item.milliseconds).getDate();
        return {
          ...item,
          firstNextDay: true,
        };
      }
      return { ...item, firstNextDay: false };
    });
    setMessageListHandled(_messageListHandled);
  }, [messageList]);

  useEffect(() => {
    scrollToBottom();
  }, [messageListHandled]);

  return (
    <ChatContent>
      <MessageContent minscale={minscale}>
        {messageListHandled &&
          messageListHandled.length > 0 &&
          messageListHandled.map((item, index) => {
            if (item.firstNextDay) {
              return (
                <>
                  <FirstNextDay>
                    {moment(item.milliseconds).format("llll").slice(0, -5)}
                  </FirstNextDay>
                  <ChatItem messageData={item} id={playerId} key={index} />
                </>
              );
            } else {
              return <ChatItem messageData={item} id={playerId} key={index} />;
            }
          })}
        {isOnline === false && (
          <div
            style={{
              textAlign: "center",
              fontSize: `${
                minscale > 0.5 ? `${minscale * 12}px` : `${0.5 * 12}px`
              }`,
              color: "red",
              marginBottom: `${
                minscale > 0.5 ? `${minscale * 10}px` : `${0.5 * 10}px`
              }`,
              letterSpacing: "2px",
            }}
          >
            {t("status.sendMessageFailed")}
          </div>
        )}
        <div ref={messagesEndRef} />
      </MessageContent>
      <ChatBoxInputSection minscale={minscale}>
        <StyledTextInput
          minscale={minscale}
          onChange={onMessageChange}
          placeholder={t("game.sendChatMessage")}
          onKeyDown={onEnter}
          value={chatMess}
        />
        <SendButton
          minscale={minscale}
          onClick={() => {
            setChatMes("");
            onSend(chatMess);
          }}
        >
          {t("common.send")}
        </SendButton>
      </ChatBoxInputSection>
      <ChatBoxCheckbox minscale={minscale}>
        <FormGroup>
          <FormControlLabel
            style={{
              padding: "0",
              margin: "0",
            }}
            control={
              <Checkbox
                style={{
                  padding: "0",
                  transform: "scale(1.2)",
                }}
                checked={checkBox}
                onClick={handleCheckBox}
                color="default"
              />
            }
            label={
              <Typography
                style={{
                  marginLeft: `${
                    minscale > 0.5 ? `${minscale * 10}px` : `${0.5 * 10}px`
                  }`,
                  fontSize: `${
                    minscale > 0.5 ? `${minscale * 10}px` : `${0.5 * 10}px`
                  }`,
                }}
              >
                {t("game.chatBoxTitlePublic")}
              </Typography>
            }
          />
        </FormGroup>
      </ChatBoxCheckbox>
    </ChatContent>
  );
};

ChatBoxTable.propTypes = {
  roomShow: PropTypes.array,
  displayRoom: PropTypes.string,
  messageList: PropTypes.array,
  threadId: PropTypes.string,
};

export default ChatBoxTable;
