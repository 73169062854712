import { Box, Container } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "react-toastify/dist/ReactToastify.css";
import CardChange from "./components/CardChange";
import ChangePlan from "./components/ChangePlan";
import ContractDetail from "./components/ContractDetail";
import UnsubscribePlan from "./components/UnsubscribePlan";
import useStyles from "./styles";

const PaymentSettings = ({ goBack }) => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const [currentValue, setCurrentValue] = useState(0);

  const isLoading = useSelector((state) => state.size.isLoading ?? false);
  const isBuyingLoading = useSelector((state) => state.payment.isBuyingLoading ?? false);

  const handleCurrentPage = useCallback((value) => {
    setCurrentValue(value);
  }, []);

  return (
    <Container className={classes.container}>
      <Box className={classes.box}>
        {currentValue === 0 && (
          <ContractDetail
            goBack={goBack}
            handleCurrentPage={handleCurrentPage}
          />
        )}
        {currentValue === 1 && (
          <ChangePlan handleCurrentPage={handleCurrentPage} />
        )}
        {currentValue === 2 && (
          <UnsubscribePlan handleCurrentPage={handleCurrentPage} />
        )}
        {currentValue === 3 && (
          <CardChange handleCurrentPage={handleCurrentPage} />
        )}
      </Box>
      <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop style={{ zIndex: 9999, color: "#fff", display: "flex", flexDirection: "column" }} open={isBuyingLoading}>
        <CircularProgress color="inherit" />
        <div style={{ padding: "50px" }}>{t("loading.isBuyingLoading")}</div>
      </Backdrop>
    </Container>
  );
};

PaymentSettings.propTypes = {
  goBack: PropTypes.func,
};

export default PaymentSettings;
