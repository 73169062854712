import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TUTORIAL_MODE } from "../../../../../../store/constant";
import { calIsMyTurn } from "../../../../../../utils/common";
import VCardShip from "../../../CardShip/VCardShip";
import { Profile } from "../../../Profile/Profile";
import { LeftTutorialCards } from "../../../TutorialCards/LeftTutorialCards";

const useStyles = makeStyles(() => ({
  profile: {
    marginRight: (props) => props.marginRight,
    position: "relative",
    top: "10px",
  },
}));

const GameLeftMiddleContainer = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box;
  padding: 0 ${(props) => props.padding}px;
`;

const LeftSideContainer = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
`;
const GameLeftMiddle = React.memo(function GameLeftMiddleComponent() {
  const shipHeight = useSelector((state) => state.size.vShipHeight);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const profileShipSpace = useSelector((state) => state.size.profileShipSpace);
  const profile = useSelector((state) => state.room.profile3);
  const cardPlayer = useSelector((state) => state.playerGameState.cardPlayer3);
  const isSorted = useSelector(
    (state) => state.playerGameState.cardPlayer3.isSorted
  );
  const myIndex = useSelector((state) => state.playerGameState.myIndex);

  const otherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const isMyTurn = useMemo(() => {
    const index = (myIndex + 3) % 4;
    return calIsMyTurn(otherPlayersState, index);
  }, [otherPlayersState, myIndex]);
  const otherState = useMemo(
    () =>
      otherPlayersState[profile?.index || 0] || {
        isYourTurn: false,
        numberOfCalls: -1,
        starCards: [],
      },
    [otherPlayersState, profile]
  );
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );

  const isTutorial = useMemo(() => {
    return [TUTORIAL_MODE.HAS_CALL, TUTORIAL_MODE.NO_CALL].includes(
      tutorialMode
    );
  }, [tutorialMode]);

  const height = useMemo(
    () => Math.round(shipHeight + shipSpace * 2),
    [shipHeight, shipSpace]
  );

  const classes = useStyles({ marginRight: profileShipSpace });
  return (
    <GameLeftMiddleContainer height={height} padding={shipSpace}>
      <LeftSideContainer>
        <Profile
          profile={profile}
          position={"right"}
          positionTurn={"left"}
          isMyTurn={isMyTurn}
          numberOfCalls={otherState.numberOfCalls}
          isTutorial={isTutorial}
          className={classes.profile}
        />
        <LeftTutorialCards
          id="leftTutorialCards"
          isTutorial={isTutorial}
          isGChaos={cardPlayer.isGChaos}
          cards={cardPlayer?.cards}
          className={classes.profile}
          isSorted={isSorted}
        />
      </LeftSideContainer>
      <VCardShip
        side="left"
        cards={otherState.starCards}
        numberOfCalls={otherState.numberOfCalls}
        profile={profile}
      />
    </GameLeftMiddleContainer>
  );
});
export default GameLeftMiddle;
