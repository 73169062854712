import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { GoTriangleLeft, GoTriangleRight } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import { declareNumberOfCallsAction } from "../../../../../store/actions/gameActions";
import { PLAYER_ROLE_G_CHAOS } from "../../../../../store/constant";
import { SET_MY_NUMBER_OF_CALLS } from "../../../../../store/types";
import { mapPlayersDeclareCalls } from "../../../../../utils/common";
import {
  Button,
  ButtonOK,
  ChangeCalls,
  Container,
  MyNumberOfCalls,
  NickName,
  NumberOfCalls,
  PlayersCall,
  PlayersName,
} from "./styles";

export const DeclareCallsDialog = () => {
  const { t } = useTranslation();
  const [myNumberOfCalls, setMyNumberOfCalls] = useState(0);
  const playerId = useSelector((state) => state.user.playerId);
  const minScale = useSelector((state) => state.size.minScale);
  const numberOfGame = useSelector((state) => state.room.table.numberOfGame);
  const { currentPlayersId } = useSelector((state) => state.room.table);
  const limitCalls = useSelector(
    (state) => state.playerGameState.myState.limitCalls
  );
  const numberOfCalls = useSelector(
    (state) => state.playerGameState.myState.numberOfCalls
  );
  const { gameId, myState, role } = useSelector(
    (state) => state.playerGameState
  );
  const otherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);

  const [players, setPlayers] = useState([]);
  const [isClicked, setIsClicked] = useState(false);
  const [limitCall, setLimitCall] = useState(0);

  const dispatch = useDispatch();

  const incrementCall = () => {
    setMyNumberOfCalls((c) => ++c);
  };

  const decrementCall = () => {
    setMyNumberOfCalls((c) => --c);
  };

  const handleClickOK = () => {
    setIsClicked(true);
    declareNumberOfCallsAction(
      playerId,
      gameId,
      myNumberOfCalls,
      myIndex,
      myState.limitCalls - 1,
      currentPlayersId,
      numberOfGame
    );
    const newMyState = { ...myState };
    newMyState.numberOfCalls = myNumberOfCalls;
    dispatch({
      type: SET_MY_NUMBER_OF_CALLS,
      payload: newMyState,
    });
  };

  useEffect(() => {
    if (numberOfCalls === -1) setIsClicked(false);
    else setIsClicked(true);
    if (limitCalls === -1) setLimitCall(0);
    else setLimitCall(limitCalls);
  }, [numberOfCalls, limitCalls]);

  useEffect(() => {
    if (numberOfCalls && 0 === myNumberOfCalls) {
      if (numberOfCalls === -1) {
        setMyNumberOfCalls(0);
      } else {
        setMyNumberOfCalls(numberOfCalls);
      }
    }
  }, [myNumberOfCalls, numberOfCalls]);

  const callFontSize = useMemo(() => Math.round(18 * minScale), [minScale]);
  const callNumberFontSize = useMemo(
    () => Math.round(35 * minScale),
    [minScale]
  );

  useEffect(() => {
    const result = mapPlayersDeclareCalls(
      joinedPlayers,
      numberOfCalls,
      myIndex,
      otherPlayersState
    );
    setPlayers(result);
  }, [joinedPlayers, numberOfCalls, myIndex, otherPlayersState]);

  return (
    <Container>
      {role !== PLAYER_ROLE_G_CHAOS && (
        <MyNumberOfCalls id="declareCallsActions">
          <p style={{ fontSize: callFontSize }}>
            {t("numberOfCalls.limitCalls", { limitCall })}
          </p>
          <ChangeCalls>
            <Button onClick={decrementCall} disabled={myNumberOfCalls <= 0}>
              <GoTriangleLeft
                style={{ fontSize: "30px" }}
                className="color-1"
              />
            </Button>
            <NumberOfCalls
              className="color-1"
              style={{ fontSize: callNumberFontSize }}
            >
              {myNumberOfCalls}
            </NumberOfCalls>
            <Button onClick={incrementCall} disabled={myNumberOfCalls === 22}>
              <GoTriangleRight
                style={{ fontSize: "30px" }}
                className="color-1"
              />
            </Button>
          </ChangeCalls>
          <ButtonOK
            onClick={handleClickOK}
            className="button-style-2"
            style={{
              opacity: isClicked ? 0.6 : 1,
              pointerEvents: isClicked ? "none" : "initial",
            }}
          >
            <div
              className="words-separate-btn-ok"
              style={{ marginLeft: 5, fontSize: callFontSize }}
            >
              {[...t("numberOfCalls.ok")].map((l) => (
                <div key={l}>{l}</div>
              ))}
            </div>
          </ButtonOK>
        </MyNumberOfCalls>
      )}

      <PlayersName id="declareCallsPlayersName">
        {players?.map((pl, index) => (
          <NickName
            key={`declareCallsNickname:${pl?.nickName}:${index}`}
            fontSize={callFontSize}
          >
            {pl?.nickName}
          </NickName>
        ))}
      </PlayersName>
      <PlayersCall>
        {players?.map((pl, index) => (
          <p key={index} style={{ fontSize: callFontSize }}>
            {pl?.numberOfCalls === -1 || undefined === pl?.numberOfCalls
              ? t("numberOfCalls.pending")
              : `${pl?.numberOfCalls} ${t("numberOfCalls.call")}`}
          </p>
        ))}
      </PlayersCall>
    </Container>
  );
};
export default DeclareCallsDialog;
