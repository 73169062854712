import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  ChangePlanBox: {
    position: "relative",
    overflow: "hidden",
    width: "100%",
    boxSizing: "border-box",
  },
  ChangePlanTitle: {
    textAlign: "center",
    fontSize: "1.5rem !important",
    fontWeight: "bold !important",
    marginBottom: "1rem",
  },
  PlanCurrent: {
    marginTop: "1rem",
  },
  PlanCurrentTitle: {
    fontSize: "1.2rem",
    marginBottom: "0.5rem !important",
    fontWeight: "bold !important",
  },
  PlanBox: {
    border: "2px solid",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    transition: "0.5 ease",
    cursor: "pointer",
  },
  PlanCurrentBox: {
    border: "1px solid",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    marginBottom: "1rem",
    transition: "0.5 ease",
    cursor: "pointer",
    width: "33% !important",
  },
  ListPlanWrapp: {
    marginTop: "1rem",
  },
  ListPlanTitle: {
    fontWeight: "bold !important",
    marginBottom: "0.5rem !important",
  },
  ListPlan: {
    width: "100%",
    display: "flex",
    gap: "10px",
    "@media (max-width: 991px)": {
      gridTemplateColumns: "repeat(3,1fr)",
    },
    "@media (max-width: 768px)": {
      gridTemplateColumns: "repeat(2,1fr)",
    },
  },
  ListPlanBox: {
    textAlign: "center",
    width: "33% !important",
  },
  PlanBoxText: {
    fontWeight: "bold !important",
    "@media (max-width: 991px)": {
      fontSize: "0.7rem !important",
    },
  },
  btns: {
    textAlign: "center",
    // margin: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-evenly",

    "@media (max-width: 768px)": {
      margin: "3rem 1rem",
    },
  },
  PlanBtn: {
    minWidth: "100px !important",

    "@media (max-width: 991px)": {
      fontSize: "0.7rem !important",
    },
  },
  Bronze: {
    borderColor: "#e15d5d",
    backgroundColor: "#eeb8b8",
    color: "#e15d5d",
    cursor: "default",
  },
  Platinum: {
    borderColor: "#6e6767d6",
    backgroundColor: "##dddbdb9e",
    color: "#6e6767d6",
    cursor: "default",
  },
  Gold: {
    borderColor: "#e6843bd6",
    backgroundColor: "#d9c99c9e",
    color: "#e6843bd6",
    cursor: "default",
  },
  Silver: {
    borderColor: "#21731dd6",
    backgroundColor: "##dddbdb9e",
    color: "#21731dd6",
    cursor: "default",
  },
  activePackage: {
    border: "2px solid blue !important",
  },
  packageBtn: {
    minWidth: "90px !important",
  },
  btnGroup: {
    display: "flex",
    justifyContent: "space-between",
    margin: "2rem auto 1rem",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: "50px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    minWidth: "170px",

    "&:hover": {
      color: "#541513 !important",
    },
  },
}));

export default useStyles;
