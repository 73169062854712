import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: "450px",
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
}));

function Form({ children }) {
  const classes = useStyles();
  return (
    <form className={classes.root} noValidate>
      {children}
    </form>
  );
}

Form.propTypes = {
  children: PropTypes.array,
};

export default Form;
