import { orderBy } from "lodash";
import moment from "moment";
import firebase, { getFunctions } from "../../services/firebase";
import { PLAYER_STATUS } from "../constant";
import ChatRepo from "../repositories/chat";
import PlayerRepo from "../repositories/player";
import RoomRepo from "../repositories/room";
import ChatStatusRepo from "../repositories/chatStatus";
import {
  SET_MESSAGES_IN_ROOM,
  SET_STREAMS,
  SET_THREAD_IN_ROOM,
} from "../types";
import { CHAT_TYPE } from "../../store/constant";
import { CHECK_IS_HAVE_ROOM_CHAT } from "../funcs";

export const CHAT_FIELDS = {
  IS_ALLOW_SHARING: "isAllowSharing",
  LAST_MESSAGE: "lastMessage",
  LAST_TIME_INTERACTIVE: "lastTimeInteractive",
  THREAD_MEMBER_IDS: "threadMemberIds",
  THREAD_MEMBERS: "threadMembers",
  THREAD_TYPE: "threadType",
};
const THREADS = "threads";
const CHATS = "chats";
const CHAT_STATUS = "chatStatus";
const STREAM_MESSAGES = "streamMessages";
export const MESSAGE = "messages";
const functions = getFunctions();

export const chatRef = firebase.firestore().collection(CHATS);
export const chatStatusRef = firebase.firestore().collection(CHAT_STATUS);
const streamMessageRef = firebase.firestore().collection(STREAM_MESSAGES);

export function mapFriends(friends = [], playerId = "", threadId = "") {
  return friends.map((friend) => {
    if (friend.friendId === playerId) {
      return {
        ...friend,
        threadId,
      };
    }
    return friend;
  });
}

export async function updateThreadIdInPlayerFriends(myId, friendId, threadId) {
  try {
    if (myId && friendId && threadId) {
      const myData = await PlayerRepo.read(myId);
      const friendData = await PlayerRepo.read(friendId);
      if (myData && friendData) {
        const myFriends = mapFriends(myData.friends, friendId, threadId);
        const otherFriends = mapFriends(friendData.friends, myId, threadId);

        await PlayerRepo.update(myId, { friends: myFriends });
        await PlayerRepo.update(friendId, { friends: otherFriends });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

export async function checkCreateThread(friends = [], friendId = "") {
  try {
    if (friends.length > 0) {
      const friend = friends.filter((f) => f.friendId === friendId)[0];
      return friend?.threadId === "";
    } else {
      return false;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {object[]} members {memberId, memberName, memberAvatar}
 * @param {string} type PRIVATE | GROUP | ROOM
 * @returns
 */
export async function createThread(members = [], type = "") {
  try {
    const memberIds = members.map((member) => member && member.memberId);
    const threadData = {
      threadType: type,
      threadMembers: members,
      threadMemberIds: memberIds,
      lastMessage: "",
      senderId: "",
      lastTimeInteractive: 0,
      isSeen: true,
      count: 0,
    };
    const threadId = await ChatRepo.create(threadData);
    chatRef.doc(threadId).set({ ...threadData, threadId });

    //TODO: add ChatStatus, check is reading user
    chatStatusRef.doc(threadId).set({
      threadMembers: members,
      count: 0,
      threadId,
    });
    if (threadId) {
      await addThreadInPlayerThreads(memberIds, threadId);
    }
    return threadId;
  } catch (error) {
    console.log(error);
  }
}

/**
 * add thread into player
 * @param {array[string]} memberIds
 * @param {string} threadId
 */
export async function addThreadInPlayerThreads(memberIds = [], threadId = "") {
  try {
    if (memberIds.length > 0 && threadId !== "") {
      const promises = memberIds.map(async (mid) => {
        const player = await PlayerRepo.read(mid);
        const currentThreads = player[THREADS] ?? [];
        if (!currentThreads.includes(threadId)) {
          return PlayerRepo.update(mid, {
            threads: [...currentThreads, threadId],
          });
        }
        return Promise.resolve();
      });
      await Promise.all(promises);
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * remove thread in player
 * @param {string[]} memberIds
 * @param {string} threadId
 */
export async function removeThreadInPlayerThreads(
  memberIds = [],
  threadId = ""
) {
  try {
    if (memberIds.length > 0 && threadId !== "") {
      const promises = memberIds.map(async (memberId) => {
        const player = await PlayerRepo.read(memberId);
        const _threads = player[THREADS] ?? [];
        const threads = _threads.filter((id) => id !== threadId);
        return PlayerRepo.update(memberId, { threads });
      });
      await Promise.all(promises);
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * send message
 * @param {object} data senderId, senderName, message
 * @param {string} threadId
 * @returns
 */
export async function sendMessage({
  threadId = "",
  senderId = "",
  senderName = "",
  senderAvatar = "",
  message = "",
  tableName = "",
  isPublic = false,
}) {
  try {
    if (threadId && senderId) {
      const now = Date.now();
      await ChatRepo.createSubDoc(threadId, {
        senderId,
        message,
        createdAt: now,
      });

      await ChatRepo.update(threadId, {
        lastMessage: message,
        lastTimeInteractive: now,
        senderId,
        count: firebase.firestore.FieldValue.increment(1),
      });

      //TODO: update chatStatus collection by threadId
      const { threadMembers } = await ChatStatusRepo.read(threadId);
      const newThreadMembers = threadMembers?.map((member) => {
        if (member.isReading === false) {
          member.countUnreadMessage += 1;
        } else {
          member.countUnreadMessage = 0;
        }

        if (member.memberId === senderId) {
          member.isReading = true;
          member.countUnreadMessage = 0;
        }
        return member;
      });
      await ChatStatusRepo.update(threadId, {
        threadMembers: newThreadMembers,
        count: firebase.firestore.FieldValue.increment(1),
      });

      if (isPublic) {
        const messageStream = {
          tableName,
          senderId,
          senderName,
          senderAvatar,
          message: message,
          createdAt: now,
        };
        addMessageToStreamMessage(messageStream);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * allow sharing chat out of table
 * @param {string} threadId
 * @param {boolean} isSharing
 */
export async function allowThreadSharing(threadId = "", isSharing = false) {
  try {
    if (threadId) {
      await ChatRepo.update(threadId, { isAllowSharing: isSharing });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * add player into thread
 * @param {string} threadId
 * @param {array} member
 */
export async function addMemberToThread(threadId = "", members = []) {
  try {
    let thread;
    let chatStatus;
    if (threadId) {
      thread = await ChatRepo.read(threadId);
      chatStatus = await ChatStatusRepo.read(threadId);
    }
    if (thread) {
      if (thread[CHAT_FIELDS.THREAD_TYPE] !== CHAT_TYPE.PRIVATE) {
        let threadMemberIds = thread[CHAT_FIELDS.THREAD_MEMBER_IDS] ?? [];
        let threadMembers = thread[CHAT_FIELDS.THREAD_MEMBERS] ?? [];
        members?.forEach((member) => {
          if (threadMembers.length > 0) {
            let index = threadMembers?.findIndex(
              (f) => f.memberId === member.memberId
            );
            if (index !== -1) {
              threadMembers[index] = { ...member };
            } else {
              threadMembers.push(member);
            }
          } else {
            threadMembers.push(member);
          }
          if (!threadMemberIds.includes(member.memberId)) {
            threadMemberIds.push(member.memberId);
          }
        });
        await ChatRepo.update(threadId, { threadMemberIds, threadMembers });

        //TODO: update chatStatus threadMembers
        let _threadMembers = chatStatus[CHAT_FIELDS.THREAD_MEMBERS] ?? [];
        members?.forEach((member) => {
          if (_threadMembers.length > 0) {
            let index = _threadMembers?.findIndex(
              (f) => f.memberId === member.memberId
            );
            if (index !== -1) {
              _threadMembers[index] = { ...member };
            } else {
              _threadMembers.push(member);
            }
          } else {
            _threadMembers.push(member);
          }
          if (!threadMemberIds.includes(member.memberId)) {
            threadMemberIds.push(member.memberId);
          }
        });
        await ChatStatusRepo.update(threadId, { _threadMembers });

        members.map(async (item) => {
          await addThreadInPlayerThreads([item.memberId], threadId);
        });
      } else if (thread[CHAT_FIELDS.THREAD_TYPE] === CHAT_TYPE.PRIVATE) {
        const threadMembers = thread[CHAT_FIELDS.THREAD_MEMBERS] ?? [];
        members?.map((item) => {
          threadMembers.push(item);
        });
        await createThread(threadMembers, CHAT_TYPE.GROUP);
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * remove player out of thread
 * @param {string} threadId
 * @param {string} memberId
 */
export async function removeMemberInThread(threadId = "", memberId = "") {
  try {
    let thread;
    if (threadId && memberId) {
      thread = await ChatRepo.read(threadId);
    }
    if (thread) {
      const _threadMemberIds = thread[CHAT_FIELDS.THREAD_MEMBER_IDS] ?? [];
      const threadMemberIds = _threadMemberIds.filter((id) => id !== memberId);
      await ChatRepo.update(threadId, { threadMemberIds });
      await removeThreadInPlayerThreads([memberId], threadId);
    }
  } catch (error) {
    console.log(error);
  }
}

export function subscribeChat(setUnsubscribe) {
  return async (dispatch, getState) => {
    try {
      const threadIds = getState().user?.threads ?? ["IfCzNTKXZvUZXs8Ej5iV"];
      for (const threadId of threadIds) {
        const unsubscribe = chatRef.doc(threadId).onSnapshot((doc) => {
          dispatch({ type: "PUSH_THREAD", payload: doc.data() });
        });
        setUnsubscribe(unsubscribe);
      }
    } catch (error) {
      console.log(error);
    }
  };
}

/**
 * add all message when allow sharing out of the table
 * @param {object} message
 */
export async function addMessageToStreamMessage(
  message = {
    tableName: "",
    senderId: "",
    senderName: "",
    senderAvatar: "",
    message: "",
    createdAt: "",
  }
) {
  try {
    if (message.message) {
      await ChatRepo.addMessageToStreamMessage(message);
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * get all message streaming
 * @param {func} setUnsubscribe
 * @returns
 */
export function subscribeStreamMessage(setUnsubscribe) {
  return async (dispatch, getState) => {
    try {
      const playerId = getState().user?.playerId;
      const friendIds = getState().user?.friends?.map((f) => f.friendId);
      const myFriendRequests = getState().user?.friendRequests ?? [];
      const unsubscribe = streamMessageRef.onSnapshot(async (snapshot) => {
        const _streams = snapshot.docs.map((doc) => {
          const { createdAt, senderId } = doc.data();
          return {
            ...doc.data(),
            milliseconds: createdAt,
            createdAt: moment(createdAt).format("HH:mm"),
            isFriend: friendIds.includes(senderId),
            isShowButton: senderId !== playerId,
          };
        });
        const streams = orderBy(_streams, ["milliseconds"], "desc");
        let new_streams = [];
        if (streams && streams.length > 0) {
          const promises = streams.map(async (message) => {
            const player = await PlayerRepo.read(message.senderId);
            const otherId = player?.playerId;
            const otherFriendRequests = player?.friendRequests ?? [];
            const isShowButton = checkIsShowButtonAddFriend(
              myFriendRequests,
              otherFriendRequests,
              playerId,
              otherId,
              message.isFriend
            );
            const status = getPlayerStatus(player);
            return {
              ...message,
              status,
              isShowButton,
            };
          });
          new_streams = await Promise.all(promises);
        }
        dispatch({ type: SET_STREAMS, payload: new_streams });
      });
      setUnsubscribe(unsubscribe);
    } catch (error) {
      console.log(error);
    }
  };
}

export function getPlayerStatus(player) {
  try {
    if (player) {
      const { isOnline, isPlaying, currentRoomJoinedId } = player;
      if (isOnline && !currentRoomJoinedId) return PLAYER_STATUS.AVAILABLE;
      if (isOnline && currentRoomJoinedId) {
        if (isPlaying) return PLAYER_STATUS.PLAYING;
        else return PLAYER_STATUS.WAITING;
      }
      return PLAYER_STATUS.OFFLINE;
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * seen message when click thread
 * @param {string} threadId
 * @param {boolean} isSeen
 */
export async function seenMessage(threadId = "", isSeen = true) {
  try {
    if (threadId) {
      await ChatRepo.update(threadId, { isSeen });
    }
  } catch (error) {
    console.log(error);
  }
}

export const hideButtonAddFriend = (friendId = "") => {
  return (dispatch, getState) => {
    const _streams = getState().chat.streams ?? [];
    const streams = _streams.map((stream) => {
      if (stream.senderId === friendId) {
        return {
          ...stream,
          isShowButton: false,
        };
      }
      return stream;
    });
    dispatch({ type: SET_STREAMS, payload: streams });
  };
};

export function checkIsShowButtonAddFriend(
  myFriendRequests = [],
  otherFriendRequests = [],
  myId = "",
  otherId = "",
  isFriend = false
) {
  if (isFriend) return true;
  if (myId === otherId) return false;
  const myHasFR = myFriendRequests.map((f) => f.friendId).includes(otherId);
  const otherHasFR = otherFriendRequests.map((f) => f.friendId).includes(myId);
  return myHasFR === otherHasFR;
}

export async function addThreadIdToRoom(roomId = "", threadId = "") {
  try {
    if (roomId && threadId) {
      await RoomRepo.update(roomId, { threadId });
    }
  } catch (error) {
    console.log(error);
  }
}
export async function addThreadIdToPlayerJoinRoom(
  playerId = "",
  threadId = ""
) {
  try {
    if (playerId && threadId) {
      const player = await PlayerRepo.read(playerId);
      const threads = player.threads ?? [];
      await PlayerRepo.update(playerId, { threads: [...threads, threadId] });
    }
  } catch (error) {
    console.log(error);
  }
}

export function subscribeThreadInRoom(setUnsubscribe) {
  return (dispatch, getState) => {
    const threadId = getState().room?.threadId;
    if (threadId) {
      const unsubscribe = chatRef.doc(threadId).onSnapshot((snapshot) => {
        dispatch({ type: SET_THREAD_IN_ROOM, payload: snapshot.data() });
      });
      setUnsubscribe(unsubscribe);
    }
  };
}

export function subscribeMessagesInRoom(setUnsubscribe) {
  return (dispatch, getState) => {
    const threadId = getState().room?.threadId;
    if (threadId) {
      const unsubscribe = chatRef
        .doc(threadId)
        .collection(MESSAGE)
        .onSnapshot((snapshot) => {
          const messages = snapshot.docs.map((doc) => {
            const { createdAt } = doc.data();
            const _createdAt = moment(createdAt).format("hh:mm");
            return {
              ...doc.data(),
              createdAt: _createdAt,
              milliseconds: createdAt,
            };
          });
          const sortedMessages = orderBy(messages, "milliseconds", "asc");
          dispatch({ type: SET_MESSAGES_IN_ROOM, payload: sortedMessages });
        });
      setUnsubscribe(unsubscribe);
    }
  };
}

export function getThreadName(
  dataRoomChat = [],
  playerId = "",
  tableName = ""
) {
  if (dataRoomChat && playerId) {
    if (dataRoomChat?.threadType === CHAT_TYPE.GROUP) {
      return dataRoomChat?.threadMembers
        .map((member) => member.memberName)
        .join(" | ");
    }
    if (dataRoomChat?.threadType === CHAT_TYPE.ROOM) {
      return tableName;
    }
    return (
      dataRoomChat?.threadMembers?.filter(
        (member) => member.memberId !== playerId
      )[0]?.memberName ?? "Chat Friend"
    );
  }
  return "";
}

//TODO: change status isRedding, when user change room
export async function setCountUnreadMessage(
  threadId = "",
  playerId = "",
  threads = []
) {
  try {
    if (threads && threads.length > 0 && threadId && playerId) {
      threads?.map(async (itemThreadId) => {
        const thread = await ChatStatusRepo.read(itemThreadId);
        if (thread) {
          let threadMembers = thread[CHAT_FIELDS.THREAD_MEMBERS] ?? [];
          threadMembers?.forEach((item) => {
            if (itemThreadId === threadId && item.memberId === playerId) {
              item.isReading = true;
              item.countUnreadMessage = 0;
            }
            if (itemThreadId !== threadId && item.memberId === playerId) {
              item.isReading = false;
            }
          });
          await ChatStatusRepo.update(itemThreadId, { threadMembers });
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

//TODO: check Count Un Message
export const checkCountUnMessage = (
  threadId = "",
  playerId = "",
  chatStatus = []
) => {
  if (playerId && threadId && chatStatus) {
    const data = chatStatus?.find((f) => f.threadId === threadId);
    if (data) {
      const playerInfo = data[CHAT_FIELDS.THREAD_MEMBERS]?.find(
        (f) => f.memberId === playerId
      );

      if (playerInfo) {
        return playerInfo?.countUnreadMessage;
      }
    }
  }
  return 0;
};

//TODO: change status isRedding = false, when the user close modal chat
export async function logoutChat(playerId = "", threads = []) {
  try {
    if (threads && threads.length > 0 && playerId) {
      threads?.map(async (itemThreadId) => {
        const thread = await ChatStatusRepo.read(itemThreadId);
        if (thread) {
          let threadMembers = thread[CHAT_FIELDS.THREAD_MEMBERS] ?? [];
          const index = threadMembers?.findIndex(
            (f) => f.memberId === playerId
          );
          if (index !== -1) {
            threadMembers[index] = {
              ...threadMembers[index],
              isReading: false,
            };
            await ChatStatusRepo.update(itemThreadId, { threadMembers });
          }
        }
      });
    }
  } catch (error) {
    console.log(error);
  }
}

export function CheckIsFriendRequests(friendRequestsList = [], playerId = "") {
  try {
    if (friendRequestsList && friendRequestsList.length > 0 && playerId) {
      const index = friendRequestsList.findIndex(
        (f) => f.friendId === playerId
      );
      return index > -1;
    }
  } catch (error) {
    console.log(error);
    return false;
  }
}

export const checkIsHaveRoomChat = async (playerListId, threads) => {
  return functions.httpsCallable(CHECK_IS_HAVE_ROOM_CHAT)({
    playerListId,
    threads,
  });
};
