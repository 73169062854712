import { Typography } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../components/Button";
import firebase from "../../../../services/firebase";
import { createUserWithPhone } from "../../../../store/actions/authActions";
import { ERROR_CODE_MESSAGE } from "../../../../store/constant";
import { SET_LOADING_GLOBAL } from "../../../../store/types";
import useStyles, { BoxWrapper } from "./styles";

firebase.auth().languageCode = "ja";

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const RegisterPhoneOtp = ({ values, handleChangePage }) => {
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const boxWrapperWidth = 569;
  const classes = useStyles();
  const [otp, setOtp] = useState("");
  const dispatch = useDispatch();

  const onChange = (e) => {
    const { value } = e.target;
    setOtp(value);
  };

  const handleLoading = (_value) => {
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: _value,
    });
  };

  const onSubmitOTP = async () => {
    handleLoading(true);
    if (!otp) {
      setErrorMessage("validate.missingVerificationCode");
      handleLoading(false);
      return;
    }
    window.confirmationResult
      .confirm(otp)
      .then(async () => {
        await firebase.auth().signOut();
        const phoneNumber = values.numberPhone;
        const result = await createUserWithPhone(phoneNumber, values.email);
        handleLoading(false);
        if (result && result.data.status === "success") {
          handleChangePage(3);
        } else {
          setErrorMessage("validate.registerNotSuccess");
        }
      })
      .catch((error) => {
        const err = ERROR_CODE_MESSAGE[error.code];
        if (err) {
          setErrorMessage(err);
        } else {
          setErrorMessage(error.message);
        }
        handleLoading(false);
      });
  };

  useEffect(() => {
    const clearError = setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    return () => clearTimeout(clearError);
  });

  return (
    <BoxWrapper width={boxWrapperWidth}>
      <Space space={40} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography className={classes.labelInput}>
          {t("common.numberPhone")}
        </Typography>
        <input
          type="text"
          name="numberPhone"
          placeholder={t("common.phone")}
          value={values?.numberPhone}
          className={classes.input}
          disabled
        />
      </Stack>
      <Space space={10} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography className={classes.labelInput}>
          {t("common.otp")}
        </Typography>
        <input
          type="text"
          name="otp"
          placeholder={t("common.otp")}
          value={otp}
          className={classes.input}
          onChange={onChange}
        />
      </Stack>

      <Space space={10} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography className={classes.labelInput}>
          {t("common.email")}
        </Typography>
        <input
          type="text"
          name="email"
          placeholder={t("common.email")}
          value={values?.email}
          className={classes.input}
          disabled
        />
      </Stack>
      <Space space={10} />

      {errorMessage && (
        <span style={{ fontSize: 12, color: "red" }}>{t(errorMessage)}</span>
      )}

      <Space space={20} />
      <Button
        type="submit"
        variant="outlined"
        text={t("register.register")}
        className={classes.loginBtn}
        onClick={onSubmitOTP}
      ></Button>
      <Space space={30} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("register.reSendVerificationCode")}
        <span
          className={classes.link}
          style={{ cursor: "pointer" }}
          onClick={() => handleChangePage(2)}
        >
          {t("login.clickHere")}
        </span>
      </Typography>
      <Space space={10} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("register.alreadyAccount")}
        <Link className={classes.link} to="/login">
          {t("login.login")}
        </Link>
      </Typography>
      <Space space={10} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("login.toContact")}
        <Link className={classes.link} to="/contact">
          {t("login.clickHere")}
        </Link>
      </Typography>

      <Space space={20} />
      <div id="recaptcha-container"></div>
    </BoxWrapper>
  );
};

RegisterPhoneOtp.propTypes = {
  values: PropTypes.object,
  handleChangePage: PropTypes.func,
};

export default RegisterPhoneOtp;
