import firebase, { getFunctions } from "../../services/firebase";
import { PAYMENT_EVENTS } from "../types";
import {
  GET_MY_INVOICES_INFO,
  GET_PLAN_DETAIL,
  CREATE_SETUP_INTENT,
  GET_PAYMENT_METHOD_LIST,
  DELETE_PAYMENT_METHOD,
} from "../funcs";

const db = firebase.firestore();
const subscriptionsRef = db.collection("subscriptions");
const functions = getFunctions();

export const subscribeToPlayerSubscriptionInfo = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    const { playerId } = getState().user;
    if (playerId && playerId !== "") {
      const unsubscribe = subscriptionsRef
        .doc(playerId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            dispatch({
              type: PAYMENT_EVENTS.SET_PLAYER_SUBSCRIPTION_DATA,
              payload: { ...snapshot.data() },
            });
          }
        });
      setUnsubscribe(unsubscribe);
    }
  };
};

export const getMyInvoices = async (playerId) => {
  try {
    return functions.httpsCallable(GET_MY_INVOICES_INFO)({ playerId });
  } catch (error) {
    console.log(error);
  }
};

export const getPlanDetailData = async (playerId) => {
  try {
    return functions.httpsCallable(GET_PLAN_DETAIL)({ playerId });
  } catch (error) {
    console.log(error);
  }
};

export const createSetupIntent = async (customerId) => {
  try {
    return functions.httpsCallable(CREATE_SETUP_INTENT)({ customerId });
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentMethodList = async (customerId) => {
  try {
    return functions.httpsCallable(GET_PAYMENT_METHOD_LIST)({ customerId });
  } catch (error) {
    console.log(error);
  }
};

export const deletePaymentMethod = async (playerId) => {
  try {
    return functions.httpsCallable(DELETE_PAYMENT_METHOD)({ playerId });
  } catch (error) {
    console.Console(error);
  }
};
