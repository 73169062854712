import { animated } from "react-spring";
import styled from "styled-components";
import { Button } from "../../../../ui/elements/Button";

export const Container = styled.div`
  width: 95%;
  height: ${(props) => props.height}px;
  box-sizing: border-box;
  background-color: #eae1d3;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: ${(props) => props.margin}px;
  justify-content: flex-start;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  height: 30%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 ${(props) => props.padding}px 0;
`;

export const Text = styled.div`
  width: 30%;
  height: 100%;
  padding: ${(props) => props.padding}px;
  box-sizing: border-box;
  & > p {
    font-size: ${(props) => props.fontSize}px;
  }
`;

export const StyleButton = styled(Button)`
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  font-size: ${(props) => props.fontSize / 1.2}px;
  padding: 0 0 !important;
  letter-spacing: 0;
  font-weight: 100;
  text-transform: initial;
  background-image: url("/assets/btn_red.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  white-space: nowrap;
  min-width: 0 !important;
  &:hover {
    font-size: ${(props) => props.fontSize / 1.2}px;
  }
`;

export const CardsContainer = styled.div`
  width: 100%;
  position: relative;
  height: 100%;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 2% 5%;
`;

export const Card = styled.img`
  left: ${(props) => props.left};
  position: absolute;
  box-shadow: 0 0 3px black;
  min-height: 90%;
  max-height: 90%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  border-radius: 4px;
  :hover {
    cursor: pointer;
    box-shadow: 0 0 0 3px #009ee7;
    transform: translate(0px, -15px);
  }
`;

export const CardAnimated = styled(animated.img)`
  box-shadow: 0 0 2px 1px black;
  border-radius: 4%;
  position: absolute;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  :hover {
    box-shadow: 0 0 0 3px #009ee7;
    transform: translate(0px, -15px);
    cursor: pointer;
  }
`;

export const AnimatedContainer = styled(animated.div)`
  position: absolute;
  :hover {
    cursor: not-allowed;
  }
`;

export const StyleSuit = styled.div`
  width: ${(props) => props.width}px !important;
  height: ${(props) => props.height}px !important;
  background-color: #c7b08b !important;
  border-radius: 4px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
`;

export const TextSuit = styled.p`
  font-size: ${(props) => props.fontSize}px;
  font-weight: 600;
  line-height: 1;
`;
