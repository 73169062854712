import { PUSH_ALL_NARRATIONS } from "../types";

const initialState = {
  audioList: {},
};

const audioReducer = (state = initialState, action) => {
  switch (action.type) {
    case PUSH_ALL_NARRATIONS:
      return {
        ...state,
        audioList: action.payload,
      };
    default:
      return state;
  }
};
export default audioReducer;
