import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { PlayerCardsArea } from "../../PlayerCardsArea/PlayerCardsArea";
import PlayerCardWrapper from "./components/PlayerCardWrapper";

import styled from "styled-components";
import GameLeftTop from "./components/GameLeftTop";
import GameLeftMiddle from "./components/GameLeftMiddle";
import { STD_SIZE } from "../../../../../store/constant";

const GameLeftContainer = styled.div`
  width: ${(props) => props.width}px;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
`;

const GameLeftComponent = () => {
  const [height, setHeight] = useState(
    STD_SIZE.STD_H_SHIP_HEIGHT + 2 * STD_SIZE.STD_SHIP_SPACE
  );
  const size = useSelector((state) => state.size);
  const myCardsWidth = useSelector((state) => state.size.myCardWidth);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const bottomHeight = useSelector((state) => state.size.bottomHeight);
  const gameLeftContainerWidth = useMemo(
    () => Math.round(myCardsWidth + shipSpace * 2),
    [myCardsWidth, shipSpace]
  );

  useEffect(() => {
    if (bottomHeight) {
      setHeight(bottomHeight);
    }
  }, [bottomHeight]);

  return (
    <GameLeftContainer width={gameLeftContainerWidth}>
      <GameLeftTop />
      <GameLeftMiddle />
      <PlayerCardWrapper height={height}>
        <PlayerCardsArea size={size} />
      </PlayerCardWrapper>
    </GameLeftContainer>
  );
};
export const GameLeft = React.memo(GameLeftComponent);
