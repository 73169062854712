import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Box,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import { orderBy as orderByLodash } from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { BiSearchAlt } from "react-icons/bi";
import { FaUserCog } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { invoiceType } from "../../../../constants";
import { getUserListPagination } from "../../../../store/actions/playTimeAction";
import { invoiceRef } from "../../../../store/repositories/invoice";
import { SET_ADMIN_LOADING } from "../../../../store/types";
import HeaderCells from "../../../InvoiceSettings/components/HeaderCells";
import { Dialog } from "../index";
import useStyles from "./styles";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const UserList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("email");
  const [page, setPage] = useState(0); // eslint-disable-next-line react-hooks/exhaustive-deps
  const [rows, setRows] = useState([]);
  const [totalPage, setTotalPage] = useState(0);
  const [term, setTerm] = useState("");
  const [pageSize] = useState(7);
  const [openEdit, setOpenEdit] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [selectedPlayerId, setSelectedPlayerId] = useState("");
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => state.admin.isLoading);

  useDocumentTitle(t("common.gConceptTitle") + t("common.userListTitle"));

  const handleClearSelectedUser = useCallback(() => {
    setSelectedUser({});
  }, []);

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectedPlayerId = useCallback((_playerId) => {
    setSelectedPlayerId(_playerId);
  }, []);

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage - 1);
  };

  const headCells = useMemo(
    () => [
      {
        id: "",
        label: "No",
      },
      {
        id: "nickName",
        label: `${t("admin.userName")}`,
      },
      { id: "email", label: `${t("paymentSettings.email")}` },
      { id: "packageName", label: `${t("paymentSettings.planName")}` },
      { id: "startDate", label: `${t("admin.startDate")}` },
      { id: "endDate", label: `${t("admin.endDate")}` },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStorage.getItem("i18nextLng")]
  );

  const handleSearch = (_term) => {
    setTerm(_term);
  };

  useEffect(() => {
    search();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderBy, order, page, pageSize]);

  const handleClickUser = (_user) => {
    if (_user) {
      setSelectedUser(_user);
      setOpenEdit(true);
    }
  };

  const handleLoading = useCallback((_isLoading) => {
    dispatch({
      type: SET_ADMIN_LOADING,
      payload: _isLoading,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const search = async () => {
    dispatch({
      type: SET_ADMIN_LOADING,
      payload: true,
    });
    const isOrder = order === "asc" ? true : false;
    const userList = await getUserListPagination(
      orderBy,
      term,
      isOrder,
      page,
      pageSize
    );

    const { elements, metaData } = userList.data;
    if (elements && elements.length > 0) {
      setRows(elements);
      setTotalPage(metaData.totalPage);
    } else {
      setRows([]);
    }

    dispatch({
      type: SET_ADMIN_LOADING,
      payload: false,
    });
  };

  useEffect(() => {
    let unsubscribe = async () => {};

    if (selectedPlayerId) {
      unsubscribe = invoiceRef
        .where("playerId", "==", selectedPlayerId)
        .onSnapshot((querySnapshot) => {
          let invoices = [];
          querySnapshot.forEach((doc) => {
            invoices.push(doc.data());
          });

          if (invoices.length > 0) {
            const sortedInvoices = orderByLodash(
              invoices,
              "completeDate",
              "desc"
            );
            const newInvoice = {
              ...sortedInvoices[0],
              time: new Date(sortedInvoices[0].completeDate).getTime(),
            };
            if (localStorage.getItem("newInvoiceTimeByPlayer")) {
              const invoicePre = JSON.parse(
                localStorage.getItem("newInvoiceTimeByPlayer")
              );
              if (newInvoice.time > invoicePre.time) {
                const _newInvoice = {
                  time: newInvoice.time,
                  status: newInvoice.status,
                  type: newInvoice.type,
                };
                localStorage.setItem(
                  "newInvoiceTimeByPlayer",
                  JSON.stringify(_newInvoice)
                );

                if (newInvoice.type === invoiceType.SUB) {
                  toast[
                    `${newInvoice.status === "paid" ? "success" : "error"}`
                  ](
                    `${
                      newInvoice.status === "paid"
                        ? t("paymentSettings.ChangePlanUserSuccess")
                        : t("paymentSettings.ChangePlanUserFail")
                    }`
                  );
                  setSelectedPlayerId("");
                  search();

                  dispatch({
                    type: SET_ADMIN_LOADING,
                    payload: false,
                  });
                }
              }
            } else {
              localStorage.setItem(
                "newInvoiceTimeByPlayer",
                JSON.stringify({ time: 0, status: "unpiad", type: "payment" })
              );
            }
          }
        });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedPlayerId]);

  return (
    <Box>
      <Box className={classes.headerWapper}>
        <Box className={classes.headerRight}>
          <Typography className={classes.headerTitle}>
            {t("admin.userList")}
          </Typography>
        </Box>
        <form
          className={classes.btnSearchBox}
          onSubmit={(e) => {
            e.preventDefault();
            setPage(0);
            search();
          }}
        >
          <input
            placeholder={`${t("admin.enterSearchMail")}`}
            type="text"
            onChange={(e) =>
              handleSearch(e.target.value.toString().trim().toLowerCase())
            }
          />
          <IconButton color="secondary" aria-label="seach button" type="submit">
            <BiSearchAlt />
          </IconButton>
        </form>
      </Box>
      <Box className={classes.invoice}>
        <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            height: "100%",
            flexDirection: "column",
          }}
        >
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{ backgroundColor: "transparent", boxShadow: "unset" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              className={classes.table}
              style={{ minWidth: "unset" }}
            >
              <HeaderCells
                headCells={headCells}
                orderBy={orderBy}
                order={order}
                createSortHandler={createSortHandler}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  rows.map((row, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell align="center" className={classes.tableCell}>
                        {index + 1 + page * pageSize}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.nickName}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {row.email}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {t("paymentSettings." + row.packageName)}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {moment(row.startDate).format("YYYY/MM/DD")}
                      </TableCell>
                      <TableCell align="center" className={classes.tableCell}>
                        {moment(row.endDate).format("YYYY/MM/DD")}
                      </TableCell>

                      <TableCell align="center" className={classes.tableCell}>
                        <IconButton
                          color="secondary"
                          aria-label="seach button"
                          style={{ color: "black" }}
                          onClick={() => handleClickUser(row)}
                        >
                          <FaUserCog />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            </Table>
            {rows && rows.length <= 0 && (
              <Box sx={{ padding: "2rem 0", textAlign: "center" }}>
                <Typography>{t("dialog.noData")}</Typography>
              </Box>
            )}
          </TableContainer>
          <Box className={classes.invoiceBottom}>
            {rows && rows.length > 0 && (
              <Stack spacing={2} alignItems="center" height="100%">
                <Pagination
                  count={totalPage}
                  className={classes.pagination}
                  onChange={handleChangePage}
                  page={page + 1}
                />
              </Stack>
            )}
          </Box>
        </Box>
      </Box>
      <Dialog.EditUser
        open={openEdit}
        handleClose={() => setOpenEdit(false)}
        dataUser={selectedUser}
        handleLoading={handleLoading}
        handleGetData={search}
        handleSelectedPlayerId={handleSelectedPlayerId}
        reloadData={search}
        clearDataUser={handleClearSelectedUser}
      />
    </Box>
  );
};

export default UserList;
