import { TextField } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  input: {},
}));

function MuiInput(props) {
  const {
    autoFocus,
    name,
    label,
    value,
    onChange,
    type,
    required,
    disabled,
    maxLength,
  } = props;

  const classes = useStyles();

  return (
    <TextField
      fullWidth
      InputLabelProps={{ shrink: true, style: { color: "#541513" } }}
      autoFocus={autoFocus}
      required={required}
      name={name}
      label={label}
      value={value}
      onChange={onChange}
      type={type}
      autoComplete="off"
      disabled={disabled}
      InputProps={{
        maxLength: maxLength,
      }}
      className={classes.input}
    />
  );
}

MuiInput.propTypes = {
  autoFocus: PropTypes.bool,
  name: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  // error: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  maxLength: PropTypes.number,
  color: PropTypes.string,
};

export default MuiInput;
