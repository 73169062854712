import moment from "moment";

moment.locale("ja");

export function formatTimestamp(timestamp = -1) {
  if (timestamp === -1) return;
  const time = moment(timestamp).format("YYYY/MM/DD, HH:mm");

  return `${time}`;
}

export function formatDateJapan(time) {
  return moment(time).format("LL");
}
