import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { ReactComponent as IconTotalChat } from "../../../assets/icons/icon-total-chat.svg";
import tableIcon from "../../../assets/icons/table.png";
import userIcon from "../../../assets/icons/user.png";
import ChatStatus from "../../../components/Status/ChatStatus";
import PlayerStatus from "../../../components/Status/PlayerStatus";
import TableStatus from "../../../components/Status/TableStatus";
import { subscribePlayer } from "../../../store/actions/playerActions";
import TabList from "../TabList";
import { Rnd } from "react-rnd";

function StatusTab({ isOpen = false, toggle, coordinates }) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [scaleHeight, setScaleHeight] = useState(1);
  const tabs = [
    {
      label_1: t("status.onlineUserList_1"),
      label_2: t("status.onlineUserList_2"),
      icon: <img width="100%" height="100%" src={userIcon} />,
      side: <PlayerStatus scaleHeight={scaleHeight} />,
    },
    {
      label_1: t("status.tableList_1"),
      label_2: t("status.tableList_2"),
      icon: <img width="100%" height="100%" src={tableIcon} />,
      side: <TableStatus />,
    },
    {
      label_1: t("status.currentStatus_1"),
      label_2: t("status.currentStatus_2"),
      icon: <IconTotalChat />,
      side: <ChatStatus />,
    },
  ];

  useEffect(() => {
    let unsubscribe = () => {};

    dispatch(subscribePlayer((func) => (unsubscribe = func)));

    return () => {
      unsubscribe();
    };
  }, [dispatch]);

  if (!isOpen) return null;
  const style = {
    boxSizing: "border-box",
    borderRadius: "10px",
    padding: "10px",
    backgroundColor: "#442211",
    zIndex: 999,
  };
  return (
    <Rnd
      style={style}
      default={{
        x: coordinates.x,
        y: coordinates.y,
        width: 400,
        height: 765,
      }}
      minWidth={400}
      maxWidth={400}
      minHeight={600}
      onResizeStop={(e, direction, ref) => {
        setScaleHeight(ref.style.height.split("px")[0] / 765);
      }}
    >
      <TabList tabs={tabs} toggle={toggle} />
    </Rnd>
  );
}

StatusTab.propTypes = {
  isOpen: PropTypes.bool,
  toggle: PropTypes.func,
  coordinates: PropTypes.object,
};
export default StatusTab;
