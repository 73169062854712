import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { seenNotify } from "../../store/actions/friendAction";
import { NOTIFY } from "../../store/constant";
import { formatTimestamp } from "../../utils/time";
import { AcceptFriend, Timestamp } from "./styles";

function AcceptFriendNotify({ notify, myId }) {
  const { t } = useTranslation();
  return (
    <AcceptFriend
      onClick={() =>
        seenNotify({
          myId: myId,
          type: NOTIFY.ACCEPT_FRIEND,
          notifyId: notify?.id,
        })
      }
      bg={notify?.seen ? "#e9e3d7" : "#c9bc9d"}
    >
      <p>
        <span style={{ fontWeight: 600 }}>{notify?.friendName}</span>
        {t("friends.approveMessage")}
      </p>
      <Timestamp>{formatTimestamp(notify?.timestamp)}</Timestamp>
    </AcceptFriend>
  );
}

AcceptFriendNotify.propTypes = {
  notify: PropTypes.object,
  myId: PropTypes.string,
};

export default AcceptFriendNotify;
