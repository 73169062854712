import styled, { css } from "styled-components";
import { Button } from "../../../../../ui/elements/Button";
import { Profile } from "../../Profile/Profile";

export const Container = styled.div`
  width: 500px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1024px) {
    max-width: 290px;
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  height: 25%;
  position: relative;
  box-sizing: border-box;
`;

export const MiddleWrapper = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  position: relative;
`;

export const ModifiedProfile = styled(Profile)``;

export const ProfileWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  box-sizing: border-box;
  background-color: #311c0a;
  display: flex;
  align-items: flex-start;
`;
export const LeaveRoomBtn = styled(Button)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  font-family: "Source Serif Pro";
  font-weight: 400;
  letter-spacing: 0;
  font-weight: 100;
  text-transform: uppercase;
  background-image: url("/assets/btn_blue.png");
  padding: ${(props) => props.fontSize / 1.2}px 0;
  min-width: 0 !important;
  ${(props) =>
    props.disable &&
    css`
      opacity: 0.6;
    `}
`;

export const BoxLeaveRoom = styled.div`
  width: ${(props) => props.width}px;
  height: 100% !important;
  display: flex;
  flex-direction: "column !important";
  align-items: flex-start;
  justify-content: flex-end;
  flex: 1;
`;
