import PropTypes from "prop-types";
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{
        flex: 1,
        boxSizing: "border-box",
        padding: "0 5px 5px 5px",
        overflow: "hidden",
      }}
      {...other}
    >
      {value === index && (
        <div
          style={{
            border: "1px solid #442211",
            boxSizing: "border-box",
            height: "calc(100%)",
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
};

export default TabPanel;
