// COMMONS
export const GET_STATE = "GET_STATE";

// AUTH
export const SET_ERROR = "SET_ERROR";
export const SET_SUCCESS = "SET_SUCCESS";
export const SET_USER_LOGOUT = "SET_USER_LOGOUT";

// USER
export const SET_USER_INFO = "SET_USER_INFO";
export const SET_FIRST_LOGIN = "SET_FIRST_LOGIN";
export const SET_FRIENDS = "SET_FRIENDS";
export const SET_DEFAULT_TABLE = "SET_DEFAULT_TABLE";
export const SET_NARRATION = "SET_NARRATION";
export const LEAVE_ROOM = "LEAVE_ROOM";

// TABLE
export const SET_TABLE_LIST = "SET_TABLE_LIST";
export const SET_PAGE = "SET_PAGE";
export const SET_TABLE_EMPTY = "SET_TABLE_EMPTY";

// Packages
export const SET_PACKAGE_LIST = "SET_PACKAGE_LIST";
export const SET_ACTIVE_PACKAGE = "SET_ACTIVE_PACKAGE";
export const SET_ALL_PACKAGES = "SET_ALL_PACKAGES";

// JOINED TABLE
export const SET_TABLE_DATA = "SET_TABLE_DATA";

//SUBSCRIBE
export const SET_SUBSCRIBE = "SET_SUBSCRIBE";
export const SET_UNSUBSCRIBE = "SET_UNSUBSCRIBE";

//INVITE
export const ADD_INVITE = "ADD_INVITE";
export const REMOVE_INVITE = "REMOVE_INVITE";
export const SEEN_INVITE = "SEEN_INVITE";
export const SEEN_ALL_INVITE = "SEEN_ALL_INVITE";

// VIDEO GAME
export const JOIN_AUDIO = "JOIN_AUDIO";
export const JOIN_VIDEO = "JOIN_VIDEO";
export const NEW_JOIN_GUY = "NEW_JOIN_GUY";
export const CLEAR_ALL_TWILIO = "CLEAR_ALL_TWILIO";
export const OFFLINE_PLAYER_TWILIO = "OFFLINE_PLAYER_TWILIO";
export const TWILIO_ACTIVE_ROOM = "TWILIO_ACTIVE_ROOM";
export const ADD_LOCAL_MEDIA_TWILIO = "ADD_LOCAL_MEDIA_TWILIO";
export const SET_LOADING_VIDEO = "SET_LOADING_VIDEO";
export const SET_LOADING_AUDIO = "SET_LOADING_AUDIO";
export const SET_IS_CONNECT_TWILIO = "SET_IS_CONNECT_TWILIO";

//ROOM
export const SET_ROOM = "SET_ROOM";
export const JOIN_ROOM = "JOIN_ROOM";
export const SET_JOIN_TABLE = "SET_JOIN_TABLE";
export const OTHER_PROFILE = "OTHER_PROFILE";
export const CLEAR_ROOM = "CLEAR_ROOM";

// PLAYER GAME STATE
// export const UPDATE_MY_INDEX = "UPDATE_MY_INDEX";
export const SET_PLAYER_GAME_STATE = "SET_PLAYER_GAME_STATE";
export const SET_PLAYER_GAME_STATE_REQUEST = "SET_PLAYER_GAME_STATE_REQUEST";
export const REMOVE_PLAYER_GAME_STATE = "REMOVE_PLAYER_GAME_STATE";
export const SET_MY_NUMBER_OF_CALLS = "SET_MY_NUMBER_OF_CALLS";
export const UPDATE_APPLY_CALLS = "UPDATE_APPLY_CALLS";
export const PLAY_A_CARD_GAME_STATE = "PLAY_A_CARD_GAME_STATE";
export const OTHER_PLAYER_HAND_CARDS = "OTHER_PLAYER_HAND_CARDS";
export const OTHER_PLAYER_HAND_CARDS_REQUEST =
  "OTHER_PLAYER_HAND_CARDS_REQUEST";
export const PLAYER_INTRODUCE_COMPLETE = "PLAYER_INTRODUCE_COMPLETE";
export const PLAYER_CHANGE_STEP = "PLAYER_CHANGE_STEP";
export const ROOM_PLAYER_OVER_GET = "ROOM_PLAYER_OVER_GET";
export const PLAYER_CHANGE_COMMIT_FIRST = "PLAYER_CHANGE_COMMIT_FIRST";
export const PLAYER_UPDATE_NEW_OTHER_PLAYER = "PLAYER_UPDATE_NEW_OTHER_PLAYER";
export const PLAYER_SAVE_NEW_OTHER_PLAYER = "PLAYER_SAVE_NEW_OTHER_PLAYER";
export const PLAYER_SAVE_GRAND_CROSS_ROUND12 =
  "PLAYER_SAVE_GRAND_CROSS_ROUND12";
export const PLAYER_UPDATE_GRAND_CROSS_ROUND12 =
  "PLAYER_UPDATE_GRAND_CROSS_ROUND12";
export const PLAYER_SAVE_OTHER_PLAYER_ROUND13 =
  "PLAYER_SAVE_OTHER_PLAYER_ROUND13";
export const PLAYER_UPDATE_OTHER_PLAYER_ROUND13 =
  "PLAYER_UPDATE_OTHER_PLAYER_ROUND13";
export const UPDATE_GRAND_CROSS_ONLY = "UPDATE_GRAND_CROSS_ONLY";
export const UPDATE_TEMP_START_GAME_GRAND_CROSS =
  "UPDATE_TEMP_START_GAME_GRAND_CROSS";
export const PLAYER_SAVE_NEW_SUIT_STATE = "PLAYER_SAVE_NEW_SUIT_STATE";
export const PLAYER_UPDATE_NEW_SUIT_STATE = "PLAYER_UPDATE_NEW_SUIT_STATE";
export const PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS =
  "PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS";
export const PLAYER_UPDATE_NEW_SUIT_STATE_OTHER_STATE =
  "PLAYER_UPDATE_NEW_SUIT_STATE_OTHER_STATE";
export const PLAYER_UPDATE_NEW_SUIT_STATE_REQUEST =
  "PLAYER_UPDATE_NEW_SUIT_STATE_REQUEST";
export const PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS_ONLY =
  "PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS_ONLY";
export const PLAYER_SAVE_NEW_SUIT_GRAND_CROSS_ONLY =
  "PLAYER_SAVE_NEW_SUIT_GRAND_CROSS_ONLY";
export const PLAYER_UPDATE_MASTER_GRAND_CROSS_ON_DISPLAY =
  "PLAYER_UPDATE_MASTER_GRAND_CROSS_ON_DISPLAY";
export const HAND_CARDS_ROUND_12 = "HAND_CARDS_ROUND_12";
export const NICKNAMES_PLAYERS_SELECT_ORDER_CARDS =
  "NICKNAMES_PLAYERS_SELECT_ORDER_CARDS";
export const CLEAR_NEWEST_PLAYER_OTHER_STATE =
  "CLEAR_NEWEST_PLAYER_OTHER_STATE";
export const ADD_PLAYER_GAME_STATE_NOTIFY_STEP =
  "ADD_PLAYER_GAME_STATE_NOTIFY_STEP";
export const ADD_PLAYER_GAME_STATE_LEAVE_ROOM_NOTIFICATION_STEP =
  "ADD_PLAYER_GAME_STATE_LEAVE_ROOM_NOTIFICATION_STEP";
export const CLEAR_PLAYER_GAME_STATE = "CLEAR_PLAYER_GAME_STATE";

// INSTRUCTOR NOTIFY
export const ADD_INSTRUCTOR_MESSAGE = "ADD_INSTRUCTOR_MESSAGE";
export const ADD_INSTRUCTOR_MESSAGE_CODE = "ADD_INSTRUCTOR_MESSAGE_CODE";
export const REMOVE_INSTRUCTOR_MESSAGE = "REMOVE_INSTRUCTOR_MESSAGE";
export const REMOVE_INSTRUCTOR_MESSAGE_CODE = "REMOVE_INSTRUCTOR_MESSAGE_CODE";

export const ADD_NARRATION = "ADD_NARRATION";
export const CLEAR_NARRATION = "CLEAR_NARRATION";
export const DONE_NARRATION = "DONE_NARRATION";
export const ADD_NARRATION_SCREEN = "ADD_NARRATION_SCREEN";
export const ADD_NARRATION_POPUP = "ADD_NARRATION_POPUP";
export const REMOVE_NARRATION_SCREEN = "REMOVE_NARRATION_SCREEN";
export const REMOVE_NARRATION_POPUP = "REMOVE_NARRATION_POPUP";
export const REMOVE_STOP_CURRENT_NARRATION = "REMOVE_STOP_CURRENT_NARRATION";
export const STOP_CURRENT_NARRATION = "STOP_CURRENT_NARRATION";
export const ADD_FUNC_STOP_CURRENT_NARRATION =
  "ADD_FUNC_STOP_CURRENT_NARRATION";
export const UPDATE_DURATION = "UPDATE_DURATION";
export const UPDATE_CONTENT_NARRATION = "UPDATE_CONTENT_NARRATION";
export const REQUEST_UPDATE_CONTENT_NARRATION =
  "REQUEST_UPDATE_CONTENT_NARRATION";

/// STEP Reducer queue
export const ADD_STEP_NARRATION = "ADD_STEP_NARRATION";
export const UPDATE_STEP_ID = "UPDATE_STEP_ID";
export const ADD_NOTIFY_STEP = "ADD_NOTIFY_STEP";
export const ADD_LEAVE_ROOM_NOTIFY_STEP = "ADD_LEAVE_ROOM_NOTIFY_STEP";
export const UPDATE_STEP_NARRATION = "UPDATE_STEP_NARRATION";
export const DONE_STEP_NARRATION = "DONE_STEP_NARRATION";
export const CLEAR_STEP_NARRATION = "CLEAR_STEP_NARRATION";

// PLAY CARD
export const DELAY_G_CHAOS_PLAY_CARD = "DELAY_G_CHAOS_PLAY_CARD";

// SELECT MASTER CARD
export const UPDATE_NICKNAME_PLAYER_SELECT_MASTER_CARD =
  "UPDATE_NICKNAME_PLAYER_SELECT_MASTER_CARD";

export const NOT_SHOW_ARK_AFTER_USE_MASTER_CARD =
  "NOT_SHOW_ARK_AFTER_USE_MASTER_CARD";
// RESIZE
export const SCREEN_RESIZE = "SCREEN_RESIZE";
export const ADD_INSTRUCTOR_CONTENT = "ADD_INSTRUCTOR_CONTENT";
export const CLEAR_INSTRUCTOR_STATE = "CLEAR_INSTRUCTOR_STATE";
export const BACKUP_GRANDCROSS = "BACKUP_GRANDCROSS";
export const REMOVE_GCHAOS_SELECT_FIRST_CARD =
  "REMOVE_GCHAOS_SELECT_FIRST_CARD";
export const GCHAOS_SELECT_ORDER_CARD = "GCHAOS_SELECT_ORDER_CARD";
export const STATE_13 = "STATE_13";
export const PAYMENT_EVENTS = {
  SET_PAYMENT_INFO: "SET_PAYMENT_INFO",
  SET_PAYMENT_PACKAGES: "SET_PAYMENT_PACKAGES",
  SET_PLAYER_SUBSCRIPTION_DATA: "SET_PLAYER_SUBSCRIPTION_DATA",
};
export const G_CHAOS_AFTER_CAPTAIN = "G_CHAOS_AFTER_CAPTAIN";
export const LOCAL_SAVE_PLAYER_GAME_STATE = "LOCAL_SAVE_PLAYER_GAME_STATE";
export const UPDATE_PLAYER_GAME_STATE_FROM_LOCAL_SAVED =
  "UPDATE_PLAYER_GAME_STATE_FROM_LOCAL_SAVED";
export const MESSAGE_END = "MESSAGE_END";
export const UPDATE_NEW_SUIT_GRAND_CROSS_ONLY =
  "UPDATE_NEW_SUIT_GRAND_CROSS_ONLY";
export const SAVE_IS_MY_TURN = "SAVE_IS_MY_TURN";
export const UPDATE_IS_MY_TURN = "UPDATE_IS_MY_TURN";
export const UPDATE_OTHER_IS_MY_TURN = "UPDATE_OTHER_IS_MY_TURN";
export const UPDATE_OTHER_PLAYER_IS_MY_TURN_REQUEST =
  "UPDATE_OTHER_PLAYER_IS_MY_TURN_REQUEST";
export const UPDATE_OTEHR_PLAYER_IS_MY_TURN = "UPDATE_OTEHR_PLAYER_IS_MY_TURN";
export const SHOW_G_CHAOS_TURN_SELECT_MASTER_CARD_REQUEST =
  "SHOW_G_CHAOS_TURN_SELECT_MASTER_CARD_REQUEST";
export const UPDATE_OTHER_PLAYERS_STATE = "UPDATE_OTHER_PLAYERS_STATE";
export const SAVE_OTHER_PLAYERS_STATE_LOCAL = "SAVE_OTHER_PLAYERS_STATE_LOCAL";
export const HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST =
  "HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST";
export const UPDATE_OTHER_PLAYERS_STATE_FROM_LOCAL =
  "UPDATE_OTHER_PLAYERS_STATE_FROM_LOCAL";

/** UPDATE STATE USE MASTER CARDS */
export const REQUEST_UPDATE_STATE_USE_MASTER_CARDS =
  "REQUEST_UPDATE_STATE_USE_MASTER_CARDS";
export const UPDATE_OTHER_STATE_USE_MASTER_CARDS =
  "UPDATE_OTHER_STATE_USE_MASTER_CARDS";
export const REQUEST_UPDATE_STATE_G_CHAOS_SELECT_MASTER_CARD =
  "REQUEST_UPDATE_STATE_G_CHAOS_SELECT_MASTER_CARD";
export const UPDATE_OTHER_STATE_G_CHAOS_SELECT_MASTER_CARD =
  "UPDATE_OTHER_STATE_G_CHAOS_SELECT_MASTER_CARD";
export const REQUEST_RESET_OTHER_STATE_USE_MASTER_CARD =
  "REQUEST_RESET_OTHER_STATE_USE_MASTER_CARD";
export const RESET_OTHER_STATE_USE_MASTER_CARD =
  "RESET_OTHER_STATE_USE_MASTER_CARD";
export const UPDATE_CAN_SELECT_MC = "UPDATE_CAN_SELECT_MC";

/** UPDATE ORDER CARDS */
export const UPDATE_ORDER_CARDS = "UPDATE_ORDER_CARDS";
export const REQUEST_UPDATE_TURN_PLAYER_SELECT_CARD =
  "REQUEST_UPDATE_TURN_PLAYER_SELECT_CARD";
export const UPDATE_TURN_PLAYER_SELECT_CARD = "UPDATE_TURN_PLAYER_SELECT_CARD";
export const REQUEST_UPDATE_TURN_G_CHAOS_SELECT_CARD =
  "REQUEST_UPDATE_TURN_G_CHAOS_SELECT_CARD";
export const UPDATE_TURN_G_CHAOS_SELECT_CARD =
  "UPDATE_TURN_G_CHAOS_SELECT_CARD";
export const UPDATE_TURN_SELECT_ORDER_CARD = "UPDATE_TURN_SELECT_ORDER_CARD";
export const SAVE_OTHER_PLAYER_STATE = "SAVE_OTHER_PLAYER_STATE";
export const REQUEST_UPDATE_TURN_ORDER_CARD = "REQUEST_UPDATE_TURN_ORDER_CARD";
export const REQUEST_SHOW_MASTER = "REQUEST_SHOW_MASTER";
export const UPDATE_SHOW_MASTER = "UPDATE_SHOW_MASTER";
export const RESET_TURN_WAIT_REQUEST_PLEASE = "RESET_TURN_WAIT_REQUEST_PLEASE";
export const UPDATE_WIN_GAME_SHOWED = "UPDATE_WIN_GAME_SHOWED";
export const UPDATE_START_TURN_TIME = "UPDATE_START_TURN_TIME";
export const UPDATE_CAN_SELECT_CARD = "UPDATE_CAN_SELECT_CARD";
export const CHECK_SHOW_DIALOG_MASTER_PICKING_CARD =
  "CHECK_SHOW_DIALOG_MASTER_PICKING_CARD";
export const PLAY_CARD_LOCAL = "PLAY_CARD_LOCAL";
export const DOUBLE_CLICK_HAND_CARD = "DOUBLE_CLICK_HAND_CARD";

/** AUDIO */
export const PUSH_ALL_NARRATIONS = "PUSH_ALL_NARRATIONS";

/** RELOAD NOTIFICATION */
export const SET_NEED_RELOAD = "SET_NEED_RELOAD";

/** NOTIFY G-CHAOS TURN PLAY CARD */
export const SET_NOTIFY_G_CHAOS_TURN = "SET_NOTIFY_G_CHAOS_TURN";

/** NOTIFY G-CHAOS TURN PLAY CARD */
export const SET_PLAYER_LIST = "SET_PLAYER_LIST";

/** STREAM MESSAGE */
export const SET_STREAMS = "SET_STREAMS";

/** CHAT IN ROOMS */
export const SET_MESSAGES_IN_ROOM = "SET_MESSAGES_IN_ROOM";
export const SET_THREAD_IN_ROOM = "SET_THREAD_IN_ROOM";
export const OPEN_CHAT_BOX = "OPEN_CHAT_BOX";
export const CLOSE_CHAT_BOX = "CLOSE_CHAT_BOX";
export const SET_DATA_LIST_ROOM = "SET_DATA_LIST_ROOM";
export const SET_DATA_MESSAGE_BY_LIST_ROOM = "SET_DATA_MESSAGE_BY_LIST_ROOM";
export const OPEN_CHAT_BOX_BY_THREAD_ID = "OPEN_CHAT_BOX_BY_THREAD_ID";
export const SET_ROOM_SELECTED_ID = "SET_ROOM_SELECTED_ID";
export const RESET_DATA = "RESET_DATA";
export const UN_READ_MESSAGE = "UN_READ_MESSAGE";
export const SET_SELECTED_THREAD_ID = "SET_SELECTED_THREAD_ID";
export const SET_CHAT_STATUS = "SET_CHAT_STATUS";
export const SET_STATUS_ONLINE = "SET_STATUS_ONLINE";

//payment
export const SET_PAYMENT_LOADING = "SET_PAYMENT_LOADING";

//Admin
export const SET_ADMIN_LOADING = "SET_ADMIN_LOADING";
export const SET_ADMIN_ID = "SET_ADMIN_ID";

//PLAY TIME
export const SET_PLAY_TIME = "SET_PLAY_TIME";
export const CLOSE_DIALOG_OVER_PLAY_TIME = "CLOSE_DIALOG_OVER_PLAY_TIME";

//PLAYING TABLES
export const SET_PLAYING_TABLE_LOADING = "SET_PLAYING_TABLE_LOADING";
export const SET_PLAYING_TABLE = "SET_PLAYING_TABLE";

//GLOBAL
export const SET_LOADING_GLOBAL = "SET_LOADING_GLOBAL";

//PAYMENT LOADING
export const BUYING_PLAN_OR_PACKAGE = "BUYING_PLAN_OR_PACKAGE";

