import { Container, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import Button from "../../components/Button";
import DialogForgotPassword from "../../components/DialogForgotPassword";
import Backdrop from "../../components/Backdrop";
import Input from "../../components/Input";
import { sendMailResetPass } from "../../store/actions/authActions";
import { validateEmail } from "../../utils/validation";
import useDocumentTitle from "../../hooks/useDocumentTitle";
const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  form: {
    marginTop: theme.spacing(5),
    maxWidth: "450px",
    minWidth: "400px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    "& > *": {
      margin: theme.spacing(2),
    },
  },
  submitButton: {
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    height: theme.spacing(5),
  },
  forgot: {
    alignSelf: "flex-end",
    top: "18%",
    marginRight: "3%",
    position: "fixed",
  },
  forgotLink: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    color: theme.palette.primary.dark,
  },
  loginBtn: {
    height: 50,
    width: 200,
    border: "2px solid black",
    backgroundColor: "#ce5931",
  },
}));

const ForgotPassword = () => {
  const classes = useStyles();
  const [email, setEmail] = useState("");
  const [validateError, setValidateError] = useState("");
  const [error, setError] = useState("");
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const history = useHistory();
  const input_W = 372;
  const input_H = 51;
  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.forgotPasswordTitle"));
  useEffect(() => {
    const clearError = setTimeout(() => {
      setError("");
    }, 3000);
    return () => clearTimeout(clearError);
  }, [error]);

  useEffect(() => {
    setValidateError(validateError);
    setError(error);
  }, [validateError, error]);

  const onChange = (e) => {
    setValidateError("");
    setEmail(e.target.value);
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    const err = validateEmail(email);
    setValidateError(err);
    if (err === "") {
      setLoading(true);
      const data = await sendMailResetPass(email);
      if (data.status == "fail") {
        if (data.reason == "auth/user-not-found")
          setError(t("errors.forgotPasswordUserNotFound"));
        else {
          if (data.reason == "errors.reSendVerifyEmail") {
            setError(t("errors.emailIsNotVerified"));
          }
        }
      } else {
        setLoading(true);
        setOpen(true);
      }
    }
  };

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  return (
    <Container className={classes.root}>
      <Backdrop open={loading} />
      <form className={classes.form}>
        <Typography variant="h4" style={{ color: "#541f1c" }}>
          {t("forgotPassword.resetPassword")}
        </Typography>
        <Input
          value={email}
          onChange={onChange}
          type="email"
          name="email"
          placeHolder={t("common.email")}
          autoFocus={true}
          error={t(validateError)}
          width={input_W}
          height={input_H}
        />
        {validateError === "validate.invalidEmail" ? (
          // <>
          //   <div style={{ height: "20px" }} />
          //   <ErrorMessage message={t(validateError.email)} />
          // </>
          <p
            style={{
              width: "372px",
              margin: "15px",
              color: "#dc3233",
              textAlign: "center",
              fontSize: 14,
              fontWeight: "600",
            }}
          >
            {t("validate.invalidEmail")}
          </p>
        ) : validateError === "validate.isRequired" ? (
          <p
            style={{
              width: "372px",
              margin: "15px",
              color: "#dc3233",
              textAlign: "center",
              fontSize: 14,
              fontWeight: "600",
            }}
          >
            {t("errors.loginEmailRequire")}
          </p>
        ) : (
          <></>
        )}
        <Button
          type="submit"
          variant="outlined"
          text={t("forgotPassword.send")}
          // background="#ce5931"
          onClick={onSubmit}
          className={classes.loginBtn}
        ></Button>
      </form>
      <DialogForgotPassword open={open} onClose={handleClose} />
    </Container>
  );
};

export default ForgotPassword;
