import React from "react";
import PropTypes from "prop-types";
import { Voice } from "../styles";

const VideoComponent = ({ src, width, height }) => {
  return <Voice src={src} width={width} height={height} />;
};

VideoComponent.propTypes = {
  src: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

export default VideoComponent;
