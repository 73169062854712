import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
const useStyles = makeStyles(() => ({
  dialogPaper: {
    backgroundColor: "rgba(176, 195, 199) !important",
    borderRadius: "40px !important",
    minWidth: "800px !important",
    overflow: "unset !important",
  },
  form: {
    height: "auto",
    // "&>*": {
    //   marginBottom: "15px",
    // },
  },
  input: {
    borderRadius: "20px !important",
    color: "#000 !important",
  },
  boxButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-evenly",
    gap: "10px",
    width: "80%",
    margin: "auto",
    height: 120,
    paddingBottom: "20px",
    "& > *": {
      textTransform: "capitalize",
    },
  },
  mainBox: {
    // display: "flex",
    // overflowY: "auto",
    // overflowX: "hidden",
    // justifyContent: "flex-start",
    // alignItems: "center",
    // height: "100%",
    // width: "100%",
    // flexDirection: "column",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    "& span": {
      marginLeft: "12px",
    },
  },
  info: {
    marginLeft: "1rem !important",
  },
  currentInfo: {},
  arrow: {
    fontSize: "30px",
    width: "100px",
  },
  field: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "1rem 0 !important",
  },
  label: {
    width: "120px !important",
    marginLeft: "1rem !important",
  },

  iconBox: {
    display: "flex !important",
    alignItems: "center !important",
    height: "60px !important",
    fontSize: "30px !important",
  },
  errorText: {
    textAlign: "center",
  },
}));

export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  border-radius: 40px;
  justify-content: space-evenly;
`;

export const TitleWrapper = styled.div`
  display: flex;
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column !important ;
`;

export const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

export const ErrorText = styled.p`
  color: rgb(220, 50, 51);
  font-weight: 600;
  width: 376px;
  font-size: 14px;
  margin: auto;
`;

export default useStyles;
