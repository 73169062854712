import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { COLOR_RED_BROWN } from "../../../store/constant";

const useStyles = makeStyles(() => ({
  loginBtn: {
    height: 50,
    width: 200,
    border: "2px solid black !important",
    margin: "0 !important",
    backgroundColor: `${COLOR_RED_BROWN} !important`,
  },
  subTitle: {
    fontWeight: "bold !important",
  },
  title: {
    fontWeight: "bold !important",
    fontSize: "28px !important",
  },
}));

export const BoxWrapper = styled.div`
  position: relative;
  width: 300px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem;
`;

export const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;
export default useStyles;
