export const CHANGE_POSITION_FUNC = "changePosition";
export const JOIN_ROOM_FUNC = "joinRoom";
export const LEAVE_ROOM_FUNC = "leaveRoom";
export const GET_TIMESTAMP = "getTimeStamp";
export const TURN_TIMEOUT = "turnTimeOut";
export const DECLARE_NUMBER_OF_CALLS = "declareNumberOfCalls";
export const APPLY_NUMBER_OF_CALLS = "applyNumberOfCalls";
export const NORMAL_PLAY_CARD = "normalPlayCard";
export const DETERMINE_PLAYER_TURN = "determinePlayerTurn";
export const SELECT_ORDER_CARD = "selectOrderCard";
export const START_TIMER_FUNC = "startTimer";
export const REQUEST_PLEASE = "requestPlease";
export const REJECT_NUMBER_OF_CALLS = "rejectNumberOfCalls";
export const SELECT_MASTER_CARD = "selectMasterCard";
export const G_CHAOS_HUMAN_SELECT_MASTER_CARD = "gChaosHumanSelectMasterCard";
export const CLEAR_PLAYER_GAME_STATE = "clearPlayerGameState";
export const CLEAR_GAME_STATE_OF_ONE_PLAYER = "clearGameStateOfOnePlayer";
export const CLEAR_ALL_PLAYER_GAME_STATE_END_GAME =
  "clearAllPlayerGameStateEndGame";
export const CREATE_GAME = "createGame";
export const STOP_TUTORIAL_GAME = "stopTutorial";
export const STEP_NARRATION_FUNC = "stepNarration";
export const SEND_MAIL_RESET_PASSWORD = "sendMailResetPassword";
export const GET_PACKAGE_INFO = "getPackageInfo";
export const GENERATE_INVITATION_LINK = "generateInvitationLink";
export const CHECK_INVITATION_LINK = "checkInvitationLink";
export const COUNT_INVITATION = "countInvitation";
export const CREATE_EMPTY_SUBSCRIPTION = "createEmptySubscription";
export const GET_PLAYER_SUBSCRIPTION_INFO = "getPlayerSubscriptionInfo";
export const CREATE_CARD_CHECKOUT_SESSION = "createCardCheckoutSession";
export const UNSUBSCRIBE_PACKAGE = "unsubscribePackage";
export const TRACK_PLAY_TIME = "trackPlayTime";
export const CHECK_PLAYER_TRIAL = "checkPlayerTrial";
export const RESEND_VERIFICATION_EMAIL = "resendVerificationEmail";
export const REMOVE_TABLE = "removeTable";
export const CREATE_GAME_ROOM = "createGameRoom";
export const REMOVE_FIRST_NOTIFICATION = "removeFirstNotification";
export const LEAVE_ROOM_WHILE_PLAYING = "leaveRoomWhilePlaying";
export const CHECK_OWNER_STATUS = "checkOwnerStatus";
export const CLEAR_MY_PLAYER_GAME_STATE_CONFIRM_SOMEONE_LEAVE =
  "clearMyGameStateConfirmSomeOneLeave";
export const PLUS_PLAYER_READY = "plusPlayerReady";
export const CONFIRM_NO_CALL_END_GAME = "confirmNoCallEndGame";
export const CONFIRM_START_GAME_NO_CALL = "confirmStartGameNoCall";
export const CLEAR_CONFIRM_START_GAME_NO_CALL = "clearConfirmStartGameNoCall";
export const SUBSCRIBE_TO_BRONZE_PACKAGE = "subscribeToBronzePackage";
export const GET_MY_INVOICES_INFO = "getMyInvoiceInfo";
export const SWITCH_PACKAGE = "switchPackages";
export const CREATE_PAYMENT_CUSTOMER = "createPaymentCustomer";
export const GET_PLAN_DETAIL = "getPlanDetail";
export const SEND_MAIL_UNSUBSCRIBE_PLAN = "sendMailUnsubscribePlan";
export const GET_ALL_PACKAGES = "getAllPackages";
export const GET_PACKAGE_BY_PLAYER_ID = "getPackageByPlayerId";
export const GET_ALL_RETAIL_PACKAGE = "getAllRetailPackage";
export const BY_RETAIL_PACKAGE = "byRetailPackage";
export const CREATE_SETUP_INTENT = "createSetupIntent";
export const GET_PAYMENT_METHOD_LIST = "getPaymentMethodList";
export const DELETE_PAYMENT_METHOD = "deletePaymentMethod";
export const GET_ADMIN_LIST = "getAdminList";
export const GET_ADMIN = "getAdmin";
export const ADD_ADMIN = "addAdmin";
export const UPDATE_ADMIN = "updateAdmin";
export const CHECK_ADMIN_NICKNAME = "checkAdminNickName";
export const DELETE_ADMIN_LIST = "deleteAdminList";
export const GET_ALL_INVOICE = "getInvoices";
export const GET_EMAIL_BY_PLAYER_ID = "getEmailByPlayerId";
export const DELETE_INVOICE_BY_ID = "deleteInvoiceById";
export const UPDATE_INVOICE = "updateInvoice";
export const GET_INVOICE_LIST_PAGINATION = "getInvoiceListPagination";
export const GET_USER_LIST_PAGINATION = "getUserListPagination";
export const UPDATE_USER_OF_PLAY_TIME = "updateUserOfPlayTime";
export const CHECK_ADMIN = "checkAdmin";
export const GET_INVOICE_LIST_PAGINATION_BY_PLAYER =
  "getInvoiceListPaginationByPlayer";
export const CHANGE_PACKGE_FREE = "changePackageFree";
export const BY_RETAIL_PACKAGE_INVOICE = "byRetailPackageInvoice";
export const ALLOW_START_GAME = "allowStartGame";
export const MINUS_PLAYTIME = "minusPlayTime";
export const CREATE_PLAYING_TABLE = "createPlayingTable";
export const GET_PLAYING_TABLE = "getPlayingTable";
export const REMOVE_PLAYING_TABLE = "removePlayingTable";
export const KICK_OUT_ROOM = "kickOutRoom";
export const CHECK_PLAYER_REMAIN_PLAYS = "checkPlayerRemainPlays";
export const START_GAME = "startGame";
export const SEND_MAIL_CONTACT = "sendMailContact";
export const SEND_MAIL_DELETE_PLAYER = "sendMailDeletePlayer";
export const DELETE_PLAYER = "deletePlayer";
export const UNSUBSCRIBE_PACKAGE_OF_CYCLO = "unsubscribePackageOfCyclo";
export const CHECK_NUMBER_PHONE_EXITS = "checkNumberPhoneExits";
export const SEND_MAIL_CREATED_ACCOUNT = "sendMailCreatedAccount";
export const SUBSCRIBE_PACKAGE_NEXT_MONTH = "subscribePackageNextMonth";
export const ADMIN_SWITCH_PACKAGE_PLAYER = "adminSwitchPackagePlayer";
export const GET_PAYMENT_METHOD_ID = "getPaymentMethodIdByPlayerId";
export const CHECK_IS_HAVE_ROOM_CHAT = "checkIsHaveRoomChat";
export const GET_URL_RECEIPT = "getUrlReceipt";
