import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectOrderCard } from "../../../../store/actions/playerGameStateActions";
import { POPUPS, STD_SIZE } from "../../../../store/constant";
import FlippedCard from "../FlippedCard";
import {
  CardSelectedWrapper,
  CardWrapper,
  Container,
  Nickname,
  TitleWrapper,
} from "./styles";

const ShowOrderCards = () => {
  const size = useSelector((state) => state.size);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const orderCards = useSelector((state) => state.playerGameState.orderCards);
  const canSelectCard = useSelector(
    (state) => state.playerGameState.canSelectCard
  );
  const popupId = useSelector((state) => state.narration.narrationPopup);

  const [innerSize, setInnerSize] = useState({});
  const [isFlippedCard, setIsFlippedCard] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (size) {
      const titleFontSize = Math.round(24 * size?.minScale);
      const nickNameFontSize = Math.round(14 * size?.minScale);
      const selectedCardWith = Math.round((size?.vShipWidth ?? 330) / 3);
      const hShipWidth = size?.hShipWidth;
      const hShipHeight = size?.hShipHeight;
      setInnerSize({
        titleFontSize,
        nickNameFontSize,
        selectedCardWith,
        hShipWidth,
        hShipHeight,
      });
    } else {
      const titleFontSize = 24;
      const nickNameFontSize = Math.round(14 * size?.minScale);
      const selectedCardWith = Math.round((size?.vShipWidth ?? 330) / 3);
      const hShipWidth = STD_SIZE.STD_SHIP_WIDTH;
      const hShipHeight = STD_SIZE.STD_SHIP_HEIGHT;
      setInnerSize({
        titleFontSize,
        nickNameFontSize,
        selectedCardWith,
        hShipWidth,
        hShipHeight,
      });
    }
  }, [size]);

  const onClickSelectCard = async (card, cardIndex) => {
    if (!canSelectCard || card?.nickName !== "") return;
    dispatch(selectOrderCard(card, cardIndex));
  };

  const hasNickName = (card) => {
    return card.nickName !== "";
  };

  useEffect(() => {
    if (popupId === POPUPS.SHOW_ORDER_CARD) {
      setIsFlippedCard(true);
    }
  }, [popupId]);

  const isShowOrderCards = useMemo(() => {
    return [
      POPUPS.SHOW_ORDER_CARDS_SCREEN,
      POPUPS.SHOW_ORDER_CARD,
      POPUPS.PLAYER_SELECT_ORDER_CARD,
      POPUPS.G_CHAOS_SELECT_ORDER_CARD,
      POPUPS.G_CHAOS_AUTO_SELECT_ORDER_CARD,
      POPUPS.UPDATE_TURN_G_CHAOS_SELECT_ORDER_CARD,
      POPUPS.UPDATE_TURN_PLAYER_SELECT_ORDER_CARD,
    ].includes(popupId);
  }, [popupId]);

  if (!isShowOrderCards) return null;

  const handleSetNickName = (nickName) => {
    if (nickName && nickName.length > 0) {
      if (nickName !== "G-Chaos" && nickName.length > 10) {
        return nickName.slice(0, 7) + "...";
      }
      return nickName;
    }
    return "";
  };
  return (
    <Container top={shipSpace} width={innerSize?.hShipWidth}>
      <TitleWrapper>
        <p></p>
      </TitleWrapper>
      <CardWrapper>
        {orderCards &&
          orderCards.map((card, index) => {
            if (card) {
              return (
                <CardSelectedWrapper
                  key={index}
                  width={innerSize.selectedCardWith}
                >
                  <Nickname hasNickname={hasNickName(card)}>
                    <p style={{ fontSize: innerSize?.nickNameFontSize }}>
                      {hasNickName(card) && handleSetNickName(card?.nickName)}
                    </p>
                  </Nickname>
                  <FlippedCard
                    flipped={isFlippedCard}
                    src={`/cards/${card.suit}${card.value}.jpg`}
                    onClickSelectCard={
                      isFlippedCard
                        ? () => {}
                        : () => onClickSelectCard(card, index)
                    }
                    size={size}
                  />
                </CardSelectedWrapper>
              );
            }
          })}
      </CardWrapper>
    </Container>
  );
};
export default ShowOrderCards;
