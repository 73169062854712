import { GET_STATE, SET_TABLE_LIST, SET_TABLE_EMPTY } from "../types";

const initialState = {
  tables: [],
  page: 1,
  lastTable: "",
  tableId: "",
  tablePaging: null,
};

const tableReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_TABLE_LIST:
      return {
        ...state,
        tables: action.payload.tables,
        tablePaging: action.payload.tablePaging,
      };
    case SET_TABLE_EMPTY:
      if (state.tablePaging) {
        state.tablePaging.destroy();
      }
      return initialState;
    case GET_STATE: {
      return state;
    }
    default:
      return state;
  }
};
export default tableReducer;
