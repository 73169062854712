import firebase, { getFunctions } from "../../services/firebase";
import {
  GET_ADMIN_LIST,
  UPDATE_ADMIN,
  DELETE_ADMIN_LIST,
  GET_ADMIN,
  ADD_ADMIN,
  CHECK_ADMIN_NICKNAME,
  SEND_MAIL_CONTACT,
} from "../funcs";
const auth = firebase.auth();
const functions = getFunctions();

export const adminRef = firebase.firestore().collection("admins");

export const getAdminList = async () => {
  try {
    return functions.httpsCallable(GET_ADMIN_LIST)();
  } catch (error) {
    console.log(error);
  }
};

export const getAdmin = async (adminId) => {
  try {
    return functions.httpsCallable(GET_ADMIN)({ adminId });
  } catch (error) {
    console.log(error);
  }
};

export const updateAdmin = async (data, adminId) => {
  try {
    const { newName, newEmail } = data;
    if (newName.length > 8) {
      return "errors.nickNameOver8Characters";
    }
    const isValidNickname = await functions.httpsCallable(CHECK_ADMIN_NICKNAME)(
      {
        newName,
      }
    );
    if (!isValidNickname.data) {
      return "errors.adminNickNameAldready";
    }
    const res = await functions.httpsCallable(UPDATE_ADMIN)({
      adminId,
      newName,
      newEmail,
    });
    if (res) return "";
    else return "admin.canNotUpdate";
  } catch (error) {
    console.log(error);
  }
};
export const createAdmin = async (data) => {
  try {
    const { address, password, userName } = data;
    if (userName.length > 8) {
      return "errors.nickNameOver8Characters";
    }
    const isValidNickname = await functions.httpsCallable(CHECK_ADMIN_NICKNAME)(
      {
        newName: userName,
      }
    );
    if (!isValidNickname.data) {
      return "errors.adminNickNameAldready";
    }

    await functions.httpsCallable(ADD_ADMIN)({
      email: address,
      password: password,
      nickName: userName,
    });
    return "";
  } catch (err) {
    if (err.code === "auth/email-already-in-use") {
      return "errors.emailExist";
    }
    if (err.code === "auth/weak-password") {
      return "errors.shortPass";
    }
    if (err.code === "auth/invalid-email") {
      return "errors.invalidEmail";
    }
    return err.code;
  }
};

export const deleteAdmin = async (adminId) => {
  try {
    return functions.httpsCallable(DELETE_ADMIN_LIST)({ adminId });
  } catch (error) {
    console.log(error);
  }
};

export const updateAdminPassword = async (email, values) => {
  try {
    const { currentPassword, newPassword } = values;
    const user = auth.currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );
    await user.reauthenticateWithCredential(credential);
    await user.updatePassword(newPassword);
    return "";
  } catch (err) {
    return err.code;
  }
};

export const sendMailContact = async (
  email,
  fullName,
  contactType,
  content
) => {
  try {
    return functions.httpsCallable(SEND_MAIL_CONTACT)({
      email,
      fullName,
      contactType,
      content,
    });
  } catch (error) {
    console.log(error);
  }
};
