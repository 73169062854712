import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import HorizontalTopCard from "./HTopStarCards";
import { DotStyle, StarCardsContainer } from "./styles";
import { cardGapWidthCalculator } from "../../../../../utils/calculations";
import { useSelector } from "react-redux";
import { TUTORIAL_MODE } from "../../../../../store/constant";

const TopStarCards = ({ cards, numberOfCalls }) => {
  const eveIndex = numberOfCalls - 2;
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const hShipWidth = useSelector((state) => state.size.hShipWidth);
  const cardWidth = useSelector((state) => state.size.cardWidth);
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );

  const isEve1Card = numberOfCalls == 2 && cards.length >= 1;
  const isBreakThrough1Call = useMemo(
    () => numberOfCalls == 1 && cards.length == 1,
    [numberOfCalls, cards]
  );
  useEffect(() => {
    if (size) {
      const [gapWidth, margin] = cardGapWidthCalculator(
        shipSpace,
        (cards && [...cards]?.length) || 0,
        size?.hShipWidth,
        size?.cardWidth
      );
      const isEve1CardAdd = isEve1Card ? shipSpace * 2 : 0;
      setInnerSize({ gapWidth, margin, isEve1CardAdd });
    } else {
      const [gapWidth, margin] = cardGapWidthCalculator(
        shipSpace,
        (cards && cards?.length) || 0,
        size?.hShipWidth,
        size?.cardWidth
      );
      const isEve1CardAdd = isEve1Card ? shipSpace : 0;
      setInnerSize({ gapWidth, margin, isEve1CardAdd });
    }
  }, [size, isEve1Card, isBreakThrough1Call, cards, shipSpace]);

  const getPositionCard = (idx, over_get_num_gap) => {
    const positionCardNoCall = Math.round(
      idx * innerSize.gapWidth +
        innerSize.margin * 2 +
        (idx == 0 ? innerSize.isEve1CardAdd : 0)
    );
    if (tutorialMode === TUTORIAL_MODE.NO_CALL) {
      return positionCardNoCall;
    }
    return idx < numberOfCalls
      ? positionCardNoCall
      : Math.round(
          hShipWidth -
            (over_get_num_gap - (idx - numberOfCalls)) * innerSize.gapWidth -
            cardWidth -
            innerSize.margin
        );
  };

  return (
    <>
      <StarCardsContainer>
        <DotStyle src="/assets/h_dot.png" />
        {cards?.map((card, idx) => {
          const over_get_num_gap =
            cards.length - numberOfCalls > 0
              ? cards.length - numberOfCalls - 1
              : 0;
          return (
            <HorizontalTopCard
              key={idx}
              src={`/cards/${card.suit}${card.value}.jpg`}
              position={"bottom"}
              zIndex={idx * 100}
              marginLeft={getPositionCard(idx, over_get_num_gap)}
              isRotate={idx === eveIndex}
              isEve1Card={numberOfCalls == 2 && cards.length == 1}
              numberOfCalls={numberOfCalls}
              isBreakThrough1Call={isBreakThrough1Call}
              size={size}
            />
          );
        })}
      </StarCardsContainer>
    </>
  );
};

TopStarCards.propTypes = {
  isMyTurn: PropTypes.bool,
  cards: PropTypes.array,
  numberOfCalls: PropTypes.number,
};

export default TopStarCards;
