import { InputAdornment, TextField } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";

const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      padding: theme.spacing(1),
      borderColor: "#4b4a4a",
      color: "#541513",
      backgroundColor: "#e9e1d4",
      zIndex: -1,
    },
    width: "80%",
  },
  visibility: {
    fontSize: 21,
    color: "#4b4a4a",
    "&:hover": {
      cursor: "pointer",
    },
  },
  input: {
    webkitBoxShadow: "0 0 0px 1000px #000 inset",
    transition: "background-color 5000s ease-in-out 0s",
    backgroundColor: "#e9e1d4",
    color: "#541513",
    fontWeight: "600",

    "&::placeholder": {
      fontSize: "13px !important",
      color: "#c1c1c1 !important",
    },

    "&:disabled": {
      color: "#c1c1c1 !important",
    },
  },
}));

const Input = ({
  value,
  onChange,
  type,
  text,
  autoFocus,
  InputProps,
  name,
  // error = null,
  width,
  placeHolder,
  show,
  disabled,
}) => {
  const classes = useStyles();
  const [innerShow, setShow] = useState(show);

  const handleShow = () => {
    setShow(!innerShow);
  };

  return (
    <TextField
      style={{
        width: width,
        height: 50,
        border: "2px solid #4b4a4a",
        backgroundColor: "#e9e1d4",
        color: `${disabled ? "#fafafa !important" : "#541513"}`,
        justifyContent: "center",
        fontWeight: "600",
      }}
      inputProps={{ className: classes.input }}
      className={classes.textField}
      label={text}
      name={name}
      value={value}
      type={`${type === "email" ? "email" : innerShow ? "text" : "password"}`}
      onChange={onChange}
      autoFocus={autoFocus}
      placeholder={placeHolder}
      size="small"
      variant="outlined"
      margin="normal"
      autoComplete="off"
      InputLabelProps={{ shrink: true }}
      disabled={disabled}
      InputProps={
        InputProps && {
          endAdornment: (
            <InputAdornment position="end">
              <div
                aria-label="toggle password visibility"
                onClick={handleShow}
                className={classes.visibility}
              >
                {innerShow ? <MdVisibility /> : <MdVisibilityOff />}
              </div>
            </InputAdornment>
          ),
        }
      }
    />
  );
};

Input.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  text: PropTypes.string,
  autoFocus: PropTypes.bool,
  InputProps: PropTypes.bool,
  name: PropTypes.string,
  // error: PropTypes.string,
  width: PropTypes.number,
  show: PropTypes.bool,
  height: PropTypes.number,
  placeHolder: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
};

export default Input;
