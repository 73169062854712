import {
  ADD_STEP_NARRATION,
  DONE_STEP_NARRATION,
  UPDATE_STEP_NARRATION,
  CLEAR_STEP_NARRATION,
  ADD_NOTIFY_STEP,
  ADD_LEAVE_ROOM_NOTIFY_STEP,
} from "../types";

const initQueue = {
  stepQueue: [],
  isDone: true,
  message: "",
};

const joinedTableReducer = (state = initQueue, action) => {
  switch (action.type) {
    case ADD_STEP_NARRATION:
      return {
        ...state,
        stepQueue: [...state.stepQueue, ...action.payload.steps],
      };
    // update after get a step to doing
    case UPDATE_STEP_NARRATION:
      return {
        ...state,
        stepQueue: [...action.payload.steps],
        isDone: false,
        message: action.payload.message,
      };

    case DONE_STEP_NARRATION:
      return {
        ...state,
        isDone: true,
      };
    case CLEAR_STEP_NARRATION:
      return initQueue;

    case ADD_NOTIFY_STEP:
      return {
        ...state,
        stepQueue: [...action.payload.steps, ...state.stepQueue],
      };
    case ADD_LEAVE_ROOM_NOTIFY_STEP:
      return {
        ...state,
        stepQueue: [...action.payload.steps],
        message: action.payload.message,
      };
    default:
      return state;
  }
};
export default joinedTableReducer;
