import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Slide,
  Typography,
  Button,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { ImArrowRight } from "react-icons/im";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Input from "../../../../../components/Input";
import SuccessMessage from "../../../../../components/SuccessMessage";
import { updateInvoiceById } from "../../../../../store/actions/invoiceAction";
import { invoiceValidateInput } from "../../../../../utils/validation";
import useStyles from "./styles";
import DateFnsUtils from "@date-io/date-fns";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import moment from "moment";

export const ErrorText = styled.p`
  color: rgb(220, 50, 51);
  font-weight: 600;
  width: 376px;
  font-size: 14px;
  margin: auto;
  text-align: center;
`;

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const EditInvoice = ({
  handleClose,
  open,
  invoiceData,
  handleLoading,
  reloadData,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({});
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [odlValue, setOldValue] = useState({});
  const [disableButton, setDisableButton] = useState(false);
  const [selectedCompleteDate, setSelectedCompleteDate] = useState("");
  const [errDate, setErrDate] = useState("");
  const completeDateRef = useRef();

  const minScale = useSelector((state) => state.size.minScale);
  const input_W = useMemo(() => (minScale < 0.4 ? 250 : 300), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 11 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(203 * minScale * 2) : 203),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H: 51,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });

  useEffect(() => {
    const clearError = setTimeout(() => {
      setSuccess("");
    }, 3000);
    return () => clearTimeout(clearError);
  }, [success]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setValues({ ...values, [name]: value });
    if (errorMessage !== "" || success !== "" || errors !== "") {
      setErrors("");
      setErrorMessage("");
      setSuccess("");
    }
  };

  const handleDateChange = (date) => {
    const dateTemp = new Date(date);
    const newDate = moment(dateTemp).format("YYYY/MM/DD");
    setSelectedCompleteDate(newDate);
    // set focus
    let _disableButton = false;
    if ("Invalid date" !== newDate) {
      setTimeout(() => {
        completeDateRef.current.focus();
      }, 0);

      setErrDate("");
    } else {
      setErrDate(t("accountSettings.invalidDate"));
      _disableButton = true;
    }

    let el = document.querySelector(`[name=completeDate]`);

    const oldDate = moment(odlValue.completeDate).format("YYYY/MM/DD");
    if (oldDate !== newDate) {
      el.parentElement.parentElement.style.borderColor = "#d6a87d";
    } else {
      el.parentElement.parentElement.style.borderColor = "rgb(75, 74, 74)";
      _disableButton = true;
    }

    setDisableButton(_disableButton);
  };

  const handleUpdateInvoice = async () => {
    const err = invoiceValidateInput(values);
    if (err) {
      setErrors(err);
      return;
    }
    if (err === "" && errDate === "") {
      handleLoading(true);
      const resulf = await updateInvoiceById({
        ...values,
        completeDate: selectedCompleteDate,
      });
      handleLoading(false);
      handleClose();
      reloadData();
      if (resulf.data.status === 1) {
        toast.success(`${t("admin.updateSuccess")}`);
      } else {
        toast.error(`${t("admin.canNotUpdate")}`);
      }
    }
  };

  useEffect(() => {
    if (values) {
      // TODO: add border color when have change
      let _disableButton = true;
      for (let name in values) {
        if (
          name === "paymentGatewayInvoiceNumber" ||
          name === "email" ||
          name === "amount" ||
          name === "status"
        ) {
          let el = document.querySelector(`[name=${name}]`);
          if (values[name] !== odlValue[name]) {
            el.parentElement.parentElement.style.borderColor = "#d6a87d";
            _disableButton = false;
          } else {
            el.parentElement.parentElement.style.borderColor =
              "rgb(75, 74, 74)";
          }
        }
      }
      setDisableButton(_disableButton);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  useEffect(() => {
    if (invoiceData) {
      setOldValue(invoiceData);
      setValues(invoiceData);
      setSelectedCompleteDate(invoiceData.completeDate);
      setErrors("");
      setErrorMessage("");
      setSuccess("");
      setErrDate("");
    }
    let el = document.querySelector(`[name=completeDate]`);
    if (el) {
      el.parentElement.parentElement.style.borderColor = "rgb(75, 74, 74)";
    }
  }, [invoiceData]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setErrors("err");
        handleClose();
      }}
      aria-describedby="dialog-change-pass"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        sx={{
          color: "#541513",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "30px",
        }}
      >
        {t("admin.editInvoice")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-change-pass-content">
          <Box className={classes.mainBox}>
            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.invoiceNumber")}
              </Typography>
              <Box>
                <Input
                  autoFocus={true}
                  value={odlValue.paymentGatewayInvoiceNumber}
                  width={input_W}
                  height={51}
                  show={true}
                  disabled={true}
                />
              </Box>
              <Box className={classes.iconBox}>
                <ImArrowRight />
              </Box>
              <Box>
                <Input
                  autoFocus={true}
                  name="paymentGatewayInvoiceNumber"
                  value={values.paymentGatewayInvoiceNumber}
                  onChange={handleChange}
                  width={input_W}
                  height={51}
                  show={true}
                />
              </Box>
            </Box>
            <Space space={10} />
            {errors.paymentGatewayInvoiceNumber && (
              <ErrorText>{t(errors.paymentGatewayInvoiceNumber)}</ErrorText>
            )}
            <Space space={10} />
            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.email")}
              </Typography>

              <Box>
                <Input
                  autoFocus={true}
                  value={odlValue.email}
                  width={input_W}
                  height={51}
                  show={true}
                  disabled={true}
                />
              </Box>
              <Box className={classes.iconBox}>
                <ImArrowRight />
              </Box>
              <Box>
                <Input
                  autoFocus={true}
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  width={input_W}
                  height={51}
                  show={true}
                  disabled={true}
                />
              </Box>
            </Box>
            <Space space={10} />
            {errors.email && <ErrorText>{t(errors.email)}</ErrorText>}
            <Space space={10} />
            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.billingDate")}
              </Typography>

              <Box>
                <Input
                  autoFocus={true}
                  value={odlValue.completeDate}
                  width={input_W}
                  height={51}
                  show={true}
                  disabled={true}
                />
              </Box>
              <Box className={classes.iconBox}>
                <ImArrowRight />
              </Box>
              <Box>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    format="yyyy/MM/dd"
                    margin="normal"
                    id="date-picker-inline"
                    name="completeDate"
                    placeholder={t("settings.dateOfBirth")}
                    invalidDateMessage=""
                    value={selectedCompleteDate ? selectedCompleteDate : null}
                    onChange={handleDateChange}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    autoOk
                    inputProps={{
                      ref: completeDateRef,
                    }}
                    InputProps={{
                      disableUnderline: true,
                    }}
                    className={classes.datePickCustum}
                  />
                </MuiPickersUtilsProvider>
              </Box>
            </Box>
            <Space space={10} />
            {errDate && <ErrorText>{t(errDate)}</ErrorText>}
            <Space space={10} />
            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.price")}
              </Typography>

              <Box>
                <Input
                  autoFocus={true}
                  value={odlValue.amount}
                  width={input_W}
                  height={51}
                  show={true}
                  disabled={true}
                />
              </Box>
              <Box className={classes.iconBox}>
                <ImArrowRight />
              </Box>
              <Box>
                <Input
                  autoFocus={true}
                  name="amount"
                  value={values.amount}
                  onChange={handleChange}
                  width={input_W}
                  height={51}
                  show={true}
                />
              </Box>
            </Box>
            <Space space={10} />
            {errors.amount && <ErrorText>{t(errors.amount)}</ErrorText>}
            <Space space={10} />

            <Box className={classes.fieldItem}>
              <Typography style={{ minWidth: 150 }}>
                {t("paymentSettings.paymentStatus")}
              </Typography>

              <Box>
                <FormControl
                  sx={{ m: 1, minWidth: 120 }}
                  className={classes.formSeclect}
                >
                  <Select
                    value={odlValue?.status ? odlValue?.status : "paid"}
                    onChange={handleChange}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                    name="status"
                    style={{ color: "#541513", fontWeight: 600 }}
                    disabled
                  >
                    <MenuItem value="paid">
                      {t("paymentSettings.paid")}
                    </MenuItem>
                    <MenuItem value="unpaid">
                      {t("paymentSettings.unpaid")}
                    </MenuItem>
                  </Select>
                </FormControl>
              </Box>
              <Box className={classes.iconBox}>
                <ImArrowRight />
              </Box>
              <FormControl
                sx={{ m: 1, minWidth: 120 }}
                className={classes.formSeclect}
              >
                <Select
                  value={values?.status ? values?.status : "paid"}
                  onChange={handleChange}
                  displayEmpty
                  inputProps={{ "aria-label": "Without label" }}
                  name="status"
                  style={{ color: "#541513", fontWeight: 600 }}
                >
                  <MenuItem value="paid">{t("paymentSettings.paid")}</MenuItem>
                  <MenuItem value="unpaid">
                    {t("paymentSettings.unpaid")}
                  </MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Space space={10} />
          </Box>
          <Box className={classes.boxButton}>
            <Button
              variant="outlined"
              className={classes.buttonBlue}
              text={t("tableSettings.back")}
              onClick={handleClose}
            >
              {t("tableSettings.back")}
            </Button>
            <Button
              onClick={handleUpdateInvoice}
              variant="contained"
              className={classes.buttonOrange}
              disabled={disableButton}
            >
              {t("changePassword.update")}
            </Button>
          </Box>
          <SuccessMessage message={t(success)} />
          {errorMessage !== "" && <ErrorText>{errorMessage}</ErrorText>}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

EditInvoice.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  invoiceData: PropTypes.object.isRequired,
  handleLoading: PropTypes.func,
  reloadData: PropTypes.func,
};

export default EditInvoice;
