import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    // justifyContent: "center",
    color: "red",
    overflow: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  loginBtn: {
    height: 50,
    width: 200,
    border: "2px solid black",
    borderRadius: "10px !important",
    margin: "0 !important",
    backgroundColor: "#6f0000",
    "& span": {
      marginLeft: 7,
    },
  },
  link: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    color: "#dd501f",
    "&:hover": {
      color: "yellow",
    },
  },
}));

export const Container = styled.div``;

export const LogoGame = styled.div`
  aspect-ratio: 1/1;
  background: url("/assets/intro2.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

export const Production = styled.div`
  background: url("/assets/introtext2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: auto;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  top: -5%;
`;
export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export default useStyles;
