/* eslint-disable no-unused-vars */
import styled from "styled-components";
import { MAIN_COLOR, SECOND_COLOR } from "../../../../../../constants";
import { Tabs, Tab, ListItemText } from "@mui/material";

export const ChatBoxModalWrapper = styled.div`
  width: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 350}px` : `${0.5 * 350}px`};
  height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 600}px` : `${0.5 * 600}px`};
  display: flex;
  background-color: #fff;
  position: fixed;
  z-index: 999;
  flex-direction: column;
  border-radius: 10px;
  left: 1%;
  top: 20%;
`;

export const ChatBoxModalHeader = styled.div`
  background-color: ${MAIN_COLOR};
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding: ${(props) =>
    props.minscale > 0.5
      ? `${props.minscale * 0.5}rem ${props.minscale * 1}rem`
      : `1px 2px`};
  height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 35}px` : `${0.5 * 46}px`};
  box-sizing: border-box;
`;
export const ChatBoxModalTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100%;
  padding: 2px 3px;
`;
export const ChatBoxModalBody = styled.div`
  flex-grow: 1;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: ${(props) =>
    props.minscale > 0.5
      ? `${props.minscale * 0.5}rem ${props.minscale * 0.5}rem ${
          props.minscale * 1
        }rem ${props.minscale * 0.5}rem`
      : `3px 3px 6px 3px`};
  height: ${(props) =>
    props.minscale > 0.5
      ? `calc(100% - ${props.minscale * 46}px)`
      : `calc(100% - ${0.5 * 46}px)`};
  box-sizing: border-box;
`;
export const ChatBoxModalContent = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
`;

export const ChatBoxModalContentHeader = styled.div`
  height: ${(props) =>
    props.minscale > 0.5
      ? `${props.minscale * 30}px !important`
      : `${0.5 * 30}px !important`};
  display: flex;
  box-sizing: border-box;
`;

export const ListRoomWrapper = styled.div`
  flex-grow: 1;
  display: flex;
  overflow: hidden;
`;

export const ListRoom = styled(Tabs)`
  height: 100%;
  width: 100%;
  .MuiTabs-indicator {
    display: none;
  }
  .MuiTabs-scroller {
    height: 100%;
  }
  .MuiTabs-flexContainer {
    height: 100%;
  }
  min-height: unset !important;
`;

export const ListRoomItem = styled(Tab)`
  width: 33% !important;
  color: #fff !important;
  background-color: ${SECOND_COLOR} !important;
  margin-left: 1px !important;
  min-height: unset !important;
  min-width: unset !important;
  height: 100% !important;
  &.Mui-selected {
    color: #fff !important;
    background-color: ${MAIN_COLOR} !important;
  }

  &.MuiButtonBase-root {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: ${(props) =>
      props.minscale > 0.5 ? `${props.minscale * 10}px` : `${0.5 * 10}px`};
    font-weight: 300;
    justify-content: flex-start;
    padding: ${(props) =>
      props.minscale > 0.5
        ? `${props.minscale * 5}px ${props.minscale * 5}px ${
            props.minscale * 5
          }px ${props.minscale * 10}px`
        : `1px 1px 1px 2px`};
  }

  svg.MuiSvgIcon-root {
    margin-left: 0;
  }

  & .MuiTypography-root {
    text-transform: initial;
    font-size: ${(props) =>
      props.minscale > 0.5 ? `${props.minscale * 13}px` : `${0.5 * 13}px`};
    line-height: 1;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: left;
    flex: 1;
  }
`;

export const ChatMessageContent = styled.div`
  border: 2px solid ${MAIN_COLOR};
  flex-grow: 1;
  height: ${(props) =>
    props.minscale > 0.5
      ? `${props.minscale * 30}px !important`
      : `${0.5 * 30}px !important`};
  box-sizing: border-box;
`;

export const ListItemTextStyled = styled(ListItemText)`
  & .MuiTypography-root {
    font-size: ${(props) =>
      props.minscale > 0.5 ? `${props.minscale * 0.9}rem` : `${0.5 * 0.9}rem`};
    line-height: 1;
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;
  }
`;

export const MessageBadge = styled.div`
  background-color: #ff2d18;
  width: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 26}px` : `${0.5 * 26}px`};
  height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 26}px` : `${0.5 * 26}px`};
  text-align: center;
  margin-left: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 5}px` : `${0.5 * 5}px`};
  border-radius: 50%;
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 16}px` : `${0.5 * 16}px`};
  line-height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 27}px` : `${0.5 * 27}px`};
  color: #fff;
`;
