import { Typography } from "@material-ui/core";
import Container from "@material-ui/core/Container";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

import { Elements, useStripe } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import styled from "styled-components";
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PRIVATE_KEY);
const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: theme.spacing(10),
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: theme.spacing(2),
    background: "none",
  },
  link: {
    textDecoration: "none",
    textTransform: "none",
    color: "white",
  },
  button: {
    backgroundColor: "rgb(186,71,35)",
    borderRadius: "999px",
    border: "black 1px solid",
    padding: "7px 2em",
    marginTop: 40,
  },
  mainWarpper: {
    padding: (props) => `${props.setPadding}px`,
  },
}));
export const LogoGame = styled.div`
  aspect-ratio: 1/1;
  background: url("/assets/intro2.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const Production = styled.div`
  background: url("/assets/introtext2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-top: -30px;
`;
export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  margin-top: 1em;
  padding: 1em;
`;

const PaymentStatusWrapper = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentStatus />
    </Elements>
  );
};

const PaymentStatus = () => {
  const stripe = useStripe();
  const [message, setMessage] = useState(null);
  const history = useHistory();

  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.paymentStatusTitle"));
  const minScale = useSelector((state) => state.size.minScale);

  const logoGameWidth = 240;
  const logoGameHeight = 150;
  const productionWidth = 130;
  const productionHeight = 7;
  const boxWrapperWidth = 350;
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (minScale) {
      const padding = Math.round(50 * minScale);
      setInnerSize({ padding });
    } else {
      const padding = 50;
      setInnerSize({ padding });
    }
  }, [minScale]);

  const classes = useStyles({
    setPadding: innerSize.padding,
  });

  useEffect(() => {
    if (message) {
      setTimeout(() => {
        history.goBack();
      }, 5000);
    }
  }, [message, history]);

  useEffect(() => {
    if (!stripe) {
      return;
    }
    const clientSecret = new URLSearchParams(window.location.search).get(
      "setup_intent_client_secret"
    );
    // Retrieve the SetupIntent
    stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent }) => {
      switch (setupIntent.status) {
        case "succeeded":
          setMessage(t("paymentSettings.changeCardSuccess"));
          break;

        case "processing":
          setMessage(t("paymentSettings.changeCardProcessing"));
          break;

        case "requires_payment_method":
          setMessage(t("paymentSettings.changeCardFailed"));
          break;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stripe]);
  return (
    <Container maxWidth="xl" className={classes.root}>
      <LogoGame width={logoGameWidth} height={logoGameHeight} />
      <Production width={productionWidth} height={productionHeight} />

      <BoxWrapper width={boxWrapperWidth}>
        <div className={classes.mainWarpper} />
        <Typography
          variant="body1"
          style={{ color: "#541f1c", fontSize: 28, fontWeight: 600 }}
        >
          {message}
        </Typography>
        <div style={{ height: 10 }}></div>
        <Typography variant="body1" style={{ color: "#dd501f", fontSize: 14 }}>
          {t("paymentSettings.redirectToHomepage")}
        </Typography>

        <div className={classes.mainWarpper} />
      </BoxWrapper>
    </Container>
  );
};

export default PaymentStatusWrapper;
