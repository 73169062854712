import { Avatar, Button } from "@material-ui/core";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { join } from "../../../store/actions/roomActions";
import { checkPlayerRemainPlays } from "../../../store/actions/playTimeAction";
import { REMOVE_INVITE } from "../../../store/types";
import { formatTimestamp } from "../../../utils/time";
import {
  InviteItemFooter,
  TableNotifyLeft,
  TableNotifyRight,
  TableNotifyWrapper,
  TableText,
  Timestamp,
} from "./styles";
import MessDialog from "../../../pages/GameScreen/components/NewDialog/MessDialog";

const NotifyJoin = ({ item, toggle, handleShowNoPlayTime }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const playerId = useSelector((state) => state.user.playerId);
  const fullName = useSelector((state) => state.user.fullName);
  const avatar = useSelector((state) => state.user.avatar);
  const nickName = useSelector((state) => state.user.nickName);
  const currentRoomJoinedId = useSelector(
    (state) => state.user.currentRoomJoinedId
  );
  const playTimeId = useSelector((state) => state.user.playTimeId);
  const trialStatus = useSelector(
    (state) => state.playTime.playTimeInfo.statusFree
  );
  const [openErrorDialog, setOpenErrorDialog] = useState(false);

  const removeJoinRoomInvite = (roomId) => {
    dispatch({ type: REMOVE_INVITE, payload: { roomId: roomId } });
  };
  const joinTable = async () => {
    removeJoinRoomInvite(item?.roomId);
    //Check if user is in game room
    if (currentRoomJoinedId) return;
    const playerData = {
      playerId: playerId,
      fullName: fullName,
      avatar: avatar,
      nickName: nickName,
    };
    const roomData = {
      playerId: playerId,
      roomId: item?.roomId,
      tableId: "",
      player: playerData,
    };
    //check enough playtime to play
    const isEnoughPlaytime = await checkPlayerRemainPlays(playTimeId);
    if (isEnoughPlaytime || trialStatus) {
      //Allow to join game
      const joinRes = await join(roomData, playerData);
      if (!joinRes) {
        setOpenErrorDialog(true);
      }
    } else {
      handleShowNoPlayTime();
      toggle();
    }
  };

  const handleCloseErrorDialog = () => {
    setOpenErrorDialog(false);
  };

  return (
    <>
      {/* NOTIFY STYLE */}
      <TableNotifyWrapper bg={item.seen ? "#e9e3d7" : "#c9bc9d"}>
        <div style={{ display: "flex", margin: "10px 0" }}>
          <Timestamp>{formatTimestamp(item.timestamp)}</Timestamp>
          <TableNotifyLeft>
            <Avatar
              alt="Avatar"
              src={item.tableAvatar}
              style={{ border: "2px solid #fff" }}
            />
          </TableNotifyLeft>
          <TableNotifyRight>
            <TableText id="table-invite">
              {t("tables.joinRoomMessage", {
                name: item.ownerFullName,
              })}
            </TableText>
            <TableText id="table-name">
              {t("tables.tableNameArg", {
                name: item.tableName,
              })}
            </TableText>
            <TableText id="table-description">
              {t("tables.descriptionArg", {
                name: item.description,
              })}
            </TableText>
            <TableText id="table-message">
              {t("tables.message", {
                name: item.message,
              })}
            </TableText>
          </TableNotifyRight>
        </div>

        <InviteItemFooter>
          <Button
            variant="outlined"
            style={{
              backgroundColor: "#442211",
              color: "#fff",
              padding: "0px 15px",
            }}
            onClick={() => joinTable()}
          >
            {t("tables.yes")}
          </Button>
          <Button
            variant="contained"
            style={{ padding: "0px 15px" }}
            onClick={() => {
              removeJoinRoomInvite(item?.roomId);
            }}
          >
            {t("tables.no")}
          </Button>
        </InviteItemFooter>
      </TableNotifyWrapper>
      <MessDialog
        isOpen={openErrorDialog}
        onClose={handleCloseErrorDialog}
        mainText={t("tables.expiredMessage")}
      />

      {/* NOTIFY STYLE */}
    </>
  );
};

NotifyJoin.propTypes = {
  item: PropTypes.object,
  toggle: PropTypes.func,
  handleShowNoPlayTime: PropTypes.func,
};
export default NotifyJoin;
