import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import {
  COLOR_BROWN_LIGHT,
  COLOR_GREY,
  COLOR_ORANGE,
  COLOR_YELLOW,
} from "../constants";

export const ProfileContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  &::after {
    content: "";
    position: absolute;
    ${(props) => props.leftRightText};
    top: 32%;
    z-index: -1;
    border-top: ${(props) => props.tipSize}px solid transparent;
    border-right: ${(props) =>
      props.position === "left"
        ? (props.isMyTurn && props.isVideo) ||
          (props.isMyTurn && !props.isVideo)
          ? `${props.tipSize}px solid ${COLOR_ORANGE}`
          : `${props.isVideo ? `` : `${props.tipSize}px solid ${COLOR_GREY}`}`
        : 0};
    border-left: ${(props) =>
      props.position === "right"
        ? (props.isMyTurn && props.isVideo) ||
          (props.isMyTurn && !props.isVideo)
          ? `${props.tipSize}px solid ${COLOR_ORANGE}`
          : `${props.isVideo ? `` : `${props.tipSize}px solid ${COLOR_GREY}`}`
        : 0};
    border-bottom: ${(props) => props.tipSize}px solid transparent;
    transition: all 0.2 ease-in;
  }
`;

export const Container = styled.div`
  box-sizing: border-box;
  background-color: #eeeeee;
  display: flex;
  flex-direction: column;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  z-index: 1;
  position: ${(props) => (props.isTutorial ? "relative" : "")};
  top: ${(props) => (props.isTutorial ? "20px" : "")};
`;

export const ProfileHeader = styled.div`
  width: 100%;
  height: 25%;
  position: relative;
  background-color: ${COLOR_BROWN_LIGHT};
  padding: 2.5%;
  box-sizing: border-box;

  &::before {
    content: "${(props) => props.yourTurn}";
    font-size: ${(props) => props.fontSize}px;
    font-weight: 900;
    ${(props) => props.boxTurn};
    position: absolute;
    width: 50%;
    margin: auto;
    z-index: 0;
    background-color: ${COLOR_ORANGE};
    color: ${COLOR_GREY};
    text-align: center;
    padding: 4px 0;
    display: ${(props) => (props.isMyTurn ? "block" : "none")};
    align-items: center;
    transition: all 0.2 ease-in;
    item-align: center;
  }
  &::after {
    content: "";
    position: absolute;
    ${(props) => props.leftRightArrow};
    z-index: -1;
    display: ${(props) => (props.isMyTurn ? "block" : "none")};
    transition: all 0.2 ease-in;
  }
`;

export const ProfileHeaderContent = styled.div`
  width: 97%;
  height: 100%;
  background-color: ${COLOR_YELLOW};
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 600;
  display: flex;
  flex-direction: row;
  align-items: center;

  & span.star {
    font-size: ${(props) => props.starSize}px;
  }

  & p {
    font-size: ${(props) => props.fontSize}px;
    font-weight: 700;
  }
`;

export const ProfileHeaderOrange = styled.div`
  height: 100%;
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: ${COLOR_ORANGE};
  border-radius: 8px 0 0 8px;
  color: white;
  box-sizing: border-box;
  overflow: hidden;
  padding: ${(props) => props.padding}px 0;
`;

export const ProfileInfo = styled.div`
  width: 100%;
  height: 25%;
  background-color: ${COLOR_BROWN_LIGHT};
  display: flex;
  justify-content: flex-start;
  box-sizing: border-box;
  flex-direction: row;
  align-items: center;
  padding: 0 ${(props) => props.padding}px;
  & > p {
    margin-left: 6px;
    width: 50%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

export const ProfileInfoLeft = styled(ProfileInfo)`
  &::after {
    content: "";
    position: absolute;
    left: ${(props) => (props.isMyTurn ? `-20px` : "-15px")};
    top: -100%;
    z-index: -1 !important;
    border-top: 10px solid transparent;
    border-right: ${(props) =>
      (props.isMyTurn && props.isVideo) || (props.isMyTurn && !props.isVideo)
        ? `15px solid ${COLOR_ORANGE}`
        : `${props.isVideo ? `` : `15px solid ${COLOR_GREY}`}`};
    border-bottom: 10px solid transparent;
  }
`;

export const ProfileInfoRight = styled(ProfileInfo)`
  &::after {
    content: "";
    position: absolute;
    right: ${(props) => (props.isMyTurn ? `-20px` : "-15px")};
    top: -100%;
    z-index: -1 !important;
    border-top: 10px solid transparent;
    border-left: ${(props) =>
      (props.isMyTurn && props.isVideo) || (props.isMyTurn && !props.isVideo)
        ? `15px solid ${COLOR_ORANGE}`
        : `${props.isVideo ? `` : `15px solid ${COLOR_GREY}`}`};
    border-bottom: 10px solid transparent;
  }
`;

export const ProfileAvatar = styled.div`
  width: 100%;
  background-color: ${COLOR_GREY};
  position: relative;
  box-sizing: border-box;
  font-size: 0.8em;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const AvatarWrapper = styled.div`
  background-color: transparent;
  border: 3px solid white;
  border-radius: 999px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
`;

export const Video = styled(Avatar)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: absolute;
  top: ${(props) => props.top}px;
  left: ${(props) => props.left}px;
  transform: scale(${(props) => props.scale});
`;

export const Voice = styled(Avatar)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  transform: scale(${(props) => props.scale});
`;

export const Text = styled.p`
  position: absolute;
  top: 10px;
  left: 35px;
`;

export const ProfileDetail = styled.div`
  width: calc(100% - 70px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  word-break: break-word;
  white-space: pre-wrap;
  & > p {
    width: 100%;
    margin-top: 8px;
    font-size: calc(10px + 0.3vw);
    font-weight: bold;
    color: #231815;
  }
`;

export const Owner = styled(Avatar)`
  font-size: ${(props) => props.fontSize}px;
  background-color: #fece02;
  border: 1px solid white;
  position: absolute;
  left: ${(props) => props.left}px;
  bottom: ${(props) => props.top}px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999 !important;
`;
export const MasterTurn = styled.div`
  background-color: ${COLOR_ORANGE};
  border: 2px solid ${COLOR_ORANGE};
  border-radius: 5px;
  padding: ${(props) => Math.round(props.minScale * 2)}px;
  width: ${(props) => props.videoSize}px;
  height: ${(props) => props.videoSize}px;
  font-size: ${(props) =>
    props.minScale <= 0.74 ? props.minScale * 15 : 15}px;
  color: white;
  & > p {
    font-weight: 900;
  }
`;

export const StyleOctagon = styled.div`
  width: ${(props) => Math.round(props.size)}px;
  height: ${(props) => Math.round(props.size)}px;
  background-image: url("/assets/icon.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: contain;
`;
