import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useTriggerConfirms } from "../../../../../hooks/useTriggerConfirms";
import { stopCurrentNarration } from "../../../../../store/actions/narrationActions";
import { playerConfirm } from "../../../../../store/actions/roomActions";
import OkDialog from "../../NewDialog/OkDialog";

const PopupConfirms = React.memo(function PopupConfirmsComponent() {
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const popupId = useSelector((state) => state.narration.narrationPopup);
  const roomId = useSelector((state) => state.room.roomId);
  const tableId = useSelector((state) => state.room.table.tableId);
  const numberOfGame = useSelector((state) => state.room.table.numberOfGame);
  const playerIds = useSelector((state) => state.room.table.currentPlayersId);
  const nickName = useSelector((state) => state.user.nickName);
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const [isShow, setIsShow] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { isShowDialog, trigger, message } = useTriggerConfirms(
    stepId,
    popupId
  );

  useEffect(() => {
    // console.log("isShow: ", isShowDialog);
    setIsShow(isShowDialog);
  }, [isShowDialog, message]);

  const onOk = () => {
    setIsShow(false);
    dispatch(stopCurrentNarration());
    const data = {
      roomId: roomId,
      playerId: playerId,
      playerIds: playerIds,
      type: trigger,
      gameId: gameId,
      tableId: tableId,
      numberOfGame: numberOfGame,
      nickName,
      stepId,
      isGChaosComputer,
    };
    playerConfirm(data);
  };
  if (!isShowDialog) return null;
  return (
    <OkDialog
      confirmText={t(message)}
      btnLabel={t("tables.ok")}
      onAgree={onOk}
      isOpen={isShow}
    />
  );
});

export default PopupConfirms;
