import PropTypes from "prop-types";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrStatusGoodSmall } from "react-icons/gr";
import DeleteDialog from "../DeleteDialog";
import {
  Wrapper,
  UserList,
  UserName,
  StatusWrapper,
  StyleButton,
} from "./styles";

function OfflineFriend({ friendsOffline = [], scaleHeight = 1 }) {
  const [friendsOff, setFriendsOff] = useState(friendsOffline);
  const [isDelete, setIsDelete] = useState(false);
  const [friendId, setFriendId] = useState(null);
  const [circleSize] = useState(14);
  const { t } = useTranslation();

  const handleDeleteFriend = (value) => {
    setIsDelete(true);
    setFriendId(value);
  };

  useEffect(() => {
    if (friendsOffline && friendsOffline.length > 0) {
      setFriendsOff(friendsOffline);
    }
  }, [friendsOffline]);
  return (
    <Fragment>
      <Wrapper scaleHeight={scaleHeight} style={{ marginTop: 10 }}>
        {friendsOff &&
          friendsOff.map((f, index) => {
            if (f && f.isFriend && f.nickName) {
              return (
                <UserList key={index}>
                  <StatusWrapper>
                    <GrStatusGoodSmall
                      style={{
                        color: "grey",
                        fontSize: circleSize,
                        flexShrink: 0,
                      }}
                    />
                    <UserName>{f.nickName}</UserName>
                  </StatusWrapper>
                  <StyleButton
                    isPresse={true}
                    onClick={() => handleDeleteFriend(f.playerId)}
                  >
                    {t("status.unfriend")}
                  </StyleButton>
                </UserList>
              );
            }
          })}
        <div style={{ height: 20 }} />
      </Wrapper>
      <DeleteDialog
        isOpen={isDelete}
        onClose={() => {
          setIsDelete(false);
        }}
        friendId={friendId}
        isOnline={false}
      />
    </Fragment>
  );
}

OfflineFriend.propTypes = {
  friendsOffline: PropTypes.any,
  scaleHeight: PropTypes.number,
};

export default OfflineFriend;
