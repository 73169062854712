import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  tableCell: {
    "@media (max-width: 991px)": {
      padding: "10px !important",
      fontSize: "10px !important",
    },
    "@media (max-width: 768px)": {
      padding: "10px 5px !important",
    },
  },
}));

export default useStyles;
