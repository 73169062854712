/* eslint-disable react/prop-types */
import styled, { css } from "styled-components";
import { StarCard } from "../../StarCards/StarCard";
import { Button } from "../../../../../ui/elements/Button";

export const CenterItem = styled.div`
  width: ${(props) => props.width}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export const HorizontalItem = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const Container = styled.div`
  width: calc(100% - 1000px);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    width: calc(100% - 580px);
  }
`;

export const TrickStartWrapperBG = styled.div`
  width: 100%;
  height: 100%;
  z-index: +1;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-image: url("/assets/bg_master_cards.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;
export const TrickStartWrapper = styled.div`
  height: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

export const StarCardsTopWrapper = styled.div`
  width: 100%;
  height: 25%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StarCardsTop = styled(StarCard)`
  width: 98%;
  height: 90%;
  aspect-ratio: 1/1;
  transform: rotate(180deg);
`;

export const StarCardsBottomWrapper = styled.div`
  width: 100%;
  height: 25%;
  box-sizing: border-box;
  background-color: #311c0a;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const StarCardsBottom = styled(StarCard)`
  width: 98%;
  height: 90%;
`;

export const GrandCrossWrapper = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  background-color: transparent;
  position: relative;
  aspect-ratio: 1;
  overflow: hidden;
`;

export const GrandCross = styled.div`
  aspect-ratio: 1/1;
  box-sizing: border-box;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  margin: auto;
  background-image: radial-gradient(
    circle,
    #ebe0d3,
    #d9c1a5,
    #c6a278,
    #b2844e,
    #9d6624
  );
`;

export const ArkWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Ark = styled.div`
  width: 150px;
  height: 150px;
  padding: 15px;
  box-sizing: border-box;
  background-color: #3b0904;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
`;

export const LogoArk = styled.img`
  width: 100%;
  height: 100%;
`;

export const MenuStartGame = styled.div`
  width: 100%;
  height: 100%;
  flex: 1;
  box-sizing: border-box;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const ModifiedButton = styled(Button)`
  width: 50%;
  padding: ${(props) => props.fontSize / 1.2}px 0;
  background-image: url("/assets/btn_menu.png");
  font-size: ${(props) => props.fontSize}px;
  color: black;
  margin-top: 20px;
  letter-spacing: 0;
  ${(props) =>
    props.disable &&
    css`
      opacity: 0.6;
    `}
`;
export const Card = styled.img`
  width: 5%;
  height: 5%;
  left: ${(props) => props.left};
  position: absolute;
`;
export const HandCards = ({ cards, isTutorial }) => {
  return isTutorial ? (
    <div
      style={{
        width: "15.3%",
        height: "22%",
        boxSizing: "border-box",
        backgroundColor: "#eae1d3",
        borderRadius: "5px",
        left: "68.9%",
        top: "1%",
        position: "absolute",
        padding: "0.5%",
        marginTop: "0.2%",
      }}
    >
      {cards &&
        cards.slice(0, 7).map(
          (card, idx) =>
            card && (
              <img
                key={`topHandCards:${idx}`}
                src={`/cards/${card.suit}${card.value}.jpg`}
                style={{
                  width: "30%",
                  position: "absolute",
                  left: `${idx * 10 + 4}%`,
                  float: "left",
                  boxShadow: "0 0 2px black",
                  maxHeight: "45%",
                  minHeight: "45%",
                  borderRadius: "5%",
                }}
              />
            )
        )}
      {cards &&
        cards.slice(7).map(
          (card, idx) =>
            card && (
              <img
                key={`topHandCards:${idx}`}
                src={`/cards/${card.suit}${card.value}.jpg`}
                style={{
                  width: "30%",
                  position: "absolute",
                  left: `${idx * 10 + 4}%`,
                  float: "left",
                  maxHeight: "45%",
                  minHeight: "45%",
                  top: "52%",
                  boxShadow: "0 0 2px black",
                  borderRadius: "5%",
                }}
              />
            )
        )}
    </div>
  ) : null;
};
