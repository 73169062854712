// register service worker for firebase cloude messaging
export const registerServiceWorker = () => {
  if ("serviceWorker" in navigator) {
    const firebaseConfig = new URLSearchParams({
      apiKey: process.env.REACT_APP_API_KEY,
      appId: process.env.REACT_APP_APP_ID,
      projectId: process.env.REACT_APP_PROJECT_ID,
      messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    }).toString();

    // Service worker URL w/config variables
    // eslint-disable-next-line no-undef
    const swUrl = `${process.env.PUBLIC_URL}/firebase-messaging-sw.js?${firebaseConfig}`;
    navigator.serviceWorker
      // .register("firebase-messaging-sw.js")
      .register(swUrl)
      .then(function (registration) {
        // eslint-disable-next-line no-console
        // console.log("[SW]: SCOPE: ", registration);
        // console.log("[SW]: SCOPE: ", registration.scope);
        return registration.scope;
      })
      .catch(function (err) {
        return err;
      });
  }
};
