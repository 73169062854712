import { PAYMENT_EVENTS, SET_LOADING_GLOBAL, BUYING_PLAN_OR_PACKAGE } from "../types";

const initlaState = {
  packages: [],
  subscriptionInfo: {
    playTimeUpdatePerInvitation: 0,
    quota: {
      remainPlayTime: [],
      invitationTimes: 0,
      playTime: 0,
      excessPlayTime: 0,
    },
    status: "close",
    playerId: "",
  },
  isLoading: false,
  isBuyingLoading: false,
};
const paymentReducer = (state = initlaState, action) => {
  switch (action.type) {
    case PAYMENT_EVENTS.SET_PAYMENT_INFO:
      return { ...state, ...action.payload.info };
    case PAYMENT_EVENTS.SET_PAYMENT_PACKAGES:
      return { ...state, packages: action.payload.packages };
    case PAYMENT_EVENTS.SET_PLAYER_SUBSCRIPTION_DATA:
      return { ...state, subscriptionInfo: action.payload };
    case SET_LOADING_GLOBAL:
      return {
        ...state,
        isLoading: action.payload,
      };
    case BUYING_PLAN_OR_PACKAGE:
      return {
        ...state,
        isBuyingLoading: action.payload,
      }
    default:
      return { ...state };
  }
};
export default paymentReducer;
