import {
  Avatar,
  Box,
  Button,
  Button as MuiButton,
  // Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import useStyles from "./styles";
import { join } from "../../store/actions/roomActions";
import RounedDialog from "../../pages/GameScreen/components/Dialog/RounedDialog";
import { COLOR_YELLOW } from "../../pages/GameScreen/components/constants";

const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_YELLOW,
    },
  },
});

const DialogInviteFriends = ({ open, onClose }) => {
  const { t } = useTranslation();
  const receives = useSelector((state) => state.room.receivedJoinInvitations);
  const table = useSelector((state) => state.room.table);
  const handleApprove = async (player) => {
    await join(table, player);
  };

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <RounedDialog
        isOpen={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "1em 2em", textAlign: "center" }}
        >
          {t("game.joinRequests")}
        </DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Box className={classes.listRequests}>
            {receives &&
              receives.map((rev, index) => {
                return (
                  <Box className={classes.requestItem} key={index}>
                    <Avatar src={rev.avatar} />
                    <Typography className={classes.nickname}>
                      {rev.nickName}
                    </Typography>
                    <Button
                      variant="contained"
                      className="button-style-1"
                      onClick={() => handleApprove(rev)}
                    >
                      {t("game.approve")}
                    </Button>
                  </Box>
                );
              })}
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "1em 2em" }}>
          <MuiButton onClick={onClose} className="button-style-1__outline">
            {t("tables.close")}
          </MuiButton>
        </DialogActions>
      </RounedDialog>
    </ThemeProvider>
  );
};

DialogInviteFriends.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DialogInviteFriends;
