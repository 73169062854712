/* eslint-disable prettier/prettier */
import {
  Box,
  Button,
  Divider,
  List,
  // styled,
  Tab,
  Tabs,
  Typography,
} from "@material-ui/core";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { STD_SIZE } from "../../../store/constant";
import TabPanel from "../TabPanel";
import { useStyles } from "./styles";

const TAB_STATUS_INDEX = "tabStatusIndex";

function TabList({ tabs = [], toggle }) {
  const minScale = useSelector((state) => state.size.minScale);
  const [drawerWidth, setDrawerWidth] = useState(STD_SIZE.STD_DRAWER_WIDTH);
  const [value, setValue] = useState(
    JSON.parse(localStorage.getItem(TAB_STATUS_INDEX)) ?? 0
  );
  const classes = useStyles({ minScale, drawerWidth });

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  useEffect(() => {
    localStorage.setItem(TAB_STATUS_INDEX, JSON.stringify(value));
  }, [value]);

  useEffect(() => {
    setValue(JSON.parse(localStorage.getItem(TAB_STATUS_INDEX)));
  }, []);

  useEffect(() => {
    const resizeWidth = STD_SIZE.STD_DRAWER_WIDTH * minScale;
    const minWidth = Math.round(STD_SIZE.STD_DRAWER_WIDTH / 2);
    if (resizeWidth < minWidth) {
      setDrawerWidth(minWidth);
    } else {
      setDrawerWidth(STD_SIZE.STD_DRAWER_WIDTH * minScale);
    }
    setValue(JSON.parse(localStorage.getItem(TAB_STATUS_INDEX)));
  }, [minScale]);

  return (
    <Box className={classes.root}>
      <Box className={classes.top}>
        <Button className={classes.closeButton} onClick={toggle}>
          <img
            className={classes.closeImg}
            src={require("../../../assets/icons/icon-close.svg").default}
            alt="close"
          />
        </Button>
      </Box>
      <Box className={classes.bottom}>
        <List className={classes.listPanel}>
          <Box className={classes.boxTabs}>
            <Tabs
              id="task-list"
              TabIndicatorProps={{ style: { backgroundColor: "transparent" } }}
              style={{
                padding: "0 5px",
              }}
              classes={{ flexContainer: classes.flexContainer }}
              value={value}
              onChange={handleChange}
            >
              {tabs.map((tab, index) => (
                <Tab
                  style={{
                    color: "#fff",
                    width: 115,
                    minWidth: 115,
                  }}
                  className={classes.tab}
                  drawerwidth={drawerWidth}
                  key={`index-${index}`}
                  label={
                    <>
                      <Box
                        className={classes.iconLabel}
                        style={{ width: 35, height: 35 }}
                      >
                        {tab.icon}
                      </Box>
                      <Typography
                        className={classes.label}
                        style={{ fontSize: 14 }}
                      >
                        {tab.label_1}
                      </Typography>
                    </>
                  }
                  value={index}
                />
              ))}
            </Tabs>
          </Box>
          {tabs.map((tab, index) => (
            <TabPanel key={`index-${index}`} value={value} index={index}>
              {tab.side}
            </TabPanel>
          ))}
        </List>
        <Divider />
      </Box>
    </Box>
  );
}

TabList.propTypes = {
  tabs: PropTypes.array,
  toggle: PropTypes.func,
};

export default TabList;
