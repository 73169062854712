/* eslint-disable prettier/prettier */
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: "border-box",
    paddingBottom: "4em",
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  box: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "auto",
    backgroundColor: "rgba(176,195,199,0.7)",
    borderRadius: "40px",
    boxSizing: "border-box",
    overflow: "hidden",
  },
  content: {
    padding: "2rem",
    overflowY: "auto",
    margin: "1rem !important",
  },
  TabsWrapp: {
    paddingBottom: "0.5rem",
    borderBottom: "1px solid #541513",
  },
  tabs: {
    width: "100%",
    display: "flex !important",
    alignItems: "center !important",
    justifyContent: "center !important",

    "& button": {
      width: "50%",
      fontWeight: "bold",
      fontSize: "16px",
    },
    "& button:hover": {
      backgroundColor: "#e9e1d4",
      color: "#541513",
    },
    "& button.Mui-selected": {
      backgroundColor: "#e9e1d4",
      color: "#541513",
    },
  },
  flexContainer: {
    justifyContent: "center",
  },

  indicator: {
    display: "none",
  },
  singleTab: {
    textTransform: "capitalize",
    fontWeight: 600,
  },
}));

export default useStyles;
