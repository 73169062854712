import styled from "styled-components";

export const TableNotifyWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;
  background-color: ${(props) => props.bg};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 20px 10px 0 10px;
  border-radius: 10px;
  cursor: pointer;
  position: relative;
  margin-top: 10px;
  flex-direction: column;
`;

export const testCom = styled.div`
  box-sizing: border-box;
  width: 100%;
  display: flex;
  align-items: center;
  position: relative;
`;

export const TableText = styled.p`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const Timestamp = styled.p`
  position: absolute;
  top: 8px;
  right: 10px;
  font-weight: 300;
  font-size: 12px;
  word-spacing: 10px;
`;

export const TableNotifyLeft = styled.div`
  flex-shrink: 0;
  width: 50px;
`;

export const TableNotifyRight = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding-left: 10px;
`;

export const ButtonText = styled.p`
  letter-spacing: ${(props) => (props.language != +"en" ? 15 : 0)};
  margin-right: ${(props) => (props.language != +"en" ? -15 : 0)};
  display: block;
  text-align: right;
`;

export const DialogText = styled.p`
  width: 100%;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  color: #000;
`;

export const InviteItemFooter = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid #442211;
`;
