import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    justifyContent: "center",
    color: "black",
    overflow: "auto",
  },
  contaier: {
    width: "500px",
    backgroundColor: "#f3f3f3",
    padding: "2rem",
    borderRadius: "20px",
    boxSizing: "border-box",
    color: "#541513",
    boxShadow:
      "rgba(0, 0, 0, 0.07) 0px 1px 1px, rgba(0, 0, 0, 0.07) 0px 2px 2px, rgba(0, 0, 0, 0.07) 0px 4px 4px, rgba(0, 0, 0, 0.07) 0px 8px 8px, rgba(0, 0, 0, 0.07) 0px 16px 16px",
  },
  btns: {
    display: "flex",
    gap: "20px",
    marginTop: "2rem",
    justifyContent: "center",
  },
  btnGoHome: {
    width: "180px",
    padding: "0.5rem 2rem",
    background: "#ce5931",
    color: "#fff",
    fontSize: "16px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    border: "none",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#7d7d7d",
    },
  },
  btnGoContact: {
    width: "180px",
    padding: "0.5rem 2rem",
    background: "#7d7d7d",
    color: "#fff",
    fontSize: "16px",
    boxShadow:
      "rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px",
    border: "none",
    cursor: "pointer",

    "&:hover": {
      backgroundColor: "#7d7d7d",
    },
  },
}));
export default useStyles;
