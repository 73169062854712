import React from "react";
import PropTypes from "prop-types";
import { CardsWrapper } from "../styles";
const PlayerCardWrapper = ({ height, children }) => {
  return <CardsWrapper height={height}>{children}</CardsWrapper>;
};

PlayerCardWrapper.propTypes = {
  height: PropTypes.number,
  children: PropTypes.any,
};

export default PlayerCardWrapper;
