import { Box, Dialog, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";
import { removeNarrationPopup } from "../../../../../store/actions/narrationActions";
import { SCREEN_NARRATION } from "../../../../../store/constant";
import FlippedCard from "../../FlippedCard";
import {
  CardSelectedWrapper,
  CardWrapper,
  Container,
  Nickname,
  TitleWrapper,
} from "./Narration34G0G1Styles";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: "calc(50% - 232px)",
    top: (props) => `calc(${props.top}% - 150px)`,
  },
});
const Narration4_123 = () => {
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const [isShowGCard, setIsShowGCard] = useState(false);
  // const isNarrationText = useSelector(
  //   (state) => state.user.setting.isNarrationText
  // );
  const classes = useStyles({
    top: 18,
  });
  const { t } = useTranslation();
  const dispatch = useDispatch();
  useEffect(() => {
    const isGCard = SCREEN_NARRATION.SG_STEP3_G_CARD === confirmId;

    setIsShowGCard(isGCard);
    if (confirmId === SCREEN_NARRATION.SG_STEP3_G_CARD_CLEAR) {
      dispatch(removeNarrationPopup(SCREEN_NARRATION.SG_STEP3_G_CARD_CLEAR));
    }
  }, [confirmId, dispatch]);
  const size = useSelector((state) => state.size);
  return (
    <Dialog
      open={isShowGCard}
      PaperProps={{
        style: {
          borderRadius: "35px",
          backgroundColor: "#f7f7f7",
        },
      }}
      PaperComponent={PaperComponent}
      classes={{ paper: classes.dialog }}
      style={{ zIndex: "1000 !important" }}
    >
      <Box id="draggable-dialog-title" width={400} height={300}>
        <Box height="100%">
          <Container>
            <TitleWrapper>
              <Box marginTop={"10px"} marginLeft={"30px"} marginRight={"30px"}>
                {t("narrations.narration3")}
              </Box>
            </TitleWrapper>
            <CardWrapper>
              <Box display="flex">
                <CardSelectedWrapper>
                  <Nickname hasNickname={true}>
                    <Box component="p" fontWeight={700}>
                      {"G0"}
                    </Box>
                  </Nickname>
                  <FlippedCard
                    flipped={true}
                    src={`/cards/G0.jpg`}
                    onClickSelectCard={() => {}}
                    size={size}
                  />
                </CardSelectedWrapper>
                <CardSelectedWrapper>
                  <Nickname hasNickname={true}>
                    <Box component="p" fontWeight={700}>
                      {"G1"}
                    </Box>
                  </Nickname>
                  <FlippedCard
                    flipped={true}
                    src={`/cards/G1.jpg`}
                    onClickSelectCard={() => {}}
                    size={size}
                  />
                </CardSelectedWrapper>
              </Box>
            </CardWrapper>
          </Container>
        </Box>
      </Box>
    </Dialog>
  );
};
export default React.memo(Narration4_123);
