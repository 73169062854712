import { colorsPlayerStatus } from "../../constants";
import firebase, { getFunctions } from "../../services/firebase";
import { getPlayerStatus } from "../../store/actions/chat";
import { FRIEND_REQUEST_ALLOW } from "../constant";
import {
  CREATE_PAYMENT_CUSTOMER,
  GET_EMAIL_BY_PLAYER_ID,
  UPDATE_USER_OF_PLAY_TIME,
  DELETE_PLAYER,
  CHECK_NUMBER_PHONE_EXITS,
  GET_PAYMENT_METHOD_ID,
} from "../funcs";
import { SET_PLAYER_LIST } from "../types";
import { checkFriendOfListFriend } from "./friendAction";
import PlayerRepo from "../repositories/player";

const NOTIFICATIONS = "notifications";
const FRIEND_REQUESTS = "friendRequests";

const functions = getFunctions();

const playerRef = firebase.firestore().collection("players");

export const createPaymentCustomerAction = async (playerId) => {
  await functions.httpsCallable(CREATE_PAYMENT_CUSTOMER)({ playerId });
};

async function checkShowButtonAddFriend(
  player = {},
  friends = [],
  myId = "",
  myFriendRequests = []
) {
  try {
    const { bannedList, friendRequestAllow, friendRequests, playerId } = player;
    const isSentRequest = await checkSentRequest(
      friendRequests,
      myId,
      myFriendRequests,
      playerId
    );
    if (isSentRequest) return false;
    const isBanned = await checkPlayerBanned(bannedList, myId);
    if (isBanned) return false;
    const isAllow = await checkFriendRequestAllow(
      friendRequestAllow,
      player.friends,
      friends
    );
    return { isAllow, friendRequestAllow };
  } catch (error) {
    console.log(error);
  }
}

async function checkPlayerBanned(bannedList = [], playerId = "") {
  return bannedList.includes(playerId);
}

async function checkFriendRequestAllow(
  allow = "",
  friends = [],
  otherFriends = []
) {
  try {
    if (allow === FRIEND_REQUEST_ALLOW.NOT_ALLOW) return false;
    if (allow === FRIEND_REQUEST_ALLOW.ALL_USER) return true;
    if (allow === FRIEND_REQUEST_ALLOW.FRIEND_OF_FRIENDS) {
      return await checkFriendOfListFriend(friends, otherFriends);
    }
    return false;
  } catch (error) {
    console.log(error);
  }
}

function checkSentRequest(
  friendRequests = [],
  myId = "",
  myFriendRequests = [],
  otherId = ""
) {
  const otherFR =
    friendRequests.findIndex((f) => f && f.friendId === myId) !== -1;
  const myFR =
    myFriendRequests.findIndex((f) => f && f.friendId === otherId) !== -1;
  return myFR || otherFR;
}

export const subscribePlayer = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    const { playerId } = getState().user;

    const unsubscribe = playerRef.onSnapshot(async (snapshot) => {
      const { friends, friendRequests } = getState().user;
      const promises = snapshot.docs.map(async (doc) => {
        const player = doc.data();
        const colorPlayerStatus = colorsPlayerStatus[getPlayerStatus(player)];
        const isFriend = friends
          ?.map((item) => item.friendId)
          .includes(player.playerId);
        let threadId = "";
        if (isFriend) {
          const friendFilter = friends?.filter((f) => f.friendId === playerId);
          if (friendFilter.length > 0) {
            threadId = friendFilter[0].threadId;
          }
        }
        let { isAllow, friendRequestAllow } = await checkShowButtonAddFriend(
          player,
          friends,
          playerId,
          friendRequests
        );
        if (player.playerId === playerId) {
          isAllow = false;
        }
        if (!friendRequestAllow) {
          friendRequestAllow = FRIEND_REQUEST_ALLOW.ALL_USER;
        }

        return {
          playerId: player.playerId ?? "",
          nickName: player.nickName ?? "",
          avatar: player.avatar ?? "",
          isOnline: player.isOnline ?? false,
          showOnline: player.showOnline ?? false,
          colorPlayerStatus,
          isFriend,
          isShowButtonAddFriend: isAllow,
          allowMode: friendRequestAllow,
          threadId,
        };
      });
      const promisesResult = await Promise.all(promises);
      const playerFiltered = promisesResult.filter((pr) => pr);
      dispatch({
        type: SET_PLAYER_LIST,
        playerList: playerFiltered,
      });
    });
    setUnsubscribe(unsubscribe);
  };
};

export const seenAllNotifications = async (playerId) => {
  try {
    if (playerId) {
      const player = await PlayerRepo.read(playerId);
      if (player) {
        //update seen notification
        const notifications = player[NOTIFICATIONS] ?? [];
        const newNotifications = notifications.map((notify) => {
          if (notify["seen"] == false) {
            return {
              ...notify,
              seen: true,
            };
          }
          return notify;
        });

        //update seen friend requests
        const friendRequestData = player[FRIEND_REQUESTS] ?? [];
        const newFriendRequest = friendRequestData.map((friendrequest) => {
          if (friendrequest["seen"] == false) {
            return {
              ...friendrequest,
              seen: true,
            };
          }
          return friendrequest;
        });
        await PlayerRepo.update(playerId, {
          [NOTIFICATIONS]: newNotifications,
          [FRIEND_REQUESTS]: newFriendRequest,
        });
        return;
      }
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 * remove player from room when room not existed
 * @param {string} playerId
 */
export async function leaveRoomWhenRoomNotExisted(playerId = "") {
  try {
    if (!playerId) {
      console.log("leaveRoomWhenRoomNotExisted: playerId is empty");
    }
    await PlayerRepo.update(playerId, { currentRoomJoinedId: null });
  } catch (error) {
    console.log("leaveRoomWhenRoomNotExisted", error);
  }
}

export const getEmailByPlayerId = async (playerId) => {
  try {
    const getEmailByPlayerIdFunc = functions.httpsCallable(
      GET_EMAIL_BY_PLAYER_ID
    );
    return getEmailByPlayerIdFunc({ playerId });
  } catch (e) {
    console.error(e);
  }
};

export const updateUserOfPlayTime = async (
  playerId,
  nickName,
  fullName,
  avatar,
  gender
) => {
  try {
    const updateUserOfPlayTimeFUNC = functions.httpsCallable(
      UPDATE_USER_OF_PLAY_TIME
    );
    return updateUserOfPlayTimeFUNC({
      playerId,
      nickName,
      fullName,
      avatar,
      gender,
    });
  } catch (e) {
    console.error(e);
  }
};

export const deletePlayer = async (playerId) => {
  try {
    const deletePlayerFUNC = functions.httpsCallable(DELETE_PLAYER);
    return deletePlayerFUNC({ playerId });
  } catch (e) {
    console.error(e);
  }
};

// TODO: check number phone exits
export const checkNumberPhoneExits = async (numberPhone) => {
  try {
    return functions.httpsCallable(CHECK_NUMBER_PHONE_EXITS)({ numberPhone });
  } catch (error) {
    console.log(error);
  }
};

export const getPaymentMethodIdByPlayerId = async (playerId) => {
  try {
    return functions.httpsCallable(GET_PAYMENT_METHOD_ID)({ playerId });
  } catch (error) {
    console.log(error);
  }
};
