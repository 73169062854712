import { SET_TABLE_DATA } from "../types";
const initJoinedTable = {
  tableId: "",
  isPlaying: false,
  currentPlayersId: [],
  owner: "",
  tableAvatar: "",
  description: "",
  roomId: "",
  tableName: "",
  isGChaoComputer: false,
};

const joinedTableReducer = (state = initJoinedTable, action) => {
  switch (action.type) {
    case SET_TABLE_DATA:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
export default joinedTableReducer;
