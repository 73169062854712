import React from "react";
import { CenterItem } from "../styles";
import PropTypes from "prop-types";
const CenterItemComponent = ({ width, children }) => {
  return <CenterItem width={width}>{children}</CenterItem>;
};

CenterItemComponent.propTypes = {
  width: PropTypes.number,
  children: PropTypes.any,
};
export default CenterItemComponent;
