import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  z-index: +1;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: auto;
  background-image: url("/assets/bg_master_cards.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`;

export const TitleWrapper = styled.div`
  width: 100%;
  text-align: center;
  color: white;
`;

export const CardWrapper = styled.div`
  width: 100%;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  & > div {
    margin: 0 15px;
  }
  display: flex;
  flex-direction: column;
`;

export const CardSelectedWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 110px;
  max-width: 150px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 12px;
`;

export const Nickname = styled.div`
  width: 110%;
  height: 28px;
  box-sizing: border-box;
  padding: 2px 5px;
  background-color: ${(props) =>
    props.hasNickname ? "#ffffff" : "transparent"};
  border-radius: 999px;
  margin-bottom: 5px;

  display: flex;
  align-items: center;
  justify-content: center;
  & > p {
    width: 90%;
    text-align: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;
