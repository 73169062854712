import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const TimeRemainClock = ({
  time,
  isRemaining,
  handleByPlayTime,
  handleUpPlan,
}) => {
  const { t } = useTranslation();
  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);
  const classes = useStyles({ isFree: playTimeInfo?.statusFree });
  const minutes = moment(time).minute();
  const seconds = moment(time).seconds();
  return (
    <div className={classes.clockBox}>
      {isRemaining && (
        <div className={classes.timeRemainingBox}>
          <p className={classes.timeRemainingTitle}>{t("clock.timeLeft")}</p>
          <p className={classes.timeRemaining}>
            {minutes}
            <span>{t("clock.minutes")}</span>
            {seconds}
            <span>{t("clock.seconds")}</span>
          </p>
        </div>
      )}
      {!isRemaining && (
        <div className={classes.clockInfo}>
          <p>{t("clock.clockInfoTitle1")}</p>
          <p>{t("clock.clockInfoTitle2")}</p>
          <p>{t("clock.clockInfoTitle3")}</p>
        </div>
      )}

      <div className={classes.playTimeBox}>
        <span>{t("clock.numberPlayTime")}</span>
        {playTimeInfo.statusFree && (
          <span>{t("paymentSettings.packagePlayTimesFree")}</span>
        )}
        {!playTimeInfo.statusFree && (
          <span>{`${
            playTimeInfo?.playTimes !== undefined ? playTimeInfo.playTimes : ""
          } ${t("clock.times")}`}</span>
        )}
      </div>
      {!playTimeInfo.statusFree && (
        <div className={classes.btn}>
          <button onClick={handleByPlayTime}>
            {t("clock.byRetailPackage")}
          </button>
        </div>
      )}
      <div className={classes.btn}>
        <button onClick={handleUpPlan}>{t("clock.planUpgrade")}</button>
      </div>
    </div>
  );
};

TimeRemainClock.propTypes = {
  time: PropTypes.any,
  isRemaining: PropTypes.bool,
  handleByPlayTime: PropTypes.func,
  handleUpPlan: PropTypes.func,
};

export default TimeRemainClock;
