import { SET_ALL_PACKAGES, SET_ACTIVE_PACKAGE } from "../types";

const initlaState = {
  packages: [],
  activePackage: {},
};
const packageReducer = (state = initlaState, action) => {
  switch (action.type) {
    case SET_ALL_PACKAGES:
      return {
        ...state,
        packages: action.payload,
      };
    case SET_ACTIVE_PACKAGE:
      return {
        ...state,
        activePackage: action.payload,
      };

    default:
      return { ...state };
  }
};
export default packageReducer;
