import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import { Route, useHistory } from "react-router-dom";
import firebase from "../../services/firebase";
import { adminRef } from "../../store/actions/adminAction";
import { getAllPackages } from "../../store/actions/packageActions";
import { ADMIN, SET_ALL_PACKAGES } from "../../store/constant";
import { SET_ADMIN_ID } from "../../store/types";
import Header from "./components/Header";
import SiderBar from "./components/SiderBar";
import useStyles from "./styles";

const auth = firebase.auth();
// eslint-disable-next-line react/prop-types
export default function AdminRoute({ component: Component, ...restParam }) {
  const [toggleSiderBar, setToggleSiderBar] = useState(true);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [adminId, setAdminId] = useState("");

  const classes = useStyles({ toggleSiderBar });

  //TODO: get all packages
  const getAllPackageFunc = async () => {
    const packages = await getAllPackages();
    if (packages.data && packages.data.length > 0) {
      dispatch({
        type: SET_ALL_PACKAGES,
        payload: packages.data,
      });
    }
  };

  //get admin nickName and set to redux
  useEffect(() => {
    let unsubscribe = () => {};
    if (adminId) {
      unsubscribe = adminRef.doc(adminId).onSnapshot((snapshot) => {
        dispatch({
          type: SET_ADMIN_ID,
          payload: snapshot.data(),
        });
      });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adminId]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // get claims in user
        const response = await user.getIdTokenResult();
        // check claims admin
        if (response.claims.admin !== ADMIN) {
          alert(`${t("admin.youDoNotHaveaccess")}`);
          await auth.signOut();
          return;
        }
        if (response.claims.admin) {
          setAdminId(response.claims.user_id);
        }
        getAllPackageFunc();
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!firebase.auth().currentUser) {
    history.push("/admin-login");
  }

  return (
    <Route
      {...restParam}
      render={(propsRoute) => {
        return (
          <div className={classes.admin}>
            <div className={classes.siderBar}>
              <SiderBar />
            </div>
            <div className={classes.adminBody} style={{ position: "relative" }}>
              <div className={classes.adminHeader}>
                <Header setToggleSiderBar={setToggleSiderBar} />
              </div>
              <div className={classes.adminContent}>
                <Component {...propsRoute} />
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
