import { orderBy } from "lodash";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Route, useHistory, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import { invoiceType } from "../../constants";
import firebase from "../../services/firebase";
import {
  chatRef,
  chatStatusRef,
  checkCountUnMessage,
  getThreadName,
} from "../../store/actions";
import { logout } from "../../store/actions/authActions";
import { ADMIN, CHAT_TYPE } from "../../store/constant";
import { invoiceRef } from "../../store/repositories/invoice";
import packageRepo, { packageRef } from "../../store/repositories/package";
import { playTimeRef } from "../../store/repositories/playTime";
import {
  OPEN_CHAT_BOX_BY_THREAD_ID,
  SET_ACTIVE_PACKAGE,
  SET_ALL_PACKAGES,
  SET_CHAT_STATUS,
  SET_DATA_LIST_ROOM,
  // SET_LOADING_GLOBAL,
  BUYING_PLAN_OR_PACKAGE,
  SET_PLAY_TIME,
  UN_READ_MESSAGE,
} from "../../store/types";

const auth = firebase.auth();
export default function PrivateRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const playerId = useSelector((state) => state.user.playerId);
  const tableName = useSelector((state) => state.room.tableName);
  const threadId = useSelector((state) => state.room.threadId);
  const threads = useSelector((state) => state.user.threads);
  const chatStatus = useSelector((state) => state.chat.chatStatus);
  const selectedThreadId = useSelector((state) => state.chat.selectedThreadId);
  const dataListRoom = useSelector((state) => state.chat.dataListRoom);
  const isOpenChatModal = useSelector((state) => state.chat.isOpenChatModal);
  const history = useHistory();
  if (location.search !== "") {
    const gameUrl = location.pathname + location.search;
    localStorage.setItem("gameUrl", gameUrl);
  }

  // TODO: Get all data colection chatStatus
  useEffect(() => {
    let unsubscribe = () => {};
    if (threads && threads.length > 0) {
      unsubscribe = chatStatusRef.onSnapshot((querySnapshot) => {
        let chatStatusData = [];
        querySnapshot.forEach((doc) => {
          if (threads.includes(doc.data().threadId)) {
            chatStatusData.push(doc.data());
          }
        });
        dispatch({
          type: SET_CHAT_STATUS,
          payload: chatStatusData,
        });
      });
    } else {
      dispatch({
        type: SET_CHAT_STATUS,
        payload: [],
      });
    }
    return () => unsubscribe();
    // eslint-disable-next-line
  }, [threads]);

  // TODO: follow the chatStatus collection when there are changes "check unread messages"
  useEffect(() => {
    let countUnreadMessage = 0;
    if (chatStatus && chatStatus.length > 0) {
      countUnreadMessage = chatStatus?.reduce((_total, item) => {
        if (
          checkCountUnMessage(item?.threadId, playerId, chatStatus) > 0 &&
          item?.threadId !== selectedThreadId
        ) {
          return ++_total;
        }
        return _total;
      }, 0);
    }
    dispatch({
      type: UN_READ_MESSAGE,
      payload: countUnreadMessage,
    });
    // eslint-disable-next-line
  }, [chatStatus, selectedThreadId]);

  //TODO: Open modal chat when new message
  useEffect(() => {
    //loc theo thoi gian lay threaid gan nhat;
    if (dataListRoom && dataListRoom.length > 0) {
      const sortedArr = orderBy(
        dataListRoom.filter((m) => m !== null),
        "lastTimeInteractive",
        "desc"
      );
      const threadIsNewMessage = sortedArr[0];

      if (localStorage.getItem("newMessageTime")) {
        const preTime = JSON.parse(localStorage.getItem("newMessageTime"));
        if (
          threadIsNewMessage.lastTimeInteractive > preTime &&
          (isOpenChatModal === false ||
            threadIsNewMessage?.threadId === threadId)
        ) {
          dispatch({
            type: OPEN_CHAT_BOX_BY_THREAD_ID,
            payload: threadIsNewMessage?.threadId,
          });
          localStorage.setItem(
            "newMessageTime",
            JSON.stringify(threadIsNewMessage.lastTimeInteractive)
          );
        }
      } else {
        localStorage.setItem("newMessageTime", JSON.stringify(0));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataListRoom]);

  // TODO: get all data room by threads of user
  useEffect(() => {
    let unsubscribe = async () => {};

    if (threads && threads.length > 0) {
      unsubscribe = chatRef.onSnapshot((querySnapshot) => {
        let data = [];
        querySnapshot.forEach((doc) => {
          if (threads.includes(doc.data().threadId)) {
            const dataRoomChat = doc.data();
            const displayRoom = getThreadName(
              dataRoomChat,
              playerId,
              tableName
            );
            data.push({ ...dataRoomChat, displayRoom });
          }
        });

        //TODO: reprocess the array in case of multi-room error CHAT_TYPE === ROOM
        let filterThreadTypeRoom =
          data?.filter((f) => f.threadType === CHAT_TYPE.ROOM) || [];
        let filterThreadTypeDiffRoom =
          data?.filter((f) => f.threadType !== CHAT_TYPE.ROOM) || [];
        let getThreadTypeRoomCurrent =
          filterThreadTypeRoom?.filter((f) => f.threadId === threadId) || [];
        let _data = [...getThreadTypeRoomCurrent, ...filterThreadTypeDiffRoom];

        dispatch({
          type: SET_DATA_LIST_ROOM,
          payload: _data,
        });
      });
    } else {
      dispatch({
        type: SET_DATA_LIST_ROOM,
        payload: [],
      });
    }

    return () => unsubscribe();
    // eslint-disable-next-line
  }, [threads, tableName, threadId]);

  //TODO: real time invoice
  useEffect(() => {
    let unsubscribe = async () => {};

    if (playerId) {
      unsubscribe = invoiceRef
        .where("playerId", "==", playerId)
        .onSnapshot((querySnapshot) => {
          let invoices = [];
          querySnapshot.forEach((doc) => {
            invoices.push(doc.data());
          });

          if (invoices.length > 0) {
            const sortedInvoices = orderBy(invoices, "completeDate", "desc");
            const newInvoice = {
              ...sortedInvoices[0],
              time: new Date(sortedInvoices[0].completeDate).getTime(),
            };
            if (!localStorage.getItem("newInvoiceTime")) {
              localStorage.setItem(
                "newInvoiceTime",
                JSON.stringify({ time: 0, status: "", type: "" })
              );
            }

            const invoicePre = JSON.parse(
              localStorage.getItem("newInvoiceTime")
            );
            if (newInvoice.time > invoicePre.time) {
              const _newInvoice = {
                time: newInvoice.time,
                status: newInvoice.status,
                type: newInvoice.type,
              };
              localStorage.setItem(
                "newInvoiceTime",
                JSON.stringify(_newInvoice)
              );

              if (newInvoice.type === invoiceType.SUB) {
                toast[`${newInvoice.status === "paid" ? "success" : "error"}`](
                  `${
                    newInvoice.status === "paid"
                      ? t("paymentSettings.ChangePlanSuccess")
                      : t("paymentSettings.ChangePlanFail")
                  }`
                );
              } else if (newInvoice.type === invoiceType.PAYMENT) {
                toast[`${newInvoice.status === "paid" ? "success" : "error"}`](
                  `${
                    newInvoice.status === "paid"
                      ? t("paymentSettings.paymentSuccess")
                      : t("paymentSettings.paymentFail")
                  }`
                );
              }
            }
          }
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        // get claims in user
        const response = await user.getIdTokenResult();
        // check claims admin
        if (response.claims.admin === ADMIN) {
          alert(`${t("admin.youDoNotHaveaccess")}`);
          dispatch(logout);
          history.push("/admin-login");
        }
      }
    });
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  //TODO: Get all packages
  useEffect(() => {
    let unsubscribe = async () => {};

    if (playerId) {
      unsubscribe = packageRef.onSnapshot((querySnapshot) => {
        let packages = [];
        querySnapshot.forEach((doc) => {
          packages.push(doc.data());
        });

        if (packages && packages.length > 0) {
          const sortedInvoices = orderBy(packages, "packageIndex");
          dispatch({
            type: SET_ALL_PACKAGES,
            payload: sortedInvoices,
          });
        }
      });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  //TODO: packageActive real time
  useEffect(() => {
    let unsubscribe = async () => {};

    if (playerId) {
      unsubscribe = playTimeRef
        .where("playerId", "==", playerId)
        .onSnapshot(async (querySnapshot) => {
          let playTimes = [];
          querySnapshot.forEach((doc) => {
            playTimes.push(doc.data());
          });

          if (playTimes && playTimes.length > 0) {
            const playTimeActive = playTimes[0];
            const packageCurrent = await packageRepo.read(
              playTimeActive.packageId
            );

            if (packageCurrent) {
              dispatch({
                type: SET_ACTIVE_PACKAGE,
                payload: packageCurrent,
              });
            }
          }
        });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  //TODO: playTimeInfo real time
  useEffect(() => {
    let unsubscribe = async () => {};

    if (playerId) {
      unsubscribe = playTimeRef
        .where("playerId", "==", playerId)
        .onSnapshot(async (querySnapshot) => {
          let playTimes = [];
          querySnapshot.forEach((doc) => {
            playTimes.push(doc.data());
          });

          if (playTimes && playTimes.length > 0) {
            const playTimeActive = playTimes[0];
            if (playTimeActive) {
              dispatch({
                type: SET_PLAY_TIME,
                payload: playTimeActive,
              });
            }
          }
        });
    }
    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerId]);

  if (!firebase.auth().currentUser) {
    history.push("/login");
  }

  return (
    <Route {...rest} render={(props) => <Component {...rest} {...props} />} />
  );
}

PrivateRoute.propTypes = {
  component: PropTypes.elementType,
  location: PropTypes.object,
};
