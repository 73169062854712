import { SET_SUBSCRIBE, SET_UNSUBSCRIBE } from "../types";

const initialState = {
  subscribes: [],
};

const subscribeReducer = (state = initialState, action) => {
  let subscribes = [];
  switch (action.type) {
    case SET_SUBSCRIBE:
      subscribes = state.subscribes;
      subscribes.push(action.payload.subscribe);
      return {
        ...state,
        subscribes: subscribes,
      };
    case SET_UNSUBSCRIBE:
      subscribes = state.subscribes;
      if (subscribes && subscribes.length > 0) {
        // unsubScribe all
        subscribes.forEach((func) => func());
      }
      return initialState;

    default:
      return state;
  }
};

export default subscribeReducer;
