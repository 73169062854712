import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { COLOR_RED_BROWN } from "../../store/constant";
const useStyles = makeStyles((theme) => ({
  root: {
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    color: "#541513",
    overflow: "auto",
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  link: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    color: "#dd501f",
    "&:hover": {
      color: "yellow",
    },
  },
  forgotLink: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    color: "white",
  },
  loginBtn: {
    height: 50,
    width: 200,
    border: "2px solid black",
    margin: "0 !important",
    backgroundColor: COLOR_RED_BROWN,
    "& span": {
      marginLeft: 7,
    },
  },
  input: {
    width: "350px",
    height: "51px",
    color: "#541513",
    transition: "background-color 5000s ease-in-out 0s",
    fontWeight: 600,
    backgroundColor: "#e9e1d4",
    padding: "18px 14px",
    boxSizing: "border-box",
    outline: "none",

    "&:disabled": {
      color: "#c1c1c1",
    },

    "&::placeHolder": {
      color: "#c1c1c1",
      fontWeight: 600,
    },
  },
  labelInput: {
    minWidth: "140px",
    textAlign: "left",
  },
}));

export const LogoGame = styled.div`
  aspect-ratio: 1/1;
  background: url("/assets/intro2.png");
  background-repeat: no-repeat;
  background-size: 100% 80%;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;
export const Production = styled.div`
  background: url("/assets/introtext2.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  margin-bottom: auto;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  position: relative;
  top: -5%;
`;
export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export default useStyles;
