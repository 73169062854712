import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    width: "100vw",
    height: "95vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "24px",
    color: "#541513",
    overflow: "auto",
    marginTop: "1rem",
    borderRadius: "24px",
    background: "#8baebedb  !important",
  },
  wrapper: {
    height: "100% !important",
    overflow: "auto",
  },
  mt20: {
    marginTop: "20px !important",
  },
}));

export default useStyles;
