import {
  Box,
  Button,
  Checkbox,
  // Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  Tooltip,
} from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import RadioGroup from "../../../components/RadioGroup";
import { COLOR_YELLOW } from "../../../pages/GameScreen/components/constants";
import RounedDialog from "../../../pages/GameScreen/components/Dialog/RounedDialog";
import BasicDialog from "../../../pages/GameScreen/components/NewDialog/BasicDialog";
import {
  removeRoom,
  updateTableSettings,
} from "../../../store/actions/tableActions";
import {
  MAX_CHARACTER_DESC,
  MAX_CHARACTER_NAME,
} from "../../../store/constant";
import theme from "../../../theme";
import AvatarUpload from "../../AvatarUpload";
import MuiInput from "../../MuiInput";
import useStyles from "../TableSettingsInRoom/styles";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const initialValues = {
  tableId: "",
  tableName: "",
  joinTableRole: "",
  description: "",
  tableAvatar: "",
  isGChaoComputer: false,
  roomId: "",
  tableUrl: "",
};

const TableSettingsInRoom = ({ open, onClose }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [image, setImage] = useState(null);
  const table = useSelector((state) => state.room.table);
  const { accessToken } = useSelector((state) => state.room);
  const [copied, setCopied] = useState(false);
  const [openConfirmClearRoom, setOpenConfirmClearRoom] = useState(false);
  const profile0 = useSelector((state) => state.room.profile0);
  const players = useSelector((state) => state.room.joinedPlayers);
  const roomId = useSelector((state) => state.room.table.roomId);
  const tableId = useSelector((state) => state.room.table.tableId);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const threadId = useSelector((state) => state.room.threadId);
  const playerReady = useSelector((state) => state.room.playerReady);
  const [isLoading, setIsLoading] = useState(false);
  const joinTableItems = [
    { id: "open", title: t("settings.open") },
    { id: "close", title: t("settings.close") },
  ];

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
  };
  const handleCheckBoxChange = (e, checked) => {
    const { name } = e.target;
    setValues({ ...values, [name]: checked });
  };

  const handleSave = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    const newValue = JSON.parse(JSON.stringify(values));
    newValue.tableAvatar = image;
    const dataUpdate = { ...newValue, playerReady: playerReady };
    await updateTableSettings(dataUpdate);
    onClose();
    setIsLoading(false);
  };

  const handleCancel = () => {
    setValues((val) => ({
      ...val,
      tableName: table.tableName,
      joinTableRole: table.joinTableRole,
      description: table.description,
      isGChaoComputer: table.isGChaoComputer,
      tableAvatar: table.tableAvatar,
    }));
    onClose();
  };

  const assignValues = useCallback(() => {
    if (table && accessToken) {
      const url = "".concat(
        window.location.origin,
        "/game/",
        table.roomId,
        "?access_token=",
        accessToken
      );
      setValues((val) => ({
        ...val,
        tableId: table.tableId,
        tableName: table.tableName,
        joinTableRole: table.joinTableRole,
        description: table.description,
        isGChaoComputer: table.isGChaoComputer,
        tableAvatar: table.tableAvatar,
        roomId: table.roomId,
        tableUrl: url,
      }));
    }
  }, [table, accessToken]);

  const { detect } = require("detect-browser");
  const browser = detect();
  const handleClearRoom = async () => {
    localStorage.setItem("isShowJoinRoomTwilio", JSON.stringify(false));
    setOpenConfirmClearRoom(false);
    setIsLoading(true);
    const myId = profile0.playerId;
    const actualPlayersIs = players
      .filter((p) => p && p.nickName !== "G-Chaos")
      .map((p) => p.playerId);
    await removeRoom(actualPlayersIs, myId, gameId, tableId, roomId, threadId);
    setIsLoading(false);
    if (browser.name === "safari") {
      location.reload();
    }
  };

  const handlePlayerClearRoom = async () => {
    setOpenConfirmClearRoom(true);
  };

  useEffect(() => {
    assignValues();
  }, [assignValues]);

  useEffect(() => {
    const clearCopied = setTimeout(() => {
      if (copied) {
        setCopied(false);
      }
    }, 2000);
    return () => clearTimeout(clearCopied);
  }, [copied]);

  return (
    <ThemeProvider theme={theme}>
      <RounedDialog
        isOpen={open}
        onClose={onClose}
        aria-labelledby="form-dialog-title"
        fullWidth
        style={{ width: "100%" }}
      >
        <DialogTitle id="form-dialog-title">
          <Box
            fontWeight="fontWeightBold"
            display="flex"
            justifyContent="center"
          >
            {t("tables.tableSetting")}
          </Box>
        </DialogTitle>
        <DialogContent className={classes.tableForm}>
          <Box display="flex" justifyContent="center">
            <AvatarUpload
              avatar={values.tableAvatar}
              name="avatar"
              id="avatar"
              provider="table"
              imageProp={image}
              setImageProp={setImage}
            />
          </Box>
          <Box>
            <MuiInput
              autoFocus={true}
              name="tableName"
              label={t("tables.tableName")}
              type="text"
              value={values.tableName}
              required={false}
              onChange={handleChangeValues}
              maxLength={MAX_CHARACTER_NAME}
            />
          </Box>
          <Box>
            <RadioGroup
              name="joinTableRole"
              label={t("tables.joinTableRole")}
              value={values.joinTableRole}
              items={joinTableItems}
              onChange={handleChangeValues}
              className={classes.radio}
            />
          </Box>
          <Box>
            <MuiInput
              name="description"
              label={t("tables.description")}
              type="text"
              value={values.description}
              required={false}
              onChange={handleChangeValues}
              maxLength={MAX_CHARACTER_DESC}
            />
          </Box>
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={handleCheckBoxChange}
                  name="isGChaoComputer"
                  value={values.isGChaoComputer}
                  checked={values.isGChaoComputer}
                />
              }
              label={t("tables.gChaosIsComputer")}
            />
          </Box>
          <Box
            display={
              values.joinTableRole === "open" && values.notify ? "none" : ""
            }
          ></Box>
          <Box>
            <FormControl
              style={{ width: "100%" }}
              className={classes.formControl}
            >
              <Tooltip title={copied ? t("tables.copied") : ""}>
                <CopyToClipboard
                  text={values.tableUrl}
                  onCopy={() => setCopied(true)}
                >
                  <Button
                    style={{ backgroundColor: COLOR_YELLOW, borderRadius: "0" }}
                  >
                    {t("tables.copyURL")}
                  </Button>
                </CopyToClipboard>
              </Tooltip>
              <input
                name="tableUrl"
                id="tableUrl"
                value={values.tableUrl}
                style={{ display: "none" }}
                onChange={() => {}}
              ></input>
            </FormControl>
          </Box>
          <Box>
            <FormControl
              style={{ width: "100%" }}
              className={classes.formControl}
            >
              <Button
                style={{ backgroundColor: COLOR_YELLOW, borderRadius: "0" }}
                onClick={handlePlayerClearRoom}
              >
                {t("tables.clearRoom")}
              </Button>
            </FormControl>
          </Box>
        </DialogContent>

        <DialogActions style={{ padding: "1em 2em" }}>
          <Button onClick={handleCancel} className="button-style-1__outline2">
            {t("tables.cancel")}
          </Button>
          <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Button
            onClick={handleSave}
            disabled={String(values.tableName).trim() === ""}
            className="button-style-1"
          >
            {t("tableSettings.save")}
          </Button>
        </DialogActions>
      </RounedDialog>
      {/* CLEAR ROOM */}
      <BasicDialog
        isOpen={openConfirmClearRoom}
        onCancel={() => setOpenConfirmClearRoom(false)}
        onAgree={handleClearRoom}
        closeText={t("tables.cancel")}
        agreeText={t("tables.clearRoom")}
        mainText={t("tables.confirmClearRoom")}
        onCloseDialog={() => setOpenConfirmClearRoom(false)}
      ></BasicDialog>
    </ThemeProvider>
  );
};

TableSettingsInRoom.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default TableSettingsInRoom;
