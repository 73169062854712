import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  stepNarration,
  stopCurrentNarration,
} from "../../../../../store/actions/narrationActions";
import {
  SCREEN_NARRATION,
  STEP_NARRATION,
} from "../../../../../store/constant";
import OkDialog from "../../NewDialog/OkDialog";

const Narration20_1MasterGChaos = () => {
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const [isShow, setIsShow] = useState(false);
  const { t } = useTranslation();
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const dispatch = useDispatch();
  const playerId = useSelector((state) => state.playerGameState.playerId);
  useEffect(() => {
    setIsShow(
      SCREEN_NARRATION.SG_STEP14_MASTER_G_CHAOS === confirmId &&
        (isGChaosComputer ||
          (currentPlayersId &&
            currentPlayersId[3] &&
            currentPlayersId[3] === playerId))
    );
  }, [confirmId, currentPlayersId, isGChaosComputer, playerId]);

  const handleAcceptIntroMaster = () => {
    setIsShow(false);
    // stop sound narration when player confirmed
    dispatch(stopCurrentNarration());
    //   move to step 6
    // request update grandCross //TODO
    // request to change step14 ->step 20.
    stepNarration({
      playerId: playerId,
      stepId: STEP_NARRATION.SG_STEP14, // 15 16 17 18 19 is the same
      playersId: currentPlayersId,
    });
    //TODO call sever reject
  };

  return (
    <>
      <OkDialog
        isOpen={isShow && !isGChaosComputer}
        btnLabel={t("narrationSetting.OK")}
        onAgree={handleAcceptIntroMaster}
        confirmText={t("narrations.narration20_1")}
      ></OkDialog>
    </>
  );
};
export default React.memo(Narration20_1MasterGChaos);
