import { Box, Dialog, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";
import { stopCurrentNarration } from "../../../../../store/actions/narrationActions";
import { playerConfirm } from "../../../../../store/actions/roomActions";
import {
  G_CHAOS,
  SCREEN_NARRATION,
  STEP_NARRATION,
  TRIGGER_USE_MASTER_CARD,
} from "../../../../../store/constant";
import {
  clearGChaosComputerSanNickName,
  getMessageGChaosSelectAllMC,
} from "../../../../../utils/common";
import { largestCard } from "../../../../../utils/gameProcess";
import { OKButton } from "../../NewDialog/Buttons";
const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: (props) => `calc(${props.left}% - 232px)`,
  },
});
const NarrationUseMasterWinner = () => {
  const classes = useStyles({
    left: 30,
  });
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const roomId = useSelector((state) => state.room.roomId);
  const tableId = useSelector((state) => state.room.table.tableId);
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const nickName = useSelector((state) => state.user.nickName);
  const [isShow, setIsShow] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const masterGrandCross = useSelector(
    (state) => state.playerGameState.masterGrandCross
  );
  const room = useSelector((state) => state.room);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const gameId = useSelector((state) => state.playerGameState.gameId);

  useEffect(() => {
    const isInSteps = [
      STEP_NARRATION.UM_STEP1,
      STEP_NARRATION.UM_STEP2,
      STEP_NARRATION.UM_STEP3,
      STEP_NARRATION.UM_STEP4,
      STEP_NARRATION.UM_STEP5,
      STEP_NARRATION.UM_STEP6,
      STEP_NARRATION.UM_STEP7,
      STEP_NARRATION.UM_STEP8,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP7,
    ].includes(stepId);
    if (!isInSteps) {
      setIsShow(false);
      return;
    }
    const isShowDialog =
      confirmId === SCREEN_NARRATION.UM_SHOW_USE_MASTER_WINNER_CONFIRM;
    if (isShowDialog) {
      setIsShow(true);
      return;
    }
    // setIsShow(false);
  }, [stepId, confirmId]);

  const handleConfirmPlayerGetMasterCard = () => {
    setIsShow(false);
    dispatch(stopCurrentNarration());
    const data = {
      roomId: roomId,
      playerId: playerId,
      playerIds: currentPlayersId,
      type: TRIGGER_USE_MASTER_CARD,
      gameId: gameId,
      tableId: tableId,
      nickName,
    };
    playerConfirm(data);
  };

  const [masterCardMessage, setMasterCardMessage] = useState("");
  useEffect(() => {
    const steps = [
      STEP_NARRATION.UM_STEP1,
      STEP_NARRATION.UM_STEP2,
      STEP_NARRATION.UM_STEP3,
      STEP_NARRATION.UM_STEP4,
      STEP_NARRATION.UM_STEP5,
      STEP_NARRATION.UM_STEP6,
      STEP_NARRATION.UM_STEP7,
      STEP_NARRATION.UM_STEP8,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6,
      STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP7,
    ];
    if (!steps.includes(stepId)) return;
    const playerGetMasterCard = () => {
      let playerGetName = "";
      if (!masterGrandCross || masterGrandCross.length <= 4) {
        return playerGetName;
      }
      const masterCard = [...masterGrandCross].slice(4);
      const index = largestCard(masterCard);
      if (-1 !== index) {
        const playerSelectIndex = masterCard[index].playerIdx;
        playerGetName =
          room.joinedPlayers && room.joinedPlayers[playerSelectIndex]
            ? room.joinedPlayers[playerSelectIndex].nickName
            : playerGetName;
      }
      return playerGetName;
    };
    const name = playerGetMasterCard();
    let message = t("narrations.narrationUseMasterWinner", {
      name: name,
    });

    message = t(getMessageGChaosSelectAllMC(masterGrandCross, message));

    if (isGChaosComputer === true && name === G_CHAOS) {
      setMasterCardMessage(clearGChaosComputerSanNickName(message));
    } else {
      setMasterCardMessage(message);
    }
  }, [isGChaosComputer, t, masterGrandCross, room, stepId]);

  return (
    <Dialog
      hideBackdrop={true}
      open={isShow}
      PaperProps={{
        style: { borderRadius: "35px", backgroundColor: "#f7f7f7" },
      }}
      PaperComponent={PaperComponent}
      classes={{ paper: classes.dialog }}
    >
      <div className="dialog-style-1" id="draggable-dialog-title">
        <div style={{ height: "70%" }}>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            height={145}
          >
            <Box
              style={{
                margin: "0px 30px",
                cursor: "move",
                top: "45px",
                position: "absolute",
              }}
              fontWeight={700}
              dangerouslySetInnerHTML={{
                __html: masterCardMessage,
              }}
              textAlign="center"
            ></Box>
          </Box>
        </div>
        <Box display="flex" justifyContent="center" style={{ height: "25%" }}>
          <Box width={135}>
            <OKButton onClick={() => handleConfirmPlayerGetMasterCard()}>
              <p
                style={{
                  letterSpacing: 15,
                  marginLeft: "15%",
                }}
              >
                {t("narrationSetting.OK")}
              </p>
            </OKButton>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};

export default React.memo(NarrationUseMasterWinner);
