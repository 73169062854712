import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";

export const Container = styled.div`
  padding: 2em;
`;

export const Title = styled.div`
  font-size: 2em;
  font-weight: bold;
  display: flex;
  justify-content: center;
  padding-bottom: 1em;
`;

export const GroupButton = styled.div`
  display: flex;
  justify-content: center;
  gap: 2em;
  "&>*": {
    text-transform: capitalize;
  }
  @media screen and (max-width: 860px) {
    gap: 0.5em;
  }
`;

export const ErrorMessage = styled.div`
  display: flex;
  justify-content: center;
  color: red;
  padding: 1em;
`;

const useStyles = makeStyles(() => ({
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: "50px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    minWidth: "170px",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: "50px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    textAlign: "center",
    minWidth: "170px",
  },
}));

export default useStyles;
