import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SCREEN_NARRATION } from "../../../../../store/constant";
import MessDialog from "../../NewDialog/MessDialog";

const NarrationNotify = () => {
  const confirmId = useSelector((state) => state.narration.narrationScreen);
  const content = useSelector((state) => state.instructorNotice.messContent);
  const code = useSelector((state) => state.instructorNotice.messageCode);
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    setIsShow(
      SCREEN_NARRATION.INSTRUCTOR_NOTIFY === confirmId &&
        code?.messageContent !== "notifyLeaveRoom"
    );
  }, [confirmId, code]);

  return (
    <MessDialog
      isOpen={isShow}
      mainText={content}
      onClose={() => setIsShow(false)}
    />
  );
};
export default React.memo(NarrationNotify);
