import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: "100%",
    backgroundColor: theme.palette.secondary.main,
    border: `3px solid ${theme.palette.secondary.dark}`,
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    paddingTop: 24,
  },
  boxCard: {
    textAlign: "center",
    width: "100%",
    flex: 1,
    display: "flex",
    justifyContent: "space-evenly",
    alignItems: "center",
    margin: 0,
  },
  cardBorder: {
    border: "1px solid black",
    "&:hover": {
      border: "1px solid black",
    },
  },
  nickName: {
    height: "30px",
  },
}));

export default useStyles;
