import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import React, { useRef } from "react";
import Dialog from "@material-ui/core/Dialog";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import { Button, Typography, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PaperComponent from "../../PaperComponent";
import { changePosition } from "../../../../../store/actions/roomActions";
import Tooltip from "@material-ui/core/Tooltip";
import { G_CHAOS } from "../../../../../store/constant";

export const ChangePositionDialog = (props) => {
  const { isOpen, onClose } = props;
  const nickNamesRefs = useRef(null);

  const room = useSelector((state) => state.room);
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const handleOnClose = () => {
    onClose();
  };
  const translateType = i18n.language; //jp | en
  const emptyList = ["〇〇", "ｘｘ", "△△", "☐☐"];
  const convertNickName2Ids = (nickNames) => {
    return nickNames.map((nickName) => {
      nickName = nickName.replace(" さん", "");
      if (nickName === null || emptyList.includes(nickName)) return null;
      const idIndex = room.joinedPlayers.findIndex(
        (ele) => ele && ele.nickName === nickName
      );
      return room.joinedPlayers[idIndex].playerId;
    });
  };
  const handleApply = () => {
    //call api
    changePosition({
      playersOrderId: convertNickName2Ids(getPlayersNickNames()),
      roomId: room.roomId,
      tableId: room.table.tableId,
    });
    onClose();
  };
  const drag = (event) => {
    event.dataTransfer.effectAllowed = "move";
    event.dataTransfer.setData("text", event.target.id);
  };
  const drop = (event) => {
    event.preventDefault();
    let data = event.dataTransfer.getData("text");
    let nodeList = nickNamesRefs.current.childNodes;
    let dragIndex = 0;
    nodeList.forEach(
      (ele, index) => (dragIndex = ele.id === data ? index : dragIndex)
    );
    if (data !== "undefined") {
      nickNamesRefs.current.replaceChild(
        document.getElementById(data),
        event.target
      );
      nickNamesRefs.current.insertBefore(
        event.target,
        nickNamesRefs.current.childNodes[dragIndex]
      );
    }
  };
  const dragOver = (e) => {
    if (e.preventDefault) {
      e.preventDefault();
    }
    e.dataTransfer.dropEffect = "move";
    return false;
  };
  //nickname with addition language
  const getPlayersNickNames = () => {
    var playersNickName = [];
    let nodeList = nickNamesRefs.current.childNodes;
    nodeList.forEach((ele) => playersNickName.push(ele.innerText));
    playersNickName.push(playersNickName.shift());
    return playersNickName;
  };
  const shiftPosition = () => {
    var playersNickName = getPlayersNickNames();
    if (room.table.isGChaoComputer) {
      const resArray = playersNickName.slice(0, 3);
      resArray.unshift(resArray.pop());
      resArray.push(playersNickName[3]);
      playersNickName = resArray;
    } else playersNickName.unshift(playersNickName.pop());
    nickNamesRefs.current.childNodes.forEach(
      (ele, index) => (ele.innerText = playersNickName[(index + 3) % 4])
    );
  };

  return (
    <Dialog
      open={isOpen}
      onClose={handleOnClose}
      PaperProps={{
        style: {
          borderRadius: "20px 20px 35px 35px",
          backgroundColor: "#f7f7f7",
        },
      }}
      PaperComponent={PaperComponent}
    >
      <div className="dialog-style-1">
        <div
          style={{ height: "35px", backgroundColor: "#63baea", cursor: "move" }}
          id="draggable-dialog-title"
        >
          <IconButton className={classes.iconBtn} onClick={() => onClose()}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </div>
        <div className={classes.mainDialog}>
          <div className={`${classes.defaultSize} ${classes.playersRole}`}>
            <Typography noWrap>G-Chaos </Typography>
            <Typography noWrap>{`G's Guest1`} </Typography>
            <Typography noWrap>{`G's Guest2`} </Typography>
            <Typography noWrap>{`G's Guest3`} </Typography>
          </div>
          <div
            className={`${classes.defaultSize} ${classes.playersNickName}`}
            id="nickNames"
            ref={nickNamesRefs}
          >
            {room &&
              room.joinedPlayers &&
              room.joinedPlayers.map((player, index) => {
                const newPlayer = room.joinedPlayers[(index + 3) % 4];
                const isDragGChaos = !(
                  newPlayer &&
                  room.table.isGChaoComputer &&
                  newPlayer.nickName === G_CHAOS
                );
                return newPlayer === null ? (
                  <div
                    className={`${classes.nickName}`}
                    draggable="true"
                    onDragStart={drag}
                    onDrop={drop}
                    onDragOver={dragOver}
                    key={index + ":position"}
                    id={index + ":position"}
                    style={{
                      cursor: isDragGChaos ? "grab" : "not-allowed",
                      height: "35px",
                    }}
                  >
                    {`${emptyList[index]} ${
                      translateType == "jp" || translateType == "ja"
                        ? " さん"
                        : ""
                    }`}
                  </div>
                ) : (
                  <Tooltip
                    title={newPlayer.nickName}
                    placement="top-start"
                    arrow
                    key={newPlayer.playerId + ":position"}
                  >
                    <div
                      className={`${classes.nickName}`}
                      id={newPlayer.playerId + ":position"}
                      draggable={isDragGChaos}
                      onDragStart={drag}
                      onDrop={(e) => {
                        isDragGChaos && drop(e);
                      }}
                      onDragOver={dragOver}
                      style={{
                        cursor: isDragGChaos ? "grab" : "not-allowed",
                        height: "35px",
                      }}
                    >
                      {`${newPlayer.nickName} ${
                        translateType == "jp" || translateType == "ja"
                          ? " さん"
                          : ""
                      }`}
                    </div>
                  </Tooltip>
                );
              })}
          </div>

          <div className={`${classes.defaultSize} ${classes.buttons}`}>
            <Button className="button-style-2" onClick={shiftPosition}>
              {t("game.buttonShift")}
            </Button>
            <Button className="button-style-2" onClick={handleApply}>
              {t("game.buttonApply")}
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
ChangePositionDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
};
export default ChangePositionDialog;
//450 x 205
