/* eslint-disable no-unused-vars */
import React from "react";
import { FaUserTie, FaFileInvoiceDollar } from "react-icons/fa";
import { RiAdminFill } from "react-icons/ri";
import { Link, NavLink } from "react-router-dom";
import logo from "../../../../assets/icons/moku.png";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";

const SiderBar = () => {
  const classes = useStyles({});
  const { t } = useTranslation();

  const links = [
    {
      title: t("admin.adminList"),
      icon: <RiAdminFill />,
      path: "/admin/admin-list",
    },
    {
      title: t("admin.userList"),
      icon: <FaUserTie />,
      path: "/admin/user-list",
    },
    {
      title: t("admin.invoice"),
      icon: <FaFileInvoiceDollar />,
      path: "/admin/invoices",
    },
  ];

  return (
    <div>
      <Link className={classes.imgBox} to="/admin/admin-list">
        <img className={classes.logoImg} alt="close" src={logo} />
        <p className={classes.logoTitle}>G-Concept</p>
      </Link>
      <div>
        {links.map((item) => (
          <NavLink
            to={`${item.path}`}
            className={(isActive) =>
              isActive ? classes.linkActive : classes.link
            }
            key={item.title}
          >
            {item.icon}
            <span className={classes.title}>{item.title}</span>
          </NavLink>
        ))}
      </div>
    </div>
  );
};

export default SiderBar;
