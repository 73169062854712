import { STEP_NARRATION } from "../constant";
import {
  ADD_INSTRUCTOR_CONTENT,
  ADD_STEP_NARRATION,
  DONE_STEP_NARRATION,
  UPDATE_STEP_NARRATION,
  ADD_LEAVE_ROOM_NOTIFY_STEP,
} from "../types";

const addStepNarrations = (steps) => (dispatch) =>
  dispatch({ type: ADD_STEP_NARRATION, payload: { steps: steps } });

const updateStepNarrations = (steps, message) => (dispatch) =>
  dispatch({
    type: UPDATE_STEP_NARRATION,
    payload: {
      steps: steps,
      message: message,
    },
  });

const doneStepNarrations = () => (dispatch) =>
  dispatch({
    type: DONE_STEP_NARRATION,
  });

const pushNotification = (message) => (dispatch) => {
  dispatch({ type: ADD_INSTRUCTOR_CONTENT, payload: { messContent: message } });
};

const pushLeaveRoomWhilePlayingStep = (message) => (dispatch) => {
  dispatch({
    type: ADD_LEAVE_ROOM_NOTIFY_STEP,
    payload: {
      steps: [STEP_NARRATION.LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_STEP],
      message,
    },
  });
};

export {
  addStepNarrations,
  updateStepNarrations,
  doneStepNarrations,
  pushNotification,
  pushLeaveRoomWhilePlayingStep,
};
