import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  clockBox: {
    textAlign: "center",
    border: "10px solid #336bab",
    padding: "5px 10px",
    borderRadius: "15px",
    color: "#442211",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",

    "@media (max-width: 1450px)": {
      border: "8px solid #336bab",
    },

    "@media (max-width: 1200px)": {
      border: "6px solid #336bab",
    },
  },
  clockInfo: {
    marginBottom: "5px",
    "& p": {
      fontSize: "10px",
      fontWeight: "500",
    },

    "@media (max-width: 1450px)": {
      "& p": {
        fontSize: "8px",
      },
    },

    "@media (max-width: 1200px)": {
      marginBottom: "2px",
      "& p": {
        fontSize: "6px",
      },
    },
  },
  timeRemainingBox: {
    marginBottom: "5px",

    "@media (max-width: 1450px)": {
      marginBottom: "2px",
    },
  },

  playTimeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    fontWeight: "bold",
    fontSize: "16px",
    flexDirection: (props) => `${props.isFree ? "column" : "row"}`,

    "@media (max-width: 1450px)": {
      fontSize: "10px",
    },

    "@media (max-width: 1200px)": {
      fontSize: "8px",
    },
  },
  timeRemainingTitle: {
    fontWeight: "bold",
    "@media (max-width: 1450px)": {
      fontSize: "12px",
    },
  },
  timeRemaining: {
    fontWeight: "bold",
    fontSize: "24px",
    letterSpacing: "4px",
    "& span": {
      fontSize: "14px",
    },

    "@media (max-width: 1450px)": {
      fontSize: "20px",
      letterSpacing: "2px",
      "& span": {
        fontSize: "10px",
      },
    },

    "@media (max-width: 1200px)": {
      fontSize: "18px",
      letterSpacing: "2px",
      "& span": {
        fontSize: "8px",
      },
    },
  },
  btn: {
    marginTop: "5px",
    display: "flex",

    "& button": {
      width: "100%",
      backgroundColor: "#442211",
      color: "white",
      fontSize: "11px",
      padding: "5px",
      borderRadius: "12px",
      border: "none",
      cursor: "pointer",

      "@media (max-width: 1450px)": {
        fontSize: "8px",
        padding: "2px 4px",
      },

      "@media (max-width: 1200px)": {
        fontSize: "5px",
      },

      "&:hover": {
        backgroundColor: "#336bab",
      },
    },
  },
}));
export default useStyles;
