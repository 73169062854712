import React from "react";
import { Button as MuiButton } from "@material-ui/core";
import PropTypes from "prop-types";
import styled from "styled-components";

const ButtonStyled = styled(MuiButton)`
  &:disabled {
    background-color: #7d7d7d !important;
  }
`;

const Button = ({
  type,
  text,
  width,
  onClick,
  variant,
  background,
  className,
  disabled,
}) => {
  return (
    <ButtonStyled
      style={{
        borderRadius: "999px",
        width: width,
        margin: "10px",
        marginTop: "15px",
        color: "white",
        borderColor: "black",
        backgroundColor: background,
      }}
      type={type}
      variant={variant}
      onClick={onClick}
      className={className}
      disabled={disabled}
    >
      {text}
    </ButtonStyled>
  );
};

Button.propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  width: PropTypes.string,
  onClick: PropTypes.func,
  variant: PropTypes.string,
  background: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

export default Button;
