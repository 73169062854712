import { Box, Dialog, makeStyles } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";
import { stepNarration } from "../../../../../store/actions/narrationActions";
import { changeStepId } from "../../../../../store/actions/playerGameStateActions";
import {
  SCREEN_NARRATION,
  STEP_NARRATION,
} from "../../../../../store/constant";
import FlippedCard from "../../FlippedCard";
import { CancelButton, OKButton } from "../../NewDialog/Buttons";
import {
  CardSelectedWrapper,
  CardWrapper,
  Container,
  Nickname,
  TitleWrapper,
} from "./Narration34G0G1Styles";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    left: "calc(50% - 232px)",
    top: (props) => `calc(${props.top}% - 150px)`,
  },
});
const Narration34GCard = () => {
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const table = useSelector((state) => state.room.table);
  const isPlaying = useSelector((state) => state.room.table.isPlaying);
  const [isShowGCard, setIsShowGCard] = useState(false);
  const [isShowInstruct, setIsShowInstruct] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const classes = useStyles({
    top: isShowInstruct ? 18 : 50,
  });
  useEffect(() => {
    if (!isPlaying) {
      setIsShowGCard(false);
      setIsShowInstruct(false);
      return;
    }
    setIsShowGCard(SCREEN_NARRATION.SG_STEP2_G_CARD === confirmId);
    setIsShowInstruct(SCREEN_NARRATION.SG_STEP2_G_CARD_INSTRUCT === confirmId);
  }, [confirmId, isPlaying]);

  const handleAcceptInstructor = () => {
    setIsShowGCard(false);
    setIsShowInstruct(false);
    dispatch(changeStepId(STEP_NARRATION.SG_STEP3));
    stepNarration({
      stepId: STEP_NARRATION.SG_STEP2_3,
      playerId: playerId,
    });
  };

  const handleRejectInstructor = () => {
    setIsShowGCard(false);
    setIsShowInstruct(false);

    // change step local
    dispatch(changeStepId(STEP_NARRATION.SG_STEP4));
    stepNarration({
      stepId: STEP_NARRATION.SG_STEP2_4,
      playerId: playerId,
    });
  };
  if (table === "" || !table?.isPlaying) return null;
  return (
    <>
      <Dialog
        open={isShowGCard || isShowInstruct}
        PaperProps={{
          style: { borderRadius: "35px", backgroundColor: "#f7f7f7" },
        }}
        PaperComponent={PaperComponent}
        classes={{ paper: classes.dialog }}
      >
        <Box id="draggable-dialog-title" width={400} height={300}>
          <Box height="100%">
            <Container>
              <TitleWrapper>
                <Box
                  marginTop={"10px"}
                  marginLeft={"30px"}
                  marginRight={"30px"}
                >
                  {t("narrations.narration3")}
                </Box>
              </TitleWrapper>
              <CardWrapper>
                <Box display="flex">
                  <CardSelectedWrapper>
                    <Nickname hasNickname={true}>
                      <Box component="p" fontWeight={700}>
                        {"G0"}
                      </Box>
                    </Nickname>
                    <FlippedCard
                      flipped={true}
                      src={`/cards/G0.jpg`}
                      onClickSelectCard={() => {}}
                    />
                  </CardSelectedWrapper>
                  <CardSelectedWrapper>
                    <Nickname hasNickname={true}>
                      <Box component="p" fontWeight={700}>
                        {"G1"}
                      </Box>
                    </Nickname>
                    <FlippedCard
                      flipped={true}
                      src={`/cards/G1.jpg`}
                      onClickSelectCard={() => {}}
                    />
                  </CardSelectedWrapper>
                </Box>
              </CardWrapper>
            </Container>
          </Box>
        </Box>
      </Dialog>
      <Dialog
        hideBackdrop={true}
        open={isShowInstruct}
        PaperProps={{
          style: { borderRadius: "35px", backgroundColor: "#f7f7f7" },
        }}
        PaperComponent={PaperComponent}
      >
        <div className="dialog-style-1" id="draggable-dialog-title">
          <div style={{ height: "70%" }}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              height={145}
            >
              <Box
                style={{
                  margin: "0px 30px",
                  cursor: "move",
                  position: "absolute",
                }}
                fontWeight={700}
                dangerouslySetInnerHTML={{ __html: t("narrations.narration4") }}
                textAlign="center"
              ></Box>
            </Box>
          </div>
          <Box display="flex" justifyContent="center" style={{ height: "25%" }}>
            <Box width={135}>
              <CancelButton onClick={handleRejectInstructor}>
                {t("narrationSetting.skip")}
              </CancelButton>
            </Box>
            <Box width={15}></Box>
            <Box width={135}>
              <OKButton onClick={handleAcceptInstructor}>
                <p>{t("narrationSetting.playInstruction")}</p>
              </OKButton>
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
};
export default React.memo(Narration34GCard);
