import PropTypes from "prop-types";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { GChaosWelcome, GuestWelcome, Master } from "./styles";

export const StarCard = ({
  children,
  className,
  profile,
  master,
  height,
  width,
}) => {
  const welcomeBandWidth = useSelector((state) => state.size.welcomeBandWidth);
  const masterWidth = useSelector((state) => state.size.masterWidth);
  const isShowMaster = useSelector(
    (state) => state.playerGameState.isShowMaster
  );

  const getMaster = useMemo(() => {
    if (master === profile.playerId && isShowMaster) {
      return <Master height={masterWidth} />;
    }
    if (profile.index === 3 && !isShowMaster) {
      return <Master height={masterWidth} />;
    }
    return <div style={{ width: "100%", height: masterWidth }} />;
  }, [master, profile.playerId, profile.index, isShowMaster, masterWidth]);

  return (
    <div
      style={{
        height: height,
        boxSizing: "border-box",
        backgroundColor: "#dea417",
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        width: width,
      }}
      className={className}
    >
      {getMaster}
      {profile?.index === 3 && (
        <GChaosWelcome height={welcomeBandWidth}>
          <img
            src="/assets/h_g_chaos_welcome.png"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
            }}
          />
        </GChaosWelcome>
      )}
      {profile?.index !== 3 && (
        <GuestWelcome height={welcomeBandWidth}>
          <img
            src="/assets/h_guest_welcome.png"
            style={{
              width: "100%",
              height: "100%",
              objectFit: "fill",
              display: "block",
            }}
          />
        </GuestWelcome>
      )}
      {children}
    </div>
  );
};

StarCard.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
  position: PropTypes.string,
  profile: PropTypes.object,
  master: PropTypes.string,
  height: PropTypes.number,
  width: PropTypes.any,
  size: PropTypes.any,
};
