import { Container } from "@material-ui/core";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Header from "../../components/Header";
import { HomeScreen } from "../HomeScreen/HomeScreen";
import { useTranslation } from "react-i18next";
import useDocumentTitle from "../../hooks/useDocumentTitle";
function Home() {
  const user = useSelector((state) => state.user);
  const history = useHistory();
  const { t } = useTranslation();
  useEffect(() => {
    if (user && user.playerId !== "") {
      history.push("/tables");
    }
  }, [history, user]);
  useDocumentTitle(t("common.gConceptTitle") + t("common.home"));
  return (
    <Container maxWidth="xl" style={{ margin: 0, padding: 0 }}>
      <Header />
      <HomeScreen />
    </Container>
  );
}

export default Home;
