/* eslint-disable no-irregular-whitespace */
import React from "react";
import { Box, Container } from "@material-ui/core";
import useStyles from "./styles";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";

const Termofuse = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  useDocumentTitle(t("common.gConceptTitle") + t("common.termOfUseTitle"));

  return (
    <Container className={classes.root}>
      <Box className={classes.wrapper}>
        <p style={{ textAlign: "center", fontWeight: "bold", fontSize: 24 }}>
          <span>黙示伝授オンライン サービス利用規約</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第1章 総則</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第1条（目的）</span>
        </p>
        <p>
          <span>
            1. 黙示伝授オンライン
            サービス利用規約（以下「本規約」といいます）は、GIFT
            R&D.M合同会社（以下「当社」といいます）が、当社所定の方法にて会員登録した者（以下「会員」といいます）に対して提供する第2項に定めるサービス（以下「本サービス」といいます）の利用に関する諸事項を定めるものです。
          </span>
        </p>
        <p>
          <span>
            2.
            本規約における本サービスとは、次の各号に定めるものとします。なお、会員のうちフィーチャーフォン、スマートフォン、タブレット等のネットワーク携帯端末（以下「ネットワーク携帯端末」といいます）専用の会員、第3条第1項に定める登録情報のうち当社が指定する情報の一部を入力していない会員、ならびに、当社以外の第三者のサービス（以下「外部サービス」といいます）において利用可能なID（当該第三者がその会員を識別するために発行する文字列、以下「外部サービスID」といいます）を利用する会員が利用可能なサービスは、本サービスの一部に限られるものとします。
          </span>
        </p>
        <p>
          <span>
            (1)　当社が、当社の管理するウェブサイトまたは当社が提携するポータルサイトにおいて提供するサービスの全て
          </span>
        </p>
        <p>
          <span>
            (2)　当社が提供するネットワーク携帯端末用のアプリケーションに関するサービスの全て（以下「本アプリケーションサービス」といいます）
          </span>
        </p>
        <p>
          <span>
            (3)　当社が、当社の提供するネットワーク携帯端末用コミュニティサイトにおいて提供するサービスの全て
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第2条（本規約の変更）</span>
        </p>
        <p>
          <span>
            1.　当社は、会員の了承を得ることなく、本規約を変更することができるものとします。この場合、会員は、本規約の変更後に本サービスを利用することにより、変更後の本規約に同意したものとみなします。
          </span>
        </p>
        <p>
          <span>
            2.　本規約の変更は、変更後の本規約が本サービスのサイトまたは本サ―ビス内に表示され、会員が閲覧可能となった時点より効力が生じるものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第2章　会員</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第3条（会員登録）</span>
        </p>
        <p>
          <span>
            1.　本サービスを利用しようとする者は、本規約に同意したうえ、当社所定の認証手続を行い、当社が指定する情報（以下「登録情報」といいます）の入力を経て、会員の登録をするものとします。なお、登録情報には、会員登録の際に登録する情報のほか、本サービスの利用に際して当社が登録を求める情報、ならびに、本アプリケーションサービスにおいては会員の携帯端末情報が含まれるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　外部サービスIDを利用する会員は、本規約以外に、外部サービスの運営者が別途定める利用規約等に従うものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第4条（登録の不承認および留保）</span>
        </p>
        <p>
          <span>
            1.　当社は、会員登録に次の事由があると判断した場合は、会員登録を承認しないことができるものとします。また、当社は、会員登録後に次の事由があると判断した場合には、会員登録を削除し、または本サービスの全部または一部の提供を停止することができるものとします。なお、これらの場合、当社は、その理由を説明または開示しない場合があります。
          </span>
        </p>
        <p>
          <span>(1)　偽名および他人名義による登録である場合</span>
        </p>
        <p>
          <span>(2)　登録情報に虚偽、不足または事実との相違がある場合</span>
        </p>
        <p>
          <span>(3)　第3条に定める会員登録の要件を満たしていない場合</span>
        </p>
        <p>
          <span>
            (4)　過去に本規約に違反した等、当社に損害または不利益を与えた者の登録である場合
          </span>
        </p>
        <p>
          <span>
            (5)　他人（当社の役職員または関係者を含む）になりすますことを目的とする登録である場合
          </span>
        </p>
        <p>
          <span>
            (6)　登録情報の文字列が他者に嫌悪感を与えるもの、または公序良俗に反するものである場合
          </span>
        </p>
        <p>
          <span>
            (7)　日本国外のドメインの電子メールアドレスを用いて第3条第1項に定める手続を行った場合
          </span>
        </p>
        <p>
          <span>
            (8)　第18条第1項に定める法定代理人の同意のない未成年者による登録である場合
          </span>
        </p>
        <p>
          <span>
            (9)　外部サービスIDの利用が外部サービスの運営者から停止または削除され、もしくは外部サービスIDの有効性を当社で確認できない場合
          </span>
        </p>
        <p>
          <span>(10)　その他登録することが不適当な場合</span>
        </p>
        <p>
          <span>
            2.　当社は、業務上の支障により会員登録を承認することが困難な場合には、会員登録の承認を一時的に留保することができるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　第1項および第2項により発生した会員の不利益および損害について、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第5条（サポート）</span>
        </p>
        <p>
          <span>
            1.　当社による本サービスおよび当社が提供するクライアントソフトウェアまたはアプリケーションソフトウェア（クライアントソフトウェアとアプリケーションソフトウェアを総称して、以下「本ソフトウェア」といいます）に関するサポートを利用しようとする者は、第3条に定める会員登録の要件を満たすものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、本サービスおよび本ソフトウェアに関するサポートを、日本語に限り提供するものとします。
          </span>
        </p>
        <p>
          <span>
            3.　当社は、本サービスおよび本ソフトウェアに関する会員からの問合せに対して、回答する義務を負わないものとします。
          </span>
        </p>
        <p>
          <span>
            4.　会員が当社に対し、本サービスおよび本ソフトウェアに関する問合せを行う場合は、当社所定の方法によるものとします。また、当該問合せは、問合せ内容に応じて当社が設定した期間（但し、当社受付日より1年間を超えないものとします）に限り有効とします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第6条（登録情報の変更）</span>
        </p>
        <p>
          <span>
            1.　登録情報に変更が生じた場合、会員は速やかに当社所定の方法にて当社に届け出るものとします。
          </span>
        </p>
        <p>
          <span>
            2.　第1項の届出をしなかったことより発生した会員の不利益および損害について、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第7条（会員ID）</span>
        </p>
        <p>
          <span>
            1.　当社は、会員登録の際に、会員から有効な携帯電話番号の提供を受け、その番号を会員ID（当社がその会員を識別するために発行する文字列をいい、外部サービスIDと総称して、以下「会員ID」といいます）として取り扱います。なお、本規約における外部サービスIDには、外部サービスIDを利用する会員を識別するために当社が発行する文字列も含まれ、当該外部サービスIDと文字列は1つの会員IDとして取り扱われるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　会員は、会員IDの使用および管理について一切の責任を持つものとします。
          </span>
        </p>
        <p>
          <span>
            3.　会員は、会員IDを第三者に利用させ、もしくは第三者の会員IDを利用してはならないものとします。
          </span>
        </p>
        <p>
          <span>
            4.　会員の責めに帰すべき事由の有無を問わず、会員IDを使用して第三者が本サービスを利用した場合においても、当該利用について当社の故意または重過失が認められる場合を除いて、当該本サービスの利用は会員によってなされたものとみなし、会員は一切の債務を負担するものとします。
          </span>
        </p>
        <p>
          <span>
            5.　会員の責めに帰すべき事由の有無を問わず、当該会員以外の第三者が当該会員の会員IDを使用したことにより、当該会員に不利益または損害を与えた場合についても、当該使用について当社の故意または重過失が認められる場合を除いて、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第8条（会員の地位の譲渡）</span>
        </p>
        <p>
          <span>
            1.　会員は、会員たる地位を第三者に譲渡することはできないものとします。
          </span>
        </p>
        <p>
          <span>
            2.　第1項の場合には、第7条第4項および第5項の規定を準用するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第9条（会員の解約）</span>
        </p>
        <p>
          <span>
            1.　会員は、当社所定の方法にて当社に届け出ることにより、会員登録を削除することができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、最終ログインから2年間以上経過している会員ID、ならびに有効に存在しないと当社が判断した外部サービスIDについては、会員登録を削除することができるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　前各項の場合において、当社は、会員から受領した金銭の払戻しは、一切行わないものとします。
          </span>
        </p>
        <p>
          <span>
            4.　会員は、会員登録を削除した会員IDについて、再登録することはできないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>第3章　本サービス</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第10条（本サービスの変更）</span>
        </p>
        <p>
          <span>
            1.　当社は、会員に事前に通知することなく、本サービスの内容の追加、廃止、その他変更をすることができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、会員に事前に通知することなく、本サービスのデータの全部または一部を削除することができるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　前各項により発生した会員の不利益および損害について、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第11条（個別規約）</span>
        </p>
        <p>
          <span>
            1.　当社は、個別の本サービスごとに規約（規約、約款、条件、ガイドライン等の名称の如何を問いません。以下「個別規約」といいます）を定めることができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　個別規約は本規約の一部を構成するものとし、両者が矛盾する場合には、個別規約が優先するものとします。
          </span>
        </p>
        <p>
          <span>
            3.　会員が、個別の本サービスを利用した場合には、当該本サービスに係る個別規約に同意したものとみなされます。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第12条（利用登録）</span>
        </p>
        <p>
          <span>
            1.　当社は、会員が本サービスを利用する際に、利用登録を要求する場合があります。
          </span>
        </p>
        <p>
          <span>2.　第1項の場合には、第2章の規定を準用するものとします。</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第13条（投稿コンテンツ)</span>
        </p>
        <p>
          <span>
            1.　会員は、当該会員が本サービスにおいて投稿・送信・開示等を行う文章・画像・映像・プログラム・データ等（以下「投稿コンテンツ」といいます）について次の各号に定める事項を保証するものとします。
          </span>
        </p>
        <p>
          <span>
            (1)　投稿コンテンツが、第三者の著作権その他の権利を侵害しておらず、会員本人が単独で完全な権利を有すること
          </span>
        </p>
        <p>
          <span>
            (2)　投稿コンテンツが第三者の著作権その他の権利を利用している場合は、当該第三者が会員に対して、自己の著作権その他の権利を本サービスにおいて会員および当社が第2項各号に規定する方法で利用し、当該第三者が著作者人格権を行使しないことについて事前に許諾をしていること
          </span>
        </p>
        <p>
          <span>
            2.　当社は、投稿コンテンツについて、事前の通知を要せず、無償で、次の各号に定める事項を行うことができるものとします。
          </span>
        </p>
        <p>
          <span>(1)　投稿コンテンツの編集および改変</span>
        </p>
        <p>
          <span>
            (2)　投稿コンテンツを、インターネットにおいて公衆に送信し、またはその他の方法で利用すること
          </span>
        </p>
        <p>
          <span>(3)　投稿コンテンツを調査しまたは分析すること</span>
        </p>
        <p>
          <span>(4)　上記各号に定める権利を第三者に許諾すること</span>
        </p>
        <p>
          <span>
            3.　投稿コンテンツに関連して、会員または当社が第三者より権利侵害の申告を受けた場合、会員自らが責任を負うものとし、会員自らの費用でこれを解決するものとします。
          </span>
        </p>
        <p>
          <span>
            4.　本サービスの運営に必要な場合、当社は公開の前後を問わず投稿コンテンツについて、閲覧し、および第21条に定める措置を講ずることができるものとします。
          </span>
        </p>
        <p>
          <span>
            5.　会員は、当社に対して、投稿コンテンツに関する著作者人格権を一切行使しないものとします。
          </span>
        </p>
        <p>
          <span>
            6.　会員が自己の投稿コンテンツにより発生した不利益および損害について、当社に故意または過失がある場合を除いて、当社は何等の責任を負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第14条（第三者サイトおよびリンク）</span>
        </p>
        <p>
          <span>
            1.　本サービスには、当社の管理するウェブサイト以外のウェブサイト（以下「第三者サイト」といいます）へのリンク（以下「本リンク」といいます）が掲載される場合があります。その場合、当社は、第三者サイトおよび本リンクに掲載される情報について完全性、正確性、有用性を有するものであることの保証を行わないものとします。
          </span>
        </p>
        <p>
          <span>
            2.　第三者サイトおよび本リンクに掲載される情報の利用に際しては、会員が自己の責任において当該情報の内容および第三者サイトの利用規約について確認するものとし、第三者サイトの利用に際しては、セキュリティ上必要な措置を会員自らの責任と判断で講じるものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第15条（SNS連携およびゲームフォーラムの利用）</span>
        </p>
        <p>
          <span>
            1.　会員が、本サービスと特定のソーシャルネットワーキングサービス（以下「SNS」といいます）との連携機能（SNS連携）を利用し、もしくは本サービス向けのゲームフォーラムを利用する場合（SNSとゲームフォーラムを総称して、以下「SNS等」といいます）、当社は、SNS等において当社以外の第三者によって投稿されたメッセージ、説明、意見、助言その他の発言（以下「発言等」といいます）について完全性、正確性、有用性を有するものであることの保証を行わないものとします。また、会員が当該発言等に依拠したことにより発生した会員の不利益および損害について、当社は何等の責任を負わないものとします。
          </span>
        </p>
        <p>
          <span>
            2.　SNS等に掲載される発言等の利用に際しては、会員が自己の責任において発言等の内容およびSNS等の利用規約について確認するものとし、SNS等の利用に際しては、セキュリティ上必要な措置を会員自らの責任と判断で講じるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　会員がSNS等を利用して自らの情報を公開する場合は、当該情報が公開可能なものであるかを会員自ら判断し、かつ、第三者によって当該情報が収集され、利用される可能性があることを十分に理解したうえで、会員自らの責任において実施するものとします。
          </span>
        </p>
        <p>
          <span>
            4.　当社は、公開・非公開を問わず会員の発言等について、本サービスの提供のために法令上許容される範囲内で閲覧し、記録保存することができるものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>第4章　有料サービス</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第16条（利用料）</span>
        </p>
        <p>
          <span>
            1.　会員は、有料の本サービス（以下「本有料サービス」といいます）を利用することができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　会員は、本有料サービスを利用する場合には、当社所定の利用料金（以下「本利用料金」といいます）を支払うものとします。
          </span>
        </p>
        <p>
          <span>
            3.　本利用料金は、本サービスのサイトまたは本サービス内に掲載するものとします。
          </span>
        </p>
        <p>
          <span>
            4.　当社は、会員の了承を得ることなく、本利用料金を変更することができるものとします。
          </span>
        </p>
        <p>
          <span>
            5.　本利用料金の変更は、変更後の本利用料金が本サービスのサイトまたは本サービス内に表示された時点より効力が生じるものとします。
          </span>
        </p>
        <p>
          <span>
            6.　本サービスの利用にともない発生する諸費用（通信料または超過ブロードバンド費用等を想定しますが、これに限りません）については、会員にて負担するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第17条（決済）</span>
        </p>
        <p>
          <span>
            1.　会員は、当社所定の決済方法にて本利用料金を支払うものとします。
          </span>
        </p>
        <p>
          <span>
            2.　会員は、前項の決済において本人以外の名義の決済情報を用いてはならないものとします。但し、会員が未成年者の場合は、法定代理人の同意を得た上、法定代理人の名義を用いることができるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　当社は、理由の如何を問わず、決済が完了し、または受領した本利用料金の払戻しをしないものとします。
          </span>
        </p>
        <p>
          <span>
            4.　会員が決済方法を第三者に利用されたことにより発生した会員の不利益および損害について、当該利用について当社に故意または重過失が認められる場合を除いて、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第18条（未成年者）</span>
        </p>
        <p>
          <span>
            1.　会員が未成年者の場合は、会員登録および本サービスの利用について法定代理人の同意を得るものとします。
          </span>
        </p>
        <p>
          <span>
            2.　会員が未成年者の場合は、本利用料金の支払に際し、法定代理人の同意を得るものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>第5章　運営</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第19条（禁止事項）</span>
        </p>
        <p>
          <span>会員は、次の行為をしてはならないものとします。</span>
        </p>
        <p>
          <span>(1)　第三者になりすまして本サービスを利用する行為</span>
        </p>
        <p>
          <span>(2)　当社の従業員、またはサポート要員になりすます行為</span>
        </p>
        <p>
          <span>
            (3)　当社または第三者の著作権、商標権、その他一切の知的財産権を侵害する行為、もしくはウォーターマーク等の権利保護技術（以下「ウォーターマーク等」という）を削除、改変または回避する行為
          </span>
        </p>
        <p>
          <span>(4)　第三者の財産またはプライバシーを侵害する行為</span>
        </p>
        <p>
          <span>(5)　第三者の個人情報を開示する行為</span>
        </p>
        <p>
          <span>
            (6)　本人の同意を得ることなくまたは詐欺的な手段により、第三者の個人情報を収集する行為
          </span>
        </p>
        <p>
          <span>
            (7)　第三者の決済情報を本人の同意なくして取得または利用する行為
          </span>
        </p>
        <p>
          <span>
            (8)　第三者を脅迫、差別もしくは誹謗中傷し、または第三者の名誉もしくは信用を毀損する行為
          </span>
        </p>
        <p>
          <span>
            (9)　当社の財産を侵害する行為、当社の信用を毀損する行為、その他当社に不利益を与える行為
          </span>
        </p>
        <p>
          <span>
            (10)　わいせつな画像、文書等を送信もしくは表示する行為、これらを収録した媒体を販売する行為、またはそれらの広告を送信もしくは表示する行為
          </span>
        </p>
        <p>
          <span>(11)　ストーカー行為等の規制等に関する法律に違反する行為</span>
        </p>
        <p>
          <span>
            (12)　無限連鎖講（ネズミ講）を開設し、またはこれを勧誘する行為
          </span>
        </p>
        <p>
          <span>(13)　公職選挙法に違反する行為</span>
        </p>
        <p>
          <span>(14)　犯罪に結びつく行為</span>
        </p>
        <p>
          <span>(15)　公序良俗に違反する行為</span>
        </p>
        <p>
          <span>
            (16)　営業活動、または営利を目的とした本サービスの利用行為
          </span>
        </p>
        <p>
          <span>
            (17)　当社が別途許諾する場合を除き、本サービスの利用に伴い発生した権利または権限の全部または一部を販売または購入し、もしくはこれらを募集する行為
          </span>
        </p>
        <p>
          <span>
            (18)　本規約に違反する会員であることを知り得たにもかかわらず、当該会員から本サービスの利用に伴い発生した権利または権限の全部または一部を購入または受領する行為。
          </span>
        </p>
        <p>
          <span>
            (19)　第三者に対し、無断で広告等のメールを送信する行為、もしくは嫌悪感を抱くメールを送信する行為
          </span>
        </p>
        <p>
          <span>(20)　第三者のメール受信を妨害する行為</span>
        </p>
        <p>
          <span>
            (21)　連鎖的なメール転送を依頼する行為、もしくは当該依頼に応じて転送する行為
          </span>
        </p>
        <p>
          <span>(22)　宗教活動、または宗教団体への勧誘行為</span>
        </p>
        <p>
          <span>(23)　第三者による本サービスの利用を妨害する行為</span>
        </p>
        <p>
          <span>
            (24)　当社の使用する本サービス用設備に無権限でアクセスし、またはその利用もしくは運営に支障を与える行為
          </span>
        </p>
        <p>
          <span>
            (25)　本サービスの運営を妨害する行為、もしくは本サービスの運営に関連して当社が不適当であると合理的に判断する行為
          </span>
        </p>
        <p>
          <span>
            (26)　当社の承認なくして、本ソフトウェアを再頒布、貸与、または販売する行為
          </span>
        </p>
        <p>
          <span>
            (27)　本サービスの利用に必要な本数を超えて、本ソフトウェアを複製する行為
          </span>
        </p>
        <p>
          <span>
            (28)　本ソフトウェアの逆コンパイル、逆アセンブル、リバースエンジニアリング等、本ソフトウェアのソースコードを解析する行為
          </span>
        </p>
        <p>
          <span>(29)　本ソフトウェアの改変、修正、または翻案行為</span>
        </p>
        <p>
          <span>
            (30)　本サービスもしくは本ソフトウェアの不具合、障害、または脆弱性を利用する行為
          </span>
        </p>
        <p>
          <span>
            (31)　本ソフトウェアおよび本サービスに関するサーバエミュレーター、ユーティリティー、その他外部ツールまたは外部プログラムの利用、開発行為
          </span>
        </p>
        <p>
          <span>(32)　当社が有害と判断するソフトウェアの配布行為</span>
        </p>
        <p>
          <span>
            (33)　本サービスの利用に際して本ソフトウェアまたはその他本サービスに係るソフトウェアの全部または一部を自動操作する行為
          </span>
        </p>
        <p>
          <span>
            (34)　本ソフトウェアに予め規定された会員の操作を逸脱して本サービスを利用する行為
          </span>
        </p>
        <p>
          <span>
            (35)　本サービスのサイトまたは本サービス内での掲示、告知等に違反する行為
          </span>
        </p>
        <p>
          <span>
            (36)　自己が単独で、もしくは第三者と共謀して、当社が個別規約に別途定める操作、当社が有害と判断する操作、または不正な操作によりゲーム結果に影響を及ぼす行為
          </span>
        </p>
        <p>
          <span>
            (37)　上記各号のいずれかに該当する行為を表示している文章やデータ等の表示場所（第三者が表示している場合を含みます）を教える行為
          </span>
        </p>
        <p>
          <span>
            (38)　上記各号のいずれかに該当する行為を行為者と共謀または共同して行い、または当該行為を促し、助長し、もしくは誘引する行為
          </span>
        </p>
        <p>
          <span>(39)　上記各号の他、法令または本規約に違反する行為</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第20条（利用制限）</span>
        </p>
        <p>
          <span>
            1.　会員が本規約に違反したと当社が判断した場合は、当社は、次の措置をとることができるものとします。なお、これらの場合、当社は、その理由を説明または開示しない場合があります。
          </span>
        </p>
        <p>
          <span>(1)　注意または警告</span>
        </p>
        <p>
          <span>(2)　会員が送信または表示するデータの削除</span>
        </p>
        <p>
          <span>(3)　本サービスの全部または一部の一時または永久の停止</span>
        </p>
        <p>
          <span>(4)　本サービスに係るデータの全部または一部の削除</span>
        </p>
        <p>
          <span>(5)　会員登録の削除</span>
        </p>
        <p>
          <span>
            2.　会員が複数の会員IDを有している場合には、本規約に違反した際に使用していた会員ID以外の会員IDについても前項の措置をとることができるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　当社は、会員が本サービスを最後に利用した日から2年以上が経過した場合、ならびに会員の外部サービスIDが有効に存在しないと当社が判断した場合、当該会員の本サービスの提供の全部もしくは一部を一時または永久に停止、または、本サービスに係るデータの全部もしくは一部の削除をすることができるものとします。
          </span>
        </p>
        <p>
          <span>
            4.　前各項により発生した会員の不利益および損害について、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第21条（自己責任）</span>
        </p>
        <p>
          <span>
            1.　会員は、自己の責任において本サービスを利用するものとし、本サービスで得た情報についても自己の責任において利用するものとし、これらの利用について一切の責任を負うものとします。
          </span>
        </p>
        <p>
          <span>
            2.　会員は、自己の行為により当社または第三者に損害を生じさせた場合には、その損害を賠償するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第22条（緊急措置）</span>
        </p>
        <p>
          <span>
            1.　当社は、本サービスの正常な提供のために緊急の措置が必要と当社が判断した場合においては、全ての会員または特定の会員に対して、本サービスの全部または一部の提供を当社が定める期間まで中断することができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、会員が本規約に定める会員の義務を履行しない結果、本サービスの正常な提供に不利益となると当社が判断した場合においては、特定の会員に対する本サービスの全部もしくは一部の提供を中断し、または第20条第1項各号に定める措置をとることができるものとします。
          </span>
        </p>
        <p>
          <span>
            3.　第1項および第2項により発生した会員の不利益および損害について、当社に故意または重過失が認められる場合を除き、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第23条（本サービスの中断）</span>
        </p>
        <p>
          <span>
            1.　当社は、本サービスのメンテナンスおよびアップデート等のために、本サービスを中断することができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　前項の場合において、当社は、緊急の場合を除いて、事前に会員に対し通知するものとします。
          </span>
        </p>
        <p>
          <span>
            3.　当社は、天災、地変、戦争、火災、労働紛争、法令および政府機関の命令、通信事業者およびその他の第三者に起因する障害等、当社のコントロールが及ばない事態により本サービスの提供が困難になった場合には、本サービスを中断することができるものとします。
          </span>
        </p>
        <p>
          <span>
            4.　第1項ないし第3項により発生した会員の不利益および損害について、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第24条（接続制限）</span>
        </p>
        <p>
          <span>
            当社は、本サービスおよび本ソフトウェアへの接続を、日本国内からのもの、または日本語のオペレーティングシステムによるものに限ることができるものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>第6章　一般条項</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第25条（知的財産権）</span>
        </p>
        <p>
          <span>
            1.　本サービス、本サービスのサイトおよび本ソフトウェアに関する一切の知的財産権は、当社に帰属するものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、本規約のほか、別途定めるソフトウェア使用許諾契約の条件に従って、会員に対し、本ソフトウェアの使用を許諾するものとします。
          </span>
        </p>
        <p>
          <span>
            3.　本規約とソフトウェア使用許諾契約が矛盾する場合は、ソフトウェア使用許諾契約を優先するものとします。
          </span>
        </p>
        <p>
          <span>
            4.　当社は、本サービスに関する権利保護を目的として、ウォーターマーク等を用い、会員による本サービスの全部または一部の利用に制限をかけることができるものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第26条（個人情報）</span>
        </p>
        <p>
          <span>
            会員の個人情報の取扱いについては、本規約に定めるもののほか、当社プライバシーポリシーが適用されるものとします。当社プライバシーポリシーについては、本サービスのサイトに掲載して提示します。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第27条（情報の記録および開示）</span>
        </p>
        <p>
          <span>
            1.　当社は、本サービスを利用した会員のIPアドレス、本サービスの利用履歴（利用日時等のアクセスログ、ドメイン情報、他の会員とのやりとりおよび本サービスの稼働状況等）、本サービスを利用する際に使用したパーソナルコンピューターまたはネットワーク携帯端末に関する情報（オペレーションシステム、ハードウェア、メモリー使用状況、端末固有ID（MACアドレス、端末の種類（型番等を含む）、周辺機器、接続国等）、外部サービスから提供される暗号化された外部サービスIDの文字列を記録することができるものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、本サービスの一部のサービスにおいて、会員が提供する本サービスに現在アクセスしている場所に関する情報（位置情報）、ならびに会員が保有する連絡先情報（会員が使用するネットワーク携帯端末内のアドレス帳、もしくは会員が使用するSNSの友達リスト）を取得し、本サービスの一部サービスにおいて利用することができるものとします。なお、当社は、当該連絡先情報を取得する場合には、事前に会員から承諾を得るものとします。
          </span>
        </p>
        <p>
          <span>
            3.　当社は、事前の通知を要せず、本サービスの一部において、会員の本サービスの利用およびその結果に関する情報ならびに特定の会員を識別することのできないニックネーム名等の登録情報の一部を公開する場合があります。
          </span>
        </p>
        <p>
          <span>
            4.　当社は、次の各号に該当する場合、登録情報、投稿コンテンツ、アクセスログ、その他当社が保有する情報を第三者に開示することができるものとします。
          </span>
        </p>
        <p>
          <span>
            (1)　捜査、裁判、その他法令上の手続に基づき開示を要請された場合
          </span>
        </p>
        <p>
          <span>
            (2)　弁護士会等の機関から正当な理由に基づき照会を受けた場合
          </span>
        </p>
        <p>
          <span>
            (3)　当社が本サービスを提供するために必要な範囲に限定して本サービス内のゲームの開発会社または運営会社もしくは本サービスの決済を行う会社に開示を行う場合
          </span>
        </p>
        <p>
          <span>
            (4)　第三者の生命、身体、財産およびその他の権利の保護のために、または当社の本サービスの運営もしくは財産およびその他の権利の保護のために、必要があると当社が判断した場合
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第28条（通知）</span>
        </p>
        <p>
          <span>
            1.　本規約に基づく当社から会員に対する通知は、本サービスのサイトまたは本サービス内での表示、電子メールの送信、その他当社が適当と判断する方法にて行うものとします。
          </span>
        </p>
        <p>
          <span>
            2.　当社は、会員に対して、電子メールまたは郵便等の方法で本サービスその他のサービスおよび商品に関する情報を提供することがあり、また、本サービスの一環として会員の電子メールアドレスに対して電子メールを送信することがあり、会員はこれを予め承諾するものとします。
          </span>
        </p>
        <p>
          <span>
            3.　会員は、前各項による通知を適宜確認するものとし、確認をしなかったことにより発生した会員の不利益および損害について、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第29条（保証の否認）</span>
        </p>
        <p>
          <span>
            当社は、当社に故意または重過失がある場合を除いて、次の事項について何等の保証も行わず、何等の責任を負わないものとします。
          </span>
        </p>
        <p>
          <span>
            (1)　本サービスまたは本ソフトウェアが会員の要求事項を満足させること
          </span>
        </p>
        <p>
          <span>
            (2)　本サービスまたは本ソフトウェアにエラーがないこと、もしくはエラーが発生しないこと
          </span>
        </p>
        <p>
          <span>
            (3)　本サービスまたは本ソフトウェアにコンピュータウイルスが侵入していないこと
          </span>
        </p>
        <p>
          <span>
            (4)　本サービスまたは本ソフトウェアを利用することで会員のコンピュータまたは携帯端末に不具合を生じさせないこと
          </span>
        </p>
        <p>
          <span>
            (5)　本サービスまたは本ソフトウェアを利用することで会員の権利または財産に不利益を生じさせないこと
          </span>
        </p>
        <p>
          <span>
            (6)　本サービスまたは本ソフトウェアを通じて会員が取得する情報等が完全性、正確性、有用性を有するものであること
          </span>
        </p>
        <p>
          <span>(7)　本サービスが中断しないこと</span>
        </p>
        <p>
          <span>(8)　本サービスに遅延がないこと</span>
        </p>
        <p>
          <span>
            (9)　本サービスまたは本ソフトウェアが他人の財産、著作権、商標権その他一切の権利を侵害していないこと
          </span>
        </p>
        <p>
          <span>
            (10)　会員が本サービスまたは本ソフトウェアを通じて他人の財産、著作権、商標権その他一切の権利を侵害しないこと
          </span>
        </p>
        <p>
          <span>(11)　会員が送信または表示するデータが喪失しないこと</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第30条（損害賠償の制限）</span>
        </p>
        <p>
          <span>
            1.　いかなる場合においても、当社の損害賠償責任は、請求の原因の如何を問わず、会員に現実に生じた通常かつ直接の損害に限るものとし、間接損害、特別損害、付随的損害、派生的損害、および偶発的損害（利用機会の喪失、およびデータの喪失を含みますが、これに限られません）については、たとえ当社がかかる損害が発生する可能性を事前に通知されていたとしても、当社は何等の責任も負わないものとします。
          </span>
        </p>
        <p>
          <span>
            2.
            いかなる場合においても、当社の損害賠償責任は、当該損害賠償責任の原因となる事実が発生した日から遡って3ヶ月間に会員が当社に支払った利用料金の総額を上限とします。
          </span>
        </p>
        <p>
          <span>
            3.　第1項および第2項の規定は、当社に故意または重大な過失がある場合は適用されないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第31条（準拠法および管轄）</span>
        </p>
        <p>
          <span>
            1.　本規約の準拠法は日本法とし、本サービスまたは本規約に関連する当社と会員との間で生じた一切の紛争は、訴額に応じ東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </span>
        </p>
        <p>
          <span>
            2.　会員が本サービスまたは本規約に関連して訴訟を提起する場合は、原因が生じてから1年以内に手続が開始されなければならないものとし、当該期間の経過後は、その原因を理由とする訴訟は提起できないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>2022年9月9日制定</span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>ソフトウェア使用許諾契約</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>
            このソフトウェア使用許諾契約（以下「本契約」といいます）は、GIFT
            R&D.M合同会社（以下「当社」といいます）が提供するオンラインゲームおよびフィーチャーフォン、スマートフォン、タブレット等のネットワーク携帯端末用のアプリケーション等（以下「本ゲーム」といいます）のクライアントソフトウェアまたはアプリケーションソフトウェア（クライアントソフトウェアとアプリケーションソフトウェアを総称して、以下「本ソフトウェア」といいます）の使用許諾に係る条件を定めるものです。お客様が本ソフトウェアをインストールした場合には、本契約に同意したものとみなされます。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第1条（使用許諾）</span>
        </p>
        <p>
          <span>
            当社は、お客様に対し、本契約の条項に基づき、本ソフトウェアの使用を非独占的に許諾します。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第2条（著作権）</span>
        </p>
        <p>
          <span>
            本ソフトウェアの著作権は、当社または本ソフトウェアの開発元に帰属するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第3条（アップデート）</span>
        </p>
        <p>
          <span>
            当社は、お客様が本ソフトウェアを実行した際に、本ソフトウェアのアップデートを行うことができるものとし、本契約は当該アップデートに対しても適用されるものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第4条（禁止事項）</span>
        </p>
        <p>
          <span>お客様は、次の行為をしてはならないものとします。</span>
        </p>
        <p>
          <span>
            (1)　当社の承認なくして、第三者に対して本ソフトウェアを再頒布、貸与、または販売する行為
          </span>
        </p>
        <p>
          <span>
            (2)　本ゲームの利用に必要な本数を超えて、本ソフトウェアを複製する行為
          </span>
        </p>
        <p>
          <span>
            (3)　本ソフトウェアの逆コンパイル、逆アセンブル、リバースエンジニアリング等、本ソフトウェアのソースコードを解析する行為
          </span>
        </p>
        <p>
          <span>(4)　本ソフトウェアの改変、修正、または翻案行為</span>
        </p>
        <p>
          <span>
            (5)　本ソフトウェアの不具合、または障害を不正な目的で利用する行為
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第5条（限定保証）</span>
        </p>
        <p>
          <span>
            本ソフトウェアは現状有姿にて提供されるものであり、明示または黙示にかかわらず、当社は、本ソフトウェアがお客様の要求事項を満足させること、および本ソフトウェアにエラーがないことにつき、何等の保証もいたしません。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第6条（管轄）</span>
        </p>
        <p>
          <span>
            本契約の準拠法は日本法とし、本契約に関連する当社とお客様との間で生じた一切の紛争は、訴額に応じ東京地方裁判所または東京簡易裁判所を第一審の専属的合意管轄裁判所とします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>第7条（雑則）</span>
        </p>
        <p>
          <span>
            本契約に定めのない事項については、当社が別途定めるネクソン会員サービス利用規約に従うものとし、本契約と当該規約との間に矛盾がある場合は、本契約が優先するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            黙示伝授オンライン サービス利用規約に係るiOSデバイスの特則
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            本特則は、本ソフトウェアが、Apple Inc.
            のAppleブランドが付されたiOSを起動できるデバイス（iPad、iPhoneおよびiPod
            touchを含みますがこれらに限りません）（以下「iOSデバイス」といいます）に対応する場合に、追加的に適用される条項です。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            1．お客様は、iTunes株式会社（以下「アイチューンズ」といいます）は本ソフトウェアをお客様に提供する際の当社の代理人として行為していること、アイチューンズは本ソフトウェアに関するお客様と当社との間の本契約の当事者ではないことについて了解するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            2．当社のお客様に対する本契約に基づく本ソフトウェアの使用許諾は、APP
            STORE利用条件（
            <a href="http://www.apple.com/legal/itunes/jp/terms.html#APPS">
              http://www.apple.com/legal/itunes/jp/terms.html#APPS
            </a>
            ）で規定する利用ルールで認められた範囲内でiOSデバイス上で利用することに限定されます。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            3．本ソフトウェアに関するメンテナンスおよびサポートの提供については、黙示伝授オンライン
            サービス利用規約（以下「本規約」といいます）が適用されるものとし、アイチューンズは責任を負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            4．本ソフトウェアの品質については本規約が適用されるものとし、本ソフトウェアが所定の品質保証に適合していない場合には、お客様はアイチューンズに対してこれを通知することができ、アイチューンズは本ソフトウェアの購入額をお客様に返金することができるものとしますが、このほか、アイチューンズは、本ソフトウェアについていかなる品質保証についての義務を負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            5．アイチューンズは、本ソフトウェアに関連するお客様または第三者からのクレーム（知的財産権侵害に関するクレームを含みます）について、責任を負わないものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            6．お客様は、アメリカ合衆国の通商禁止国に居住しておらず、また、アメリカ合衆国財務省の特別指定国リスト（Specially
            Designated Nationals
            List）またはアメリカ合衆国商務省の拒否個人団体リスト（Denied
            Person’s List or Entity
            List）上の者ではないことを表明かつ保証するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            7．お客様は、お客様が本ソフトウェアの使用の際に適用される第三者の付加的な条件に従わなければならない場合があります。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>
            8．アイチューンズおよびアイチューンズの子会社が、当社の本契約における第三者受益者であり、お客様による本契約の履行を強制する権利を有することを、お客様は了解するものとします。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p>
          <span>9．本ソフトウェアに関するお問い合わせ先</span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>名称：GIFT R&D.M合同会社</span>
        </p>
        <p>
          <span>住所：東京都千代田区一番町９番地</span>
        </p>
        <p>
          <span>
            メールアドレス：黙示伝授オンライン ホームページをご参照ください。
          </span>
        </p>
        <p>
          <span></span>
        </p>
        <p className={classes.mt20}>
          <span>以上</span>
          <span></span>
        </p>
      </Box>
    </Container>
  );
};

export default Termofuse;
