import React from "react";
import useStyles from "./styles";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const PlayTimeInfoBox = ({ handleByPlayTime, handleUpPlan }) => {
  const { t } = useTranslation();
  const classes = useStyles();

  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);
  return (
    <div className={classes.clockBox}>
      <div className={classes.playTimeBox}>
        <span>{t("clock.numberPlayTime")}</span>

        {playTimeInfo.statusFree && (
          <span>{t("paymentSettings.packagePlayTimesFree")}</span>
        )}
        {!playTimeInfo.statusFree && (
          <span>{`${
            playTimeInfo?.playTimes !== undefined ? playTimeInfo.playTimes : ""
          } ${t("clock.times")}`}</span>
        )}
      </div>
      {!playTimeInfo.statusFree && (
        <div className={classes.btn}>
          <button onClick={handleByPlayTime}>
            {t("clock.byRetailPackage")}
          </button>
        </div>
      )}
      <div className={classes.btn}>
        <button onClick={handleUpPlan}>{t("clock.planUpgrade")}</button>
      </div>
    </div>
  );
};

PlayTimeInfoBox.propTypes = {
  handleByPlayTime: PropTypes.func,
  handleUpPlan: PropTypes.func,
};

export default PlayTimeInfoBox;
