import React from "react";
import PropTypes from "prop-types";
import { GiClubs, GiHearts, GiSpades, GiDiamonds } from "react-icons/gi";
import { StyleOctagon } from "../../Profile/styles";

const ratio = 1.25;
function IconSuit({ suit, fontSize }) {
  return (
    <>
      {suit === "Spades" && <GiSpades style={{ fontSize: fontSize * ratio }} />}
      {suit === "Hearts" && (
        <GiHearts style={{ fontSize: fontSize * ratio, color: "red" }} />
      )}
      {suit === "Diamonds" && (
        <GiDiamonds style={{ fontSize: fontSize * ratio, color: "red" }} />
      )}
      {suit === "Clubs" && <GiClubs style={{ fontSize: fontSize * ratio }} />}
      {suit === "whatever" && <StyleOctagon size={fontSize} />}
    </>
  );
}
IconSuit.propTypes = {
  suit: PropTypes.string,
  fontSize: PropTypes.number,
};

export default IconSuit;
