import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles(() => ({
  defaultSize: {
    width: "160px",
  },
  mainDialog: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    marginLeft: 20,
    height: "65%",
  },
  playersNickName: {
    display: "grid",
    marginLeft: "20px",
    cursor: "grab",
    width: 380,
    "@media (max-width: 1024px)": {
      marginTop: "-10%",
      fontSize: "10px",
    },
  },
}));

export default useStyles;
