import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { getPlayerStatus } from "../../../../store/actions/roomActions";
import { checkOwnerStatusAction } from "../../../../store/actions/tableActions";
import { reduceNameLength, sleep } from "../../../../utils/common";
import MessDialog from "../../components/NewDialog/MessDialog";
const PLAYER_ID = "playerId";
const IS_ONLINE = "isOnline";
const LAST_CHANGED = "lastChanged";
const LIMIT_TIME = 30000;

const OnlineStatusNotifier = React.memo(function OnlineNotifierComponent() {
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);
  const nextOwnerId = useSelector((state) => state.room.table.nextOwnerId);
  const currentOwnerId = useSelector((state) => state.room.table.owner);
  const tableId = useSelector((state) => state.room.table.tableId);
  const roomId = useSelector((state) => state.room.roomId);
  const myId = useSelector((state) => state.user.playerId);
  const currentState = useRef({ currentPlayers: [], interval: null }).current;
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const playerChangeStatus = useRef(null);
  const beforePlayerChangeStatus = useRef(null);
  const isReload = useRef(0);
  const MINUTE = 1000 * 60;
  const usersStatus = useRef({}).current;
  const currentStatus = useRef([]);
  const notify = useRef([]);
  const [forceRender, setForceRender] = useState(0);

  const addUserKey = (users = []) => {
    const playerIds = users.map((user) => user && user[PLAYER_ID]);
    /**
     * add new key when reload/joinRoom
     * default: isOnline: true, lastChanged: now
     */
    for (const user of users) {
      if (!user) continue;
      const { playerId, name } = user;
      if (!(playerId in usersStatus) && name !== "G-Chaos") {
        usersStatus[playerId] = {
          playerId: playerId,
          name: name,
          isOnline: true,
          lastChanged: Date.now(),
        };
      }
    }

    /**
     * reset usersStatus -> reload, leaveRoom
     * check keys in usersStatus object
     * if key not exist in playerIds -> delete
     */
    for (const key in usersStatus) {
      if (Object.hasOwnProperty.call(usersStatus, key)) {
        if (!playerIds.includes(key)) {
          delete usersStatus[key];
        }
      }
    }
  };

  // reload/joinRoom -> update user key
  useEffect(() => {
    const updateUsersKey = () => {
      if (joinedPlayers && joinedPlayers.length > 0) {
        currentStatus.current = [...joinedPlayers];
        const players = joinedPlayers.map(
          (pl) => pl && { playerId: pl[PLAYER_ID], name: pl["nickName"] }
        );
        if (players && players.length > 0) {
          addUserKey(players);
        }
      }
    };
    updateUsersKey();
    // eslint-disable-next-line
  }, [joinedPlayers, t]);

  const updateStatus = (
    time = 0,
    playerId = "",
    nickName = "",
    isOnline = false,
    lastChanged = Date.now()
  ) => {
    if (!playerId || !nickName) return;
    if (time >= LIMIT_TIME) {
      usersStatus[playerId][IS_ONLINE] = isOnline;
      usersStatus[playerId][LAST_CHANGED] = lastChanged;
      const text = t(
        isOnline
          ? "instructor.playerConnected"
          : "instructor.playerDisconnected",
        {
          name: reduceNameLength(nickName, 20),
        }
      );
      notify.current.push(text);
      setForceRender((prev) => prev + 1);
    } else {
      usersStatus[playerId][LAST_CHANGED] = Date.now();
    }
  };

  const dispatch = useDispatch();

  const subTime = (time = "") => {
    if (time) {
      const timestamp = Date.now() - new Date(time).getTime();
      console.log("time: ", timestamp);
      return Date.now() - new Date(time).getTime();
    }
    return false;
  };

  useEffect(() => {
    if (notify.current.length > 0 && message === "") {
      setMessage(notify.current.pop());
    }
  }, [message, forceRender]);

  // recheck connection after each 3s
  const checkConnection = () => {
    setInterval(() => {
      if (currentStatus.current && currentStatus.current.length === 0) return;
      currentStatus.current.forEach((player) => {
        if (!player) return;
        const { playerId, nickName, isOnline, lastChanged } = player;
        if (nickName === "G-Chaos" || playerId === myId) return;
        if (
          isOnline !== undefined &&
          lastChanged !== undefined &&
          isOnline !== usersStatus[playerId][IS_ONLINE]
        ) {
          const time = usersStatus[playerId][LAST_CHANGED] - lastChanged;
          updateStatus(time, playerId, nickName, isOnline, lastChanged);
        } else {
          usersStatus[playerId][LAST_CHANGED] = Date.now();
        }
      });
    }, 3000);
  };

  useEffect(() => {
    checkConnection();
    // eslint-disable-next-line
  }, []);

  // hide message after 2s
  useEffect(() => {
    const checkStatus = async () => {
      if (joinedPlayers && joinedPlayers.length === 0) return;
      if (
        playerChangeStatus.current &&
        playerChangeStatus.current[PLAYER_ID] !== myId &&
        beforePlayerChangeStatus.current
      ) {
        await sleep(LIMIT_TIME);
        const playerNewStatus = dispatch(
          getPlayerStatus(playerChangeStatus.current[PLAYER_ID])
        );
        if (!playerNewStatus) return;
        if (subTime(playerNewStatus[LAST_CHANGED]) < LIMIT_TIME) {
          isReload.current = 1;
          beforePlayerChangeStatus.current = playerChangeStatus.current;
          return;
        }
        if (
          new Date(playerChangeStatus.current[LAST_CHANGED]).getTime() -
            new Date(beforePlayerChangeStatus.current[LAST_CHANGED]).getTime() <
          LIMIT_TIME
        ) {
          isReload.current = 1;
          beforePlayerChangeStatus.current = playerChangeStatus.current;
        } else {
          isReload.current = 0;
          beforePlayerChangeStatus.current = playerChangeStatus.current;
          const text = t(
            playerNewStatus[IS_ONLINE]
              ? "instructor.playerConnected"
              : "instructor.playerDisconnected",
            {
              name: playerNewStatus.nickName,
            }
          );
          setMessage(text);
        }
      }
    };

    checkStatus();
  }, [joinedPlayers, myId, t, dispatch]);

  useEffect(() => {
    const hideMessage = async () => {
      if (message) {
        await sleep(2000);
        setMessage("");
      }
    };
    hideMessage();
  }, [message]);

  useEffect(() => {
    if (nextOwnerId === myId) {
      // Call check function here
      if (currentState.interval === null) {
        const checkInterval = setInterval(() => {
          checkOwnerStatusAction(currentOwnerId, tableId, roomId, myId);
        }, MINUTE);
        currentState.interval = checkInterval;
      }
    } else {
      if (currentState.interval) {
        clearInterval(currentState.interval);
        currentState.interval = null;
      }
    }
  }, [nextOwnerId, myId, MINUTE, currentState, tableId, roomId, currentOwnerId]);

  if (message !== "") {
    return <MessDialog isOpen={true} mainText={message} />;
  }
  return <></>;
});

export default OnlineStatusNotifier;
