import React, { useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { selectMasterCardGChaosIsHuman } from "../../../../store/actions/gameActions";
import {
  checkPlayerIsSelected,
  playerSelectMC,
} from "../../../../store/actions/playerGameStateActions";
import { SCREEN_NARRATION } from "../../../../store/constant";
import { HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST } from "../../../../store/types";
import FlippedCard from "../FlippedCard";
import {
  CardSelectedWrapper,
  CardWrapper,
  Container,
  Nickname,
  TitleWrapper,
} from "./styles";
import UpdateTurnPlayerSelectMasterCard from "./UpdateTurnPlayerSelectMasterCard";

const ShowMasterCardsGChaosIsHuman = () => {
  const nickName = useSelector((state) => state.user.nickName);
  const tableId = useSelector((state) => state.room.table.tableId);
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);
  const gameId = useSelector((state) => state.playerGameState.gameId);
  const isMyTurn = useSelector((state) => state.playerGameState.isMyTurn);
  const playerId = useSelector((state) => state.playerGameState.playerId);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  const canSelectMC = useSelector((state) => state.playerGameState.canSelectMC);
  const masterGrandCross = useSelector(
    (state) => state.playerGameState.masterGrandCross
  );
  const popupId = useSelector((state) => state.narration.narrationPopup);
  const screenId = useSelector((state) => state.narration.narrationScreen);
  const isGChaosComputer = useSelector(
    (state) => state.room.table.isGChaoComputer
  );
  const size = useSelector((state) => state.size);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const hShipWidth = useSelector((state) => state.size.hShipWidth);

  const [innerSize, setInnerSize] = useState({});
  const [isShowMasterCard, setIsShowMasterCard] = useState(false);
  const [isFlipped, setIsFlipped] = useState(false);
  const [masterCards, setMasterCards] = useState([]);
  const [hideCount, setHideCount] = useState(0);

  const dispatch = useDispatch();

  const handleSelectCard = (card) => {
    if (!canSelectMC) return;
    const isPlayerSelected = checkPlayerIsSelected(
      nickName,
      masterGrandCross,
      isMyTurn,
      card
    );
    if (isPlayerSelected) return;
    const playerIdx = card.playerIdx;
    dispatch(playerSelectMC(playerIdx, nickName, myIndex));
    selectMasterCardGChaosIsHuman(playerId, playerIdx, stepId, tableId, gameId);
  };

  useEffect(() => {
    const show = [
      SCREEN_NARRATION.UM_SHOW_USE_MASTER_WINNER_CONFIRM,
      SCREEN_NARRATION.UM_SHOW_MASTER_CARD,
      SCREEN_NARRATION.UM_SHOW_MASTER_CARD_ALL,
    ].includes(popupId);
    if (show) {
      if (hideCount === 0) {
        dispatch({ type: HIDE_ALL_TURN_OTHER_PLAYER_STATE_REQUEST });
        setHideCount(1);
      }
      setIsShowMasterCard(true);
    }
  }, [popupId, dispatch, masterGrandCross, hideCount]);

  useEffect(() => {
    if (SCREEN_NARRATION.UM_SHOW_MASTER_CARD_ALL === screenId) {
      setIsFlipped(true);
    }
  }, [screenId, dispatch]);

  useEffect(() => {
    if (size) {
      const fontSize = Math.round(16 * size.minScale);
      setInnerSize({ fontSize });
    } else {
      const fontSize = 16;
      setInnerSize({ fontSize });
    }
  }, [size]);

  useEffect(() => {
    const isSetMasterCards = masterGrandCross && masterGrandCross.length > 4;
    if (!isSetMasterCards) return;
    const _masterCards = masterGrandCross.slice(4);
    setMasterCards(_masterCards);
  }, [masterGrandCross, dispatch]);

  const checkShowMasterCards = useMemo(() => {
    return isShowMasterCard && masterCards.length > 0;
  }, [isShowMasterCard, masterCards]);

  const handleSetNickName = (card) => {
    if (!card) return "";
    if (card.playerIdx === -1) return "";
    const player = joinedPlayers[card.playerIdx];
    if (!player) return "";
    return player.nickName;
  };

  if (!checkShowMasterCards) return null;

  return (
    <Container top={shipSpace} width={hShipWidth} id="show-master-card">
      <UpdateTurnPlayerSelectMasterCard popupId={popupId} />
      <TitleWrapper>
        <p></p>
      </TitleWrapper>
      <CardWrapper>
        {joinedPlayers &&
          masterCards.map((c, index) => {
            return (
              <CardSelectedWrapper key={index}>
                <Nickname
                  hasNickname={c.playerIdx > -1}
                  isDisplayNickName={c.playerIdx >= 0 && !isGChaosComputer}
                  fontSize={innerSize.fontSize}
                >
                  <p style={{ fontSize: innerSize.fontSize }}>
                    {handleSetNickName(c)}
                  </p>
                </Nickname>
                <FlippedCard
                  flipped={isFlipped}
                  src={`/cards/${c.suit}${c.value}.jpg`}
                  onClickSelectCard={() => handleSelectCard(c)}
                  size={size}
                />
              </CardSelectedWrapper>
            );
          })}
      </CardWrapper>
    </Container>
  );
};
export default React.memo(ShowMasterCardsGChaosIsHuman);
