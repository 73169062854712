import firebase from "../../services/firebase";
import { isEmpty } from "lodash";
const TABLES = "tables";
const tableCollection = firebase.firestore().collection(TABLES);

/**
 * get data by docId
 * @param {string} docId
 * @returns
 */
async function read(docId = "") {
  return (await tableCollection.doc(docId).get()).data();
}

// async function readAllSubDoc(docId = "") {
//   return tableCollection.doc(docId).collection(OVER_PLAYTIME_PLAYER).get();
// }

/**
 * get all players data
 * @returns
 */
async function readAll() {
  return tableCollection.get();
}

/**
 * update data by docId
 * @param {string} docId
 * @param {object} data
 * @returns
 */
async function update(docId = "", data = {}) {
  if (!docId || isEmpty(data)) return;
  return tableCollection.doc(docId).update(data);
}

async function deleteTable(docId) {
  if (docId) {
    return tableCollection.doc(docId).delete();
  }
}

export default {
  read,
  readAll,
  update,
  deleteTable,
  // readAllSubDoc,
};
