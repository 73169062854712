import { Box, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";
import { clearNarration } from "../../../../../store/actions/narrationActions";
import {
  clearMyGameStateConfirmSomeOneLeave,
  plusPlayerReady,
} from "../../../../../store/actions/playerGameStateActions";
import { NOTIFICATION_TYPE } from "../../../../../store/constant";
import { OKButton } from "../../NewDialog/Buttons";

const NarrationLeaveWhilePlayingNotification = React.memo(
  function NarrationLeaveWhilePlayingNotificationComponent() {
    const playerId = useSelector((state) => state.user.playerId);
    const roomId = useSelector((state) => state.room.roomId);
    const tableId = useSelector((state) => state.room.table.tableId);
    const playerGameStateNotification = useSelector(
      (state) => state.playerGameState.notifications
    );
    const [content, setContent] = useState("");
    const [isShow, setIsShow] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const dispatch = useDispatch();
    const { t } = useTranslation();

    const onOk = async () => {
      if (!isClicked) {
        clearMyGameStateConfirmSomeOneLeave(playerId);
        setIsShow(false);
        dispatch(clearNarration());
        // Plus playerReady when press button OK
        plusPlayerReady(roomId, tableId, playerId);
        setIsClicked(true);
        console.log("Click ready button");
      }
    };

    useEffect(() => {
      if (
        playerGameStateNotification &&
        playerGameStateNotification.length > 0
      ) {
        const first = playerGameStateNotification[0];
        if (first.type === NOTIFICATION_TYPE.LEAVE_ROOM) {
          const message = t(`tables.${first.message}`, {
            name: first?.data?.nickName ?? "",
          });
          setContent(message);
          setIsShow(true);
          setIsClicked(false);
        }
      }
    }, [playerGameStateNotification, t]);
    return (
      <Dialog
        hideBackdrop={true}
        open={isShow}
        PaperProps={{
          style: { borderRadius: "35px", backgroundColor: "#f7f7f7" },
        }}
        PaperComponent={PaperComponent}
        // classes={{ paper: classes.dialog }}
      >
        <div className="dialog-style-1" id="draggable-dialog-title">
          <div style={{ height: "70%" }}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              height={145}
            >
              <Box
                style={{
                  padding: "0 30px",
                  cursor: "pointer",
                }}
                fontWeight={700}
                dangerouslySetInnerHTML={{
                  __html: content,
                }}
                textAlign="center"
              ></Box>
            </Box>
          </div>
          <Box display="flex" justifyContent="center" style={{ height: "25%" }}>
            <Box width={135}>
              <OKButton onClick={onOk}>
                <p
                  style={{
                    letterSpacing: 15,
                    marginLeft: "15%",
                  }}
                >
                  {t("narrationSetting.OK")}
                </p>
              </OKButton>
            </Box>
          </Box>
        </div>
      </Dialog>
    );
  }
);
export default NarrationLeaveWhilePlayingNotification;
