import { intersectionWith, isEqual, uniqBy } from "lodash";
import { v4 as uuidV4 } from "uuid";
import firebase from "../../services/firebase";
import { FRIEND_REQUEST_ALLOW, NOTIFY } from "../constant";
import ChatRepo from "../repositories/chat";
import PlayerRepo from "../repositories/player";
import { SET_STATUS_ONLINE } from "../types";
const db = firebase.firestore();
const auth = firebase.auth();
const realtime = firebase.database();

/** FIELDS */
const PLAYER_ID = "playerId";
const FRIEND_ID = "friendId";
const NICKNAME = "nickName";
const AVATAR = "avatar";
const FRIEND_REQUESTS = "friendRequests";
const REQUESTS_SENT = "requestsSent";
const THREADS = "threads";
const FRIENDS = "friends";
const NOTIFICATIONS = "notifications";
const IS_ONLINE = "isOnline";
const IS_SHOW_STATUS = "isShowStatus";
const IS_PLAYING = "isPlaying";
const IS_IN_ROOM = "currentRoomJoinedId";

/** TYPE NOTIFICATIONS */
const ADD_FRIEND = "ADD_FRIEND";
const ACCEPT_FRIEND = "ACCEPT_FRIEND";

export const subscribeConnect = (setUnsubscribe) => {
  return (dispatch) => {
    const unsubscribe = realtime.ref(".info/connected").on("value", () => {
      if (auth.currentUser) {
        const uid = auth.currentUser.uid;
        const userStatusRtdbRef = realtime.ref("/status/" + uid);

        const isOfflineRtdb = {
          isOnline: false,
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };

        const isOnlineRtdb = {
          isOnline: true,
          last_changed: firebase.database.ServerValue.TIMESTAMP,
        };
        userStatusRtdbRef
          .onDisconnect()
          .set(isOfflineRtdb)
          .then(function () {
            db.collection("players")
              .doc(uid)
              .set({ isOnline: true }, { merge: true });
            dispatch({
              type: SET_STATUS_ONLINE,
              payload: true,
            });
            userStatusRtdbRef.set(isOnlineRtdb);
          });
      }
    });
    setUnsubscribe(unsubscribe);
  };
};

export const searchFriends = async (query, exceptUid) => {
  try {
    if (query !== "") {
      let usersRef = db
        .collection("players")
        .where("firstLogin", "==", false)
        .where("nickName", "==", query);

      if (exceptUid && exceptUid.length > 0) {
        usersRef = usersRef.where("nickName", "not-in", exceptUid);
      }
      const data = await usersRef.get();
      return data.docs.map((doc) => doc.data());
    } else {
      return [];
    }
  } catch (error) {
    console.log(error);
  }
};

/**
 *
 * @param {string} myId
 * @param {string} avatar
 * @param {string} nickname
 * @param {string} introduce
 * @param {string} friendId
 * @returns
 */
export async function sendFriendRequest({
  myId = "",
  avatar = "",
  nickname = "",
  //introduce = "",
  friendId = "",
}) {
  try {
    if (myId && friendId) {
      const playerData = await PlayerRepo.read(myId);
      const friendData = await PlayerRepo.read(friendId);
      if (playerData && friendData) {
        let friendRequests = [];
        let requestsSent = [];
        if (FRIEND_REQUESTS in friendData) {
          friendRequests = friendData[FRIEND_REQUESTS];
        }
        if (REQUESTS_SENT in playerData) {
          requestsSent = playerData[REQUESTS_SENT];
        }
        const indexOfSentRequest = requestsSent.indexOf(friendId);
        if (indexOfSentRequest === -1) {
          requestsSent.push(friendId);
          await PlayerRepo.update(myId, {
            [REQUESTS_SENT]: uniqBy(requestsSent, myId),
          });
        }
        const index = friendRequests.findIndex(
          (fr) => fr[FRIEND_ID] === friendId
        );
        if (index === -1) {
          friendRequests.push({
            friendId: myId,
            friendAvatar: avatar,
            friendName: nickname,
            //introduce: introduce,
            timestamp: Date.now(),
            seen: false,
            type: ADD_FRIEND,
            id: uuidV4(),
          });

          await PlayerRepo.update(friendId, {
            [FRIEND_REQUESTS]: uniqBy(friendRequests, FRIEND_ID),
          });
        }
        return "success";
      } else {
        return "fail";
      }
    } else {
      return "fail";
    }
  } catch (error) {
    console.log(error);
    return "fail";
  }
}

export async function seenNotify({ myId = "", type = "", notifyId = "" }) {
  try {
    if (myId && type) {
      const player = await PlayerRepo.read(myId);
      if (player) {
        if (type === NOTIFY.ACCEPT_FRIEND) {
          const notifications = player[NOTIFICATIONS] ?? [];
          const newNotifications = notifications.map((notify) => {
            if (notify["id"] === notifyId) {
              return {
                ...notify,
                seen: true,
              };
            }
            return notify;
          });
          await PlayerRepo.update(myId, { [NOTIFICATIONS]: newNotifications });
          return;
        }
        if (type === NOTIFY.ADD_FRIEND) {
          const friendRequests = player[FRIEND_REQUESTS] ?? [];
          const newFriendRequests = friendRequests.map((fr) => {
            if (fr["id"] === notifyId) {
              return {
                ...fr,
                seen: true,
              };
            }
            return fr;
          });
          await PlayerRepo.update(myId, {
            [FRIEND_REQUESTS]: newFriendRequests,
          });
        }
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * update status when user seen notification
 * @param {string} myId
 * @param {string} friendId
 * @returns
 */
export async function seenSendFriendRequest(myId = "", friendId = "") {
  try {
    if (!myId || !friendId) return;
    const player = await PlayerRepo.read(myId);
    const friendRequests = player[FRIEND_REQUESTS] ?? [];
    const newFriendRequests = friendRequests.map((fr) => {
      if (fr[FRIEND_ID] === friendId) {
        return {
          ...fr,
          seen: true,
        };
      }
      return fr;
    });
    await PlayerRepo.update(myId, { [FRIEND_REQUESTS]: newFriendRequests });
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {*} myData
 * @param {*} friendData
 * @returns
 */
export function sendNotifyAcceptFriend(myData = {}, friendData = {}) {
  try {
    let myNotifications = [];
    let friendNotifications = [];
    if (NOTIFICATIONS in myData) {
      myNotifications = myData[NOTIFICATIONS];
    }
    if (NOTIFICATIONS in friendData) {
      friendNotifications = friendData[NOTIFICATIONS];
    }
    myNotifications.push({
      id: uuidV4(),
      type: ACCEPT_FRIEND,
      friendId: friendData[PLAYER_ID],
      friendName: friendData[NICKNAME],
      seen: true,
      timestamp: Date.now(),
    });
    friendNotifications.push({
      id: uuidV4(),
      type: ACCEPT_FRIEND,
      friendId: myData[PLAYER_ID],
      friendName: myData[NICKNAME],
      seen: false,
      timestamp: Date.now(),
    });
    return {
      myNotifications,
      friendNotifications,
    };
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {*} myData
 * @param {*} friendData
 * @returns
 */
export async function getNewFriends(myData, friendData) {
  try {
    let friends1 = [];
    let friends2 = [];
    if (FRIENDS in myData) {
      friends1 = myData[FRIENDS];
    }
    friends1.push({
      friendId: friendData[PLAYER_ID],
      nickName: friendData[NICKNAME],
      avatar: friendData[AVATAR],
      threadId: "",
    });
    if (FRIENDS in friendData) {
      friends2 = friendData[FRIENDS];
    }
    friends2.push({
      friendId: myData[PLAYER_ID],
      nickName: myData[NICKNAME],
      avatar: myData[AVATAR],
      threadId: "",
    });
    return {
      friends1,
      friends2,
    };
  } catch (error) {
    console.log(error);
  }
}

/**
 * accept friend invitation
 * @param {string} myId
 * @param {string} friendId
 * @returns
 */
export async function acceptFriendRequest(myId = "", friendId = "") {
  try {
    if (myId && friendId) {
      const myData = await PlayerRepo.read(myId);
      const friendData = await PlayerRepo.read(friendId);
      if (myData && friendData) {
        const { friends1, friends2 } = await getNewFriends(myData, friendData);
        const { myNotifications, friendNotifications } = sendNotifyAcceptFriend(
          myData,
          friendData
        );
        const friendRequests = myData[FRIEND_REQUESTS].filter(
          (f) => f && f[FRIEND_ID] !== friendId
        );

        const _requestsSent = friendData.requestsSent ?? [];
        const requestsSent = _requestsSent.filter((fid) => fid !== myId);
        await PlayerRepo.update(myId, {
          [FRIENDS]: friends1,
          [NOTIFICATIONS]: myNotifications,
          [FRIEND_REQUESTS]: friendRequests,
        });
        await PlayerRepo.update(friendId, {
          [FRIENDS]: friends2,
          [NOTIFICATIONS]: friendNotifications,
          requestsSent,
        });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * delete friend request
 * @param {string} myId
 * @param {string} friendId
 * @returns
 */
export async function rejectFriendRequest(myId = "", friendId = "") {
  try {
    if (myId && friendId) {
      const myData = await PlayerRepo.read(myId);
      const friendData = await PlayerRepo.read(friendId);
      if (myData && friendData) {
        const _friendRequests = myData.friendRequests ?? [];
        const friendRequests = _friendRequests.filter(
          (fr) => fr[FRIEND_ID] !== friendId
        );

        const _requestsSent = friendData.requestsSent ?? [];
        const requestsSent = _requestsSent.filter((fid) => fid !== myId);
        await PlayerRepo.update(myId, { friendRequests });
        await PlayerRepo.update(friendId, { requestsSent });
      }
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * delete friend by id
 * @param {string} myId
 * @param {string} friendId
 * @returns
 */
export async function deleteFriend(myId = "", friendId = "") {
  try {
    if (myId && friendId) {
      const myData = await PlayerRepo.read(myId);
      const friendData = await PlayerRepo.read(friendId);
      if (myData && friendData) {
        const { myFriends, otherFriends, myThreads, otherThreads, threadId } =
          formatFriendsData({ myId, myData, friendId, friendData });
        await PlayerRepo.update(myId, {
          [FRIENDS]: myFriends,
          [THREADS]: myThreads,
        });
        await PlayerRepo.update(friendId, {
          [FRIENDS]: otherFriends,
          [THREADS]: otherThreads,
        });
        if (threadId) {
          await ChatRepo.deleteCollection(`chats/${threadId}/messages`);
          await ChatRepo.deleteDocument(threadId);
        }
        return "success";
      } else {
        return "fail";
      }
    } else {
      return "fail";
    }
  } catch (error) {
    console.log(error);
    return "fail";
  }
}

function formatFriendsData({ myId, myData, friendId, friendData }) {
  let myFriends = [];
  let otherFriends = [];
  let myThreads = [];
  let otherThreads = [];
  let threadId = "";
  if (myData && friendData) {
    const myFriend = myData[FRIENDS].filter((f) => f[FRIEND_ID] === friendId);
    if (myFriend.length > 0) {
      threadId = myFriend[0].threadId;
    }
    myFriends = myData[FRIENDS].filter((f) => f[FRIEND_ID] !== friendId);
    otherFriends = friendData[FRIENDS].filter((f) => f[FRIEND_ID] !== myId);
    myThreads = myData[THREADS].filter((id) => id !== threadId);
    otherThreads = friendData[THREADS].filter((id) => id !== threadId);
  }
  return {
    myFriends,
    otherFriends,
    myThreads,
    otherThreads,
    threadId,
  };
}

export async function blockFriend(myId = "", friendId = "") {
  try {
    if (!myId || !friendId) return;
    const myData = await PlayerRepo.read(myId);
    const bannedList = myData.bannedList ?? [];
    bannedList.push(friendId);
    await PlayerRepo.update(myId, { bannedList });
  } catch (error) {
    console.log(error);
  }
}

/**
 * list online users
 * @returns
 */
export async function onlineUsers(myId = "") {
  try {
    if (!myId) return;
    const player = await PlayerRepo.read(myId);
    const friends = player[FRIENDS] ?? [];
    const friendRequests = player[FRIEND_REQUESTS] ?? [];
    const players = await PlayerRepo.readAll();
    if (!players) return;
    return formatOnlineUserList(players.docs, friends, myId, friendRequests);
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {array[document]} docs
 * @returns
 */
export function formatOnlineUserList(
  docs = [],
  friends = [],
  myId = "",
  friendRequests = []
) {
  try {
    const users = [];
    for (const doc of docs) {
      const data = doc.data();
      if (data && data[IS_ONLINE]) {
        if (data[NICKNAME] && data[PLAYER_ID] !== myId) {
          users.push({
            friendId: data[PLAYER_ID],
            friendName: data[NICKNAME],
            isFriend: isFriend(friends, data[PLAYER_ID]),
            isOnline: data[IS_ONLINE] ?? false,
            isInRoom: data[IS_IN_ROOM] ? true : false,
            isPlaying: data[IS_PLAYING],
            isShowStatus: data[IS_SHOW_STATUS] ?? true,
            isShowAddFriend: sentFriendRequest(
              data[PLAYER_ID],
              friendRequests,
              myId,
              data[FRIEND_REQUESTS]
            ),
          });
        }
      }
    }
    return users;
  } catch (error) {
    console.log(error);
  }
}

export function isFriend(friends = [], friendId = "") {
  try {
    if (friends.length === 0 || friendId === "") return false;
    let _isFriend = false;
    let index = 0;
    while (_isFriend === false && index < friends.length) {
      if (friends[index][FRIEND_ID] === friendId) {
        _isFriend = true;
      }
      ++index;
    }
    return _isFriend;
  } catch (error) {
    console.log(error);
  }
}

export function sentFriendRequest(
  friendId = "",
  friendRequests = [],
  myId = "",
  myFriendRequests = []
) {
  try {
    let check1 = false;
    let check2 = false;
    check1 =
      friendRequests.findIndex((fr) => fr[FRIEND_ID] === friendId) === -1;
    if (check1) {
      check2 =
        myFriendRequests.findIndex((fr) => fr[FRIEND_ID] === myId) === -1;
    }
    return check1 && check2;
  } catch (error) {
    console.log(error);
  }
}

/**
 * list online friends
 * @param {string} playerId
 * @returns
 */
export async function friendList(playerId = "", isOnline = false) {
  try {
    if (!playerId) return;
    const player = await PlayerRepo.read(playerId);
    if (!player) return;
    const promises = [];
    player[FRIENDS].forEach((f) => {
      if (f && f[IS_ONLINE] && f[IS_ONLINE][IS_ONLINE] === isOnline) {
        promises.push(PlayerRepo.read(f[FRIEND_ID]));
      }
    });
    const friends = await Promise.all(promises);
    return formatFriendList(friends);
  } catch (error) {
    console.log(error);
  }
}

/**
 *
 * @param {array} friends
 * @param {array} users
 * @returns
 */
export function formatFriendList(friends = []) {
  return friends.map((f) => {
    return {
      friendId: f[PLAYER_ID],
      friendName: f[NICKNAME],
      isFriend: true,
      isOnline: f[IS_ONLINE] ?? true,
      isInRoom: f[IS_IN_ROOM] ? true : false,
      isPlaying: f[IS_PLAYING],
      isShowStatus: f[IS_SHOW_STATUS] ?? true,
    };
  });
}

export async function allowAddFriendRequest(
  playerId = "",
  friendRequestAllow = FRIEND_REQUEST_ALLOW.ALL_USER
) {
  try {
    if (playerId) {
      await PlayerRepo.update(playerId, { friendRequestAllow });
    }
  } catch (error) {
    console.log(error);
  }
}

/**
 * show/hide add friend request
 * @param {string} playerId
 * @returns
 */
export async function checkFriendOfListFriend(
  playListFriends = [],
  currentListFriends = []
) {
  try {
    if (playListFriends.length > 0 && currentListFriends.length > 0) {
      const listFriendIntersection = intersectionWith(
        playListFriends,
        currentListFriends,
        isEqual
      );
      return listFriendIntersection.length > 0;
    }
  } catch (error) {
    console.log(error);
  }
}
