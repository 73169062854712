import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  GAME_STATUS,
  SCREEN_NARRATION,
  TUTORIAL_MODE,
} from "../../../../../store/constant";
import { useSelector } from "react-redux";

const TrickStarCardContainer = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.width}px;
  background-image: url("/assets/bg_master_cards.png");
  box-sizing: border-box;
  border-radius: 5px;
  background-size: 100% 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  right: ${(props) => props.rightSpace};
`;

const TrickStarCard = styled.img`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  box-shadow: 7px 7px 10px;
  border-radius: 4%;
  position: relative;
  bottom: 5px;
`;
export const TrickStarComponent = () => {
  const { t } = useTranslation();
  const minScale = useSelector((state) => state.size.minScale);
  const cardWidth = useSelector((state) => state.size.cardWidth);
  const cardHeight = useSelector((state) => state.size.cardHeight);
  const space = useSelector((state) => state.size.shipSpace);
  const width = useSelector((state) => state.size.vShipWidth);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const titleFontSize = useMemo(
    () => Math.round((minScale ?? 1) * 16),
    [minScale]
  );
  const trickStarCardWidth = useMemo(
    () => Math.round(cardWidth * 0.8),
    [cardWidth]
  );
  const trickStarCardHeight = useMemo(
    () => Math.round(cardHeight * 0.8),
    [cardHeight]
  );
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const trickStarCard = useSelector(
    (state) => state.playerGameState.trickStarCard
  );
  const [isShowMyCards, setIsShowMyCards] = useState(false);
  const [isShowTrickStar, setIsShowTrickStar] = useState(false);

  useEffect(() => {
    if ([GAME_STATUS.WIN, GAME_STATUS.LOSE].includes(gameStatus)) {
      setIsShowTrickStar(false);
      setIsShowMyCards(false);
    }
    if (!("suit" in trickStarCard)) {
      setIsShowTrickStar(false);
      setIsShowMyCards(false);
      return;
    }
    if (
      ("suit" in trickStarCard &&
        confirmId === SCREEN_NARRATION.UM_MASTER_CALL_SHOW_TRICK_STAR) ||
      [TUTORIAL_MODE.NO_CALL, TUTORIAL_MODE.HAS_CALL].includes(tutorialMode)
    ) {
      setIsShowTrickStar(true);
      setIsShowMyCards(true);
    }
  }, [confirmId, trickStarCard, tutorialMode, gameStatus]);

  const rightSpace = useMemo(() => {
    return `calc(${Math.round(shipSpace * 2.2)}px);`;
  }, [shipSpace]);

  const cardSrc = useMemo(() => {
    return isShowMyCards
      ? `/cards/${trickStarCard.suit}${trickStarCard.value}.jpg`
      : "/cards/back.png";
  }, [trickStarCard, isShowMyCards]);

  return isShowTrickStar ? (
    <TrickStarCardContainer
      rightSpace={rightSpace}
      width={width / 1.25}
      space={space}
    >
      <p
        style={{
          textAlign: "center",
          color: "white",
          fontSize: `${titleFontSize}px`,
          padding: `${titleFontSize / 2}px`,
          fontWeight: 400,
          position: "relative",
          bottom: "5px",
        }}
      >
        {t("game.trickStar")}
      </p>
      <TrickStarCard
        src={cardSrc}
        width={trickStarCardWidth}
        height={trickStarCardHeight}
      />
    </TrickStarCardContainer>
  ) : null;
};

const TrickStar = React.memo(TrickStarComponent);
export default TrickStar;
