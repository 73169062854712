import PropTypes from "prop-types";
import { useEffect } from "react";
import { Redirect, Route, useHistory, useLocation } from "react-router-dom";
import firebase from "../../../../services/firebase";
import { ADMIN } from "../../../../store/constant";

export default function AdminPublicRoute({ component: Component, ...rest }) {
  const location = useLocation();
  const history = useHistory();
  console.log("firebase.auth().currentUser: ", firebase.auth().currentUser);
  useEffect(() => {
    firebase.auth().onAuthStateChanged(async (user) => {
      if (user) {
        // get claims in user
        const response = await user.getIdTokenResult();

        // check claims admin
        if (response.claims.admin !== ADMIN) {
          await firebase.auth().signOut();
          history.push("/admin-login");
        }
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Route
      {...rest}
      render={(props) =>
        firebase.auth().currentUser ? (
          <Redirect
            to={
              location.state && location.pathname !== "/admin-login"
                ? `${location.state.from.pathname + location.state.from.search}`
                : "/admin/admin-list"
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

AdminPublicRoute.propTypes = {
  component: PropTypes.elementType,
};
