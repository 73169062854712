/* eslint-disable react/prop-types */
import DateFnsUtils from "@date-io/date-fns";
import { Box, Button, Container } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import { format } from "date-fns";
import jaLocale from "date-fns/locale/ja";
import moment from "moment";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import AvatarUpload from "../../components/AvatarUpload";
import ErrorMessage from "../../components/ErrorMessage";
import Input from "../../components/Input";
import RadioGroup from "../../components/RadioGroup";
import SuccessMessage from "../../components/SuccessMessage";
import { updateUserOfPlayTime } from "../../store/actions/playerActions";
import {
  updatePlayersFriend,
  updateUser,
} from "../../store/actions/userAction";
import {
  FRIEND_REQUEST_ALLOW,
  MAX_CHARACTER_NAME,
  MAX_CHARACTER_NICK_NAME,
} from "../../store/constant";
import { settingValidateInput } from "../../utils/validation";
import useStyles, { BoxWrapper } from "./styles";

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;
const initialValues = {
  playerId: "",
  fullName: "",
  nickName: "",
  dateOfBirth: "",
  gender: "",
  email: "",
  avatar: "",
  showOnline: "false",
  friendRequestAllow: FRIEND_REQUEST_ALLOW.ALL_USER,
};

class JaLocalizedUtils extends DateFnsUtils {
  // ヘッダ部分のテキストを取得するメソッド
  getCalendarHeaderText(date) {
    return format(date, "yyyy年M月", { locale: this.locale });
  }
  getDatePickerHeaderText(date) {
    return format(date, "M月d日(E)", { locale: jaLocale });
  }

  getYearText(date) {
    const seireki = date.getFullYear();
    const wareki = `${String(seireki)} 年`;
    return wareki;
  }
}

const AccountSettings = ({ goBack }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialValues);
  const [selectedDate, setSelectedDate] = useState(null);
  const [image, setImage] = useState(null);
  const [success, setSuccess] = useState("");
  const [errors, setErrors] = useState({});
  const [errDate, setErrDate] = useState("");
  const user = useSelector((state) => state.user);
  const birthDayRef = useRef();

  const dispatch = useDispatch();
  const input_H = 51;

  useEffect(() => {
    const clearError = setTimeout(() => {
      setSuccess("");
    }, 3000);
    return () => clearTimeout(clearError);
  }, [success]);

  useEffect(() => {
    setValues({
      playerId: user.playerId,
      fullName: user.fullName,
      nickName: user.nickName,
      dateOfBirth: user.dateOfBirth,
      gender: user.gender,
      email: user.email,
      avatar: user.avatar,
      showOnline: user.showOnline === false ? "false" : "true",
      friendRequestAllow:
        user.friendRequestAllow ?? initialValues.friendRequestAllow,
    });
    setSelectedDate(user.dateOfBirth);
  }, [user]);

  const genderItems = [
    { id: "1", title: t("accountSettings.female") },
    { id: "2", title: t("accountSettings.male") },
    { id: "3", title: t("accountSettings.other") },
    { id: "4", title: t("accountSettings.unanswered") },
  ];

  const showOnlineItems = [
    { id: "false", title: t("status.off") },
    { id: "true", title: t("status.on") },
  ];

  const requestAllowItems = [
    { id: FRIEND_REQUEST_ALLOW.ALL_USER, title: t("status.allUser") },
    {
      id: FRIEND_REQUEST_ALLOW.FRIEND_OF_FRIENDS,
      title: t("status.friendsOfFriends"),
    },
    { id: FRIEND_REQUEST_ALLOW.NOT_ALLOW, title: t("status.notAllow") },
  ];

  const handleChangeValues = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors((err) => ({ ...err, [name]: "" }));
  };
  const handleNickNameChangeValues = (e) => {
    let { name, value } = e.target;
    value = ""
      .concat(value)
      .replace(/[^一-龠ぁ-ゔァ-ヴーa-zA-Z0-9ａ-ｚＡ-Ｚ０-９々〆〤]/gi, "");
    setValues({ ...values, [name]: value });
    setErrors({});
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    values.showOnline = values.showOnline === "false" ? false : true;
    const err = settingValidateInput(values);
    if (err) {
      let errorMessage = "";
      Object.keys(err).forEach((key, index) => {
        if (index === 0) {
          errorMessage = err[key];
          return;
        }
      });
      setErrors((prevErr) => ({ ...prevErr, message: errorMessage }));
      return;
    }
    if (err === "") {
      const newData = { ...values };
      if (selectedDate !== "") {
        newData.dateOfBirth = new Date(selectedDate).toISOString();
      } else {
        newData.dateOfBirth = "";
      }
      const res = await updateUser(
        newData,
        image,
        user.nickName,
        user.firstLogin
      );

      if (res) {
        if (
          res !== "errors.nickNameAlready" &&
          res !== "errors.nickNameOver8Characters"
        ) {
          dispatch({
            type: "SET_USER_INFO",
            payload: res,
          });
          dispatch({ type: "SET_FIRST_LOGIN" });
          setSuccess("notifications.updateSuccessfully");
          setErrors({});
          await updatePlayersFriend();
          await updateUserOfPlayTime(
            res.playerId,
            res.nickName,
            res.fullName,
            res.avatar,
            res.gender
          );
        } else {
          setErrors((error) => ({ ...error, message: res }));
        }
      }
    }
  };

  const handleDateChange = (date) => {
    const dateTemp = new Date(date);
    const newDate = moment(dateTemp).format("YYYY/MM/DD");
    setSelectedDate(newDate);
    // set focus
    if ("Invalid date" !== newDate) {
      setTimeout(() => {
        birthDayRef.current.focus();
        const value = birthDayRef.current.value;
        birthDayRef.current.value = "";
        birthDayRef.current.value = value;
      }, 0);
      setErrDate("");
    } else {
      setErrDate(t("accountSettings.invalidDate"));
    }
  };
  const minScale = useSelector((state) => state.size.minScale);
  const boxWrapperWidth = useMemo(
    () => (minScale < 0.4 ? 350 : 569),
    [minScale]
  );
  const input_W = useMemo(() => (minScale < 0.4 ? 250 : 372), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 9 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(203 * minScale * 2) : 203),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });

  return (
    <Container className={classes.root}>
      <Box className={classes.mainBox}>
        <form onSubmit={handleSubmit}>
          <Box display="flex" justifyContent="center">
            <AvatarUpload
              imageProp={image}
              setImageProp={setImage}
              avatar={user.avatar}
              provider={user.providerId}
              name="avatar"
              id="avatar"
            />
          </Box>
          <Space space={20} />
          <BoxWrapper width={boxWrapperWidth}>
            <Space space={40} />
            <Input
              value={values.fullName || ""}
              onChange={handleChangeValues}
              placeHolder={t("accountSettings.fullName")}
              name="fullName"
              type="text"
              autoFocus={true}
              isShow={true}
              maxLength={MAX_CHARACTER_NAME}
              width={input_W}
              height={input_H}
              show={true}
            />
            <Space space={20} />
            <Input
              name="nickName"
              placeHolder={t("accountSettings.nickName")}
              type="text"
              value={values.nickName || ""}
              onChange={handleNickNameChangeValues}
              error={t(errors.nickName)}
              required={true}
              isShow={true}
              maxLength={MAX_CHARACTER_NICK_NAME}
              width={input_W}
              height={input_H}
              show={true}
            />
            <MuiPickersUtilsProvider locale={jaLocale} utils={JaLocalizedUtils}>
              <KeyboardDatePicker
                // format="yyyy/MM/dd"
                format="yyyy年M月d日"
                margin="normal"
                id="date-picker-inline"
                name="dateOfBirth"
                placeholder={t("settings.dateOfBirth")}
                value={selectedDate ? selectedDate : null}
                invalidDateMessage=""
                onChange={handleDateChange}
                InputLabelProps={{
                  shrink: true,
                }}
                autoOk
                inputProps={{
                  ref: birthDayRef,
                }}
                InputProps={{
                  disableUnderline: true,
                }}
                className={classes.dateOfBirth}
                okLabel={t("paymentSettings.oke")}
                cancelLabel={t("paymentSettings.cancel")}
                DateValidato
              />
            </MuiPickersUtilsProvider>
            {errDate && (
              <ErrorMessage style={{ fontSize: 14 }} message={t(errDate)} />
            )}
            <RadioGroup
              name="gender"
              label={t("accountSettings.gender")}
              value={values.gender}
              items={genderItems}
              onChange={handleChangeValues}
            />
            <RadioGroup
              name="showOnline"
              label={t("accountSettings.showOnlineStatus")}
              value={values.showOnline}
              items={showOnlineItems}
              onChange={handleChangeValues}
            />
            <RadioGroup
              name="friendRequestAllow"
              label={t("accountSettings.friendRequestAllow")}
              value={values.friendRequestAllow}
              items={requestAllowItems}
              onChange={handleChangeValues}
              col={true}
            />
            <Input
              value={user.providerId === "password" ? user.email : values.email}
              onChange={handleChangeValues}
              type="email"
              name="email"
              placeHolder={t("common.email")}
              autoFocus={false}
              error={t(errors.email)}
              width={input_W}
              height={input_H}
            />
            <Space space={30} />
            <SuccessMessage message={t(success)} />
            {errors?.message && <ErrorMessage message={t(errors?.message)} />}
            <Box className={classes.boxButton}>
              <Button
                onClick={goBack}
                className={classes.buttonBlue}
                variant="outlined"
              >
                {t("accountSettings.back")}
              </Button>
              <Button
                onClick={handleSubmit}
                variant="contained"
                className={classes.buttonOrange}
              >
                {t("accountSettings.save")}
              </Button>
            </Box>
          </BoxWrapper>
        </form>
      </Box>
    </Container>
  );
};

export default AccountSettings;
