export function emptyMessageNarration(narrationId = "") {
  return [
    "narrationSilence",
    "narration3",
    "narration8",
    "narration10",
    "narration20_2",
    "narration35",
    "narration20_no_message",
  ].includes(narrationId);
}
