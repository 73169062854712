import PropTypes from "prop-types";
import styled from "styled-components";
import ButtonMU from "@material-ui/core/Button";

const BasicButton = styled(ButtonMU)`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  padding: 10px 15px;
  border: none;
  background-color: transparent;
  background-image: url("/assets/btn_start_game.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  color: white;
  font-size: 1.5em;
  font-weight: bold;
  cursor: pointer;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 5px;
  &:hover {
    transform: scale(1.01);
    transition: all 0.2s ease-in-out;
  }
`;

export const Button = ({ children, className, ...otherProps }) => {
  return (
    <BasicButton className={className} {...otherProps}>
      {children}
    </BasicButton>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  className: PropTypes.any,
};
