import {
  ADD_INSTRUCTOR_MESSAGE,
  ADD_INSTRUCTOR_MESSAGE_CODE,
  REMOVE_INSTRUCTOR_MESSAGE,
  REMOVE_INSTRUCTOR_MESSAGE_CODE,
  ADD_INSTRUCTOR_CONTENT,
  CLEAR_INSTRUCTOR_STATE,
} from "../types";

const initialState = {
  message: "",
  messageCode: "",
  messContent: "",
};

const instructorNotifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_INSTRUCTOR_MESSAGE:
      return {
        ...state,
        message: action.payload.message,
      };
    case ADD_INSTRUCTOR_MESSAGE_CODE:
      return {
        ...state,
        messageCode: action.payload.message,
      };
    case REMOVE_INSTRUCTOR_MESSAGE:
      return {
        ...state,
        message: "",
      };
    case REMOVE_INSTRUCTOR_MESSAGE_CODE:
      return {
        ...state,
        messageCode: "",
      };
    case ADD_INSTRUCTOR_CONTENT:
      return {
        ...state,
        messContent: action.payload.messContent,
      };
    case CLEAR_INSTRUCTOR_STATE:
      return initialState;
    default:
      return state;
  }
};

export default instructorNotifyReducer;
