import React, { useEffect } from "react";
import PropType from "prop-types";
import { useDispatch } from "react-redux";
import {
  REQUEST_RESET_OTHER_STATE_USE_MASTER_CARD,
  REQUEST_UPDATE_STATE_G_CHAOS_SELECT_MASTER_CARD,
} from "../../../../store/types";
import { POPUPS } from "../../../../store/constant";

const UpdateTurnPlayerSelectMasterCard = React.memo(function Component({
  popupId,
}) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (popupId === POPUPS.G_CHAOS_SELECT_MASTER_CARD) {
      dispatch({ type: REQUEST_UPDATE_STATE_G_CHAOS_SELECT_MASTER_CARD });
    }
    if (popupId === POPUPS.RESET_OTHER_STATE_USE_MASTER_CARD) {
      dispatch({ type: REQUEST_RESET_OTHER_STATE_USE_MASTER_CARD });
    }
  }, [popupId, dispatch]);
  return <></>;
});

UpdateTurnPlayerSelectMasterCard.propTypes = {
  stepId: PropType.string,
  narrationId: PropType.string,
  popupId: PropType.string,
};

export default UpdateTurnPlayerSelectMasterCard;
