import { Avatar } from "@material-ui/core";
import PropTypes from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import {
  ChatContainer,
  ChatItemContainer,
  ChatNickName,
  ChatText,
  TimeSpan,
  ChatWrapper,
  AvatarWrapper,
} from "./styles";

const ChatItem = (props) => {
  const { messageData, id } = props;
  const { message, avatar, time, nickName, playerId } = messageData;
  let isMine = playerId === id ? true : false;
  const minscale = useSelector((state) => state.size.minScale);

  const tip = isMine ? `text-align: left;` : `text-align: right;`;
  const namePosition = isMine
    ? "align-self: flex-end"
    : "align-self: flex-start";

  return (
    <ChatItemContainer isMine={isMine} minscale={minscale}>
      {!isMine ? (
        <AvatarWrapper minscale={minscale}>
          <Avatar
            style={{
              width: `${
                minscale > 0.5 ? `${minscale * 45}px` : `${0.5 * 45}px`
              }`,
              height: `${
                minscale > 0.5 ? `${minscale * 45}px` : `${0.5 * 45}px`
              }`,
            }}
            alt={nickName}
            src={avatar ?? ""}
          />
        </AvatarWrapper>
      ) : null}
      <ChatWrapper>
        <ChatNickName namePosition={namePosition} minscale={minscale}>
          {nickName}
        </ChatNickName>
        <ChatContainer>
          <ChatText
            isMine={isMine}
            minscale={minscale}
          >{`${message}`}</ChatText>
          <TimeSpan tip={tip} minscale={minscale}>
            {time}
          </TimeSpan>
        </ChatContainer>
      </ChatWrapper>
    </ChatItemContainer>
  );
};

ChatItem.propTypes = {
  messageData: PropTypes.object,
  id: PropTypes.string,
};
export default React.memo(ChatItem);
