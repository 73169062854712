import firebase from "../../services/firebase";
import { isEmpty } from "lodash";
const ROOMS = "rooms";
const roomCollection = firebase.firestore().collection(ROOMS);

/**
 * get data by docId
 * @param {string} docId
 * @returns
 */
async function read(docId = "") {
  return (await roomCollection.doc(docId).get()).data();
}

/**
 * get all players data
 * @returns
 */
async function readAll() {
  return roomCollection.get();
}

/**
 * update data by docId
 * @param {string} docId
 * @param {object} data
 * @returns
 */
async function update(docId = "", data = {}) {
  if (!docId || isEmpty(data)) return;
  return roomCollection.doc(docId).update(data);
}

async function deleteRoom(docId) {
  if (docId) {
    return roomCollection.doc(docId).delete();
  }
}

export default {
  read,
  readAll,
  update,
  deleteRoom,
};
