import {
  CARD_VALUE,
  CARD_SUIT_G,
  LIMIT_TIME,
  GAME_STATUS,
  G_CHAOS_PLAY_CARD_DELAY_TIME,
  G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST,
} from "../store/constant";
import { isGChaosPlayFirst, nextIsChaos } from "./common";
export const isStarCard = (card) => {
  return card.value >= CARD_VALUE.TEN || card.suit === CARD_SUIT_G;
};
export const getStarCards = (cards) => {
  return cards.filter((card) => {
    return isStarCard(card);
  });
};
export const delayShowMasterCardsTime = (currentState, newState) => {
  return currentState.grandCross.length === 6 &&
    newState.grandCross.length === 4
    ? LIMIT_TIME.DELAY_SHOW_MASTER_CARDS
    : 0;
};
export const delayOpenCardsLastRoundTime = (currentState, newState) => {
  return newState.currentRound === 13 && !newState.grandCross.includes(null)
    ? LIMIT_TIME.DELAY_OPEN_CARDS_LAST_ROUND
    : 0;
};
export const delayEndGameTime = (currentState, newState) => {
  return currentState.currentRound != 0 && newState.currentRound === 0
    ? LIMIT_TIME.DELAY_END_GAME
    : 0;
};
export const delayNormalTurnTime = (currentState, newState) => {
  return (!currentState.grandCross.includes(null) &&
    newState.grandCross.every((ele) => ele === null) &&
    !isLoseOrWin(currentState, newState)) ||
    isGChaosPlayFirst(newState.grandCross)
    ? LIMIT_TIME.DELAY_NORMAL_TURN
    : 0;
};
export const isLoseOrWin = (currentState, newState) => {
  return (
    currentState.gameStatus === GAME_STATUS.PLAYING &&
    [GAME_STATUS.LOSE, GAME_STATUS.WIN, GAME_STATUS.END].includes(
      newState.gameStatus
    )
  );
};

const checkGChaosPlayLast = (currentState, newState) => {
  const currentGrandCross = [...currentState.grandCross];
  const newGrandCross = [...newState.grandCross];
  const isCurrentMissingOnlyGChaos =
    currentGrandCross[0] !== null &&
    currentGrandCross[1] !== null &&
    currentGrandCross[2] !== null &&
    currentGrandCross[3] === null;
  const isNewChaosAppear = newGrandCross[3] !== null;
  return isCurrentMissingOnlyGChaos && isNewChaosAppear;
};

const checkGChaosAfterCaptain = (currentState, newState) => {
  const currentGrandCross = [...currentState.grandCross];
  const newGrandCross = [...newState.grandCross];
  const masterIsBeforeGChaosPlayed =
    currentGrandCross[0] === null &&
    currentGrandCross[1] === null &&
    currentGrandCross[2] !== null &&
    currentGrandCross[3] === null;
  const newGrandCrossGchaosPlayed =
    newGrandCross[0] === null &&
    newGrandCross[1] === null &&
    newGrandCross[2] !== null &&
    newGrandCross[3] !== null;
  return masterIsBeforeGChaosPlayed && newGrandCrossGchaosPlayed;
};
export const delayGChaosPlayCard = () => {
  let isNextGChaos = false;
  return (currentState, newState) => {
    const grandCross = newState.grandCross;
    if (isNextGChaos === true) {
      isNextGChaos = nextIsChaos(grandCross);
      if (checkGChaosPlayLast(currentState, newState) === true) {
        return G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST;
      }
      return checkGChaosAfterCaptain(currentState, newState) === true
        ? G_CHAOS_PLAY_CARD_DELAY_TIME
        : G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST;
    } else {
      isNextGChaos = nextIsChaos(grandCross);
      return 0;
    }
  };
};
// const checkToDelayGChaosPlayCard = delayGChaosPlayCard();
export const getGrandCrossDelayTime = (currentState, newState) => {
  const timeDelay = [
    delayShowMasterCardsTime(currentState, newState),
    delayOpenCardsLastRoundTime(currentState, newState),
    delayEndGameTime(currentState, newState),
    delayNormalTurnTime(currentState, newState),
    // checkToDelayGChaosPlayCard(currentState, newState),
  ];
  const time = Math.max(...timeDelay);
  return time === undefined ? 0 : time;
};
