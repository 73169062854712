import { getFunctions } from "../../services/firebase";
import {
  DELETE_INVOICE_BY_ID,
  GET_ALL_INVOICE,
  GET_INVOICE_LIST_PAGINATION,
  GET_INVOICE_LIST_PAGINATION_BY_PLAYER,
  UPDATE_INVOICE,
  GET_URL_RECEIPT,
} from "../funcs";

const functions = getFunctions();

export const descendingComparator = (a, b, orderBy) => {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
};

export const getComparator = (_order, _orderBy) => {
  return _order === "desc"
    ? (a, b) => descendingComparator(a, b, _orderBy)
    : (a, b) => -descendingComparator(a, b, _orderBy);
};

export const stableSort = (array, comparator) => {
  if (array.length > 0) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const _order = comparator(a[0], b[0]);
      if (_order !== 0) {
        return _order;
      }
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
};

export const getAllInvoices = async () => {
  try {
    const getAllInvoicesFunc = functions.httpsCallable(GET_ALL_INVOICE);
    return getAllInvoicesFunc({});
  } catch (e) {
    console.error(e);
  }
};

export const deleteInvoiceById = async (invoiceId) => {
  try {
    const deleteInvoiceByIdFUNC = functions.httpsCallable(DELETE_INVOICE_BY_ID);
    return deleteInvoiceByIdFUNC({ invoiceId });
  } catch (e) {
    console.error(e);
  }
};

// eslint-disable-next-line no-unused-vars
export const updateInvoiceById = async (newInvoice) => {
  try {
    const updateInvoiceByIdFUNC = functions.httpsCallable(UPDATE_INVOICE);
    return updateInvoiceByIdFUNC({ newInvoice });
  } catch (e) {
    console.error(e);
  }
};

export const getInvoiceListPagination = async (
  orderByField,
  keySearch = "",
  order = true,
  page = 0,
  limit = 5
) => {
  try {
    const getInvoiceListPaginationFUNC = functions.httpsCallable(
      GET_INVOICE_LIST_PAGINATION
    );
    return getInvoiceListPaginationFUNC({
      page,
      limit,
      orderByField,
      order,
      keySearch,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getInvoiceListPaginationByPlayer = async (
  playerId,
  orderByField,
  order = true,
  page = 0,
  limit = 5
) => {
  try {
    const getInvoiceListPaginationByPlayerFUNC = functions.httpsCallable(
      GET_INVOICE_LIST_PAGINATION_BY_PLAYER
    );
    return getInvoiceListPaginationByPlayerFUNC({
      playerId,
      page,
      limit,
      orderByField,
      order,
    });
  } catch (e) {
    console.error(e);
  }
};

export const getUrlReceipt = async (url) => {
  try {
    const func = functions.httpsCallable(GET_URL_RECEIPT);
    return func({ url });
  } catch (e) {
    console.error(e);
  }
};
