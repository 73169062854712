/* eslint-disable no-unused-vars */

import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Button from "../../../../../components/Button";
import Input from "../../../../../components/Input";
import SuccessMessage from "../../../../../components/SuccessMessage";
import { updatePassword } from "../../../../../store/actions/authActions";
import { changePasswordValidateInput } from "../../../../../utils/validation";
import useStyles, { BoxWrapper } from "./styles";

const initialState = {
  currentPassword: "",
  newPassword: "",
  newPasswordConfirm: "",
};

export const ErrorText = styled.p`
  color: rgb(220, 50, 51);
  font-weight: 600;
  width: 376px;
  font-size: 14px;
  margin: auto;
`;

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ChangePassDialog = ({ handleClose, open }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialState);
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const adminEmail = useSelector((state) => state.admin.adminEmail);
  const minScale = useSelector((state) => state.size.minScale);
  const boxWrapperWidth = useMemo(
    () => (minScale < 0.4 ? 350 : 569),
    [minScale]
  );
  const input_W = useMemo(() => (minScale < 0.4 ? 250 : 372), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 11 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(203 * minScale * 2) : 203),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H: 51,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (errorMessage !== "" || success !== "" || errors !== "") {
      setErrors("");
      setErrorMessage("");
      setSuccess("");
    }
  };

  const handleUpdatePassword = async () => {
    const err = changePasswordValidateInput(values);
    if (err === "") {
      const result = await updatePassword(adminEmail, values);
      if (result === "auth/wrong-password") {
        setErrorMessage(t("errors.currentPasswordIsInCorrect"));
      }
      if (result === "auth/too-many-requests") {
        setErrorMessage(t("errors.tooManyRequests"));
      }
      if (result === "") {
        setSuccess(t("notifications.updatePasswordSuccess"));
        setErrorMessage("");
      }
    }
    setErrors(err);
  };
  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setErrors("err");
        handleClose();
      }}
      aria-describedby="dialog-change-pass"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        sx={{
          color: "#541513",
          textAlign: "center",
          fontWeight: "bold",
          fontSize: "30px",
        }}
      >
        {t("changePassword.changePassword")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="dialog-change-pass-content">
          <Box className={classes.mainBox}>
            <form className={classes.form}>
              <BoxWrapper width={boxWrapperWidth}>
                <Space space={30} />
                <Input
                  autoFocus={true}
                  name="currentPassword"
                  placeHolder={t("changePassword.currentPassword")}
                  type="password"
                  value={values.currentPassword}
                  onChange={handleChange}
                  InputProps={true}
                  width={input_W}
                  height={51}
                />
                <Space space={10} />
                {errors.currentPassword && (
                  <>
                    <Space space={10} />
                    <ErrorText>{t(errors.currentPassword)}</ErrorText>
                    <Space space={10} />
                  </>
                )}
                <Space space={10} />
                <Input
                  name="newPassword"
                  placeHolder={t("changePassword.newPassword")}
                  type="password"
                  value={values.newPassword}
                  onChange={handleChange}
                  error={t(errors.newPassword)}
                  InputProps={true}
                  width={input_W}
                  height={51}
                />
                <Space space={10} />
                {errors.newPassword && (
                  <>
                    <Space space={10} />
                    <ErrorText>{t(errors.newPassword)}</ErrorText>
                    <Space space={10} />
                  </>
                )}
                <Space space={10} />
                <Input
                  name="newPasswordConfirm"
                  placeHolder={t("changePassword.newPasswordConfirm")}
                  type="password"
                  value={values.newPasswordConfirm}
                  onChange={handleChange}
                  error={t(errors.newPasswordConfirm)}
                  InputProps={true}
                  width={input_W}
                  height={51}
                />
                <Space space={10} />
                {errors.newPasswordConfirm && (
                  <>
                    <Space space={10} />
                    <ErrorText>{t(errors.newPasswordConfirm)}</ErrorText>
                    <Space space={10} />
                  </>
                )}
                <SuccessMessage message={t(success)} />
                {errorMessage !== "" && <ErrorText>{errorMessage}</ErrorText>}
                <Box className={classes.boxButton}>
                  <Button
                    onClick={() => {
                      setErrors("err");
                      handleClose();
                    }}
                    variant="outlined"
                    className={classes.buttonBlue}
                    background="#7d7d7d"
                    text={t("tableSettings.back")}
                  />
                  <Button
                    onClick={handleUpdatePassword}
                    variant="contained"
                    className={classes.buttonOrange}
                    background="#ce5931"
                    text={t("changePassword.update")}
                  />
                </Box>
              </BoxWrapper>
            </form>
          </Box>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
};

ChangePassDialog.propTypes = {
  handleClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
};

export default ChangePassDialog;
