import PropTypes from "prop-types";
import React, { useState, useEffect, useMemo } from "react";
import HorizontalBottomCard from "./HBottomStarCards";
import { DotStyle, StarCardsContainer } from "./styles";
import { useSelector } from "react-redux";
// import { STD_SIZE } from "../../../../../store/constant";
import { cardGapWidthCalculator } from "../../../../../utils/calculations";
import { TUTORIAL_MODE } from "../../../../../store/constant";

const BottomStarCards = ({ cards, numberOfCalls }) => {
  const size = useSelector((state) => state.size);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const hShipWidth = useSelector((state) => state.size.hShipWidth);
  const cardWidth = useSelector((state) => state.size.cardWidth);
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );
  const [innerSize, setInnerSize] = useState({});
  const eveIndex = numberOfCalls - 2;
  const [gapWidth, margin] = useMemo(() => {
    return cardGapWidthCalculator(
      shipSpace,
      cards?.length || 0,
      hShipWidth,
      cardWidth
    );
  }, [cards, hShipWidth, cardWidth, shipSpace]);

  useEffect(() => {
    if (size) {
      const isEve1Card = numberOfCalls == 2 && cards.length >= 1;
      const isBreakThrough1Call = numberOfCalls == 1 && cards.length == 1;
      const isEve1CardAdd = isEve1Card ? shipSpace * 2 : 0;
      setInnerSize({
        isEve1CardAdd,
        isEve1Card,
        isBreakThrough1Call,
      });
    } else {
      const isEve1Card = numberOfCalls == 2 && cards.length == 1;
      const isBreakThrough1Call = numberOfCalls == 1 && cards.length == 1;
      const isEve1CardAdd = isEve1Card ? shipSpace : 0;

      setInnerSize({
        isEve1CardAdd,
        isEve1Card,
        isBreakThrough1Call,
      });
    }
  }, [size, cards, numberOfCalls, shipSpace]);

  const getPositionCard = (idx, over_get_num_gap) => {
    const positionCardNoCall = Math.round(
      idx * gapWidth + margin * 2 + (idx == 0 ? innerSize.isEve1CardAdd : 0)
    );
    if (tutorialMode === TUTORIAL_MODE.NO_CALL) {
      return positionCardNoCall;
    }
    return idx < numberOfCalls
      ? positionCardNoCall
      : Math.round(
          hShipWidth -
            (over_get_num_gap - (idx - numberOfCalls)) * gapWidth -
            cardWidth -
            margin
        );
  };

  return (
    <StarCardsContainer>
      <DotStyle src="/assets/h_dot.png" />
      {cards?.map((card, idx) => {
        const over_get_num_gap =
          cards.length - numberOfCalls > 0
            ? cards.length - numberOfCalls - 1
            : 0;
        return (
          <HorizontalBottomCard
            key={idx}
            src={`/cards/${card.suit}${card.value}.jpg`}
            position={"bottom"}
            zIndex={idx * 100}
            marginLeft={getPositionCard(idx, over_get_num_gap)}
            isRotate={idx === eveIndex}
            isEve1Card={numberOfCalls == 2 && cards.length == 1}
            size={size}
            isBreakThrough1Call={innerSize.isBreakThrough1Call}
          />
        );
      })}
    </StarCardsContainer>
  );
};

BottomStarCards.propTypes = {
  cards: PropTypes.array,
  numberOfCalls: PropTypes.number,
};

export default BottomStarCards;
