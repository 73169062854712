import {
  Box,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Button from "../../../../../components/Button";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Input from "../../../../../components/Input";
import {
  createAdmin,
  getAdminList,
} from "../../../../../store/actions/adminAction";
import { registerValidateInput } from "../../../../../utils/validation";
import useStyles, { BoxWrapper, Space, TitleWrapper } from "./styles";

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const initialState = {
  address: "",
  userName: "",
  password: "",
  error: {
    password: "",
  },
};

const DialogAddAdmin = ({ open, handleClose, setRows, handleLoading }) => {
  const { t } = useTranslation();
  const [values, setValues] = useState({
    address: "example",
    userName: "example",
    password: "example",
    error: {
      password: "example",
    },
  });
  const [errors, setErrors] = useState("");
  const [responseError, setResponseError] = useState("");
  const minScale = useSelector((state) => state.size.minScale);
  const boxWrapperWidth = useMemo(
    () => (minScale < 0.4 ? 250 : 500),
    [minScale]
  );
  const input_W = useMemo(() => (minScale < 0.4 ? 200 : 250), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 11 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(203 * minScale * 2) : 203),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H: 51,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });

  useEffect(() => {
    const clearError = setTimeout(() => {
      setResponseError("");
    }, 10000);

    return () => clearTimeout(clearError);
  }, [responseError]);

  useEffect(() => {
    let timeId = setTimeout(() => {
      setValues(initialState);
      clearTimeout(timeId);
    }, 1000);
    return () => clearTimeout(timeId);
  }, [open]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    setErrors((err) => ({ ...err, [name]: "" }));
  };

  const handleCreateAdmin = async () => {
    handleLoading(true);
    const err = registerValidateInput(values);
    if (err === "") {
      const res = await createAdmin(values);
      if (res === "") {
        const adminData = await getAdminList();
        setRows(adminData.data);
        toast.success(t("admin.createAdminSuccess"));
        setValues(initialState);
        handleClose();
      }
      setResponseError(t(res));
    }
    setErrors(err);
    handleLoading(false);
  };

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setErrors("err");
        setValues(initialState);
        handleClose();
      }}
      aria-describedby="dialog-change-pass"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        style={{ fontSize: "30px", alignItems: "center", textAlign: "center" }}
      >
        {t("admin.addAdminTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box className={classes.mainBox}>
            <BoxWrapper width={boxWrapperWidth}>
              <Space space={10} />
              <TitleWrapper
                style={{ display: "flex", justifyContent: "center" }}
              >
                <div>
                  <form className={classes.form}>
                    <div className={classes.field}>
                      <DialogTitle>{t("admin.accountNameTitle")}</DialogTitle>
                      <Input
                        name="userName"
                        placeHolder={t("admin.newNamePlaceholder")}
                        type="text"
                        value={values.userName}
                        onChange={handleChange}
                        width={input_W}
                        height={51}
                        show={true}
                        autoFocus={true}
                      />
                    </div>
                    {errors.userName && (
                      <p style={{ textAlign: "center", color: "red" }}>
                        {t(errors.userName)}
                      </p>
                    )}
                    <div className={classes.field}>
                      <DialogTitle>{t("admin.password")}</DialogTitle>
                      <Input
                        name="password"
                        placeHolder={t("admin.newPasswordPlaceholder")}
                        type="password"
                        value={values.password}
                        onChange={handleChange}
                        error={t(errors.password)}
                        InputProps={true}
                        width={input_W}
                        height={51}
                        style={{ fontSize: "12px !important" }}
                      />
                    </div>
                    {errors.password && (
                      <p style={{ textAlign: "center", color: "red" }}>
                        {t(errors.password)}
                      </p>
                    )}
                    <div className={classes.field}>
                      <DialogTitle>{t("admin.emailTitle")}</DialogTitle>
                      <Input
                        name="address"
                        placeHolder={t("admin.newEmailPlaceholder")}
                        type="text"
                        value={values.address}
                        onChange={handleChange}
                        width={input_W}
                        height={51}
                        show={true}
                        error={t(errors.email)}
                      />
                    </div>
                    {errors.email && (
                      <p style={{ textAlign: "center", color: "red" }}>
                        {t(errors.email)}
                      </p>
                    )}
                  </form>
                </div>
              </TitleWrapper>
            </BoxWrapper>
          </Box>
        </DialogContentText>
      </DialogContent>
      {responseError ? (
        <ErrorMessage message={responseError} />
      ) : (
        <div>&nbsp;</div>
      )}

      <Box className={classes.boxButton}>
        <Button
          onClick={() => {
            setValues(initialState);
            handleClose();
          }}
          variant="outlined"
          className={classes.buttonBlue}
          background="#7d7d7d"
          text={t("admin.cancel")}
        />
        <Button
          onClick={handleCreateAdmin}
          variant="contained"
          className={classes.buttonOrange}
          background="#ce5931"
          text={t("admin.ok")}
        />
      </Box>
    </Dialog>
  );
};

DialogAddAdmin.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  setRows: PropTypes.func,
  handleLoading: PropTypes.func,
};

export default DialogAddAdmin;
