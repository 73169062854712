import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import { makeStyles } from "@material-ui/core/styles";
import useOnlineStatus from "@rehooks/online-status";
import debounce from "lodash.debounce";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "./App.css";
import PrivateRoute from "./components/PrivateRoute";
import PublicRoute from "./components/PublicRoute";
import PageNotFound from "./pages/404Page";
import AdminRoute from "./pages/Admin";
import {
  AdminList,
  Invoices,
  Login as AdminLogin,
  UserList,
} from "./pages/Admin/components";
import AdminPublicRoute from "./pages/Admin/components/AdminPublicRoute";
import Contact from "./pages/Contact";
import ContactSuccess from "./pages/ContactSuccess";
import ForgotPassword from "./pages/ForgotPassword";
import Friends from "./pages/Friends";
import ChatBoxModal from "./pages/GameScreen/components/chatbox/components/ChatBoxModal";
import LoadCard from "./pages/GameScreen/components/LoadCard";
import ReloadNetworkNG from "./pages/GameScreen/components/NewDialog/ReloadNetworkNG";
import { GameScreen } from "./pages/GameScreen/GameScreen";
import Home from "./pages/Home";
import { HomeScreen } from "./pages/HomeScreen/HomeScreen";
import Login from "./pages/Login";
import Notifications from "./pages/Notifications";
import PaymentStatus from "./pages/PaymentSettings/paymentStatus";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import Register from "./pages/Register";
import Rules from "./pages/Rules";
import Settings from "./pages/Settings";
import Tables from "./pages/Tables";
import Termofuse from "./pages/Termofuse";
import firebase from "./services/firebase";
import { subscribeJoinRoom } from "./services/initFCM";
import { subscribeConnect } from "./store/actions/friendAction";
import {
  CLOSE_DIALOG_OVER_PLAY_TIME,
  SCREEN_RESIZE,
  SET_STATUS_ONLINE,
  SET_USER_INFO,
} from "./store/types";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
}));

let connectedRef = firebase.database().ref(".info/connected");

function App() {
  const classes = useStyles();
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const isOpenChatModal = useSelector((state) => state.chat.isOpenChatModal);
  const isLoading = useSelector((state) => state.size.isLoading ?? false);
  const isBuyingLoading = useSelector((state) => state.payment.isBuyingLoading ?? false);
  const onlineDB = useSelector((state) => state.user.isOnline);
  const playerId = useSelector((state) => state.user.playerId);
  const onlineBrowser = useOnlineStatus();
  const [isOpenDialogReload, setIsOpenDialogReload] = useState(false);
  const [countdown, setCountDown] = useState(-1);
  const intervalRef = useRef(null);
  const counterInterval = useRef(0);
  const { t } = useTranslation();

  const clearRecheckConnection = () => {
    setIsOpenDialogReload(false);
    setCountDown(-1);
    clearInterval(intervalRef.current);
    intervalRef.current = null;
    counterInterval.current = 0;
    if (!playerId) return;
    const isOnlineRtdb = {
      isOnline: true,
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    };
    firebase
      .database()
      .ref("/status/" + playerId)
      .set(isOnlineRtdb);
  };

  useEffect(() => {
    const pollOnlineStatus = () => {
      if (!onlineDB || !onlineBrowser) {
        console.log("Retry connect");
        intervalRef.current = setInterval(() => {
          ++counterInterval.current;
          connectedRef.on("value", (snap) => {
            if (snap.val() === true) {
              clearRecheckConnection();
              dispatch({ type: SET_STATUS_ONLINE, payload: true });
            } else {
              if (counterInterval.current >= 15) {
                console.log("Reload Network");
                location.reload();
                return;
              }
              if (counterInterval.current >= 10) {
                console.log("Hon 10 giay offline");
                setCountDown(15 - counterInterval.current);
                setIsOpenDialogReload(true);
              }
            }
          });
        }, 1000);
      } else {
        clearRecheckConnection();
      }
    };
    pollOnlineStatus();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onlineBrowser, onlineDB]);

  useEffect(() => {
    const width = window.innerWidth;
    const height = window.innerHeight;
    dispatch({
      type: SCREEN_RESIZE,
      payload: {
        width,
        height,
      },
    });
    function handleResize() {
      const width = window.innerWidth;
      const height = window.innerHeight;
      dispatch({
        type: SCREEN_RESIZE,
        payload: {
          width,
          height,
        },
      });
    }

    window.addEventListener("resize", debounce(handleResize, 500));
  }, [dispatch]);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        const userSubscribe = firebase
          .firestore()
          .collection("players")
          .doc(user.uid)
          .onSnapshot((doc) => {
            if (doc.exists) {
              dispatch({
                type: SET_USER_INFO,
                payload: doc.data(),
              });
            }
          });
        dispatch({
          type: "SET_SUBSCRIBE",
          payload: { subscribe: userSubscribe },
        });
        dispatch(subscribeJoinRoom);
      }
      setLoading(false);
    });
    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    let unsubscribe = () => {};
    dispatch(subscribeConnect((func) => (unsubscribe = func)));
    return () => unsubscribe();
  }, [dispatch]);

  useEffect(() => {
    localStorage.setItem("onlineStatus", JSON.stringify([]));
    localStorage.setItem("isPlaying", JSON.stringify(false));
  }, []);

  useEffect(() => {
    dispatch({ type: CLOSE_DIALOG_OVER_PLAY_TIME, payload: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BrowserRouter>
      <Backdrop className={classes.backdrop} open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        className={classes.backdrop}
        open={isLoading}
        style={{ zIndex: 999999 }}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Backdrop
        className={classes.backdrop}
        open={isBuyingLoading}
        style={{ zIndex: 999999, display: "flex", flexDirection: "column" }}
      >
        <CircularProgress color="inherit" />
        <div style={{ padding: "50px" }}>{t("loading.isBuyingLoading")}</div>
      </Backdrop>
      <ToastContainer />

      <LoadCard isLoaded={true} />
      <ChatBoxModal isOpenChatModal={isOpenChatModal} />
      {isOpenDialogReload && countdown >= 0 && (
        <ReloadNetworkNG isOpen={isOpenDialogReload} countdown={countdown} />
      )}
      {/* <LoadAllNarration onLoad={false} pushNarration={pushNarration} /> */}
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/home" component={HomeScreen} />
        <Route path="/notifications" component={Notifications} />
        <Route path="/forgotPassword" component={ForgotPassword} />
        <Route path="/rules" component={Rules} />
        <Route path="/payments/setup-complete" component={PaymentStatus} />
        <PublicRoute path="/register" component={Register} />
        <PublicRoute path="/login" component={Login} />
        <PrivateRoute path="/tables" component={Tables} />
        <PrivateRoute path="/settings" component={Settings} />
        <PrivateRoute path="/friends" component={Friends} />
        <PrivateRoute path="/game/:gameId" component={GameScreen} />
        <Route path="/contact" component={Contact} />
        <Route path="/contact-success" component={ContactSuccess} />
        <Route path="/termofuse" component={Termofuse} />
        <Route path="/privacy-policy" component={PrivacyPolicy} />

        {/* Admin */}
        <AdminPublicRoute path="/admin-login" component={AdminLogin} />
        <AdminRoute exact path="/admin" component={AdminList} />
        <AdminRoute path="/admin/user-list" component={UserList} />
        <AdminRoute path="/admin/admin-list" component={AdminList} />
        <AdminRoute path="/admin/invoices" component={Invoices} />
        <Route path="*" component={PageNotFound} />
      </Switch>
    </BrowserRouter>
  );
}

export default App;
