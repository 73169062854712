import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  root: {
    margin: 0,
    padding: 0,
    width: "100vw",
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    boxSizing: "border-box",
    paddingBottom: "2em",
  },
  settings: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    marginTop: "3%",
  },
  goBackWrap: {
    position: "fixed",
    bottom: "0%",
  },
  goBack: {
    margin: "10px 0",
    padding: "5px 15px",
    textTransform: "initial",
    alignSelf: "flex-end",
    color: "white",
  },
  tabs: {
    // display: "inline-block",
    borderRight: `1px solid ${theme.palette.divider}`,
    width: (props) => `${props.tabsWidth}px`,
    height: (props) => `${props.tabsHeight}px`,
    marginTop: "10%",
    backgroundColor: "#e8e1d5",
    borderRadius: "40px",
    boxSizing: "border-box",

    "& button": {
      height: 30,
      backgroundColor: "#e8e1d5",
      color: "#541513",
      fontSize: 14,
      fontWeight: 500,
    },
    "& button.Mui-selected": {
      backgroundColor: "#f4cbc1",
    },
    "& button:nth-child(1)": {
      marginTop: 35,
    },

    textAlign: "center",
    justifyItems: "center",
  },
  indicator: {
    left: 0,
    width: 5,
    backgroundColor: "#ee4100",
  },
  accountSettings: {
    flexGrow: 2,
    display: "flex",
    height: "90%",
  },
  singleTab: {
    textTransform: "capitalize",
    color: "black",

    backgroundColor: "#eecdc2",
    justifyContent: "center",
    textAlign: "left",
    paddingLeft: (props) => `${props.paddingLeft}px`,

    "& span": {
      fontSize: (props) => `${props.fonSize12}px`,
      fontWeight: "600",
      display: "block",
      paddingLeft: (props) => `${props.fonSize12}px`,
    },
  },
  timeRemainClockBox: {
    right: "2%",
    width: "200px",
    bottom: "2%",
    position: "fixed",
    zIndex: 10,
  },
}));

export default useStyles;
