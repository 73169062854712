// Spacing
export const BORDER_RADIUS = "0.7em";

// Colors
export const COLOR_BROWN = "#542e14";
export const COLOR_WHITE = "#ffffff";
export const COLOR_DARK_GREEN = "#d1e066";
export const COLOR_BLACK = "#000000";
export const COLOR_YELLOW_LIGHT = "#f9e9b9";
export const COLOR_BLUE_LIGHT = "#61baea";
export const COLOR_YELLOW = "#ecbd2a";
export const COLOR_GREY_LIGHT = "#f7f7f7";
export const COLOR_BROWN_LIGHT = "#cca98e";
export const COLOR_GREY = "#e6e6e7";
export const COLOR_ORANGE = "#e95316";
