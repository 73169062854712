import React from "react";
import { makeStyles } from "@material-ui/core";
import { useSelector } from "react-redux";

const useStyles = makeStyles(() => ({
  menuTopRoot: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-end",
    width: "100%",
    alignItems: "center",
  },
}));

const MenuTop = React.memo(function MenuTopComponent() {
  const classes = useStyles();
  // const minScale = useSelector((state) => state.size.minScale);
  const logoWidth = useSelector((state) => state.size.logoWidth);
  const logoHeight = useSelector((state) => state.size.logoHeight);

  return (
    <div className={classes.menuTopRoot} id="gc-menu-top">
      <div
        style={{
          width: logoWidth,
          height: logoHeight,
          background: `url("/assets/game_logo.png")`,
          backgroundRepeat: "no-repeat",
          backgroundSize: "100% 100%",
        }}
      />
    </div>
  );
});

export default MenuTop;
