import React from "react";
import PropTypes from "prop-types";

const HTopStarCards = ({
  src,
  zIndex,
  marginLeft,
  isRotate,
  size,
  isBreakThrough1Call,
  // isEve1Card,
}) => {
  const left = isBreakThrough1Call
    ? `calc(50% - ${Math.round(size?.cardWidth / 2)}px)`
    : `${marginLeft}px`;

  const rotate = `rotate(${0 + (isRotate ? 90 : 0)}deg)`;
  const translate = `translate(-${
    isRotate ? Math.round(size?.cardHeight / 2 - size?.cardWidth / 2) : 0
  }px,0)`;
  return (
    <div
      style={{
        width: size?.cardWidth,
        height: size?.cardHeight,
        borderRadius: "4%",
        transform: `${rotate} ${translate}`,
        zIndex: zIndex,
        position: "absolute",
        left: left,
        boxShadow: "0 0 3px black",
      }}
    >
      <img
        src={src}
        style={{
          objectFit: "fill",
          borderRadius: "4%",
          width: "100%",
          height: "100%",
        }}
      />
    </div>
  );
};
HTopStarCards.propTypes = {
  src: PropTypes.elementType,
  zIndex: PropTypes.number,
  marginLeft: PropTypes.any,
  size: PropTypes.any,
  isRotate: PropTypes.bool,
  isEve1Card: PropTypes.bool,
  numberOfCalls: PropTypes.number,
  isBreakThrough1Call: PropTypes.bool,
};

export default HTopStarCards;
