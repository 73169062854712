import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow } from "@mui/material";
import useStyles from "./styles";

const HeaderCells = ({ headCells, orderBy, order }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
          >
            {headCell.label}
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

HeaderCells.propTypes = {
  headCells: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  createSortHandler: PropTypes.func,
};

export default HeaderCells;
