import { Box } from "@material-ui/core";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  Button,
  IconButton,
  Pagination,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useTranslation } from "react-i18next";
import { BiSearchAlt } from "react-icons/bi";
import { FaEdit, FaUserPlus } from "react-icons/fa";
import { AiOutlineDelete } from "react-icons/ai";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  deleteAdmin,
  getAdminList,
} from "../../../../store/actions/adminAction";
import DialogAddAdmin from "../../components/Dialog/DialogAddAdmin";
import DialogDelete from "../../components/Dialog/DialogDelete";
import DialogEditAdmin from "../../components/Dialog/DialogEditAdmin";
import HeaderCells from "./components/HeaderCells";
import useStyles from "./styles";
import useDocumentTitle from "../../../../hooks/useDocumentTitle";

const AdminList = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const inputRef = useRef();
  const pageSize = useMemo(() => 7, []);
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("invoiceNumber");
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState({});
  const [selectAdmin, setSelectAdmin] = useState({});
  const [openAddAdmin, setOpenAddAdmin] = useState(false);
  const [openEditAdmin, setOpenEditAdmin] = useState(false);
  const [openDeleteAdmin, setOpenDeleteAdmin] = useState(false);
  const [term, setTerm] = useState("");
  const adminId = useSelector((state) => state.admin.adminId);

  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [fetch, setFetch] = useState([]);

  useDocumentTitle(t("common.gConceptTitle") + t("common.adminListTitle"));

  const totalPage = useMemo(
    () => Math.floor((rows?.length - 1) / pageSize) + 1,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rows, pageSize]
  );

  const headCells = useMemo(
    () => [
      { id: "No", label: `${t("admin.no")}` },
      { id: "Name", label: `${t("admin.name")}` },
      { id: "Email", label: `${t("admin.email")}` },
      { id: "", label: `${t("")}` },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [localStorage.getItem("i18nextLng")]
  );

  const handleRequestSort = (_event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const createSortHandler = (property) => (event) => {
    handleRequestSort(event, property);
  };

  const handleChangePage = (_event, newPage) => {
    setPage(newPage - 1);
  };

  const handleCloseAddAdmin = useCallback(() => {
    setOpenAddAdmin(false);
  }, []);

  const handleCloseDelete = useCallback(() => {
    setOpenDeleteAdmin(false);
  }, []);

  const handleGetAdmin = async () => {
    const adminData = await getAdminList();
    setRows(adminData.data);
  };

  const handleClickAdmin = async (adminData) => {
    setSelectAdmin(adminData);
    setOpenDeleteAdmin(true);
  };

  const handleDelete = async () => {
    setOpenDeleteAdmin(false);
    setIsLoading(true);
    if (selectAdmin.playerId === "") return;
    const resulf = await deleteAdmin(selectAdmin?.playerId);
    if (resulf) {
      toast.success(t("admin.deleteAdminSuccess"));
    } else {
      toast.error(t("admin.deleteAdminFail"));
    }
    handleGetAdmin();
    setIsLoading(false);
  };

  const handleOpenEdit = async (selectedAdmin) => {
    if (selectedAdmin) {
      setEditData(selectedAdmin);
      setOpenEditAdmin(true);
    }
  };

  const handleLoading = useCallback((_isLoading) => {
    setIsLoading(_isLoading);
  }, []);

  useEffect(() => {
    const getAdmin = async () => {
      setIsLoading(true);
      const adminData = await getAdminList();
      setFetch(adminData.data);
      setIsLoading(false);
    };
    getAdmin();
  }, []);

  useEffect(() => {
    search();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetch]);

  const search = () => {
    if (term.length > 0) {
      const filtered = fetch?.filter((f) =>
        f?.email.toLowerCase().includes(term)
      );

      if (filtered.length > 0) {
        setPage(0);
        setRows(filtered);
      } else {
        setRows([]);
      }
    } else {
      setPage(0);
      setRows(fetch);
    }
  };

  return (
    <div className={classes.container}>
      <Box>
        <Backdrop style={{ zIndex: 9999, color: "#fff" }} open={isLoading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            fontSize: "24px",
            alignItems: "center",
          }}
        >
          <Typography className={classes.title}>
            {t("admin.adminList")}
          </Typography>
          <form
            className={classes.btnSearchBox}
            onSubmit={(e) => {
              e.preventDefault();
              search();
            }}
          >
            <input
              ref={inputRef}
              placeholder={t("admin.search")}
              type="text"
              onChange={(e) => {
                setTerm(e.target.value.toString().trim().toLowerCase());
              }}
            />
            <IconButton
              color="secondary"
              aria-label="seach button"
              type="submit"
              sx={{ borderRadius: "10px" }}
              onClick={() => {
                search();
              }}
            >
              <BiSearchAlt />
            </IconButton>
          </form>
          <div>
            <Button
              className={classes.addButton}
              onClick={() => setOpenAddAdmin(true)}
            >
              <FaUserPlus sx={{ fontSize: "20px" }} />
              {t("admin.addAdminTitle")}
            </Button>
          </div>
        </Box>
        <Box>
          <TableContainer
            component={Paper}
            className={classes.tableContainer}
            style={{ backgroundColor: "transparent", boxShadow: "unset" }}
          >
            <Table
              sx={{ minWidth: 650 }}
              className={classes.table}
              style={{ minWidth: "unset" }}
            >
              <HeaderCells
                headCells={headCells}
                orderBy={orderBy}
                order={order}
                createSortHandler={createSortHandler}
              />
              <TableBody>
                {rows &&
                  rows.length > 0 &&
                  rows
                    .slice(page * pageSize, page * pageSize + pageSize)
                    .map((row, i) => (
                      <TableRow
                        key={i}
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell align="center" className={classes.tableCell}>
                          {page * pageSize + i + 1}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.nickName}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          {row.email}
                        </TableCell>
                        <TableCell align="center" className={classes.tableCell}>
                          <Button sx={{ fontSize: "24px" }}>
                            <FaEdit onClick={() => handleOpenEdit(row)} />
                          </Button>
                          <Button
                            sx={{ fontSize: "24px" }}
                            onClick={() => handleClickAdmin(row)}
                            style={{
                              opacity: `${adminId !== row.playerId ? 1 : 0}`,
                              visibility: `${
                                adminId !== row.playerId ? "visible" : "hidden"
                              }`,
                            }}
                          >
                            <AiOutlineDelete />
                          </Button>
                        </TableCell>
                      </TableRow>
                    ))}
              </TableBody>
            </Table>
            {rows && rows.length <= 0 && (
              <Box sx={{ padding: "2rem 0", textAlign: "center" }}>
                <Typography>{t("dialog.noData")}</Typography>
              </Box>
            )}
          </TableContainer>
          <Box className={classes.adminListBottom}>
            {rows && rows.length > 0 && (
              <Stack spacing={2} alignItems="center" height="100%">
                <Pagination
                  count={totalPage}
                  className={classes.pagination}
                  onChange={handleChangePage}
                />
              </Stack>
            )}
          </Box>
        </Box>
        <DialogAddAdmin
          open={openAddAdmin}
          handleClose={handleCloseAddAdmin}
          setRows={setRows}
          handleLoading={handleLoading}
        />
        <DialogEditAdmin
          open={openEditAdmin}
          handleClose={() => setOpenEditAdmin(false)}
          editData={editData}
          setRows={setRows}
          handleLoading={handleLoading}
        />
        <DialogDelete
          open={openDeleteAdmin}
          handleClose={handleCloseDelete}
          handleSubmit={handleDelete}
          contentConfirm={t("admin.contentDelete")}
        />
      </Box>
    </div>
  );
};

export default AdminList;
