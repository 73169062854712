import {
  SET_USER_INFO,
  SET_USER_LOGOUT,
  SET_FIRST_LOGIN,
  SET_FRIENDS,
  SET_DEFAULT_TABLE,
  SET_NARRATION,
  LEAVE_ROOM,
} from "../types";

const initialState = {
  playerId: "",
  fullName: "",
  nickName: "",
  friendInvitations: [], // {playerId,fullName, avatar, nickName, message,type (request/received)}
  dateOfBirth: "",
  gender: "",
  email: "",
  avatar: "",
  firstLogin: "",
  lastLogin: [],
  isOnline: true,
  friendRequests: [],
  notifications: [],
  friends: [],
  createDate: "",
  snsId: "",
  setting: {
    joinTableRole: "",
    defaultTableName: "",
    defaultAvatar: "",
    isNarrationSound: false,
    isNarrationText: true,
  },
  totalScore: 0,
  privateInvitations: [], // {roomId,ownerNickName, tableAvatar, ownerAvatar, inviteMessage}
  providerId: "",
  paymentMethodId: "",
  currentRoomJoinedId: null,
  package: "",
  moneyAmout: "",
  remainingHours: "",
  registeredTime: "",
  numberOfGames: 0,
  threads: [],
  showOnline: false,
  friendRequestAllow: "",
  playTimeId: "",
  numberPhone: "",
  readedConfirmMutipleDevices: false,
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_USER_INFO:
      return {
        ...state,
        playerId: action.payload.playerId,
        fullName: action.payload.fullName,
        nickName: action.payload.nickName,
        friendInvitations: action.payload.friendInvitations,
        dateOfBirth: action.payload.dateOfBirth,
        gender: action.payload.gender,
        email: action.payload.email,
        avatar: action.payload.avatar,
        firstLogin: action.payload.firstLogin,
        lastLogin: action.payload.lastLogin,
        friendRequests: action.payload.friendRequests,
        requestsSent: action.payload.requestsSent,
        notifications: action.payload.notifications,
        friends: action.payload.friends,
        createDate: action.payload.createDate,
        snsId: action.payload.snsId,
        currentRoomJoinedId: action.payload.currentRoomJoinedId,
        setting: {
          joinTableRole: action.payload?.setting?.joinTableRole || "",
          defaultTableName: action.payload?.setting?.defaultTableName || "",
          defaultAvatar: action.payload?.setting?.defaultAvatar || "",
          isNarrationSound: !!action.payload?.setting?.isNarrationSound,
          isNarrationText: !!action.payload?.setting?.isNarrationText,
        },
        totalScore: action.payload.totalScore,
        privateInvitations: action.payload.privateInvitations,
        providerId: action.payload.providerId,
        registeredTime: action.payload.registeredTime,
        numberOfGames: action.payload.numberOfGames,
        threads: action.payload.threads,
        isOnline: action.payload.isOnline,
        showOnline: action.payload.showOnline,
        friendRequestAllow: action.payload.friendRequestAllow,
        paymentMethodId: action.payload.paymentMethodId,
        playTimeId: action.payload.playTimeId,
        numberPhone: action.payload.numberPhone,
        readedConfirmMutipleDevices:
          action.payload.readedConfirmMutipleDevices || false,
      };
    case SET_FIRST_LOGIN:
      return {
        ...state,
        firstLogin: false,
      };
    case SET_DEFAULT_TABLE:
      return {
        ...state,
        setting: action.payload,
      };
    case SET_FRIENDS:
      return {
        ...state,
        friends: action.payload,
      };
    case LEAVE_ROOM:
      return {
        ...state,
        currentRoomJoinedId: null,
      };
    case SET_NARRATION:
      return {
        ...state,
        setting: {
          ...state.setting,
          isNarrationSound: action.payload.isNarrationSound,
          isNarrationText: action.payload.isNarrationText,
        },
      };

    case SET_USER_LOGOUT:
      return initialState;
    default:
      return state;
  }
};

export default userReducer;
