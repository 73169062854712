import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dialogPaper: {
    backgroundColor: "rgba(176, 195, 199, 1) !important",
    borderRadius: "40px !important",
    maxWidth: "unset !important",
    overflow: "unset !important",
  },
  mainBox: {
    padding: "2rem !important",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931 ",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    "& span": {
      marginLeft: "12px",
    },
  },
  iconBox: {
    display: "flex !important",
    alignItems: "center !important",
    height: "51px !important",
  },
  formSeclect: {
    width: "200px",
    height: "40px !important",
    backgroundColor: " #fff !important",
    justifyContent: "flex-start !important",
    fontWeight: 600,
  },
  boxButton: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: "20px",
  },
  fieldItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: "10px",
  },
}));

export default useStyles;
