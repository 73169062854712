import React from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import useDocumentTitle from "../../hooks/useDocumentTitle";
import { useTranslation } from "react-i18next";
const HomePage = styled.div`
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  background: url("/assets/game_bg.png") no-repeat center center fixed;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
`;
export const MyImg = styled.img`
  margin-top: 20px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const IntroText = styled.img`
  margin-top: 22px;
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
`;

export const HomeScreen = () => {
  const minScale = useSelector((state) => state.size.minScale);
  const myImgWidth = 550 * minScale;
  const myImgHeight = 450 * minScale;
  const introTextWidth = 1074 * minScale;
  const introTextHeight = 105 * minScale;
  const { t } = useTranslation();

  useDocumentTitle(t("common.gConceptTitle") + t("common.homeScreenTitle"));

  return (
    <HomePage>
      <MyImg width={myImgWidth} height={myImgHeight} src="/assets/moku.png" />
      <IntroText
        width={introTextWidth}
        height={introTextHeight}
        src="/assets/introText.png"
      />
    </HomePage>
  );
};
