import styled from "styled-components";

export const FriendRequestItems = styled.div`
  width: 350px;
  box-sizing: border-box;
  scroll-behavior: smooth;
  max-height: 300px;
`;

export const FriendItemWrapper = styled.div`
  width: 100%;
  box-sizing: border-box;
`;

export const FriendItem = styled.div`
  position: relative;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-evenly;
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
`;

export const Timestamp = styled.p`
  position: absolute;
  top: 8px;
  right: 10px;
  font-weight: 300;
  font-size: 12px;
  word-spacing: 10px;
`;

export const FriendItemBody = styled.div`
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 10px;
  width: 100%;
`;

export const FriendItemAvatar = styled.div`
  width: 50px;
  height: 50px;
  border-radius: 999px;
  flex-shrink: 0;
`;

export const FriendItemNickname = styled.p`
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
`;

export const FriendItemIntroduce = styled.p`
  font-weight: 300;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  margin-top: 10px;
`;

export const FriendInfoWrapper = styled.div`
  box-sizing: border-box;
  flex: 1;
  padding: 10px 15px;
`;

export const FriendItemFooter = styled.div`
  box-sizing: border-box;
  padding: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border-top: 1px solid #442211;
`;

export const FriendItemDelete = styled.div`
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px 10px;
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  cursor: pointer;
`;
export const AcceptFriend = styled.div`
  position: relative;
  box-sizing: border-box;
  margin-top: 10px;
  padding: 20px 10px;
  background-color: ${(props) => props.bg};
  border-radius: 10px;
  cursor: pointer;
`;
