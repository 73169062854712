import { getFunctions } from "../../services/firebase";
import {
  APPLY_NUMBER_OF_CALLS,
  CONFIRM_NO_CALL_END_GAME,
  CREATE_GAME,
  DECLARE_NUMBER_OF_CALLS,
  GET_TIMESTAMP,
  G_CHAOS_HUMAN_SELECT_MASTER_CARD,
  REJECT_NUMBER_OF_CALLS,
  REQUEST_PLEASE,
  SELECT_MASTER_CARD,
  SELECT_ORDER_CARD,
  STOP_TUTORIAL_GAME,
  TURN_TIMEOUT,
} from "../funcs";
import TableRepo from "../repositories/table";
const functions = getFunctions();
export const initGame = async (
  tableId,
  playersId,
  tutorialMode,
  numberOfGame,
  isGChaoComputer,
  isSkipIntroduce
) => {
  try {
    const data = {
      tableId,
      playersId,
      tutorialMode,
      numberOfGame,
      isGChaoComputer,
      isSkipIntroduce,
    };
    const game = functions.httpsCallable(CREATE_GAME);
    return await game(data);
  } catch (error) {
    console.log(error);
  }
};

export const updateShowArkStatus = async (tableId) => {
  try {
    if (tableId) {
      await TableRepo.update(tableId, { showArk: false });
    }
  } catch (error) {
    console.log(error);
  }
};

export const declareNumberOfCallsAction = async (
  playerId,
  gameId,
  numberOfCalls,
  indexOfPlayer,
  limitCalls,
  playersId,
  numberOfGame
) => {
  try {
    const data = {
      playerId,
      gameId,
      numberOfCalls,
      indexOfPlayer,
      limitCalls,
      playersId,
      numberOfGame,
    };
    const func = functions.httpsCallable(DECLARE_NUMBER_OF_CALLS);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const applyNumberOfCallOfPlayer = async (playerId, gameId) => {
  try {
    const data = {
      playerId,
      gameId,
    };
    const func = functions.httpsCallable(APPLY_NUMBER_OF_CALLS);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const rejectNumberOfCallsOfPlayer = async (
  playerId,
  gameId,
  roomId,
  nickName
) => {
  try {
    const data = {
      playerId,
      gameId,
      roomId,
      nickName,
    };
    const func = functions.httpsCallable(REJECT_NUMBER_OF_CALLS);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const fnUpdateOrderCardForPlayer = async (data) => {
  try {
    const func = functions.httpsCallable(SELECT_ORDER_CARD);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const selectMasterCard = async (playerId, cardIndex, stepId) => {
  try {
    const data = { playerId, cardIndex, stepId };
    const func = functions.httpsCallable(SELECT_MASTER_CARD);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const selectMasterCardGChaosIsHuman = async (
  playerId,
  cardIndex,
  stepId,
  tableId,
  gameId
) => {
  try {
    const data = { playerId, cardIndex, stepId, tableId, gameId };
    const func = functions.httpsCallable(G_CHAOS_HUMAN_SELECT_MASTER_CARD);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const getTimeStamp = async () => {
  let result = null;
  try {
    const func = functions.httpsCallable(GET_TIMESTAMP);
    result = await func();
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const turnTimeOut = async (data) => {
  let result = {};
  try {
    const func = functions.httpsCallable(TURN_TIMEOUT);
    await func(data);
  } catch (error) {
    console.log(error);
  }
  return result;
};

export const requestPlease = async (data) => {
  let result = {};
  try {
    const func = functions.httpsCallable(REQUEST_PLEASE);
    await func(data);
  } catch (error) {
    console.log(error);
  }
  return result;
};
export const stopTutorialGame = async (data) => {
  try {
    await functions.httpsCallable(STOP_TUTORIAL_GAME)(data);
  } catch (error) {
    console.log(error);
  }
};

export const confirmNoCallEndGame = async (
  playerIds,
  tableId,
  gameId,
  playerId
) => {
  try {
    await functions.httpsCallable(CONFIRM_NO_CALL_END_GAME)({
      playerIds,
      tableId,
      gameId,
      playerId,
    });
  } catch (error) {
    console.log(error);
  }
};

/**
 * send a request to the server to delete a game
 * @returns
 */
export const sendTimeOut = () => {
  return (dispatch, getState) => {
    const playerGameState = getState().playerGameState;
    if (!playerGameState) return;
    const { gameId, turnStatus, currentRound } = playerGameState;
    const data = {
      gameId,
      currentRound,
      turnStatus,
      startTurnTime: {},
    };
    try {
      const func = functions.httpsCallable(TURN_TIMEOUT);
      func(data);
    } catch (error) {
      console.log(error);
    }
  };
};
