import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  GAME_STATUS,
  SCREEN_NARRATION,
  STEP_NARRATION,
  TURN_STATUS,
} from "../../../../store/constant";
import { sortCards } from "../../../../utils/cards";
import { isShowTutorialCardsEndGame } from "../../../../utils/common";
import { CardsGeneral } from "./Cards";
import TutorialWrapper from "./component/TutorialWrapper";
import { GChaosLeftRightCardTutorialCard } from "./GChaosLeftRightTutorialCard";
export const TopTutorialCards = ({
  cards,
  isTutorial,
  isGChaos,
  className,
  isSorted,
}) => {
  const narrationShowCard = useSelector(
    (state) => state.narration.narrationScreen
  );
  const turnStatus = useSelector((state) => state.playerGameState.turnStatus);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const [isHideMyCards, setIsHideMyCards] = useState(false);
  const gameStatus = useSelector((state) => state.playerGameState.gameStatus);
  const size = useSelector((state) => state.size);
  const confirmId = useSelector((state) => state.narration.narrationPopup);
  const [isShowAllCards, setIsShowAllCards] = useState(false);
  const hShipHeight = useSelector((state) => state.size.hShipHeight);
  const playerCards = useMemo(
    () => (isSorted ? sortCards(cards) : cards),
    [cards, isSorted]
  );

  // SHOW CARD  AFTER STEP 7
  useEffect(() => {
    if (turnStatus === TURN_STATUS.INTRODUCE) {
      setIsHideMyCards(true);
    } else {
      !!turnStatus && setIsHideMyCards(false);
    }
    // declare number also hide cards
    if (
      turnStatus === TURN_STATUS.DECLARE_CALL &&
      stepId === STEP_NARRATION.SG_STEP5
    ) {
      setIsHideMyCards(false);
    }
    if (
      turnStatus === TURN_STATUS.DECLARE_CALL &&
      stepId !== STEP_NARRATION.SG_STEP5
    ) {
      setIsHideMyCards(false);
    }
    // hide card when step 6 and show after 2s
    if (stepId === STEP_NARRATION.SG_STEP6) {
      setIsHideMyCards(false);
    }
  }, [turnStatus, stepId]);

  useEffect(() => {
    if (narrationShowCard === SCREEN_NARRATION.SG_STEP3_DEAL_CARDS) {
      setIsHideMyCards(false);
    }
  }, [narrationShowCard]);

  useEffect(() => {
    if (!cards) {
      setIsShowAllCards(false);
      return;
    }
    if (isShowTutorialCardsEndGame(cards, confirmId, gameStatus)) {
      setIsShowAllCards(true);
    }
  }, [confirmId, cards, gameStatus]);

  const isOpenTutorialCards = () => {
    const isCardsNotNull = !!cards;
    return isCardsNotNull && isTutorial;
  };

  return (
    (isOpenTutorialCards() || isShowAllCards) && (
      <TutorialWrapper
        width={hShipHeight}
        position="right"
        className={className}
      >
        {!isHideMyCards && (
          <>
            {isGChaos && gameStatus === GAME_STATUS.PLAYING ? (
              <GChaosLeftRightCardTutorialCard size={size} />
            ) : (
              <CardsGeneral cards={playerCards} />
            )}
          </>
        )}
      </TutorialWrapper>
    )
  );
};

TopTutorialCards.propTypes = {
  cards: PropTypes.array,
  isTutorial: PropTypes.bool,
  isGChaos: PropTypes.bool,
  className: PropTypes.string,
  isSorted: PropTypes.bool,
};
