import { CARD_VALUE as CV, CARD_SUIT as CS } from "./../store/constant";
const computeRefCard = (card) => {
  const newCard = { ...card };
  switch (card.suit) {
    case CS.CLUBS: {
      if (newCard.value === CV.J) {
        newCard.refValue = 98;
        return newCard;
      }
      newCard.refValue = newCard.value;
      return newCard;
    }
    case CS.DIAMONDS: {
      newCard.refValue = newCard.value + 15;
      return newCard;
    }
    case CS.HEARTS: {
      newCard.refValue = newCard.value + 30;
      return newCard;
    }
    case CS.SPADES: {
      if (newCard.value === CV.J) {
        newCard.refValue = 99;
      } else newCard.refValue = newCard.value + 45;
      return newCard;
    }
    case CS.G: {
      if (newCard.value === 1) newCard.refValue = 101;
      else newCard.refValue = 102;
      return newCard;
    }
    default:
      return newCard;
  }
};

export const findCardIndex = (thisCard, cards) => {
  return cards.findIndex((card) => {
    return card.suit === thisCard.suit && card.value === thisCard.value;
  });
};

export const findSuitIndex = (suit, cards) => {
  return cards.findIndex((card) => {
    return card.suit === suit;
  });
};

export const largestCard = (cards, isOrderCard) => {
  const numberOfCards = cards.length;
  const refCards = mappingRefCard(cards, isOrderCard);
  refCards.sort((card1, card2) => {
    return card1.refValue - card2.refValue;
  });
  return cards.findIndex(
    (card) =>
      card.suit === refCards[numberOfCards - 1].suit &&
      card.value === refCards[numberOfCards - 1].value
  );
};

export const mappingRefCard = (cards, isOrderCard) => {
  const refCards = cards.map((card) => computeRefCard(card));
  if (isOrderCard) return refCards;
  const heartQIdx = findCardIndex(
    {
      suit: CS.HEARTS,
      value: CV.Q,
    },
    refCards
  );
  const gCardIdx = findSuitIndex(CS.G, refCards);
  if (heartQIdx !== -1 && gCardIdx !== -1) {
    refCards[heartQIdx].refValue = 200;
  }
  return refCards;
};
export const isStarCard = (card) => {
  return card.value >= CV.TEN || card.suit === CS.G;
};
export const getStarCards = (cards) => {
  return cards.filter((card) => {
    return isStarCard(card);
  });
};
