import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  clockBox: {
    textAlign: "center",
    border: "10px solid #336bab",
    padding: "5px 10px",
    borderRadius: "15px",
    color: "#442211",
    backgroundColor: "#fff",
    boxShadow: "rgb(0 0 0 / 24%) 0px 3px 8px",
  },

  playTimeBox: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-around",
    fontWeight: "bold",
    fontSize: "16px",
  },
  btn: {
    marginTop: "10px",

    "& button": {
      width: "100%",
      backgroundColor: "#442211",
      color: "white",
      fontSize: "11px",
      padding: "5px",
      borderRadius: "12px",
      border: "none",
      cursor: "pointer",

      "&:hover": {
        backgroundColor: "#336bab",
      },
    },
  },
}));
export default useStyles;
