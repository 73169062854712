import styled, { keyframes } from "styled-components";
import { Button } from "@material-ui/core";

function glowing() {
  // 236, 189, 42: #ff9900;
  return keyframes`
  0% {
    box-shadow: 0 0 0 0 rgba(236, 189, 42, 0.5),
      0 0 0 1px rgba(236, 189, 42, 0.5),
      0 0 0 2px rgba(236, 189, 42, 0.5),
      0 0 0 3px rgba(236, 189, 42, 0.5);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(236, 189, 42, 0.5),
      0 0 0 4px rgba(236, 189, 42, 0.5),
      0 0 0 10px rgba(236, 189, 42, 0),
      0 0 0 20px rgba(236, 189, 42, 0);
  }
//   0% { background-position: 0 0; }
//   50% { background-position: 400% 0; }
//   100% { background-position: 0 0; }
// }
  `;
}

// @keyframes glowing  {

// }

export const OKButtonNormal = styled(Button)`
  width: 135px;
  height: 45px;
  background-color: #ecbd2a;
  border: 2px solid #ecbd2a;
  border-radius: 0;
  font-weight: 600;
  :hover {
    background-color: #ecbd2a;
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 105px;
    height: 35px;
    left: 10%;
  }
`;

export const OKButton = styled(Button)`
  width: 135px;
  height: 45px;
  background-color: #ecbd2a;
  border: 2px solid #ecbd2a;
  border-radius: 0;
  font-weight: 600;
  animation: ${glowing} 1500ms infinite;
  :hover {
    background-color: #ecbd2a;
    font-size: 16px !important;
  }
  @media screen and (max-width: 1024px) {
    width: 105px;
    height: 35px;
    left: 10%;
  }
`;
export const CancelButton = styled(Button)`
  width: 135px;
  height: 45px;
  background-color: #f7f7f7;
  border: 2px solid #ecbd2a;
  border-radius: 0;
  font-weight: 600;
  :hover {
    background-color: #f7f7f7;
  }
  @media screen and (max-width: 1024px) {
    width: 115px;
    height: 35px;
    left: 10%;
  }
`;
