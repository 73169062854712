import styled from "styled-components";
import { Profile } from "../../Profile/Profile";

export const Container = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: absolute;
  background-color: transparent;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
`;

export const ProfileWrapper = styled.div`
  flex: 1;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-evenly;
`;

export const ModifiedProfile = styled(Profile)`
  margin-right: 15px;
`;

export const ShipWrapper = styled.div`
  width: 45%;
  height: 100%;
  box-sizing: border-box;
  background-color: #dea417;
  margin: 0 3px;
  position: relative;
`;

export const GChaosWelcome = styled.div`
  width: ${(props) => props.width}px;
  height: 100%;
  // background-image: url("/assets/v_g_chaos_welcome.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(${(props) => (props.side === "left" ? 180 : 0)}deg);
  overflow: hidden;
  box-sizing: border-box;
`;

export const GuestWelcome = styled.div`
  width: ${(props) => props.width}px;
  height: 99.5%;
  // background-image: url("/assets/v_guest_welcome.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(${(props) => (props.side === "left" ? 180 : 0)}deg);
  overflow: hidden;
  box-sizing: border-box;
`;

export const Master = styled.div`
  width: ${(props) => props.width}px;
  height: 100%;
  background-image: url("/assets/v_master.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
`;
