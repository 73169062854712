import styled from "styled-components";
import { Profile } from "../../Profile/Profile";
export const Container = styled.div`
  width: 500px;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media screen and (max-width: 1024px) {
    max-width: 290px;
  }
`;

export const TopWrapper = styled.div`
  width: 100%;
  height: 25%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
`;

export const ModifiedTopAvatar = styled(Profile)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  margin-right: 15px;
`;

export const MiddleWrapper = styled.div`
  width: 100%;
  flex: 1;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
`;

export const AvatarWrapper = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
`;

export const CardsWrapper = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  background-color: #311c0a;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`;
