import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Cards, Card, CardsTop, CardsBottom } from "./styles";
import {
  SCREEN_NARRATION,
  STEP_NARRATION,
  TURN_STATUS,
} from "../../../../store/constant";
import { useSelector } from "react-redux";
// import { STD_SIZE } from "../../../../store/constant";
import { calTutorialGap } from "../../../../utils/calculations";

const CardsMemo = ({ cards }) => {
  const narrationShowCard = useSelector(
    (state) => state.narration.narrationScreen
  );
  const turnStatus = useSelector((state) => state.playerGameState.turnStatus);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const containerWidth = useSelector((state) => state.size.vShipWidth);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const [cardW, cardH, gap] = useMemo(
    () => calTutorialGap(containerWidth, shipSpace),
    [containerWidth, shipSpace]
  );
  const [isShowMyCards, setIsShowMyCards] = useState(false);
  // const size = useSelector((state) => state.size);
  // const [innerSize, setInnerSize] = useState({});

  // useEffect(() => {
  //   if (size) {
  //     const topLeft = Math.round(size?.vShipWidth * 0.05);
  //     const cardWidth = size.cardWidth * 0.63;
  //     const cardHeight = size.cardHeight * 0.63;
  //     const orgCardWidth = size.cardWidth;
  //     setInnerSize({ cardWidth, cardHeight, topLeft, orgCardWidth });
  //   } else {
  //     const topLeft = Math.round(STD_SIZE.STD_SHIP_HEIGHT * 0.05);
  //     const cardWidth = STD_SIZE.STD_CARD_WIDTH * 0.63;
  //     const cardHeight = STD_SIZE.STD_SHIP_HEIGHT * 0.63;
  //     const orgCardWidth = STD_SIZE.STD_CARD_WIDTH;
  //     setInnerSize({ cardWidth, cardHeight, topLeft, orgCardWidth });
  //   }
  // }, [size]);
  // SHOW CARD  AFTER STEP 7
  useEffect(() => {
    if (turnStatus !== TURN_STATUS.INTRODUCE) {
      setIsShowMyCards(true);
    }
    // declare number also hide cards
    if (
      turnStatus === TURN_STATUS.DECLARE_CALL &&
      stepId === STEP_NARRATION.SG_STEP5
    ) {
      setIsShowMyCards(false);
    }
    if (
      turnStatus === TURN_STATUS.DECLARE_CALL &&
      stepId !== STEP_NARRATION.SG_STEP5
    ) {
      setIsShowMyCards(true);
    }
    // hide card when step 6 and show after 2s
    if (stepId === STEP_NARRATION.SG_STEP6) {
      setIsShowMyCards(true);
    }
  }, [turnStatus, stepId]);
  useEffect(() => {
    if (narrationShowCard === SCREEN_NARRATION.SG_STEP3_DEAL_CARDS) {
      setIsShowMyCards(false);
    }
    if (narrationShowCard === SCREEN_NARRATION.SG_STEP5_SHOW_DEAL_CARDS) {
      setIsShowMyCards(true);
    }
  }, [narrationShowCard]);
  return (
    <>
      <Cards>
        <CardsTop>
          {cards &&
            cards.slice(0, 7).map(
              (card, idx) =>
                card && (
                  <div
                    key={`topHandCards:${idx}`}
                    style={{
                      width: cardW,
                      height: cardH,
                      zIndex: idx,
                      top: shipSpace,
                      left: Math.round(idx * gap + shipSpace),
                      position: "absolute",
                      borderRadius: "4%",
                    }}
                  >
                    <Card
                      src={
                        !isShowMyCards
                          ? "/cards/back.png"
                          : `/cards/${card.suit}${card.value}.jpg`
                      }
                    />
                  </div>
                )
            )}
        </CardsTop>
        <CardsBottom>
          {cards &&
            cards.slice(7).map(
              (card, idx) =>
                card && (
                  <div
                    key={`topHandCards:${idx}`}
                    style={{
                      width: cardW,
                      height: cardH,
                      zIndex: idx,
                      top: shipSpace * 2 + cardH,
                      left: Math.round(idx * gap + shipSpace),
                      position: "absolute",
                      borderRadius: "4%",
                    }}
                  >
                    <Card
                      src={
                        !isShowMyCards
                          ? "/cards/back.png"
                          : `/cards/${card.suit}${card.value}.jpg`
                      }
                    />
                  </div>
                )
            )}
        </CardsBottom>
      </Cards>
    </>
  );
};

CardsMemo.propTypes = {
  cards: PropTypes.array,
};

export const CardsGeneral = React.memo(CardsMemo);
