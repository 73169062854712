import { getFunctions } from "../../services/firebase";
import {
  GENERATE_INVITATION_LINK,
  COUNT_INVITATION,
  GET_PLAYER_SUBSCRIPTION_INFO,
  CREATE_CARD_CHECKOUT_SESSION,
  UNSUBSCRIBE_PACKAGE,
  TRACK_PLAY_TIME,
  CHECK_PLAYER_TRIAL,
  GET_PACKAGE_INFO,
  SUBSCRIBE_TO_BRONZE_PACKAGE,
  SWITCH_PACKAGE,
  GET_ALL_PACKAGES,
  GET_PACKAGE_BY_PLAYER_ID,
  CHANGE_PACKGE_FREE,
  UNSUBSCRIBE_PACKAGE_OF_CYCLO,
  SUBSCRIBE_PACKAGE_NEXT_MONTH,
  ADMIN_SWITCH_PACKAGE_PLAYER,
} from "../funcs";
const functions = getFunctions();

export const getPackageInfo = async () => {
  try {
    return functions.httpsCallable(GET_PACKAGE_INFO)({});
  } catch (error) {
    console.log(error);
  }
};

export const generateInvitationLink = async (playerId, url) => {
  try {
    const generateInvitationLinkFunction = functions.httpsCallable(
      GENERATE_INVITATION_LINK
    );
    return generateInvitationLinkFunction({
      playerId,
      url,
    });
  } catch (e) {
    console.error(e);
  }
};

export const countInvitation = async (playerId) => {
  try {
    const countInvitationFunction = functions.httpsCallable(COUNT_INVITATION);
    return countInvitationFunction({ playerId });
  } catch (e) {
    console.error(e);
  }
};

export const getPlayerSubscriptionInfo = async (playerId) => {
  try {
    const getPlayerSubscriptionInfoFunction = functions.httpsCallable(
      GET_PLAYER_SUBSCRIPTION_INFO
    );
    return getPlayerSubscriptionInfoFunction({ playerId });
  } catch (e) {
    console.error(e);
  }
};

export const createCardCheckoutSession = async (playerId, packageId, url) => {
  try {
    const createCardCheckoutSessionFunction = functions.httpsCallable(
      CREATE_CARD_CHECKOUT_SESSION
    );
    return createCardCheckoutSessionFunction({
      playerId,
      packageId,
      url,
    });
  } catch (e) {
    console.error(e);
  }
};

export const unsubscribePackage = async (playerId) => {
  try {
    const unsubscribePackageFunction =
      functions.httpsCallable(UNSUBSCRIBE_PACKAGE);
    return unsubscribePackageFunction({ playerId });
  } catch (e) {
    console.error(e);
  }
};

export const trackPlayTime = async (playerId, time) => {
  try {
    const trackPlayTimeFunction = functions.httpsCallable(TRACK_PLAY_TIME);
    return trackPlayTimeFunction({ playerId, time });
  } catch (e) {
    console.error(e);
  }
};

export const checkPlayerTrial = async (playerId) => {
  try {
    const checkPlayerTrialFunction =
      functions.httpsCallable(CHECK_PLAYER_TRIAL);
    return checkPlayerTrialFunction({ playerId });
  } catch (e) {
    console.error(e);
  }
};

export const subscribeToBronzePackage = async (playerId) => {
  return functions.httpsCallable(SUBSCRIBE_TO_BRONZE_PACKAGE)({ playerId });
};

export const switchPackge = async (packageFrom, packageTo, playerId) => {
  return functions.httpsCallable(SWITCH_PACKAGE)({
    packageFrom,
    packageTo,
    playerId,
  });
};

export const adminSwitchPackagePlayer = async (
  packageFromId,
  packageToId,
  playerId
) => {
  return functions.httpsCallable(ADMIN_SWITCH_PACKAGE_PLAYER)({
    packageFromId,
    packageToId,
    playerId,
  });
};

export const getAllPackages = async () => {
  try {
    return functions.httpsCallable(GET_ALL_PACKAGES)();
  } catch (e) {
    console.error(e);
  }
};

export const getPackageByPlayerId = async (playerId) => {
  try {
    return functions.httpsCallable(GET_PACKAGE_BY_PLAYER_ID)({ playerId });
  } catch (e) {
    console.error(e);
  }
};

export const changePackageFree = async (playerId) => {
  return functions.httpsCallable(CHANGE_PACKGE_FREE)({
    playerId,
  });
};

export const subscribeToBronzePackageOfCyclo = async (playerId) => {
  return functions.httpsCallable(UNSUBSCRIBE_PACKAGE_OF_CYCLO)({ playerId });
};

export const subscribePackageNextMonth = async (
  playerId,
  packageNextMonth,
  playTime
) => {
  return functions.httpsCallable(SUBSCRIBE_PACKAGE_NEXT_MONTH)({
    playerId,
    packageNextMonth,
    playTime,
  });
};
