import { Box, Dialog, IconButton, makeStyles } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PaperComponent from "../../PaperComponent";
import { OKButton, CancelButton } from "../Buttons";
import CloseIcon from "@material-ui/icons/Close";

const useStyles = makeStyles(() => ({
  styleFalse: {
    borderRadius: "30px",
    backgroundColor: "#f7f7f7",
    fontSize: (props) => props.fontSize,
  },
  styleTrue: {
    borderRadius: "30px",
    backgroundColor: "#f7f7f7",
    right: "20%",
    fontSize: (props) => props.fontSize,
    "@media (max-width: 1024px)": {
      overflow: "hidden",
      right: "25%",
    },
  },
  closeIcon: {
    color: "black",
    fontSize: 12,
  },
  iconBtn: {
    backgroundColor: "white !important",
    padding: "5px",
    position: "absolute",
    top: 15,
    right: 15,
  },
}));
const BasicDialog = (props) => {
  const {
    isOpen,
    onCancel,
    onAgree,
    mainText,
    closeText,
    agreeText,
    onCloseDialog,
    style = false,
    withXButton = false,
    onXButtonClick,
  } = props;
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (size) {
      const fontSize = Math.round(23 * size?.minScale);
      setInnerSize({ fontSize });
    } else {
      const fontSize = 23 * 1;
      setInnerSize({ fontSize });
    }
  }, [size]);
  const classes = useStyles({ fontSize: innerSize.fontSize });
  return (
    <Dialog
      open={isOpen}
      PaperProps={{
        className: style ? classes.styleTrue : classes.styleFalse,
      }}
      PaperComponent={PaperComponent}
      onClose={() => onCloseDialog && onCloseDialog()}
    >
      <div className="dialog-style-1" id="draggable-dialog-title">
        <div style={{ height: "70%" }}>
          {withXButton && (
            <IconButton className={classes.iconBtn} onClick={onXButtonClick}>
              <CloseIcon className={classes.closeIcon} />
            </IconButton>
          )}
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
            height={145}
          >
            <p
              style={{
                textAlign: "center",
                padding: "0 2em",
                fontWeight: 700,
              }}
              dangerouslySetInnerHTML={{ __html: mainText }}
            />
          </Box>
        </div>
        <Box display="flex" justifyContent="center" style={{ height: "25%" }}>
          <Box width={135}>
            <CancelButton onClick={() => onCancel()}>{closeText}</CancelButton>
          </Box>
          <Box width={15}></Box>
          <Box width={135}>
            <OKButton onClick={() => onAgree()}>
              <div
                style={{
                  textAlign: "center",
                  display: "flex",
                  flexFlow: "row nowrap",
                  justifyContent: "space-evenly",
                  width: "90%",
                }}
              >
                {agreeText &&
                  agreeText.length > 0 &&
                  [...agreeText].map((letter, idx) => (
                    <div key={letter + idx}>{letter}</div>
                  ))}
              </div>
            </OKButton>
          </Box>
        </Box>
      </div>
    </Dialog>
  );
};
BasicDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onAgree: PropTypes.func,
  mainText: PropTypes.string,
  closeText: PropTypes.string,
  agreeText: PropTypes.string,
  onCloseDialog: PropTypes.func,
  style: PropTypes.bool,
  withXButton: PropTypes.bool,
  onXButtonClick: PropTypes.func,
};

export default React.memo(BasicDialog);
