import { IconButton, Typography } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { addMemberToThread } from "../../../store/actions";
import { checkIsHaveRoomChat } from "../../../store/actions/chat";
import ChatRepo from "../../../store/repositories/chat";
import {
  SET_SELECTED_THREAD_ID,
  SET_LOADING_GLOBAL,
} from "../../../store/types";
import SearchFriendInput from "./SearchFriendInput";
import {
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogStyled,
  StyleButton,
  useStyles,
  WrapperSearch,
} from "./styles";

const AddFriendToRoomDialog = (props) => {
  const classes = useStyles({ focus });
  const { isOpen, onClose, threadId } = props;
  const [listFriend, setListFriend] = useState([]);
  const [friendSelected, setFriendSelected] = useState([]);
  const [memberIds, setMemberIds] = useState([]);
  const [error, setError] = useState(false);
  const friends = useSelector((state) => state.user.friends);
  const threads = useSelector((state) => state.user.threads);
  const dispatch = useDispatch();

  const { t } = useTranslation();

  const handleChange = useCallback(
    (values) => {
      setFriendSelected(values);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [threadId]
  );

  const handleLoading = (isLoading) => {
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: isLoading,
    });
  };

  const handleCreateGroupChat = async (f_selected = []) => {
    handleLoading(true);
    if (f_selected && f_selected.length > 0) {
      const countFriend = f_selected?.length + memberIds?.length;
      if (countFriend <= 10) {
        // eslint-disable-next-line no-unused-vars
        const _friendSelected = f_selected.map((item) => {
          return {
            memberId: item.friendId,
            memberName: item.nickName ?? "",
            memberAvatar: item.avatar ?? "",
            isReading: false,
            countUnreadMessage: 0,
          };
        });
        const allPlayerAddRoomIds = [...memberIds];
        f_selected.forEach((item) => {
          allPlayerAddRoomIds.push(item.friendId);
        });

        const result = await checkIsHaveRoomChat(allPlayerAddRoomIds, threads);
        if (result.data) {
          setError(false);
          setFriendSelected([]);
          onClose();
          dispatch({
            type: SET_SELECTED_THREAD_ID,
            payload: result.data,
          });
        } else {
          await addMemberToThread(threadId, _friendSelected);
          setError(false);
          setFriendSelected([]);
          onClose();
        }
      } else {
        setError(true);
      }
    }
    handleLoading(false);
  };

  useEffect(() => {
    const readData = async () => {
      if (threadId) {
        const thread = await ChatRepo.read(threadId);
        if (thread) {
          const threadMemberIds = thread.threadMemberIds ?? [];
          setMemberIds(threadMemberIds);
          if (threadMemberIds && threadMemberIds.length > 0) {
            const friendList = friends?.filter(
              (f) => !threadMemberIds.includes(f.friendId)
            );
            setListFriend(friendList);
          }
        }
      }
    };
    readData();
    // eslint-disable-next-line
  }, [threadId]);

  return (
    <DialogStyled
      open={isOpen}
      onClose={onClose}
      className={"add-friend-to-room-dialog"}
    >
      <div className={classes.dialog}>
        <DialogHeader>
          <IconButton
            className={classes.iconBtn}
            onClick={() => {
              setFriendSelected([]);
              setError(false);
              onClose();
            }}
          >
            <CloseIcon style={{ fontSize: 30 }} />
          </IconButton>
        </DialogHeader>
        <DialogContent>
          <Typography className={classes.dialogTitle}>
            {t("dialog.whoAddFriendToRoom")}
          </Typography>
          <WrapperSearch>
            <SearchFriendInput
              listFriend={listFriend}
              handleChange={handleChange}
            />
          </WrapperSearch>
          {error && (
            <div style={{ fontSize: "14px", marginTop: "10px", color: "red" }}>
              {t("dialog.roomFullMember")}
            </div>
          )}
          <StyleButton
            isDisable={friendSelected.length > 0}
            onClick={() => {
              handleCreateGroupChat(friendSelected);
            }}
          >
            {t("dialog.createGroupChat")}
          </StyleButton>
        </DialogContent>
        <DialogFooter></DialogFooter>
      </div>
    </DialogStyled>
  );
};

AddFriendToRoomDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  threadId: PropTypes.string,
};

export default AddFriendToRoomDialog;
