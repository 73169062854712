import { Dialog } from "@material-ui/core";
import React, { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";
import { mapDisplayCalls } from "../../../../../store/actions/playerGameStateActions";
import { SCREEN_NARRATION } from "../../../../../store/constant";
import {
  Nickname,
  NicknameWrapper,
  Number,
  NumberOfCallContainer,
  NumberOfCallItem,
  NumberOfCalls,
  StyleOctagon,
  TextCall,
} from "../styles";

const Narration15DetermineCall = () => {
  const { t } = useTranslation();
  const confirmId = useSelector((state) => state.narration.narrationScreen);
  const [isShow, setIsShow] = useState(false);
  const [playersNumberOfCalls, setPlayersNumberOfCalls] = useState([]);
  const numberOfCalls = useSelector(
    (state) => state.playerGameState.myState.numberOfCalls
  );
  const joinedPlayers = useSelector((state) => state.room.joinedPlayers);

  const otherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const minScale = useSelector((state) => state.size.minScale ?? 1);
  const starSize = useMemo(() => Math.round((minScale ?? 1) * 30), [minScale]);
  const [isOpenDialog, setIsOpenDialog] = useState(false);
  const size = useSelector((state) => state.size);
  const [innerSize, setInnerSize] = useState({});

  useEffect(() => {
    setIsShow(SCREEN_NARRATION.SG_STEP13_DETERMINE_DECLARE_CALL === confirmId);
    if (playersNumberOfCalls && playersNumberOfCalls.length > 0) {
      setIsOpenDialog(true);
    }
  }, [confirmId, playersNumberOfCalls]);

  useEffect(() => {
    const isUndefined =
      numberOfCalls !== -1 &&
      numberOfCalls !== undefined &&
      joinedPlayers !== undefined &&
      joinedPlayers.length > 0 &&
      otherPlayersState !== undefined &&
      otherPlayersState.length > 0;
    if (!isUndefined) return;

    const allCalls = mapDisplayCalls({
      joinedPlayers,
      numberOfCalls,
      otherPlayersState,
    });
    setPlayersNumberOfCalls(allCalls);
    setIsOpenDialog(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isShow, joinedPlayers, numberOfCalls]);

  useEffect(() => {
    const _minScale = size.minScale || 1;
    const textFontSize = Math.round(20 * _minScale);
    const nickNameFontSize = Math.round(26 * _minScale);
    const callFontSize = Math.round(20 * _minScale);
    setInnerSize({
      textFontSize,
      nickNameFontSize,
      callFontSize,
    });
  }, [size]);

  return (
    isOpenDialog &&
    isShow && (
      <Dialog
        open={isShow}
        PaperProps={{
          style: { borderRadius: "35px", backgroundColor: "#CCA98E" },
        }}
        PaperComponent={PaperComponent}
      >
        <NumberOfCallContainer
          id="draggable-dialog-title"
          className="dialog-style-1"
        >
          {playersNumberOfCalls &&
            playersNumberOfCalls.map((p, idx) => (
              <NumberOfCallItem key={idx}>
                <NicknameWrapper>
                  {p.nickName === "G-Chaos" || idx === 0 ? (
                    <>
                      <StyleOctagon size={starSize} />
                      <Nickname
                        style={{ fontSize: innerSize?.nickNameFontSize }}
                      >{`${p?.nickName ?? ""}`}</Nickname>
                    </>
                  ) : (
                    <Nickname
                      style={{ fontSize: innerSize?.nickNameFontSize }}
                    >{`✡ ${p?.nickName ?? ""}`}</Nickname>
                  )}
                </NicknameWrapper>
                <NumberOfCalls>
                  <Number style={{ fontSize: innerSize?.callFontSize }}>{`${
                    p?.numberOfCalls === undefined || p?.numberOfCalls === -1
                      ? ""
                      : p.numberOfCalls
                  }`}</Number>
                  <TextCall style={{ fontSize: innerSize?.textFontSize }}>{`${
                    p?.numberOfCalls === undefined || p?.numberOfCalls === -1
                      ? t("numberOfCalls.pending")
                      : t("numberOfCalls.call")
                  }`}</TextCall>
                </NumberOfCalls>
              </NumberOfCallItem>
            ))}
        </NumberOfCallContainer>
      </Dialog>
    )
  );
};
export default React.memo(Narration15DetermineCall);
