import axios from "axios";
import * as _ from "lodash";
import { stopCurrentNarration } from "../store/actions/narrationActions";
import {
  playerChangeCommitFirst,
  saveGrandCrossRound12,
  saveNewestOtherPlayerState,
  saveOtherPlayerStateRound13,
  saveStateNewSuit,
} from "../store/actions/playerGameStateActions";
import { showOverGetPlayerName } from "../store/actions/roomActions";
import { addStepNarrations } from "../store/actions/stepActions";
import {
  CARD_SUIT_CLUBS,
  CARD_SUIT_DIAMONDS,
  CARD_SUIT_G,
  CARD_SUIT_HEARTS,
  CARD_SUIT_SPADES,
  CARD_SUIT_WHATEVER,
  GAME_STATUS,
  LIMIT_TIME,
  ONE_DAY,
  PLAYER_ROLES,
  POPUPS,
  SAN,
  SCREEN_NARRATION,
  STEP_NARRATION,
  TRIAL_DAYS,
  TURN_STATUS,
} from "../store/constant";
import {
  ADD_INSTRUCTOR_MESSAGE_CODE,
  CLEAR_STEP_NARRATION,
  SET_PLAYER_GAME_STATE_REQUEST,
  UPDATE_STEP_ID,
} from "../store/types";
import { isLoseOrWin } from "./gameConditions";
export const executeFunc = async (baseURL, functionName, data) => {
  return axios({
    url: `${baseURL}/${functionName}`,
    method: "POST",
    data: JSON.stringify(data),
    headers: { "Content-Type": "multipart/form-data" },
  });
};

/**
 * Get turn time
 * @param {*} turnStatus
 * @returns
 */
export const getTurnTime = (turnStatus) => {
  switch (turnStatus) {
    case TURN_STATUS.WAIT_RF:
      return LIMIT_TIME.WAIT_RF;
    case TURN_STATUS.PLAY_CARD:
      return LIMIT_TIME.PLAY_CARD;
    case TURN_STATUS.CAPTAIN:
      return LIMIT_TIME.PLAY_CARD;
    case TURN_STATUS.DECLARE_CALL:
      return LIMIT_TIME.DECLARE_CALL;
    case TURN_STATUS.DECIDE_MASTER:
      return LIMIT_TIME.DECIDE_MASTER;
    case TURN_STATUS.SELECT_CARD_REQUEST_PLEASE:
      return LIMIT_TIME.SELECT_CARD_REQUEST_PLEASE;
    default:
      return 60;
  }
};

/**
 * isGChaosPlayFirst
 * @param {*} grandCross
 * @returns
 */
export const isGChaosPlayFirst = (grandCross) => {
  return (
    grandCross.filter((ele) => ele === null).length === 3 &&
    grandCross[3] !== null
  );
};

/**
 * Check if gchaos after captain
 * @param {*} currentState
 * @param {*} newState
 * @returns
 */
export const checkGChaosAfterCaptain = (currentState, newState) => {
  const currentGrandCross = currentState.grandCross;
  const newGrandCross = newState.grandCross;

  const currentGrandCrossAllNulls =
    currentGrandCross.filter((e) => e === null).length === 4;
  const newGrandCrossHasCaptainCardAndGChaosCard =
    newGrandCross[2] !== null && newGrandCross[3] !== null;

  const case1 =
    currentGrandCrossAllNulls && newGrandCrossHasCaptainCardAndGChaosCard;

  const currentCaptainPlayed =
    currentGrandCross[2] !== null &&
    currentGrandCross[3] === null &&
    currentGrandCross[0] === null &&
    currentGrandCross[1] === null;
  const newChaosPlayed =
    newGrandCross[2] !== null &&
    newGrandCross[3] !== null &&
    newGrandCross[0] === null &&
    newGrandCross[1] === null;
  const currentChaosPlayed =
    currentGrandCross[2] !== null &&
    currentGrandCross[3] !== null &&
    currentGrandCross[0] === null &&
    currentGrandCross[1] === null;
  const case2 = currentCaptainPlayed && newChaosPlayed;

  const currentFullCards =
    currentGrandCross.filter((e) => e !== null).length === 4;
  const case3 = currentFullCards && newChaosPlayed;

  const case4 = currentChaosPlayed && newChaosPlayed;

  return case1 || case2 || case3 || case4;
};

/**
 * Is the game playing
 * @param {*} turnStatus
 * @returns
 */
export const isPlayingCard = (turnStatus) => {
  return [
    TURN_STATUS.WAIT_RF,
    TURN_STATUS.PLAY_CARD,
    TURN_STATUS.CAPTAIN,
  ].includes(turnStatus);
};

/**
 * Get cookie
 * @param {*} cookieName
 * @returns
 */
export const getCookie = (cookieName) => {
  var name = cookieName + "=";
  var ca = document.cookie.split(";");
  for (let c of ca) {
    while (c.charAt(0) == " ") {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

/**
 * Check if the round is commit
 * @param {*} currentState
 * @param {*} newState
 * @returns
 */
function isRoundCommitted(currentState, newState) {
  const currentFull4Cards = !currentState.grandCross.includes(null);
  const newState0Cards = newState.grandCross.every((ele) => ele === null);
  const notWinOrLoseInNewState = !isLoseOrWin(currentState, newState);
  const notHaveNewYorkTime =
    -1 ===
    currentState.grandCross.findIndex(
      (card) => null !== card && "NewYorkTimes" === card.suit
    );
  return (
    currentFull4Cards &&
    newState0Cards &&
    notWinOrLoseInNewState &&
    notHaveNewYorkTime
  );
}

/**
 * Select state
 * @param {*} currentState
 * @param {*} newState
 * @returns
 */
export const selectUpdateState = (currentState, newState) => {
  if (currentState?.grandCross && newState?.grandCross) {
    const currentNum =
      currentState?.grandCross?.filter(
        (e) => e !== null && e.suit !== "NewYorkTimes"
      )?.length ?? 0;
    const newNum =
      newState?.grandCross?.filter(
        (e) => e !== null && e.suit !== "NewYorkTimes"
      )?.length ?? 0;
    if (
      (currentNum === 4 || currentNum === 6) &&
      (newNum === 0 || newNum === 1 || newNum === 2 || newNum === 6)
    ) {
      return newState;
    } else if (newNum - currentNum === 1 || newNum === currentNum) {
      return newState;
    } else if (currentNum === 0 && newNum === 2) {
      return newState;
    } else if (currentNum === 3 && newNum === 0) {
      return newState;
    } else {
      return null;
    }
  }
  return null;
};

/**
 * Check if g-chaos play card normal
 * @param {*} currentState
 * @param {*} newState
 * @returns
 */
export const isGChaoPlayNormal = (currentState, newState) => {
  const grandCross = newState?.grandCross ?? [null, null, null, null];
  const cards = grandCross.filter((e) => e !== null);
  const cardLength = cards.length;
  // G-Chaos play first
  if (cardLength === 1 && grandCross[3] !== null) {
    return false;
  } else {
    const currentGrandCross = currentState?.grandCross ?? [
      null,
      null,
      null,
      null,
    ];
    return currentGrandCross[3] === null && grandCross[3] !== null;
  }
};

/**
 * Notify to user base on state
 *
 * Currently, it will notify new suit, leave room, join room
 * @param {*} currentState
 * @param {*} newState
 * @param {*} roomState
 * @param {*} dispatch
 * @returns
 */
export const instructorNotify = (
  currentState,
  newState,
  roomState,
  dispatch
) => {
  if (
    !roomState.roomId &&
    !roomState.joinedPlayers.every((elm) => elm !== null)
  ) {
    return;
  }

  // show all when reload
  if (!currentState.playerId) {
    dispatch({
      type: SET_PLAYER_GAME_STATE_REQUEST,
      payload: newState,
    });
    currentState = { ...newState };
    currentState.stepId = "";
  }

  let message = null;

  // check commitRound
  const isCommitRound = isRoundCommitted({ ...currentState }, { ...newState });

  // show nick name of player end game
  dispatch(showOverGetPlayerName(newState.endGamePlayerId));

  const state = selectUpdateState({ ...currentState }, { ...newState });
  if (state !== null) {
    dispatch(saveStateNewSuitOnGrandCross(state));
  }
  if (
    newState.stepId &&
    newState.stepId !== currentState.stepId &&
    newState.stepId.indexOf("PC_STEP") === -1
  ) {
    if (newState.stepId === STEP_NARRATION.SG_STEP12_STOP_TUTORIAL) {
      dispatch(stopCurrentNarration());
      dispatch({ type: CLEAR_STEP_NARRATION });

      dispatch(addStepNarrations([newState.stepId]));
      return;
    }
    if (
      currentState.stepId === STEP_NARRATION.SG_STEP7 &&
      newState.stepId === STEP_NARRATION.SG_STEP5
    ) {
      return;
    }
    if (
      ![
        STEP_NARRATION.ED_STEP1,
        STEP_NARRATION.ED_STEP2,
        STEP_NARRATION.ED_STEP3,
        STEP_NARRATION.ED_STEP4,
      ].includes(newState.stepId)
    ) {
      dispatch(playerChangeCommitFirst(newState));
    }
    dispatch(addStepNarrations([newState.stepId]));
  }

  if (newState.gameStatus === GAME_STATUS.WIN) {
    message = null;
    return;
  }
  if (isCommitRound === true) {
    // new Players
    const currentPlayers = mapAllPlayerInRoom(currentState, roomState);
    const newPlayers = mapAllPlayerInRoom(newState, roomState);
    message = getInstructorEndRound(
      currentPlayers,
      newPlayers,
      newState.nextActionPlayer
    );

    if (
      message &&
      ![STEP_NARRATION.ED_STEP14_G_CHAOS, STEP_NARRATION.ED_STEP14].includes(
        newState.stepId
      )
    ) {
      dispatch({ type: UPDATE_STEP_ID, payload: message });
    }
    message = null;
  }

  // NoHaveSuit
  const cardSuits = [
    CARD_SUIT_SPADES,
    CARD_SUIT_HEARTS,
    CARD_SUIT_DIAMONDS,
    CARD_SUIT_CLUBS,
  ];
  const isCheckSuit =
    cardSuits.includes(currentState.suit) && cardSuits.includes(newState.suit);
  if (isCheckSuit) {
    const newPlayers = mapAllPlayerInRoom(newState, roomState);
    message = getInstructorNoHaveSuit(
      currentState.grandCross,
      newPlayers,
      newState.grandCross,
      newState.suit
    );
    if (message) {
      setTimeout(() => {
        if (
          message.messageContent !== "notifyLeaveRoom" ||
          roomState.table.isPlaying !== true
        ) {
          dispatchMessageCode(message, dispatch);
        }
      }, 500);
    }
  }
};

/**
 * Map all player in room clockwide
 * @param {*} playerState
 * @param {*} roomState
 * @returns
 */
export const mapAllPlayerInRoom = (playerState, roomState) => {
  // set Players
  const newOtherPlayer = [...playerState.otherPlayersState];
  const { numberOfCalls, starCards } = playerState.myState;
  newOtherPlayer[playerState.myIndex] = {
    numberOfCalls,
    starCards,
  };
  return roomState?.joinedPlayers?.map((player, pIdx) => {
    if (null === player) {
      return {};
    }
    return {
      playerId: player.playerId,
      nickName: player.nickName,
      ...newOtherPlayer[pIdx],
    };
  });
};

/**
 * Return step id for captain narration
 * @param {*} currentPlayers
 * @param {*} newPlayers
 * @param {*} captainPlayerId
 * @returns
 */
export const getInstructorEndRound = (
  currentPlayers,
  newPlayers,
  captainPlayerId
) => {
  let narrationId = "";

  const playerIdxTurn = currentPlayers.findIndex(
    (player) => player && player.playerId === captainPlayerId
  );

  if (-1 === playerIdxTurn) {
    return narrationId;
  }

  let endRoundNarration = getNarrationNotify(
    currentPlayers[playerIdxTurn],
    newPlayers[playerIdxTurn]
  );

  /// is GChaos captain
  if (playerIdxTurn === 3) {
    endRoundNarration += "_G_CHAOS";
  }
  return endRoundNarration;
};

/**
 * Get narration notify
 * @param {*} oldPlayer
 * @param {*} newPlayer
 * @returns
 */
const getNarrationNotify = (oldPlayer, newPlayer) => {
  // captain
  let narrationId = STEP_NARRATION.ED_STEP10;
  const numberOldStarCards = oldPlayer?.starCards?.length;
  const numberNewStarCards = newPlayer?.starCards?.length;
  //  is "Get"
  const isGet = numberNewStarCards > numberOldStarCards;
  // is "Eve"
  const isEve = isGet && numberNewStarCards === newPlayer.numberOfCalls - 1;
  // is "breakThrough"
  const isBreakThough = isGet && numberNewStarCards === newPlayer.numberOfCalls;

  if (isGet) {
    narrationId = STEP_NARRATION.ED_STEP11;
  }
  if (isEve) {
    narrationId = STEP_NARRATION.ED_STEP12;
  }
  if (isBreakThough) {
    narrationId = STEP_NARRATION.ED_STEP13;
  }

  console.log("narrationId: ", narrationId);

  return narrationId;
};

/**
 * Get instructor player don't have suit of round
 * @param {*} currentGrandCross
 * @param {*} newPlayers
 * @param {*} newGrandCross
 * @param {*} roundSuit
 * @returns
 */
const getInstructorNoHaveSuit = (
  currentGrandCross,
  newPlayers,
  newGrandCross,
  roundSuit
) => {
  let message = null;
  // index of card same place with player place.
  const indexOfNewCard = newGrandCross.findIndex(
    (newCard, iNewCard) =>
      iNewCard <= 3 && null !== newCard && null === currentGrandCross[iNewCard]
  );
  // GChaos can play any card, GChaos stay in 3.
  if (![-1, 3].includes(indexOfNewCard)) {
    const newCard = newGrandCross[indexOfNewCard];
    // G0 can play anytime player want
    const isG0Card = newCard.suit === CARD_SUIT_G;
    // GChaos can play any card
    if (!isG0Card && newCard.suit !== roundSuit) {
      message = {
        playerIdx: indexOfNewCard,
        messageContent: `${"".concat(roundSuit).toLowerCase()}NoHaveSuit`,
        nickName: newPlayers[indexOfNewCard].nickName,
        isGChaos: false,
      };
    }
  }
  return message;
};

/**
 * Notify room
 * @param {*} currentRoomState
 * @param {*} newState
 * @param {*} dispatch
 * @returns
 */
export const notifyRoom = (currentRoomState, newRoomState, dispatch) => {
  // check join sameRoom
  if (currentRoomState.accessToken !== newRoomState.accessToken) {
    return;
  }

  const oldPlayers = currentRoomState.joinedPlayers.filter(
    (player) => null !== player
  );
  const newPlayers = newRoomState.joinedPlayers.filter(
    (player) => null !== player
  );
  // the firs time load will not check who join room
  if (0 === oldPlayers.length || oldPlayers.length === newPlayers.length) {
    return;
  }

  let players = [];
  let isLeaveRoom = oldPlayers.length > newPlayers.length; // true is lea
  if (isLeaveRoom) {
    players = onlyArrSecond(newPlayers, oldPlayers);
  } else {
    players = onlyArrSecond(oldPlayers, newPlayers);
  }

  players.forEach((newEle) => {
    let message = {
      playerIdx: -1,
      messageContent: isLeaveRoom ? "notifyLeaveRoom" : "notifyJoinRoom",
      nickName: newEle.nickName,
      isGChaos: false,
    };
    if (currentRoomState.table.isPlaying === false) {
      dispatchMessageCode(message, dispatch);
    }
  });
};

/**
 * Only arr sencond
 * @param {*} arrPlayer1
 * @param {*} arrPlayer2
 * @returns
 */
const onlyArrSecond = (arrPlayer1, arrPlayer2) => {
  return arrPlayer2.filter(
    (val) => -1 === arrPlayer1.findIndex((old) => old.playerId === val.playerId)
  );
};

const dispatchMessageCode = (message, dispatch) => {
  dispatch({
    type: ADD_INSTRUCTOR_MESSAGE_CODE,
    payload: {
      message: message,
    },
  });
};

/**
 * Get role of player
 * @param {*} ids
 * @param {*} master
 * @returns
 */
export const getRoles = (ids, master) => {
  const roles = ids.map((id) =>
    id == master ? PLAYER_ROLES.MASTER : PLAYER_ROLES.NORMAL
  );
  roles[3] =
    ids[3] == master ? PLAYER_ROLES.G_CHAOS_MASTER : PLAYER_ROLES.G_CHAOS;
  return roles;
};

/**
 * Is game is end
 * @param {*} gameStatus
 * @returns
 */
export const isGameEnd = (gameStatus) => {
  return [GAME_STATUS.END, GAME_STATUS.WIN, GAME_STATUS.LOSE].includes(
    gameStatus
  );
};

/**
 * Load card image first time when start game
 */
export const loadFirstCardImg = () => {
  const imgs = [];
  //  suit
  const suits = [
    CARD_SUIT_SPADES,
    CARD_SUIT_HEARTS,
    CARD_SUIT_DIAMONDS,
    CARD_SUIT_CLUBS,
  ];
  // mapping card suit
  const numberOfCard = Array(13).fill(null);
  suits.map((suit) => {
    // card suit
    imgs.push(`/cards/${suit}.jpg`);
    numberOfCard.map((_, vIndex) =>
      // card number
      imgs.push(`/cards/${suit}${vIndex + 2}.jpg`)
    );
  });
  // load G0-1
  imgs.push(`/cards/G0.jpg`);
  imgs.push(`/cards/G1.jpg`);

  cacheImages(imgs);
};

/**
 * Cache images
 * @param {*} srcArray
 */
const cacheImages = async (srcArray) => {
  const promises = await srcArray.map((src) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.src = src;
      img.onload = resolve();
      img.onerror = reject();
    });
  });
  await Promise.all(promises);
};

/**
 * Get notify new guit of the round
 * @param {*} suit
 * @param {*} gCard
 * @returns
 */
export const getNotifyNewSuitOfRound = (suit, gCard) => {
  let narrationId = "";
  switch (suit) {
    case CARD_SUIT_SPADES:
      narrationId = STEP_NARRATION.PC_STEP3; // "narration22";
      break;
    case CARD_SUIT_HEARTS:
      narrationId = STEP_NARRATION.PC_STEP4; // "narration23";
      break;
    case CARD_SUIT_DIAMONDS:
      narrationId = STEP_NARRATION.PC_STEP5; // "narration24";
      break;
    case CARD_SUIT_CLUBS:
      narrationId = STEP_NARRATION.PC_STEP6; //"narration25";
      break;
    case CARD_SUIT_WHATEVER:
      if (0 === gCard.value) {
        narrationId = STEP_NARRATION.PC_STEP1; //"narration30_1";
      }
      if (1 === gCard.value) {
        narrationId = STEP_NARRATION.PC_STEP2;
      }
      break;
  }

  return narrationId;
};

/**
 * Get time chat
 * @param {*} sec
 * @returns
 */
export const getTimeChat = (sec) => {
  let t = new Date(Date.UTC(1970, 0, 1));
  t.setUTCSeconds(sec);
  return `${t.getHours()}:${t.getMinutes() < 10 ? "0" : ""}${t.getMinutes()}`;
};

/**
 * Update state after captain narration is showed
 * @param {*} currentState
 * @param {*} newState
 * @returns
 */
export const updateStateAfterCaptainNarration =
  (currentState, newState) => (dispatch) => {
    // in round 12  save grand cross, and round 13 save other state and my state
    if (![13].includes(newState.currentRound)) {
      dispatch(
        saveNewestOtherPlayerState({
          otherPlayersState: [...newState.otherPlayersState],
          grandCross: [...newState.grandCross],
          myState: { ...newState.myState },
        })
      );
      newState.grandCross = currentState.grandCross;
      newState.otherPlayersState = currentState.otherPlayersState;
      newState.myState = currentState.myState;
    }
  };

/**
 * Save player game state in last round
 * @param {*} newState
 * @returns
 */
export const savePlayerGameStateInLastRound = (newState) => (dispatch) => {
  if (13 === newState.currentRound) {
    if (!newState.grandCross.includes(null)) {
      dispatch(saveGrandCrossRound12(newState.grandCross));
    }
    dispatch(
      saveOtherPlayerStateRound13({
        otherPlayersState: [...newState.otherPlayersState],
        myState: { ...newState.myState },
      })
    );
  }
};

export const saveStateNewSuitOnGrandCross = (newState) => (dispatch) => {
  const newSuitGrandCross = [...newState.grandCross];
  dispatch(
    saveStateNewSuit({
      grandCross: newSuitGrandCross,
      myState: { ...newState.myState },
      otherPlayersState: [...newState.otherPlayersState],
    })
  );
};

export const getMessageNickName = (nickName, isGChaoComputer) => {
  if (isGChaoComputer === true) {
    return nickName;
  } else {
    return nickName + SAN;
  }
};

export const clearGChaosComputerSanNickName = (message) => {
  return message ? message?.replace(SAN, "") : "";
};

export const getMessageGChaosSelectAllMC = (grandCross, message) => {
  if (!grandCross || grandCross.length <= 4) return;
  const masterGrandCross = [...grandCross];
  let count = 0;
  for (const card of masterGrandCross) {
    if (card && card.playerIdx === 3) ++count;
  }
  if (count === 2) {
    message = "narrations.gChaosSelectAllMC";
  }
  return message;
};

/**
 * Deep diff between two object, using lodash
 * @param  {Object} object Object compared
 * @param  {Object} base   Object to compare with
 * @return {Object}        Return a new object who represent the diff
 */
export function difference(object, base) {
  function changes(object, base) {
    return _.transform(object, function (result, value, key) {
      if (!_.isEqual(value, base[key])) {
        result[key] =
          _.isObject(value) && _.isObject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
}

/**
 * Sleep for n milliseconds
 * @param {number} ms
 * @returns
 */
export function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

export function reduceNameLength(nickname, nameLength) {
  if (nickname && nickname.length > 0) {
    if (nickname !== "G-Chaos" && nickname.length > 5) {
      return nickname.slice(0, nameLength) + "...";
    }
    return nickname; //G-Chaos
  }
  return "";
}

export function checkGChaosPlayFirst(grandCross) {
  return (
    grandCross[0] === null &&
    grandCross[1] === null &&
    grandCross[2] === null &&
    grandCross[3] !== null
  );
}

export function checkGChaosPlayLast(grandCross) {
  return (
    grandCross[0] !== null &&
    grandCross[1] !== null &&
    grandCross[2] !== null &&
    grandCross[3] === null
  );
}

/**
 * Handle for normal play card condition when G-Chaos is not captain
 *
 * When G-Chaos plays card after someone, we need to deplay it about 3s --> This function help we know is next player game state update is G-Chaos or not,
 * from the current player view
 * @param {any} grandCross
 * @returns
 */
export function nextIsChaos(grandCross) {
  // grandCross length can be greater than 4 when select master cards
  if (grandCross.length === 4) {
    const isPlayerJustBeforeGChaosPlayedCard = grandCross[2] !== null;
    const isGChaosPlayedCard = grandCross[3] !== null;
    const isGChaosPlayLast = checkGChaosPlayLast(grandCross);
    if (isGChaosPlayLast === true) {
      return false;
    }
    if (
      isPlayerJustBeforeGChaosPlayedCard === true &&
      isGChaosPlayedCard === false
    ) {
      return true;
    }
    return false;
  }
  return false;
}

/**
 * This function check if the player is the captain of next turn (next turn mean, after grand cross is clear).
 * Because we want to delay the turn indicator of the player (the red flag)
 * @param {*} currentPlayerState : can be playerGameState or CardPlayer[1-2-3] current state
 * @param {*} newPlayerState : can be playerGameState or CardPlayer[1-2-3] new state
 * @param {*} currentGrandCross : current grandCross of playerGameState
 * @returns
 */
export function checkCaptainButNotShowTurnYet(
  currentPlayerState,
  newPlayerState,
  currentGrandCross
) {
  const isMyTurn = newPlayerState.isMyTurn === true;
  const currentEndRound =
    currentGrandCross.filter((e) => e !== null).length === 4;
  const isCaptain = currentPlayerState.turnStatus === TURN_STATUS.CAPTAIN;
  return isMyTurn && currentEndRound && isCaptain;
}
export function calIsMyTurn(otherPlayerStates, index) {
  return otherPlayerStates[index]?.isYourTurn ?? false;
}

/**
 * todo: check step to use order cards
 * @param {string} stepId
 * @returns
 */
export const checkStepOrderCards = (stepId) => {
  return [
    STEP_NARRATION.G_CHAOS_SELECT_ORDER_CARD_FIRST,
    STEP_NARRATION.PLAYER_SELECT_ORDER_CARD_FIRST,
    STEP_NARRATION.G_CHAOS_SELECT_ORDER_CARD_1ST_TIME,
    STEP_NARRATION.PLAYER_SELECT_ORDER_CARD_1ST_TIME,
    STEP_NARRATION.PLAYER_SELECT_ORDER_CARD_2ND_TIME,
    STEP_NARRATION.PLAYER_SELECT_ORDER_CARD_3RD_TIME,
    STEP_NARRATION.CONFIRM_END_ORDER_CARD,
    STEP_NARRATION.CONFIRM_OPEN_ORDER_CARD,
    STEP_NARRATION.SG_STEP14,
    STEP_NARRATION.SG_STEP15,
    STEP_NARRATION.SG_STEP16,
    STEP_NARRATION.SG_STEP22,
  ].includes(stepId);
};

/**
 * todo: check step to use master cards
 * @param {string} stepId
 * @returns
 */
export const checkStepMasterCards = (stepId) => {
  return [
    STEP_NARRATION.UM_STEP1,
    STEP_NARRATION.UM_STEP2,
    STEP_NARRATION.UM_STEP3,
    STEP_NARRATION.UM_STEP4,
    STEP_NARRATION.UM_STEP5,
    STEP_NARRATION.UM_STEP6,
    STEP_NARRATION.UM_STEP7,
    STEP_NARRATION.UM_STEP8,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP7,
  ].includes(stepId);
};
/**
 * todo: check step to use master cards
 * @param {string} stepId
 * @returns
 */
export const checkStepMasterCardsGChaosComputer = (stepId) => {
  return [
    STEP_NARRATION.UM_STEP1,
    STEP_NARRATION.UM_STEP2,
    STEP_NARRATION.UM_STEP3,
    STEP_NARRATION.UM_STEP4,
    STEP_NARRATION.UM_STEP5,
    STEP_NARRATION.UM_STEP6,
    STEP_NARRATION.UM_STEP7,
    STEP_NARRATION.UM_STEP8,
  ].includes(stepId);
};

/**
 * todo: check step to use master cards
 * @param {string} stepId
 * @returns
 */
export const checkStepMasterCardsGChaosHuman = (stepId) => {
  return [
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5,
    STEP_NARRATION.UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6,
  ].includes(stepId);
};
/**
 * todo: check first player
 * @param {*} orderCards
 * @case has g-chaos and g-chaos select first
 * @case no g-chaos and no player select
 * @returns
 */
export const checkFirstPlayerSelectOrderCard = (orderCards = []) => {
  try {
    if (orderCards.length === 0) return;
    let isFirstPlayer = false;
    const firstValue = orderCards.filter((e) => e !== null)[0];
    let nicknameCount = 0;
    orderCards.forEach((e) => {
      if (e && e.nickName !== "") ++nicknameCount;
    });
    const playerIndexes = firstValue.playersIndex;
    const hasGChaos = playerIndexes.indexOf(3) !== -1;
    if (nicknameCount === 0) {
      isFirstPlayer = true;
    }
    if (nicknameCount === 1 && hasGChaos) {
      isFirstPlayer = true;
    }
    return isFirstPlayer;
  } catch (error) {
    console.log(error);
  }
};

/**
 * Not show message after last player select
 * @param {*} orderCards
 * @returns
 */
export const checkLastPlayerSelectOrderCard = (orderCards = []) => {
  try {
    if (orderCards.length === 0) return;
    const _orderCards = orderCards.filter((e) => e !== null);
    const cardCount = _orderCards.length;
    let nickNameCount = 0;
    _orderCards.forEach((e) => {
      if (e && e.nickName !== "") ++nickNameCount;
    });
    if (cardCount === nickNameCount) return true;
    return false;
  } catch (error) {
    console.log("checkLastPlayerSelectOrderCard: ", error);
  }
};

export const waitUseMasterCard = (turnStatus = "") => {
  return turnStatus === TURN_STATUS.WAIT_RF;
};

export const checkStepConfirmAfterSelectOrderCards = (stepId) => {
  return [
    STEP_NARRATION.SG_STEP14,
    STEP_NARRATION.SG_STEP15,
    STEP_NARRATION.SG_STEP16,
    STEP_NARRATION.SG_STEP22,
  ].includes(stepId);
};

export const checkPopupGetNicknameSelectOrderCards = (popupId) => {
  return [
    POPUPS.G_CHAOS_SELECT_ORDER_CARD,
    POPUPS.PLAYER_SELECT_ORDER_CARD,
  ].includes(popupId);
};

/**
 * todo: check use master cards or not
 * @param {*} newState
 * @returns
 */
export const checkIsHideAllTurn = (newState) => {
  try {
    let isHideAllTurn = false;
    const stepId = newState?.stepId;

    isHideAllTurn = checkStepMasterCardsGChaosComputer(stepId);
    if (isHideAllTurn) return isHideAllTurn;
    return isHideAllTurn;
  } catch (error) {
    console.log("checkIsHideAllTurn: ", error);
  }
};

/**
 * todo: update other player state
 * @param {*} newState
 * @returns
 */
export const hideAllTurnPlayers = (newState) => {
  try {
    const otherPlayerState = newState?.otherPlayersState ?? [];
    const myIndex = newState?.myIndex ?? -1;
    const numberOfCalls = newState?.myState?.numberOfCalls ?? -1;
    const starCards = newState?.myState?.starCards ?? [];
    const isUpdate =
      otherPlayerState.length > 0 && myIndex >= 0 && numberOfCalls >= 0;
    let newOtherPlayerState = [...otherPlayerState];
    if (!isUpdate) return newOtherPlayerState;

    newOtherPlayerState = [...otherPlayerState].map((item) => ({
      ...item,
      isYourTurn: false,
    }));
    newOtherPlayerState[myIndex]["numberOfCalls"] = numberOfCalls;
    newOtherPlayerState[myIndex]["starCards"] = starCards;
    return newOtherPlayerState;
  } catch (error) {
    console.log("hideAllTurnPlayers:", error);
  }
};

export const checkStepsShowMaster = (stepId) => {
  return [
    STEP_NARRATION.SG_STEP14,
    STEP_NARRATION.SG_STEP15,
    STEP_NARRATION.SG_STEP16,
    STEP_NARRATION.SG_STEP17,
    STEP_NARRATION.SG_STEP18,
    STEP_NARRATION.SG_STEP19,
    STEP_NARRATION.SG_STEP21,
    STEP_NARRATION.SG_STEP22,
    STEP_NARRATION.SG_STEP23,
    STEP_NARRATION.SG_STEP24,
  ].includes(stepId);
};

export const isShowTutorialCardsEndGame = (
  cards = [],
  popupId = "",
  gameStatus = ""
) => {
  return (
    [
      SCREEN_NARRATION.ED_STEP2_END_GAME,
      SCREEN_NARRATION.ED_STEP3_END_GAME,
      SCREEN_NARRATION.ED_STEP4_END_GAME,
      SCREEN_NARRATION.SG_STEP12_END_GAME,
      SCREEN_NARRATION.SG_STEP12_STOP_TUTORIAL,
    ].includes(popupId) &&
    cards.length > 0 &&
    gameStatus === GAME_STATUS.LOSE
  );
};

export const getSourceImages = () => {
  const suits = ["Clubs", "Diamonds", "Hearts", "Spades"];
  const values = [2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14];
  const deck = [];
  for (const suit of suits) {
    deck.push(`cards/${suit}.jpg`);
    for (const value of values) {
      deck.push(`cards/${suit}${value}.jpg`);
    }
  }
  deck.push(`cards/G0.jpg`);
  deck.push(`cards/G1.jpg`);
  deck.push(`cards/back.png`);
  deck.push(`assets/bg_master_cards.png`);
  deck.push(`assets/logo_ark.png`);
  deck.push(`assets/wingame_bg.png`);
  deck.push(`assets/wingame_orange.png`);
  deck.push(`assets/wingame_white.png`);
  deck.push(`assets/realGif.gif`);
  return deck;
};

export const mapPlayersDeclareCalls = (
  joinedPlayers = [],
  myNumberOfCalls = -1,
  myIndex = -1,
  otherPlayerState = []
) => {
  try {
    if (
      myIndex === -1 ||
      joinedPlayers.length < 4 ||
      otherPlayerState.length === 0
    )
      return;
    const newPlayers = [
      ...joinedPlayers.slice(myIndex),
      ...joinedPlayers.slice(0, myIndex),
    ];
    const numberOfCalls = otherPlayerState.map((other) =>
      other !== null ? other.numberOfCalls : myNumberOfCalls
    );
    const newNumberOfCalls = [
      ...numberOfCalls.slice(myIndex),
      ...numberOfCalls.slice(0, myIndex),
    ];
    return newPlayers.map((player, index) => ({
      nickName: player?.nickName ?? "",
      numberOfCalls: newNumberOfCalls[index],
    }));
  } catch (error) {
    console.error(error);
  }
};

export function subDate(time) {
  return (Date.now() - new Date(time).getTime()) / ONE_DAY;
}

export function expiredTrial(time) {
  if (!time) return false;
  return subDate(time) > TRIAL_DAYS;
}

export function numberWithCommas(num) {
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
