import { makeStyles } from "@material-ui/core";
import { Box, DialogTitle } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import PropTypes from "prop-types";
import React from "react";
import { useTranslation } from "react-i18next";
import { blockFriend } from "../../../../store/actions/friendAction";

const useStyles = makeStyles(() => ({
  left: {
    display: "flex",
    flexDirection: "row-reverse",
    backgroundColor: "transparent",
    boxSizing: "border-box",
    padding: "10px 20px",
  },
  closeButton: {
    // backgroundColor: "red",
    color: "white",
    borderRadius: 0,
    boxSizing: "border-box",
    padding: 0,
    margin: 0,
    width: 20,
    height: 20,
  },
  closeImg: {
    width: "18px",
  },
  dialogTitle: {
    fontSize: "1rem !important",
    padding: "16px 47px !important",
    color: "#442211 !important",
    fontWeight: "normal !important",
  },
  dialogActions: {
    padding: "0 !important",
    justifyContent: "space-evenly !important",
  },
  dialogButton: {
    background: "#442211 0% 0% no-repeat padding-box !important",
    borderRadius: "9px !important",
    width: "74px !important",
    height: "21px !important",
    color: "#fff !important",
    fonWeight: "normal !important",
    fontSize: "0.7rem !important",
  },
}));

const BlockFriendRequest = ({ isOpen, onClose, myId, friendId }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const handleSuccess = () => {
    blockFriend(myId, friendId);
    onClose(false);
  };

  return (
    <Dialog
      open={isOpen}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      PaperProps={{
        style: {
          backgroundColor: "#C1AE8A",
          borderRadius: "7px",
          width: "424px",
          height: "146px",
        },
      }}
    >
      <Box className={classes.left}>
        <Button className={classes.closeButton} onClick={() => onClose(false)}>
          <img
            className={classes.closeImg}
            src={require("../../../../assets/icons/icon-close.svg").default}
            alt="close"
          />
        </Button>
      </Box>
      <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">
        {t("dialog.BlockFriendRequestTitle")}
      </DialogTitle>
      <DialogActions className={classes.dialogActions}>
        <Button className={classes.dialogButton} onClick={handleSuccess}>
          {t("dialog.yes")}
        </Button>
        <Button className={classes.dialogButton} onClick={() => onClose(false)}>
          {t("dialog.no")}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

BlockFriendRequest.propTypes = {
  isOpen: PropTypes.bool,
  myId: PropTypes.string,
  friendId: PropTypes.string,
  onClose: PropTypes.func,
};

export default BlockFriendRequest;
