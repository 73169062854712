import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import PropTypes from "prop-types";
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import BronzeIcon from "../../../assets/icons/bronze.png";
import GoldIcon from "../../../assets/icons/gold.png";
import PlatinumlIcon from "../../../assets/icons/platinum.png";
import SilverlIcon from "../../../assets/icons/silver.png";
import TrialIcon from "../../../assets/icons/trial.png";
import PackageCard from "../../../components/PackageCard";
import { apiStatus, PACKAGE_BACK_GROUND_COLOR } from "../../../constants";
import DialogConfirm from "../../../pages/GameScreen/components/Dialog/DialogConfirm";
import {
  subscribePackageNextMonth,
  switchPackge,
} from "../../../store/actions/packageActions";
import { formatDateJapan } from "../../../utils/time";
import ButtonCustom from "../../Button";
import { numberWithCommas } from "../../../utils/common";
import { BUYING_PLAN_OR_PACKAGE } from "../../../store/types";
import useStyles from "./styles";

const BySubDialog = ({
  goBack,
  openDialog,
  handleLoading,
  isInGameSreen,
  handleRedirect,
}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const classes = useStyles({});
  const [open, setOpen] = useState(false);
  const [openDialogErrorCard, setOpenDialogErrorCard] = useState(false);
  const [seclectedPackage, setSeclectedPackage] = useState(null);
  const playerId = useSelector((state) => state.user.playerId);
  const paymentMethodId = useSelector((state) => state.user.paymentMethodId);
  const packages = useSelector((state) => state.package.packages);
  const activePackage = useSelector((state) => state.package.activePackage);
  const playTimeInfo = useSelector((state) => state.playTime.playTimeInfo);
  const iconPackage = {
    GOLD: GoldIcon,
    TRIAL: TrialIcon,
    BRONZE: BronzeIcon,
    PLATINUM: PlatinumlIcon,
    SILVER: SilverlIcon,
  };

  const handleDisableButton = (_packageName) => {
    return !!(
      (playTimeInfo?.subscriptionNextMonth?.isSubscriptionNextMonth &&
        playTimeInfo?.subscriptionNextMonth?.packageName === _packageName) ||
      (!playTimeInfo?.subscriptionNextMonth?.isSubscriptionNextMonth &&
        playTimeInfo?.packageName === _packageName)
    );
  };

  const getBackgroudColorPackage = (packageName, status) => {
    if (packageName === "Bronze" && status) {
      return PACKAGE_BACK_GROUND_COLOR.TRIAL;
    }
    return PACKAGE_BACK_GROUND_COLOR[`${packageName?.toUpperCase()}`];
  };

  const getIconPackage = (packageName, status) => {
    if (packageName === "Bronze" && status) {
      return iconPackage.TRIAL;
    }
    return iconPackage[`${packageName?.toUpperCase()}`];
  };

  const handleClose = useCallback(() => {
    setSeclectedPackage(null);
    setOpen(false);
  }, []);

  const handleSubmitDialogErrorCard = useCallback(() => {
    setOpenDialogErrorCard(false);
    goBack();
    handleRedirect();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleShowDialogChangePlan = (_package) => {
    if (paymentMethodId) {
      setSeclectedPackage(_package);
      setOpen(true);
    } else {
      setOpenDialogErrorCard(true);
    }
  };

  const handleSubscribe = useCallback(async () => {
    if (activePackage && seclectedPackage && playerId && playTimeInfo) {
      setOpen(false);
      // handleLoading(true);
      dispatch({
        type: BUYING_PLAN_OR_PACKAGE,
        payload: true,
      });
      if (playTimeInfo.statusFree) {
        const resulf = await subscribePackageNextMonth(
          playerId,
          seclectedPackage,
          playTimeInfo
        );
        if (resulf.data.status === apiStatus.FAIL) {
          toast.error(t("paymentSettings.ChangePlanFail"));
          handleLoading(false);
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        } else if (resulf.data.status === apiStatus.SUCCESS) {
          toast.success(t("paymentSettings.ChangePlanSuccess"));
          // handleLoading(false);
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        }
        return;
      }

      if (seclectedPackage.packageIndex > activePackage.packageIndex) {
        const resulf = await switchPackge(
          activePackage,
          seclectedPackage,
          playerId
        );
        if (resulf.data.status === apiStatus.FAIL) {
          toast.error(t("paymentSettings.ChangePlanFail"));
          // handleLoading(false);
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        } else if (resulf.data.status === apiStatus.SUCCESS) {
          toast.success(t("paymentSettings.ChangePlanSuccess"));
          // handleLoading(false);
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        }
      } else {
        const resulf = await subscribePackageNextMonth(
          playerId,
          seclectedPackage,
          playTimeInfo
        );
        if (resulf.data.status === apiStatus.FAIL) {
          toast.error(t("paymentSettings.ChangePlanFail"));
          // handleLoading(false);
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        } else if (resulf.data.status === apiStatus.SUCCESS) {
          toast.success(t("paymentSettings.ChangePlanSuccess"));
          // handleLoading(false);
          dispatch({
            type: BUYING_PLAN_OR_PACKAGE,
            payload: false,
          });
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePackage, seclectedPackage, playerId]);

  return (
    <Dialog
      open={openDialog}
      className={classes.dialogWapper}
      fullWidth
      maxWidth="lg"
      classes={{ paper: classes.paper }}
    >
      <DialogContent className={classes.dialogContent}>
        <Box className={classes.ChangePlanBox} style={{ color: "#541513" }}>
          <Typography className={classes.dialogContentTilte}>
            {t("paymentSettings.changePlanTitle")}
          </Typography>
          <Box className={classes.PlanCurrent}>
            <Box
              className={classes.ListPlan}
              style={{ justifyContent: "space-between", alignItems: "end" }}
            >
              <Box className={classes.ListPlanBox}></Box>
              {activePackage && !playTimeInfo?.statusFree && (
                <Box className={classes.ListPlanBox}>
                  <Box className={classes.TitleBox}>
                    <Typography className={classes.PlanCurrentTitle}>
                      {t("paymentSettings.currentPlan")}
                    </Typography>
                  </Box>
                  <PackageCard
                    packageName={playTimeInfo?.packageName}
                    packagePrice={activePackage?.packagePrice}
                    isShowPackagePrice
                    packagePlayTimes={activePackage?.packagePlayTimes}
                    isShowDesciption
                    packageIcon={
                      <img
                        src={`${getIconPackage(
                          playTimeInfo?.packageName,
                          playTimeInfo?.statusFree
                        )}`}
                      />
                    }
                    backgroundColor={getBackgroudColorPackage(
                      playTimeInfo?.packageName,
                      playTimeInfo?.statusFree
                    )}
                  />
                </Box>
              )}

              {activePackage && playTimeInfo?.statusFree && (
                <Box className={classes.ListPlanBox}>
                  <Box className={classes.TitleBox}>
                    <Typography className={classes.PlanCurrentTitle}>
                      {t("paymentSettings.currentPlan")}
                    </Typography>
                  </Box>
                  <PackageCard
                    packageName="trial"
                    packageIcon={
                      <img
                        src={`${getIconPackage(
                          playTimeInfo?.packageName,
                          playTimeInfo?.statusFree
                        )}`}
                      />
                    }
                    backgroundColor={getBackgroudColorPackage(
                      playTimeInfo?.packageName,
                      playTimeInfo?.statusFree
                    )}
                    color="#fff"
                  />
                </Box>
              )}
              <Box className={classes.ListPlanBox}>
                {playTimeInfo?.subscriptionNextMonth?.isSubscriptionNextMonth &&
                  playTimeInfo?.subscriptionNextMonth?.packageName !==
                    playTimeInfo?.packageName && (
                    <>
                      <Box className={classes.TitleBox}>
                        <Typography className={classes.PlanCurrentTitle}>
                          変更予定のプラン
                        </Typography>
                        <Typography className={classes.PlanCurrentTitle}>
                          {`(${formatDateJapan(
                            playTimeInfo?.subscriptionNextMonth
                              ?.nextDaySubscription
                          )})`}
                        </Typography>
                      </Box>
                      <PackageCard
                        packageName={
                          playTimeInfo?.subscriptionNextMonth?.packageName
                        }
                        packagePrice={
                          playTimeInfo?.subscriptionNextMonth?.packagePrice
                        }
                        isShowPackagePrice
                        packagePlayTimes={
                          playTimeInfo?.subscriptionNextMonth?.packagePlayTimes
                        }
                        isShowDesciption
                        packageIcon={
                          <img
                            src={`${getIconPackage(
                              playTimeInfo?.subscriptionNextMonth?.packageName,
                              false
                            )}`}
                          />
                        }
                        reposinve
                        backgroundColor={getBackgroudColorPackage(
                          playTimeInfo?.subscriptionNextMonth?.packageName,
                          false
                        )}
                      />
                    </>
                  )}
              </Box>
            </Box>
          </Box>
          <Box className={classes.ListPlanWrapp}>
            <Typography className={classes.ListPlanTitle}>
              {t("paymentSettings.choosePlan")}
            </Typography>
            <Box className={classes.ListPlan}>
              {packages &&
                packages.length > 0 &&
                packages.map((item) => (
                  <Box className={classes.ListPlanBox} key={item?.packageName}>
                    <Box>
                      <PackageCard
                        packageName={item.packageName}
                        packagePrice={item.packagePrice}
                        isShowPackagePrice
                        packagePlayTimes={item.packagePlayTimes}
                        isShowDesciption
                        packageIcon={
                          <img
                            src={`${getIconPackage(
                              item.packageName,
                              item.statusFree
                            )}`}
                          />
                        }
                        backgroundColor={getBackgroudColorPackage(
                          item.packageName,
                          item.statusFree
                        )}
                      />
                    </Box>
                    <Button
                      variant="contained"
                      style={{
                        backgroundColor: "#ce5931",
                        textTransform: "capitalize",
                        marginTop: "10px",
                      }}
                      disabled={handleDisableButton(item?.packageName)}
                      className={classes.packageBtn}
                      onClick={() => {
                        handleShowDialogChangePlan(item);
                      }}
                    >{`${
                      handleDisableButton(item?.packageName)
                        ? t("paymentSettings.Purchased")
                        : t("paymentSettings.Purchase")
                    }`}</Button>
                  </Box>
                ))}
            </Box>
          </Box>
          <div className={classes.btnGroup}>
            <Box className={classes.btns}>
              <ButtonCustom
                className={classes.buttonBlue}
                onClick={() => goBack()}
                text={t("paymentSettings.back")}
              />
            </Box>
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography>*価格はすべて税込です</Typography>
            </Box>
          </div>
          <DialogConfirm
            open={open}
            handleClose={handleClose}
            handleSubmit={handleSubscribe}
            contentConfirm={t("paymentSettings.changePlanConfirmTitle", {
              package: `${t(
                "paymentSettings." + seclectedPackage?.packageName
              )}`,
              price: `${
                seclectedPackage?.packagePrice
                  ? numberWithCommas(seclectedPackage?.packagePrice)
                  : 0
              }`,
            })}
            okLabel={t("paymentSettings.oke")}
            cancelLabel={t("paymentSettings.cancel")}
          />
          <DialogConfirm
            open={openDialogErrorCard}
            handleClose={() => setOpenDialogErrorCard(false)}
            handleSubmit={handleSubmitDialogErrorCard}
            contentConfirm={t("paymentSettings.dialogErrorCard")}
            okLabel="プラン詳細へ"
            cancelLabel="閉じる"
            isNoShowConfirmButton={isInGameSreen ? true : false}
          />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

BySubDialog.propTypes = {
  goBack: PropTypes.func,
  handleLoading: PropTypes.func,
  openDialog: PropTypes.bool,
  isInGameSreen: PropTypes.bool,
  handleRedirect: PropTypes.func,
};

export default BySubDialog;
