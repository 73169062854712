import React, { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";

const ChangePositionButtonStyled = styled(Button)`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  font-size: ${(props) => props.fontSize}px;
  font-family: "Source Serif Pro";
  font-weight: 400;
  letter-spacing: 0;
  font-weight: 100;
  text-transform: uppercase;
  background-image: url("/assets/btn_blue.png");
  margin-top: ${(props) => props.marginTop}px;
  background-size: 100% 100%;
  padding: ${(props) => props.fontSize / 1.2}px 0;
  background-repeat: no-repeat;
  color: white;
  ${(props) =>
    props.disable &&
    css`
      opacity: 0.6;
    `}
  &:hover {
    transform: scale(1.01);
    transition: all 0.4s ease-in-out;
  }
`;

const ChangePositionButton = React.memo(function ChangePositionButtonComponent({
  isDisable,
  onClick,
}) {
  const { t } = useTranslation();
  const size = useSelector((state) => state.size);
  const longButtonWidth = useSelector((state) => state.size.longButtonWidth);
  const longButtonHeight = useSelector((state) => state.size.longButtonHeight);
  const [innerSize, setInnerSize] = useState({});
  useEffect(() => {
    if (size) {
      const startGameFontSize = 16 * size?.minScale;
      setInnerSize({
        startGameFontSize,
      });
    } else {
      const startGameFontSize = 16;
      setInnerSize({
        startGameFontSize,
      });
    }
  }, [size]);
  return (
    <ChangePositionButtonStyled
      onClick={onClick}
      isdiable={isDisable}
      width={longButtonWidth}
      height={longButtonHeight}
      fontSize={innerSize.startGameFontSize}
    >
      {t("game.changePosition")}
    </ChangePositionButtonStyled>
  );
});

ChangePositionButton.propTypes = {
  isDisable: PropTypes.bool,
  onClick: PropTypes.func,
};

export default ChangePositionButton;
