import { makeStyles } from "@material-ui/core";
import styled from "styled-components";
import { COLOR_BLUE_LIGHT } from "../../constants";
export const useStyle = makeStyles({
  closeIcon: {
    color: "black",
    fontSize: "1em",
    width: "1em",
  },
  iconBtn: {
    backgroundColor: "white !important",
    width: "1.1em",
    height: "1.1em",
  },
  dialog: {
    borderRadius: "1em 2em !important",
  },
});

export const BaseDialogHeader = styled.div`
  background-color: ${COLOR_BLUE_LIGHT};
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  padding: 0 0.7em;
  height: 3em;
`;
