import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  packageBox: {
    backgroundColor: (props) =>
      `${props.backgroundColor ? props.backgroundColor : "#e3e2e1"}`,
    padding: "20px 10px",
    position: "relative",
    textAlign: "center",
    color: (props) => `${props.color ? props.color : "#541513"}`,
    borderRadius: "2px",
    width: "100%",
    boxSizing: "border-box",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100px",
    marginTop: "30px !important",
    boxShadow:
      "rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px",
  },
  packageIconBox: {
    position: "absolute",
    top: "-32px",
    left: "50%",
    transform: "translateX(-50%)",

    "& img": {
      width: "50px",
      height: "50px",
    },
  },
  textRepnsive: {
    fontWeight: "bold !important",
    "@media (max-width: 991px)": {
      fontSize: "0.7rem !important",
    },
  },
  text: {
    fontWeight: "bold !important",
  },
}));

export default useStyles;
