import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  dialogContent: {
    width: "90%",
    height: "300px",
    position: "relative",
    padding: "1em 2em",
  },
  search: {
    position: "sticky",
    width: "100%",
  },
  listRequests: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: "100%",
    "&>*": {
      margin: "10px",
    },
    overflow: "hidden",
  },
  requestItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  nickname: {
    flex: 1,
    marginLeft: "10px",
  },
  buttonInvite: {
    backgroundColor: "#283048",
    textTransform: "capitalize",
    color: "#ffffff",
    "&:hover": {
      backgroundColor: "#283048",
    },
  },
}));

export default useStyles;
