import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
  style: {
    color: theme.palette.error.main,
    maxWidth: "350px",
    fontWeight: "600",
    margin: "auto",
    fontSize: 14,
  },
}));

const ErrorMessage = ({ message }) => {
  const classes = useStyles();
  return <Typography className={classes.style}>{message}</Typography>;
};

ErrorMessage.propTypes = {
  message: PropTypes.string,
};

export default ErrorMessage;
