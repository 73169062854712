import {
  ADD_LEAVE_ROOM_NOTIFY_STEP,
  ADD_NARRATION,
  ADD_NARRATION_POPUP,
  ADD_NARRATION_SCREEN,
  CLEAR_NARRATION,
  DONE_NARRATION,
  REMOVE_NARRATION_POPUP,
  REMOVE_NARRATION_SCREEN,
  STOP_CURRENT_NARRATION,
  UPDATE_DURATION,
  REMOVE_STOP_CURRENT_NARRATION,
} from "../types";

const initialState = {
  narrationId: "",
  currentNarrationId: "",
  duration: 0,
  narrationPopup: "",
  narrationScreen: "",
  stopNarrationId: "",
  messageEnd: false,
};

const instructorNotifyReducer = (state = initialState, action) => {
  switch (action.type) {
    case ADD_NARRATION:
      return {
        ...state,
        narrationId: action.payload.narrationId,
        duration: action.payload.duration,
        currentNarrationId: action.payload.narrationId,
      };
    case UPDATE_DURATION:
      return {
        ...state,
        duration: action.payload.duration,
      };
    case ADD_NARRATION_SCREEN:
      return {
        ...state,
        narrationScreen: action.payload.narrationScreen,
      };
    case REMOVE_NARRATION_SCREEN:
      return {
        ...state,
        narrationScreen: "",
      };
    case ADD_NARRATION_POPUP:
      return {
        ...state,
        narrationPopup: action.payload.narrationPopup,
      };
    case REMOVE_NARRATION_POPUP:
      return state.narrationPopup === action.payload.narrationPopup
        ? {
            ...state,
            narrationPopup: "",
          }
        : state;
    case DONE_NARRATION: {
      return {
        ...state,
        narrationId: "",
        duration: 0,
        currentNarrationId: "",
        narrationText: "",
      };
    }

    case STOP_CURRENT_NARRATION:
      return {
        ...state,
        stopNarrationId: STOP_CURRENT_NARRATION,
        narrationPopup: "",
        narrationScreen: "",
      };

    case REMOVE_STOP_CURRENT_NARRATION:
      return {
        ...state,
        stopNarrationId: "",
      };

    case CLEAR_NARRATION:
      return initialState;

    case ADD_LEAVE_ROOM_NOTIFY_STEP:
      return initialState;
    default:
      return state;
  }
};

export default instructorNotifyReducer;
