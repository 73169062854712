import axios from "axios";
import firebase, { getFunctions } from "../../services/firebase";
import { setError } from "../actions/setMessage";
import { ERROR_CODE_MESSAGE } from "../constant";
import {
  RESEND_VERIFICATION_EMAIL,
  SEND_MAIL_RESET_PASSWORD,
  SEND_MAIL_CREATED_ACCOUNT,
} from "../funcs";
import { SET_UNSUBSCRIBE, SET_USER_INFO, SET_USER_LOGOUT } from "../types";
import { countInvitation, subscribeToBronzePackage } from "./packageActions";
import { createEmptySubscription } from "./subscriptionActions";
import { acceptFriendRequest } from "./userAction";

const auth = firebase.auth();
const db = firebase.firestore();
const functions = getFunctions();

export const createAccount = async (data, isStaff) => {
  try {
    const { email, password } = data;
    const res = await auth.createUserWithEmailAndPassword(email, password);
    if (res.user) {
      const player = await saveUserToFireStore(res.user, isStaff);
      await createEmptySubscription(player.playerId, player.email);
      await subscribeToBronzePackage(player.playerId);
      await auth.signOut();
    }
    return "";
  } catch (err) {
    await auth.signOut();
    if (err.code === "auth/email-already-in-use") {
      return "errors.emailExist";
    }
    if (err.code === "auth/too-many-request") {
      return "";
    }
    if (err.code === "auth/network-request-failed") {
      return "validate.noConnectionNetwork";
    }
    return err.code;
  }
};

export const createAccountWithPhone = async (user, email, numberPhone) => {
  try {
    if (user) {
      const player = await saveUserPhoneToFireStore(user, email, numberPhone);
      await createEmptySubscription(player.playerId, player.email);
      await subscribeToBronzePackage(player.playerId);
      await sendMailCreatedAccount(email);
      await auth.signOut();
    }
    return "";
  } catch (err) {
    await auth.signOut();
  }
};

export const createUserWithPhone = async (phoneNumber, email) => {
  try {
    const func = functions.httpsCallable("createUser");
    return func({ phoneNumber, email });
  } catch (error) {
    console.log(error);
  }
};

const saveUserPhoneToFireStore = async (user, email, numberPhone) => {
  const createdDate = new Date();
  const userData = {
    playerId: user.uid,
    fullName: user.displayName,
    nickName: "",
    friendInvitations: [],
    dateOfBirth: "",
    gender: "4",
    email: email,
    avatar: "",
    firstLogin: true,
    lastLogin: "",
    friends: [],
    isPlaying: false,
    createDate: createdDate.toISOString(),
    snsId: "",
    setting: {
      joinTableRole: "open",
      defaultTableName: "",
      defaultAvatar: "",
      isNarrationSound: false,
      isNarrationText: true,
    },
    totalScore: 0,
    providerId: "password", //user.providerData[0].providerId
    currentRoomJoinedId: null,
    privateInvitations: [],
    registeredTime: new Date().toUTCString(),
    numberOfGames: 0,
    role: "user",
    numberPhone: numberPhone,
  };
  await db.collection("players").doc(userData.playerId).set(userData);
  return userData;
};

/**""
 * Add new friend player-guest (invitor and invited guest)
 * @param {*} player
 * @param {*} invitationData
 */
export const addFriendAndCountInvitation = async (player, invitationData) => {
  if (invitationData.playerId !== undefined) {
    await acceptFriendRequest(player.playerId, invitationData.playerId);
    await countInvitation(invitationData.playerId);
  }
};

export const reSendVerifyEmail = async (email, password, url) => {
  try {
    const res = await auth.signInWithEmailAndPassword(email, password);
    if (!res.user.emailVerified) {
      const data = { email, url };
      const func = functions.httpsCallable(RESEND_VERIFICATION_EMAIL);
      await func(data);
    }
  } catch (error) {
    console.log(error);
  } finally {
    await auth.signOut();
  }
};
export const signInWithEmail = (email, password) => {
  return async (dispatch) => {
    try {
      const res = await auth.signInWithEmailAndPassword(email, password);
      if (!res.user.emailVerified) {
        auth.signOut();
        return "errors.emailIsNotVerified";
      }
      if (res.user.uid) {
        const doc = await db.collection("players").doc(res.user.uid).get();
        if (doc.exists) {
          dispatch({ type: SET_USER_INFO, payload: doc.data() });
        }
      }
      return "";
    } catch (err) {
      if (err.code === "auth/wrong-password") {
        return "errors.wrongPassword";
      }
      if (err.code === "auth/user-not-found") {
        return "errors.wrongPassword";
      }
      if (err.code === "auth/too-many-requests") {
        return "errors.tooManyRequests";
      }
      if (err.code === "auth/network-request-failed") {
        return "validate.noConnectionNetwork";
      }
      if (err.code === "permission-denied") {
        return "admin.youDoNotHaveaccess";
      }
      console.log(err.code);
      return err;
    }
  };
};

const saveUserToFireStore = async (user, accessToken, profile, idToken) => {
  const trialExpiredDate = new Date();
  trialExpiredDate.setDate(trialExpiredDate.getDate() + 3);
  const createdDate = new Date();
  const userData = {
    playerId: user.uid,
    fullName: user.displayName,
    nickName: "",
    friendInvitations: [],
    dateOfBirth: "",
    gender: "4",
    email: user.providerData[0].email,
    avatar: user.photoURL,
    firstLogin: true,
    lastLogin: "",
    friends: [],
    isPlaying: false,
    createDate: createdDate.toISOString(),
    snsId: "",
    setting: {
      joinTableRole: "open",
      defaultTableName: "",
      defaultAvatar: "",
      isNarrationSound: false,
      isNarrationText: true,
    },
    totalScore: 0,
    providerId: user.providerData[0].providerId,
    currentRoomJoinedId: null,
    privateInvitations: [],
    registeredTime: new Date().toUTCString(),
    numberOfGames: 0,
    role: "user",
  };
  if (user.providerData[0].providerId === "password") {
    userData.avatar = "";
  }

  if (user.providerData[0].providerId === "google.com") {
    const response = await axios.get(
      `https://oauth2.googleapis.com/tokeninfo?id_token=${idToken}`
    );
    userData.email = response.data.email;
  }

  if (user.providerData[0].providerId === "facebook.com") {
    userData["avatar"] = await uploadImageToStorage(
      `${user["photoURL"]}?access_token=${accessToken}&type=large`
    );
    userData["profileId"] = profile["id"] ?? "";
    userData["fullName"] = profile["name"] ?? "";
    userData["email"] = profile["email"] ?? "";
    userData["dateOfBirth"] = profile["birthday"] ?? "";
    userData["gender"] = getGender(profile["gender"]);
  }
  await db.collection("players").doc(userData.playerId).set(userData);
  return userData;
};

const getGender = (gender) => {
  switch (gender) {
    case "female":
      return "1";
    case "male":
      return "2";
    case "other":
      return "3";
    default:
      return "4";
  }
};

export const updatePassword = async (email, values) => {
  try {
    const { currentPassword, newPassword } = values;
    const user = auth.currentUser;
    const credential = firebase.auth.EmailAuthProvider.credential(
      email,
      currentPassword
    );
    await user.reauthenticateWithCredential(credential);
    await user.updatePassword(newPassword);
    return "";
  } catch (err) {
    return err.code;
  }
};

export const getUserData = (uid) => {
  return async (dispatch) => {
    try {
      const doc = await db.collection("players").doc(uid).get();
      if (doc.exists) {
        dispatch(setUserLogged(doc.data()));
      }
    } catch (err) {
      dispatch(setError(err.message));
    }
  };
};
const uploadImageToStorage = async (url) => {
  try {
    const response = await fetch(url);
    const blob = await response.blob();
    const ref = firebase
      .storage()
      .ref(`avatar/${auth.currentUser.uid}`)
      .child("avatar");
    const responseUploadImage = await ref.put(blob);
    const newUrl = await responseUploadImage.ref.getDownloadURL();
    return newUrl;
  } catch (error) {
    console.log(error);
  }
};

export const logout = (dispatch) => {
  dispatch({ type: SET_UNSUBSCRIBE });
  dispatch({
    type: SET_USER_LOGOUT,
  });
  firebase
    .database()
    .ref("/status/" + auth.currentUser.uid)
    .set({
      isOnline: false,
      last_changed: firebase.database.ServerValue.TIMESTAMP,
    });
  auth.signOut();
};

export const adminLogout = async () => {
  await auth.signOut();
};

const setUserLogged = (user) => {
  return (dispatch) => {
    dispatch({
      type: SET_USER_INFO,
      payload: user,
    });
  };
};

export const resetPassword = async (email, url) => {
  try {
    const language = localStorage.getItem("i18nextLng");
    auth.languageCode = language;
    const actionCodeSettings = { url };
    await auth.sendPasswordResetEmail(email, actionCodeSettings);
  } catch (error) {
    if (error.code === "auth/user-not-found") {
      return "errors.forgotPasswordUserNotFound";
    }
  }
};
export const sendMailResetPass = async (email) => {
  let result = { data: { status: "", reason: "" } };
  try {
    const language = localStorage.getItem("i18nextLng");
    const data = {
      email,
      language,
    };
    const func = functions.httpsCallable(SEND_MAIL_RESET_PASSWORD);
    result = await func(data);
  } catch (error) {
    console.log(error);
  }
  return result.data;
};

export const sendMailCreatedAccount = async (email) => {
  let result = { data: { status: "", reason: "" } };
  try {
    const func = functions.httpsCallable(SEND_MAIL_CREATED_ACCOUNT);
    result = await func({ email });
  } catch (error) {
    console.log(error);
  }
  return result.data;
};

export const sendOtpCode = async (phoneNumber, appVerifier) => {
  return firebase
    .auth()
    .signInWithPhoneNumber(phoneNumber, appVerifier)
    .then((confirmationResult) => {
      window.confirmationResult = confirmationResult;
      return "";
    })
    .catch((error) => {
      const err = ERROR_CODE_MESSAGE[error.code];
      if (err) {
        return err;
      }
      return "validate.internalError";
    });
};
