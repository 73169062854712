import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import TwilioVideo from "twilio-video";
import { getVideoGameToken } from "../../../../store/actions/tableActions";
import {
  ADD_LOCAL_MEDIA_TWILIO,
  CLEAR_ALL_TWILIO,
  JOIN_AUDIO,
  JOIN_VIDEO,
  NEW_JOIN_GUY,
  OFFLINE_PLAYER_TWILIO,
  SET_LOADING_AUDIO,
  SET_LOADING_VIDEO,
  TWILIO_ACTIVE_ROOM,
  SET_IS_CONNECT_TWILIO,
} from "../../../../store/types";
import MessDialog from "../NewDialog/MessDialog";
import errorRepo from "../../../../store/repositories/error";
import moment from "moment";
import { POSITION_ERROR, TYPE_ERROR } from "../../../../constants";
import { useTranslation } from "react-i18next";

moment.locale("ja");

const VIDEO_MEDIA = "video";
const AUDIO_MEDIA = "audio";
const videoSize = { width: 300, height: 180 };
const VideoComponent = ({ roomId }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const history = useHistory();
  const [openErrPermission, setOpenErrPermission] = useState(false);
  const [errPermissionText, setErrPermissionText] = useState("");
  const playerId = useSelector((state) => state.user.playerId);
  const numberPhone = useSelector((state) => state.user.numberPhone);
  const nickName = useSelector((state) => state.user.nickName);
  const tableName = useSelector((state) => state.room.tableName);
  const isLoadingVideo = useSelector((state) => state.videoGame.isLoadingVideo);
  const isLoadingAudio = useSelector((state) => state.videoGame.isLoadingAudio);

  const initial = useCallback(() => {
    let room = {};
    let audioPermission = false;
    let videoPermission = false;

    const onOffMedia = (media, isVideo) => {
      return async (on) => {
        if (on) {
          let track = null;
          if (isVideo && videoPermission) {
            track = await TwilioVideo.createLocalVideoTrack(videoSize);
          }
          if (!isVideo && audioPermission) {
            track = await TwilioVideo.createLocalAudioTrack();
          }
          track && room.localParticipant.publishTrack(track);
        }
        if (!on) {
          media.forEach((publication) => {
            publication.track.disable();
            publication.track.stop();
            const attachedElements = publication.track.detach();
            attachedElements.forEach((element) => element.remove());
            room.localParticipant.unpublishTrack(publication.track);
          });
        }
      };
    };

    const manageLocalParticipant = (room) => {
      try {
        // Log your Client's LocalParticipant in the Room
        const localParticipant = room.localParticipant;
        if (localParticipant.audioTracks) {
          dispatch({
            type: ADD_LOCAL_MEDIA_TWILIO,
            payload: { audio: onOffMedia(localParticipant.audioTracks) },
          });
        }
        if (localParticipant.videoTracks) {
          dispatch({
            type: ADD_LOCAL_MEDIA_TWILIO,
            payload: { video: onOffMedia(localParticipant.videoTracks, true) },
          });
        }
        participantConnected(localParticipant);
      } catch (error) {
        console.log("manageLocalParticipant", error);
      }
    };

    // A RemoteTrack was unpublished from the Room.
    const trackUnpublished = (publication, identity) => {
      try {
        addPlayerVideo(null, publication.kind, identity, false);
      } catch (error) {
        console.log("trackUnpublished", error);
      }
    };

    const attachTrack = (track, identity) => {
      if (track.attach) {
        const trackTyped = track;
        const mediaEl = trackTyped.attach();
        if (mediaEl.tagName === "VIDEO") {
          mediaEl.style.maxWidth = "100%";
          mediaEl.style.maxHeight = "100%";
          mediaEl.style.backgroundColor = "black";
        }
        addPlayerVideo(mediaEl, track.kind, identity, track.isEnabled);

        if (track.kind === "video" && identity === playerId) {
          handleLoadingVideo(false);
        } else if (track.kind === "audio" && identity === playerId) {
          handleLoadingAudio(false);
        }
      }
    };

    const addPlayerVideo = (media, kind, identity, isUse = true) => {
      if (!history.location.pathname.includes("game")) {
        dispatch({ type: CLEAR_ALL_TWILIO });
      }
      switch (kind) {
        case "video":
          dispatch({
            type: JOIN_VIDEO,
            payload: {
              identity: identity,
              video: media,
              isVideo: isUse,
            },
          });
          break;
        case "audio":
          dispatch({
            type: JOIN_AUDIO,
            payload: {
              identity: identity,
              audio: media,
              isAudio: isUse,
            },
          });
          break;
        default:
          break;
      }
    };
    // A new RemoteTrack was published to the Room.
    const trackPublished = (publication, identity) => {
      try {
        if (
          (publication.isSubscribed || publication.isTrackEnabled) &&
          publication.track
        ) {
          attachTrack(publication.track, identity);
          handleTrackEnDisabled(publication.track, identity);
        }
        publication.on("subscribed", function (track) {
          attachTrack(track, identity);
          handleTrackEnDisabled(track, identity);
        });
        publication.on("unsubscribed", (track) => detachTrack(track, identity));
      } catch (error) {
        console.log("trackPublished", error);
      }
    };
    const handleTrackEnDisabled = (track, identity) => {
      try {
        track.on("disabled", () => {
          /* Hide the associated <video> element and show an avatar image. */
          addPlayerVideo(null, track.kind, identity, false);
          if (track.kind === "video" && identity === playerId) {
            handleLoadingVideo(false);
          } else if (track.kind === "audio" && identity === playerId) {
            handleLoadingAudio(false);
          }
        });
        track.on("enabled", () => {
          /* Hide the avatar image and show the associated <video> element. */
          attachTrack(track, identity);
        });
      } catch (error) {
        console.log("handleTrackEnDisabled", error);
      }
    };

    const participantConnected = (participant) => {
      try {
        const identity = participant.identity;
        // add participant in to reducer
        dispatch({ type: NEW_JOIN_GUY, payload: { identity: identity } });

        participant.tracks.forEach(function (publication) {
          trackPublished(publication, identity);
        });
        participant.on("trackPublished", function (publication) {
          trackPublished(publication, identity);
        });
        participant.on("trackUnpublished", (publication) => {
          trackUnpublished(publication, identity);
        });
        participant.on("trackPublished", function (publication) {
          trackPublished(publication, identity);
        });
      } catch (error) {
        console.log("participantConnected: ", error);
      }
    };
    // Get the Participant's Tracks.
    const getTracks = (participant) => {
      try {
        return Array.from(participant.tracks.values())
          .filter(function (publication) {
            return publication.track;
          })
          .map(function (publication) {
            return publication.track;
          });
      } catch (error) {
        console.log("getTracks: ", error);
      }
    };

    const detachTrack = (track, identity) => {
      console.log("detachTrack", identity);
      // TODO: detachTrack here
      try {
        if (track.detach) {
          const trackTyped = track;
          trackTyped.detach().forEach(function (element) {
            element.remove();
          });
        }
      } catch (error) {
        console.log("detachTrack: ", error);
      }
    };
    // Detach the Participant's Tracks from the DOM.
    const detachParticipantTracks = (participant) => {
      try {
        let tracks = getTracks(participant);
        tracks.forEach((track) => detachTrack(track, participant.identity));
      } catch (error) {
        console.log("detachParticipantTracks: ", error);
      }
    };
    const checkMediaName = async (mediaName) => {
      let permission = false;
      try {
        const media = await navigator.mediaDevices.getUserMedia({
          [mediaName]: true,
        });
        permission = !!media;
        if (permission) {
          media.getTracks().forEach((track) => track.stop());
        }
      } catch (error) {
        permission = false;
      }
      return permission;
    };

    const connectVideo = async (roomId) => {
      audioPermission = await checkMediaName(AUDIO_MEDIA);
      videoPermission = await checkMediaName(VIDEO_MEDIA);

      const token = await getVideoGameToken(roomId);
      if (!token || "" === token) return;
      let connectOptions = {
        name: roomId,
        audio: false,
        video: false,
      };
      try {
        room = await TwilioVideo.connect(token, connectOptions);
        dispatch({
          type: TWILIO_ACTIVE_ROOM,
          payload: {
            activeRoom: room,
          },
        });
      } catch (error) {
        const dataError = {
          playerId: playerId,
          nickName: nickName,
          numberPhone: numberPhone,
          createdDate: Date.now(),
          createdDateFormat: moment(Date.now()).format("llll"),
          positionError: POSITION_ERROR.GAME,
          typeError: TYPE_ERROR.TWILIO,
          codeError: error.code,
          description: {
            message: error.message,
            roomId: roomId,
            tableName: tableName,
          },
        };
        errorRepo.create(dataError);
        setErrPermissionText(t("tables.mediaError", { error: error.code }));
        setOpenErrPermission(true);
        handleLoadingVideo(false);
        handleLoadingAudio(false);
        dispatch({
          type: SET_IS_CONNECT_TWILIO,
          payload: false,
        });

        return;
      }

      manageLocalParticipant(room);

      room.participants.forEach((participant) => {
        participantConnected(participant);
      });

      // When a Participant joins the Room, log the event.
      room.on("participantConnected", (participant) => {
        participantConnected(participant);
      });

      // When a Participant leaves the Room, detach its Tracks.
      room.on("participantDisconnected", (participant) => {
        detachParticipantTracks(participant);
        dispatch({
          type: OFFLINE_PLAYER_TWILIO,
          payload: {
            identity: participant.identity,
          },
        });
      });
      room.on("participantReconnecting", (remoteParticipant) => {
        /* Update the RemoteParticipant UI here */
        detachParticipantTracks(remoteParticipant);
        dispatch({
          type: OFFLINE_PLAYER_TWILIO,
          payload: {
            identity: remoteParticipant.identity,
          },
        });
      });

      room.on("participantReconnected", (remoteParticipant) => {
        participantConnected(remoteParticipant);
        /* Update the RemoteParticipant UI here */
      });
      // Once the LocalParticipant leaves the room, detach the Tracks
      // of all Participants, including that of the LocalParticipant.
      room.on("disconnected", () => {
        if (room) {
          detachParticipantTracks(room.localParticipant);
          room.participants.forEach(detachParticipantTracks);
        }
      });
      handleLoadingAudio(false);
      handleLoadingVideo(false);
    };

    if (roomId) {
      handleLoadingAudio(true);
      handleLoadingVideo(true);
      connectVideo(roomId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, history.location.pathname, roomId]);

  const handleLoadingAudio = useCallback(
    (isLoading) => {
      dispatch({
        type: SET_LOADING_AUDIO,
        payload: isLoading,
      });
    },
    [dispatch]
  );

  const handleLoadingVideo = useCallback(
    (isLoading) => {
      dispatch({
        type: SET_LOADING_VIDEO,
        payload: isLoading,
      });
    },
    [dispatch]
  );

  useEffect(() => {
    initial();
  }, [initial]);

  useEffect(() => {
    const clearError = setTimeout(() => {
      if (openErrPermission) {
        setOpenErrPermission(false);
        setErrPermissionText("");
      }
    }, 3000);

    return () => clearTimeout(clearError);
  }, [openErrPermission]);

  useEffect(() => {
    const clearError = setTimeout(() => {
      if (isLoadingAudio) {
        setOpenErrPermission(true);
        setErrPermissionText(t("tables.mediaError", { error: "" }));
        handleLoadingAudio(false);
      }

      if (isLoadingVideo) {
        setOpenErrPermission(true);
        setErrPermissionText(t("tables.mediaError", { error: "" }));
        handleLoadingVideo(false);
      }
    }, 30000);

    return () => clearTimeout(clearError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingVideo, isLoadingAudio]);

  return (
    <MessDialog
      isOpen={openErrPermission}
      onClose={() => setOpenErrPermission(false)}
      mainText={errPermissionText}
    />
  );
};
VideoComponent.propTypes = {
  roomId: PropTypes.string,
};
export default React.memo(VideoComponent);
