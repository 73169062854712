import { Avatar, Box, Button, Container, TextField } from "@material-ui/core";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import Header from "../../components/Header";
import useFriendsSearch from "../../hooks/useFriendsSearch";
import { Redirect } from "react-router-dom";
import { IoArrowBackSharp } from "react-icons/io5";
import {
  acceptFriendRequest,
  createFriendRequest,
  rejectFriendRequest,
  setUserInfo,
} from "../../store/actions/userAction";
import useStyles, {
  ButtonBox,
  ButtonStyle2,
  FriendAvatar,
  FriendBox,
  FriendInfo,
  FriendItemWrapper,
  FriendNickname,
  FriendsWrapper,
  OfflineStatus,
  OnlineStatus,
  RequestItem,
  SearchBoxWrapper,
  SearchItem,
  SearchItemNickname,
  SidebarWrapper,
  Title,
} from "./styles";

import { useHistory } from "react-router-dom";
import useDocumentTitle from "../../hooks/useDocumentTitle";
const Friends = () => {
  const classes = useStyles();
  const { t } = useTranslation();
  const [query, setQuery] = useState("");
  const [keyword, setKeyword] = useState("");
  const [currentFriendIds, setCurrentFriendIds] = useState();
  const userFriends = useSelector((state) => state.user.friends);
  const invites = useSelector((state) => state.user.friendInvitations);
  const nickName = useSelector((state) => state.user.nickName);
  const history = useHistory();
  const currentRoomJoinedId = useSelector(
    (state) => state.user.currentRoomJoinedId
  );
  const userUid = useSelector((state) => state.user.playerId);

  const receivedInvites = useSelector((state) =>
    state.user.friendInvitations.filter(
      (request) => request.type === "received"
    )
  );
  const typingSearchRef = useRef(null);
  const { friends } = useFriendsSearch(keyword, currentFriendIds);
  useDocumentTitle(t("common.gConceptTitle") + t("common.friendTitle"));

  useEffect(() => {
    if (userFriends && userFriends.length > 0) {
      const friendIds = userFriends.map((friend) => friend.nickName);
      const inviteIds = invites.map((invite) => invite.nickName);
      friendIds.push(...inviteIds);
      friendIds.push(nickName);
      setCurrentFriendIds(friendIds);
    }
  }, [userFriends, nickName, invites]);

  useEffect(() => {
    if (typingSearchRef.current) {
      clearTimeout(typingSearchRef.current);
    }

    typingSearchRef.current = setTimeout(() => {
      setKeyword(query);
    }, 500);
  }, [query]);

  const handleSearch = (e) => {
    setQuery(e.target.value);
  };

  const handleSendFriendRequest = async (friendId) => {
    await createFriendRequest(userUid, friendId);
  };

  // friend request

  const dispatch = useDispatch();

  const handleAcceptRequest = async (playerId) => {
    await acceptFriendRequest(userUid, playerId);
    dispatch(setUserInfo());
  };

  const handleRemoveRequest = async (playerId) => {
    await rejectFriendRequest(userUid, playerId);
    dispatch(setUserInfo());
  };
  if (currentRoomJoinedId && currentRoomJoinedId != "") {
    return <Redirect push to="/tables" />;
  }

  return (
    <Container maxWidth="xl" className={classes.root}>
      <Header />
      <Container maxWidth="xl" className={classes.main}>
        <SidebarWrapper>
          <Title>{t("friends.addFriends")}</Title>
          <SearchBoxWrapper>
            <TextField
              variant="outlined"
              placeholder={t("friends.searchByNickName")}
              autoComplete="off"
              size="small"
              className={classes.inputSearch}
              inputProps={{ className: classes.inputSearchFriend }}
              onChange={handleSearch}
            />
          </SearchBoxWrapper>
          {friends &&
            friends.length > 0 &&
            friends.map((friend) => {
              if (friend.playerId !== userUid) {
                return (
                  <SearchItem key={friend.playerId}>
                    <FriendAvatar>
                      <Avatar
                        src={friend.avatar}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </FriendAvatar>
                    <SearchItemNickname>{friend.nickName}</SearchItemNickname>
                    <ButtonStyle2
                      variant="contained"
                      onClick={() => handleSendFriendRequest(friend.playerId)}
                      disabled={
                        invites &&
                        invites.length > 0 &&
                        invites.filter(
                          (invite) =>
                            invite.type === "request" &&
                            invite.playerId === friend.playerId
                        ).length > 0
                        // invites[0].type === "request"
                      }
                    >
                      {invites &&
                      invites.length > 0 &&
                      invites.filter(
                        (invite) =>
                          invite.type === "request" &&
                          invite.playerId === friend.playerId
                      ).length > 0
                        ? t("friends.invited")
                        : t("friends.add")}
                    </ButtonStyle2>
                  </SearchItem>
                );
              }
            })}
          <Box className={classes.sidebarRequestFriends}>
            <Title>{t("friends.request")}</Title>
            <Box className={classes.sidebarListRequest}>
              {receivedInvites &&
                receivedInvites.map((request) => {
                  return (
                    <RequestItem key={request.playerId}>
                      <FriendAvatar>
                        <Avatar
                          src={request.avatar}
                          style={{ width: "100%", height: "100%" }}
                        />
                      </FriendAvatar>
                      <FriendNickname style={{ flex: 1 }}>
                        {request.nickName}
                      </FriendNickname>
                      <ButtonBox>
                        <ButtonStyle2
                          style={{
                            backgroundColor: "transparent",
                            borderColor: "white",
                            color: "white",
                          }}
                          variant="outlined"
                          onClick={() => handleRemoveRequest(request.playerId)}
                        >
                          {t("friends.deny")}
                        </ButtonStyle2>
                        <ButtonStyle2
                          variant="outlined"
                          style={{ backgroundColor: "#eeeeee" }}
                          onClick={() => handleAcceptRequest(request.playerId)}
                        >
                          {t("friends.approve")}
                        </ButtonStyle2>
                      </ButtonBox>
                    </RequestItem>
                  );
                })}
            </Box>
            <Box display="flex" justifyContent="flex-start">
              <Button
                startIcon={<IoArrowBackSharp />}
                className={classes.goBack}
                onClick={() => history.push("tables")}
              >
                {t("settings.goBack")}
              </Button>
            </Box>
          </Box>
        </SidebarWrapper>
        <FriendsWrapper>
          <div style={{ display: "flex", flexDirection: "colunm" }}>
            <Title>{t("friends.friends")}</Title>
          </div>
          <FriendBox>
            {userFriends &&
              userFriends.map((friend) => {
                return (
                  <FriendItemWrapper key={friend.friendId}>
                    <FriendAvatar>
                      <Avatar
                        src={friend.avatar}
                        style={{ width: "100%", height: "100%" }}
                      />
                    </FriendAvatar>
                    <FriendInfo>
                      <FriendNickname>{friend.nickName}</FriendNickname>
                      {friend.isOnline && (
                        <OnlineStatus>{t("friends.online")}</OnlineStatus>
                      )}
                      {!friend.isOnline && (
                        <OfflineStatus>{t("friends.offline")}</OfflineStatus>
                      )}
                    </FriendInfo>
                  </FriendItemWrapper>
                );
              })}
          </FriendBox>
        </FriendsWrapper>
      </Container>
    </Container>
  );
};

export default Friends;
