import React from "react";
import PropTypes from "prop-types";
import PaperComponent from "../../../../../components/Dialog/PaperComponent";
import { Box, Dialog } from "@material-ui/core";
import { OKButton } from "../Buttons";
const OkDialog = ({
  isOpen,
  confirmText,
  btnLabel = "OK",
  isDisable,
  onAgree = () => {},
}) => {
  return (
    <>
      <Dialog
        open={isOpen}
        PaperProps={{
          style: {
            borderRadius: "35px",
            backgroundColor: "#f7f7f7",
            overflow: "unset !important",
          },
        }}
        PaperComponent={PaperComponent}
        maxWidth="sm"
      >
        <div className="dialog-style-1" id="draggable-dialog-title">
          <div style={{ height: "70%" }}>
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              height={145}
            >
              <p
                style={{
                  textAlign: "center",
                  margin: "0px 30px",
                  fontWeight: 700,
                }}
                dangerouslySetInnerHTML={{ __html: confirmText }}
              />

              {/* <Box
                style={{
                  float: "none",
                  display: "inline !important",
                  margin: "0px 30px",
                  cursor: "move",
                  // top: "45px",
                  position: "absolute",
                }}
                fontWeight={700}
                dangerouslySetInnerHTML={{ __html: confirmText }}
                textAlign="center"
              ></Box> */}
            </Box>
          </div>
          <Box display="flex" justifyContent="center" style={{ height: "25%" }}>
            <Box width={135}>
              <OKButton onClick={onAgree} disabled={isDisable}>
                <p
                  style={{
                    letterSpacing: 15,
                    marginLeft: "15%",
                  }}
                >
                  {btnLabel}
                </p>
              </OKButton>
            </Box>
          </Box>
        </div>
      </Dialog>
    </>
  );
};

OkDialog.propTypes = {
  isOpen: PropTypes.bool,
  confirmText: PropTypes.string,
  btnLabel: PropTypes.string,
  isDisable: PropTypes.bool,
  onAgree: PropTypes.func,
};
export default React.memo(OkDialog);
