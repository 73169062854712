import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  container: {
    boxSizing: "border-box",
    display: "flex",
    width: "100%",
    flexDirection: "column",
    overflow: "auto",
  },
  invoice: {
    display: "flex",
    flexDirection: "column",
    width: "80%",
    margin: "auto",
    backgroundColor: "rgba(176,195,199,0.7)",
    borderRadius: "40px",
    boxSizing: "border-box",
    padding: "3rem",
    minHeight: "650px",
    position: "relative",
    overflow: "hidden",

    "@media (max-width: 991px)": {
      padding: "2rem",
    },
  },
  invoiceBottom: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "1.5rem",
    "& button": {
      "@media (max-width: 768px)": {
        fontSize: "10px",
      },
    },
  },
  tableCell: {
    "@media (max-width: 1200px)": {
      padding: "10px 5px !important",
      fontSize: "12px !important",
    },
    "@media (max-width: 991px)": {
      padding: "10px !important",
      fontSize: "10px !important",
    },
    "@media (max-width: 768px)": {
      padding: "10px 5px !important",
    },
  },
  tableContainer: {
    backgroundColor: "transparent",
  },

  pagination: {
    color: "#541513",
    "@media (max-width: 768px)": {
      "& li": {
        width: "30px",
      },
    },
  },
  title: {
    fontSize: "24px !important",
    fontWeight: "600 !important",
    letterSpacing: "2px !important",
    textAlign: "center",
    marginBottom: "1rem !important",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: "50px",
    textTransform: "capitalize",
    fontSize: "16px",
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
    minWidth: "170px",
  },
}));

export default useStyles;
