import {
  ADD_INVITE,
  REMOVE_INVITE,
  SEEN_INVITE,
  SEEN_ALL_INVITE,
} from "../types";

const initialState = {
  invite: [],
};

const inviteRoomReducer = (state = initialState, action) => {
  let invites = [];
  switch (action.type) {
    case ADD_INVITE:
      invites = state.invite;
      if (invites && invites.length > 0) {
        // unsubScribe all
        invites = invites.filter(
          (item) => item.roomId !== action.payload.roomId
        );
      }
      return {
        invite: [
          {
            roomId: action.payload.roomId,
            tableName: action.payload.tableName,
            tableAvatar: action.payload.tableAvatar,
            description: action.payload.description,
            message: action.payload.message,
            ownerFullName: action.payload.ownerFullName,
            type: action.payload.type, // private or public
            seen: action.payload.seen, // seen is true, not yet is false
            timestamp: action.payload.timestamp,
          },
          ...invites,
        ],
      };
    case REMOVE_INVITE:
      invites = state.invite;
      if (invites && invites.length > 0) {
        // unsubScribe all
        invites = invites.filter(
          (item) => item.roomId !== action.payload.roomId
        );
      }
      return {
        invite: [...invites],
      };
    case SEEN_INVITE:
      invites = state.invite;
      if (invites && invites.length > 0) {
        // unsubScribe all
        const inviteIndex = invites.findIndex(
          (item) => item.roomId === action.payload.roomId
        );
        if (inviteIndex > -1) {
          invites[inviteIndex].seen = true;
        }
      }
      return {
        invite: [...invites],
      };
    case SEEN_ALL_INVITE:
      invites = state.invite;
      if (invites && invites.length > 0) {
        invites.forEach((item) => (item.seen = true));
      }
      return {
        invite: [...invites],
      };
    default:
      return state;
  }
};

export default inviteRoomReducer;
