import {
  CARD_SUIT,
  PLAYER_ROLE_G_CHAOS,
  PLAYER_ROLE_MASTER_G_CHAOS,
  TURN_STATUS,
} from "../../../../store/constant";
import { ADD_INSTRUCTOR_MESSAGE_CODE } from "../../../../store/types";

/**
 *
 * @param {*} suit: suit of round
 * @param {*} isG0Card: card G0
 * @param {*} isCaptain: captain of round
 * @param {*} card: card played
 * @returns
 */
export function checkNoHaveSuit(
  suitOfRound = "",
  turnStatus = "",
  card = { suit: "", value: -1 }
) {
  if (suitOfRound === "" || card.suit === "") return false;
  return (
    !isCaptain(turnStatus) &&
    !isG0Card(card) &&
    card.suit !== suitOfRound &&
    CARD_SUIT.WHATEVER !== suitOfRound
  );
}

/**
 *
 * @param {suit: string, value: number} card: card played
 * @returns
 */
export function isG0Card(card = { suit: "", value: -1 }) {
  if (card.suit === "") return false;
  return CARD_SUIT.G === card.suit && 0 === card.value;
}

/**
 *
 * @param {suit: string} card: card played
 * @returns
 */
export function isGCard(card = { suit: "" }) {
  if (card.suit === "") return false;
  return card.suit === CARD_SUIT.G;
}

/**
 *
 * @param {string} role: role of player
 * @returns
 */
export function isGChaos(role = "") {
  return [PLAYER_ROLE_G_CHAOS, PLAYER_ROLE_MASTER_G_CHAOS].includes(role);
}

/**
 *
 * @param {string} turnStatus
 * @returns
 */
export function isCaptain(turnStatus = "") {
  if (turnStatus === "") return false;
  return turnStatus === TURN_STATUS.CAPTAIN;
}

export function isHaveSuitInHandCards(handCards = [], suit = "") {
  if (handCards.length === 0 || suit === "") return false;
  return (
    -1 !== handCards.findIndex((card) => null !== card && card.suit === suit)
  );
}

/**
 *
 * @param {i18n} t
 * @param {string} suit
 * @returns
 */
export function notifyMessageCardInvalid(t, suit = "") {
  if (suit === "") return;
  return t(`instructor.suitOfRound`, {
    suit: t(`suit.${suit.toLowerCase()}`),
  });
}

/**
 *
 * @param {number} myIndex
 * @param {array} grandCross: cards on grand cross
 * @returns
 */
export function isPlayCard(myIndex = -1, grandCross = []) {
  if (-1 === myIndex || grandCross.length === 0) return false;
  return null !== grandCross[myIndex];
}

/**
 *
 * @param {*} dispatch
 * @param {string} suit
 */
export function addMessageNoHaveSuit(dispatch, suit = "") {
  dispatch({
    type: ADD_INSTRUCTOR_MESSAGE_CODE,
    payload: {
      message: {
        suit: suit.toLowerCase(),
        isGChaos: false,
        messageContent: "NoHaveSuit",
      },
    },
  });
}

/**
 * check person is g-chaos -> can play card
 * @param {*} nickName
 * @param {*} index
 * @returns
 */
export function isGChaosPlayer(nickName = "", index = -1) {
  if (index !== 3) return false;
  if (index === 3 && nickName !== "G-Chaos") return true;
}

/**
 * check your turn play card
 * @param {*} turnStatus
 * @returns
 */
export function checkPlayCardTurn(turnStatus = "") {
  if (turnStatus === "") return false;
  return [TURN_STATUS.PLAY_CARD, TURN_STATUS.CAPTAIN].includes(turnStatus);
}

/**
 * find my index in room
 * @param {array[string]} playerIds
 * @param {string} playerId
 * @returns
 */
export function findMyIndex(playerIds = [], playerId = "") {
  if (playerIds.length === 0 || playerId === "") return -1;
  return playerIds.indexOf(playerId);
}
