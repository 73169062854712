import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  defaultSize: {
    width: "100px",
  },

  mainDialog: {
    marginTop: "40px",
    marginLeft: "50px",
    display: "flex",
    cursor: "default",
  },
  playersNickName: {
    display: "grid",
    cursor: "grab",
    width: "170px",
  },
  playersRole: {
    display: "grid",
    marginRight: "10px",
  },
  buttons: {
    display: "grid",
    gridGap: "15%",
    width: "120px",
    paddingBottom: "15px",
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
  nickName: {
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
  },
  closeIcon: {
    color: "black",
    fontSize: "1em",
    width: "1em",
  },
  iconBtn: {
    backgroundColor: "white !important",
    width: "1em",
    height: "1em",
    float: "right",
    fontSize: 20,
    marginRight: 18,
    marginTop: 5,
  },
}));

export default useStyles;
