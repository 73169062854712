import {
  Badge,
  ClickAwayListener,
  IconButton,
  MenuItem,
  MenuList,
  Paper,
  Popper,
} from "@material-ui/core";
import PropTypes from "prop-types";
import SettingsIcon from "@material-ui/icons/Settings";
import React, { useMemo, useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineGlobal } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import TableSettingsInRoom from "../../../../../components/Dialog/TableSettingsInRoom";
import DialogInviteFriends from "../../../../../components/DialogInviteFriends";
import DialogRequestJoinRoom from "../../../../../components/DialogRequestJoinRoom";
import useStyles from "./styles";
import { IoIosNotifications } from "react-icons/io";
import Notifications from "../../../../../components/Header/components/notifications";
import {
  clearPrivateMessage,
  updatePlayerStatus,
} from "../../../../../store/actions/userAction";
import { seenAllNotifications } from "../../../../../store/actions/playerActions";
import { ADD_INVITE, SEEN_ALL_INVITE } from "../../../../../store/types";

export const GameConfig = ({ toggleStatusList }) => {
  const { t } = useTranslation();
  const room = useSelector((state) => state.room);
  const playerId = useSelector((state) => state.user.playerId);
  const friendRequests = useSelector((state) => state.user.friendRequests);
  const hShipHeight = useSelector((state) => state.size.hShipHeight);
  const notifications = useSelector((state) => state.user.notifications ?? []);
  const right = useMemo(() => Math.round(hShipHeight / 2 + 30), [hShipHeight]);
  const bottom = useMemo(() => Math.round(hShipHeight / 2), [hShipHeight]);
  const minScale = useSelector((state) => state.size.minScale);
  const classes = useStyles({ right, bottom, minScale });
  const anchorRef = React.useRef(null);
  const [openDialogInviteFriends, setOpenDialogInviteFriends] = useState(false);
  const [openDialogJoinRequest, setOpenDialogJoinRequest] = useState(false);
  const [openDialogTableConfig, setOpenDialogTableConfig] = useState(false);
  const notifies = useSelector((state) => state.inviteNotify.invite);
  const privateInvites = useSelector((state) => state.user.privateInvitations);
  const infoNotifications = useSelector(
    (state) => state.user.infoNotifications
  );
  const [notifyBadge, setNotifyBadge] = useState(0);
  const [allNotifies, setAllNotifies] = useState([]);
  const { isPlaying, owner } = useSelector((state) => state.room.table);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();
  const handleClose = (event, value) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (value === "invite") {
      setOpen(false);
      setOpenDialogInviteFriends(true);
    }
    if (value === "request") {
      setOpen(false);
      setOpenDialogJoinRequest(true);
    }
    if (value === "tableConfig") {
      setOpen(false);
      setOpenDialogTableConfig(true);
    }
    setOpen(false);
  };
  const onCloseDialog = () => {
    setOpenDialogInviteFriends(false);
    setOpenDialogJoinRequest(false);
    setOpenDialogTableConfig(false);
  };
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const toggleDrawer = async () => {
    setOpenNotifications(!openNotifications);
    seenAllNotifications(playerId);
    await updatePlayerStatus(playerId);
    dispatch({ type: SEEN_ALL_INVITE, payload: {} });
  };

  useEffect(() => {
    if (notifies && friendRequests && notifications) {
      const badgeCount =
        notifies.filter((notify) => !notify.seen).length +
        friendRequests.filter((item) => item.seen === false).length +
        notifications.filter((item) => item.seen === false).length;
      setNotifyBadge(badgeCount);
      const data = notifies.concat(friendRequests, notifications);
      const _notifies = data.sort((a, b) =>
        a.timestamp < b.timestamp ? 1 : -1
      );
      setAllNotifies(_notifies);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [notifies, friendRequests, notifications]);

  // update notify
  useEffect(() => {
    if (privateInvites && privateInvites.length > 0) {
      privateInvites.forEach((itemInvite) => {
        dispatch({
          type: ADD_INVITE,
          payload: {
            roomId: itemInvite.roomId,
            tableName: itemInvite.tableName,
            tableAvatar: itemInvite.tableAvatar,
            description: itemInvite.description,
            message: itemInvite.inviteMessage,
            ownerFullName: itemInvite.ownerFullName,
            type: "private",
            seen: false,
            timestamp: Date.now(),
          },
        });
      });
      clearPrivateMessage(playerId);
    }
  }, [privateInvites, dispatch, playerId]);

  // update notify
  useEffect(() => {
    if (infoNotifications && infoNotifications.length > 0) {
      infoNotifications.forEach((itemInfo) => {
        dispatch({
          type: ADD_INVITE,
          payload: {
            roomId: "",
            tableName: t(itemInfo.title),
            tableAvatar: "",
            description: "",
            message: t("invitation.playTimeAdded"),
            ownerFullName: t("invitation.inviteAFriendSuccessfully"),
            type: itemInfo.type,
            seen: false,
            timestamp: Date.now(),
          },
        });
      });
    }
  }, [infoNotifications, dispatch, t]);
  return (
    <>
      {owner === playerId && !isPlaying && (
        <IconButton
          className={classes.menu}
          aria-controls={open ? "menu-list-grow" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
        >
          <Badge
            badgeContent={
              room && room.receivedJoinInvitations
                ? room.receivedJoinInvitations.length
                : 0
            }
            color="error"
            overlap="rectangular"
          >
            <SettingsIcon style={{ fontSize: "1.5rem" }} />
          </Badge>
        </IconButton>
      )}

      {/* notify */}
      <IconButton
        className={classes.notify}
        component="span"
        disableRipple={true}
        onClick={toggleDrawer}
      >
        <Badge
          badgeContent={notifyBadge ?? 0}
          color="error"
          overlap="rectangular"
        >
          <IoIosNotifications style={{ fontSize: "1.5rem" }} />
        </Badge>
      </IconButton>

      {/* BUTTON STATUS LIST */}
      <IconButton className={classes.status}>
        <Badge badgeContent={0} color="error" overlap="rectangular">
          <AiOutlineGlobal
            style={{ color: "#ecbd2a", fontSize: "1.5rem" }}
            onClick={toggleStatusList}
          />
        </Badge>
      </IconButton>

      {/* BUTTON STATUS LIST */}

      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="bottom-end"
        style={{
          zIndex: "+100",
          display: "absolute",
          top: 50,
          right: 55,
          left: "unset",
        }}
      >
        <Paper>
          <ClickAwayListener onClickAway={handleClose}>
            <MenuList
              autoFocusItem={open}
              id="menu-list-grow"
              style={{ display: "flex", flexDirection: "column" }}
            >
              <MenuItem onClick={(e) => handleClose(e, "invite")}>
                {t("game.inviteFriends")}
              </MenuItem>
              <Badge
                badgeContent={
                  room && room.receivedJoinInvitations
                    ? room.receivedJoinInvitations.length
                    : 0
                }
                color="error"
                overlap="rectangular"
              >
                <MenuItem onClick={(e) => handleClose(e, "request")}>
                  {t("game.joinRequests")}
                </MenuItem>
              </Badge>
              <MenuItem onClick={(e) => handleClose(e, "tableConfig")}>
                {t("tables.tableSetting")}
              </MenuItem>
            </MenuList>
          </ClickAwayListener>
        </Paper>
      </Popper>
      <TableSettingsInRoom
        open={openDialogTableConfig}
        onClose={onCloseDialog}
      />
      <DialogInviteFriends
        open={openDialogInviteFriends}
        onClose={onCloseDialog}
      />
      <DialogRequestJoinRoom
        open={openDialogJoinRequest}
        onClose={onCloseDialog}
      />
      {/* DRAWER NOTIFICATIONS */}
      <Notifications
        allNotifies={allNotifies}
        myId={playerId}
        isOpen={openNotifications}
        toggle={toggleDrawer}
      />
    </>
  );
};

GameConfig.propTypes = {
  toggleStatusList: PropTypes.func,
  openStatusList: PropTypes.bool,
};
export default GameConfig;
