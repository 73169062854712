import firebase from "../../services/firebase";
import { isEmpty } from "lodash";
const PLAYERS = "players";
const playerCollection = firebase.firestore().collection(PLAYERS);

/**
 * get data by docId
 * @param {string} docId
 * @returns
 */
async function read(docId = "") {
  return (await playerCollection.doc(docId).get()).data();
}

/**
 * get all players data
 * @returns
 */
async function readAll() {
  return playerCollection.get();
}

/**
 * update data by docId
 * @param {string} docId
 * @param {object} data
 * @returns
 */
async function update(docId = "", data = {}) {
  if (!docId || isEmpty(data)) return;
  return playerCollection.doc(docId).update(data);
}

export default {
  read,
  readAll,
  update,
};
