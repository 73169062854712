import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import styled from "styled-components";
import Backdrop from "@material-ui/core/Backdrop";
import CircularProgress from "@material-ui/core/CircularProgress";

const VideoVoiceButtonStyled = styled.div`
  width: ${(props) => props.width}px;
  height: ${(props) => props.height}px;
  cursor: ${(props) => (props.isLoading ? "default" : "pointer")};
  box-sizing: border-box;
  position: relative;

  &:hover {
    transform: ${(props) => (props.isLoading ? "scale(1)" : "scale(1.1)")};
  }
`;

const VoiceVideoButton = React.memo(function VoiceVideoButtonComponent({
  onClick,
  isOn,
  onSrc,
  offSrc,
  isLoading,
}) {
  const videoVoiceButtonWidth = useSelector(
    (state) => state.size.videoVoiceButtonWidth
  );
  const videoVoiceButtonHeight = useSelector(
    (state) => state.size.videoVoiceButtonHeight
  );
  return (
    <VideoVoiceButtonStyled
      onClick={onClick}
      height={videoVoiceButtonHeight}
      width={videoVoiceButtonWidth}
      isLoading={isLoading}
    >
      <img
        src={isOn ? onSrc : offSrc}
        alt="voice_on"
        style={{ width: "100%", height: "100%", objectFit: "fill" }}
      />
      <Backdrop
        open={isLoading}
        style={{
          zIndex: 999999,
          position: "absolute",
          borderRadius: "50%",
          width: "130%",
          height: "130%",
          transform: "translate(-11%, -13%)",
        }}
      >
        <CircularProgress style={{ color: "#e9e0d2", width: 20, height: 20 }} />
      </Backdrop>
    </VideoVoiceButtonStyled>
  );
});
VoiceVideoButton.propTypes = {
  onClick: PropTypes.func,
  isOn: PropTypes.bool,
  onSrc: PropTypes.string,
  offSrc: PropTypes.string,
  isLoading: PropTypes.bool,
};

export default VoiceVideoButton;
