import moment from "moment";
import firebase, { getFunctions } from "../../services/firebase";
import { SET_USER_INFO } from "../types";
import ChatRepo from "../repositories/chat";
import PlayerRepo from "../repositories/player";

const auth = firebase.auth();
const db = firebase.firestore();
const realtime = firebase.database();
const fs = firebase.storage();
const functions = getFunctions();

export const setUserInfo = () => {
  return async (dispatch) => {
    try {
      const doc = await db
        .collection("players")
        .doc(auth.currentUser.uid)
        .get();
      if (doc.exists) {
        const userData = { ...doc.data() };
        if (userData.dateOfBirth !== "") {
          const date = new Date(doc.data().dateOfBirth);
          userData.dateOfBirth = moment(date).format("MM/dd/yyyy");
        } else {
          userData.dateOfBirth = "";
        }

        dispatch({ type: SET_USER_INFO, payload: doc.data() });
      }
    } catch (error) {
      console.log(error);
      return error.code;
    }
  };
};
//Update player status onl/off when login
export async function updatePlayerStatus(userId) {
  try {
    if (userId && userId !== "") {
      realtime.ref("/status/" + userId).set({
        isOnline: true,
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      });
      await PlayerRepo.update(userId, { isOnline: true });
    }
  } catch (error) {
    console.log(error);
  }
}
// Update memberName in chat document when change NickName
export async function updateThreadMemberInChat(
  threadId = "",
  playerId = "",
  newNickName = ""
) {
  try {
    const threadData = await ChatRepo.read(threadId);
    let threadMembers = threadData.threadMembers;
    threadMembers.forEach(async (member) => {
      if (member.memberId == playerId) {
        member.memberName = newNickName;
      }
    });
    await ChatRepo.update(threadId, { threadMembers: threadMembers });
  } catch (error) {
    console.log(error);
  }
}

export const updateUser = async (data, avatar, oldNickName, firstLogin) => {
  try {
    const newData = { ...data };
    if (firstLogin) {
      realtime.ref("/status/" + auth.currentUser.uid).set({
        isOnline: true,
        last_changed: firebase.database.ServerValue.TIMESTAMP,
      });
    }
    newData.firstLogin = false;
    let isChanged = oldNickName !== newData.nickName;
    if (isChanged) {
      if (newData.nickName.length > 8) {
        return "errors.nickNameOver8Characters";
      }
      const isUpdated = await updateNickName(oldNickName, newData.nickName);
      if (!isUpdated) return "errors.nickNameAlready";
      const playerThreads = await PlayerRepo.read(auth.currentUser.uid);
      playerThreads?.threads?.forEach((thread) =>
        updateThreadMemberInChat(thread, auth.currentUser.uid, newData.nickName)
      );
    }
    if (avatar) {
      const urlAvatar = await uploadImage(
        avatar,
        newData.playerId,
        true,
        false
      );
      if (urlAvatar) {
        newData.avatar = urlAvatar;
      }
    }
    const doc = await db.collection("players").doc(auth.currentUser.uid);
    await doc.update(newData);
    if (firstLogin) {
      const tableSetting = {
        joinTableRole: "open",
        defaultTableName:
          localStorage.getItem("i18nextLng") === "en"
            ? newData.nickName + "'s table"
            : newData.nickName + "のテーブル",
        defaultAvatar: "",
        isNarrationSound: false,
        isNarrationText: true,
      };
      await updateSettingDefaultTable(auth.currentUser.uid, tableSetting, null);
    }
    return (await doc.get()).data();
  } catch (error) {
    console.log(error);
  }
};

export const uploadImage = async (image, id, avatar, imageTable) => {
  try {
    let url = "";
    if (image && image instanceof File) {
      let refFolder = "";
      if (avatar) {
        refFolder = fs.ref(`avatar/${id}`);
      }
      if (imageTable) {
        refFolder = fs.ref(`table/${id}`);
      }
      const imageRef = refFolder.child(image.name);
      await imageRef.put(image);
      url = await imageRef.getDownloadURL();
    }
    return url;
  } catch (err) {
    console.error(err);
    return "";
  }
};

const updateNickName = async (oldNickName, newNickName) => {
  try {
    const nicknames = db.collection("nicknames");
    const nickname = await nicknames.doc(newNickName);
    const doc = await nickname.get();
    if (doc.exists) {
      const isUse = doc.data().isUse;
      if (isUse) {
        return false;
      }
      await nickname.update({
        isUse: true,
      });
      if (oldNickName !== "") {
        await nicknames.doc(oldNickName).update({ isUse: false });
      }
      return true;
    } else {
      if (oldNickName === "") {
        await nicknames.doc(newNickName).set({ isUse: true });
        return true;
      } else {
        const updateOldNickname = await nicknames.doc(oldNickName);
        const oldDoc = await updateOldNickname.get();
        if (oldDoc.exists) {
          await updateOldNickname.update({ isUse: false });
          await nicknames.doc(newNickName).set({ isUse: true });
          return true;
        }
      }
    }
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const updateSettingDefaultTable = async (playerId, setting, image) => {
  try {
    if (image) {
      const urlAvatar = await uploadImage(image, playerId, false, true);
      if (urlAvatar) {
        setting.defaultAvatar = urlAvatar;
      }
    }
    await db.collection("players").doc(playerId).update({
      setting,
    });
    return playerId;
  } catch (error) {
    console.log(error);
  }
};

export const acceptFriendRequest = async (userUid, friendUid) => {
  try {
    const userRef = db.collection("players").doc(userUid);
    const friendRef = db.collection("players").doc(friendUid);
    const userData = (await userRef.get()).data();
    const friendData = (await friendRef.get()).data();
    const friendDocRef = await realtime
      .ref(`/status/${friendUid}`)
      .once("value");
    const friendStatus = friendDocRef.val();

    let friendIsOnline = friendStatus && friendStatus.isOnline;

    const userInvites = removeFriendRequest(
      friendUid,
      userData.friendInvitations
    );
    const friendInvites = removeFriendRequest(
      userUid,
      friendData.friendInvitations
    );

    const userFriendList = userData.friends.filter(
      (friend) => friend.friendId !== friendData.playerId
    );
    userFriendList.push({
      friendId: friendData.playerId,
      nickName: friendData.nickName,
      avatar: friendData.avatar,
      fullName: friendData.fullName,
      isOnline: friendIsOnline,
    });

    const fFriendList = friendData.friends.filter(
      (friend) => friend.friendId !== userData.playerId
    );
    fFriendList.push({
      friendId: userData.playerId,
      nickName: userData.nickName,
      avatar: userData.avatar,
      fullName: userData.fullName,
      isOnline: true,
    });

    await db.runTransaction(async (transaction) => {
      const userDoc = await transaction.get(userRef);

      if (!userDoc.exists) {
        console.error("Document does not exist!");
      }
      // update  user  invite
      transaction.update(userRef, {
        friendInvitations: userInvites,
        friends: userFriendList,
      });
      // update friend  invite
      transaction.update(friendRef, {
        friendInvitations: friendInvites,
        friends: fFriendList,
      });
    });
  } catch (error) {
    console.log(error);
  }
};

const removeFriendRequest = (uid, arrayRequest) => {
  if (!arrayRequest) {
    return [];
  }
  return arrayRequest.filter((item) => item.playerId !== uid);
};

export const rejectFriendRequest = async (userUid, friendUid) => {
  const userRef = db.collection("players").doc(userUid);

  await db.runTransaction(async (transaction) => {
    const userDoc = await transaction.get(userRef);

    if (!userDoc.exists) {
      console.error(`Document ${userUid}does not exist!`);
      return;
    }
    const userData = userDoc.data();
    const userInvites = removeFriendRequest(
      friendUid,
      userData.friendInvitations
    );
    // update  user  invite
    transaction.update(userRef, {
      friendInvitations: userInvites,
    });

    const friendRef = db.collection("players").doc(friendUid);
    const friendDoc = await friendRef.get();

    if (!friendDoc.exists) {
      console.error(`Document ${friendUid}does not exist!`);
      return;
    }
    const friendData = friendDoc.data();
    const friendInvites = removeFriendRequest(
      userUid,
      friendData.friendInvitations
    );
    // update friend  invite
    transaction.update(friendRef, {
      friendInvitations: friendInvites,
    });
  });
};

export const createFriendRequest = async (userUid, friendUid) => {
  const userRef = db.collection("players").doc(userUid);
  const friendRef = db.collection("players").doc(friendUid);
  const userDoc = await userRef.get();
  const friendDoc = await friendRef.get();

  if (!userDoc.exists || !friendDoc.exists) {
    return;
  }
  const userData = userDoc.data();
  const friendData = friendDoc.data();
  const userInvites = userData.friendInvitations;
  const existIndexInvites = userInvites.findIndex(
    (invite) => invite.type === "received" && invite.playerId
  );
  if (existIndexInvites > -1) {
    acceptFriendRequest(userUid, friendUid);
    return;
  }
  userInvites.push({
    playerId: friendUid,
    fullName: friendData.fullName,
    avatar: friendData.avatar,
    nickName: friendData.nickName,
    message: "",
    type: "request",
    isOnline: false,
  });

  const friendInvites = friendData.friendInvitations;
  friendInvites.push({
    playerId: userUid,
    fullName: userData.fullName,
    avatar: userData.avatar,
    nickName: userData.nickName,
    message: "",
    type: "received",
    isOnline: true,
  });
  await db.runTransaction(async (transaction) => {
    const userDoc = await transaction.get(userRef);

    if (!userDoc.exists) {
      console.error("Document does not exist!");
      return;
    }
    // update  user  invite
    transaction.update(userRef, {
      friendInvitations: userInvites,
    });
    // update friend  invite
    transaction.update(friendRef, {
      friendInvitations: friendInvites,
    });
  });
};

export const clearPrivateMessage = async (userUid) => {
  let returnFlag = false;
  try {
    const userRef = db.collection("players").doc(userUid);
    const userDoc = await userRef.get();
    if (userDoc.exists) {
      userRef.update({ privateInvitations: [] });
    }
  } catch (error) {
    console.error(error);
    returnFlag = false;
  }
  return returnFlag;
};

export const updateNarrationConfig = async (narration) => {
  try {
    const updateNarration = functions.httpsCallable("updateNarration");

    const data = {
      isNarrationSound: narration.isNarrationSound,
      isNarrationText: narration.isNarrationText,
      playerId: narration.playerId,
    };
    await updateNarration(data);
  } catch (error) {
    console.log(error);
  }
};
export const updatePlayersFriend = async () => {
  const uid = auth.currentUser.uid;
  const playerRef = await db.doc(`players/${uid}`).get();
  if (playerRef.exists) {
    const playerData = playerRef.data();
    const friendsList = playerData.friends;
    friendsList.forEach((friend) => {
      const updateUserRef = db.doc(`players/${friend.friendId}`);
      return db.runTransaction(async (transaction) => {
        const doc = await transaction.get(updateUserRef);
        if (doc.exists) {
          const friends = doc.data().friends;
          const newFriends = friends.map((fr) => {
            const obj = { ...fr };

            if (fr.friendId === uid) {
              obj.avatar = playerData.avatar;
              obj.nickName = playerData.nickName;
              obj.fullName = playerData.fullName;
            }
            return obj;
          });
          transaction.update(updateUserRef, { friends: newFriends });
        }
      });
    });
  }
};

//TODO: set readedConfirmMutipleDevices
export const transitionReadedConfirmMutipleDevices = async (_value) => {
  const uid = auth.currentUser.uid;

  await PlayerRepo.update(uid, { readedConfirmMutipleDevices: _value });
};
