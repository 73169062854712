import { makeStyles } from "@material-ui/core/styles";
import { COLOR_YELLOW } from "../../pages/GameScreen/components/constants";
const useStyles = makeStyles(() => ({
  dialogContent: {
    // width: "400px",
    height: "300px",
    position: "relative",
  },
  search: {
    position: "sticky",
    width: "100%",
  },
  listFriends: {
    display: "flex",
    flexDirection: "column",
    "&>*": {
      margin: "10px",
    },
  },
  friendItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  nickname: {
    flexGrow: 1,
    marginLeft: "10px",
  },
  buttonInvite: {
    backgroundColor: COLOR_YELLOW,
    textTransform: "capitalize",
    color: "black",
    "&:hover": {
      backgroundColor: "#ffbe08",
    },
    padding: "6px 14px",
    minWidth: "130px",
  },
}));

export default useStyles;
