import { isEmpty } from "lodash";
import firebase from "../../services/firebase";

const ERRORS = "errors";
export const errorRef = firebase.firestore().collection(ERRORS);

/**
 * data shema
 * @param {playerId} string
 * @param {numberPhone} number
 * @param {createdDate} number
 * @param {createdDateFormat} string // format("LL")
 * @param {positionError} string // ROOM | CHAT | TABLE | ..... (typeScreen)
 * @param {typeError} string // VOICE | VIDEO ...
 * @param {codeError} number // 201 | 20135
 * @param {description} string
 * @returns
 */

/**
 * create errors data‚
 * @param {object}
 * @returns
 */
async function create(data = {}) {
  if (!isEmpty(data)) {
    return (await errorRef.add(data)).id;
  }
}

/**
 * get data by docId
 * @param {string} docId
 * @returns
 */
async function read(docId = "") {
  return (await errorRef.doc(docId).get()).data();
}

/**
 * get all errors data
 * @returns
 */
async function readAll() {
  return errorRef.get();
}

/**
 * update data by docId
 * @param {string} docId
 * @param {object} data
 * @returns
 */
async function update(docId = "", data = {}) {
  if (docId && !isEmpty(data)) {
    return errorRef.doc(docId).update(data);
  }
}

/**
 * add sub document in sub collection
 * @param {string} docId threadId
 * @param {object} data message
 * @returns
 */

async function deleteDocument(docPath) {
  if (docPath) {
    return errorRef.doc(docPath).delete();
  }
}

export default {
  create,
  read,
  readAll,
  update,
  deleteDocument,
};
