import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { StyleSuit, TextSuit } from "../styles";
import IconSuit from "./IconSuit";

const SuitComponent = ({ suit }) => {
  const size = useSelector((state) => state.size);
  const suitWidth = useSelector((state) => state.size.sortButtonWidth);
  const suitHeight = useSelector((state) => state.size.sortButtonHeight);
  const [innerSize, setInnerSize] = useState({ fontSize: 16 });

  const { t } = useTranslation();
  useEffect(() => {
    if (size) {
      const fontSize = 16 * size?.minScale;
      setInnerSize({
        fontSize,
      });
    }
  }, [size]);

  return (
    <div style={{ visibility: suit !== "" ? "visible" : "hidden" }}>
      {suit === "whatever" ? (
        <StyleSuit
          width={suitWidth}
          height={suitHeight}
          fontSize={innerSize.fontSize}
        >
          <TextSuit fontSize={innerSize.fontSize}>
            {t("noLimit.noLimit")}
          </TextSuit>
        </StyleSuit>
      ) : (
        <StyleSuit
          width={suitWidth}
          height={suitHeight}
          fontSize={innerSize.fontSize}
        >
          <IconSuit suit={suit} fontSize={innerSize.fontSize * 1.25} />
          <TextSuit
            fontSize={innerSize.fontSize}
            style={{
              whiteSpace: "nowrap",
            }}
          >
            {t("onStage.onStage")}
          </TextSuit>
        </StyleSuit>
      )}
    </div>
  );
};

SuitComponent.propTypes = {
  suit: PropTypes.string,
};
export default SuitComponent;
