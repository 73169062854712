import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
const useStyles = makeStyles(() => ({
  root: {
    color: "white",
    height: "100%",
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: "4.5em",
  },
  form: {
    maxWidth: "450px",
    height: "auto",
    "&>*": {
      marginBottom: "15px",
    },
    borderRadius: "20px",
    padding: "15px",
    backgroundColor: "transparent",
  },
  boxButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "75%",
    margin: "auto",
    height: 120,
    "&>*": {
      textTransform: "capitalize",
    },
  },
  mainBox: {
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    justifyContent: "center",
    height: "100%",
    // "&::-webkit-scrollbar": {
    //   display: "block !important",
    // },
  },
  dateOfBirth: {
    width: (props) => `${props.input_W}px`,
    height: (props) => `${props.input_H}px`,
    color: "#541f1c",
    textAlign: "center",
    border: "2px solid rgb(75, 74, 74)",
    "& div": {
      height: "100%",
      color: "#541513",
      backgroundColor: "#e9e1d4",
      paddingLeft: "1em",
      border: "none",
    },
    "& input": {
      fontWeight: "600",
      fontSize: 16,
    },
    "& input::placeholder": {
      color: "#c1c1c1 !important",
    },
  },
  input: {
    width: "80%",
    height: "40px",
    backgroundColor: "rgba(176, 195, 199, 0.7)",
    color: "#541f1c",
    textAlign: "start",
    border: "2px solid rgb(75, 74, 74)",
    borderRadius: "5px",
    fontSize: 16,
    fontWeight: "600",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
}));

export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  justify-content: space-evenly;
`;
export default useStyles;
