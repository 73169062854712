import React from "react";
import PropTypes from "prop-types";
import { ProfileWrapper } from "../styles";

const RightBottom = ({ height, children }) => {
  return <ProfileWrapper height={height}>{children}</ProfileWrapper>;
};

RightBottom.propTypes = {
  height: PropTypes.number,
  children: PropTypes.any,
};

export default RightBottom;
