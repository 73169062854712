import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
const useStyles = makeStyles(() => ({
  dialogPaper: {
    backgroundColor: "rgba(176, 195, 199, 1) !important",
    borderRadius: "40px !important",
    overflow: "unset !important",
  },
  form: {
    height: "auto",
    "&>*": {
      marginBottom: "15px",
    },
  },
  boxButton: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
    width: "80%",
    margin: "auto",
    height: 120,
    paddingBottom: "20px",
    "& > *": {
      textTransform: "capitalize",
    },
  },
  mainBox: {
    display: "flex",
    overflowY: "auto",
    overflowX: "hidden",
    justifyContent: "flex-start",
    alignItems: "center",
    height: "100%",
    width: "100%",
    flexDirection: "column",
  },
  buttonBlue: {
    borderRadius: "28px",
    backgroundColor: "#7d7d7d",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
  buttonOrange: {
    borderRadius: "999px",
    backgroundColor: "#ce5931",
    color: "white",
    width: (props) => `${props.btnWidth}px`,
    height: (props) => `${props.btnHeight}px`,
    textTransform: "capitalize",
    fontSize: (props) => `${props.fontSize16}px`,
    padding: (props) => `${props.buttonPaddingY}px ${props.buttonPaddingX}px`,
    border: "2px solid black",
  },
}));

export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  border-radius: 40px;
  justify-content: space-evenly;
`;
export default useStyles;
