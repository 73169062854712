import {
  Button,
  ClickAwayListener,
  Grow,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { AiOutlineMenu } from "react-icons/ai";
import { GrUserSettings } from "react-icons/gr";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { adminLogout } from "../../../../store/actions/authActions";
import Dialog from "../Dialog";
import useStyles from "./styles";

// eslint-disable-next-line react/prop-types
const Header = ({ setToggleSiderBar }) => {
  const { t } = useTranslation();
  const history = useHistory();

  const classes = useStyles({});
  const dispatch = useDispatch();
  const adminNickName = useSelector((state) => state.admin.adminNickName);
  const [openMenuSettings, setOpenMenuSettings] = useState(false);
  const [open, setOpen] = useState(false);

  const handleLogout = async () => {
    await adminLogout();
    dispatch({ type: "INITIAL_STATE" });
    history.push("/admin-login");
  };
  const handleToggleSettings = () => {
    setOpenMenuSettings((prevOpen) => !prevOpen);
  };

  const handleCloseDialog = () => {
    setOpen(false);
  };

  return (
    <div className={classes.header}>
      <div className={classes.toggleBtn}>
        <AiOutlineMenu
          className={classes.toggleIcon}
          onClick={() => setToggleSiderBar((pre) => !pre)}
        />
        <Typography className={classes.userName}>{t("admin.admin")}</Typography>
      </div>

      <Button className={classes.Btns} onClick={handleToggleSettings}>
        <GrUserSettings />
        <Typography className={classes.userName}>{adminNickName}</Typography>
        <Popper
          open={openMenuSettings}
          role={undefined}
          transition
          disablePortal
          placement="bottom-end"
          style={{
            zIndex: 1000,
            position: "absolute",
            top: "40px",
            right: 0,
            left: "unset",
          }}
          className={classes.handleToggleSettings}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === "bottom-end",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleToggleSettings}>
                  <MenuList
                    autoFocusItem={openMenuSettings}
                    id={"menu-settings"}
                    style={{ width: "250px" }}
                  >
                    <MenuItem onClick={() => setOpen(true)}>
                      {t("changePassword.changePassword")}
                    </MenuItem>
                    <MenuItem onClick={handleLogout}>
                      {t("common.logout")}
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Button>
      <Dialog.ChangePassDialog
        handleClose={handleCloseDialog}
        open={open}
        title="OK"
      />
    </div>
  );
};

export default Header;
