import { makeStyles } from "@material-ui/core";
import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { TUTORIAL_MODE } from "../../../../../../store/constant";
import { calIsMyTurn } from "../../../../../../utils/common";
import VCardShip from "../../../CardShip/VCardShip";
import { Profile } from "../../../Profile/Profile";
import { RightTutorialCards } from "../../../TutorialCards/RightTutorialCards";

const useStyles = makeStyles(() => ({
  profile: {
    marginLeft: (props) => props.marginRight,
    position: "relative",
    top: "10px",
  },
}));

const GameRightMiddleContainer = styled.div`
  height: ${(props) => props.height}px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  padding: 0 ${(props) => props.padding}px;
`;

const RightSideContainer = styled.div`
  height: 100%;
  width: auto;
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  position: relative;
`;
const GameRightMiddle = React.memo(function GameRightMiddleComponent() {
  const shipHeight = useSelector((state) => state.size.vShipHeight);
  const shipSpace = useSelector((state) => state.size.shipSpace);
  const profileShipSpace = useSelector((state) => state.size.profileShipSpace);
  const profile = useSelector((state) => state.room.profile1);
  const cardPlayer = useSelector((state) => state.playerGameState.cardPlayer1);
  const myIndex = useSelector((state) => state.playerGameState.myIndex);
  const otherPlayersState = useSelector(
    (state) => state.playerGameState.otherPlayersState
  );
  const isMyTurn = useMemo(() => {
    return calIsMyTurn(otherPlayersState, (myIndex + 1) % 4);
  }, [otherPlayersState, myIndex]);
  const otherState = useMemo(
    () =>
      otherPlayersState[profile?.index || 0] || {
        isYourTurn: false,
        numberOfCalls: -1,
        starCards: [],
      },
    [otherPlayersState, profile]
  );
  const tutorialMode = useSelector(
    (state) => state.playerGameState.tutorialMode
  );

  const isTutorial = useMemo(() => {
    return [TUTORIAL_MODE.HAS_CALL, TUTORIAL_MODE.NO_CALL].includes(
      tutorialMode
    );
  }, [tutorialMode]);

  const height = useMemo(
    () => Math.round(shipHeight + shipSpace * 2),
    [shipHeight, shipSpace]
  );

  const classes = useStyles({ marginRight: profileShipSpace });

  return (
    <GameRightMiddleContainer height={height} padding={shipSpace}>
      <VCardShip
        side="right"
        cards={otherState.starCards}
        numberOfCalls={otherState.numberOfCalls}
        profile={profile}
      />
      <RightSideContainer>
        <Profile
          profile={profile}
          position={"left"}
          positionTurn={"right"}
          isMyTurn={isMyTurn}
          numberOfCalls={otherState.numberOfCalls}
          isTutorial={isTutorial}
          className={classes.profile}
        />
        <RightTutorialCards
          id="rightTutorialCards"
          isTutorial={isTutorial}
          cards={cardPlayer?.cards}
          isGChaos={cardPlayer.isGChaos}
          className={classes.profile}
          isSorted={cardPlayer.isSorted}
        />
      </RightSideContainer>
    </GameRightMiddleContainer>
  );
});
export default GameRightMiddle;
