import React from "react";
import PropTypes from "prop-types";
import { TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import useStyles from "./styles";

const HeaderCells = ({ headCells, orderBy, order, createSortHandler }) => {
  const classes = useStyles();

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align="center"
            sortDirection={orderBy === headCell.id ? order : false}
            className={classes.tableCell}
            style={{ color: "#541513", fontWeight: "bold" }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
              style={{
                color: "#541513",
                fontWeight: "bold",
              }}
              className={classes.label}
            >
              {headCell.label}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell></TableCell>
      </TableRow>
    </TableHead>
  );
};

HeaderCells.propTypes = {
  headCells: PropTypes.array.isRequired,
  orderBy: PropTypes.string.isRequired,
  order: PropTypes.string.isRequired,
  createSortHandler: PropTypes.func,
};

export default HeaderCells;
