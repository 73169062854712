import { STEP_NARRATION, UPDATE_MASTER_GRAND_CROSS } from "../constant";
import {
  ADD_PLAYER_GAME_STATE_LEAVE_ROOM_NOTIFICATION_STEP,
  ADD_PLAYER_GAME_STATE_NOTIFY_STEP,
  CHECK_SHOW_DIALOG_MASTER_PICKING_CARD,
  CLEAR_NEWEST_PLAYER_OTHER_STATE,
  CLEAR_PLAYER_GAME_STATE,
  DELAY_G_CHAOS_PLAY_CARD,
  GCHAOS_SELECT_ORDER_CARD,
  HAND_CARDS_ROUND_12,
  LOCAL_SAVE_PLAYER_GAME_STATE,
  NICKNAMES_PLAYERS_SELECT_ORDER_CARDS,
  NOT_SHOW_ARK_AFTER_USE_MASTER_CARD,
  OTHER_PLAYER_HAND_CARDS,
  PLAYER_CHANGE_COMMIT_FIRST,
  PLAYER_CHANGE_STEP,
  PLAYER_INTRODUCE_COMPLETE,
  PLAYER_SAVE_GRAND_CROSS_ROUND12,
  PLAYER_SAVE_NEW_OTHER_PLAYER,
  PLAYER_SAVE_NEW_SUIT_GRAND_CROSS_ONLY,
  PLAYER_SAVE_NEW_SUIT_STATE,
  PLAYER_SAVE_OTHER_PLAYER_ROUND13,
  PLAYER_UPDATE_GRAND_CROSS_ROUND12,
  PLAYER_UPDATE_MASTER_GRAND_CROSS_ON_DISPLAY,
  PLAYER_UPDATE_NEW_OTHER_PLAYER,
  PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS,
  PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS_ONLY,
  PLAYER_UPDATE_NEW_SUIT_STATE_OTHER_STATE,
  PLAYER_UPDATE_OTHER_PLAYER_ROUND13,
  PLAY_A_CARD_GAME_STATE,
  PLAY_CARD_LOCAL,
  DOUBLE_CLICK_HAND_CARD,
  REMOVE_GCHAOS_SELECT_FIRST_CARD,
  REMOVE_PLAYER_GAME_STATE,
  RESET_OTHER_STATE_USE_MASTER_CARD,
  SAVE_IS_MY_TURN,
  SAVE_OTHER_PLAYERS_STATE_LOCAL,
  SET_MY_NUMBER_OF_CALLS,
  SET_NEED_RELOAD,
  SET_NOTIFY_G_CHAOS_TURN,
  SET_PLAYER_GAME_STATE,
  STATE_13,
  UPDATE_APPLY_CALLS,
  UPDATE_CAN_SELECT_CARD,
  UPDATE_CAN_SELECT_MC,
  UPDATE_GRAND_CROSS_ONLY,
  UPDATE_IS_MY_TURN,
  UPDATE_NICKNAME_PLAYER_SELECT_MASTER_CARD,
  UPDATE_ORDER_CARDS,
  UPDATE_OTEHR_PLAYER_IS_MY_TURN,
  UPDATE_OTHER_IS_MY_TURN,
  UPDATE_OTHER_PLAYERS_STATE,
  UPDATE_OTHER_PLAYERS_STATE_FROM_LOCAL,
  UPDATE_OTHER_STATE_G_CHAOS_SELECT_MASTER_CARD,
  UPDATE_OTHER_STATE_USE_MASTER_CARDS,
  UPDATE_PLAYER_GAME_STATE_FROM_LOCAL_SAVED,
  UPDATE_SHOW_MASTER,
  UPDATE_START_TURN_TIME,
  UPDATE_STEP_ID,
  UPDATE_TEMP_START_GAME_GRAND_CROSS,
  UPDATE_TURN_SELECT_ORDER_CARD,
  UPDATE_WIN_GAME_SHOWED,
} from "../types";
const initialState = {
  playerId: "",
  gameId: "",
  otherPlayersState: [],
  isMyTurn: false,
  grandCross: [null, null, null, null],
  turnStatus: "",
  role: "Normal",
  instructorMessage: "",
  gameStartTime: "",
  startTurnTime: {},
  playerMessage: "",
  myState: {
    handCards: [],
    starCards: [],
    numberOfCalls: -1,
    limitCalls: -1,
  },
  gameStatus: "",
  suit: "",
  master: "",
  isShowMaster: false,
  currentRound: 0,
  gChaosNumberOfCalls: null,
  tutorialMode: "",
  cardPlayer1: [],
  cardPlayer2: [],
  cardPlayer3: [],
  // for narration step
  stepId: "",
  orderGrandCross: [null, null, null, null],
  endGamePlayerId: "",
  trickStarCard: {},
  masterGrandCross: [null, null, null, null],

  // effect update otherPlayerState
  newestPlayerOtherState: [],
  newestGrandCross: [],
  newestMyState: {
    handCards: [],
    starCards: [],
    numberOfCalls: -1,
    limitCalls: -1,
  },
  grandCrossRound12: [null, null, null, null],
  otherPlayersStateRound13: [null, null, null, null],
  myStateRound13: {
    handCards: [],
    starCards: [],
    numberOfCalls: -1,
    limitCalls: -1,
  },
  // new suit
  newSuitGrandCross: [null, null, null, null],
  newSuitMyState: {
    handCards: [],
    starCards: [],
    numberOfCalls: -1,
    limitCalls: -1,
  },
  newSuitOtherPlayersState: [null, null, null, null],

  // hand cards round 12
  cardPlayer1Round12: [],
  cardPlayer2Round12: [],
  cardPlayer3Round12: [],
  // nicknames of players select order cards
  nicknames: "",
  myIndex: -1,
  tempStarGameGrandCross: [null, null, null, null],
  notifications: [],
  localSavePlayerGameState: {},
  gChaosAfterCaptain: false,
  isMyTurnLocal: false,
  otherPlayersStateLocal: [],
  orderCards: [],
  nextConfirmType: "",
  nextActionPlayer: "",
  canPlayCard: false,
  gChaosId: "",
  canSelectCard: false,
  canSelectMC: false,
  winGameShowed: false,
  isShowDialogMasterConfirmRequestPlease: false,
  isShowDialogWaitMasterConfirmRequestPlease: false,
  playCardLocal: null,
  needReload: false,
  showArk: true,
  notifyGChaosTurn: false,
  doubleClickCard: null,
  playCardFail: false,
};

const playerGameStatesReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_CAN_SELECT_MC: {
      return {
        ...state,
        canSelectMC: action.payload,
      };
    }
    case CHECK_SHOW_DIALOG_MASTER_PICKING_CARD: {
      return {
        ...state,
        isShowDialogMasterConfirmRequestPlease:
          action.payload.isShowDialogMasterConfirmRequestPlease,
        isShowDialogWaitMasterConfirmRequestPlease:
          action.payload.isShowDialogWaitMasterConfirmRequestPlease,
      };
    }
    case UPDATE_MASTER_GRAND_CROSS: {
      return {
        ...state,
        masterGrandCross: action.payload.masterGrandCross,
      };
    }
    case UPDATE_NICKNAME_PLAYER_SELECT_MASTER_CARD: {
      return {
        ...state,
        grandCross: action.payload.grandCross,
        masterGrandCross: action.payload.grandCross,
        canSelectMC: false,
      };
    }
    case SET_PLAYER_GAME_STATE: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case SET_MY_NUMBER_OF_CALLS: {
      return {
        ...state,
        myState: action.payload,
      };
    }

    case UPDATE_APPLY_CALLS: {
      return {
        ...state,
        myState: {
          ...state.myState,
          limitCalls: action.payload.limitCalls,
        },
      };
    }
    case PLAY_A_CARD_GAME_STATE: {
      return {
        ...state,
        newSuitGrandCross: action.payload.grandCross,
        grandCross: action.payload.grandCross,
        myState: action.payload.myState,
      };
    }
    case OTHER_PLAYER_HAND_CARDS: {
      return {
        ...state,
        cardPlayer1: action.payload.cardPlayer1
          ? action.payload.cardPlayer1
          : state.cardPlayer1,
        cardPlayer2: action.payload.cardPlayer2
          ? action.payload.cardPlayer2
          : state.cardPlayer2,
        cardPlayer3: action.payload.cardPlayer3
          ? action.payload.cardPlayer3
          : state.cardPlayer3,
      };
    }
    case PLAYER_INTRODUCE_COMPLETE:
      return {
        ...state,
        grandCross: action.payload.grandCross,
      };

    case PLAYER_CHANGE_STEP:
      return {
        ...state,
        stepId: action.payload.stepId,
      };
    case PLAYER_CHANGE_COMMIT_FIRST:
      return {
        ...state,
        orderGrandCross: action.payload.orderGrandCross,
        endGamePlayerId: action.payload.endGamePlayerId,
        masterGrandCross: action.payload.masterGrandCross,
        stepId: action.payload.stepId,
      };

    case PLAYER_UPDATE_NEW_OTHER_PLAYER:
      return {
        ...state,
        otherPlayersState: [...state.newestPlayerOtherState],
        grandCross: [...state.newestGrandCross],
        myState: { ...state.newestMyState },
      };
    case PLAYER_SAVE_NEW_OTHER_PLAYER:
      return {
        ...state,
        newestPlayerOtherState: action.payload.newestPlayerOtherState,
        newestGrandCross: action.payload.newestGrandCross,
        newestMyState: action.payload.newestMyState,
      };

    //     PLAYER_SAVE_GRAND_CROSS_ROUND12 =
    //  PLAYER_SAVE_OTHER_PLAYER_ROUND13 =
    case PLAYER_SAVE_GRAND_CROSS_ROUND12:
      return {
        ...state,
        // currentRound: 13,
        grandCrossRound12: action.payload.grandCross,
      };
    case PLAYER_SAVE_OTHER_PLAYER_ROUND13:
      return {
        ...state,
        // currentRound: 13,
        otherPlayersStateRound13: action.payload.otherPlayersState,
        myStateRound13: action.payload.myState,
      };

    case PLAYER_UPDATE_GRAND_CROSS_ROUND12:
      return {
        ...state,
        grandCross: state.grandCrossRound12,
        myState: action.payload.myState,
        cardPlayer1Round12: action.payload.cardPlayer1Round12,
        cardPlayer2Round12: action.payload.cardPlayer2Round12,
        cardPlayer3Round12: action.payload.cardPlayer3Round12,
      };

    case PLAYER_UPDATE_OTHER_PLAYER_ROUND13:
      return {
        ...state,
        // currentRound: 13,
        otherPlayersState: state.otherPlayersStateRound13,
        myState: state.myStateRound13,
        grandCross: [null, null, null, null],
      };
    case PLAYER_SAVE_NEW_SUIT_STATE:
      return {
        ...state,
        newSuitGrandCross: action.payload.grandCross,
        newSuitMyState: action.payload.myState,
        newSuitOtherPlayersState: action.payload.otherPlayersState,
      };
    case PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS:
      return {
        ...state,
        grandCross: [...state.newSuitGrandCross],
        myState: { ...state.newSuitMyState },
      };
    case PLAYER_UPDATE_NEW_SUIT_STATE_OTHER_STATE:
      return {
        ...state,
        otherPlayersState: [...state.newSuitOtherPlayersState],
      };
    case DELAY_G_CHAOS_PLAY_CARD:
      return {
        ...state,
        grandCross: [null, null, null, null],
        myState: { ...state.newSuitMyState },
        otherPlayersState: [...state.newSuitOtherPlayersState],
      };
    case HAND_CARDS_ROUND_12:
      return {
        ...state,
        cardPlayer1Round12: action.payload.cardPlayer1Round12
          ? action.payload.cardPlayer1Round12
          : state.cardPlayer1Round12,
        cardPlayer2Round12: action.payload.cardPlayer2Round12
          ? action.payload.cardPlayer2Round12
          : state.cardPlayer2Round12,
        cardPlayer3Round12: action.payload.cardPlayer3Round12
          ? action.payload.cardPlayer3Round12
          : state.cardPlayer3Round12,
      };

    case PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS_ONLY:
      return {
        ...state,
        grandCross: [...state.newSuitGrandCross],
      };

    case PLAYER_SAVE_NEW_SUIT_GRAND_CROSS_ONLY:
      return {
        ...state,
        newSuitGrandCross: action.payload.grandCross,
      };
    case PLAYER_UPDATE_MASTER_GRAND_CROSS_ON_DISPLAY:
      return {
        ...state,
        grandCross: [...state.masterGrandCross],
      };
    case NICKNAMES_PLAYERS_SELECT_ORDER_CARDS:
      return {
        ...state,
        nicknames: action.payload.nicknames,
      };
    case CLEAR_NEWEST_PLAYER_OTHER_STATE:
      return {
        ...state,
        newestPlayerOtherState: [],
        newestGrandCross: [],
        newestMyState: {
          handCards: [],
          starCards: [],
          numberOfCalls: -1,
          limitCalls: -1,
        },
      };
    case REMOVE_PLAYER_GAME_STATE:
      return initialState;
    case ADD_PLAYER_GAME_STATE_NOTIFY_STEP:
      return {
        ...state,
        stepId: STEP_NARRATION.INSTRUCTOR_NOTIFY_STEP,
      };
    case ADD_PLAYER_GAME_STATE_LEAVE_ROOM_NOTIFICATION_STEP:
      return {
        ...state,
        stepId: STEP_NARRATION.LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_STEP,
      };
    case UPDATE_GRAND_CROSS_ONLY:
      return {
        ...state,
        grandCross: action.payload.grandCross,
      };
    case UPDATE_TEMP_START_GAME_GRAND_CROSS:
      return {
        ...state,
        tempStarGameGrandCross: action.payload.grandCross,
      };
    case REMOVE_GCHAOS_SELECT_FIRST_CARD:
      return {
        ...state,
        grandCross: action.payload.grandCross,
        newSuitGrandCross: action.payload.newGrandCross,
      };
    case GCHAOS_SELECT_ORDER_CARD:
      return {
        ...state,
        grandCross: action.payload.grandCross,
      };
    case STATE_13:
      return {
        ...state,
        currentRound: 13,
      };
    case LOCAL_SAVE_PLAYER_GAME_STATE:
      return {
        ...state,
        localSavePlayerGameState: action.payload.state,
        gChaosAfterCaptain: action.payload.isGChoasAfterCaptain,
      };
    case UPDATE_PLAYER_GAME_STATE_FROM_LOCAL_SAVED:
      return {
        ...state,
        ...state.localSavePlayerGameState,
        localSavePlayerGameState: {},
        gChaosAfterCaptain: action.payload.isGChaosAfterCaptain,
      };
    case SAVE_IS_MY_TURN:
      return {
        ...state,
        isMyTurnLocal: action.payload,
      };
    case UPDATE_IS_MY_TURN:
      return {
        ...state,
        isMyTurn: state.isMyTurnLocal,
      };
    case UPDATE_OTHER_IS_MY_TURN: {
      return {
        ...state,
        cardPlayer1: {
          ...state.cardPlayer1,
          isMyTurn: state.cardPlayer1.isMyTurnLocal,
        },
        cardPlayer2: {
          ...state.cardPlayer2,
          isMyTurn: state.cardPlayer2.isMyTurnLocal,
        },
        cardPlayer3: {
          ...state.cardPlayer3,
          isMyTurn: state.cardPlayer3.isMyTurnLocal,
        },
        // otherPlayersState: state.otherPlayersStateLocal,
      };
    }
    case UPDATE_OTEHR_PLAYER_IS_MY_TURN:
      return {
        ...state,
        otherPlayersState: action.payload,
      };
    case UPDATE_OTHER_PLAYERS_STATE:
      return {
        ...state,
        otherPlayersState: action.payload,
      };
    case SAVE_OTHER_PLAYERS_STATE_LOCAL:
      return {
        ...state,
        otherPlayersStateLocal: action.payload,
      };
    case UPDATE_OTHER_PLAYERS_STATE_FROM_LOCAL:
      return {
        ...state,
        otherPlayersState: state.otherPlayersStateLocal,
      };
    case UPDATE_OTHER_STATE_USE_MASTER_CARDS: {
      return {
        ...state,
        isMyTurn: action.payload.isMyTurn,
        otherPlayersState: action.payload.otherPlayersState,
        canSelectMC: action.payload.canSelectMC,
      };
    }
    case UPDATE_OTHER_STATE_G_CHAOS_SELECT_MASTER_CARD: {
      return {
        ...state,
        otherPlayersState: action.payload.otherPlayersState,
      };
    }
    case RESET_OTHER_STATE_USE_MASTER_CARD: {
      return {
        ...state,
        otherPlayersState: action.payload.otherPlayersState,
      };
    }
    case UPDATE_ORDER_CARDS: {
      return {
        ...state,
        isMyTurn: action.payload.isMyTurn,
        orderCards: action.payload.orderCards,
      };
    }
    case UPDATE_SHOW_MASTER: {
      return {
        ...state,
        isShowMaster: action.payload,
      };
    }
    case UPDATE_WIN_GAME_SHOWED: {
      return {
        ...state,
        winGameShowed: action.payload,
      };
    }
    case UPDATE_START_TURN_TIME: {
      return {
        ...state,
        startTurnTime: action.payload,
      };
    }
    case UPDATE_STEP_ID: {
      return {
        ...state,
        stepId: action.payload,
      };
    }
    case UPDATE_TURN_SELECT_ORDER_CARD: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case UPDATE_CAN_SELECT_CARD: {
      return {
        ...state,
        ...action.payload,
      };
    }
    case PLAY_CARD_LOCAL: {
      return {
        ...state,
        playCardLocal: action.payload,
      };
    }
    case DOUBLE_CLICK_HAND_CARD: {
      return {
        ...state,
        doubleClickCard: action.payload,
      };
    }
    case CLEAR_PLAYER_GAME_STATE:
      return initialState;
    case SET_NEED_RELOAD:
      return {
        ...state,
        needReload: action.payload,
      };
    case NOT_SHOW_ARK_AFTER_USE_MASTER_CARD: {
      return {
        ...state,
        showArk: action.payload,
      };
    }
    case SET_NOTIFY_G_CHAOS_TURN: {
      return {
        ...state,
        notifyGChaosTurn: action.payload,
      };
    }
    case "PLAY_CARD_FAIL": {
      return {
        ...state,
        playCardFail: action.payload,
      };
    }
    default:
      return state;
  }
};

export default playerGameStatesReducer;
