import { Route, Redirect, useLocation } from "react-router-dom";
import firebase from "../../services/firebase";
import PropTypes from "prop-types";
export default function PublicRoute({ component: Component, ...rest }) {
  const location = useLocation();
  return (
    <Route
      {...rest}
      render={(props) =>
        firebase.auth().currentUser ? (
          <Redirect
            to={
              location.state && location.pathname !== "/login"
                ? `${location.state.from.pathname + location.state.from.search}`
                : "/"
            }
          />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
}

PublicRoute.propTypes = {
  component: PropTypes.elementType,
};
