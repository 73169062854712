import { getFunctions } from "../../services/firebase";
import {
  CREATE_EMPTY_SUBSCRIPTION,
  GET_PLAYER_SUBSCRIPTION_INFO,
  SEND_MAIL_UNSUBSCRIBE_PLAN,
  SEND_MAIL_DELETE_PLAYER,
} from "../funcs";
const functions = getFunctions();

export const createEmptySubscription = async (playerId, email) => {
  const createEmptySubscriptionFunction = functions.httpsCallable(
    CREATE_EMPTY_SUBSCRIPTION
  );

  return createEmptySubscriptionFunction({ playerId, email });
};

export const getPlayerSubscriptionInfo = async (playerId) => {
  const getPlayerSubscriptionInfoFunction = functions.httpsCallable(
    GET_PLAYER_SUBSCRIPTION_INFO
  );
  return getPlayerSubscriptionInfoFunction({ playerId });
};

export const sendMailUnsubscribePlan = async (email) => {
  const lang = localStorage.getItem("i18nextLng");

  const sendMailUnsubscribePlanFunction = functions.httpsCallable(
    SEND_MAIL_UNSUBSCRIBE_PLAN
  );
  return sendMailUnsubscribePlanFunction({ email, lang });
};

export const sendMailDeletePlayer = async (email, fullName) => {
  try {
    return functions.httpsCallable(SEND_MAIL_DELETE_PLAYER)({
      email,
      fullName,
    });
  } catch (error) {
    console.log(error);
  }
};
