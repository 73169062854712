import { Box, Checkbox, makeStyles, Typography } from "@material-ui/core";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormGroup from "@mui/material/FormGroup";
import { Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GrStatusGoodSmall } from "react-icons/gr";
import { useSelector } from "react-redux";
import styled from "styled-components";
import { tableList } from "../../../store/actions/tableActions";
import { STD_SIZE } from "../../../store/constant";
import PlayerStt from "../components/PlayerStt";

const NicknameStyle = styled.p`
  margin-left: 40px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  padding-right: 1.5em;
  box-sizing: border-box;
`;

const TableStyle = styled.li`
  margin-bottom: 15px;
  border-bottom: 1px solid #442211;
  padding-bottom: 15px;
  &:last-child {
    border-bottom: 0;
  }
`;

const ListTable = styled.ul`
  padding: 15px;
  list-style: none;
`;

const useStyles = makeStyles(() => ({
  label: {
    fontSize: "14px",
    textAlign: "start",
    boxSizing: "border-box",
    color: "#442211",
  },
  tableName: {
    fontSize: "14px",
    textAlign: "start",
    boxSizing: "border-box",
    fontWeight: "600",
    color: "#442211",
  },
  description: {
    fontSize: "14px",
    textAlign: "start",
    boxSizing: "border-box",
    marginTop: "10px",
    whiteSpace: "normal",
    color: "#442211",
  },
  root: {
    padding: "5px 15px 15px",
    borderBottom: "1px solid #442211",
  },
  tableStatus: {
    display: "flex",
    width: "100%",
  },
  unlockWrap: {
    display: "flex",
    width: "50%",
    alignItems: "center",
  },
  unlockImg: {
    width: "25px",
    color: "#442211",
  },
  lockImg: {
    width: "22px",
    color: "#442211",
  },
  iconWrap: {
    margin: "0px 15px",
  },
  contentTop: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  tableNameWrap: {
    display: "flex",
    alignItems: "center",
  },
}));

function TableStatus() {
  const [tablesList, setTableList] = useState([]);
  const { t } = useTranslation();
  const minScale = useSelector((state) => state.size.minScale);
  const [drawerWidth, setDrawerWidth] = useState(STD_SIZE.STD_DRAWER_WIDTH);
  const classes = useStyles({ minScale, drawerWidth });
  const [checkBoxUnlock, setCheckBoxUnlock] = useState(true);
  const [checkBoxLock, setCheckBoxLock] = useState(true);
  const [circleSize] = useState(14);

  useEffect(() => {
    const resizeWidth = STD_SIZE.STD_DRAWER_WIDTH * minScale;
    const minWidth = Math.round(STD_SIZE.STD_DRAWER_WIDTH / 2);
    if (resizeWidth < minWidth) {
      setDrawerWidth(minWidth);
    } else {
      setDrawerWidth(STD_SIZE.STD_DRAWER_WIDTH * minScale);
    }
  }, [minScale]);

  useEffect(() => {
    const fetchTableList = async () => {
      const tableData = await tableList();
      if (checkBoxUnlock && !checkBoxLock) {
        setTableList(tableData.filter((table) => table?.isOpen === true));
      } else if (!checkBoxUnlock && checkBoxLock) {
        setTableList(tableData.filter((table) => table?.isOpen === false));
      } else if (!checkBoxUnlock && !checkBoxLock) {
        setTableList([]);
      } else {
        setTableList(tableData);
      }
    };
    fetchTableList();
  }, [checkBoxUnlock, checkBoxLock]);

  const handleCheckBoxUnlock = (e) => {
    setCheckBoxUnlock(e.target.checked);
  };

  const handleCheckBoxLock = (e) => {
    setCheckBoxLock(e.target.checked);
  };
  return (
    <Fragment>
      <PlayerStt isShowOnlineStatus={false} />
      <Box className={classes.root}>
        <Typography className={classes.label}>{t("tables.filter")}</Typography>
        <Box className={classes.tableStatus}>
          <Box className={classes.unlockWrap}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  style={{
                    padding: "0",
                    margin: "0",
                  }}
                  control={
                    <Checkbox
                      style={{
                        padding: "0",
                        transform: "scale(2)",
                      }}
                      checked={checkBoxUnlock}
                      onClick={handleCheckBoxUnlock}
                      color="default"
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
            <Box className={classes.iconWrap}>
              <img
                className={classes.unlockImg}
                src={require("../../../assets/icons/icon-unlock.svg").default}
                alt="unlock"
              />
            </Box>
            <Box className={classes.label}>{t("tables.tableOpen")}</Box>
          </Box>
          <Box className={classes.unlockWrap}>
            <Box>
              <FormGroup>
                <FormControlLabel
                  style={{
                    padding: "0",
                    margin: "0",
                  }}
                  control={
                    <Checkbox
                      style={{
                        padding: "0",
                        transform: "scale(2)",
                      }}
                      checked={checkBoxLock}
                      onClick={handleCheckBoxLock}
                      color="default"
                    />
                  }
                  label=""
                />
              </FormGroup>
            </Box>
            <Box className={classes.iconWrap}>
              <img
                className={classes.lockImg}
                src={require("../../../assets/icons/icon-lock.svg").default}
                alt="lock"
              />
            </Box>
            <Box className={classes.label}>{t("tables.tableClose")}</Box>
          </Box>
        </Box>
      </Box>
      <ListTable>
        {tablesList.map((item) => (
          <TableStyle key={item?.tableId}>
            <span>
              {item?.isPlaying === true ? (
                <Box className={classes.contentTop}>
                  <Box className={classes.tableNameWrap}>
                    <GrStatusGoodSmall
                      style={{
                        color: "#DC000C",
                        marginRight: "10px",
                        fontSize: circleSize,
                      }}
                    />
                    <Typography className={classes.tableName}>
                      {t("tables.tableName")}: {item?.tableName.slice(0, -5)}
                    </Typography>
                  </Box>
                  {item?.isOpen ? (
                    <Box>
                      <img
                        className={classes.unlockImg}
                        src={
                          require("../../../assets/icons/icon-unlock.svg")
                            .default
                        }
                        alt="unlock"
                      />
                    </Box>
                  ) : (
                    <Box>
                      <img
                        className={classes.lockImg}
                        src={
                          require("../../../assets/icons/icon-lock.svg").default
                        }
                        alt="lock"
                      />
                    </Box>
                  )}
                </Box>
              ) : (
                <Box className={classes.contentTop}>
                  <Box className={classes.tableNameWrap}>
                    <GrStatusGoodSmall
                      style={{
                        color: "#FFEC00",
                        marginRight: "10px",
                        fontSize: circleSize,
                      }}
                    />
                    <Typography className={classes.tableName}>
                      {t("tables.tableName")}: {item?.tableName.slice(0, -5)}
                    </Typography>
                  </Box>
                  {item?.isOpen ? (
                    <Box>
                      <img
                        className={classes.unlockImg}
                        src={
                          require("../../../assets/icons/icon-unlock.svg")
                            .default
                        }
                        alt="unlock"
                      />
                    </Box>
                  ) : (
                    <Box>
                      <img
                        className={classes.lockImg}
                        src={
                          require("../../../assets/icons/icon-lock.svg").default
                        }
                        alt="lock"
                      />
                    </Box>
                  )}
                </Box>
              )}
            </span>
            {item?.players.length > 2 ? (
              <Box
                style={{
                  display: "flex",
                }}
              >
                <Box
                  style={{
                    width: "170px",
                  }}
                >
                  {item.players
                    .slice(0, Math.ceil(item.players.length / 2))
                    .map((p) => (
                      <NicknameStyle
                        key={p?.playerId}
                        className={classes.label}
                      >
                        {p?.playerName === "G-Chaos"
                          ? t("status.computer")
                          : p?.playerName}
                      </NicknameStyle>
                    ))}
                </Box>
                <Box
                  style={{
                    display: "flex",
                  }}
                >
                  <Box
                    style={{
                      width: "170px",
                    }}
                  >
                    {item.players
                      .slice(
                        Math.ceil(item.players.length / 2),
                        item.players.length
                      )
                      .map((p) => (
                        <NicknameStyle
                          key={p?.playerId}
                          className={classes.label}
                        >
                          {p?.playerName === "G-Chaos"
                            ? t("status.computer")
                            : p?.playerName}
                        </NicknameStyle>
                      ))}
                  </Box>
                </Box>
              </Box>
            ) : (
              <Box
                style={{
                  display: "flex",
                }}
              >
                <Box
                  style={{
                    width: "170px",
                  }}
                >
                  {item.players.map((p) => (
                    <NicknameStyle key={p?.playerId} className={classes.label}>
                      {p?.playerName === "G-Chaos"
                        ? t("status.computer")
                        : p?.playerName}
                    </NicknameStyle>
                  ))}
                </Box>
              </Box>
            )}
            <Box className={classes.description}>{item?.description}</Box>
          </TableStyle>
        ))}
      </ListTable>
    </Fragment>
  );
}

export default TableStatus;
