import React from "react";
import PropTypes from "prop-types";
import "./BaseDialog.css";
import { BaseDialogHeader, useStyle } from "./styles";
import { Dialog, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

const BaseDialog = (props) => {
  const { isOpen, onClose, children } = props;
  const classes = useStyle();
  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      className={"base-dialog"}
      classes={{ paper: classes.paper }}
    >
      <BaseDialogHeader>
        <IconButton className={classes.iconBtn} onClick={onClose}>
          <CloseIcon className={classes.closeIcon} />
        </IconButton>
      </BaseDialogHeader>
      {children}
    </Dialog>
  );
};

BaseDialog.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node,
};

export default React.memo(BaseDialog);
