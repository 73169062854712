import firebase, { getFunctions } from "../../services/firebase";
import {
  clearGChaosComputerSanNickName,
  instructorNotify,
} from "../../utils/common";
import {
  CARD_SUIT,
  CARD_SUIT_CLUBS,
  CARD_SUIT_DIAMONDS,
  CARD_SUIT_HEARTS,
  CARD_SUIT_SPADES,
  CARD_SUIT_WHATEVER,
  G_CHAOS,
  PLAYER_ROLE_G_CHAOS,
  PLAYER_ROLE_MASTER_G_CHAOS,
  POPUPS,
  SCREEN_NARRATION,
  UPDATE_MASTER_GRAND_CROSS,
} from "../constant";
import {
  CLEAR_ALL_PLAYER_GAME_STATE_END_GAME,
  CLEAR_GAME_STATE_OF_ONE_PLAYER,
  CLEAR_MY_PLAYER_GAME_STATE_CONFIRM_SOMEONE_LEAVE,
  CLEAR_PLAYER_GAME_STATE,
  PLUS_PLAYER_READY,
} from "../funcs";
import {
  DELAY_G_CHAOS_PLAY_CARD,
  GCHAOS_SELECT_ORDER_CARD,
  HAND_CARDS_ROUND_12,
  NICKNAMES_PLAYERS_SELECT_ORDER_CARDS,
  OTHER_PLAYER_HAND_CARDS,
  PLAYER_CHANGE_COMMIT_FIRST,
  PLAYER_CHANGE_STEP,
  PLAYER_INTRODUCE_COMPLETE,
  PLAYER_SAVE_GRAND_CROSS_ROUND12,
  PLAYER_SAVE_NEW_OTHER_PLAYER,
  PLAYER_SAVE_NEW_SUIT_STATE,
  PLAYER_SAVE_OTHER_PLAYER_ROUND13,
  PLAYER_UPDATE_GRAND_CROSS_ROUND12,
  PLAYER_UPDATE_MASTER_GRAND_CROSS_ON_DISPLAY,
  PLAYER_UPDATE_NEW_OTHER_PLAYER,
  PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS_ONLY,
  PLAYER_UPDATE_NEW_SUIT_STATE_REQUEST,
  PLAYER_UPDATE_OTHER_PLAYER_ROUND13,
  PLAY_A_CARD_GAME_STATE,
  REMOVE_GCHAOS_SELECT_FIRST_CARD,
  REMOVE_PLAYER_GAME_STATE,
  SET_PLAYER_GAME_STATE_REQUEST,
  UPDATE_APPLY_CALLS,
  UPDATE_IS_MY_TURN,
  UPDATE_NICKNAME_PLAYER_SELECT_MASTER_CARD,
  UPDATE_ORDER_CARDS,
  UPDATE_OTHER_IS_MY_TURN,
  DOUBLE_CLICK_HAND_CARD,
} from "../types";
import { fnUpdateOrderCardForPlayer } from "./gameActions";

const db = firebase.firestore();
const playerGameStateRef = db.collection("playerGameStates");
const functions = getFunctions();

export const udpateCardSorted = async (playerId) => {
  return playerGameStateRef.doc(playerId).update({ isSorted: true });
};

/**
 * Listen player game state from FireStore
 * @param {*} setUnsubscribe
 * @returns
 */
export const subscribePlayerGameStateByPlayerId = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    const { playerId } = getState().user;
    const currentAllState = getState();
    if (playerId && playerId !== "") {
      const unsubscribe = playerGameStateRef
        .doc(playerId)
        .onSnapshot((snapshot) => {
          if (snapshot.exists) {
            const newState = snapshot.data();
            const currentState = getState().playerGameState;
            const roomState = getState().room;
            dispatch({
              type: SET_PLAYER_GAME_STATE_REQUEST,
              payload: newState,
            });
            instructorNotify(
              currentState,
              newState,
              roomState,
              dispatch,
              currentAllState
            );
          } else {
            dispatch({
              type: REMOVE_PLAYER_GAME_STATE,
            });
          }
        });
      setUnsubscribe(unsubscribe);
    }
  };
};

export const updatePlayerStartTurnTime = async (timeStamp, playerId) => {
  return playerGameStateRef.doc(playerId).update({
    startTurnTime: timeStamp,
  });
};

export const subscribeOtherPlayer = (setUnsubscribe) => {
  return async (dispatch, getState) => {
    const { playerId } = getState().user;
    const { currentPlayersId } = getState().room.table;

    if (currentPlayersId && playerId) {
      const indexOfPlayer = currentPlayersId.indexOf(playerId);

      if (indexOfPlayer !== -1) {
        const mapIds = [
          ...currentPlayersId.slice(indexOfPlayer + 1, currentPlayersId.length),
          ...currentPlayersId.slice(0, indexOfPlayer),
        ];
        mapIds.forEach((pid, idx) => {
          if (pid && pid !== "") {
            const unsubscribe = playerGameStateRef
              .doc(pid)
              .onSnapshot((snapshot) => {
                if (snapshot.exists) {
                  const playerState = snapshot.data();

                  const isGChaos = [
                    PLAYER_ROLE_G_CHAOS,
                    PLAYER_ROLE_MASTER_G_CHAOS,
                  ].includes(playerState.role);
                  dispatch({
                    type: OTHER_PLAYER_HAND_CARDS,
                    payload: {
                      [`cardPlayer${idx + 1}`]: {
                        isGChaos: isGChaos,
                        isMyTurn: false,
                        isMyTurnLocal: playerState.isMyTurn,
                        numberOfCalls: playerState.myState.numberOfCalls,
                        starCards: playerState.myState.starCards,
                        cards: playerState.myState.handCards.filter(
                          (card) => card !== null
                        ),
                        playerId: playerState.playerId,
                        turnStatus: playerState.turnStatus,
                        grandCross: playerState.grandCross,
                        isSorted: playerState.isSorted,
                        myIndex: (indexOfPlayer + idx + 1) % 4,
                      },
                    },
                  });
                }
              });
            setUnsubscribe(unsubscribe);
          }
        });
      }
    }
  };
};
export const clearPlayerGameState = async ({
  playerIds,
  gameId,
  tableId,
  roomId,
}) => {
  try {
    const data = {
      playerIds: playerIds,
      gameId: gameId,
      tableId: tableId,
      roomId: roomId,
    };
    const func = functions.httpsCallable(CLEAR_PLAYER_GAME_STATE);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const clearGameStateOfOnePlayer = async (playerId, roomId, tableId) => {
  try {
    return await functions.httpsCallable(CLEAR_GAME_STATE_OF_ONE_PLAYER)({
      playerId,
      roomId,
      tableId,
    });
  } catch (error) {
    console.log(error);
  }
};

export const clearPlayerGameStateEndGame = async ({
  playerIds,
  gameId,
  tableId,
  roomId,
  isOffline,
}) => {
  try {
    const data = {
      playerIds: playerIds,
      gameId: gameId,
      tableId: tableId,
      roomId: roomId,
      isOffline,
    };
    const func = functions.httpsCallable(CLEAR_ALL_PLAYER_GAME_STATE_END_GAME);
    await func(data);
  } catch (error) {
    console.log(error);
  }
};

export const completeIntroduce = (index) => {
  return async (dispatch, getState) => {
    const grandCross = [...getState().playerGameState.grandCross];
    grandCross[index] = { suit: "NewYorkTimes", value: "" };
    dispatch({
      type: PLAYER_INTRODUCE_COMPLETE,
      payload: { grandCross: grandCross },
    });
  };
};

export const changeStepId = (stepId) => (dispatch) =>
  dispatch({ type: PLAYER_CHANGE_STEP, payload: { stepId: stepId } });

export const updateApplyCall = (limit) => (dispatch) =>
  dispatch({ type: UPDATE_APPLY_CALLS, payload: { limitCalls: limit } });

export const playerChangeCommitFirst = (payload) => (dispatch) =>
  dispatch({
    type: PLAYER_CHANGE_COMMIT_FIRST,
    payload: {
      orderGrandCross: payload.orderGrandCross,
      endGamePlayerId: payload.endGamePlayerId,
      masterGrandCross: payload.masterGrandCross,
      stepId: payload.stepId,
    },
  });

export const saveNewestOtherPlayerState =
  ({ otherPlayersState, grandCross, myState }) =>
  (dispatch) =>
    dispatch({
      type: PLAYER_SAVE_NEW_OTHER_PLAYER,
      payload: {
        newestPlayerOtherState: otherPlayersState,
        newestGrandCross: grandCross,
        newestMyState: myState,
      },
    });

// PLAYER_UPDATE_NEW_OTHER_PLAYER
export const updateNewOtherPlayerState = () => (dispatch) =>
  dispatch({ type: PLAYER_UPDATE_NEW_OTHER_PLAYER });

export const saveGrandCrossRound12 = (grandCross) => {
  return (dispatch) => {
    dispatch({
      type: PLAYER_SAVE_GRAND_CROSS_ROUND12,
      payload: {
        grandCross: grandCross,
      },
    });
  };
};

//saveOtherPlayerStateRound13

export const saveOtherPlayerStateRound13 =
  ({ otherPlayersState, myState }) =>
  (dispatch) =>
    dispatch({
      type: PLAYER_SAVE_OTHER_PLAYER_ROUND13,
      payload: {
        otherPlayersState: otherPlayersState,
        myState: myState,
      },
    });

export const updateGrandCrossRound12 = () => {
  return (dispatch, getState) => {
    let myState = getState().playerGameState?.myState;
    myState["handCards"] = new Array(13).fill(null);
    dispatch({
      type: PLAYER_UPDATE_GRAND_CROSS_ROUND12,
      payload: {
        myState: myState,
        cardPlayer1Round12: [],
        cardPlayer2Round12: [],
        cardPlayer3Round12: [],
      },
    });
  };
};

export const updateOtherPlayerStateRound13 = () => (dispatch) =>
  dispatch({ type: PLAYER_UPDATE_OTHER_PLAYER_ROUND13 });

export const saveStateNewSuit =
  ({ grandCross, myState, otherPlayersState }) =>
  (dispatch) =>
    dispatch({
      type: PLAYER_SAVE_NEW_SUIT_STATE,
      payload: {
        grandCross: grandCross,
        myState: myState,
        otherPlayersState: otherPlayersState,
      },
    });

// PLAYER_UPDATE_NEW_SUIT_STATE

export const updateStateNewSuit = () => (dispatch) =>
  dispatch({
    type: PLAYER_UPDATE_NEW_SUIT_STATE_REQUEST,
  });

export const delayGChaosPlayCard = () => (dispatch) =>
  dispatch({
    type: DELAY_G_CHAOS_PLAY_CARD,
  });

export const updateStateNewSuitGrandCrossOnly = () => (dispatch) =>
  dispatch({
    type: PLAYER_UPDATE_NEW_SUIT_STATE_GRAND_CROSS_ONLY,
  });
export const updateMasterGrandCrossOnDisplay = () => (dispatch) =>
  dispatch({ type: PLAYER_UPDATE_MASTER_GRAND_CROSS_ON_DISPLAY });

export const saveHandCardsRound12 = () => {
  return (dispatch, getState) => {
    const cardPlayer1 = getState().playerGameState?.cardPlayer1?.cards?.filter(
      (c) => c !== null
    );
    const cardPlayer2 = getState().playerGameState?.cardPlayer2?.cards?.filter(
      (c) => c !== null
    );
    const cardPlayer3 = getState().playerGameState?.cardPlayer3?.cards?.filter(
      (c) => c !== null
    );

    const card1 = cardPlayer1?.length === 1 ? [...cardPlayer1] : null;
    const card2 = cardPlayer2?.length === 1 ? [...cardPlayer2] : null;
    const card3 = cardPlayer3?.length === 1 ? [...cardPlayer3] : null;

    if (!card1 && !card2 && !card3) return;

    dispatch({
      type: HAND_CARDS_ROUND_12,
      payload: {
        cardPlayer1Round12: card1,
        cardPlayer2Round12: card2,
        cardPlayer3Round12: card3,
      },
    });
  };
};

export const getNicknamePlayersSelectOrderCards = () => {
  return (dispatch, getState) => {
    const joinedPlayers = getState().room?.joinedPlayers;
    const orderCards = getState().playerGameState?.orderCards;
    const orderCardsNotNull = [...orderCards.filter((item) => item !== null)];

    const playersIndex = orderCardsNotNull[0]?.playersIndex;
    if (!playersIndex) return;
    const nicknames = playersIndex.map((index) => {
      if (joinedPlayers[index]) {
        return joinedPlayers[index].nickName;
      }
      return "";
    });
    const uniqueSet = new Set(nicknames);
    const backToArr = [...uniqueSet];
    dispatch({
      type: NICKNAMES_PLAYERS_SELECT_ORDER_CARDS,
      payload: {
        nicknames: backToArr.join(", "),
      },
    });
  };
};

export const removeACard = (removeCard, handleCards) => {
  const _handCards = [...handleCards];
  const indexOfRemoveCard = handleCards.findIndex(
    (card) =>
      null !== card &&
      card.suit === removeCard.suit &&
      card.value === removeCard.value
  );
  if (-1 === indexOfRemoveCard) {
    return;
  }
  _handCards[indexOfRemoveCard] = null;
  return _handCards;
};

// Dispatch card before send to server
export const playerPlayCard = (card, index) => {
  return async (dispatch, getState) => {
    const grandCross = [...getState().playerGameState.grandCross];
    let myState = { ...getState().playerGameState.myState };
    const otherPlayersState = [...getState().playerGameState.otherPlayersState];

    grandCross[index] = card;
    const _handCards = removeACard(card, myState.handCards);
    myState = { ...myState, handCards: _handCards };
    dispatch({
      type: PLAY_A_CARD_GAME_STATE,
      payload: { grandCross: grandCross, myState: myState },
    });
    dispatch({
      type: PLAYER_SAVE_NEW_SUIT_STATE,
      payload: {
        grandCross: grandCross,
        myState: myState,
        otherPlayersState,
      },
    });
    dispatch({
      type: DOUBLE_CLICK_HAND_CARD,
      payload: true,
    });
  };
};

export const playerSelectMC = (cardIndex, nickName, myIndex) => {
  return async (dispatch, getState) => {
    const grandCross = [...getState().playerGameState.grandCross];
    const _grandCross = grandCross.map((gcc) => {
      if (cardIndex === gcc.playerIdx) {
        gcc.nickName = nickName;
        gcc.playerIdx = myIndex;
      }
      return gcc;
    });
    dispatch({
      type: UPDATE_NICKNAME_PLAYER_SELECT_MASTER_CARD,
      payload: {
        grandCross: _grandCross,
      },
    });
  };
};

export const mapDisplayCalls = ({
  joinedPlayers,
  numberOfCalls,
  otherPlayersState,
}) => {
  let newCalls = [...otherPlayersState];
  newCalls = newCalls.map((item) => {
    if (item === null) {
      item = { numberOfCalls };
    }
    return item["numberOfCalls"];
  });
  newCalls = [newCalls[3]].concat(newCalls.splice(0, 3));

  const _joinedPlayers = [...joinedPlayers];
  const newPlayerCalls = [_joinedPlayers[3]].concat(
    _joinedPlayers.splice(0, 3)
  );
  newPlayerCalls.map((item, index) => {
    if (item !== null) {
      item["numberOfCalls"] = newCalls[index];
    }
    return item;
  });
  return newPlayerCalls;
};

// NOTE: Why???
export const getTurnOfPlayer = (otherPlayersState, isMyTurn) => {
  let counterTurnPlayer = 0;
  const _otherPlayersState = [...otherPlayersState];
  for (const pl of _otherPlayersState) {
    if (pl && pl.isYourTurn === false) ++counterTurnPlayer;
  }
  return 3 === counterTurnPlayer && isMyTurn;
};

export const getTitleSelectOrderCards = (
  otherPlayersState,
  joinedPlayers,
  myIndex,
  isMyTurn
) => {
  let nickname = "";

  if (isMyTurn) {
    nickname = joinedPlayers[myIndex]?.nickName;
    return nickname;
  }
  let indexOfPlayer = otherPlayersState.findIndex((pl) => pl && pl.isYourTurn);
  if (-1 === indexOfPlayer) return;
  nickname = joinedPlayers[indexOfPlayer]?.nickName;
  return nickname;
};

export const checkPlayerIsSelected = (nickname, grandCross, isMyTurn, card) => {
  if (!isMyTurn || -1 < card.playerIdx) return true; // can not select card

  // selected card
  let isSelected = false;
  for (const item of grandCross) {
    if (item && item.nickName && item.nickName === nickname) {
      isSelected = true;
    }
  }
  return isSelected;
};

export const clearMyGameStateConfirmSomeOneLeave = async (playerId) => {
  const func = functions.httpsCallable(
    CLEAR_MY_PLAYER_GAME_STATE_CONFIRM_SOMEONE_LEAVE
  );
  await func({ playerId });
};

export const plusPlayerReady = async (roomId, tableId, playerId) => {
  await functions.httpsCallable(PLUS_PLAYER_READY)({
    roomId,
    tableId,
    playerId,
  });
};

export const getNickNameTurnOfPlayer = (
  otherPlayersState,
  joinedPlayers,
  myIndex,
  isSelectMasterCard
) => {
  const isProcess =
    otherPlayersState &&
    otherPlayersState.length > 0 &&
    joinedPlayers &&
    joinedPlayers.length > 0 &&
    myIndex !== -1;
  if (!isProcess) return;
  let index = -1;
  const _otherPlayersState = [...otherPlayersState];
  if (isSelectMasterCard) {
    index = _otherPlayersState.findIndex((pl) => pl && pl.isMyTurn);
  } else {
    index = _otherPlayersState.findIndex((pl) => pl && pl.isYourTurn);
  }
  let playerTurn = "";
  if (-1 === index) {
    playerTurn = joinedPlayers[myIndex];
    return playerTurn;
  }
  playerTurn = joinedPlayers[index];
  return playerTurn;
};

export const removeGChaosSelectOrderFirstCard = () => {
  return async (dispatch, getState) => {
    const grandCross = [...getState().playerGameState.grandCross];
    const newGrandCross = [...grandCross].map((e) => {
      if (e?.nickName && e.nickName === G_CHAOS) {
        e.nickName = "";
      }
      return e;
    });
    dispatch({
      type: REMOVE_GCHAOS_SELECT_FIRST_CARD,
      payload: { grandCross: newGrandCross, newGrandCross: grandCross },
    });
  };
};
export const gchaosSelectOrderCard = () => {
  return async (dispatch, getState) => {
    const grandCross = [...getState().playerGameState.newSuitGrandCross];
    dispatch({
      type: GCHAOS_SELECT_ORDER_CARD,
      payload: { grandCross },
    });
  };
};

/**
 * todo: get first card
 * todo: get stepId by first card {suit, value}
 * @param {[card]} grandCross
 * @returns stepId
 */
export const getStepIdNewSuitOfRound = (grandCross, suit) => {
  const index = grandCross?.findIndex((e) => null !== e);
  const firstCard = grandCross[index];
  const steps = {
    [CARD_SUIT.G]: CARD_SUIT_WHATEVER,
    [CARD_SUIT_SPADES]: SCREEN_NARRATION.PC_STEP3,
    [CARD_SUIT_HEARTS]: SCREEN_NARRATION.PC_STEP4,
    [CARD_SUIT_DIAMONDS]: SCREEN_NARRATION.PC_STEP5,
    [CARD_SUIT_CLUBS]: SCREEN_NARRATION.PC_STEP6,
  };
  let stepId = steps[firstCard.suit];

  if (stepId === CARD_SUIT_WHATEVER) {
    if (index === 3) {
      stepId =
        firstCard.value === 0
          ? SCREEN_NARRATION.PC_STEP1
          : SCREEN_NARRATION.PC_STEP2;
      return stepId;
    }
    return steps[suit];
  }
  return stepId;
};

export const updateAllTurn = () => (dispatch) => {
  dispatch({
    type: UPDATE_IS_MY_TURN,
  });
  dispatch({ type: UPDATE_OTHER_IS_MY_TURN });
};

export const updateMasterGrandCross = () => async (dispatch, getState) => {
  const masterGrandCross = getState().playerGameState?.grandCross;
  const isUpdateMasterGrandCross =
    masterGrandCross && masterGrandCross.length > 4;
  if (!isUpdateMasterGrandCross) return;
  dispatch({
    type: UPDATE_MASTER_GRAND_CROSS,
    payload: {
      masterGrandCross: masterGrandCross,
    },
  });
};

/**
 * todo: get nickname of player select order cards in joinedPlayers
 * @param {*} orderCards
 * @param {*} joinedPlayers
 * @returns
 */
export const getNicknameFirstPlayerSelectOrderCard = (
  popupId,
  orderCards = [],
  joinedPlayers = []
) => {
  try {
    const isGetNickName = orderCards.length > 0 && joinedPlayers.length > 0;
    if (!isGetNickName) return;

    const _orderCards = orderCards.filter((e) => e !== null);
    const cardCount = _orderCards.length;
    let nicknameCount = 0;
    for (const oc of _orderCards) {
      if (oc.nickName !== "") ++nicknameCount;
    }

    const firstItem = _orderCards[0];
    let nickName = "";
    if (popupId === POPUPS.G_CHAOS_SELECT_ORDER_CARD) {
      nickName = joinedPlayers[3].nickName;
      return nickName;
    }
    if (cardCount === nicknameCount) return nickName;

    const playerIndex = firstItem.playersIndex[nicknameCount];
    nickName = joinedPlayers[playerIndex].nickName;
    return nickName;
  } catch (error) {
    console.log("getNicknameFirstPlayerSelectOrderCard: ", error);
  }
};

/**
 * todo: check the right turn or not
 * todo: check the player selected or not
 * @param {*} myIndex
 * @param {*} nickName
 * @param {*} orderCards
 * @param {*} isMyTurn
 * @returns
 */
export const checkConditionSelectOrderCard = ({
  myIndex,
  nickName,
  orderCards,
  isMyTurn,
}) => {
  let countClick = 0;
  let canClick = false;
  const isExistPlayerId = orderCards[myIndex]?.playersIndex.includes(myIndex);
  orderCards.forEach((card) => {
    if (card && card?.nickName === nickName) ++countClick;
  });

  if (countClick === 0) canClick = true;
  return isExistPlayerId && isMyTurn && canClick;
};

/**
 * todo: update order cards in redux
 * todo: prevent player select card 2nd time
 * todo: call api select order cards
 * @param {*} card
 * @param {*} cardIndex
 * @returns
 */

export const selectOrderCard = (card, cardIndex) => {
  return async (dispatch, getState) => {
    const confirmType = getState().playerGameState?.nextConfirmType;
    const actionPlayer = getState().playerGameState?.nextActionPlayer;
    const nickName = getState().user?.nickName;
    const gameId = getState().playerGameState?.gameId;
    const myIndex = getState().playerGameState?.myIndex;
    const orderCards = getState().playerGameState?.orderCards;
    const playerIds = getState().room?.table.currentPlayersId;

    const isCanCallAPI =
      confirmType &&
      actionPlayer &&
      nickName &&
      gameId &&
      myIndex > -1 &&
      orderCards?.length > 0 &&
      playerIds?.length > 0;

    if (!isCanCallAPI) return;

    const filterOrderCard = orderCards.filter(
      (item) => item.nickName === nickName
    );
    if (filterOrderCard.length === 1) return;

    const orderCard = { ...card };
    const newOrderCards = [...orderCards];
    orderCard["nickName"] = nickName;
    orderCard["playerIndex"] = myIndex;
    newOrderCards[cardIndex] = orderCard;

    dispatch({
      type: UPDATE_ORDER_CARDS,
      payload: {
        isMyTurn: false,
        orderCards: newOrderCards,
      },
    });

    const orderCardData = {
      orderCard: orderCard,
      gameId: gameId,
      playerIds: playerIds,
      confirmType: confirmType,
      actionPlayer: actionPlayer,
    };
    fnUpdateOrderCardForPlayer(orderCardData);
  };
};

/**
 * todo: find current index
 * todo: find next index
 * todo: update other player state
 * @param {*} orderCards
 * @param {*} otherPlayersState
 * @param {*} myIndex
 * @returns
 */

export const findNextIndexSelectOrderCards = (
  orderCards = [],
  otherPlayersState = [],
  myIndex = -1
) => {
  try {
    if (
      orderCards.length === 0 ||
      otherPlayersState.length === 0 ||
      myIndex < 0
    )
      return;
    const _orderCards = orderCards.filter((e) => e !== null);
    const nicknameCount = _orderCards.filter((e) => e.nickName !== "").length;
    const cardCount = _orderCards.length;
    if (cardCount === nicknameCount) return otherPlayersState;
    const playerIndexes = _orderCards[0].playersIndex;
    const currentIndex = playerIndexes.indexOf(myIndex);
    const newOtherPlayersState = [...otherPlayersState];
    const nextIndex = playerIndexes[currentIndex + 1];
    newOtherPlayersState[nextIndex].isYourTurn = true;
    return newOtherPlayersState;
  } catch (error) {
    console.log("findNextIndexSelectOrderCards: ", error);
  }
};

export const getTurnOfGChaos = (otherPlayersState = [], myIndex = -1) => {
  try {
    if (otherPlayersState.length === 0 || myIndex === -1) return;
    const _otherPlayerState = [...otherPlayersState].map((item) => {
      return {
        ...item,
        isYourTurn: false,
      };
    });
    _otherPlayerState[3].isYourTurn = true;
    _otherPlayerState[myIndex] = null;
    return _otherPlayerState;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getTurnOfPlayerOrderCard = (
  actionPlayer = "",
  myIndex = -1,
  playerIds = [],
  otherPlayersState = []
) => {
  try {
    const isGetTurn =
      actionPlayer !== "" &&
      myIndex > -1 &&
      playerIds.length === 4 &&
      otherPlayersState.length === 4;
    if (!isGetTurn) return;

    const newIndexTurn = playerIds.indexOf(actionPlayer);
    if (newIndexTurn === -1) return;
    const otherState = [...otherPlayersState].map((item, index) => {
      if (index !== myIndex) {
        item.isYourTurn = false;
        return item;
      }
      return null;
    });
    if (newIndexTurn === myIndex) return { otherState, newIndexTurn };
    otherState[newIndexTurn].isYourTurn = true;
    return { otherState, newIndexTurn };
  } catch (error) {
    console.error(error);
  }
};

export const getNicknamePlayerSelectOrderCard = (
  t,
  message,
  players = [],
  actionPlayer = "",
  isGChaosComputer = false
) => {
  try {
    const isGetNickname =
      players.length > 0 && actionPlayer !== "" && message !== "";
    if (!isGetNickname) return;
    console.log(players, actionPlayer);
    const playerFilter = players.filter(
      (pl) => pl && pl.playerId === actionPlayer
    );
    let nickName = "";
    if (playerFilter.length === 1) {
      nickName = playerFilter[0].nickName;
    }
    let text = t(`narrations.${message}`, { name: nickName });
    if (isGChaosComputer === true && nickName === G_CHAOS) {
      text = clearGChaosComputerSanNickName(text);
    }
    return text;
  } catch (error) {
    console.log(error);
    return error;
  }
};

export const getNickNameMaster = (
  t,
  message,
  players = [],
  master = "",
  isGChaosComputer = false
) => {
  try {
    const isGetNickname =
      players.length === 4 && message === "narration17" && master !== "";
    if (!isGetNickname) return;
    const playerFilter = players.filter((pl) => pl?.playerId === master);
    if (playerFilter?.length === 0) return;
    const nickName = playerFilter[0].nickName;
    let text = t(`narrations.${message}`, { name: nickName });
    if (isGChaosComputer === true && nickName === G_CHAOS) {
      text = clearGChaosComputerSanNickName(text);
    }
    return text;
  } catch (error) {
    console.error("getNickNameMaster: ", error);
  }
};

export const clearGameStateWhenOutRoom = (playerId) => {
  try {
    if (playerId) {
      return playerGameStateRef.doc(playerId).delete();
    }
  } catch (error) {
    console.error("clearGameStateWhenOutRoom: ", error);
  }
};
