import { makeStyles } from "@material-ui/core/styles";
import styled from "styled-components";
import { COLOR_RED_BROWN } from "../../../../store/constant";
const useStyles = makeStyles((theme) => ({
  forgotLink: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    color: "white",
  },
  loginBtn: {
    height: 50,
    width: 200,
    border: "2px solid black",
    margin: "0 !important",
    backgroundColor: COLOR_RED_BROWN,
    "& span": {
      marginLeft: 7,
    },
  },
  link: {
    textDecoration: "none",
    marginLeft: theme.spacing(1),
    color: "#dd501f",
    "&:hover": {
      color: "yellow",
    },
  },
  input: {
    width: "350px",
    height: "51px",
    color: "#541513",
    transition: "background-color 5000s ease-in-out 0s",
    fontWeight: 600,
    backgroundColor: "#e9e1d4",
    padding: "18px 14px",
    boxSizing: "border-box",
    outline: "none",

    "&:disabled": {
      color: "#c1c1c1",
    },

    "&::placeHolder": {
      color: "#c1c1c1",
      fontWeight: 600,
    },
  },
  labelInput: {
    minWidth: "140px",
    textAlign: "left",
    fontWeight: "bold !important",
  },
}));

export const BoxWrapper = styled.div`
  position: relative;
  width: ${(props) => props.width}px;
  text-align: center;
  background-color: rgba(176, 195, 199, 0.7);
  border-radius: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
`;
export default useStyles;
