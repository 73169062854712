import {
  Avatar,
  Box,
  Button,
  Button as MuiButton,
  // Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { inviteFriendJoinRoom } from "../../store/actions/tableActions";
import useStyles from "./styles";
import RounedDialog from "../../pages/GameScreen/components/Dialog/RounedDialog";
import { COLOR_YELLOW } from "../../pages/GameScreen/components/constants";
import PlayerRepo from "../../store/repositories/player";
const theme = createTheme({
  palette: {
    primary: {
      main: COLOR_YELLOW,
    },
  },
});

const DialogInviteFriends = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [friends, setFriends] = useState([]);
  const user = useSelector((state) => state.user);
  const room = useSelector((state) => state.room);
  const table = useSelector((state) => state.room.table);
  const userFriends = useSelector((state) => state.user.friends);
  const userCurrentRoomJoinedId = useSelector(
    (state) => state.user.currentRoomJoinedId
  );
  const currentPlayersId = useSelector(
    (state) => state.room.table.currentPlayersId
  );
  const requestedJoinInvitations = useSelector(
    (state) => state.room.requestedJoinInvitations
  );

  useEffect(() => {
    if (
      userFriends &&
      userFriends.length > 0 &&
      currentPlayersId &&
      currentPlayersId.length > 0 &&
      requestedJoinInvitations &&
      userCurrentRoomJoinedId
    ) {
      const checkInviteFriends = async () => {
        const friendsList = userFriends.map(async (friend) => {
          const playerCurrentRoomJoinedId = await getCurrentRoomJoinedId(
            friend.friendId
          );
          return {
            ...friend,
            invited: requestedJoinInvitations.includes(friend.friendId),
            isShowButtonPlaying: playerCurrentRoomJoinedId ? true : false,
          };
        });
        const _friendsList = await Promise.all(friendsList);
        setFriends(_friendsList);
      };
      checkInviteFriends();
    }
  }, [
    open,
    currentPlayersId,
    requestedJoinInvitations,
    userFriends,
    userCurrentRoomJoinedId,
  ]);

  const handleClickInvite = (friend) => {
    const friendsUpdate = friends.map((friendItem) => {
      if (friend.friendId === friendItem.friendId) {
        friendItem.invited = true;
      }
      return friendItem;
    });
    setFriends(friendsUpdate);
    inviteFriendJoinRoom(friend, user, room, table);
  };

  const getCurrentRoomJoinedId = async (_playerId = "") => {
    if (_playerId) {
      const player = await PlayerRepo.read(_playerId);
      return player?.currentRoomJoinedId;
    }
    return "";
  };

  const classes = useStyles();
  return (
    <ThemeProvider theme={theme}>
      <RounedDialog
        isOpen={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{ padding: "1em 2em", textAlign: "center" }}
        >
          {t("game.inviteFriends")}
        </DialogTitle>
        <DialogContent
          className={classes.dialogContent}
          style={{ padding: "1em 2em" }}
        >
          <TextField
            autoFocus
            label={t("game.searchByNickName")}
            type="text"
            className={classes.search}
            color="primary"
          />
          <Box className={classes.listFriends} padding={"1em 2em"}>
            {friends &&
              friends.length > 0 &&
              friends.map((friend) => {
                return (
                  <Box className={classes.friendItem} key={friend.friendId}>
                    <Avatar src={friend.avatar} />
                    <Typography className={classes.nickname}>
                      {friend.nickName}
                    </Typography>
                    {!friend.isShowButtonPlaying && (
                      <Button
                        variant="contained"
                        className={classes.buttonInvite}
                        onClick={() => handleClickInvite(friend)}
                        disabled={friend.invited}
                      >
                        {friend.invited ? t("game.invited") : t("game.invite")}
                      </Button>
                    )}
                    {friend.isShowButtonPlaying && (
                      <Button
                        variant="contained"
                        className={classes.buttonInvite}
                        disabled={friend.isShowButtonPlaying}
                      >
                        {t("game.playingGame")}
                      </Button>
                    )}
                  </Box>
                );
              })}
          </Box>
        </DialogContent>
        <DialogActions style={{ padding: "1em 2em" }}>
          <MuiButton onClick={onClose} className="button-style-1__outline">
            {t("tables.close")}
          </MuiButton>
        </DialogActions>
      </RounedDialog>
    </ThemeProvider>
  );
};

DialogInviteFriends.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
};

export default DialogInviteFriends;
