import { Box, DialogActions, DialogContent } from "@material-ui/core";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { GiPartyPopper } from "react-icons/gi";
import { IoCloseCircleOutline } from "react-icons/io5";
import { GAME_STATUS } from "../../../store/constant";
import useStyles from "./styles";
import BaseDialog from "../../../pages/GameScreen/components/Dialog/BaseDialog";

const ShowInfo = (props) => {
  const { isOpen, onClose, value, gameStatus } = props;
  const [message, setMessage] = useState("");
  const { t } = useTranslation();
  const classes = useStyles();
  const handleOnClose = () => {
    if (onClose) onClose();
  };

  useEffect(() => {
    if (value === "1") {
      setMessage(t("endGame.lose"));
      return;
    }
    setMessage(value);
  }, [value, t]);
  return (
    <BaseDialog isOpen={isOpen} onClose={handleOnClose}>
      <DialogContent className={classes.mainContent}>
        <Box>
          <Box display="flex" justifyContent="center" flexDirection="column">
            <Box display="flex" justifyContent="center" mb={1}>
              {gameStatus === "lose" && (
                <IoCloseCircleOutline size="60" color="#ff5722" />
              )}
              {gameStatus === "win" && (
                <GiPartyPopper size="70" color="#ff9100" />
              )}
              {gameStatus === GAME_STATUS.END && (
                <GiPartyPopper size="70" color="#ff9100" />
              )}
            </Box>
            <Box display="flex" justifyContent="center">
              <Box
                fontWeight="fontWeightBold"
                textAlign="center"
                dangerouslySetInnerHTML={{ __html: message }}
              ></Box>
            </Box>
          </Box>
        </Box>
        <DialogActions className={classes.actionsContent}></DialogActions>
      </DialogContent>
    </BaseDialog>
  );
};
ShowInfo.propTypes = {
  isOpen: PropTypes.bool,
  gameStatus: PropTypes.string,
  onClose: PropTypes.func,
  value: PropTypes.string,
};
export default ShowInfo;
