import { Typography, Box } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../../components/Button";
import firebase from "../../../../services/firebase";
import { registerPhoneValidateInput } from "../../../../utils/validation";
import useStyles, { BoxWrapper } from "./styles";
import { sendOtpCode } from "../../../../store/actions/authActions";
import { SET_LOADING_GLOBAL } from "../../../../store/types";
import { useDispatch } from "react-redux";
import { PHONE_CODE } from "../../../../constants";
import { checkNumberPhoneExits } from "../../../../store/actions/playerActions";

firebase.auth().languageCode = "ja";

const Space = styled.div`
  width: 100%;
  height: ${(props) => props.space}px;
`;

const RegisterPhone = ({ values, handleChangePage, handleChangeValues }) => {
  const [errors, setErrors] = useState({});
  const [errorMessage, setErrorMessage] = useState("");
  const { t } = useTranslation();
  const boxWrapperWidth = 650;
  const classes = useStyles();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(true);

  const configureCaptcha = () => {
    window.recaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      "recaptcha-container",
      {
        size: "invisible",
        defaultCountry: "JP",
      }
    );
    window.recaptchaVerifier.render();
  };

  const handleLoading = (_value) => {
    dispatch({
      type: SET_LOADING_GLOBAL,
      payload: _value,
    });
  };

  const onSignInSubmit = async () => {
    handleLoading(true);
    setErrors({});
    const err = registerPhoneValidateInput(values);
    if (err) {
      setErrors((prevErr) => ({ ...prevErr, ...err }));
      handleLoading(false);
      return;
    }

    // TODO: check number phone exits
    const isExitNumberPhone = await checkNumberPhoneExits(values.numberPhone);

    if (isExitNumberPhone?.data) {
      errors.numberPhone = "validate.numberPhoneRegistered";
      setErrors(errors);
      handleLoading(false);
      return;
    }

    if (err === "") {
      configureCaptcha();
      const phoneNumber = PHONE_CODE + values.numberPhone;
      const appVerifier = window.recaptchaVerifier;
      const otpErr = await sendOtpCode(phoneNumber, appVerifier);
      if (otpErr === "") {
        handleChangePage(1);
      } else {
        setErrorMessage(otpErr);
      }
    }
    handleLoading(false);
  };

  useEffect(() => {
    const clearError = setTimeout(() => {
      setErrorMessage("");
    }, 3000);

    return () => clearTimeout(clearError);
  });

  const handleCheckConfirm = (e) => {
    setIsDisabled(!e.target.checked);
  };

  return (
    <BoxWrapper width={boxWrapperWidth}>
      <Space space={40} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography className={classes.labelInput}>
          {t("common.numberPhone")}
        </Typography>
        <input
          type="text"
          name="numberPhone"
          placeholder={t("common.phone")}
          value={values.numberPhone}
          className={classes.input}
          onChange={handleChangeValues}
        />
      </Stack>
      <Space space={20} />
      {errors?.numberPhone && (
        <span style={{ fontSize: 12, color: "red" }}>
          {t(errors?.numberPhone)}
        </span>
      )}
      <Space space={10} />
      <Stack direction="row" spacing={2} alignItems="center">
        <Typography className={classes.labelInput}>
          {t("common.email")}
        </Typography>
        <input
          type="text"
          name="email"
          placeholder={t("common.email")}
          value={values.email}
          className={classes.input}
          onChange={handleChangeValues}
        />
      </Stack>
      <Space space={20} />
      {errors?.email && (
        <span style={{ fontSize: 12, color: "red" }}>{t(errors?.email)}</span>
      )}

      {errorMessage && (
        <span style={{ fontSize: 12, color: "red" }}>{t(errorMessage)}</span>
      )}
      <Space space={20} />
      <Box style={{ border: "2px solid #0167c8", padding: 10 }}>
        <Typography style={{ fontWeight: "bold" }}>
          個人情報の取扱いについて
        </Typography>
        <Space space={20} />
        <Typography style={{ fontSize: 14 }}>
          入力いただいた内容及び個人情報は、お問い合わせ内容への対応のみに利用し、
        </Typography>
        <Typography style={{ fontSize: 14 }}>
          弊社にて適切に管理いたします。
        </Typography>
        <Typography style={{ fontSize: 14 }}>
          入力していただいた個人情報をご本人様の同意なしに提供することはありません。
        </Typography>
        <Typography style={{ fontSize: 14 }}>
          <a className={classes.link} href="/termofuse" target="_blank">
            利用規約
          </a>
          、
          <a className={classes.link} href="/privacy-policy" target="_blank">
            プライバシーポリシー
          </a>
          のご同意のうえ、入力内容の確認にお進みください。
        </Typography>
        <Space space={10} />
        <Box>
          <input
            type="checkbox"
            name="confirm"
            id="confirm"
            onChange={handleCheckConfirm}
          />
          <label htmlFor="confirm" style={{ marginLeft: 5 }}>
            同意する
          </label>
        </Box>
      </Box>
      <Space space={20} />
      <Button
        type="submit"
        variant="outlined"
        text={t("register.certificationRegistration")}
        className={classes.loginBtn}
        onClick={onSignInSubmit}
        disabled={isDisabled}
      ></Button>
      <Space space={30} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("register.alreadyAccount")}
        <Link className={classes.link} to="/login">
          {t("login.login")}
        </Link>
      </Typography>
      <Space space={10} />
      <Typography
        variant="body1"
        style={{ color: "#541f1c", fontSize: 16, fontWeight: "600" }}
      >
        {t("login.toContact")}
        <Link className={classes.link} to="/contact">
          {t("login.clickHere")}
        </Link>
      </Typography>

      <Space space={20} />
      <div id="recaptcha-container"></div>
    </BoxWrapper>
  );
};

RegisterPhone.propTypes = {
  values: PropTypes.object,
  handleChangeValues: PropTypes.func,
  handleChangePage: PropTypes.func,
};

export default RegisterPhone;
