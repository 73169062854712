import styled from "styled-components";

export const StarCardsContainer = styled.div`
  width: 100%;
  bottom: 0;
  flex: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const DotStyle = styled.img`
  position: absolute;
  width: 100%;
  margin: auto;
  transform: rotate(180deg);
`;
