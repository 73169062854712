import { SET_PLAY_TIME } from "../types";

const initlaState = {
  playTimeInfo: {},
};
const playTimeReducer = (state = initlaState, action) => {
  switch (action.type) {
    case SET_PLAY_TIME:
      return {
        ...state,
        playTimeInfo: action.payload,
      };

    default:
      return { ...state };
  }
};
export default playTimeReducer;
