import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  ADD_INSTRUCTOR_CONTENT,
  REMOVE_INSTRUCTOR_MESSAGE,
} from "../../../../store/types";
import MessDialog from "../NewDialog/MessDialog";
import { sleep } from "../../../../utils/common";

const InstructorNotify = () => {
  const instructorMes = useSelector((state) => state.instructorNotice.message);
  const instructorMessCode = useSelector(
    (state) => state.instructorNotice.messageCode
  );
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const { t } = useTranslation();

  useEffect(() => {
    if (!instructorMes) return;
    dispatch({
      type: ADD_INSTRUCTOR_CONTENT,
      payload: { messContent: instructorMes },
    });
    dispatch({ type: REMOVE_INSTRUCTOR_MESSAGE });
  }, [instructorMes, dispatch]);

  useEffect(() => {
    if (!instructorMessCode || !instructorMessCode.messageContent) return;
    const { isGChaos, messageContent, nickName, suit } = instructorMessCode;
    let typePlayer = isGChaos ? "instructorGChaos" : "instructor";
    let messContent = "";
    if (messageContent === "NoHaveSuit") {
      messContent = t(`instructor._${suit}NoHaveSuit`);
    } else if (
      messageContent === "notifyJoinRoom" ||
      messageContent === "notifyLeaveRoom"
    ) {
      //Who is leave or join room
      if (nickName === "G-Chaos") {
        messContent = t(`${typePlayer}.${messageContent}`, {
          name: nickName,
          alias: "",
        });
      } else {
        messContent = t(`${typePlayer}.${messageContent}`, {
          name: nickName,
          alias: "さん",
        });
      }
    } else {
      messContent = t(`${typePlayer}.${messageContent}`, {
        name: nickName,
      });
    }
    console.log("notify_message: ", messContent);

    dispatch({ type: ADD_INSTRUCTOR_CONTENT, payload: { messContent } });
    const showMessage = async () => {
      setMessage(messContent);
      await sleep(2000);
      setMessage("");
    };
    showMessage();
  }, [instructorMessCode, dispatch, t]);

  return <MessDialog isOpen={message !== ""} mainText={message} />;
};

InstructorNotify.propTypes = {
  message: PropTypes.string,
};
export default InstructorNotify;
