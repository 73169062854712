import styled from "styled-components";
import { COLOR_DARK_GREEN, COLOR_BLACK } from "../../../../../../constants";
import iconArrowDown from "../../../../../../assets/icons/iconArrowDown.png";
import iconArrowUp from "../../../../../../assets/icons/iconArrowUp.png";
import { ListFriendGroup } from "../ListFriendOfGroup/styles";

export const ChatBoxGroupWrapper = styled.div`
  flex-grow: 1;
  height: 100%;
`;

export const ChatContent = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  height: 100%;
  position: relative;
`;

export const ChatBoxInputSection = styled.div`
  background-color: ${COLOR_DARK_GREEN};
  height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 2.6}em` : `${0.5 * 2.6}em`};
  width: 100%;
  padding: 1px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  box-sizing: border-box;
`;

export const ChatBoxCheckbox = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 2.5}em` : `${0.5 * 2.5}em`};
  padding: ${(props) =>
    props.minscale > 0.5 ? `0 ${props.minscale * 10}px` : `0 ${0.5 * 10}px`};
`;

export const StyledTextInput = styled.input`
  border: none;
  height: 100%;
  width: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 16}em` : `${0.5 * 16}em`};
  outline: none;
  padding-left: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 1}em` : `${0.5 * 1}em`};
  box-sizing: border-box;
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 16}px` : `${0.5 * 16}px`};
  &:focus {
    outline: none;
  }
  flex-grow: 1;
`;

export const SendButton = styled.button`
  appearance: none;
  border: none;
  color: ${COLOR_BLACK};
  background-color: ${COLOR_DARK_GREEN};
  padding: ${(props) =>
    props.minscale > 0.5 ? `0 ${props.minscale * 1}em` : `0 ${0.5 * 1}em`};
  margin: 0 auto;
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 16}px` : `${0.5 * 16}px`};
  display: flex;
  align-items: center;
  justify-content: center;
  &:focus {
    border: none;
  }
  &:hover {
    cursor: pointer;
  }
`;

export const MessageContent = styled.div`
  flex-grow: 1;
  overflow-y: scroll;
  overflow-x: hidden;
  height: 100%;

  ::-webkit-scrollbar {
    width: ${(props) =>
      props.minscale > 0.5 ? `${props.minscale * 26}px` : `${0.5 * 26}px`};
  }

  ::-webkit-scrollbar-track {
    background-color: #412c1d;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #917e70;
    border-radius: 5px;
    border: 1px solid #412c1d;
    background-clip: content-box;
  }

  ::-webkit-scrollbar-button:vertical:decrement:start {
    display: block;
    width: ${(props) =>
      props.minscale > 0.5 ? `${props.minscale * 26}px` : `${0.5 * 26}px`};
    background-image: url(${iconArrowUp});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    cursor: pointer !important;
  }

  ::-webkit-scrollbar-button:vertical:decrement:end {
    display: block;
    background-image: url(${iconArrowDown});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: ${(props) =>
      props.minscale > 0.5 ? `${props.minscale * 26}px` : `${0.5 * 26}px`};
    cursor: pointer;
  }
`;

export const ChatGroupHeader = styled.div`
  padding: 0.5rem 1rem;
  padding: ${(props) =>
    props.minscale > 0.5
      ? `${props.minscale * 0.5}rem ${props.minscale * 1}rem`
      : `${0.5 * 0.5}rem ${0.5 * 1}rem`};
  position: sticky;
  top: 0;
  z-index: 10;
  left: 0;
  right: 0;
  background-color: white;
`;

export const ChatGroupTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 5}px` : `${0.5 * 5}px`};
`;

export const ChatGroupNamePlayer = styled.div`
  flex-grow: 1;
  position: relative;
  cursor: pointer;

  &:hover {
    ${ListFriendGroup} {
      opacity: 1;
      visibility: visible;
      transform: scale(1);
    }
  }
`;

export const StyleButton = styled.button`
  width: max-content;
  box-sizing: border-box;
  border-radius: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 10}px` : `${0.5 * 10}px`};
  background-color: #442211;
  padding: ${(props) => (props.minscale > 0.5 ? `3px 15px` : `1px 5px`)};
  color: #fff;
  font-size: ${(props) =>
    props.minscale > 0.5 ? `${props.minscale * 12}px` : `${0.5 * 12}px`};
  font-weight: 300;
  border: none;
  cursor: pointer;
`;

export const FirstNextDay = styled.div`
  text-align: center;
  font-size: 10px;
  color: #c1c1c1;
`;
