import { Box } from "@material-ui/core";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Fragment, useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import styled from "styled-components";
import ListPlayer from "../components/ListPlayer";
import OfflineFriend from "../components/ListPlayer/offlineFriend";
import PlayerStt from "../components/PlayerStt";
import Search from "../components/Search";
import PropTypes from "prop-types";

const PlayerStatusWrapper = styled.div`
  padding: 15px;
  height: calc(100% - 82px);
  overflow: hidden;
`;

const SearchPlayer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;
const SearchWrap = styled.div`
  width: 250px;
`;
const ListPlayerWrap = styled.div`
  margin-bottom: 10px;
`;

const LabelFriendList = styled.p`
  width: 100%;
  line-height: 1;
  padding: 5px 0;
  color: #442211;
  font-size: 14px;
  font-weight: bold;
`;

const TextNotFoundPlayer = styled.p`
  text-align: center;
  color: #442211;
`;

function PlayerStatus(props) {
  const { scaleHeight } = props;
  const [usersOnline, setUsersOnline] = useState([]);
  const [searchUsers, setSearchUsers] = useState([]);
  const [friendsOffline, setFriendsOffline] = useState([]);
  const [term, setTerm] = useState("");
  const [checked, setChecked] = useState(false);
  const playerList = useSelector(
    (state) => state.statusPlayer.playerList ?? []
  );

  const { t } = useTranslation();

  const handleChangeCheckbox = (event) => {
    setChecked(event.target.checked);
  };

  useEffect(() => {
    const userOnline = playerList?.filter(
      (user) => user.isOnline === true && user.showOnline === true
    );
    setUsersOnline(userOnline);
    const _friendsOffline = playerList?.filter(
      (user) => user.isOnline === false || user.showOnline === false
    );
    setFriendsOffline(_friendsOffline);
  }, [playerList]);

  const handleSearch = useCallback((_term) => {
    setTerm(_term);
  }, []);

  useEffect(() => {
    const search = () => {
      if (term.length > 0) {
        const filtered = usersOnline?.filter((f) =>
          f?.nickName.toLowerCase().includes(term)
        );
        if (filtered.length > 0) {
          setSearchUsers(filtered);
        } else {
          setSearchUsers([]);
        }
      } else {
        setSearchUsers([]);
      }
    };
    search();
    // eslint-disable-next-line
  }, [term]);

  return (
    <Fragment>
      <PlayerStt isShowOnlineStatus={true} />
      <PlayerStatusWrapper>
        <div>
          <SearchPlayer>
            <FormControlLabel
              style={{ color: "#442211" }}
              label={t("status.friend")}
              control={
                <Checkbox
                  checked={checked}
                  onChange={handleChangeCheckbox}
                  sx={{ "& .MuiSvgIcon-root": { fontSize: 32 } }}
                  color="default"
                />
              }
            />
            <SearchWrap>
              <Search search={handleSearch}></Search>
            </SearchWrap>
          </SearchPlayer>
          <ListPlayerWrap>
            {searchUsers.length === 0 && term.length > 0 ? (
              <TextNotFoundPlayer>
                {t("friends.notFoundPlayer")}
              </TextNotFoundPlayer>
            ) : null}
            <ListPlayer
              usersOnline={term.length > 0 ? searchUsers : usersOnline}
              checkBox={checked}
              friendsOffline={[]}
              isTop={true}
              scaleHeight={scaleHeight}
            />
          </ListPlayerWrap>
        </div>
        <div>
          <LabelFriendList>{t("status.friendsOffline")}</LabelFriendList>
          <Box style={{ borderTop: "1px solid #442211" }}>
            <OfflineFriend
              friendsOffline={friendsOffline}
              scaleHeight={scaleHeight}
            />
          </Box>
        </div>
      </PlayerStatusWrapper>
    </Fragment>
  );
}

PlayerStatus.propTypes = {
  scaleHeight: PropTypes.number,
};

export default PlayerStatus;
