/* 4 type of
1. narration: run text and sound (type,name)
2. popup: popup wait action from user(type,name)
3. screen: display with duration time(type,name,duration)

*/

export default {
  SG_STEP1: [
    {
      type: "narration",
      name: "narration1",
    },
    {
      type: "narration",
      name: "narration2",
    },
    {
      type: "popup",
      name: "CONFIRM_PLAYER_COMPLETE_INTRODUCE",
      filename: "completeIntroduce",
    },
  ],
  SG_STEP2: [
    {
      type: "popup",
      name: "SG_STEP2_G_CARD",
    },
    {
      type: "narration",
      name: "narration3",
    },
    {
      type: "popup",
      name: "SG_STEP2_G_CARD_INSTRUCT",
      filename: "narration4",
    },
  ],
  SG_STEP3: [
    {
      type: "popup",
      name: "SG_STEP3_G_CARD",
    },
    {
      type: "narration",
      name: "narration4_1_1",
    },
    {
      type: "narration",
      name: "narration4_1_2",
    },
    {
      type: "narration",
      name: "narration4_1_3",
    },
    {
      type: "popup",
      name: "SG_STEP3_G_CARD_CLEAR",
    },
    {
      type: "narration",
      name: "narration5",
    },
    {
      type: "screen",
      name: "SG_STEP3_ARK",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration6",
    },
    {
      type: "screen",
      name: "wait_to_show_trick_star",
      duration: 2000,
    },
    {
      type: "screen",
      name: "SG_STEP_SHOW_TRICK_STAR",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration7",
    },
    {
      type: "screen",
      name: "wait_to_deal_cards",
      duration: 2000,
    },
    {
      type: "screen",
      name: "SG_STEP3_DEAL_CARDS",
      duration: 1000,
    },
    {
      type: "popup",
      name: "CONFIRM_AFTER_INSTRUCTION",
      filename: "narration8",
    },
  ],
  SG_STEP4: [
    {
      type: "narration",
      name: "narration5",
    },
    {
      type: "screen",
      name: "SG_STEP3_ARK",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration6",
    },
    {
      type: "screen",
      name: "wait_to_show_trick_star",
      duration: 2000,
    },
    {
      type: "screen",
      name: "SG_STEP_SHOW_TRICK_STAR",
      duration: 2000,
    },

    {
      type: "narration",
      name: "narration7",
    },
    {
      type: "screen",
      name: "wait_to_deal_cards",
      duration: 2000,
    },
    {
      type: "screen",
      name: "SG_STEP3_DEAL_CARDS",
      duration: 1000,
    },
    {
      type: "popup",
      name: "CONFIRM_AFTER_INSTRUCTION",
      filename: "narration8",
    },
  ],
  SG_STEP5: [
    {
      type: "screen",
      name: "SG_STEP3_DEAL_CARDS",
      duration: 1000,
    },
    {
      type: "narration",
      name: "narration9",
    },
    {
      type: "screen",
      name: "wait_to_show_deal_card",
      duration: 2000,
    },
    {
      type: "screen",
      name: "SG_STEP5_SHOW_DEAL_CARDS",
      duration: 0,
    },
  ],
  SG_STEP6: [
    {
      type: "screen",
      name: "wait_to_show_deal_card",
      duration: 2000,
    },
    {
      type: "screen",
      name: "SG_STEP5_SHOW_DEAL_CARDS",
      duration: 0,
    },
  ],
  SG_STEP7: [
    {
      type: "screen",
      name: "wait_to_run",
      duration: 2000,
    },
    {
      type: "popup",
      name: "SG_STEP7_RECALL",
      filename: "narration10",
    },
  ],
  SG_STEP8: [
    {
      type: "narration",
      name: "narration11",
    },
  ],
  SG_STEP9: [
    {
      type: "narration",
      name: "narration12",
    },
  ],
  SG_STEP10: [
    {
      type: "popup",
      name: "SG_STEP10_OVER_CALL",
      filename: "narration13",
    },
  ],
  SG_STEP11: [
    {
      type: "popup",
      name: "SG_STEP11_OVER_CALL",
      filename: "narration14",
    },
  ],
  SG_STEP12: [
    {
      type: "screen",
      name: "wait_to_show_end_game",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration39",
    },
    {
      type: "narration",
      name: "narration40",
    },
    {
      type: "narration",
      name: "narration41",
    },
    {
      type: "screen",
      name: "wait_to_run_next_end_game",
      duration: 3000,
    },
    {
      type: "popup",
      name: "SG_STEP12_END_GAME",
    },
  ],
  SG_STEP12_STOP_TUTORIAL: [
    {
      type: "screen",
      name: "wait_to_show_end_game",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration39",
    },
    {
      type: "narration",
      name: "narration40",
    },
    {
      type: "narration",
      name: "narration41",
    },
    {
      type: "screen",
      name: "wait_to_run_next_end_game",
      duration: 3000,
    },
    {
      type: "popup_wait",
      name: "SG_STEP12_STOP_TUTORIAL",
    },
  ],

  /**
   * todo: display number of calls dialog after apply calls
   * todo: open order cards screen (player same calls)
   */
  SG_STEP13: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration16_1",
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 500,
    },
    {
      type: "narration",
      name: "narration16_2",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_ORDER_CARD",
      filename: "narrationFirstPlayerSelectOrderCard",
    },
  ],
  /**
   * TODO: follow the step below after select order cards
   * @param tutorialMode: noTutorial
   * @param master: is g-chaos
   * @param isGChaosComputer: false
   */
  SG_STEP14: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "narration",
      name: "narration16_3",
    },
    // show dialog order card
    {
      type: "screen",
      name: "wait_to_show_order_card",
      duration: 2000,
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARD",
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration20",
    },
    {
      type: "narration",
      name: "narration20_1",
    },
    {
      type: "popup",
      name: "CONFIRM_G_CHAOS_MASTER_START_GAME",
      filename: "",
    },
  ],
  /**
   * TODO: follow the step below after select order cards
   * @param tutorialMode: noTutorial
   * @param master: is not g-chaos
   */
  SG_STEP15: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "narration",
      name: "narration16_3",
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARD",
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration18",
    },
    {
      type: "popup",
      name: "CONFIRM_AFTER_CALL",
      filename: "narration20_no_message",
    },
  ],
  SG_STEP16: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "narration",
      name: "narration16_3",
    },
    {
      type: "screen",
      name: "wait_to_show_order_card",
      duration: 2000,
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARD",
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration18",
    },
    {
      type: "narration",
      name: "narration19",
    },
    {
      type: "popup",
      name: "CONFIRM_AFTER_CALL",
      filename: "narration20_no_message",
    },
  ],
  /**
   * TODO: follow the step below after apply number of calls
   * @param tutorialMode: noTutorial
   * @param master: is G-chaos
   */
  SG_STEP17: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration20",
    },
    {
      type: "narration",
      name: "narration20_1",
    },
    {
      type: "popup",
      name: "CONFIRM_G_CHAOS_MASTER_START_GAME",
      filename: "",
    },
  ],
  /**
   * TODO: follow the step below after apply number of calls
   * @param tutorialMode: noTutorial
   * @param master: is not g-chaos
   * @param isFirstGame: false
   */
  SG_STEP18: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration18",
    },
    {
      type: "popup",
      name: "CONFIRM_AFTER_CALL",
      filename: "narration20_no_message",
    },
  ],
  /**
   * TODO: follow the step below after apply number of calls
   * @param tutorialMode: noTutorial
   * @param master: is not g-chaos
   * @param isFirstGame: true
   */
  SG_STEP19: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration17",
    }, // hide screen to see master ship
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration18",
    },
    {
      type: "narration",
      name: "narration19",
    },
    {
      type: "popup",
      name: "CONFIRM_AFTER_CALL",
      filename: "narration20_no_message",
    },
  ],
  /**
   * TODO: follow the step below after player confirm at step 18,19
   * @param tutorialMode: noTutorial
   */
  SG_STEP20: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration21",
    },
  ],
  SG_STEP21: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration20",
    },
    {
      type: "narration",
      name: "narration20_1",
    },
    {
      type: "popup",
      name: "CONFIRM_G_CHAOS_MASTER_START_GAME",
      filename: "",
    },
  ],
  SG_STEP22: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "narration",
      name: "narration16_3",
    },
    {
      type: "screen",
      name: "wait_to_show_order_card",
      duration: 2000,
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARD",
    },
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration20",
    },
    {
      type: "narration",
      name: "narration20_1",
    },
    {
      type: "popup",
      name: "CONFIRM_G_CHAOS_MASTER_START_GAME",
      filename: "",
    },
  ],
  /**
   * TODO: follow the step below after start game
   * @param tutorialMode: noCall
   * @param isGChaosComputer: true
   */
  SG_STEP23: [
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration20",
    },
    {
      type: "narration",
      name: "narration20_1",
    },
    {
      type: "popup",
      name: "CONFIRM_G_CHAOS_MASTER_START_GAME",
      filename: "",
    },
  ],
  /**
   * TODO: follow the step below after start game
   * @param tutorialMode: noCall
   * @param isGChaosComputer: false
   */
  SG_STEP24: [
    {
      type: "narration",
      name: "narration17",
    },
    {
      type: "screen",
      name: "show_master_ship",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration20",
    },
    {
      type: "narration",
      name: "narration20_1",
    },
    {
      type: "popup",
      name: "CONFIRM_G_CHAOS_MASTER_START_GAME",
      filename: "",
    },
  ],
  SG_STEP25: [
    {
      type: "popup",
      name: "CONFIRM_AFTER_INSTRUCTION",
      filename: "narration8",
    },
  ],

  // PLAY CARD
  SHOW_FIRST_CARD: [
    {
      type: "screen",
      name: "DELAY_G_CHAOS_PLAY_CARD",
      duration: 1000,
    },
    {
      type: "screen",
      name: "SHOW_FIRST_CARD",
      duration: 500,
    },
  ],
  SHOW_CARD_FIRST_ROUND: [
    {
      type: "screen",
      name: "DELAY_G_CHAOS_PLAY_CARD",
      duration: 1000,
    },
    {
      type: "narration",
      name: "narration21",
    },
    {
      type: "screen",
      name: "SHOW_CARD_FIRST_ROUND",
      duration: 500,
    },
  ],
  PC_STEP1: [
    {
      type: "narration",
      name: "narration30_1",
    },
  ],
  PC_STEP2: [
    {
      type: "narration",
      name: "narration30_2",
    },
  ],
  PC_STEP3: [
    {
      type: "narration",
      name: "narration22",
    },
  ],
  PC_STEP4: [
    {
      type: "narration",
      name: "narration23",
    },
  ],
  PC_STEP5: [
    {
      type: "narration",
      name: "narration24",
    },
  ],
  PC_STEP6: [
    {
      type: "narration",
      name: "narration25",
    },
  ],

  ED_STEP1: [
    {
      type: "popup",
      name: "CONFIRM_OPEN_CARDS_ROUND_13",
      filename: "narration35",
    },
  ],
  ED_STEP2: [
    {
      type: "screen",
      name: "wait_to_run",
      duration: 3000,
    },
    {
      type: "narration",
      name: "narration36",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 2000,
    },
    {
      type: "popup",
      name: "ANIMATED_WIN_GAME",
    },
  ],
  ED_STEP3: [
    {
      type: "screen",
      name: "wait_to_run",
      duration: 3000,
    },
    {
      type: "narration",
      name: "narration37",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 2000,
    },
    {
      type: "popup",
      name: "ANIMATED_WIN_GAME",
    },
  ],
  ED_STEP4: [
    {
      type: "screen",
      name: "wait_to_run",
      duration: 3000,
    },
    {
      type: "narration",
      name: "narration38",
    },
    {
      type: "narration",
      name: "narration39",
    },
    {
      type: "narration",
      name: "narration40",
    },
    {
      type: "narration",
      name: "narration41",
    },
    {
      type: "popup",
      name: "ED_STEP4_END_GAME",
    },
  ],

  END_GAME_TUTORIAL_NO_CALL: [
    {
      type: "screen",
      name: "wait_to_run",
      duration: 3000,
    },
    {
      type: "popup",
      name: "END_GAME_TUTORIAL_NO_CALL",
    },
  ],

  //   just captain not get
  ED_STEP10: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration26",
    },
  ],
  //   just captain not get
  ED_STEP10_G_CHAOS: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration26GChaos",
    },
  ],
  //   get and captain
  ED_STEP11: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration27",
    },
  ],
  //   get and captain
  ED_STEP11_G_CHAOS: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration27GChaos",
    },
  ],
  //   get eve and captain
  ED_STEP12: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration28",
    },
  ],
  //   get eve and captain
  ED_STEP12_G_CHAOS: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration28GChaos",
    },
  ],
  //   get breakthrough  and captain
  ED_STEP13: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration29",
    },
  ],
  //   get breakthrough  and captain
  ED_STEP13_G_CHAOS: [
    {
      type: "narration",
      name: "narrationSilence",
    },
    {
      type: "narration",
      name: "narration29GChaos",
    },
  ],
  ED_STEP14: [
    {
      type: "narration",
      name: "narration38_1",
      duration: 6326,
    },
  ],
  ED_STEP14_G_CHAOS: [
    {
      type: "narration",
      name: "narration38_1_GChaos",
      duration: 6326,
    },
  ],
  UM_STEP1: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_MASTER_CALL_SHOW_TRICK_STAR",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  UM_STEP2: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  // g-chaos first select master card
  UM_STEP3: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32",
    },

    {
      type: "narration",
      name: "narrationGChaosSelectMC",
    },
    {
      type: "popup",
      name: "G_CHAOS_SELECT_MASTER_CARD",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 1500,
    },
    {
      type: "screen",
      name: "UM_G_CHAOS_SELECT_CARD",
      duration: 1000,
    },
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32_1",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  // player 2 select master card
  // UM_STEP1 -> UM_STEP4
  UM_STEP4: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32_1",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  UM_STEP5: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narrationGChaosSelectMC",
    },
    {
      type: "popup",
      name: "G_CHAOS_SELECT_MASTER_CARD",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 1500,
    },
    {
      type: "screen",
      name: "UM_G_CHAOS_SELECT_CARD",
      duration: 1000,
    },
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration33",
    },
    {
      type: "screen",
      name: "UM_SHOW_MASTER_CARD_ALL",
      duration: 2000,
    },

    {
      type: "popup",
      name: "UM_SHOW_USE_MASTER_WINNER_CONFIRM",
    },
  ],
  UM_STEP6: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration33",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 2000,
    },
    {
      type: "screen",
      name: "UM_SHOW_MASTER_CARD_ALL",
      duration: 3000,
    },
    {
      type: "popup",
      name: "UM_SHOW_USE_MASTER_WINNER_CONFIRM",
    },
  ],
  UM_STEP7: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_MASTER_CALL_SHOW_TRICK_STAR",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "screen",
      name: "wait_to_run3",
      duration: 2000,
    },
    {
      type: "narration",
      name: "narration34",
    },
    {
      type: "popup",
      name: "G_CHAOS_SELECT_MASTER_CARD",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 1500,
    },
    {
      type: "screen",
      name: "UM_G_CHAOS_SELECT_ALL_CARD",
      duration: 1000,
    },
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "screen",
      name: "wait_to_show_card1",
      duration: 800,
    },
    {
      type: "screen",
      name: "UM_SHOW_MASTER_CARD_1",
      duration: 1500,
    },
    {
      type: "screen",
      name: "UM_SHOW_MASTER_CARD_ALL",
      duration: 2000,
    },
    {
      type: "popup",
      name: "UM_SHOW_USE_MASTER_WINNER_CONFIRM",
    },
  ],
  UM_STEP8: [
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD_ALL",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "screen",
      name: "wait_to_master_card_screen",
      duration: 3000,
    },
    {
      type: "screen",
      name: "UM_CLOSE_MASTER_CARD",
      duration: 0,
    },
  ],
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_MASTER_CALL_SHOW_TRICK_STAR",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  // g-chaos first select master card
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration31",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  // player 2 select master card
  // UM_STEP1 -> UM_STEP4
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration32_1",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "popup",
      name: "PLAYER_SELECT_MASTER_CARD",
      filename: "narrationPlayerSelectMC",
    },
    {
      type: "narration",
      name: "narrationPlayerSelectMC",
    },
  ],
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6: [
    {
      type: "popup",
      name: "RESET_OTHER_STATE_USE_MASTER_CARD",
    },
    {
      type: "popup",
      name: "UM_SHOW_MASTER_CARD",
    },
    {
      type: "narration",
      name: "narration33",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 2000,
    },
    {
      type: "screen",
      name: "UM_SHOW_MASTER_CARD_ALL",
      duration: 3000,
    },
    {
      type: "popup",
      name: "UM_SHOW_USE_MASTER_WINNER_CONFIRM",
    },
  ],

  INSTRUCTOR_NOTIFY_STEP: [
    {
      type: "screen",
      name: "INSTRUCTOR_NOTIFY",
      duration: 2000,
    },
  ],
  LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_STEP: [
    {
      type: "popup",
      name: "LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_SCREEN",
    },
  ],
  /** USE ORDER CARD */
  /**
   * todo: display number of calls dialog after apply calls
   * todo: open order cards screen (player same calls)
   * @case G-Chaos select first
   */

  G_CHAOS_SELECT_ORDER_CARD_1ST_TIME: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 500,
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration16_1",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 500,
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 500,
    },
    {
      type: "narration",
      name: "narration16_2",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 500,
    },
    {
      type: "narration",
      name: "narrationFirstPlayerSelectOrderCard",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 500,
    },
    {
      type: "popup",
      name: "UPDATE_TURN_G_CHAOS_SELECT_ORDER_CARD",
    },
  ],
  /**
   * todo: display number of calls dialog after apply calls
   * todo: open order cards screen (player same calls)
   * @case player select first
   */

  PLAYER_SELECT_ORDER_CARD_1ST_TIME: [
    {
      type: "narration",
      name: "narration15",
    },
    {
      type: "screen",
      name: "SG_STEP13_DETERMINE_DECLARE_CALL",
      duration: 5000,
    },
    {
      type: "narration",
      name: "narration16_1",
    },
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
      filename: "",
    },
    {
      type: "narration",
      name: "narration16_2",
    },
    {
      type: "narration",
      name: "narrationFirstPlayerSelectOrderCard",
    },
    {
      type: "popup",
      name: "UPDATE_TURN_PLAYER_SELECT_ORDER_CARD",
    },
  ],
  PLAYER_SELECT_ORDER_CARD_2ND_TIME: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 1500,
    },
    {
      type: "narration",
      name: "narrationFirstPlayerSelectOrderCard",
    },
    {
      type: "popup",
      name: "UPDATE_TURN_PLAYER_SELECT_ORDER_CARD",
      filename: "",
    },
  ],
  PLAYER_SELECT_ORDER_CARD_3RD_TIME: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
    {
      type: "screen",
      name: "wait_to_run",
      duration: 1500,
    },
    {
      type: "narration",
      name: "narrationFirstPlayerSelectOrderCard",
    },
    {
      type: "popup",
      name: "UPDATE_TURN_PLAYER_SELECT_ORDER_CARD",
    },
  ],
  CONFIRM_END_ORDER_CARD: [
    {
      type: "popup",
      name: "SHOW_ORDER_CARDS_SCREEN",
    },
  ],
};
