import styled from "styled-components";

export const Container = styled.div`
  width: ${(props) => props.width};
  height: ${(props) => props.height};
  box-sizing: border-box;
  background-color: #dea417;
  position: relative;
  display: flex;
  align-items: flex-end;
`;

export const GChaosWelcome = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  // background-image: url("/assets/h_g_chaos_welcome.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(180deg);
  box-sizing: border-box;
  overflow: hidden;
`;

export const GuestWelcome = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  // background-image: url("/assets/h_guest_welcome.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transform: rotate(180deg);
  box-sizing: border-box;
  overflow: hidden;
`;

export const Master = styled.div`
  width: 100%;
  height: ${(props) => props.height}px;
  background-image: url("/assets/h_master.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
`;
