import React from "react";
import PropTypes from "prop-types";
import { Text } from "../styles";

const TextComponent = ({ fontSize, padding, children }) => {
  return (
    <Text fontSize={fontSize} padding={padding}>
      {children}
    </Text>
  );
};
TextComponent.propTypes = {
  fontSize: PropTypes.number,
  children: PropTypes.any,
  padding: PropTypes.number,
};
export default TextComponent;
