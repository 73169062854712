import { makeStyles, Typography } from "@material-ui/core";
import React from "react";
import PropTypes from "prop-types";

const useStyles = makeStyles(() => ({
  style: {
    color: "#00ff00",
    fontWeight: "500",
    maxWidth: "250px",
    margin: "auto",
  },
}));

const SuccessMessage = ({ message }) => {
  const classes = useStyles();
  return <Typography className={classes.style}>{message}</Typography>;
};

SuccessMessage.propTypes = {
  message: PropTypes.string,
};

export default SuccessMessage;
