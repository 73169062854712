import { makeStyles } from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  tableForm: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
  },
  radio: {
    color: "black",
    width: "100%",
    fontWeight: "400",
    letterSpacing: 0,
    "&>label": {
      fontSize: 12,
      color: "#888888",
      fontWeight: "400",
      letterSpacing: 0,
    },
    "& div": {
      "& label": {
        "& span": {
          fontSize: 16,
          color: "black",
          fontWeight: "400",
          letterSpacing: 0,
        },
      },
    },
  },
}));

export default useStyles;
