/* eslint-disable no-unused-vars */
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";
import React, { forwardRef, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaLongArrowAltRight } from "react-icons/fa";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import ErrorMessage from "../../../../../components/ErrorMessage";
import Input from "../../../../../components/Input";
import {
  getAdminList,
  updateAdmin,
} from "../../../../../store/actions/adminAction";
import useStyles, { ContentWrapper, TitleWrapper } from "./styles";
import { validateEmail } from "../../../../../utils/validation";

const initialState = {
  newName: "",
  newEmail: "",
};

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const DialogEditAdmin = ({
  open,
  handleClose,
  editData,
  setRows,
  handleLoading,
}) => {
  const { t } = useTranslation();
  const [values, setValues] = useState(initialState);
  const [oldValue, setOldValue] = useState({});
  const [errors, setErrors] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [success, setSuccess] = useState("");
  const [disabledButton, setDisabledButton] = useState(true);
  const minScale = useSelector((state) => state.size.minScale);
  const input_W = useMemo(() => (minScale < 0.4 ? 200 : 250), [minScale]);
  const fontSize16 = useMemo(() => (minScale < 0.4 ? 11 : 16), [minScale]);
  const buttonPaddingX = useMemo(() => Math.round(30 * minScale), [minScale]);
  const buttonPaddingY = useMemo(() => Math.round(10 * minScale), [minScale]);
  const btnWidth = useMemo(
    () => (minScale < 0.4 ? Math.round(203 * minScale * 2) : 203),
    [minScale]
  );
  const btnHeight = useMemo(
    () => (minScale < 0.4 ? Math.round(56 * minScale * 2) : 56),
    [minScale]
  );
  const classes = useStyles({
    input_W,
    input_H: 51,
    fontSize16,
    buttonPaddingX,
    buttonPaddingY,
    btnWidth,
    btnHeight,
  });

  useEffect(() => {
    const clearError = setTimeout(() => {
      setErrorMessage("");
    }, 10000);

    return () => clearTimeout(clearError);
  }, [errorMessage]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setValues({ ...values, [name]: value });
    if (errorMessage !== "" || success !== "" || errors !== "") {
      setErrors("");
      setErrorMessage("");
      setSuccess("");
    }
  };

  const handleUpdateAdmin = async () => {
    handleLoading(true);
    const err = validateEmail(values.newEmail);
    if (err) {
      setErrorMessage(t(err));
      handleLoading(false);
      return;
    }

    if (values && values.newName !== "") {
      let res = await updateAdmin(values, editData.playerId);
      if (res === "") {
        const adminData = await getAdminList();
        toast.success(`${t("admin.updateSuccess")}`);
        setRows(adminData.data);
        handleClose();
      }
      setErrorMessage(t(res));
    }
    handleLoading(false);
  };

  useEffect(() => {
    if (editData) {
      setOldValue({
        newName: editData.nickName,
        newEmail: editData.email,
      });
      setValues({
        ...initialState,
        newName: editData.nickName,
        newEmail: editData.email,
      });
    }
  }, [editData]);

  useEffect(() => {
    if (values) {
      // TODO: add border color when have change
      let _disableButton = true;
      for (let name in values) {
        if (name === "newName" || name === "newEmail") {
          let el = document.querySelector(`[name=${name}]`);
          if (
            values[name] !== oldValue[name] &&
            el?.parentElement?.parentElement
          ) {
            el.parentElement.parentElement.style.borderColor = "#d6a87d";
            _disableButton = false;
          } else if (el?.parentElement?.parentElement) {
            el.parentElement.parentElement.style.borderColor =
              "rgb(75, 74, 74)";
          }
        }
      }
      setDisabledButton(_disableButton);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Dialog
      open={open}
      TransitionComponent={Transition}
      keepMounted
      onClose={() => {
        setErrors("err");
        handleClose();
      }}
      aria-describedby="dialog-change-pass"
      classes={{
        paper: classes.dialogPaper,
      }}
    >
      <DialogTitle
        style={{ fontSize: "30px", alignItems: "center", textAlign: "center" }}
      >
        {t("admin.editAdminTitle")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <Box className={classes.mainBox}>
            <TitleWrapper
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <Typography className={classes.label}>&nbsp;</Typography>
              <DialogTitle width={input_W} style={{ textAlign: "center" }}>
                {t("admin.beforeEdit")}
              </DialogTitle>
              <Box className={classes.iconBox}>&nbsp;</Box>
              <DialogTitle width={input_W} style={{ textAlign: "center" }}>
                {t("admin.afterEdit")}
              </DialogTitle>
            </TitleWrapper>
            <Box className={classes.fieldItem}>
              <ContentWrapper>
                <Box className={classes.field}>
                  <Typography className={classes.label}>
                    {t("admin.nickName")}
                  </Typography>
                  <Input
                    sx={{ margin: "0 !important" }}
                    value={editData.nickName}
                    width={input_W + 10}
                    height={51}
                    className={classes.input}
                    disabled
                    show={true}
                  />
                  <Box className={classes.iconBox}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Input
                    autoFocus={true}
                    name="newName"
                    placeHolder=""
                    type="text"
                    value={values.newName}
                    onChange={handleChange}
                    width={input_W + 10}
                    height={51}
                    show={true}
                  />
                </Box>
                <Box className={classes.field}>
                  <Typography className={classes.label}>
                    {t("admin.email")}
                  </Typography>
                  <Input
                    sx={{ margin: "0 !important" }}
                    value={editData.email}
                    width={input_W + 10}
                    height={51}
                    className={classes.input}
                    disabled
                    show={true}
                  />
                  <Box className={classes.iconBox}>
                    <FaLongArrowAltRight />
                  </Box>
                  <Input
                    autoFocus={true}
                    name="newEmail"
                    placeHolder=""
                    type="text"
                    value={values.newEmail}
                    onChange={handleChange}
                    width={input_W + 10}
                    height={51}
                    show={true}
                  />
                </Box>
              </ContentWrapper>
            </Box>
          </Box>
        </DialogContentText>
      </DialogContent>
      {errorMessage ? (
        <ErrorMessage message={errorMessage} />
      ) : (
        <div>&nbsp;</div>
      )}
      <Box className={classes.boxButton}>
        <Button
          variant="outlined"
          className={classes.buttonBlue}
          onClick={() => {
            setErrors("err");
            handleClose();
          }}
        >
          {t("tableSettings.back")}
        </Button>
        <Button
          onClick={handleUpdateAdmin}
          variant="contained"
          className={classes.buttonOrange}
          disabled={disabledButton}
        >
          {t("changePassword.update")}
        </Button>
      </Box>
    </Dialog>
  );
};

DialogEditAdmin.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func,
  editData: PropTypes.object.isRequired,
  setRows: PropTypes.func,
  handleLoading: PropTypes.func,
};

export default DialogEditAdmin;
