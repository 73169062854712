import {
  SET_ROOM,
  JOIN_ROOM,
  SET_JOIN_TABLE,
  OTHER_PROFILE,
  ROOM_PLAYER_OVER_GET,
  CLEAR_ROOM,
} from "../types";
const initialState = {
  chattingMessages: [],
  joinedPlayers: [],
  receivedJoinInvitations: [],
  requestedJoinInvitations: [],
  roomId: "",
  threadId: "",
  accessToken: "",
  table: "",
  tableName: "",
  profile0: {},
  profile1: {},
  profile2: {},
  profile3: {},
  notifications: [],
  startGameNoCallConfirmations: [],
  playerReady: 0,
};

const roomReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_ROOM:
      return {
        ...state,
        chattingMessages: action.payload.chattingMessages,
        joinedPlayers: action.payload.joinedPlayers,
        receivedJoinInvitations: action.payload.receivedJoinInvitations,
        requestedJoinInvitations: action.payload.requestedJoinInvitations,
        roomId: action.payload.roomId,
        threadId: action.payload.threadId,
        accessToken: action.payload.accessToken,
        notifications: action.payload.notifications,
        startGameNoCallConfirmations:
          action.payload.startGameNoCallConfirmations,
        playerReady: action.payload.playerReady,
      };
    case JOIN_ROOM:
      return {
        ...state,
        chattingMessages: action.payload.chattingMessages,
        joinedPlayers: action.payload.joinedPlayers,
        roomId: action.payload.roomId,
        notifications: action.payload.notifications,
      };

    case SET_JOIN_TABLE:
      return {
        ...state,
        table: action.payload,
        tableName: action.payload.tableName,
      };
    case OTHER_PROFILE:
      return {
        ...state,
        profile0: action.payload.profile0
          ? action.payload.profile0
          : state.profile0,
        profile1: action.payload.profile1
          ? action.payload.profile1
          : state.profile1,
        profile2: action.payload.profile2
          ? action.payload.profile2
          : state.profile2,
        profile3: action.payload.profile3
          ? action.payload.profile3
          : state.profile3,
      };
    case ROOM_PLAYER_OVER_GET:
      return {
        ...state,
        endGamePlayerName: getPlayerNameEndGame(action.payload.playerId, [
          state.profile0,
          state.profile1,
          state.profile2,
          state.profile3,
        ]),
      };
    case CLEAR_ROOM:
      return initialState;
    default:
      return state;
  }
};

export const getPlayerNameEndGame = (playerId, profiles) => {
  const indexOfPlayer = profiles.findIndex(
    (profile) => profile && profile.playerId === playerId
  );
  return -1 === indexOfPlayer ? "" : profiles[indexOfPlayer].nickName;
};

export default roomReducer;
