/**
 * Return lose case is under get or not
 */
export function isUnderGet(myState, otherPlayerStates, myIndex) {
  const allStates = [...otherPlayerStates];
  allStates[myIndex] = myState;
  for (const state of allStates) {
    const starCount = state.starCards.length;
    const numberOfCalls = state.numberOfCalls;
    if (starCount > numberOfCalls) {
      return false;
    }
  }
  return true;
}
