import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import OkDialog from "../../pages/GameScreen/components/NewDialog/OkDialog";
import { MIN, STEP_NARRATION } from "../../store/constant";
import { SET_NOTIFY_G_CHAOS_TURN } from "../../store/types";

const NotifyGChaosTurn = () => {
  const notifyGChaosTurn = useSelector(
    (state) => state.playerGameState.notifyGChaosTurn
  );
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const timeoutTimer = useRef(null);
  const currentNotifyGChaosTurn = useRef(notifyGChaosTurn);
  const [isShowReloadDialog, setIsShowReloadDialog] = useState(false);
  const dispatch = useDispatch();
  const { t } = useTranslation();

  useEffect(() => {
    const isTrackReload = () => {
      return [
        STEP_NARRATION.ED_STEP1,
        STEP_NARRATION.ED_STEP2,
        STEP_NARRATION.ED_STEP3,
        STEP_NARRATION.ED_STEP4,
        STEP_NARRATION.UM_STEP1,
        STEP_NARRATION.UM_STEP2,
        STEP_NARRATION.UM_STEP3,
        STEP_NARRATION.UM_STEP4,
        STEP_NARRATION.UM_STEP5,
        STEP_NARRATION.UM_STEP6,
        STEP_NARRATION.UM_STEP7,
        STEP_NARRATION.UM_STEP8,
      ].includes(stepId);
    };
    if (isTrackReload() && currentNotifyGChaosTurn.current) {
      setIsShowReloadDialog(false);
      clearTimeout(timeoutTimer.current);
    }
  }, [stepId]);

  useEffect(() => {
    currentNotifyGChaosTurn.current = notifyGChaosTurn;
    if (notifyGChaosTurn === true) {
      timeoutTimer.current = setTimeout(() => {
        setIsShowReloadDialog(true);
        clearTimeout(timeoutTimer.current);
      }, MIN);
    } else {
      clearTimeout(timeoutTimer.current);
      setIsShowReloadDialog(false);
    }
  }, [notifyGChaosTurn]);

  const onOK = () => {
    clearTimeout(timeoutTimer.current);
    setIsShowReloadDialog(false);
    dispatch({ type: SET_NOTIFY_G_CHAOS_TURN, payload: false });
  };

  return (
    <div>
      <OkDialog
        isOpen={isShowReloadDialog}
        onAgree={onOK}
        confirmText={t("notify.gChaosTurn")}
        btnLabel={"OK"}
      />
    </div>
  );
};

export default NotifyGChaosTurn;
