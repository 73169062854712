import { Box, Dialog } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  SCREEN_NARRATION,
  STEP_NARRATION,
} from "../../../../../store/constant";
import FlippedCard from "../../FlippedCard";
import PaperComponent from "../../PaperComponent";
import {
  CardSelectedWrapper,
  CardWrapper,
  Container,
  TitleWrapper,
} from "./Narration5ArkStyle";
const Narration5Ark = () => {
  const confirmId = useSelector((state) => state.narration.narrationScreen);
  const stepId = useSelector((state) => state.playerGameState.stepId);
  const [isShow, setIsShow] = useState(true);

  useEffect(() => {
    setIsShow(
      SCREEN_NARRATION.SG_STEP3_ARK === confirmId &&
        [STEP_NARRATION.SG_STEP3, STEP_NARRATION.SG_STEP4].includes(stepId)
    );
  }, [confirmId, stepId]);

  return (
    <Dialog open={isShow} PaperComponent={PaperComponent}>
      <Box id="draggable-dialog-title" width={350} height={250}>
        <Box height="100%">
          <Container>
            <CardWrapper>
              <TitleWrapper>
                <Box marginBottom={1}>{"ARK"}</Box>
              </TitleWrapper>
              <Box display="flex">
                <CardSelectedWrapper>
                  <FlippedCard
                    flipped={false}
                    src={`/cards/back.png`}
                    onClickSelectCard={() => {}}
                  />
                </CardSelectedWrapper>
                <CardSelectedWrapper>
                  <FlippedCard
                    flipped={false}
                    src={`/cards/back.png`}
                    onClickSelectCard={() => {}}
                  />
                </CardSelectedWrapper>
              </Box>
            </CardWrapper>
          </Container>
        </Box>
      </Box>
    </Dialog>
  );
};
export default React.memo(Narration5Ark);
