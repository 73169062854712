import { makeStyles } from "@material-ui/core/styles";
import { COLOR_YELLOW } from "../../constants";
const useStyles = makeStyles(() => ({
  menu: {
    position: "fixed",
    top: 0,
    right: "100px",
    color: COLOR_YELLOW,
  },
  status: {
    position: "fixed",
    top: 0,
    right: "20px",
    color: COLOR_YELLOW,
  },
  notify: {
    position: "fixed",
    top: 0,
    right: "60px",
    color: COLOR_YELLOW,
  },
}));

export default useStyles;
