export const IS_DEBUGGER = false;
export const MAX_CHARACTER_NAME = 18;
export const MAX_CHARACTER_DESC = 18;
export const MAX_CHARACTER_NICK_NAME = 20;
export const MAX_CHARACTER_MESSAGE = 160;
export const START_GAME_NO_CALL_CONFIRMATIONS = "startGameNoCallConfirmations";
export const TURN_STATUS = {
  INTRODUCE: "introduce",
  CAPTAIN: "captain",
  PLAY_CARD: "playCard",
  WAIT_RF: "waitRequestPlease",
  DECLARE_CALL: "declareCall",
  DECIDE_MASTER: "decideMaster",
  SELECT_CARD_REQUEST_PLEASE: "selectCardRequestPlease",
};
export const COLOR_RED_BROWN = "rgb(191,73,36)";
export const LIMIT_TIME = {
  PLAY_CARD: 10,
  WAIT_RF: 10,
  CAPTAIN: 10,
  DECLARE_CALL: 10,
  DECIDE_MASTER: 10,
  SELECT_CARD_REQUEST_PLEASE: 10,
  DELAY_SHOW_MASTER_CARDS: 0,
  DELAY_END_GAME: 3000,
  DELAY_NORMAL_TURN: 0,
  DELAY_OPEN_CARDS_LAST_ROUND: 2000,
  DELAY_SHOW_INSTRUCTOR_MESSAGE: 5000,
  DELAY_END_A_ROUND: 3000,
};

//G-Chaos
export const G_CHAOS = "G-Chaos";
export const ONE_DAY = 1000 * 60 * 60 * 24;
export const TRIAL_DAYS = 7;

// turn status
export const TURN_STATUS_PLAY_CARD = "playCard";
export const TURN_STATUS_WAIT_REQUEST_PLEASE = "waitRequestPlease";
export const TURN_STATUS_CAPTAIN = "captain";
export const TURN_STATUS_DECIDE_MASTER = "decideMaster";

/// Player ROLE
export const PLAYER_ROLE_MASTER = "Master";
export const PLAYER_ROLE_NORMAL = "Normal";
export const PLAYER_ROLE_G_CHAOS = "GChaos";
export const PLAYER_ROLE_MASTER_G_CHAOS = "Master-GChaos";
// card suits
export const CARD_SUIT_CLUBS = "Clubs";
export const CARD_SUIT_DIAMONDS = "Diamonds";
export const CARD_SUIT_HEARTS = "Hearts";
export const CARD_SUIT_SPADES = "Spades";
export const CARD_SUIT_G = "G";
export const CARD_SUIT_WHATEVER = "whatever";

export const SEC = 1000;
export const MIN = 60 * SEC;
export const MIN5 = 5 * MIN;
export const MIN15 = 15 * MIN;
export const HOUR = 60 * MIN;
export const DAY = 24 * HOUR;
export const TWO_MONTHS = 60 * DAY;

export const STD_SIZE = {
  STD_WIDTH: 1920,
  STD_HEIGHT: 1080,
  STD_H_SHIP_WIDTH: 550,
  STD_H_SHIP_HEIGHT: 240,
  STD_V_SHIP_WIDTH: 240,
  STD_V_SHIP_HEIGHT: 550,
  STD_SHIP_LONG_SHORT_RATIO: 2.3,
  STD_CARD_WIDTH: 95,
  STD_CARD_HEIGHT: 145,
  STD_CARD_WIDTH_CARD_HEIGHT_RATIO: 0.655,
  STD_SHIP_SPACE: 12,
  STD_MY_CARD_WIDTH: 587,
  STD_AVATAR_WIDTH: 255,
  STD_AVATAR_HEIGHT: 240,
  STD_ARK_SIZE_GRAND_CROSS_WIDTH_RATIO: 0.33,
  STD_ARK_PADDING_ARK_SIZE_RATIO: 0.14,
  STD_LONG_BUTTON_WIDTH: 203,
  STD_LONG_BUTTON_HEIGHT_LONG_BUTTON_WIDTH_RATIO: 0.2, // STD_LONG_BUTTON_WIDTH * 0.2 => height
  STD_MENU_BUTTON_WIDTH: 200,
  STD_MENU_BUTTON_HEIGHT: 77,
  STD_MENU_BUTTON_WIDTH_HEIGHT_RATIO: 2.6,
  STD_MENU_WIDTH_MENU_BUTTON_WIDTH_RATIO: 1.2,
  STD_MENU_BUTTON_SPACE: 24,
  STD_CHAT_BUTTON_WIDTH: 184,
  STD_CHAT_BUTTON_HEIGHT: 54,
  STD_CHAT_BUTTON_WIDTH_HEIGHT_RATIO: 3.4,
  STD_VIDEO_VOICE_BUTTON_WIDTH: 68,
  STD_VIDEO_VOICE_BUTTON_HEIGHT: 73,
  STD_WELCOME_BAND_WIDTH: 48,
  STD_MASTER_WIDTH: 24,
  STD_PROFILE_SHIP_SPACE: 24,
  STD_MY_CARD_LEFT_MARGIN: 15,
  STD_MENU_TITLE_FONT_SIZE: 25,
  STD_LOGO_WIDTH: 233,
  STD_LOGO_HEIGHT: 191,
  STD_SVG_SETTING_BUTTON: 45,
  STD_DRAWER_WIDTH: 450,
};

// card value
export const CARD_VALUE = {
  J: 11,
  Q: 12,
  K: 13,
  A: 14,
  G1: 15,
  G0: 16,
  TEN: 10,
};

export const CARD_SUIT = {
  CLUBS: "Clubs",
  DIAMONDS: "Diamonds",
  HEARTS: "Hearts",
  SPADES: "Spades",
  G: "G",
  WHATEVER: "whatever",
};

export const GAME_STATUS = {
  WIN: "win",
  LOSE: "lose",
  PLAYING: "playing",
  END: "end",
  RESET: "",
};

export const TUTORIAL_MODE = {
  NO_TUTORIAL: "noTutorial",
  HAS_CALL: "hasCall",
  NO_CALL: "noCall",
};

export const PLAYER_ROLES = {
  MASTER: PLAYER_ROLE_MASTER,
  NORMAL: PLAYER_ROLE_NORMAL,
  G_CHAOS: PLAYER_ROLE_G_CHAOS,
  G_CHAOS_MASTER: PLAYER_ROLE_MASTER_G_CHAOS,
};

export const STEP_NARRATION = {
  SG_STEP1: "SG_STEP1",
  SG_STEP2: "SG_STEP2",
  SG_STEP2_3: "SG_STEP2_3",
  SG_STEP2_4: "SG_STEP2_4",
  SG_STEP3: "SG_STEP3",
  SG_STEP4: "SG_STEP4",
  SG_STEP5: "SG_STEP5",
  SG_STEP6: "SG_STEP6",
  SG_STEP7: "SG_STEP7",
  SG_STEP8: "SG_STEP8",
  SG_STEP9: "SG_STEP9",
  SG_STEP10: "SG_STEP10",
  SG_STEP11: "SG_STEP11",
  SG_STEP12: "SG_STEP12",
  SG_STEP12_STOP_TUTORIAL: "SG_STEP12_STOP_TUTORIAL",
  SG_STEP13: "SG_STEP13",
  SG_STEP14: "SG_STEP14",
  SG_STEP15: "SG_STEP15",
  SG_STEP16: "SG_STEP16",
  SG_STEP17: "SG_STEP17",
  SG_STEP18: "SG_STEP18",
  SG_STEP19: "SG_STEP19",
  SG_STEP20: "SG_STEP20",
  SG_STEP21: "SG_STEP21",
  SG_STEP22: "SG_STEP22",
  SG_STEP23: "SG_STEP23",
  SG_STEP24: "SG_STEP24",
  SG_STEP25: "SG_STEP25",
  // USE MASTER CARD
  UM_STEP1: "UM_STEP1",
  UM_STEP2: "UM_STEP2",
  UM_STEP3: "UM_STEP3",
  UM_STEP4: "UM_STEP4",
  UM_STEP5: "UM_STEP5",
  UM_STEP6: "UM_STEP6",
  UM_STEP7: "UM_STEP7",
  UM_STEP8: "UM_STEP8",
  // G-CHAOS IS HUMAN
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP1",
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP2",
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP3",
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP4",
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP5",
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP6",
  UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP7:
    "UM_G_CHAOS_HUMAN_SELECT_MASTER_CARD_STEP7",

  //END GAME
  ED_STEP1: "ED_STEP1",
  ED_FINAL_STAGE: "ED_FINAL_STAGE",
  ED_STEP2: "ED_STEP2",
  ED_STEP3: "ED_STEP3",
  ED_STEP4: "ED_STEP4",
  ED_STEP10: "ED_STEP10",
  ED_STEP10_G_CHAOS: "ED_STEP10_G_CHAOS",
  ED_STEP11: "ED_STEP11",
  ED_STEP11_G_CHAOS: "ED_STEP11_G_CHAOS",
  ED_STEP12: "ED_STEP12",
  ED_STEP12_G_CHAOS: "ED_STEP12_G_CHAOS",
  ED_STEP13: "ED_STEP13",
  ED_STEP13_G_CHAOS: "ED_STEP13_G_CHAOS",
  ED_STEP14: "ED_STEP14",
  ED_STEP14_G_CHAOS: "ED_STEP14_G_CHAOS",
  END_GAME_TUTORIAL_NO_CALL: "END_GAME_TUTORIAL_NO_CALL",

  // PLAY CARD
  PC_STEP1: "PC_STEP1",
  PC_STEP2: "PC_STEP2",
  PC_STEP3: "PC_STEP3",
  PC_STEP4: "PC_STEP4",
  PC_STEP5: "PC_STEP5",
  PC_STEP6: "PC_STEP6",
  SHOW_FIRST_CARD: "SHOW_FIRST_CARD",
  SHOW_CARD_FIRST_ROUND: "SHOW_CARD_FIRST_ROUND",

  // First round
  PC_G0_FIRST_ROUND: "PC_G0_FIRST_ROUND",
  PC_G1_FIRST_ROUND: "PC_G1_FIRST_ROUND",
  PC_SPADE_FIRST_ROUND: "PC_SPADE_FIRST_ROUND",
  PC_HEART_FIRST_ROUND: "PC_HEART_FIRST_ROUND",
  PC_DIAMOND_FIRST_ROUND: "PC_DIAMOND_FIRST_ROUND",
  PC_CLUB_FIRST_ROUND: "PC_CLUB_FIRST_ROUND",

  INSTRUCTOR_NOTIFY_STEP: "INSTRUCTOR_NOTIFY_STEP",
  LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_STEP:
    "LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_STEP",
  /** USE ORDER CARDS */
  G_CHAOS_SELECT_ORDER_CARD_FIRST: "G_CHAOS_SELECT_ORDER_CARD_FIRST",
  PLAYER_SELECT_ORDER_CARD_FIRST: "PLAYER_SELECT_ORDER_CARD_FIRST",
  G_CHAOS_SELECT_ORDER_CARD_1ST_TIME: "G_CHAOS_SELECT_ORDER_CARD_1ST_TIME",
  PLAYER_SELECT_ORDER_CARD_1ST_TIME: "PLAYER_SELECT_ORDER_CARD_1ST_TIME",
  PLAYER_SELECT_ORDER_CARD_2ND_TIME: "PLAYER_SELECT_ORDER_CARD_2ND_TIME",
  PLAYER_SELECT_ORDER_CARD_3RD_TIME: "PLAYER_SELECT_ORDER_CARD_3RD_TIME",
  CONFIRM_END_ORDER_CARD: "CONFIRM_END_ORDER_CARD",
  CONFIRM_OPEN_ORDER_CARD: "CONFIRM_OPEN_ORDER_CARD",
  CONFIRM_CAN_SELECT_ORDER_CARD: "CONFIRM_CAN_SELECT_ORDER_CARD",
};

export const SCREEN_NARRATION = {
  //START GAME
  SG_STEP1_INTRODUCE: "SG_STEP1_INTRODUCE",
  SG_STEP2_G_CARD: "SG_STEP2_G_CARD",
  SG_STEP2_G_CARD_INSTRUCT: "SG_STEP2_G_CARD_INSTRUCT",
  SG_STEP3_ARK: "SG_STEP3_ARK",
  SG_STEP3_DEAL_CARDS: "SG_STEP3_DEAL_CARDS",
  SG_STEP3_APOCALYPSE: "SG_STEP3_APOCALYPSE",
  SG_STEP5_SHOW_DEAL_CARDS: "SG_STEP5_SHOW_DEAL_CARDS",
  SG_STEP7_RECALL: "SG_STEP7_RECALL",
  SG_STEP10_OVER_CALL: "SG_STEP10_OVER_CALL",
  SG_STEP11_OVER_CALL: "SG_STEP11_OVER_CALL",
  SG_STEP12_END_GAME: "SG_STEP12_END_GAME",
  SG_STEP12_STOP_TUTORIAL: "SG_STEP12_STOP_TUTORIAL",
  SG_STEP13_DETERMINE_DECLARE_CALL: "SG_STEP13_DETERMINE_DECLARE_CALL",
  SG_STEP13_SHOW_ORDER_CARD: "SG_STEP13_SHOW_ORDER_CARD",
  SG_STEP13_ORDER_CARD: "SG_STEP13_ORDER_CARD",
  SG_STEP14_START_GAME: "SG_STEP14_START_GAME",
  SG_STEP14_MASTER_G_CHAOS: "SG_STEP14_MASTER_G_CHAOS",
  SG_STEP15_INTRO_MASTER: "SG_STEP15_INTRO_MASTER",
  SG_STEP13_HIDE_ORDER_CARD: "SG_STEP13_HIDE_ORDER_CARD",
  SG_STEP22_SHOW_ORDER_SCREEN: "SG_STEP22_SHOW_ORDER_SCREEN",
  SG_STEP_SHOW_TRICK_STAR: "SG_STEP_SHOW_TRICK_STAR",
  SG_STEP3_G_CARD: "SG_STEP3_G_CARD",
  SG_STEP3_G_CARD_CLEAR: "SG_STEP3_G_CARD_CLEAR",
  G_CHAOS_MASTER_SHOW_DIALOG_CONFIRM_START_GAME:
    "G_CHAOS_MASTER_SHOW_DIALOG_CONFIRM_START_GAME",

  //PLAY CARD
  PC_STEP_SHOW_SHOW_FIRST_CARD: "PC_STEP_SHOW_SHOW_FIRST_CARD",
  DELAY_G_CHAOS_PLAY_CARD: "DELAY_G_CHAOS_PLAY_CARD",
  PC_STEP1: "PC_STEP1",
  PC_STEP2: "PC_STEP2",
  PC_STEP3: "PC_STEP3",
  PC_STEP4: "PC_STEP4",
  PC_STEP5: "PC_STEP5",
  PC_STEP6: "PC_STEP6",
  SHOW_FIRST_CARD: "SHOW_FIRST_CARD",

  // PLAY CARD FIRST ROUND
  PC_G0_FIRST_ROUND: "PC_G0_FIRST_ROUND",
  PC_G1_FIRST_ROUND: "PC_G1_FIRST_ROUND",
  PC_SPADE_FIRST_ROUND: "PC_SPADE_FIRST_ROUND",
  PC_HEART_FIRST_ROUND: "PC_HEART_FIRST_ROUND",
  PC_DIAMOND_FIRST_ROUND: "PC_DIAMOND_FIRST_ROUND",
  PC_CLUB_FIRST_ROUND: "PC_CLUB_FIRST_ROUND",
  SHOW_CARD_FIRST_ROUND: "SHOW_CARD_FIRST_ROUND",

  //END ROUND
  ED_STEP2_END_GAME: "ED_STEP2_END_GAME",
  ED_STEP3_END_GAME: "ED_STEP3_END_GAME",
  ED_STEP4_END_GAME: "ED_STEP4_END_GAME",
  ED_STEP4_OVER_GET: "ED_STEP4_OVER_GET",
  ED_STEP4_OVER_GET_NO_CALL: "ED_STEP4_OVER_GET_NO_CALL",

  ED_STEP_CAPTAIN: "ED_STEP_CAPTAIN",
  ED_STEP_CAPTAIN_G_CHAOS: "ED_STEP_CAPTAIN_G_CHAOS",
  ED_STEP_GET_CAPTAIN: "ED_STEP_GET_CAPTAIN",
  ED_STEP_GET_CAPTAIN_G_CHAOS: "ED_STEP_GET_CAPTAIN_G_CHAOS",
  ED_STEP_GET_EVE_CAPTAIN: "ED_STEP_GET_EVE_CAPTAIN",
  ED_STEP_GET_EVE_CAPTAIN_G_CHAOS: "ED_STEP_GET_EVE_CAPTAIN_G_CHAOS",
  ED_STEP_GET_BREAKTHROUGH_CAPTAIN: "ED_STEP_GET_BREAKTHROUGH_CAPTAIN",
  ED_STEP_GET_BREAKTHROUGH_CAPTAIN_G_CHAOS:
    "ED_STEP_GET_BREAKTHROUGH_CAPTAIN_G_CHAOS",
  ED_STEP_CLOSE_NEW_CAPTAIN: "ED_STEP_CLOSE_NEW_CAPTAIN",
  ED_STEP_HIDE_ALL_GRAND_CROSS: "ED_STEP_HIDE_ALL_GRAND_CROSS",
  ED_STEP_SHOW_ALL_GRAND_CROSS: "ED_STEP_SHOW_ALL_GRAND_CROSS",
  ED_STEP_UPDATE_GRAND_CROSS_STAR_CARD: "ED_STEP_UPDATE_GRAND_CROSS_STAR_CARD",
  ED_STEP_UPDATE_GRAND_CROSS_START_CARD_ROUND13:
    "ED_STEP_UPDATE_GRAND_CROSS_START_CARD_ROUND13",

  // USE MASTER CARD
  UM_MASTER_CALL_SHOW_TRICK_STAR: "UM_MASTER_CALL_SHOW_TRICK_STAR",
  UM_SHOW_MASTER_CARD: "UM_SHOW_MASTER_CARD",
  UM_CLOSE_MASTER_CARD: "UM_CLOSE_MASTER_CARD",
  UM_SHOW_MASTER_CARD_1: "UM_SHOW_MASTER_CARD_1",
  UM_SHOW_MASTER_CARD_ALL: "UM_SHOW_MASTER_CARD_ALL",
  UM_SHOW_USE_MASTER_WINNER_CONFIRM: "UM_SHOW_USE_MASTER_WINNER_CONFIRM",
  UM_PLAYER_SELECT_CARD: "UM_PLAYER_SELECT_CARD",
  UM_MESSAGE_PLAYER_SELECT_CARD: "UM_MESSAGE_PLAYER_SELECT_CARD",
  UM_MESSAGE_G_CHAOS_SELECT_CARD: "UM_MESSAGE_G_CHAOS_SELECT_CARD",
  UM_MESSAGE_G_CHAOS_SELECT_ALL_CARD: "UM_MESSAGE_G_CHAOS_SELECT_ALL_CARD",
  UM_G_CHAOS_SELECT_CARD: "UM_G_CHAOS_SELECT_CARD",
  UM_G_CHAOS_SELECT_ALL_CARD: "UM_G_CHAOS_SELECT_ALL_CARD",
  WAIT_TO_RUN: "wait_to_run",
  RESET_OTHER_STATE_USE_MASTER_CARD: "RESET_OTHER_STATE_USE_MASTER_CARD",

  INSTRUCTOR_NOTIFY: "INSTRUCTOR_NOTIFY",
  LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_SCREEN:
    "LEAVE_ROOM_WHILE_PLAYING_NOTIFICATION_SCREEN",
  SG_STEP12_STOP_TUTORIAL_NO_CALL: "SG_STEP12_STOP_TUTORIAL_NO_CALL",
  SG_G_CHAOS_MASTER_NARRATION_20_2: "narration20_2",
  SG_20_2_SG_SHOW_GRAND_CROSS: "20_2_SG_SHOW_GRAND_CROSS",
  SG_20_2_SG_SHOW_HIDE_CROSS: "20_2_SG_SHOW_HIDE_CROSS",
  BACKUP_GRANDCROSS: "BACKUP_GRANDCROSS",
  START_SELECT_ORDER_CARD: "START_SELECT_ORDER_CARD",
};

export const POPUPS = {
  PLAYER_SELECT_MASTER_CARD: "PLAYER_SELECT_MASTER_CARD",
  G_CHAOS_SELECT_MASTER_CARD: "G_CHAOS_SELECT_MASTER_CARD",
  RESET_OTHER_STATE_USE_MASTER_CARD: "RESET_OTHER_STATE_USE_MASTER_CARD",

  /**USE ORDER CARD */
  SHOW_ORDER_CARDS_SCREEN: "SHOW_ORDER_CARDS_SCREEN",
  PLAYER_SELECT_ORDER_CARD: "PLAYER_SELECT_ORDER_CARD",
  G_CHAOS_SELECT_ORDER_CARD: "G_CHAOS_SELECT_ORDER_CARD",
  SHOW_ORDER_CARD: "SHOW_ORDER_CARD",
  G_CHAOS_AUTO_SELECT_ORDER_CARD: "G_CHAOS_AUTO_SELECT_ORDER_CARD",

  UPDATE_TURN_G_CHAOS_SELECT_ORDER_CARD:
    "UPDATE_TURN_G_CHAOS_SELECT_ORDER_CARD",
  UPDATE_TURN_PLAYER_SELECT_ORDER_CARD: "UPDATE_TURN_PLAYER_SELECT_ORDER_CARD",
  ANIMATED_WIN_GAME: "ANIMATED_WIN_GAME",
  END_GAME_TUTORIAL_NO_CALL: "END_GAME_TUTORIAL_NO_CALL",
};

export const NOTIFICATION_TYPE = {
  LEAVE_ROOM: "LEAVE_ROOM_NOTIFICATION",
};
export const GCHAOS_NORMAL_PLAY_DELAY_TIME = 2000;
export const MESSAGE_SHOWING_TIME = 2000;
export const SAN = "さん";

// triggers
export const TRIGGER_G_CHAOS_MASTER_START_GAME = "triggerGChaosStartGame";
export const TRIGGER_PLAYER_START_GAME = "triggerPlayerStartGame";
export const TRIGGER_PLAYER_START_GAME_AFTER_CALL =
  "triggerPlayerStartGameAfterCall";
export const TRIGGER_USE_MASTER_CARD = "triggerUseMasterCard";
export const TRIGGER_OPEN_CARDS_ROUND_13 = "triggerOpenCardRound13";
export const TRIGGER_END_GAME_NO_CALL = "triggerEndGameNoCall";
export const TRIGGER_G_CHAOS_PLAY_NEXT = "triggerGChaosPlayNext";
export const TOKYO_REGION = "asia-northeast1";

export const ALL_PLAYER_CONFIRM = "allPlayerConfirms";
export const PLAYER_CONFIRMS = "playerConfirms";
export const PLAYER_CONFIRMED = "playerConfirmed";
export const LOGS = "logs";
export const GAMES = "games";
export const EVENTS = "events";

export const OVERTIME = "overTime";

// rooms triggers
export const UPDATE_NEW_SUIT = "updateNewSuit";

/**
 * Time deplay (lag) then G-Chaos plays card after acaption, the message "captain" will show in 1.2 sẽ to show card in grand cross and show message
 * Product Owner (Sato san) want delay 0.8s after message close.
 */
export const G_CHAOS_PLAY_CARD_DELAY_TIME = 3600;

/**
 * Time deplay (lag) when G-Chaos play after normal player also the last time
 */
export const G_CHAOS_PLAY_CARD_DELAY_TIME_PLAY_LAST = 800;

export const G_CHAOS_PLAY_NEXT_DEPLAY_TIME = 1000;

export const TURN_INDICATOR_DELAY_FOR_CAPTAIN = 2000;

export const DISPLAY_NICKNAME_G_CHAOS_SELECT_MASTER_CARD =
  "displayNicknameGChaosSelectMasterCard";
export const UPDATE_MASTER_GRAND_CROSS = "UPDATE_MASTER_GRAND_CROSS";
export const DELAY_SHOW_ALL_CARD_DIALOG = 3000;

/** USE ORDER CARD */
export const LOCAL_G_CHAOS_AUTO_SELECT_ORDER_CARD =
  "LOCAL_G_CHAOS_AUTO_SELECT_ORDER_CARD";
export const LOCAL_SHOW_ALL_ORDER_CARDS_SELECTED =
  "LOCAL_SHOW_ALL_ORDER_CARDS_SELECTED";
export const G_CHAOS_SELECTED_ORDER_CARD = "G_CHAOS_SELECTED_ORDER_CARD";
export const DELAYS = {
  CHANGE_TURN_AFTER_CAPTAIN: 5100,
  CHANGE_TURN_NORMAL: 1000,
  RETRY_INTERVAL: 2100,
  RETRY_FIRST_MINUTE: 1000,
  RETRY_SECOND_TO_FIFTH_MINUTE: 5000,
  RETRY_SIXTH_TO_END_GAME: 10000,
};

export const CONFIRMS = {
  CONFIRM_START_GAME_DONE: "CONFIRM_START_GAME_DONE",
  CONFIRM_PREPARE_TO_CALL: "CONFIRM_PREPARE_TO_CALL",
  CONFIRM_FINAL_CALL: "CONFIRM_FINAL_CALL",
  CONFIRM_READY_TO_PLAY: "CONFIRM_READY_TO_PLAY",
  CONFIRM_NEW_TURN: "CONFIRM_NEW_TURN",
  CONFIRM_NEW_TURN_DONE: "CONFIRM_NEW_TURN_DONE",
  CONFIRM_SHOW_CARD: "CONFIRM_SHOW_CARD",
  CONFIRM_SHOW_SUIT_OF_ROUND: "CONFIRM_SHOW_SUIT_OF_ROUND",
  CONFIRM_APPLY_CALL: "CONFIRM_APPLY_CALL",

  CONFIRM_UPDATE_TURN_G_CHAOS_SELECT_ORDER_CARD:
    "CONFIRM_UPDATE_TURN_G_CHAOS_SELECT_ORDER_CARD",
  CONFIRM_G_CHAOS_SELECT_ORDER_CARD: "CONFIRM_G_CHAOS_SELECT_ORDER_CARD",
  CONFIRM_UPDATE_TURN_PLAYER_SELECT_ORDER_CARD:
    "CONFIRM_UPDATE_TURN_PLAYER_SELECT_ORDER_CARD",
  CONFIRM_END_ORDER_CARD: "CONFIRM_END_ORDER_CARD",
  CONFIRM_OPEN_ORDER_CARD: "CONFIRM_OPEN_ORDER_CARD",
  CONFIRM_CAN_SELECT_ORDER_CARD: "CONFIRM_CAN_SELECT_ORDER_CARD",
  CONFIRM_CAN_SELECT_ORDER_CARD_1: "CONFIRM_CAN_SELECT_ORDER_CARD_1",
  CONFIRM_CAN_SELECT_ORDER_CARD_2: "CONFIRM_CAN_SELECT_ORDER_CARD_2",
  CONFIRM_SHOW_CAPTAIN: "CONFIRM_SHOW_CAPTAIN",
  CONFIRM_SHOW_LAST_CARD: "CONFIRM_SHOW_LAST_CARD",
  CONFIRM_WAIT_MASTER_REQUEST_PLEASE: "CONFIRM_WAIT_MASTER_REQUEST_PLEASE",
  CONFIRM_END_GAME: "CONFIRM_END_GAME",
  CONFIRM_END_STAGE_13: "CONFIRM_END_STAGE_13",
  CONFIRM_SHOW_CARD_ROUND_13: "CONFIRM_SHOW_CARD_ROUND_13",
  HAS_TRICK_STAR: "HAS_TRICK_STAR",
  CONFIRM_SOMEONE_LEAVE_ROOM_WHILE_PLAYING:
    "CONFIRM_SOMEONE_LEAVE_ROOM_WHILE_PLAYING",
  SORT_CARD: "SORT_CARD",
  CONFIRM_PLAYER_COMPLETE_INTRODUCE: "CONFIRM_PLAYER_COMPLETE_INTRODUCE",
};

export const GAME_LOG_SHORTENED_CODES = {
  INITIAL_PLAYER_GAME_STATE: "IPGS",
  PLAY_CARD: "PC",
  CONFIRM_START_GAME_DONE: "CF_SG_DONE",
  CONFIRM_PREPARE_TO_CALL: "CF_PR_CALL",
  CONFIRM_READY_TO_PLAY: "CF_READY_PLAY",
  CONFIRM_NEW_TURN: "CF_NEW_TURN",
  CONFIRM_APPLY_CALL: "CF_APPLY_CALL",
  CONFIRM_SHOW_CARD: "CF_SHOW_CARD",
  CONFIRM_SHOW_SUIT_OF_ROUND: "CF_SUIT_OF_ROUND",
  CONFIRM_CAN_SELECT_ORDER_CARD: "CF_CAN_SELEC_OC",
  CONFIRM_G_CHAOS_SELECT_ORDER_CARD: "CF_GCHAOS_SELECXT_OC",
  CONFIRM_END_ORDER_CARD: "CF_END_OC",
  CONFIRM_OPEN_ORDER_CARD: "CF_OPEN_OC",
  CONFIRM_SHOW_CAPTAIN: "CF_SHOW_CAPTAIN",
  CONFIRM_SHOW_LAST_CARD: "CF_SHOW_LC",
  CONFIRM_WAIT_MASTER_REQUEST_PLEASE: "CF_WAIT_MASTER_REQUEST",
  CONFIRM_NEW_TURN_DONE: "CF_NEW_TURN_DONE",
  CONFIRM_END_GAME: "CF_EG",
  CONFIRM_SHOW_CARD_ROUND_13: "CF_SHOW_CARD_13",
  CONFIRM_END_STAGE_13: "CF_END_13",
  triggerGChaosStartGame: "GCHAOS_MASTER_SG",
  triggerConfirmOpenAllCards: "OPEN_ALL_CARDS",
  triggerPlayerStartGame: "PLAYER_SG",
  triggerPlayerStartGameAfterCall: "PLAYER_SG_AFTER_CALL",
  triggerUseMasterCard: "USE_MC",
  triggerOpenCardRound13: "OPEN_CARD_13",
  triggerEndGameNoCall: "END_GAME_NO_CALL",
  triggerGChaosPlayNext: "G-CHAOS_PLAY_NEXT",
  TRIGGERED: {
    CONFIRM_START_GAME_DONE: "CFED_SG_DONE",
    CONFIRM_PREPARE_TO_CALL: "CFED_PR_CALL",
    CONFIRM_READY_TO_PLAY: "CFED_READY_PLAY",
    CONFIRM_NEW_TURN: "CFED_NEW_TURN",
    CONFIRM_APPLY_CALL: "CFED_APPLY_CALL",
    CONFIRM_SHOW_CARD: "CFED_SHOW_CARD",
    CONFIRM_SHOW_SUIT_OF_ROUND: "CFED_SUIT_OF_ROUND",
    CONFIRM_CAN_SELECT_ORDER_CARD: "CFED_CAN_SELEC_OC",
    CONFIRM_G_CHAOS_SELECT_ORDER_CARD: "CFED_GCHAOS_SELECXT_OC",
    CONFIRM_END_ORDER_CARD: "CFED_END_OC",
    CONFIRM_OPEN_ORDER_CARD: "CFED_OPEN_OC",
    CONFIRM_SHOW_CAPTAIN: "CFED_SHOW_CAPTAIN",
    CONFIRM_SHOW_LAST_CARD: "CFED_SHOW_LC",
    CONFIRM_WAIT_MASTER_REQUEST_PLEASE: "CFED_WAIT_MASTER_REQUEST",
    CONFIRM_NEW_TURN_DONE: "CFED_NEW_TURN_DONE",
    CONFIRM_END_GAME: "CFED_EG",
    CONFIRM_SHOW_CARD_ROUND_13: "CFED_SHOW_CARD_13",
    CONFIRM_END_STAGE_13: "CFED_END_13",
    triggerGChaosStartGame: "CFED_GCHAOS_MASTER_SG",
    triggerConfirmOpenAllCards: "CFED_OPEN_ALL_CARDS",
    triggerPlayerStartGame: "CFED_PLAYER_SG",
    triggerPlayerStartGameAfterCall: "CFED_PLAYER_SG_AFTER_CALL",
    triggerUseMasterCard: "CFED_USE_MC",
    triggerOpenCardRound13: "CFED_OPEN_CARD_13",
    triggerEndGameNoCall: "CFED_END_GAME_NO_CALL",
    triggerGChaosPlayNext: "CFED_G-CHAOS_PLAY_NEXT",
  },
};

export const logs = {
  CONFIRM_TYPE: "CONFIRM_TYPE",
  ACTION_PLAYER: "ACTION_PLAYER",
  ACTION_PLAYER_NICK_NAME: "ACTION_PLAYER_NICK_NAME",
  GRAND_CROSS: "GRAND_CROSS",
  ORDER_CARDS: "ORDER_CARDS",
  GAME_ROUND: "GAME_ROUND",
  CAN_PLAY_CARD: "CAN_PLAY_CARD",
  CAN_SELECT_CARD: "CAN_SELECT_CARD",
  TIME_CHANGED: "TIME_CHANGED",
};

export const NOTIFY = {
  ACCEPT_FRIEND: "ACCEPT_FRIEND",
  ADD_FRIEND: "ADD_FRIEND",
};

/** PLAYER STATUS */
export const PLAYER_STATUS = {
  AVAILABLE: "AVAILABLE",
  WAITING: "WAITING",
  PLAYING: "PLAYING",
  OFFLINE: "OFFLINE",
};

/** CHAT TYPE */
export const CHAT_TYPE = {
  PRIVATE: "PRIVATE",
  GROUP: "GROUP",
  ROOM: "ROOM",
};

/** FRIEND_REQUEST_ALLOW */
export const FRIEND_REQUEST_ALLOW = {
  ALL_USER: "ALL_USER",
  FRIEND_OF_FRIENDS: "FRIEND_OF_FRIENDS",
  NOT_ALLOW: "NOT_ALLOW",
};

// package
export const SET_ALL_PACKAGES = "SET_ALL_PACKAGES";
export const BRONZE_PACKAGE_NAME = "Bronze";

//admin
export const SET_ADMIN_ID = "SET_ADMIN_ID";
export const ADMIN = "999";

//error code
export const ERROR_CODE_MESSAGE = {
  "auth/network-request-failed": "validate.noConnectionNetwork",
  "auth/internal-error": "validate.internalError",
  "auth/invalid-phone-number": "validate.invalidPhone",
  "auth/phone-number-already-exists": "validate.accountUsing",
  "auth/code-expired": "validate.codeExpired",
  "auth/invalid-verification-code": "validate.invalidCode",
  "auth/missing-verification-code": "validate.missingVerificationCode",
  "auth/too-many-requests": "validate.tooManyRequests",
  "auth/captcha-check-failed": "validate.captchaCheckFailed",
};
