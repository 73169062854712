import React from "react";
import PropTypes from "prop-types";
import {} from "../styles";

const TutorialWrapper = ({ width, children, className }) => {
  return (
    <div
      style={{
        width: width,
        height: width,
        boxSizing: "border-box",
        backgroundColor: "#eae1d3",
        borderRadius: "5px",
        position: "relative",
        alignItems: "center",
        justifyContent: "center",
        display: "flex",
        // margin: position == "left" ? "0 15px 0 0" : "0 0 0 15px",
        // marginBottom: 15,
      }}
      className={className}
    >
      {children}
    </div>
  );
};

TutorialWrapper.propTypes = {
  width: PropTypes.number,
  position: PropTypes.string,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default TutorialWrapper;
