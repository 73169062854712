import styled from "styled-components";

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-auto-rows: 30px;
  row-gap: 5px;
  color: #442211;
  height: ${(props) => props.scaleHeight * 200 + "px"};
  max-height: ${(props) => props.scaleHeight * 200 + "px"};
  overflow-y: auto;
  padding-right: 15px;
`;

export const UserList = styled.div`
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  column-gap: 5px;
  font-size: 12px;
  font-weight: 300;
  text-align: center;
  justify-items: center;
  align-items: center;
`;

export const StatusWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 10px 0;
`;

export const UserName = styled.div`
  font-size: 14px;
  font-weight: 400;
  padding-left: 5px;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-word;
  text-align: left;
`;

export const StyleButton = styled.button`
  width: 100%;
  box-sizing: border-box;
  border-radius: 10px;
  background-color: ${(props) => (props.isPresse ? "#442211" : "#fff")};
  padding: 5px;
  color: ${(props) => (props.isPresse ? "#fff" : "#442211")};
  font-size: 12px;
  font-weight: 300;
  border: ${(props) => (props.isPresse ? "none" : "1px solid #442211")};
  cursor: ${(props) => (props.isPresse ? "pointer" : "default")};
`;

export const LabelFriend = styled.div`
  box-sizing: border-box;
  width: 100%;
  border-radius: 10px;
  border: 1px solid #442211;
  background-color: #fff;
  padding: 3px 15px;
  font-size: 12px;
  font-weight: 300;
`;
