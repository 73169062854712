import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import App from "./App";
import "./i18n";
import store from "./store";
import { registerServiceWorker } from "./services/serviceWorker";
registerServiceWorker();
import theme from "./theme";
import { ThemeProvider } from "@material-ui/core/styles";

ReactDOM.render(
  <Provider store={store}>
    <Suspense fallback="">
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </Suspense>
  </Provider>,
  document.getElementById("root")
);
